import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const FTS = () => {
    return (
        <section className='FTS'>
            <MatchTable betType='FTS' />
            <Remark />
        </section>
    )
}
export default FTS;