import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import './style.scss';

const SingleLineUpMarquee = ({ lineHeight = 26, data = [] }) => {
    const ref = useRef(null);
    const [animate, setAnimate] = useState(false);
    const [index, setIndex] = useState(1);
    const [top, setTop] = useState('0');
    const [stop, setStop] = useState(false);
    const [speed, setSpeed] = useState(window.innerWidth * 10);

    useEffect(() => {
        setAnimate(false);
        setTop('0');
        setIndex(1);
    }, [data]);

    const scrollUp = useCallback(() => {
        if (lineHeight >= ref.current.scrollHeight || stop) {
            return;
        }
        setAnimate(true);
        setTop(`${-index * lineHeight}px`);
        if (index * lineHeight >= ref.current.scrollHeight) {
            setIndex(1);
        } else {
            setIndex(index + 1);
        }
    }, [index, lineHeight, stop]);

    useEffect(() => {
        const id = setTimeout(() => {
            if (index === 1) {
                setAnimate(false);
                setTop('0');
            }
        }, 1000);
        return () => clearTimeout(id);
    }, [index]);

    useEffect(() => {
        const id = setTimeout(scrollUp, Math.min(8000, Math.max(2000, speed)));
        return () => clearTimeout(id);
    }, [scrollUp, speed]);

    const visibilityChangeHandle = useCallback(() => {
        setStop(!!document.hidden);
    }, [setStop]);

    useEffect(() => {
        window.addEventListener('visibilitychange', visibilityChangeHandle);
        return () => window.removeEventListener('visibilitychange', visibilityChangeHandle);
    }, [visibilityChangeHandle]);

    const timer = useRef(null);
    const prev = useRef(Date.now());
    const handleResizeHandle = useCallback(() => {
        const now = Date.now();
        if (now - prev.current >= 500) {
            setStop(true);
            setAnimate(false);
            setTop('0');
            setIndex(1);
            timer.current !== null && clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setSpeed(window.innerWidth * 10);
                setStop(false);
            }, 520);
            prev.current = Date.now();
        }
    }, [setStop]);

    useEffect(() => {
        window.addEventListener('resize', handleResizeHandle);
        return () => window.removeEventListener('resize', handleResizeHandle);
    }, [handleResizeHandle]);

    return (
        <div
            className="single-line-up-marquee-container"
            style={{ '--line-height': lineHeight + 'px' }}
            onMouseEnter={() => {
                setStop(true);
            }}
            onMouseLeave={() => {
                setStop(false);
            }}
        >
            <div style={{ transform: `translate3d(0, ${top}, 0)` }} className={animate ? 'animate' : ''}>
                <div ref={ref} className="content">
                    {data.map((i, index) => (
                        <div key={index} className="item" dangerouslySetInnerHTML={{ __html: i }} />
                    ))}
                </div>
                <div className="content">
                    {data.map((i, index) => (
                        <div key={index} className="item" dangerouslySetInnerHTML={{ __html: i }} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default memo(SingleLineUpMarquee);
