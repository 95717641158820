import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SerachBox from './SearchBox';
import SearchResult from './SearchResult';
import { GetBetslipConfig } from '../../Common/ConfigHelper';
import { GetChannelPara } from '../../Common/ChannelParaFunc';
import { getSessionId, getStatment } from '../BetSlipAPI';
import { getSessionId_IB, getStatment_IB } from '../BetSlipIBAPI';
import useBroadcastChannel from '../../Common/hooks/useBroadcastChannel';

const AccountRecords = ({
    setShowExportPrint,
    currentTab,
    offsetDate,
    updateDownloadData,
    balanceLoaded,
    isSpeedbet,
    setIsApiErr,
    isReturnErrorCode,
    setErrorMessageCode,
    setDisableScroll,
    setIsCallingApi
}) => {
    const { t, i18n } = useTranslation();
    const [showResult, setShowResult] = useState(false);
    const [showSearchBox, setShowSearchBox] = useState(true);
    const [statementData, setStatementData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    const [lastStatReq, setLastStatReq] = useState(''); // IB
    const [incompleteSeg, setIncompleteSeg] = useState(''); // IB
    const broadcast_resetlogin = useBroadcastChannel('resetLoginTimeout');

    const statementTimeFrame = () => {
        let tf = null;
        if (isSpeedbet) {
            tf = GetChannelPara('OnlineStatTimeFrame4SpeedBet');
        } else {
            tf = GetChannelPara('OnlineStatTimeFrame');
        }

        if (tf) {
            return tf;
        }
        return 8;
    };

    const [paginationData, setPaginationData] = useState({
        pageSize: GetChannelPara('OnlineStmtRecPerPage') || 10,
        pageNumber: 1,
        totalPage: 1,
        nextPage: false,
        nextPageDate: 0,
        nextPageKey: 0
    });

    const [currentSearchParams, setCurrentSearchParams] = useState({
        startDate: offsetDate,
        endDate: offsetDate,
        searchType: 'today', // 'today' 'range' 'last'
        lastDay: 8,
        transType: {
            value: '3',
            text: t('LB_BETSLIP_ACCOUNTRECORDS_BETTYPE_ALL')
        },
        displayType: {
            value: '1',
            text: t('LB_BETSLIP_ACCOUNTRECORDS_TXNTYPE_ALL')
        },
        openingBalance: '1'
    });

    const [searchParams, setSearchParams] = useState({
        startDate: offsetDate,
        endDate: offsetDate,
        searchType: 'today', // 'today' 'range' 'last'
        lastDay: 8,
        transType: {
            value: '3',
            text: t('LB_BETSLIP_ACCOUNTRECORDS_BETTYPE_ALL')
        },
        displayType: {
            value: '1',
            text: t('LB_BETSLIP_ACCOUNTRECORDS_TXNTYPE_ALL')
        },
        openingBalance: '1'
    });

    const maxSelectDays = statementTimeFrame();

    const speedbetDayRangeDropdown = () => {
        const numbers = GetBetslipConfig()?.speedbetStatementDaysRange;
        const para = GetChannelPara('OnlineStatTimeFrame4SpeedBet');
        return numbers?.filter((number) => number <= para);
    };
    const statementExtension = () => {
        if (isSpeedbet) {
            return GetBetslipConfig()?.statementExtension;
        } else {
            return false;
        }
    };

    const updateSearchParams = (newSearchParams) => {
        if (newSearchParams.startDate) {
            searchParams.startDate = newSearchParams.startDate;
            searchParams.endDate = newSearchParams.endDate;
        }
        if (newSearchParams.searchType) {
            searchParams.searchType = newSearchParams.searchType;
        }
        if (newSearchParams.lastDay) {
            searchParams.lastDay = newSearchParams.lastDay;
        }
        if (newSearchParams.transType) {
            searchParams.transType = newSearchParams.transType;
        }
        if (newSearchParams.displayType) {
            searchParams.displayType = newSearchParams.displayType;
        }
        setSearchParams({ ...searchParams });
    };

    useEffect(() => {
        if (balanceLoaded) {
            const pageSize = GetChannelPara('OnlineStmtRecPerPage') || 10;
            setPaginationData({
                ...paginationData,
                pageSize
            });
            handleSearch();
            updateSearchParams({
                searchType: 'range'
            });
        }
    }, [balanceLoaded]);

    useEffect(() => {
        if (statementData.length > 0 && currentTab === 2) {
            setShowExportPrint(true);
        } else if (statementData.length === 0 && currentTab === 2) {
            setShowExportPrint(false);
        }
    }, [statementData, currentTab]);

    const dateToDateString = (date) => {
        const dateStr = date.toLocaleDateString('sv-SE').split('-').join('');
        return dateStr;
    };

    const onClickSearch = () => {
        //reset currentSearchParams
        currentSearchParams.startDate = searchParams.startDate;
        currentSearchParams.endDate = searchParams.endDate;
        currentSearchParams.searchType = searchParams.searchType;
        currentSearchParams.lastDay = searchParams.lastDay;
        currentSearchParams.transType = searchParams.transType;
        currentSearchParams.displayType = searchParams.displayType;
        currentSearchParams.openingBalance = searchParams.openingBalance;
        setCurrentSearchParams({ ...currentSearchParams });

        updatePaginationDate({
            ...paginationData,
            totalPage: 1,
            pageNumber: 1,
            nextPage: false
        });
        handleSearch(false);
    };

    const handleSearch = async (nextPage) => {
        const accessToken = sessionStorage.getItem('access_token');
        if (!accessToken) return;
        setShowSearchBox(false);
        setIsLoading(true);
        setShowResult(true);
        setIsCallingApi(true);
        if (nextPage) {
            setIsNextPageLoading(true);
        } else {
            setStatementData([]);
            setLastStatReq(null);
            setIncompleteSeg(null);
        }

        const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken);
        broadcast_resetlogin?.postMessage(false);

        if (sessionData?.sessionId) {
            const params = {
                language: i18n.language,
                numberOfTransaction: paginationData.pageSize,
                horsePerLine: window.innerWidth > 768 ? 2 : 1,
                transType: currentSearchParams.transType.value,
                displayType: currentSearchParams.displayType.value,
                nextPage,
                continuationDate: paginationData.nextPageDate,
                continuationKey: paginationData.nextPageKey,
                lastStatReq: nextPage ? lastStatReq : null, // IB
                incompleteSeg: nextPage ? incompleteSeg : null, // IB
                searchType: currentSearchParams.searchType,
                openingBalance: currentSearchParams.openingBalance
            };

            //console.log('currentSearchParams', currentSearchParams);
            switch (currentSearchParams.searchType) {
                case 'today':
                    params.startDate = dateToDateString(offsetDate);
                    params.dayCount = 1;
                    params.endDate = dateToDateString(offsetDate);
                    break;
                case 'range':
                    params.startDate = dateToDateString(currentSearchParams.startDate);
                    params.dayCount =
                        Math.ceil(
                            (currentSearchParams.endDate.getTime() - currentSearchParams.startDate.getTime()) /
                                24 /
                                60 /
                                60 /
                                1000
                        ) + 1;
                    params.endDate = dateToDateString(currentSearchParams.endDate);
                    break;
                case 'last':
                    params.dayCount = currentSearchParams.lastDay;
                    params.startDate = dateToDateString(
                        new Date(offsetDate.getTime() - (currentSearchParams.lastDay - 1) * 24 * 60 * 60 * 1000)
                    );
                    params.endDate = dateToDateString(offsetDate);
                    break;
                case 'next':
                    params.dayCount = currentSearchParams.dayCount;
                    params.startDate = currentSearchParams.startDate;
                    params.endDate = currentSearchParams.endDate;
                    break;
            }

            const responseData = window.globalConfig.IS_IB
                ? await getStatment_IB(params, i18n.language)
                : await getStatment(sessionData, accessToken, params);
            broadcast_resetlogin?.postMessage(false);
            if (isReturnErrorCode(window.sessionStorage.getItem('stmt_status'), 'stmt')) {
                return;
            }

            setLastStatReq(responseData?.LastStatReq); // IB
            setIncompleteSeg(responseData?.IncompleteSeg); // IB
            //console.log('responseData', responseData);
            if (responseData?.transactionContent) {
                let pageNumber;
                let totalPage;
                let newStatementData;
                if (nextPage) {
                    pageNumber = paginationData.pageNumber + 1;
                    totalPage = paginationData.totalPage + 1;
                    newStatementData = [...statementData, ...responseData.transactionContent];
                } else {
                    totalPage = 1;
                    pageNumber = 1;
                    newStatementData = responseData.transactionContent;
                }
                setStatementData(newStatementData);
                updateDownloadData({
                    statementData: newStatementData,
                    searchParams: {
                        startDate: searchParams.startDate,
                        dayCount: params.dayCount,
                        endDate: searchParams.endDate,
                        searchType: searchParams.searchType
                    }
                });
                setPaginationData({
                    ...paginationData,
                    pageNumber,
                    totalPage,
                    nextPage: responseData.continue,
                    nextPageDate: responseData.continuationDate,
                    nextPageKey: responseData.continuationKey
                });
                setCurrentSearchParams({
                    ...currentSearchParams,
                    searchType: 'next',
                    startDate: responseData?.curDate,
                    endDate: responseData?.endDate,
                    openingBalance: responseData?.openBal
                });
                setIsApiErr(false);
            } else {
                setIsApiErr(true);
                setErrorMessageCode(responseData?.stmt_status);
            }
        } else {
            setIsApiErr(true);
        }
        setIsLoading(false);
        setIsCallingApi(false);
        setIsNextPageLoading(false);
    };

    const handleClickNewSearch = () => {
        setShowSearchBox(true);
    };

    const closeSearchBox = () => {
        setShowSearchBox(false);
    };

    useEffect(() => {
        setDisableScroll(showSearchBox);
    }, [showSearchBox]);

    const updatePaginationDate = (newData) => {
        setPaginationData(newData);
    };

    return (
        <>
            <div className="ARContent">
                <SerachBox
                    onChangeSearchParams={updateSearchParams}
                    searchParams={searchParams}
                    maxSelectDays={maxSelectDays}
                    isSpeedbet={isSpeedbet}
                    offsetDate={offsetDate}
                    speedbetDayRangeDropdown={speedbetDayRangeDropdown()}
                    statementExtension={statementExtension()}
                    onStatementSearch={onClickSearch}
                    isShowResult={showResult}
                    isShowSearchBox={showSearchBox}
                    closeSearchBox={closeSearchBox}
                />
                <SearchResult
                    data={statementData}
                    isShowResult={showResult}
                    searchParams={searchParams}
                    handleClickNewSearch={handleClickNewSearch}
                    paginationData={paginationData}
                    updatePaginationDate={updatePaginationDate}
                    handleSearchData={handleSearch}
                    isLoading={isLoading}
                    isNextPageLoading={isNextPageLoading}
                    currentTab={currentTab}
                />
            </div>
        </>
    );
};

export default AccountRecords;
