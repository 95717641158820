import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../Common/CommonHooks';
import { WPQOddsSwitch, WPQOddsTableTop20, WPQOddsTableTop10 } from './QINOddsTableBase';
import { RacingContext } from '../../Home/RacingPage';
import { useQuickBet } from './QuickBetHook';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Loading from '../../Common/ui-components/Loading';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import {isStartSellpmPool } from './Common';
import { clipboardIndicatorStyle, clipboardTableTop20, clipboardTableBankerTop10 } from './ClipboardCommon';

const CustomScrollbars = (props) => (
    <Scrollbars
        autoHeight
        // autoHide
        // autoHideTimeout={1000}
        // autoHideDuration={200}
        autoHeightMin={50}
        autoHeightMax={5000}
        {...props}
    />
);

const QINOddsTable = ({ msg, isLoading }) => {
    const { t, i18n } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const race = mtg.races.filter((item) => item.no === context.content.raceNo)[0];

    const containerRef = useRef();
    const tableRef = useRef();
    const [showTable, setShowTable] = useState(true);
    const [tableType, setTableType] = useState({
        QIN: '',
        QPL: ''
    });
    const [init, setInit] = useState(false);
    const { handleClickCombOdds } = useQuickBet();

    useEffect(() => {
        setShowTable(true);

        if (race.wageringFieldSize > 14) {
            setTableType({
                QIN: 'Top',
                QPL: 'Top'
            });
        } else {
            setTableType({
                QIN: '',
                QPL: ''
            });
        }

        setInit(true);
        // eslint-disable-next-line
    }, [context.content.raceNo]);

    useEffect(() => {
        if (tableRef.current && containerRef.current && showTable) {
            const height = tableRef.current.clientHeight + 40 + 12;
            containerRef.current.style.height = height + 'px';
        }
        const size = document.documentElement.clientWidth;

        if (!showTable && size <= 768) {
            containerRef.current.style.height = '40px';
        }
    }, [tableType, msg, showTable]);

    const getPool = (betType) => {
        if(mtg.pmPools.length < 1) return 
        let preSuffix = context.content.isPresales ? 'Pre' : '';

        return mtg.pmPools.filter(
            (data) =>
                data.leg.races[0] == context.content.raceNo && data.oddsType == betType + tableType[betType] + preSuffix
        )[0];
    };

    const getPoolStatus = (betType) => {
        if(mtg.poolInvs.length < 1) return 

        return mtg.poolInvs.filter(
            (data) =>
                data.leg.races[0] == context.content.raceNo && data.oddsType == betType
        )[0];
    };

    const getOdds = (betType) => {
       
        let pool = getPool(betType);
        if (pool == null) {
            return [];
        }
        return pool.oddsNodes;
    };

    const onChangeTableDisplay = () => {
        const height = tableRef.current.clientHeight + 40;
        if (!showTable) {
            containerRef.current.style.height = height + 'px';
        }  else {
            containerRef.current.style.height = '40px';
        }
        setShowTable(!showTable);
    };

    const handleClickOdds = (odds, betType) => {
        const pmPool = getPool(betType);
        //const startSell = pmPool.status === 'START_SELL';
        const startSell = isStartSellpmPool(pmPool);
        if (startSell) {
            handleClickCombOdds(odds, betType);
        }
    };

    const checkScratch = (top, left, odds) => {
        if (race == null) return;
        const data = race.runners.filter((item) => parseInt(item.no) === top || parseInt(item.no) === left);

        if (data.length === 0) return;
        if (odds === 'SCR') return t('LB_RC_SCR_S');
        if (data[0]?.status.toUpperCase().indexOf('SCRATCHED') >= 0 || data[1]?.status.toUpperCase().indexOf('SCRATCHED') >= 0)
            return t('LB_RC_SCR_S');
        if (['RESERVED', 'STANDBY'].includes(data[0]?.status.toUpperCase())
         || ['RESERVED', 'STANDBY'].includes(data[1]?.status.toUpperCase())) return '---';
    };

    const findOdds = (top, left, oddsList) => {
        if (!oddsList || oddsList.length === 0) return;
        let data = oddsList.find((item) => {
            const [iTop, iLeft] = item.combString.split(',');
            return (Number(iTop) === top && Number(iLeft) === left) || (Number(iTop) === left && Number(iLeft) === top);
        });
        return data || {};
    };



    const WPQOddsSwitchMemo = useMemo(
        () =>
            ({ betType, tableType, setTableType }) =>
                <WPQOddsSwitch betType={betType} tableType={tableType} setTableType={setTableType} />,
        []
    );

    

    let poolQIN = getPool('QIN');
    let poolQPL = getPool('QPL');
    //let enableQuickBetQIN = !isMobileSize && poolQIN?.status === 'START_SELL';
    let enableQuickBetQIN = !isMobileSize && isStartSellpmPool(poolQIN);
    //let enableQuickBetQPL = !isMobileSize && poolQPL?.status === 'START_SELL';
    let enableQuickBetQPL = !isMobileSize &&  isStartSellpmPool(poolQPL);
    // let startSellQIN = poolQIN?.status === 'START_SELL';
    let startSellQIN = isStartSellpmPool(poolQIN);
    // let startSellQPL = poolQPL?.status === 'START_SELL';
    let startSellQPL = isStartSellpmPool(poolQPL);
    
    const otherTableProps = {
        tableType, race, getOdds, getPool, getPoolStatus, findOdds, checkScratch, handleClickOdds, showTable
    }

    context.racingFuncs.copyClipboardLeftOddsTable = () => { 
        return `${clipboardTable({...otherTableProps, betType:"QIN", context, t, i18n})}
            ${clipboardTable({...otherTableProps, betType:"QPL", context, t, i18n})}`;
    }

    return (
        <div className={`qin-odds-table-container ${showTable ? '' : 'qinHideTable'}`} ref={containerRef}>
            <div
                className={`odds-table-display-switch ${showTable ? 'show-table' : ''}`}
                onClick={onChangeTableDisplay}
            >
                {t('LB_ODDS')}
                <div>
                    <ArrowIcon direction='down' change={showTable} changeDirection='up' />
                </div>
            </div>
            {init && !isLoading ? (
                <div className="qin-odds-slide-container" ref={tableRef}>
                    {race.wageringFieldSize > 14 && (
                        <WPQOddsSwitchMemo betType="QIN" tableType={tableType} setTableType={setTableType} />
                    )}
                    <CustomScrollbars>
                        <div className="qin-odds-slide">
                            {tableType.QIN === '' && <WPQOddsTable betType={'QIN'} enableQuickBet={enableQuickBetQIN} isStartSell={startSellQIN} {...otherTableProps}/>}
                            {tableType.QIN === 'Top' && (
                                <WPQOddsTableTop20
                                    betType={'QIN'}
                                    data={getOdds('QIN')}
                                    pool={poolQIN}
                                    onClickOdds={handleClickOdds}
                                    enableQuickBet={enableQuickBetQIN}
                                    isStartSell={startSellQIN}
                                />
                            )}
                            {tableType.QIN === 'Banker' && (
                                <WPQOddsTableTop10
                                    betType={'QIN'}
                                    race={race}
                                    data={getOdds('QIN')}
                                    pool={poolQIN}
                                    onClickOdds={handleClickOdds}
                                    enableQuickBet={enableQuickBetQIN}
                                    isStartSell={startSellQIN}
                                />
                            )}
                        </div>
                    </CustomScrollbars>
                    {race.wageringFieldSize > 14 && (
                        <WPQOddsSwitchMemo betType="QPL" tableType={tableType} setTableType={setTableType} />
                    )}
                    <CustomScrollbars>
                        <div className="qin-odds-slide">
                            {tableType.QPL === '' && <WPQOddsTable betType={'QPL'} enableQuickBet={ enableQuickBetQPL} isStartSell={startSellQPL} {...otherTableProps}/>}
                            {tableType.QPL === 'Top' && (
                                <WPQOddsTableTop20
                                    betType={'QPL'}
                                    data={getOdds('QPL')}
                                    pool={poolQPL}
                                    onClickOdds={handleClickOdds}
                                    enableQuickBet={enableQuickBetQPL}
                                    isStartSell={startSellQPL}
                                />
                            )}
                            {tableType.QPL === 'Banker' && (
                                <WPQOddsTableTop10
                                    betType={'QPL'}
                                    race={race}
                                    data={getOdds('QPL')}
                                    pool={poolQPL}
                                    onClickOdds={handleClickOdds}
                                    enableQuickBet={enableQuickBetQPL}
                                    isStartSell={startSellQPL}
                                />
                            )}
                        </div>
                    </CustomScrollbars>
                    {msg}
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default QINOddsTable;

const WPQOddsTable = (props) => {
    const { betType, enableQuickBet,  isStartSell, tableType, race } = props
    const context = useContext(RacingContext)
    return (
        <>
            <div className={`qin-table ${tableType[betType] != '' ? 'hide' : ''}`}>
                <div className="qin-odds-table">
                    <TableHeader title={betType} {...props}/>
                    <OddsTable betType={betType} enableQuickBet={enableQuickBet} isStartSell={isStartSell} content={context} {...props}/>
                </div>
                {race.wageringFieldSize > 14 && (
                    <div className="qin-odds-table">
                        <AllTableHeader pageNumber={2} {...props}/>
                        <AllTableOthers pageNumber={2} betType={betType} enableQuickBet={enableQuickBet} isStartSell={isStartSell} content={context} {...props}/>
                    </div>
                )}
                {race.wageringFieldSize > 29 && (
                    <div className="qin-odds-table qin-odds-table-page3">
                        <AllTableHeader pageNumber={3} {...props}/>
                        <AllTableOthers pageNumber={3} betType={betType} enableQuickBet={enableQuickBet} isStartSell={isStartSell} content={context} {...props}/>
                    </div>
                )}
            </div>
        </>
    );
};

const TableHeader = ({ title, showTable}) => {
    const {t, i18n} = useTranslation()
    const { isMobileSize } = useWindowSize();
    let header = [];

    for (let i = 0; i <= 14; i++) {
        if (i === 0)
            header.push(
                <>
                    <td colSpan={3} className={'qin-odds-table-title'}>
                        <div className={`${!isMobileSize?'wpqLabelWidth':''}`}>{t(`LB_${title}`)}</div>
                    </td>
                </>
            );
        else if (i === 1) {
        } else {
            header.push(<div>{i}</div>);
        }

        if (i === 14)
            header.push(
                <div className="">
                    <div></div>
                </div>
            );
    }
    return (
        <div
            className={`qin-odds-table-header ${showTable ? '' : 'hide-title'} ${
                i18n.language === 'en' ? 'en-title-' + title : ''
            }`}
        >
            {header}
        </div>
    );
};

const Row = ({ index, className, betType, enableQuickBet, isStartSell, getOdds, getPool, getPoolStatus, race, findOdds, checkScratch, handleClickOdds, context }) => {
    const row = [];
    const oddsList = getOdds(betType);
    const pool = getPool(betType);
    const isRefund = getPoolStatus(betType)?.status.indexOf("REFUND")>=0;
    for (let i = 0; i <= 15; i++) {
        if (i === 0 && index === 1)
            row.push(
                <div className="left-number" key={'left-number' + index + betType + i}>
                    <div></div>
                </div>
            );
        else if (i === 0)
            row.push(
                <div className="left-number" key={'left-number' + index + betType + i}>
                    {' '}
                    {index + 7}
                </div>
            );
        else if (index === i)
            row.push(
                <div className="center-number" key={'center-number' + index + betType + i}>
                    {' '}
                    {index + 7}
                </div>
            );
        else if (index === i - 1)
            row.push(
                <div className="center-number" key={'center-number' + index + betType + i}>
                    {' '}
                    {i - 1}
                </div>
            );
        else if (i - 1 > race.wageringFieldSize || index > race.wageringFieldSize) {
            row.push(<div key={`cell-${index}-${i}-${betType}-all`}></div>);
        } else if (index > i && index + 7 > race.wageringFieldSize) row.push(<div></div>);
        else {
            if (isRefund) {
                let colIdx =  0
                let rowIdx = 0
                if(index > i){
                    colIdx = i + 7
                    rowIdx = index +7
                }else if(i > index + 1){
                    colIdx = index
                    rowIdx = i-1
                }
                row.push(<div key={`cell-${index}-${i}-${betType}-all`} className='disabled' id={`qb_${betType}_${colIdx}_${rowIdx}`}>---</div>);
                if (i === 15) {
                    row.push(
                        <div className="right-number" key={`cell-${index}-${i}-${betType}-all-15`}>
                            {index}
                        </div>
                    );
                }
                continue;
            }
            if (index > i) {
                const oddsInfo = findOdds(i + 7, index + 7, oddsList);
                const scratched = checkScratch(i + 7, index + 7, oddsInfo?.oddsValue);
                const value = scratched || oddsInfo?.oddsValue;
                const isPresales = context?.content?.isPresales
                const hot = !scratched
                    ? oddsInfo?.hotFavourite
                        ? 'favourite'
                        : isPresales ? '' 
                            :
                            oddsInfo?.oddsDropValue >= 50
                            ? 'drop50'
                            : oddsInfo?.oddsDropValue >= 20
                            ? 'drop20'
                            : ''
                    : '';

                row.push(
                    <div key={`cell-${index}-${i}-${betType}-all`} className={`${hot} ${!isStartSell || scratched ? "disabled" : ''}`} id={`qb_${betType}_${i + 7}_${index + 7}`}>
                        <span
                            onClick={() => enableQuickBet && !scratched && handleClickOdds(oddsInfo, betType)}
                            className={`${!enableQuickBet || scratched ? '' : 'cp table-odds'}`}
                        >
                            {value}
                        </span>
                    </div>
                );
            } else if (i > index + 1) {
                const oddsInfo = findOdds(i - 1, index, oddsList) || {};
                const scratched = checkScratch(i - 1, index, oddsInfo?.oddsValue);
                const value = scratched || oddsInfo?.oddsValue;
                const isPresales = context?.content?.isPresales
                const hot = !scratched
                    ? oddsInfo?.hotFavourite
                        ? 'favourite'
                        : isPresales ? '' 
                            :
                            oddsInfo?.oddsDropValue >= 50
                            ? 'drop50'
                            : oddsInfo?.oddsDropValue >= 20
                            ? 'drop20'
                            : ''
                    : '';

                row.push(
                    <div key={`cell-${index}-${i}-${betType}-all`} className={`${hot} ${!isStartSell || scratched ? "disabled" : ''}`} id={`qb_${betType}_${index}_${i - 1}`}>
                        <span
                            onClick={() => enableQuickBet && !scratched && handleClickOdds(oddsInfo, betType)}
                            className={`${!enableQuickBet || scratched ? '' : 'cp table-odds'}`}
                        >
                            {value}
                        </span>
                    </div>
                );
            } else {
                row.push(<div></div>);
            }
        }
        if (i === 15) {
            row.push(
                <div className="right-number" key={`cell-${index}-${i}-${betType}-all-15`}>
                    {index}
                </div>
            );
        }
    }

    return <div className={`iwn-odds-table-row ${className}`}>{row}</div>;
};

const OddsTable = (props) => {
    const table = [];

    for (let i = 1; i <= 7; i++) {
        table.push(
            <Row
                index={i}
                className={i % 2 === 0 ? 'sec' : ''}
                key={i}
                {...props}
            />
        );
    }
    return table;
};

const AllTableRow = ({ index, className, betType, pageNumber, enableQuickBet, isStartSell, getOdds,getPool, getPoolStatus, race, findOdds, handleClickOdds, checkScratch, context }) => {
    const row = [];
    const oddsList = getOdds(betType);
    const pool = getPool(betType);
    const isRefund = getPoolStatus(betType)?.status.indexOf("REFUND")>=0;
    const length = pageNumber === 3 ? 6 : 16;
    for (let i = 0; i <= length; i++) {
        let num = i + (pageNumber - 2) * 15 + 14;
        if (i === 0 || i === length)
            row.push(
                <div className="left-number" key={`number-${i}-${index}-${betType}`}>
                    {index}
                </div>
            );
        else if (
            index >= num ||
            oddsList?.length === 0 ||
            num > race.wageringFieldSize ||
            index > race.wageringFieldSize
        )
            row.push(<div key={`empty-${i}-${index}-${betType}`}></div>);
        else {
            if (isRefund) {
                row.push(<div key={`cell-${index}-${i}-${betType}-all`} className='disabled' id={`qb_${betType}_${num}_${index}`}>---</div>);
                continue;
            }
            const oddsInfo = findOdds(num, index, oddsList);
            const scratched = checkScratch(num, index, oddsInfo?.oddsValue);
            const value = scratched || oddsInfo?.oddsValue;
            const isPresales = context?.content?.isPresales
                const hot = !scratched
                    ? oddsInfo?.hotFavourite
                        ? 'favourite'
                        : isPresales ? '' 
                            :
                            oddsInfo?.oddsDropValue >= 50
                            ? 'drop50'
                            : oddsInfo?.oddsDropValue >= 20
                            ? 'drop20'
                            : ''
                : '';

            row.push(
                <div key={`cell-${index}-${i}-${betType}-${pageNumber}`} className={`${hot} ${!isStartSell || scratched ? "disabled" : ''}`} id={`qb_${betType}_${num}_${index}`}>
                    <span
                        
                        onClick={() => enableQuickBet && !scratched && handleClickOdds(oddsInfo, betType)}
                        className={`${!enableQuickBet && scratched ? '' : 'cp table-odds'}`}
                    >
                        {value}
                    </span>
                </div>
            );
        }
    }

    return <div className="iwn-odds-table-row">{row}</div>;
};

const AllTableHeader = ({ pageNumber }) => {
    const row = [];
    const length = pageNumber === 3 ? 6 : 16;
    for (let i = 0; i <= length; i++) {
        const num = i + (pageNumber - 2) * 15 + 14;
        if (i === 0 || i === length) row.push(<div key={`header-${num}-${pageNumber}`}></div>);
        else {
            row.push(<div key={`header-${num}-${pageNumber}`}>{num}</div>);
        }
    }
    return <div className="qin-odds-table-header">{row}</div>;
};

const AllTableOthers = (props) => {
    const {pageNumber, betType, race } = props
    const table = [];
    const length = 14 * pageNumber > race.wageringFieldSize - 1 ? race.wageringFieldSize - 1 : 14 * pageNumber;
    for (let i = 1; i <= length; i++) {
        table.push(
            <AllTableRow
                index={i}
                key={`${betType}-row-${i}-${pageNumber}-all`}
                {...props}
            />
        );
    }

    return table;
};

const clipboardRowAll = (index, props) => {
    const { betType, getOdds, getPoolStatus, race, findOdds, checkScratch, context } = props;
    const row = [];
    const oddsList = getOdds(betType);
    const isRefund = getPoolStatus(betType)?.status.indexOf("REFUND")>=0;
    for (let i = 0; i <= 15; i++) {
        if (i === 0 && index === 1)
            row.push(`<td style="background-color:#6176a0"></td>`);
        else if (i === 0 || index === i)
            row.push(`<td style="background-color:#6176a0">${index+7}</td>`);
        else if (index === i - 1)
            row.push(`<td style="background-color:#6176a0">${i-1}</td>`);
        else if (i - 1 > race.wageringFieldSize || index > race.wageringFieldSize) {
            row.push(`<td></td>`);
        } else if (index > i && index + 7 > race.wageringFieldSize)
            row.push(`<td></td>`);
        else {
            if (isRefund) {
                row.push(`<td>---</td>`);
            }
            else {
                if (index > i) {
                    const oddsInfo = findOdds(i + 7, index + 7, oddsList);
                    const scratched = checkScratch(i + 7, index + 7, oddsInfo?.oddsValue);
                    const value = scratched || oddsInfo?.oddsValue || "";
                    const style = clipboardIndicatorStyle(context, oddsInfo?.hotFavourite, oddsInfo?.oddsDropValue);
                    row.push(`<td style="${style}">${value}</td>`);
                } else if (i > index + 1) {
                    const oddsInfo = findOdds(i - 1, index, oddsList) || {};
                    const scratched = checkScratch(i - 1, index, oddsInfo?.oddsValue);
                    const value = scratched || oddsInfo?.oddsValue || "";
                    const style = clipboardIndicatorStyle(context, oddsInfo?.hotFavourite, oddsInfo?.oddsDropValue);
                    row.push(`<td style="${style}">${value}</td>`);
                } else {
                    row.push(`<td></td>`);
                }
            }
        }
        if (i === 15) {
            row.push(`<td style="background-color:#6176a0">${index}</td>`);
        }
    }

    return `<tr>${row.join("")}</tr>`;
}

const clipboardRowAllOthers = (index, pageNumber, props) => {
    const { betType, getOdds, getPoolStatus, race, findOdds, checkScratch, context } = props;
    const row = [];
    const oddsList = getOdds(betType);
    const isRefund = getPoolStatus(betType)?.status.indexOf("REFUND")>=0;
    const length = pageNumber === 3 ? 6 : 16;
    for (let i = 0; i <= length; i++) {
        let num = i + (pageNumber - 2) * 15 + 14;
        if (i === 0 || i === length)
            row.push(`<td style="background-color:#6176a0">${index}</td>`);
        else if (
            index >= num ||
            oddsList?.length === 0 ||
            num > race.wageringFieldSize ||
            index > race.wageringFieldSize
        )
            row.push(`<td></td>`);
        else {
            if (isRefund) {
                row.push(`<td>---</td>`);
            }
            else {
                const oddsInfo = findOdds(num, index, oddsList);
                const scratched = checkScratch(num, index, oddsInfo?.oddsValue);
                const value = scratched || oddsInfo?.oddsValue || "";
                const style = clipboardIndicatorStyle(context, oddsInfo?.hotFavourite, oddsInfo?.oddsDropValue);
                row.push(`<td style="${style}">${value}</td>`);
            }
        }
    }
    return `<tr>${row.join("")}</tr>`;
};


const clipboardTableAll = (props) => {
    const { t, betType, race } = props;
    let table = [];

    let header = [];
    for (let i = 0; i <= 14; i++) {
        if (i === 0)
            header.push(`<td colspan="3" style="background-color:#122c68; color:#FFFFFF">${t(`LB_${betType}`)}</td>`);
        else if (i === 1) {
        } else {
            header.push(`<td style="background-color:#6176a0">${i}</td>`);
        }
        if (i === 14)
            header.push(`<td style="background-color:#6176a0"></td>`);
    }
    table.push(`<tr>${header.join("")}</tr>`);

    for (let i = 1; i <= 7; i++) {
        table.push(clipboardRowAll(i , {...props}));
    }

    if ( race.wageringFieldSize > 14 ) {
        let tmpHeader = [];
        for (let i = 0; i <= 16; i++) {
            if (i === 0 || i === 16) {
                tmpHeader.push(`<td style="background-color:#6176a0"></td>`);
            }
            else {
                tmpHeader.push(`<td style="background-color:#6176a0">${i+14}</td>`);
            }
        }
        table.push(`<tr>${tmpHeader.join("")}</tr>`);

        for (let i = 1; i < race.wageringFieldSize; i++) {
            table.push(clipboardRowAllOthers(i, 2, {...props}));
        }
    }

    if ( race.wageringFieldSize > 29 ) {
        let tmpHeader = [];
        for (let i = 0; i <= 6; i++) {
            if (i === 0 || i === 6) {
                tmpHeader.push(`<td style="background-color:#6176a0"></td>`);
            }
            else {
                tmpHeader.push(`<td style="background-color:#6176a0">${i+29}</td>`);
            }
        }
        table.push(`<tr>${tmpHeader.join("")}</tr>`);

        for (let i = 1; i < race.wageringFieldSize; i++) {
            table.push(clipboardRowAllOthers(i, 3, {...props}));
        }
    }

    return `<table>${table.join("")}</table>`;
}

const clipboardTableTop = (props) => {
    const { betType, getOdds, getPoolStatus, t } = props;
    const data = getOdds(betType);
    const isRefund = getPoolStatus(betType)?.status.indexOf("REFUND")>=0;
    return clipboardTableTop20(data, `${t(`LB_${props.betType}`)} ${t(`LB_RC_WPQ_TABLE_TOP`)}`, isRefund);
}

const clipboardTableBanker = (props) => {
    const { betType, getOdds, getPoolStatus, race, t, i18n } = props;
    const data = getOdds(betType);
    const isRefund = getPoolStatus(betType)?.status.indexOf("REFUND")>=0;
    return clipboardTableBankerTop10(data, race.runners, `${t(`LB_${props.betType}`)} ${t(`LB_RC_WPQ_TABLE_BANKER`)}`, isRefund, t, i18n);
}

const clipboardTable = (props) => {
    const { tableType, betType } = props;

    switch (tableType[betType]) {
        case "Top":
            return clipboardTableTop(props);
        case "Banker":
            return clipboardTableBanker(props);
        default:
            return clipboardTableAll(props);
    }
}