import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../Common/GlobalFunc';
import { getDayOfWeek, get12HourTime } from '../../Common/home-common';
import { MarksixContext } from '../../Home/MarksixPage';
import { handleClickHelpLink, handleClickChanceTableLink } from './Common';
import { isSnowballDraw } from './Common';
import { useWindowSize } from '../../Common/CommonHooks';
import Loading from '../../Common/ui-components/Loading';

const NextDrawInfoTable = ({ activeType }) => {
  const { t, i18n } = useTranslation();
  const context = useContext(MarksixContext);
  const { isMobileSize } = useWindowSize();
  const draw = context.nextDraw;
  const prevDraw = context.lastDraw;
  const isSB = isSnowballDraw(draw);
  const pageType = context.pageName || 'SINGLE';
  const TableL = () => {
    const nextNumber = isSB ? 'LB_M6_SNOWBALLDRAW_NUM' : 'LB_M6_NEXTDRAW_NUM'
    let drawDt = ['', ''];
    let dateOfWeek = '(-)';
    let nextDrawTime = '-';
    let inv = ' ';
    let drawNum = ' ';
    if (draw?.no) {
      let dNo = String(draw?.no).padStart(3, '0');
      const drawId = `${draw?.year.substring(2, 4)}/${isSB ? (context.isMergeDraw ? `${dNo} ${draw?.snowballCode}` : draw?.snowballCode) : dNo}`;
      const sbName = draw["snowballName_" + i18n.language];
      drawNum = `${drawId}${isSB ? ` ${sbName}` : ''}`;
      if (draw?.drawDate != null && draw?.drawDate != '') {
        drawDt = formatDateTime(draw?.drawDate.substring(0, 10));
        dateOfWeek = '(' + getDayOfWeek(draw?.drawDate, t) + ')';
      }
      if (draw?.closeDate != null && draw?.closeDate != '') {
        nextDrawTime = get12HourTime(draw?.closeDate.substring(11, 16), t);
      }
      if (draw?.lotteryPool?.totalInvestment && draw?.lotteryPool?.totalInvestment > 0)
        inv = "$" + parseInt(draw?.lotteryPool.totalInvestment).toLocaleString();
    }
    return <div className='next-draw-table-r next-draw-table-l'>
      <div className='next-draw-table-item df'><div>{t(nextNumber)}</div><div>{drawNum}</div></div>
      <div className='next-draw-table-item next-draw-table-item-grey-bg df'><div>{t('LB_M6_DRAWDATE')}</div><div style={{ wordBreak: "keep-all" }}>{drawDt[0]} <wbr />{dateOfWeek}</div></div>
      <div className='next-draw-table-item df'><div>{t('LB_M6_STOPTIME')}</div><div>{nextDrawTime}</div></div>
      <div className='next-draw-table-item next-draw-table-item-grey-bg next-draw-table-item-no-bottom-boreder df'><div>{t('LB_M6_TURNOVER')}</div><div>{inv}</div></div>
    </div>
  }

  const TableR = () => {
    let estdiv = '-';
    if (draw?.lotteryPool?.derivedFirstPrizeDiv && draw?.lotteryPool?.derivedFirstPrizeDiv > 0)
      estdiv = "$" + parseInt(draw?.lotteryPool.derivedFirstPrizeDiv).toLocaleString();
    let jpt = '-';
    if (draw?.lotteryPool?.jackpot && draw?.lotteryPool?.jackpot > 0)
      jpt = "$" + parseInt(draw?.lotteryPool.jackpot).toLocaleString();
    return <div className='next-draw-table-r next-draw-table-estAmount'>
      <div className='jackpot-row'>
        <div>{t('LB_M6_JACKPOT')}</div>
        <div className='next-draw-table-item-red-type'>{jpt}</div>
      </div>
      <div className='estdiv-row'>
        <div className='estLabel'>{t('LB_M6_EST_DIV')}</div>
        <div className='next-draw-table-item-red-type estFontSize'>{estdiv}</div>
      </div>
    </div>
  }
  let curBetType = ['RANDOM', 'RANDOMSNOWBALL'].includes(pageType) && ['MULTIPLE', 'BANKER'].includes(activeType) ? activeType : pageType;
  const headerTitle = pageType == 'HOME' ? 'SINGLE' : pageType;
  if (pageType === 'MULTIPLESNOWBALL') {
    curBetType = 'MULTIPLE'
  }
  if (pageType === 'BANKERSNOWBALL') {
    curBetType = 'BANKER'
  }
  return <div className='next-draw-table-container'>
    {isMobileSize ?
      <header >{t(isSB ? "LB_M6_SNOWBALLDRAW" : 'LB_M6_NEXTDRAW')}</header>
      :
      <header className="df marksix-header">
        <div className="df">
          {t(`LB_M6_${headerTitle}`)}
          {isSB ? ` (${t('LB_M6_SNOWBALL')})` : ''}
          <div className="marksix-help" title={t('LB_M6_HELP')} onClick={() => { handleClickHelpLink(i18n.language) }} />
        </div>
        {(['MULTIPLE', 'BANKER', 'MULTIPLESNOWBALL', 'BANKERSNOWBALL'].includes(pageType) || ['MULTIPLE', 'BANKER'].includes(activeType)) && (
          <div className="df marsix-chance-help" onClick={() => { handleClickChanceTableLink(i18n.language) }}>
            <i className="icon-table-white" />
            <div>{t(`LB_M6_HEADER_CHANCE_TABLE_${curBetType}`)}</div>
          </div>
        )}
      </header>
    }
    {context?.gqlVars?.loading ? <div className='next-draw-table next-draw-noData'>
      <Loading />
    </div>
      :
      prevDraw?.id ? <div className='next-draw-table next-draw-table-header'>
        <TableL />
        <TableR />
      </div>
        :
        <div className='next-draw-table next-draw-noData'>
          <div className='noInfoTip'>
            {t('LB_RC_JTC_NOINFO')}
          </div>
        </div>
    }
  </div>
}

export default NextDrawInfoTable