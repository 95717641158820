
export const getQueryId = (betBasketsRef) => {
    let matchIds=[],tournIds=[],meetingList=[];
    betBasketsRef.current?.forEach((list,index) =>{
        list.forEach(item=>{
            const {prod,betObj}=item;
            let queryIds=[];
            let singleId='';
            if(prod=='FB'){
                if(betObj.betType == "ALUP"){
                    betObj.betList.forEach(betLine=>{
                        let betLineId='';
                        if(betLine.matchInfo){
                            betLineId=betLine.matchInfo.id;
                            matchIds.push(betLineId);
                        }else if(betLine.tournInfo){
                            betLineId=betLine.tournInfo.id;
                            tournIds.push(betLineId);
                        }
                        queryIds.push(betLineId);
                    })
                }else{
                    if(betObj.matchInfo){
                        singleId=betObj.matchInfo.id;
                        matchIds.push(singleId);
                        queryIds.push(singleId);
                    } 
                    if(betObj.tournInfo){
                       singleId=betObj.tournInfo.id;
                       tournIds.push(singleId);
                       queryIds.push(singleId);
                    }
                }
            }
            if(prod=='HR'){
                // WIP
            }
            item.queryIds=queryIds;
        })
    })
    return {
        matchIds:[...new Set(matchIds)].sort(),
        tournIds:[...new Set(tournIds)].sort(),
        meetingList:[...new Set(meetingList)]
    }
}