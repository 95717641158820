import { toLocaleLang } from '../Common/home-common';
const { v4: uuidv4 } = require('uuid');

// Login api start
export const initSsoAPI = async () => {
    //await logout(); //logout first to clear cookies
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/authtree/authenticate?authIndexType=service&authIndexValue=hkjcSSOPassword&ForceAuth=true`;
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Accept-API-Version': 'resource=2.0,protocol=1.0'
        },
        credentials: 'include'
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const initSsoIbAPI = async () => {
    //Update customer back to low level login
    //await logoutSsoHighIB();

    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/SSOService.svc/DoCheckSSOSignInStatusTR`;
    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: `{"knownWebID":"","knownSSOGUID":"","isNew":true}`
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
};

export const logoutSsoHighIB = async () => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Logout.svc/DoLogoutSSOHighTR`;

    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: `{"knownWebID":"","knownSSOGUID":"","isNew":true}`
    })
        .then()
        .catch((err) => {
            console.error(err);
        });
};

export const initLoginAPI = async () => {
    await logout(); //logout first to clear cookies
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/authtree/authenticate?authIndexType=service&authIndexValue=hkjcSSOPassword&ForceAuth=true`;
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Accept-API-Version': 'resource=2.0,protocol=1.0'
        },
        credentials: 'include'
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const checkAccountPasswordAPI = async (data) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/authtree/authenticate?authIndexType=service&authIndexValue=hkjcSSOPassword&ForceAuth=true`;
    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutPromise = (timeout) => {
        return new Promise((resolve, rejiect) => {
            setTimeout(() => {
                resolve({ code: 416 });
                controller.abort();
            }, timeout);
        });
    };

    const requestPromise = () => {
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            credentials: 'include',
            signal: signal
        }).then((res) => {
            return res.json();
        });
    };

    return await Promise.race([timeoutPromise(window.globalConfig.API_REQUEST_TIMEOUT), requestPromise()])
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const initEkbaAPI = async (accessToken) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/authtree/authenticate?authIndexType=service&authIndexValue=hkjcSSOEkba&ForceAuth=true`;

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Accept-API-Version': 'resource=2.0, protocol=1.0',
            'Access-Token': accessToken
        }
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
};

export const checkEKBAAnswerAPI = async (accessToken, data) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/authtree/authenticate?authIndexType=service&authIndexValue=hkjcSSOEkba&ForceAuth=true`;

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept-API-Version': 'resource=2.0,protocol=1.0',
            'Access-Token': accessToken
        },
        body: JSON.stringify(data),
        credentials: 'include'
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
};

export const getAuthorize = async (tokenId, clientId = 'JCBW') => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/auth/authorize`;
    let bodyValue = '';
    switch (clientId) {
        case 'JCBW_LS':
            bodyValue = `scope=ls_profile hkjcid openid ls_txn_ewallet ls_txn_eftservice ls_txn_session jcbw&client_id=${clientId}&csrf=${tokenId}&response_type=code&redirect_uri=${window.globalConfig.SITE_DOMAIN}&decision=allow`;
            break;
        case 'JCBW_EHS':
            bodyValue = `scope=ls_profile hs_profile hkjcid openid txn_gp7 txn_gp6 ls_txn_ewallet hs_txn_ewallet ls_txn_eftservice ls_txn_session hs_messaging ehs_profile jcbw&client_id=${clientId}&csrf=${tokenId}&response_type=code&redirect_uri=${window.globalConfig.SITE_DOMAIN}&decision=allow`;
            break;
        case 'JCBW':
        default:
            //bodyValue = `scope=ls_profile hs_profile hkjcid openid txn_gp7 txn_gp6 ls_txn_ewallet hs_txn_ewallet ls_txn_eftservice ls_txn_session jcbw&client_id=${clientId}&csrf=${tokenId}&response_type=code&redirect_uri=${window.globalConfig.SITE_DOMAIN}&decision=allow`;
            bodyValue = `scope=ls_profile hs_profile hkjcid openid txn_gp7 txn_gp6 ls_txn_ewallet hs_txn_ewallet ls_txn_eftservice ls_txn_session hs_messaging jcbw&client_id=${clientId}&csrf=${tokenId}&response_type=code&redirect_uri=${window.globalConfig.SITE_DOMAIN}&decision=allow`;
            break;
    }

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        redirect: 'follow',
        body: bodyValue
    })
        .then((res) => {
            return res; //.json();
        })
        .catch((err) => {
            console.info('Authorize err', err);
            return err;
        });
};

export const getAccessToken = async (code, clientId = 'JCBW') => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/auth/access_token`;
    const accCode = () => {
        switch (clientId) {
            case 'JCBW_LS':
                return `Basic ${window.btoa('JCBW_LS:00000000')}`;
            case 'JCBW_EHS':
                return `Basic ${window.btoa('JCBW_EHS:00000000')}`;
            case 'JCBW':
            default:
                return `Basic ${window.btoa('JCBW:00000000')}`;
        }
    };
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: accCode()
        },
        credentials: 'include',
        body: `grant_type=authorization_code&code=${code}&redirect_uri=${window.globalConfig.SITE_DOMAIN}`
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
};

export const renewAccessToken = async (refreshToken, clientId = 'JCBW') => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/auth/access_token`;
    const accCode = () => {
        switch (clientId) {
            case 'JCBW_LS':
                return `Basic ${window.btoa('JCBW_LS:00000000')}`;
            case 'JCBW_EHS':
                return `Basic ${window.btoa('JCBW_EHS:00000000')}`;
            case 'JCBW':
            default:
                return `Basic ${window.btoa('JCBW:00000000')}`;
        }
    };
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: accCode()
        },
        credentials: 'include',
        body: `grant_type=refresh_token&refresh_token=${refreshToken}`
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
};

export const renewSSO = async (accessToken) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/ciam/sso/v1/protected/wcip`;
    return await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        body: `{"ticketType": "both"}`
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
};

export const getUserInfo = async (access_token) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/ciam/customers/profiledetails?acctType=BET,WEB&accountDetails=Basic,KbInfo,Contact`;
    return await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${access_token}`
        }
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

export const getUserInfoAdditional = async (access_token, webAccount) => {
    const uuid = uuidv4();
    const url = `${window.globalConfig.LOGIN_SERVICE_SICA_URL}/ciam/sica/v1/profile/${webAccount}`;
    return await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${access_token}`,
            'Trace-Id': uuid
        }
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

// Login api end

// send bet Api start

export const getSessionId = async (accessToken, IS_DEBUG) => {
    let url = '';
    if (IS_DEBUG) {
        url = `${
            window.globalConfig.EFT_URL
        }/sp22/sessionmanagement/v100/session?deviceType=11&siteInfo=${getSiteInfo()}&isEWalletLogin=false&longitude=114&latitude=22&network=W`;
    } else {
        url = `${
            window.globalConfig.TRANSACTION_URL
        }/sp22/sessionmanagement/v100/session?deviceType=17&siteInfo=${getSiteInfo()}&isEWalletLogin=false&longitude=114&latitude=22&network=W`;
    }
    return await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })
        .then((res) => {
            if (!res.ok && res.status == 401) {
                sessionStorage.setItem('access_token', '');
                sessionStorage.setItem('refresh_token', '');
            }
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
};

export const revokeSession = async (accessToken) => {
    let url = `${
        window.globalConfig.TRANSACTION_URL
    }/sp22/sessionmanagement/v101/revokeSession?deviceType=17&siteInfo=${getSiteInfo()}&isEWalletLogin=false&longitude=114&latitude=22&network=W`;
    return await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

export const getBalance = async ({ sessionData, accessToken }) => {
    const url = `${
        window.globalConfig.TRANSACTION_URL
    }/sp22/sessionmanagement/v100/account/balance/?deviceType=17&siteInfo=${getSiteInfo()}&sessionId=${
        sessionData.sessionId
    }&timestampId=${sessionData.timestampId}`;
    return await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

export const sendBetApi = async ({ sessionData, bets, accessToken, language }) => {
    //const url = `${window.globalConfig.TRANSACTION_URL}/wagering/account/v4/ticket/?deviceType=11&siteInfo=${getSiteInfo()}&sessionId=${sessionData.sessionId}&timestampId=${sessionData.timestampId}&language=${toLocaleLang(language)}`;
    const url = `${
        window.globalConfig.TRANSACTION_URL
    }/sp22/sessionmanagement/v100/account/placebet/racing?deviceType=11&sessionId=${
        sessionData.sessionId
    }&timestampId=${sessionData.timestampId}&language=${toLocaleLang(language)}&siteInfo=${getSiteInfo()}`;
    const body = JSON.stringify({
        bets
    });
    return await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        body
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

// send bet Api end

// account records api start

export const getStatment = async (sessionData, accessToken, params) => {
    const language = toLocaleLang(params.language);
    let others = ``;
    if (params.displayType === '0') {
        others += '&ticketWithPayout=true';
    }
    switch (params.transType) {
        case '0':
            others += '&racingTicket=true&noveltyTicket=true&cancellation=true&otherTransactions=true';
            break;
        case '1':
            others += '&footballTicket=true&cancellation=true&otherTransactions=true';
            break;
        case '2':
            others += '&lotteryTicket=true&cancellation=true&otherTransactions=true';
            break;
        case '3':
            others = '&withdrawalsTicket=true&depositsTicket=true&otherTransactions=true';
            break;
        default:
            break;
    }

    if (params.nextPage) {
        others += `&continuationDate=${params.continuationDate}&continuationKey=${params.continuationKey}`;
    }
    let url = '';
    if (window.globalConfig.IS_DEBUG) {
        //hardcode request url for txn dev
        if (params.transType == '0') {
            url = `${
                window.globalConfig.STATEMENT_URL
            }/wagering/account/v4/statement/?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${
                sessionData.sessionId
            }&timestampId=${
                sessionData.timestampId
            }&startDate=20210921&dayCount=1&numberOfTransaction=100&horsePerLine=${
                params.horsePerLine
            }&language=${language}${others}`;
        } else {
            url = `${
                window.globalConfig.STATEMENT_URL
            }/wagering/account/v4/statement/?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${
                sessionData.sessionId
            }&timestampId=${sessionData.timestampId}&startDate=20210328&dayCount=900&numberOfTransaction=${
                params.numberOfTransaction
            }&horsePerLine=${params.horsePerLine}&language=${language}${others}`;
        }
    } else {
        url = `${
            window.globalConfig.STATEMENT_URL
        }/sp22/sessionmanagement/v100/account/statement?deviceType=17&siteInfo=${getSiteInfo()}&sessionId=${
            sessionData.sessionId
        }&timestampId=${sessionData.timestampId}&startDate=${params.startDate}&dayCount=${
            params.dayCount
        }&numberOfTransaction=${params.numberOfTransaction}&horsePerLine=${
            params.horsePerLine
        }&language=${language}${others}`;
    }
    return await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

export const getStatementRecall = async (sessionData, accessToken, params) => {
    const language = toLocaleLang(params.language);

    let others = ``;

    if (params.nextPage) {
        others += `&continuationId=${params.continuationId}`;
    }

    const url = `${
        window.globalConfig.STATEMENT_URL
    }/sp22/sessionmanagement/v100/account/recall?deviceType=17&siteInfo=${getSiteInfo()}&transactionNumber=${
        params.numberOfTransaction
    }&sessionId=${sessionData.sessionId}&timestampId=${sessionData.timestampId}&horsePerLine=${
        params.horsePerLine
    }&language=${language}${others}`;

    return await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

// account records api end

export const logout = async (accessToken) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/ciam/sso/v1/public/logout`;
    return await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Accept-API-Version': 'resource=3.1,protocol=1.0'
        }
    })
        .then()
        .catch((err) => {
            console.error(err);
        });
};

export const getSiteInfo = () => {
    if (window.globalConfig.IS_SPEEDBET) {
        return 'SPEEDBET';
    } else if (window.globalConfig.IS_EWIN2) {
        return 'EWIN2';
    } else if (window.globalConfig.IS_PREVIEW) {
        return 'PREVIEW';
    } else {
        return 'MASS';
    }
};
