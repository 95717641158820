import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { SingleMatchTable } from '../Common/MatchTable';
import { MatchInfo } from '../Common/ResultsBase';
import { MatchDropdown, Remark, isRefundSuspened } from '../Common/MatchBase';
import FootballTitle from '../Common/FootballTitle';
import { useQuery } from '@apollo/client';
import { getLastOddsQuery } from '../Data/DataQuery';
import { toDateFormat } from '../Common/Common';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const LastOdds = ({ match, matchList, isInProgress, onClickBackBtn, onClickResultDropdown }) => {

    const { t } = useTranslation();
    const context = useContext(FootballContext);
    const lastOddsOrder = context.content.config.LastOddsOrder;
    const extraTimePools = context.content.config.ExtraTimePools;
    const isNTSPools = context.content.config.NTSPools;
    const [apiErr,setApiErr]=useState(false);

    const lastOddsGqlVars = useQuery(getLastOddsQuery(), {
        variables: { matchId: match.id },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: res => {
            setApiErr(false);
        },
        onError:err=>{
            setApiErr(true);
        }
    });

    const getLastOddsTime = () => {
        if (lastOddsGqlVars?.data?.matches?.length > 0 ) {
            let dt = new Date(lastOddsGqlVars.data.matches[0].updateAt)
            lastOddsGqlVars.data.matches[0].foPools.forEach(foPool => {
                let tmpDt = new Date(foPool.updateAt)
                if ( dt < tmpDt ) {
                    dt = tmpDt;
                }
            });
            return toDateFormat(dt, true, true);
        }
        return null;
    }

    

    const onSelectDropdown = (matchId) => {
        onClickResultDropdown(matchId, isInProgress);
    }

    let lastRefTime = getLastOddsTime();

    if(apiErr){
        return <div className='noInfoTip'>{t('LB_RC_JTC_NOINFO')}</div>
    }
    const allMatchProps = {lastOddsGqlVars, lastOddsOrder, isNTSPools, match, extraTimePools};

    return <div className="fb-result-detail-container">
        <header className='fb-result-detail-header'>
            <FootballTitle title={t('LB_FB_RESULT_LAST_ODDS')} isPoolDefined={true} timeStr={lastRefTime}/>
        </header>

        <div className='mobile-top-title'>
            <ArrowIcon className='hueRotate' onClick={onClickBackBtn} direction='left'  size={24} />
            <div>{t('LB_FB_RESULT_LAST_ODDS')}</div>
            <div className="lastodds-refresh-time">
                <div>{t('LB_RC_REFRESH_AT')}</div>
                <div>{lastRefTime}</div>
            </div>
        </div>
        <div className='top-header-container'>
            <div className='back-btn' onClick={onClickBackBtn}>
                <ArrowIcon className='' direction='left'  size={24} />
                <span>{t('LB_FB_PRE_PAGE')}</span>
            </div>
            <div className='ddNoRSpace'>
                <MatchDropdown singleMatch={match} matchList={matchList} onClickResultDropdown={onSelectDropdown} />
            </div>
        </div>
        <section className='LAST_ODDS'>
            <MatchInfo match={match} isResultPage={true}/>
            <AllMatchTable {...allMatchProps}/>
        </section>

    </div>
}

export default LastOdds;

const AllMatchTable = ({lastOddsGqlVars, lastOddsOrder, isNTSPools, match, extraTimePools}) => {
        if (lastOddsGqlVars?.data?.matches?.length > 0 ) {
            let foPools = lastOddsGqlVars?.data?.matches[0].foPools;
            return lastOddsOrder.map(betType => {
                let definedPools = foPools.filter(x=>x.oddsType===betType);
                definedPools = definedPools.map(pool => {
                    if(!pool.closedLines || pool.closedLines.length == 0){
                        pool.closedLines = pool.lines
                    }
                    return pool
                })
                if ( definedPools.length>0 ) {
                    let isNTSPool = isNTSPools.includes(betType);
                    let tmpMatch = {...match};
                    let isVoidMatch = tmpMatch.status.toUpperCase()=="MATCHVOIDED"
                    tmpMatch.foPools = definedPools;
                    if ( !isVoidMatch && definedPools[0].inplay && !extraTimePools.includes(betType) && !isNTSPool ) {
                        return <>
                            <SingleMatchTable betType={betType} match={tmpMatch} foPool={definedPools[0]} isCloseLine={true} showInplayLabel={false} />
                            <SingleMatchTable betType={betType} match={tmpMatch} foPool={definedPools[0]} isCloseLine={false} showInplayLabel={true} />
                        </>
                    }
                    else {
                        let showLabel = isNTSPool || extraTimePools.includes(betType);
                        let isCloseLine = (isVoidMatch || showLabel || definedPools[0]?.closedLines?.length == 0) ? false : true
                        return <SingleMatchTable betType={betType} match={tmpMatch} foPool={definedPools[0]} isCloseLine={isCloseLine} showInplayLabel={showLabel} />
                    }
                }
            })
        }
        return null;
    }