import React from 'react';
import CashOutFootball from './CashOutFootball'
import { CashOutNoData } from './CashOutBase';

const CashOutMain = (props) => {
    const { cashoutData, esValues, curTab, jumpUrl, esApiError, isShowAllupTab,singleBetData,allupBetData, liveBetData, winningBetData } = props;
    
    const filterData = () => {
        switch ( curTab ) {
            case "LIVE":
                return liveBetData;
            case "SINGLE":
                return singleBetData;
            case "ALLUP":
                return allupBetData;
            case "WINNING":
                return winningBetData
        }
        return cashoutData;
    }

    return filterData().length > 0 ? <div className='cashOutMain'>
        {
            filterData().map(item => {
                let esValue = esValues.filter(x=> x.betId===item.uniqueTicketId)[0];
                const isMoreButton = item.legs.length > 3
                if(esValue?.status == "BET_SOLD"){
                    return null
                }
                return <CashOutFootball {...props} data={item} esValue={esValue} key={`${item.accountTranNo}`} isMoreButton={isMoreButton} />
            })
        }
    </div>
        :
        <CashOutNoData {...props} />;
}

export default CashOutMain