import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewWindow } from '../../Common/home-common';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import './RelatedMenu.scss';

const RelatedMenu = ({ isMobileSize }) => {
    const { t, i18n } = useTranslation();
    const clickTopMenuLink = (idx) => {
        let url = t('URL_' + idx);
        NewWindow(url, '', 770, 500, 1, 1);
    };

    return (
        <nav className={`RelatedMenu ${isMobileSize ? 'RelatedMenu-mobile' : ''}`}>
            <a aria-label={t('LB_HOME')} className="RelatedMenu-item" onClick={() => clickTopMenuLink('HKJC')}>
                {t('LB_HOME')}
            </a>
            <a
                aria-label={t('LB_RACING_AND_ENTERTAINMENT')}
                className="RelatedMenu-item"
                id="fMenuRC"
                onClick={() => clickTopMenuLink('JCEW')}
            >
                {t('LB_RACING_AND_ENTERTAINMENT')}
            </a>
            <a
                aria-label={t('LB_HORSE_RACING')}
                className="RelatedMenu-item"
                id="fMenuRW"
                onClick={() => {
                    clickTopMenuLink('JCRW');
                    glassboxCustomEvent('To_JCRW', 'Horse_Racing');
                }}
            >
                {t('LB_HORSE_RACING')}
            </a>
            <a
                aria-label={t('LB_FOOTBALL')}
                className="RelatedMenu-item"
                id="fMenuFB"
                onClick={() => clickTopMenuLink('JCFW')}
            >
                {t('LB_FOOTBALL')}
            </a>
            <a
                aria-label={t('LB_MEMBERSHIP')}
                className="RelatedMenu-item"
                id="fMenuMEM"
                onClick={() => clickTopMenuLink('MEM')}
            >
                {t('LB_MEMBERSHIP')}
            </a>
            <a
                aria-label={t('LB_CHARITIES_AND_Community')}
                className="RelatedMenu-item"
                id="fMenuCHA"
                onClick={() => clickTopMenuLink('CHA')}
            >
                {t('LB_CHARITIES_AND_Community')}
            </a>
            <a
                aria-label={t('LB_ABOUT_HKJC')}
                className="RelatedMenu-item"
                id="fMenuABOUT"
                onClick={() => clickTopMenuLink('CORP')}
            >
                {t('LB_ABOUT_HKJC')}
            </a>
        </nav>
    );
};

export default RelatedMenu;
