
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSiteCoreImagePath } from '../../../../Common/home-common';
import { handleClickMenuItem } from '../../../../Menu/MenuCommon';
import { scrollTo } from '../../../../Common/GlobalFunc';

const FPSBanner = ({fpsBannerQuery}) => {
    const { i18n }  = useTranslation();
    const [ loading, data ] = fpsBannerQuery;
    const navigate = useNavigate();

    const goToLink = (url) => {
        scrollTo(0, 0);
        navigate(url);
    }

    if ( loading || data?.itemEnable?.value!=="1" ) {
        return null;
    }
    return <div className="bannerBox">
        {data.itemRedirection.value !== "" ?
            <a href="#" onClick={(event)=> { handleClickMenuItem(event, data, goToLink) }}>
                <img src={getSiteCoreImagePath(data.image.src, i18n.language)} width={480} height={80} title={data.image.alt} />
            </a>
            : <img src={getSiteCoreImagePath(data.image.src, i18n.language)} width={480} height={80} title={data.image.alt} />}
    </div>
}

export default FPSBanner