import React, {useEffect, useState} from 'react'
import {isParentNode } from '../BetSlipBase'
import {getPersonalSettings} from '../../Home/PersonalSettings/config'

export const useExpend = ({isShowConfirmation,isShowPreviewInfo}) => {
    const [isExpended, setIsExpended] = useState(false)

    useEffect(()=>{
        if(isShowConfirmation || isShowPreviewInfo || parseInt(getPersonalSettings('showBetlineDetail')) === 1){
            setIsExpended(true)
        }else{
            setIsExpended(false)
        }
    },[isShowPreviewInfo,isShowConfirmation])

    const handleClickExpendBox =(e, ignoreClassList) => {

        const canSwitch = isParentNode(ignoreClassList, e.target)
        if(canSwitch || isShowConfirmation || isShowPreviewInfo) return
        setIsExpended(!isExpended)
    }


    return {
        isExpended,
        handleClickExpendBox
    }
}