import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect,useState } from 'react';

const QTTQuickPick = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="qtt-qp-button-set">
        <div className="method-title">{t('LB_RC_ODDS_QTTQP')}</div>
        <div><div className='randomBtn' onClick={()=> { props.handleQTTRandom(4, 24) }}>4</div></div>
        <div><div className='randomBtn' onClick={()=> { props.handleQTTRandom(5, 120) }}>5</div></div>
        <div><div className='randomBtn' onClick={()=> { props.handleQTTRandom(6, 360) }}>6</div></div>
    </div>);
}
export default QTTQuickPick