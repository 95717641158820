import React, { useContext } from 'react';
import NextDrawInfoTable from '../Common/NextDrawInfoTable';
import LastDrawInfoTable from '../Common/LastDrawInfoTable';
import SingleSelectedNumTable from '../Common/SingleSelectedNumTable'
import MyFavourites from '../Common/MyFavourites';
import { MarksixContext } from '../../Home/MarksixPage';

const Single = () => {
    const context = useContext(MarksixContext);
    const showLastDraw = context.pageName === "HOME";

    return (
        <section className={context.pageName}>
            <NextDrawInfoTable />
            <SingleSelectedNumTable />
            <MyFavourites />
            {showLastDraw && <LastDrawInfoTable />}
        </section>
    );
}
export default Single;
