import React from 'react';
import { gql } from '@apollo/client';

const getRaceFragment = () => {
  return gql`fragment raceFragment on Race {
    id
    no
    status
    raceName_en
    raceName_ch
    postTime
    country_en
    country_ch
    distance
    wageringFieldSize
    go_en
    go_ch
    ratingType
    raceTrack {
      description_en
      description_ch
    }
    raceCourse {
      description_en
      description_ch
      displayCode
    }
    raceClass_en
    raceClass_ch
    judgeSigns {
      value_en
    }
  }`
}

const getRacingBlockFragment = () => {
  return gql`fragment racingBlockFragment on RaceMeeting {
    jpEsts: pmPools(oddsTypes: [TCE,TRI,FF,QTT,DT,TT,SixUP], filters: ["jackpot", "estimatedDividend"]) {
      leg {
        number
        races
      }
      oddsType
      jackpot
      estimatedDividend
      mergedPoolId
    }
    poolInvs: pmPools(oddsTypes: [WIN,PLA,QIN,QPL,CWA,CWB,CWC,IWN,FCT,TCE,TRI,FF,QTT,DBL,TBL,DT,TT,SixUP]) {
      id
      leg {
        races
      }
    }
    penetrometerReadings(filters:["first"]) {
      reading
      readingTime
    }
    hammerReadings(filters:["first"]) {
      reading
      readingTime
    }
    changeHistories(filters:["top3"]) {
      type
      time
      raceNo
      runnerNo
      horseName_ch
      horseName_en
      jockeyName_ch
      jockeyName_en
      scratchHorseName_ch
      scratchHorseName_en
      handicapWeight
      scrResvIndicator
    }
  }`
}

const getRacingFoPoolFragment = () => {
  return gql`fragment racingFoPoolFragment on RacingFoPool {
    instNo
    poolId
    oddsType
    status
    sellStatus
    otherSelNo
    inplayUpTo
    expStartDateTime
    expStopDateTime
    raceStopSellNo
    raceStopSellStatus
    includeRaces
    excludeRaces
    lastUpdateTime
    selections {
      order
      number
      code
      name_en
      name_ch
      scheduleRides
      remainingRides
      points
      lineId
      combId
      combStatus
      openOdds
      prevOdds
      currentOdds
      results {
        raceNo
        points
        point1st
        point2nd
        point3rd
        dhRmk1st
        dhRmk2nd
        dhRmk3rd
        count1st
        count2nd
        count3rd
        count4th
        numerator4th
        denominator4th
      }
    }
    otherSelections {
      order
      code
      name_en
      name_ch
      scheduleRides
      remainingRides
      points
      results {
        raceNo
        points
        point1st
        point2nd
        point3rd
        dhRmk1st
        dhRmk2nd
        dhRmk3rd
        count1st
        count2nd
        count3rd
        count4th
        numerator4th
        denominator4th
      }
    }
  }`
}

export function getMeetingListQuery() {
  return gql`
    query racing {
        raceMeetings
        {
          id
          venueCode
          date
          status
        }
    }
  `;
}


export function getRacingQuery() {
    return gql`
        ${getRaceFragment()}
        ${getRacingBlockFragment()}
        query raceMeetings($date: String, $venueCode: String) {
            timeOffset {
              rc
            }
            activeMeetings : raceMeetings
            {
              id
              venueCode
              date
              status
              races {
                no
                postTime
                status
                wageringFieldSize
              }
            }
            raceMeetings(date: $date, venueCode: $venueCode)
            {
              id
              status
              venueCode
              date
              totalNumberOfRace
              currentNumberOfRace
              dateOfWeek
              meetingType
              totalInvestment
              country {
                code
                namech
                nameen
                seq
              }
              races {
                ...raceFragment
                runners {
                    id
                    no
                    standbyNo
                    status
                    name_ch
                    name_en
                    horse {
                      id
                      code
                    }
                    color
                    barrierDrawNumber
                    handicapWeight
                    currentWeight
                    currentRating
                    internationalRating
                    gearInfo
                    racingColorFileName
                    allowance
                    trainerPreference
                    last6run
                    saddleClothNo
                    trumpCard
                    priority
                    finalPosition
                    deadHeat
                    winOdds
                    jockey {
                      code
                      name_en
                      name_ch
                    }
                    trainer {
                      code
                      name_en
                      name_ch
                    }
                }
              }
              obSt: pmPools(oddsTypes: [WIN, PLA]) {
                leg {
                  races
                }
                oddsType
                comingleStatus
              }
              poolInvs: pmPools(oddsTypes: [WIN,PLA,QIN,QPL,CWA,CWB,CWC,IWN,FCT,TCE,TRI,FF,QTT,DBL,TBL,DT,TT,SixUP]) {
                id
                leg {
                  number
                  races
                }
                status
                sellStatus
                oddsType
                investment
                mergedPoolId
                lastUpdateTime
              }
              ...racingBlockFragment
              pmPools(oddsTypes: []) {
                id
              }
              jkcInstNo : foPools(oddsTypes: [JKC], filters: ["top"]) {
                instNo
              }
              tncInstNo : foPools(oddsTypes: [TNC], filters: ["top"]) {
                instNo
              }
            }
        }
    `;
}

export function getResultsPageMeeting () {
  return gql`
  ${getRacingFoPoolFragment()}
  query resultMeetings($date: String, $venueCode: String, $foOddsTypes: [OddsType], $foFilter: [String], $resultOddsType: [OddsType]) {
      raceMeetings(date: $date, venueCode: $venueCode)
      {
        id
        resPools: pmPools(oddsTypes: $resultOddsType) {
          leg {
            number
            races
          }
          status
          oddsType
          name_en
          name_ch
          lastUpdateTime
          dividends (officialOnly: true) {
            winComb
            type
            div
            seq
            status
            guarantee
            partial
            partialUnit
          }
          cWinSelections {
            composite
            name_ch
            name_en
            starters
          }
        }
        foPools(oddsTypes: $foOddsTypes, filters: $foFilter) {
          ...racingFoPoolFragment
        }
      }
  }
`;
}

export function getReloadRacingQuery() {
  return gql`
      query racing($date: String, $venueCode: String) {
          raceMeetings(date: $date, venueCode: $venueCode)
          {
            status
            currentNumberOfRace
            totalInvestment
            races {
              no
              status
              runners {
                  id
                  status
              }
            }
          }
      }
  `;
}

export function getFoPoolQuery() {
  return gql`
      ${getRacingFoPoolFragment()}
      query racing($date: String, $venueCode: String, $foOddsTypes: [OddsType]) {
          raceMeetings(date: $date, venueCode: $venueCode)
          {
            foPools(oddsTypes: $foOddsTypes, filters: ["top"]) {
              ...racingFoPoolFragment
            }
          }
      }
  `;
}

export function getPmPoolQuery() {
  return gql`
      query racing($date: String, $venueCode: String, $oddsTypes: [OddsType], $raceNo: Int) {
          raceMeetings(date: $date, venueCode: $venueCode)
          {
            pmPools(oddsTypes: $oddsTypes, raceNo: $raceNo) {
              id
              status
              sellStatus
              oddsType
              lastUpdateTime
              guarantee
              minTicketCost
              name_en
              name_ch
              leg {
                number
                races
              }
              cWinSelections {
                composite
                name_ch
                name_en
                starters
              }
              oddsNodes {
                combString
                oddsValue
                hotFavourite
                oddsDropValue
                bankerOdds {
                  combString
                  oddsValue
                }
              }
            }
          }
      }
  `;
}

export function getPoolInvQuery() {
  return gql`
      query racing($date: String, $venueCode: String, $oddsTypes: [OddsType], $raceNo: Int) {
          raceMeetings(date: $date, venueCode: $venueCode)
          {
            totalInvestment
            poolInvs: pmPools(oddsTypes: $oddsTypes, raceNo: $raceNo) {
              id
              leg {
                number
                races
              }
              status
              sellStatus
              oddsType
              investment
              mergedPoolId
              lastUpdateTime
            }
          }
      }
  `;
}

export function getRacingFoRaceByRaceQuery() {
  return gql`
      ${getRacingFoPoolFragment()}
      query racingFoRaceByRace($date: String, $venueCode: String, $foOddsTypes: [OddsType]) {
          raceMeetings(date: $date, venueCode: $venueCode)
          {
            id
            status
            venueCode
            date
            totalNumberOfRace
            currentNumberOfRace
            dateOfWeek
            foPools(oddsTypes: $foOddsTypes) {
              ...racingFoPoolFragment
            }
          }
      }
  `;
}

export function getChangesQuery() {
  return gql`
    query racingChanges {
      raceMeetings
      {
          id
          venueCode
          date
          changeHistories {
            type
            time
            raceNo
            runnerNo
            horseName_ch
            horseName_en
            jockeyName_ch
            jockeyName_en
            scratchHorseName_ch
            scratchHorseName_en
            handicapWeight
            scrResvIndicator

          }
      }
    }
  `;
}

export function getRBCMeetingListQuery() {
  return gql`
    query rbcList($startDate: String, $endDate: String) {
      raceMeetings(startDate: $startDate, endDate: $endDate, all: true)
      {
          date
          venueCode
      }
    }
  `;
}

export function getRBCMeetingQuery() {
  return gql`
    query rbcMeeting($date: String, $venueCode: String) {
      raceMeetings(all: false, date: $date, venueCode: $venueCode, rebateCalOnly: true)
      {
        date
        venueCode
        totalNumberOfRace
        status
        races {
            no
            status
            runners {
                no
                status
            }
        }
        pmPools(oddsTypes: [WIN, PLA, QIN, QPL, IWN, FCT, TCE, TRI, QTT, FF, DBL, TBL, DT, TT, SixUP,CWA, CWB, CWC]) {
            oddsType
            status
            rebateRate
            leg {
                number
                races
            }
            cWinSelections {
                composite
                starters
            }
            dividends {
                winComb
                div
                partialUnit
            }
        }
        cwInfo: pmPools(oddsTypes: [CWA, CWB, CWC]) {
          oddsType
          leg {
              races
          }
          oddsNodes {
            combString
            oddsValue
          }
        }
      }
    }
  `;
}

export function getRacingBlockQuery() {
  return gql`
      ${getRaceFragment()}
      ${getRacingBlockFragment()}
      query racingBlock {
          timeOffset {
            rc
          }
          raceMeetings
          {
            id
            status
            venueCode
            date
            totalNumberOfRace
            currentNumberOfRace
            dateOfWeek
            meetingType
            totalInvestment
            isSeasonLastMeeting
            races {
              ...raceFragment
              runners {
                  id
                  no
                  standbyNo
                  status
                  name_ch
                  name_en
                  horse {
                    id
                    code
                  }
              }
            }
            pmPools(oddsTypes: [TT]) {
              id
              leg {
                races
              }
              status
              sellStatus
              oddsType
              lastUpdateTime
            }
            ...racingBlockFragment
          }
      }
  `;
}

export function getJockeyTrainerRankingQuery() {
  return gql`
    query jockeyTrainerRanking {
      lastMeeting(localOnly: true) {
        date
      }
      jockeyStat {
        code
        name_ch
        name_en
        visitingIndex
      }
      trainerStat {
        code
        name_ch
        name_en
        visitingIndex
      }
    }
  `;
}

function getQueryFoOddsTypes(content) {
  let queryArr = [];
  switch (content.page) {
    case "JKC":
      queryArr = ["JKC"];
      break;
    case "TNC":
      queryArr = ["TNC"];
      break;
    case "RESULTS":
    case "HOME_RESULTS":
      queryArr = ["JKC", "TNC"];
      break;

  }
  return queryArr;
}

function getQueryResultOddsTypes(content) {
  let queryArr = [];
  switch (content.page) {
      case "RESULTS":
      case "HOME_RESULTS":
        queryArr = ["WIN","PLA","QIN","QPL","CWA","CWB","CWC","IWN","FCT","TCE","TRI","FF","QTT","DBL","TBL","DT","TT","SixUP"];
        break;
  }
  return queryArr;
}

export function getQueryVariables(content) {
  return { "date": content.date, "venueCode": content.venue, "raceNo": content.raceNo,
            "foOddsTypes": getQueryFoOddsTypes(content),
            "foFilter": ["RESULTS", "HOME_RESULTS"].includes(content.page) ? null : ["top"],
            "resultOddsType": getQueryResultOddsTypes(content) };
}