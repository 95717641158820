import moment from 'moment';
import { isSellingStarted, getCellId } from '../../Common/Common';
import { isMatchKickOff } from '../../Common/MatchBase';

export const setMatchDataOddsDiff = (contentRef, newMatches, params) => {
    let inplayOnly = params.inplayOnly 
    contentRef.current.matchData?.matches?.forEach(match =>{
        let rMatch = newMatches?.find(x=> x.id===match.id);
        let isInplayMatch = match.poolInfo?.inplayPools?.length > 0
        if ( !rMatch  ) {
            if(!inplayOnly  || (inplayOnly && isInplayMatch && match.poolInfo.inplayPools.includes(contentRef.betType))){
                contentRef.current.oddsSelection = contentRef.current.oddsSelection.map(item =>{
                    if(item.matchId == match.id ){
                        return {
                            ...item,
                            checked: false
                        }
                    }
                    return item
                })
            }
            return false
        };

        match.foPools?.forEach(pool=> {
            let rFoPool = rMatch.foPools.find(i => i.id == pool.id)
            setFoPoolOddsDiff(contentRef, rMatch, rFoPool, pool, inplayOnly);
        });
    });
}

export const setTournDataOddsDiff = (contentRef, newTourns) => {
    newTourns?.forEach(rTourn =>{
        let tourn = contentRef.current.tournData?.tournaments?.find(x=> x.id===rTourn.id);
        if ( !tourn ) {
            contentRef.current.oddsSelection = contentRef.current.oddsSelection.map(item =>{
                if(item.matchId == rTourn.id){
                    return {
                        ...item,
                        checked: false
                    }
                }
                return item
            })
            return false
        }else{
            tourn.foPools.forEach(pool => {
                let newPool = rTourn.foPools.find(p => p.id === pool.id)
                if(!newPool){
                    contentRef.current.oddsSelection = contentRef.current.oddsSelection.map(item =>{
                        let [bype, lineId, combId, poolId, str] = item.id.split('_')
                        if(poolId == pool.id){
                            return {
                                ...item,
                                checked: false
                            }
                        }
                        return item
                    })
                }
            })
        }

        tourn.foPools?.forEach(foPool=> {
            let rFoPool = rTourn.foPools.find(i => foPool.id == i.id)
            setFoPoolOddsDiff(contentRef, tourn, rFoPool, foPool, false);
        });
    });
}

const setFoPoolOddsDiff = (contentRef, parent, rFoPool, foPool, inplayOnly) => {
    let isKickOff = isMatchKickOff(parent.status)
    let poolInfo = parent.poolInfo
    if ( foPool &&  !rFoPool ) {
        let oddsType = foPool.instNo > 0 ? `${foPool.oddsType}${foPool.instNo}` : foPool.oddsType
        if(!poolInfo.normalPools.includes(oddsType)){
            foPool.lines.forEach(line =>{
                line.combinations.forEach(comb =>{
                    let id = getCellId(foPool, line, comb)
                    let selectionIdx = contentRef.current.oddsSelection.findIndex(x => x.id === id)
                    if( selectionIdx > -1){
                        contentRef.current.oddsSelection[selectionIdx].checked = false
                    }
                })
            })
        }
        contentRef.current.oddsSelection = [...contentRef.current.oddsSelection]
        return 
    }
    rFoPool.lines?.forEach(rLine => {
        let line = foPool?.lines.find(x=> x.lineId===rLine.lineId);
        if ( !line ) return false;
        rLine.combinations?.forEach(rComb => {
            let comb = line.combinations.find(x=> x.combId===rComb.combId);
            if ( !comb ) return false;

            let id = getCellId(rFoPool, rLine, rComb);
            let isSelling = isSellingStarted(parent, rFoPool, rLine) && rComb.status == 'AVAILABLE';
            let oldPoolSelling = isSellingStarted(parent, foPool, line) && comb?.status == 'AVAILABLE'

            let isStopSelltoStartSell = ((isKickOff && !oldPoolSelling) || ['PAYOUTSTARTED', 'SUSPENDED', 'PAYOUTSTOPPED'].includes(line.status)) && isSelling
            let oddsDiff =  isKickOff && !isSelling ? 0 : parseFloat(rComb.currentOdds) - parseFloat(comb.currentOdds);
            let idx = contentRef.current.oddsSelection.findIndex(x => x.id === id);
            let now = moment();
            if ( idx > -1 ) {
                if(isStopSelltoStartSell){
                    contentRef.current.oddsSelection[idx].oddsDiff = 0
                    contentRef.current.oddsSelection[idx].oddUpdTs = null
                }else if ( oddsDiff!=0 ) {
                    contentRef.current.oddsSelection[idx].oddsDiff = oddsDiff;
                    contentRef.current.oddsSelection[idx].oddUpdTs = now;
                }
                if( !isSelling ){
                    contentRef.current.oddsSelection[idx].checked = false
                }
            }
            else if(oddsDiff!=0){
                contentRef.current.oddsSelection.push({
                    id: id,
                    oddsDiff: oddsDiff,
                    oddUpdTs: now
                })
            }
           
        });
        if(['CRS','FCS'].includes(contentRef.current.page) && contentRef.current.config?.CorrectScorePools.includes(foPool.oddsType)){
            let comb = [...line.combinations].sort((a,b)=> a.currentOdds - b.currentOdds)
            comb.forEach((comb, index) => {
                let id = getCellId(foPool, line, comb)
                if(index > 3 ){
                    let idx = contentRef.current.oddsSelection.findIndex(i => i.id === id)
                    if(idx > -1)  contentRef.current.oddsSelection[idx].checked = false
                }
            })
        }
        contentRef.current.oddsSelection = [...contentRef.current.oddsSelection]
    });
}