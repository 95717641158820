import React, { useState, useRef, useEffect } from 'react';
import Slider from "react-slick";
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { useWindowSize } from '../../Common/CommonHooks.js'

const RBCMultiLegSlider = (props) => {
  const {isMobileSize, width} = useWindowSize()
  const [slideIdx, setSlideIdx] = useState(0);
  const [curCnt, setCurCtn] = useState(0);
  let sliderRef = useRef();

  const gotoNext = () => {
    // setSlideIdx(slideIdx + 1)
    sliderRef.current.slickNext();
  }

  const goToBack = () => {
    // setSlideIdx(slideIdx - 1)
    sliderRef.current.slickPrev();
  }

  useEffect(() => {
    if ( props?.children?.length == 0) {
      setSlideIdx(0)
    }
    if (curCnt !== props.cnt) {
      setCurCtn(props.cnt)
      if(slideIdx  === props.cnt - 1 && slideIdx > 0){
        setTimeout(()=>{
            // console.log(sliderRef.current)
            sliderRef.current?.slickPrev?.()
          },400)
      }
      // console.log(props,slideIdx )
      // if(curCnt > props.cnt && props.cnt  > 2 && props.races?.[slideIdx ] > props?.curSelectRaceNo ){
        // setTimeout(()=>{
        //   console.log(sliderRef.current)
          // sliderRef.current?.slickPrev?.()
        // },400)
      // }
    }
  }, [props,slideIdx]);

  // useEffect(() => {
    // sliderRef.current.slickGoTo(slideIdx);
  // }, [slideIdx]);

  const settings = {
    // arrows: false,
    centerMode: false,
    // centerPadding: "0px",
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    slidesPerRow: 1,
    focusOnSelect: false,
    draggable: false,
    beforeChange: (current, next) => {
      // setSlideIdx(next);
    },
    afterChange: (currentIndex) => {
      setSlideIdx(currentIndex)
    },

    responsive: [
      {
        breakpoint: 9999,
        settings: isMobileSize?{
          slidesToShow: 2,
        }:'unslick'
      },
      {
        breakpoint: 10000,
        settings: 'unslick'
      }
    ]
  };

  return (<>
    <Slider {...settings} className={props.className} ref={sliderRef}>
      {[...props.children]}
    </Slider>
    <div className={`multi-leg-slide-btn-prev ${slideIdx <= 0 ? "hide" : ""}`} onClick={goToBack}>
      <ArrowIcon direction='left' color='white' size={18}/>
    </div>
    <div className={`multi-leg-slide-btn-next ${slideIdx + 2 >= props.cnt || props.cnt < 3 ? "hide" : ""}`} onClick={gotoNext}>
      <ArrowIcon direction='right' color='white' size={18}/>
    </div>
  </>);
}
export default RBCMultiLegSlider
