import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FPSChargeTips from './index';
import { isHasPropertyInObj } from '../../../../Common/GlobalFunc';
import LinkBankList from '../LinkBankList';

const noNba = 'noNba',
    noFps = 'noFps',
    fpsServiceCant = 'fpsServiceCant',
    showBankList = 'showbankList',
    epsServiceCant = 'epsServiceCant';

export const ExceptionalCaseComp = ({ props }) => {
    const { t, i18n } = useTranslation();
    const [btnText, setBtnText] = useState('');
    const [caseStatus, setCaseStatus] = useState(getExpCaseStatus);
    const { getExpCaseStatus, bankList, fpsMessageQuery, isShowBtnArea, isHadPPS } = props;

    const message = () => {
        if (isHasPropertyInObj(props, 'getExpCaseStatus')) {
            const { isSingleLeg } = props;
            let text = t('LB_BETSLIP_DONE');
            let domText = null;
            if (getExpCaseStatus === noNba) {
                domText = (
                    <>
                        <div className="mrB24" dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_CASE_NONBA1') }}></div>
                        <div className="underLineText" onClick={goToSuppBank}>
                            {t('LB_BS_FT_CASE_NONBA2')}
                        </div>
                    </>
                );
            }
            if (getExpCaseStatus === noFps) {
                text = t('LB_BS_FT_CASE_LINKNOW');
                domText = (
                    <>
                        <div className="mrB24">
                            {t('LB_BS_FT_CASE_NOFUNDSTRANSFERVICE1')}
                            <span className="caseCl" onClick={goToAddFpsBank}>
                                {t('LB_BS_FT_CASE_NOFUNDSTRANSFERVICE2')}
                            </span>
                            {t('LB_BS_FT_CASE_NOFUNDSTRANSFERVICE3')}
                        </div>
                    </>
                );
            }
            if (getExpCaseStatus === fpsServiceCant) {
                let singleLegMsg = <></>;
                if (isSingleLeg) {
                    singleLegMsg = (
                        <div className="mrB24 marT-16">
                            {t('LB_BS_FT_SINGLELEG_FPSMSG1')}
                            <span className="caseCl text-underline" onClick={handleClickHereBtn}>
                                {t('LB_BS_FT_SINGLELEG_FPSMSG2')}
                            </span>
                            {t('LB_BS_FT_SINGLELEG_FPSMSG3')}
                        </div>
                    );
                }
                domText = (
                    <>
                        <div className="mrB24">{t('LB_BS_FT_CASE_FPSISSUSPENDED')}</div>
                        {singleLegMsg}
                    </>
                );
            }
            setBtnText(text);
            return domText;
        }
    };
    const goToSuppBank = () => {
        setCaseStatus(showBankList);
    };
    const goToAddFpsBank = () => {
        const { goToLinkBAPage } = props;
        goToLinkBAPage(0);
    };
    const continueFn = () => {
        const { getExpCaseStatus } = props;
        if (getExpCaseStatus === noFps) {
            goToAddFpsBank();
        } else {
            window.close();
        }
    };

    const handleClickHereBtn = () => {
        const { goToLinkBAPage, bankAccountType } = props;
        goToLinkBAPage(1, bankAccountType);
    };

    return (
        <>
            {caseStatus === showBankList ? (
                <LinkBankList props={{ bankList, getExpCaseStatus }} setCaseStatus={setCaseStatus} />
            ) : (
                <FPSChargeTips props={{ hideTitle: true, message, continueFn, continueBtnText: btnText, isShowBtnArea, isHadPPS }} />
            )}
        </>
    );
};
