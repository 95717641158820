import React from 'react';

import Single from '../Page/Single';
import Multiple from '../Page/Multiple';
import Random from '../Page/Random';
import Banker from '../Page/Banker';
import Results from '../Page/Results';
import CheckDrawResult from '../Page/CheckDrawResult';
import Statistics from '../Page/Statistics';
import Fixtures from '../Page/Fixtures';
import UserInfo from '../Page/UserInfo';
import Loading from '../../Common/ui-components/Loading';

const RedirectPage = ({ pageName }) => {
    switch (pageName) {
        case "SINGLE":
            return <Single key='SINGLE' />;
        case "SINGLESNOWBALL":
            return <Single key='SINGLESNOWBALL' />;
        case "MULTIPLE":
            return <Multiple key='MULTIPLE' />;
        case "MULTIPLESNOWBALL":
            return <Multiple key='MULTIPLESNOWBALL' />;
        case "RANDOM":
            return <Random key='RANDOM' />;
        case "RANDOMSNOWBALL":
            return <Random key='RANDOMSNOWBALL' />;
        case "BANKER":
            return <Banker key='BANKER' />;
        case "BANKERSNOWBALL":
            return <Banker key='BANKERSNOWBALL' />;
        case "RESULTS":
            return <Results key='RESULTS' />;
        case "STATISTICS":
            return <Statistics key='STATISTICS' />;
        case "FIXTURES":
            return <Fixtures key='FIXTURES' />;
        case "CHECKDRAWRESULT":
            return <CheckDrawResult key='CHECKDRAWRESULT' />;
        case "USERINFO":
            return <UserInfo />
        default:
            return <Single key='HOME' />;
    }
}
export default RedirectPage;