import { useTranslation } from 'react-i18next';
import './index.scss'
import { useEffect } from 'react';

export const allMatchTimeTypes = [{
    key: 'ALL',
    ch: '所有',
    en: 'All'
}, {
    key: 'FT',
    ch: '全場',
    en: 'Full Time'
}, {
    key: 'FH',
    ch: '半場',
    en: 'First Half'
}]

export const FBMatchTypeTab = ({allMatchTimeTypes, selected, onChange, match, selectedOddsType}) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const showTab = getFirstHalfPools(match, selectedOddsType).length > 0 && getFTPoolsStartSell(match, selectedOddsType).length > 0
    useEffect(()=>{
        const showTab = getFirstHalfPools(match, selectedOddsType).length > 0 && getFTPoolsStartSell(match, selectedOddsType).length > 0
        if(!showTab){
            onChange('ALL')
        }
    },[match.foPools, selectedOddsType])
    return showTab ? <div className='FBMatchTypeTabContainer'>
        {
        allMatchTimeTypes.map((Item) => {
            return <div className={`FBMatchTypeTab ${selected === Item?.key ? 'active' : '' }`} onClick={() => onChange(Item?.key)}>{Item?.[lang]}</div>
        })
        }
    </div> : null
}

export const getFirstHalfPools = (match, selectedOddsType) =>{
    const FirstHalfPools = window.fbConfig.FirstHalfPools?.filter(betType => !selectedOddsType || selectedOddsType?.includes(betType))
    return match?.foPools?.filter(pool => FirstHalfPools.includes(pool.oddsType) ) || []
}


export const getFTPoolsStartSell = (match, selectedOddsType) => {
    const FullTimePools = window.fbConfig.FullTimePools?.filter(betType => !selectedOddsType || selectedOddsType?.includes(betType))
    return match?.foPools?.filter(pool => FullTimePools.includes(pool.oddsType)) || []
}