export const parseTopic = (topic) => {
    try{
        let tInfo = topic.split('/');
        if ( tInfo.length < 10 ) {
            return {}
        }
        let raceStatus = false;
        let meetingStatus = false;
        if( tInfo.length == 11){
            if(tInfo[10] != "jk" && tInfo[10] != "trn"){
                raceStatus = true;
            }
            else{
                return false;
            }
        }
        if( tInfo.length == 10){
            meetingStatus = true;   
        }
        let dt;
        let ve;
        let no;
        let bType = ""  ;
        let isOdds = false ;
        let dType;
        let instanceNo = -1;
        let pmPoolStatus = false;
        let isFoPool = false;
        let langVer = window.location.pathname;
        let langInfo = langVer.split('/')[1];
        if(raceStatus || meetingStatus ){
            if(raceStatus){
                no = parseInt(tInfo[10]);
            }      
        }
        else{
            no = parseInt(tInfo[10]);
            bType = translateBetType(tInfo[11])  ;
            isOdds = tInfo[12]==='odds';
            dType =  tInfo[tInfo.length-1];
            instanceNo = -1;
        
            if( ["JKC", "TNC"].includes(bType) ) {
                isFoPool = true;
                instanceNo = tInfo[12];
                if(dType == instanceNo ) {
                    dType = "sta";
                }
            }
            else {
                pmPoolStatus = true;
            }
    
        }
        dt = `${tInfo[8].substring(0,4)}-${tInfo[8].substring(4,6)}-${tInfo[8].substring(6,8)}`;
        ve = tInfo[9].toUpperCase();

        return {
            date: dt,
            venue: ve,
            raceNo: no,
            oddsType: bType,
            isOdds: isOdds,
            isFoPool: isFoPool,
            displayType: dType,
            insNo : instanceNo,
            pmPoolStatus : pmPoolStatus,
            raceStatus : raceStatus,
            meetingStatus : meetingStatus,
            langInfo: langInfo
        }
    }
    catch (e) {
        console.error(e, e.stack);
    }
    
}

const translateBetType = (bType) => {
    let type = bType?.toUpperCase();
    switch (type)  {
        case "D_T":
            return "DT";
        case "T_T":
            return "TT";
        case "F_F":
            return "FF";
        case "6UP":
            return "SixUP";
    }
    return type;
}