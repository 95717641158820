import React, { useState, useEffect, useContext } from 'react';
import StatisticsBallPanelTable from '../Common/StatisticsBallPanelTable';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../Common/CommonHooks';
import AmountDisplayTable from './AmountDisplayTable';
import { setMyFavCookie, MyFavouritesContext } from './MyFavourites';
import { MarksixContext } from '../../Home/MarksixPage';
import { isStartSell } from './Common';
import { isPmuEoD, eodMessage } from '../../Common/GlobalFunc';

const BankerSelectedNumTable = () => {
    const { t } = useTranslation();
    const context = useContext(MarksixContext);
    const isSell = isStartSell(context.nextDraw);
    const { isMobileSize } = useWindowSize();
    const initDefaultUnitBet = sessionStorage.getItem('Mk6DefaultUnitBetAmount')
        ? Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount'))
        : 10;
    const [unitBet, setUnitBet] = useState(initDefaultUnitBet);
    const [selectedTableData, setSelectedTableData] = useState([
        { numList: [], bankerList: [], isFav: false, chance: 0, unitBet, multidraw: 1 }
    ]);
    const [disUnitBet5, setDisUnitBet5] = useState(true);
    const scSize = document.documentElement.clientWidth;
    const { updateMyFavourites } = useContext(MyFavouritesContext);
    const [multidraw, setMultidraw] = useState(1);
    const [mySelectData, setMySelectData] = useState({ sinceNumber: true, timersNumber: false, isMultiCheck: true });
    const [betTypeSelect, setBetTypeSelect] = useState([
        { type: "banker", name1: 'LB_M6_BANKERLABEL', disabled: false, checked: true },
        { type: "leg", name1: 'LB_M6_LEGLABEL', disabled: false, checked: false },
      ]);

    const getChance = (arr) => {
        const { numList, bankerList } = arr;
        let size = numList.length;
        let count = 6 - bankerList.length;
        let betNum = 0;
        if (size + bankerList.length < 6 || size < count) {
        } else {
            let val = 1;
            let n = 1;
            const length = count;
            for (let i = 0; i < length; i++) {
                val *= size;
                n *= count;
                size--;
                count--;
            }
            betNum = val / n;
        }
        return betNum;
    };

    const onChangeBall = (ballList, ballNum, activeIndex, curLabelType, random = '') => {
        const newTableData = JSON.parse(JSON.stringify(selectedTableData));
        if (!curLabelType || (curLabelType === 'leg' && random!=='')) {
            newTableData[0].numList = ballList;
        } else {
            newTableData[0] = ballList;
        }
        newTableData[0].multidraw = multidraw;
        newTableData[0].random = !!random;
        newTableData[0].isFav = false;
        newTableData[0].chance = getChance(newTableData[0]);
        setSelectedTableData(newTableData);
    };

    const onChangeDraw = (num) => {
        const newTableData = JSON.parse(JSON.stringify(selectedTableData));
        newTableData[0].multidraw = num;
        setMultidraw(num);
        setSelectedTableData(newTableData);
    };

    const onChangeMode = (data) => {
        setMySelectData({ ...data });
    };

    const randomBall = () => {
        try {
            const { numList, bankerList } = selectedTableData[0];
            const curSeletedList = [...new Set([...numList, ...bankerList])];
            if (curSeletedList.length >= 49) {
                return alert(t('LB_M6_MSG_SELECT_NUMBERS_LINE_MAX'));
            }
            const isRandom=curSeletedList.length==0?true:!!selectedTableData[0].random;
            const ball = [...numList];
            const ballLength = bankerList.length > 0 ? 7 - bankerList.length : 6;
            if (ball.length < ballLength) {
                while (ball.length < ballLength) {
                    const rand = Math.ceil(Math.random() * 49);
                    if (!curSeletedList.includes(rand)) ball.push(rand);
                }
            } else {
                const length = ball.length;
                while (ball.length < length + 1) {
                    const rand = Math.ceil(Math.random() * 49);
                    if (!curSeletedList.includes(rand)) {
                        ball.push(rand);
                    }
                }
            }

            const sortBall = ball.sort((a, b) => a - b);
            onChangeBall(sortBall, null, 0, 'leg', isRandom);
            // setSelectedTableData([{...selectedTableData[0],chance: getChance()}])
        } catch (error) {
            console.error(error);
        }
    };

    const clearBall = (itemIndex) => {
        if (selectedTableData[itemIndex].numList.length === 0) return;
        const newData = JSON.parse(JSON.stringify(selectedTableData));
        if (newData[newData.length - 1].numList.length === 6 || newData.length - 1 === itemIndex) {
            newData.push({
                numList: [],
                bankerList: [],
                isFav: false,
                chance: 0,
                unitBet: initDefaultUnitBet,
                multidraw: 1
            });
        }
        newData.splice(itemIndex, 1);
        setSelectedTableData(newData);
    };

    const clearAllNumber = () => {
        setSelectedTableData([
            { numList: [], bankerList: [], isFav: false, chance: 0, unitBet: initDefaultUnitBet, multidraw: 1 }
        ]);
    };

    const handleClickSelectedBall = (num, index, curLabelType) => {
        const labelType = curLabelType === 'banker' ? 'bankerList' : 'numList';
        const newTableData = JSON.parse(JSON.stringify(selectedTableData));
        newTableData[index][labelType] = newTableData[index][labelType].filter((item) => item !== num);
        newTableData[index].isFav = false;
        setSelectedTableData(newTableData);
    };

    const handleClickFavIcon = (index) => {
        const newList = [...selectedTableData];
        if (newList[0].numList.length + newList[0].bankerList.length >= 6) {
            newList[0].isFav = !newList[0].isFav;
            setSelectedTableData(newList);

            if (newList[0].isFav) {
                if (!isSell) {
                    if (isPmuEoD() && eodMessage()?.length > 0) {
                        return window.alert(eodMessage());
                    } else {
                        return window.alert(t('LB_M6_MSG_NOT_DIFINED'));
                    }
                }
                if (
                    newList[0]?.bankerList?.length > 0 &&
                    newList[0].bankerList.length + newList[0].numList.length < 7
                ) {
                    return window.alert(t('LB_M6_MSG_INSU_SELECTIONS'));
                }
                // const fav = newList.filter(item => item.isFav)
                const fav = newList.filter((item, idx) => item.isFav && idx === index);
                if (mySelectData.isMultiCheck) {
                    fav[0].bankerList = [];
                }
                for (let i = 0; i < newList.length; i++) {
                    if (newList[i].numList.length >= 49) {
                        return window.alert(t('LB_M6_MSG_SELECT_NUMBERS_MULT_49'));
                    }
                }
                if (fav.length > 0) {
                    const favMax = setMyFavCookie(fav, t('LB_M6_MSG_MYFAV_MAX'));
                    if (favMax) return;
                    window.alert(t('LB_M6_MSG_MYFAV'));
                    updateMyFavourites();
                    if(isMobileSize) window.location.hash = "#SelectTable";
                    else document.querySelector('.StandardPage-scrollbars > div:first-child').scrollTop = 5000
                    //   clearAllNumber();
                }
            }

            return;
        }
        alert(t('LB_M6_MSG_INSU_SELECTIONS'));
    };

    const handleClickBet = (betNum) => {
        setUnitBet(betNum);
        selectedTableData[0].unitBet = betNum;
        setSelectedTableData(selectedTableData);
    };
    useEffect(() => {
        if (selectedTableData[0].numList.length < 7 && selectedTableData[0].bankerList.length === 0) {
            setDisUnitBet5(true);
            setUnitBet(initDefaultUnitBet);
            selectedTableData[0].unitBet = initDefaultUnitBet;
            setSelectedTableData(selectedTableData);
        } else {
            setDisUnitBet5(false);
        }
        updateChance(selectedTableData[0])
    }, [selectedTableData[0].numList, selectedTableData[0].bankerList]);

    const updateChance = (parObj) => {
        selectedTableData[0].chance = getChance(parObj || selectedTableData[0])
        setSelectedTableData([...selectedTableData])
    }

    const selectTableProps = {
        t,
        scSize,
        handleClickFavIcon,
        handleClickSelectedBall,
        selectedTableData,
        handleClickBet,
        unitBet,
        clearBall,
        mySelectData,
        disUnitBet5,
        randomBall
    };

    return (
        <div className="marksix-banker-middle marksix-checkresult-middle">
            <StatisticsBallPanelTable
                onChangeBall={onChangeBall}
                size={50}
                curBallList={selectedTableData[0]}
                type="statistics"
                selectedData={selectedTableData}
                onChangeMode={onChangeMode}
                clear={clearAllNumber}
                setBetTypeSelect={setBetTypeSelect}
                betTypeSelect={betTypeSelect}
            />
            <SelectTable {...selectTableProps} />
            <AmountDisplayTable
                activeType={'BANKER'}
                selectedData={selectedTableData}
                onChangeDraw={onChangeDraw}
                tableType="banker"
                clear={clearAllNumber}
                unitBet={unitBet}
                className="amount-diplay-d"
            />
        </div>
    );
};

export default BankerSelectedNumTable;

const SelectTable = (props) => {
    const { selectedTableData,disUnitBet5 } = props;
    let key = 1;
    const table = selectedTableData.map((item, index) => {
        return <TableRow ballData={item} index={index} key={key++} disUnitBet5={disUnitBet5} className={index % 2 ? '' : 'sec'} {...props} />;
    });
    return (
        <div className="single-select-table" id="SelectTable">
            <TableHeader {...props} />
            {table}
        </div>
    );
};

const TableHeader = ({ scSize, t }) => {
    return (
        <div className="table-row table-row-header">
            <div className="table-cell table-cell-fav">
                {scSize > 1023 ? t('LB_M6_SINGLE_TABLE_FAV') : t('LB_M6_SINGLE_TABLE_FAV_M')}
            </div>
            <div className="table-cell table-cell-number">
                <div>{t('LB_M6_SINGLE_TABLE_NUMBER')}</div>
            </div>
            <div className="table-cell"></div>
        </div>
    );
};

const TableRow = ({
    ballData,
    index,
    scSize,
    handleClickFavIcon,
    t,
    handleClickSelectedBall,
    disUnitBet5,
    handleClickBet,
    unitBet,
    randomBall,
    clearBall,
    mySelectData
}) => {
    const imgSize = scSize > 1023 ? 'm' : 's';
    const initDefaultUnitBet = sessionStorage.getItem('Mk6DefaultUnitBetAmount')
        ? Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount'))
        : 10;
    const initPartialUnitBet = sessionStorage.getItem('Mk6PartialUnitBetAmount')
        ? Number(sessionStorage.getItem('Mk6PartialUnitBetAmount'))
        : 5;
    return (
        <div className="table-row">
            <div className="table-cell">
                <div className="table-cell-fav-box pr">
                    {/* {activeItem === index && <div className='table-row-arrow'></div>} */}
                    <div
                        title={t('LB_M6_SINGLE_TABLE_FAV')}
                        className={ballData.isFav ? 'marksix-fav-active' : 'marksix-fav'}
                        onClick={() => handleClickFavIcon(index)}
                    ></div>
                </div>
            </div>
            <div className="table-cell">
                {ballData.numList.length > 0 || ballData.bankerList.length > 0 ? (
                    <>
                        <div
                            className="table-cell-banker-ball-box"
                            style={{ display: !mySelectData.isMultiCheck ? 'flex' : 'none' }}
                        >
                            <div className="table-cell-banker-text">{t('LB_M6_BANKERLABEL')}:</div>
                            <div className="df table-cell-ball-box">
                                {ballData?.bankerList?.map((i, ind) => {
                                    return (
                                        <div
                                            className="ball-img-box"
                                            onClick={() => handleClickSelectedBall(i, index, 'banker')}
                                            key={ind}
                                        >
                                            <img
                                                className={`ballsize-${imgSize}`}
                                                src={
                                                    i &&
                                                    require(`../../../info/marksix/info/images/marksix-gold-${i}.svg`)
                                                }
                                                alt={i}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="table-cell-banker-ball-box">
                            <div
                                className="table-cell-banker-text"
                                style={{ display: !mySelectData.isMultiCheck ? 'block' : 'none' }}
                            >
                                {t('LB_M6_LEGLABEL')}:
                            </div>
                            <div className="df table-cell-ball-box">
                                {ballData.numList.map((i, ind) => {
                                    return (
                                        <div
                                            className="ball-img-box"
                                            onClick={() => handleClickSelectedBall(i, index, 'leg')}
                                            key={ind}
                                        >
                                            <img
                                                className={`ballsize-${imgSize}`}
                                                src={
                                                    i &&
                                                    require(`../../../info/marksix/info/images/marksix-${i}.svg`)
                                                }
                                                alt={i}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="df table-cell-bet-box">
                            <label
                                className="radio-container"
                                onClick={() => !disUnitBet5 && handleClickBet(initPartialUnitBet)}
                            >
                                {t('LB_M6_MULT_PARBET').replace('{0}', initPartialUnitBet)}
                                <input
                                    type="radio"
                                    className="invCalFlexiBet-betline"
                                    onChange={() => handleClickBet(initPartialUnitBet)}
                                    disabled={disUnitBet5}
                                    checked={unitBet === initPartialUnitBet}
                                />
                                <span className="radio-checkmark"></span>
                            </label>
                            <label className="radio-container" onClick={() => handleClickBet(initDefaultUnitBet)}>
                                {t('LB_M6_MULT_UNITBET').replace('{0}', initDefaultUnitBet)}
                                <input
                                    type="radio"
                                    className="invCalFlexiBet-betline"
                                    onChange={() => handleClickBet(initDefaultUnitBet)}
                                    checked={unitBet === initDefaultUnitBet}
                                />
                                <span className="radio-checkmark"></span>
                            </label>
                        </div>
                    </>
                ) : (
                    <div className="df table-cell-ball-box"></div>
                )}
            </div>
            <div className="table-cell table-cell-ctrl-box">
                <div className="df ">
                    <div
                        className="marksix-download-icon"
                        title={t('LB_M6_QUICKPICK_BTN_TITLE')}
                        onClick={() => randomBall()}
                    ></div>
                    <div
                        className="marksix-delete-icon"
                        title={t('LB_M6_DELETE_BTN_TITLE')}
                        onClick={() => clearBall(index)}
                    ></div>
                </div>
            </div>
        </div>
    );
};
