import React, { memo } from 'react';
import style from './index.module.scss';

const BtnExpand = ({ isExpand = true, className = '', backgroundColor = '#003c84' }) => {
    return (
        <span
            style={{ '--background-color': backgroundColor }}
            className={`${style.BtnExpand} ${isExpand ? style.isExpand : ''} ${className}`}
        />
    );
};

export default memo(BtnExpand);
