import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import OddsTable from '../Common/RCOddsTable';
import symbol_plus from '../../../info/Include/images/symbol_plus_blue.svg';
import symbol_star from '../../../info/Include/images/symbol_star_blue.svg';
import { isLocalMeeting } from '../../Common/home-common';
import { RacingContext } from '../../Home/RacingPage';

function RCFooterLegend() {
    const { t } = useTranslation();

    return (
        <div className="rc-page-footer-legend">
            <div className="rc-page-footer-title">{t('LB_RC_RACECARD_FOOTER_REF')}</div>
            <div className="rc-page-footer-content df">
                <div className="left">{t('LB_RC_RACECARD_FOOTER_TP')} </div>
                <div className="right df">
                    <div id={'raceCard_TP_trumpCard'}>
                        <img className="symbol-plus-20" src={symbol_plus} alt="" />{' '}
                        <span>{t('LB_RC_RACECARD_FOOTER_PLUS')} </span>
                    </div>
                    <div id={'raceCard_TP_priorityRun'}>
                        <img className="symbol-star-20" src={symbol_star} alt="" />{' '}
                        <span> {t('LB_RC_RACECARD_FOOTER_STAR')} </span>
                    </div>
                    <div id={'raceCard_TP_order'} className="rc-page-footer-p">
                        <span>{t('LB_RC_RACECARD_FOOTER_P')} - </span>
                        <span>{t('LB_RC_RACECARD_FOOTER_ORDER')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

const RaceCard = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const raceObj = mtg.races.filter((x) => x.no == context.content.raceNo)[0];
    const isLocal = isLocalMeeting(context.content.venue);

    const RCColumns = {
        tp: true,
        no: true,
        colour: true,
        horseName: true,
        rcNote: context.content.isPeNoteEnable,
        draw: true,
        wt: true,
        jocky: true,
        alw: true,
        trainer: true,
        bodyWT: true,
        rtg: true,
        intRtg: true,
        gear: true,
        last6: true,
        standby: true
    };
    const SHColumns = {
        tp: true,
        no: true,
        colour: true,
        horseName: true,
        rcNote: context.content.isPeNoteEnable,
        draw: true,
        wt: true,
        jocky: true,
        trainer: true,
        bodyWT: true,
        rtg: true,
        intRtg: true,
        gear: true,
        last6: true,
        standby: true,
        showStandby: true
    };

    const hasStandby = () => {
        return (
            raceObj?.runners?.filter(
                (item) => item.status.toUpperCase() == 'STANDBY' || item.status.toUpperCase() == 'SCRATCHEDRESERVED'
            )?.length > 0
        );
    };

    return (
        <section className={'horse-racing-race-card ' + context.content.page}>
            <OddsTable columns={RCColumns} betType={context.content.page} />
            {hasStandby() && (
                <div className="standby-horse-table">
                    <header>{t(isLocal ? 'LB_RC_RACECARD_SH_TABLE_TITLE' : 'LB_RC_RACECARD_RES_TABLE_TITLE')}</header>
                    <OddsTable columns={SHColumns} betType={context.content.page} />
                </div>
            )}
            <RCFooterLegend />
        </section>
    );
};
export default RaceCard;
