import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { textFormatAmountFn } from '../../../Common/GlobalFunc';
import { saveBetLinesToCurrentSessionRecords, findPpsDataToCSRecords } from '../../../Common/CurrentSessionRecords';
import { formatServiceCharge } from '../utils';
import { GetError } from '../../../Common/bs_str_table';

const DepositResult = ({ props }) => {
    const {
        curStep,
        setCurStep,
        bankInfo,
        loginAccount,
        setResultShowStatus,
        transactionMethod,
        depositAmount,
        setDepositAmount,
        loadingStatus,
        resultPageRespData,
        setResultPageRespData,
        getBalance,
        goToLinkBAPage,
        bankOrPpsAccType,
        ppsRespData, 
        setPpsPageRespData,
        initType,
        nbaData
    } = props;
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!loadingStatus) {
            if(resultPageRespData.curIsPpsType){
                let enMsg=resultPageRespData.respMsg;
                let chMsg=resultPageRespData.respMsg;
                if(!resultPageRespData.respMsg && resultPageRespData.Result!='0'){
                    const code=resultPageRespData.Result;
                    const resCode=resultPageRespData.ResponseCode;
                    let numberCode=parseInt(code, 10)
                    if(numberCode>10 && GetError(code,i18n.language)){
                        enMsg = formatMsgFn(resCode, 'en', code)
                        chMsg = formatMsgFn(resCode, 'ch', code)
                    }else if(numberCode>0){
                        enMsg = formatMsgFn(resCode || code, 'en', 'lbl_pps_reject_msg')
                        chMsg = formatMsgFn(resCode || code, 'ch', 'lbl_pps_reject_msg')
                    }
                }
                const recordData = [
                    {
                        prod: 'FT',
                        Result : resultPageRespData.Result, 
                        resCode : resultPageRespData.ResponseCode, 
                        resultStatus: resultPageRespData.curSessionRecordsStatus,
                        transactionNumber: resultPageRespData.transactionNumber,
                        referenceNo: resultPageRespData.ReferenceNo,
                        transaction: 'Deposit',
                        curIsPpsType : true,
                        msg_en : enMsg,
                        msg_ch : chMsg,
                        amount: textFormatAmountFn(
                            resultPageRespData.Amount ? resultPageRespData.Amount : depositAmount,
                            2
                        ) ,
                        charge: textFormatAmountFn(resultPageRespData.serviceChargeAmt, 0),
                    }
                ]
                findPpsDataToCSRecords(recordData)
                saveBetLinesToCurrentSessionRecords(recordData);
            } else {
                saveBetLinesToCurrentSessionRecords([
                    {
                        prod: 'FT',
                        resultStatus: resultStatus,
                        transactionNumber: resultPageRespData?.transactionNumber != null && resultPageRespData.transactionNumber != undefined? resultPageRespData.transactionNumber : '',
                        transaction: 'Deposit',
                        nba: bankInfo?.bankAccountType == '1' ? '1' : '2',
                        amount: resultStatus == 'TIMEOUT' || resultStatus == 'PENDING'? "---": textFormatAmountFn(
                            resultPageRespData.resAmt ? resultPageRespData.resAmt : depositAmount,
                            2
                        ),
                        charge: textFormatAmountFn(resultPageRespData.serviceChargeAmt, 0),
                        msg_en:
                            resultPageRespData?.errMsg +
                            (resultPageRespData.errorCode && transactionMethod == 'FPS'? ` (${resultPageRespData.errorCode})` : ''),
                        msg_ch:
                            resultPageRespData?.errMsgTC +
                            (resultPageRespData.errorCode && transactionMethod == 'FPS'? ` (${resultPageRespData.errorCode})` : '')
                    }
                ]);
            }
        }
    }, [loadingStatus]);

    const doneFn = () => {
        setCurStep(0);
        setResultShowStatus('');
        setDepositAmount('');
        setResultPageRespData({});
        setPpsPageRespData({});
        initType();
    };

    const goLinkBankFn = () => {
        goToLinkBAPage(1, bankInfo.bankAccountType);
    };
    /*
    const getServiceCharge = () => {
        const chargeAmt = resultPageRespData?.serviceChargeAmt || resultPageRespData?.epscoChargeAmountCent;
        let amount = 0;
        if (window.globalConfig.IS_IB) {
            amount = chargeAmt;
        } else {
            amount = chargeAmt / 100;
        }
        return chargeAmt > 0 ? formatServiceCharge(amount) : null;
    };
*/
    const getServiceCharge = () => {
        var chargeAmt = 0;
        if (transactionMethod == 'FPS') {
            if (window.globalConfig.IS_IB) {
                chargeAmt = resultPageRespData?.serviceChargeAmt;
            } else {
                chargeAmt = resultPageRespData?.serviceChargeAmt / 100;
            }
            if (chargeAmt > 0) {
                return formatServiceCharge(chargeAmt);
            }
            return false;
        }

        if (transactionMethod == 'EPS') {
            if (window.globalConfig.IS_IB) {
                chargeAmt = resultPageRespData?.epscoChargeAmountCent?.replace('$', '');
                if (chargeAmt > 0) {
                    return formatServiceCharge(chargeAmt);
                }
                return false;
            } else {
                chargeAmt = resultPageRespData?.epscoChargeAmountCent;
                if (chargeAmt > 0) {
                    return formatServiceCharge(chargeAmt / 100);
                }
                return false;
            }
        }
    };

    const dateFormat = (date) => {
        if(date?.indexOf('T')){
            let newDate=date.split('T');
            let txnDt=newDate[0].split('-');
            if(txnDt.length==3){
                newDate = txnDt.reverse().join('/');
            }
            return newDate;
        }
        return date;
    }

    const ppsResultCode = (code) => {
        let msg='';
        if(code==='0'){
            msg = t('LB_BS_FT_PPS_ACCEPTED_TIPS');
        }else if(code){
            let numberCode=parseInt(code, 10)
            const faqUrl=`${window.globalConfig.IS_URL}/jcbw/ekba/promo/pps/pps_faq_${i18n.language}.asp?code=${code}`;
            if(numberCode>10 && GetError(code,i18n.language)){
                msg=GetError(code,i18n.language);
                msg = msg.replace(/##resCode##/g, code)
                msg = msg.replace(/{goPpsFAQUrl}/g, faqUrl)
            }else 
            if(numberCode>0){
                msg=GetError('lbl_pps_reject_msg',i18n.language);
                msg = msg?.replace(/##resCode##/g, code)
                msg = msg?.replace(/{goPpsFAQUrl}/g, faqUrl)
            }
        }
        return <div
            className="pps-msg"
            dangerouslySetInnerHTML={{
                __html: msg
            }}
        />;
    }

    const langName = i18n.language === 'en' ? 'name_en' : 'name_ch';
    const methodFps = bankInfo?.['FPS'];
    const resultStatus = resultPageRespData?.status;
    const showFpsBankList =
        transactionMethod === 'EPS' && !methodFps?.show && bankInfo?.bankInfo?.eDDADepositEnable == '1';
    const curIsPpsType=bankOrPpsAccType==='ppsType';
    const fpsErrCode=resultPageRespData?.errorCode && transactionMethod == 'FPS'? ` (${resultPageRespData.errorCode})` : '';
    const errMsgText= `${i18n.language == "en"? resultPageRespData?.errMsg: resultPageRespData?.errMsgTC}${fpsErrCode}`;

    if (curStep === 2 && !loadingStatus) {
        if(!curIsPpsType){
            return (
                <div className="deposit-prev ">
                    <div className="deposit-prev-title fontW-b">
                        <div>{t('LB_BS_FT_SUMMARY')}</div>
                    </div>
                    <div className="FT-form-content">
                        <div className="formItem">
                            <div className="formItemLabel">{t('LB_BS_FT_FROM')}:</div>
                            <div className="formItemContent">
                                <div className="contentTop">{bankInfo?.bankInfo?.[langName]}</div>
                                <div>{bankInfo?.bankAccountNoMasked}</div>
                            </div>
                        </div>
                        <div className="formItem">
                            <div className="formItemLabel">{t('LB_BS_FT_TO')}:</div>
                            <div className="formItemContent">
                                <div className="contentTop">{t('LB_BS_FT_BETACCOUNT')}</div>
                                <div>{loginAccount}</div>
                            </div>
                        </div>
                        <div className="formItem">
                            <div className="formItemLabel">{t('LB_BS_FT_TRANSACTIONMETHOD')}:</div>
                            <div className="formItemContent">
                                <div>
                                    {transactionMethod === 'EPS'
                                        ? t('LB_BS_FT_EPS').replace('{0}', t('LB_BS_FT_DEPOSIT'))
                                        : t('LB_BS_FT_FPS').replace('{0}', t('LB_BS_FT_DEPOSIT'))}
                                </div>
                            </div>
                        </div>
                        <div className="formItem">
                            <div className="formItemLabel">{t('LB_BS_FT_RESULT_AMOUNT')}:</div>
                            <div className="formItemContent">
                                <div>
                                    $
                                    {textFormatAmountFn(
                                        resultPageRespData.resAmt ? resultPageRespData.resAmt : depositAmount,
                                        2
                                    )}
                                </div>
                            </div>
                        </div>
                        {resultStatus === 'REJECTED' && (
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_LINK_RJ_REASON')}</div>
                                <div className="formItemContent">
                                    {window.globalConfig.IS_IB ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: errMsgText
                                            }}
                                        ></div>
                                    ) : (
                                        <div>
                                            {resultPageRespData?.msg}
                                            {resultPageRespData.errorCode ? `(${resultPageRespData.errorCode})` : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {['SUCCESSFUL', 'TIMEOUT'].includes(resultStatus) && getServiceCharge() && (
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_SERVICECHARGE')}:</div>
                                <div className="formItemContent">
                                    <div>${getServiceCharge()}</div>
                                </div>
                            </div>
                        )}
    
                        {['SUCCESSFUL'].includes(resultStatus) && (
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_REF_NO')}:</div>
                                <div className="formItemContent">
                                    <div>{resultPageRespData?.transactionNumber}</div>
                                </div>
                            </div>
                        )}
                        {['SUCCESSFUL', 'REJECTED'].includes(resultStatus) && getBalance !== '' && (
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_AC_BALANCE')}:</div>
                                <div className="formItemContent">
                                    <div>${textFormatAmountFn(getBalance)}</div>
                                </div>
                            </div>
                        )}
                        {['TIMEOUT', 'PENDING'].includes(resultStatus) && (
                            <div className="result-tips">{i18n.language == "en"? resultPageRespData?.msg_en : resultPageRespData?.msg_ch}</div>
                        )}
                        
                    </div>
                    {showFpsBankList && (
                        <>
                            <div className="deposit-registerFPS-msg">
                                {t('LB_BS_FT_RESULTS_FPSNOPIN1')}
                                <span className="goToLinkBank" onClick={goLinkBankFn}>
                                    {t('LB_BS_FT_RESULTS_FPSNOPIN2')}
                                </span>
                                {t('LB_BS_FT_RESULTS_FPSNOPIN3')}
                            </div>
                        </>
                    )}
                    <div className="deposit-btnArea">
                        <div className="deposit-btn doneBtn" onClick={doneFn}>
                            {t('LB_BETSLIP_DONE')}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="deposit-prev ">
                    <div className="deposit-prev-title fontW-b">
                        <div>{t('LB_BS_FT_SUMMARY')}</div>
                    </div>
                    <div className="FT-form-content">
                        {resultPageRespData?.Result=='0'?<>
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_PPS_TXN_DATE')}:</div>
                                <div className="formItemContent">
                                    <div>{dateFormat(resultPageRespData?.TxDate)}</div>
                                </div>
                            </div>
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_PPS_REF_NO')}:</div>
                                <div className="formItemContent">
                                    <div>{resultPageRespData?.ReferenceNo}</div>
                                </div>
                            </div>
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_PPS_PAYMENT_NO')}:</div>
                                <div className="formItemContent">
                                    <div>{resultPageRespData?.ISN}</div>
                                </div>
                            </div>
                        </>:<>
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_FROM')}:</div>
                                <div className="formItemContent">
                                    <div>{t('LB_BS_FT_LINK_PPS_FROM')}</div>
                                </div>
                            </div>
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_TO')}:</div>
                                <div className="formItemContent">
                                    <div className="contentTop">{t('LB_BS_FT_BETACCOUNT')}</div>
                                    <div>{loginAccount}</div>
                                </div>
                            </div>
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_TRANSACTIONMETHOD')}:</div>
                                <div className="formItemContent">
                                    <div>{t('LB_BS_FT_LINK_PPS_METHOD')}</div>
                                </div>
                            </div>
                        </>}
                        <div className="formItem">
                            <div className="formItemLabel">{t('LB_BS_FT_RESULT_AMOUNT')}:</div>
                            <div className="formItemContent">
                                <div>$
                                    {textFormatAmountFn(
                                        resultPageRespData.Amount ? resultPageRespData.Amount : depositAmount,
                                        2
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="formItem">
                            <div className="formItemLabel">{t('LB_BS_FT_PPS_RESULT_MSG')}:</div>
                            <div className="formItemContent">
                                <div>{ppsResultCode(resultPageRespData?.Result)}</div>
                            </div>
                        </div>
                        
                    </div>

                    <div className="deposit-btnArea">
                        <div className="deposit-btn doneBtn" onClick={doneFn}>
                            {t('LB_BETSLIP_DONE')}
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        return null;
    }
};

export const formatMsgFn = (resCode, lang, code) => {
    const urlCode = !isNaN(code) ? code : resCode;
    const faqUrl=`${window.globalConfig.IS_URL}/jcbw/ekba/promo/pps/pps_faq_${lang=='en'?'en':'ch'}.asp?code=${urlCode}`;
    let msg=GetError(code,lang);
    msg = msg.replace(/##resCode##/g, resCode);
    msg = msg.replace(/{goPpsFAQUrl}/g, faqUrl);
    return msg;
}

export default DepositResult;
