import { createMatch } from './createMatch';
import { getDisplayMatches } from '../useGetMatchList/common';

export const parseNewMatch = (message, topicInfo, contentRef) => {
    let idx = contentRef.current.rawMatchList.findIndex(x => x.id === message.id);
    if( idx<0 ) {
        contentRef.current.rawMatchList.push(createMatch(message, topicInfo, contentRef));
    }
    else {
        contentRef.current.rawMatchList[idx] = createMatch(message, topicInfo, contentRef);
    }
    let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
    contentRef.current.listData = { matches : matchList };
}