import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AmountDisplayTable, { MobileAmountDisplay } from './AmountDisplayTable';
import { QuickPickTopTable } from './QuickPickBase';
import { setMyFavCookie, MyFavouritesContext } from './MyFavourites';
import { MarksixContext } from '../../Home/MarksixPage';
import { isStartSell } from './Common';
import { useWindowSize } from '../../Common/CommonHooks';
import { isPmuEoD, eodMessage } from '../../Common/GlobalFunc';

const QuickPick = ({ activeType, setActiveType }) => {
    const { t } = useTranslation();
    const context = useContext(MarksixContext);
    const {ewinAtM6Obj} = context;
    const { singleShowUnitBet, multipleShowUnitBet, bankerShowUnitBet, initDefaultUnitBet, initPartialUnitBet } = ewinAtM6Obj;
    const isSell = isStartSell(context.nextDraw);
    const { isMobileSize } = useWindowSize();
    const [selectedTableData, setSelectedTableData] = useState([
        { numList: [], isFav: false, chance: 0, unitBet: initDefaultUnitBet, multidraw: 1 }
    ]);
    const [curActiveType, setCurActiveType] = useState('SINGLE');
    const [comb, setComb] = useState({
        entrie: 4,
        numSelections: 7,
        numBanker: 1,
        numLeg: 6
    });
    const scSize = document.documentElement.clientWidth;
    const { updateMyFavourites } = useContext(MyFavouritesContext);
    const [multidraw, setMultidraw] = useState(1);

    const getChance = (total, count) => {
        if (total < count) return 0;
        let val = 1;
        let n = 1;
        const length = count;
        for (let i = 0; i < length; i++) {
            val *= total;
            n *= count;
            total--;
            count--;
        }
        return val / n;
    };

    const clearBall = (itemIndex) => {
        if (selectedTableData[itemIndex].numList.length === 0 && !selectedTableData[itemIndex]?.bankerList?.length)
            return;
        const newData = JSON.parse(JSON.stringify(selectedTableData));
        if (newData.length === 1) {
            newData.push({ numList: [], isFav: false, chance: 0, multidraw });
        }
        newData.splice(itemIndex, 1);
        setSelectedTableData(newData);
    };

    const clearAllNumber = () => {
        setSelectedTableData([{ numList: [], isFav: false, chance: 0, multidraw }]);
    };

    const randomBall = () => {
        const data = [];
        const selection = activeType === 'SINGLE' ? 6 : comb.numSelections;
        if (['SINGLE', 'MULTIPLE'].includes(activeType)) {
            for (let i = 0; i < comb.entrie; i++) {
                const numList = [];
                while (numList.length < selection) {
                    const rand = Math.ceil(Math.random() * 49);
                    if (!numList.includes(rand)) numList.push(rand);
                }
                data.push({
                    numList: numList.sort((a, b) => a - b),
                    isFav: false,
                    chance: getChance(selection, 6),
                    multidraw,
                    unitBet: initDefaultUnitBet,
                    random: true
                });
            }
        } else {
            for (let i = 0; i < comb.entrie; i++) {
                const numList = [];
                const bankerList = [];
                while (bankerList.length < comb.numBanker) {
                    const rand = Math.ceil(Math.random() * 49);
                    if (!bankerList.includes(rand)) bankerList.push(rand);
                }
                while (numList.length < comb.numLeg) {
                    const rand = Math.ceil(Math.random() * 49);
                    if (!numList.includes(rand) && !bankerList.includes(rand)) numList.push(rand);
                }
                data.push({
                    numList: numList.sort((a, b) => a - b),
                    bankerList: bankerList.sort((a, b) => a - b),
                    isFav: false,
                    chance: getChance(comb.numLeg, 6 - comb.numBanker),
                    multidraw,
                    unitBet: initDefaultUnitBet,
                    random: true
                });
            }
        }
        setCurActiveType(activeType);
        setSelectedTableData(data);
    };

    const onChangeDraw = (num) => {
        const newTableData = JSON.parse(JSON.stringify(selectedTableData));
        setMultidraw(num);
        const newArr = newTableData.map((item) => {
            return { ...item, multidraw: num };
        });
        setSelectedTableData(newArr);
    };

    const handleClickFavIcon = (index) => {
        const newList = [...selectedTableData];
        if (newList[index].numList.length + (newList[index]?.bankerList?.length || 0) >= 6) {
            if (newList[index].isFav) {
                newList[index].isFav = !newList[index].isFav;
                setSelectedTableData(newList);
            } else {
                if (!isSell) {
                    if (isPmuEoD() && eodMessage()?.length > 0) {
                        return window.alert(eodMessage());
                    } else {
                        return window.alert(t('LB_M6_MSG_NOT_DIFINED'));
                    }
                }
                if (
                    newList[index]?.bankerList?.length > 0 &&
                    newList[index].bankerList.length + newList[index].numList.length < 7
                ) {
                    return window.alert(t('LB_M6_MSG_INSU_SELECTIONS'));
                }
                // const fav = newList.filter(item => item.isFav)
                const fav = newList.filter((item, idx) => !item.isFav && idx === index);
                for (let i = 0; i < newList.length; i++) {
                    if (newList[i].numList.length >= 49) {
                        return window.alert(t('LB_M6_MSG_SELECT_NUMBERS_MULT_49'));
                    }
                }
                if (fav.length > 0) {
                    const favMax = setMyFavCookie(fav, t('LB_M6_MSG_MYFAV_MAX'));
                    if (favMax) return;
                    window.alert(t('LB_M6_MSG_MYFAV'));
                    newList[index].isFav = !newList[index].isFav;
                    setSelectedTableData(newList);
                    updateMyFavourites();
                    if(isMobileSize) window.location.hash = "#SelectTable";
                    else document.querySelector('.StandardPage-scrollbars > div:first-child').scrollTop = 5000
                    //   clearBall(index);
                }
            }
            return;
        }
        alert(t('LB_M6_MSG_INSU_SELECTIONS'));
    };

    const handleClickBet = (index, val) => {
        setSelectedTableData((oldVal) => {
            oldVal[index].unitBet = val;
            return [...oldVal];
        });
    };

    const selectTableProps = {
        scSize,
        t,
        handleClickFavIcon,
        selectedTableData,
        handleClickBet,
        clearBall
    };

    return (
        <div className="marksix-random-middle">
            <QuickPickTopTable
                activeType={activeType}
                onChangeActiveType={setActiveType}
                onChangeComb={setComb}
                onClickRandom={randomBall}
                clear={clearAllNumber}
                selectedData={selectedTableData}
            />
            {isMobileSize ? (
                <MobileAmountDisplay
                    activeType={curActiveType}
                    selectedData={selectedTableData}
                    unitBet={initDefaultUnitBet}
                    onChangeDraw={onChangeDraw}
                    tableType="random"
                    clear={clearAllNumber}
                    className="amount-diplay-m"
                />
            ) : null}
            <SelectTable {...selectTableProps} />
            <AmountDisplayTable
                activeType={curActiveType}
                selectedData={selectedTableData}
                unitBet={initDefaultUnitBet}
                onChangeDraw={onChangeDraw}
                tableType="random"
                clear={clearAllNumber}
                className="amount-diplay-d"
            />
        </div>
    );
};

export default QuickPick;

const SelectTable = (props) => {
    let key = 1;
    const table = props.selectedTableData?.map((item, index) => {
        return <TableRow ballData={item} index={index} key={key++} className={index % 2 ? '' : 'sec'} {...props} />;
    });
    return (
        <div className="single-select-table" id="SelectTable">
            <TableHeader {...props} />
            {table}
        </div>
    );
};

const TableHeader = ({ scSize, t }) => {
    return (
        <div className="table-row table-row-header">
            <div className="table-cell table-cell-fav">
                {scSize > 1023 ? t('LB_M6_SINGLE_TABLE_FAV') : t('LB_M6_SINGLE_TABLE_FAV_M')}
            </div>
            <div className="table-cell table-cell-number">
                <div>{t('LB_M6_SINGLE_TABLE_NUMBER')}</div>
            </div>
            <div className="table-cell"></div>
        </div>
    );
};

const TableRow = ({ ballData, index, className, scSize, t, handleClickFavIcon, handleClickBet, clearBall }) => {
    const imgSize = scSize > 1023 ? 'm' : 's';
    const {ewinAtM6Obj} = useContext(MarksixContext);
    const {  initDefaultUnitBet, initPartialUnitBet } = ewinAtM6Obj;
    return (
        <div className={`table-row ${className}`}>
            <div className="table-cell">
                <div className="table-cell-fav-box pr">
                    <div
                        title={t('LB_M6_SINGLE_TABLE_FAV')}
                        className={ballData.isFav ? 'marksix-fav-active' : 'marksix-fav'}
                        onClick={() => handleClickFavIcon(index)}
                    ></div>
                </div>
            </div>
            <div className="table-cell">
                <div className="table-cell-ball-box random-ball-box">
                    {ballData?.bankerList && (
                        <div className="df">
                            <div className="banker-label">{t('LB_M6_BANKERLABEL')}:</div>
                            <div className="df">
                                {ballData?.bankerList?.map((i, ind) => {
                                    return (
                                        <div className="ball-img-box" key={ind}>
                                            <img
                                                className={`ballsize-${imgSize}`}
                                                src={
                                                    i &&
                                                    require(`../../../info/marksix/info/images/marksix-gold-${i}.svg`)
                                                }
                                                alt={i}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="df">
                        {ballData?.bankerList && <div className="banker-label">{t('LB_M6_LEGLABEL')}:</div>}
                        <div className="df ball-list-container">
                            {ballData.numList.map((i, ind) => {
                                return (
                                    <div className="ball-img-box" key={ind}>
                                        <img
                                            className={`ballsize-${imgSize}`}
                                            src={
                                                i &&
                                                require(`../../../info/marksix/info/images/marksix-${i}.svg`)
                                            }
                                            alt={i}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={`df table-cell-bet-box ${(ballData.numList.length >= 7 || (ballData.numList.length >= 6 && ballData?.bankerList >= 1)) && ballData.chance > 0 ? 'bet-box-show' : 'bet-box-hide'}`}>
                    <label
                        className="radio-container"
                        onClick={() => ballData.numList.length >= 7 && handleClickBet(index, initPartialUnitBet)}
                    >
                        {t('LB_M6_MULT_PARBET').replace('{0}', initPartialUnitBet)}
                        <input
                            type="radio"
                            className="invCalFlexiBet-betline"
                            onChange={() => handleClickBet(index, initPartialUnitBet)}
                            checked={ballData.unitBet === initPartialUnitBet}
                        />
                        <span className="radio-checkmark"></span>
                    </label>
                    <label className="radio-container" onClick={() => handleClickBet(index, initDefaultUnitBet)}>
                        {t('LB_M6_MULT_UNITBET').replace('{0}', initDefaultUnitBet)}
                        <input
                            type="radio"
                            className="invCalFlexiBet-betline"
                            onChange={() => handleClickBet(index, initDefaultUnitBet)}
                            checked={ballData.unitBet === initDefaultUnitBet}
                        />
                        <span className="radio-checkmark"></span>
                    </label>
                </div>
            </div>
            <div className="table-cell table-cell-ctrl-box">
                <div className="df ">
                    <div className="marksix-delete-icon" onClick={() => clearBall(index)}></div>
                </div>
            </div>
        </div>
    );
};
