import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewWindow, NewWindowWithProps, getMenuParamByName } from '../../Common/home-common';
import { isLogonHigh } from '../../BetSlip/LoginHooks';
import { getWebIDHashed } from '../../Common/utils/wa';
import { useGetSurveyQuery } from '../../Home/Data/SitecoreDataHooks';
import './Survey.scss';

const onSurveyClick = (survey, entry) => {
    let surveyUrl = survey.surveyUrl?.value || "";
    if ( surveyUrl != "" ) {
        surveyUrl = surveyUrl.replace("{hashId}", getWebIDHashed())
        .replace("{channel}", window.sessionStorage.getItem('ChannelID'))
        .replace("{entrypoint}", entry);
        if (survey.redirectMethod?.value === window.sitecoreVars.REDIRECT_POPUP) {
            let winProps = getMenuParamByName(survey);
            NewWindowWithProps(surveyUrl, 'survey', winProps);
        } else if (survey.redirectMethod?.value === window.sitecoreVars.REDIRECT_REPLACETAB) {
            NewWindowWithProps(surveyUrl, '_self');
        } else {
            window.open(surveyUrl);
        }
    }
};

const Survey = () => {
    const { t } = useTranslation();
    const { survey } = useGetSurveyQuery();

    return isLogonHigh() && survey.enable?.value==="1" ? (
        <div className="fMenuRowSurvey" onClick={() => { onSurveyClick(survey, 1) }}>
            <div className="topMenuSurveyIcon" />
            <span className="topMenuSurvey">{survey.lblHome?.value}</span>
        </div>
    ) : null;
};

export const SurveyInBetSlip = () => {
    return null; // SQ0248-3943
    // const { t } = useTranslation();
    // const { survey } = useGetSurveyQuery();

    // return isLogonHigh() && survey.enable?.value==="1" ? (
    //     <div className="SurveyInBetSlipWrap">
    //         <div className="SurveyInBetSlip">
    //             <div className="icon"></div>
    //             <div className="text">
    //                 <span className="Leave-Feedback">{survey.lblBetConfirm?.value}</span>
    //                 <span className="click-share-opinion">{survey.descBetConfirm?.value}</span>
    //             </div>
    //             <div className="button" onClick={() => { onSurveyClick(survey, 2) }}>
    //                 {t('LB_SURVEY_BTN')}
    //             </div>
    //         </div>
    //     </div>
    // ) : null;
};

export default Survey;
