import React, { useState } from 'react';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { useWindowSize } from '../../Common/CommonHooks';

const Pagination = ({ onChange, pageNum, total, AlwaysShow }) => {
    const {isMobileSize} = useWindowSize();
    const showPageCount = isMobileSize ? 3 : 10
    const [position, setPosition] = useState(1);
    const onChangePage = (index, action) => {
        if (action) {
            if (action === 'PRE' && pageNum > 1) {
                const newPageNum = pageNum - 1
                onChange(newPageNum);
                setPosition(Math.ceil(newPageNum/showPageCount))
            } else if (action === 'NEXT' && pageNum < total) {
                const newPageNum = pageNum + 1
                onChange(newPageNum);
                setPosition(Math.ceil(newPageNum/showPageCount))
            }
            return;
        }
        onChange(index);
    };

    const pageList = [];
    for (let i = (position - 1) * showPageCount + 1; i <= total && i <= position * showPageCount; i++) {
        pageList.push(
            <div key={`pageNum-${i}`} onClick={() => onChangePage(i)} className={`${pageNum === i ? 'active' : ''}`}>
                {i}
            </div>
        );
    }

    return (
        <>
            {total > 1 || AlwaysShow ? (
                <div className="df pagination-box">
                    <div className={`${pageNum === 1 && 'disable'}`} onClick={() => onChangePage(null, 'PRE')}>
                        <ArrowIcon direction="left" />
                    </div>
                    {position > 1 ? (
                        <div
                            style={{lineHeight: '16px'}}
                            onClick={() => {
                                if (position > 1) {
                                    const newPosition = position - 1;
                                    setPosition(newPosition);
                                    onChange(newPosition * showPageCount);
                                }
                            }}
                        >
                            ...
                        </div>
                    ) : null}

                    {pageList}
                    {position * showPageCount < total ? (
                        <div
                            style={{lineHeight: '16px'}}
                            onClick={() => {
                                if (position * showPageCount < total) {
                                    setPosition(position + 1);
                                    onChange(position * showPageCount + 1);
                                }
                            }}
                        >
                            ...
                        </div>
                    ) : null}

                    <div className={`${pageNum >= total && 'disable'}`} onClick={() => onChangePage(null, 'NEXT')}>
                        <ArrowIcon direction="right" />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Pagination;
