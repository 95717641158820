export const rsFoUserContent = {
    en: {
        "data": {
            "item": {
                "children": [{
                    "key": "Int_JKC Regular Season",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "Jockeys' Ranking 22/23"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW(SA)"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "JKC_Statistics(SA)"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Scheduled Rides List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "JKC_Odds_Charts"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockeys' Rides For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "JKC_Standings"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockey's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Jockey_Ranking"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TEK"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K Teetan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.jpg?rev=b0040024abab4cf0bf86a6ee102fffdc",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyTEK_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.jpg?rev=765c522ac141414eb378d8f53299c9c1",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K Teetan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "PZ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Z Purton"
                            },
                            "pIcon": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/jockeyPZ_1/remark.PNG?rev=b6475d8343a14100b8024433039f38fd",
                                "width": 28,
                                "height": 33,
                                "alt": "remark"
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.jpg?rev=8b1731e17ee14fb8a00361a22932991d",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Z Purton"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HCY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Y Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=410e8cf43a7e41c6b4155eb36b523d1f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=64a9b1e4389f412eab62fda2fbc8f8b2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Y Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "DSS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "S De Sousa"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "S De Sousa"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FEL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ferraris"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=7fb2cd65354042f09569d7601803596a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=861e397da68a41ebb62f34eb1a75ad9c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ferraris"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "PMF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M F Poon"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.jpg?rev=79061669c53f4f30a0dbffdff98a1d66",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyPMF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=d31fe139c7074a99bb03b1866cafc4f2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M F Poon"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y L Chung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y L Chung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BHW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H Bentley"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H Bentley"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CJE"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C L Chau"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.jpg?rev=def751e36dce4fc78268c4b6df737cc6",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCJE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.jpg?rev=0b2866675ff54a409e57cf1737b99e9b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C L Chau"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HEL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Hewitson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Hewitson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LDE"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K C Leung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.jpg?rev=4ee25d9c04aa49618e58e5a216fb39a3",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLDE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.jpg?rev=f9d39f0d100c48f9914ecbbf1a83226c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K C Leung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BV"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "V Borges"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.jpg?rev=0683c09559264058a26d618e4578049b",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.jpg?rev=2a54b9c92d9542459e0050f1ab6ddfac",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "V Borges"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Hamelin"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=8d449f6292aa4d2c94ce0064bd6b3eb5",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=7e349d8765a94623a2a042accace06cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Hamelin"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CML"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M Chadwick"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.jpg?rev=4e8e7884a5a64d88a83762da001c764b",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCML_i_1"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.jpg?rev=72073421cf1143cc9a7ae847c181affd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M Chadwick"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WJH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H N Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=8fb6c0e7fa194ae2a7120da49cec66e8",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WJH&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.jpg?rev=afdb18ddbb004b95b4cfe56afcd39020",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H N Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Badel"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Badel"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CLR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Currie"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MOJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Moreira"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Moreira"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LHW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H W Lai"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.jpg?rev=951f3abb8259425cb61286a7ff0ba8ea",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.jpg?rev=2125f70c94d2433cb8ee85f068288680",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H W Lai"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MHT"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H T Mo"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.jpg?rev=0b2f7a49f9a04ff3ac40497989748982",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyMHT_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.jpg?rev=9a846823c38748f4ad8aa71b440d80cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H T Mo"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WCV"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.jpg?rev=6a5db94687414b2c848eab58005b1c76",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyWCV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.jpg?rev=c387e018cb9c467a864e9014856e3e7b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CHA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Chan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=6fc925d237294e51b42ce84d9a4fe827",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=c992fc065d8442ccb24f7a18d7f98268",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Chan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YML"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M L Yeung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.jpg?rev=861b988f3a7043aa8baf6fa5e5ede203",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyYML_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.jpg?rev=b7dee0c129e04c34b95ff24909ac0d50",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M L Yeung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MMR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Maia"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=f8ed246939ca4d8eb55f815a93a4b1a9",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=697a86113d6948d28f1c135047e237bd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Maia"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Regular_DS",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "Jockeys' Ranking 2022/23"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "System configuration"
                    },
                    "lastUpdText": {
                        "value": ""
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "123",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "123"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "456",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "456"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "testing"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Z Purton CCY"
                            },
                            "pIcon": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/jockeyPZ_1/remark.PNG?rev=b6475d8343a14100b8024433039f38fd",
                                "width": 28,
                                "height": 33,
                                "alt": "remark"
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "System configuration"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ/jockeyPZ.jpg?rev=bf702d46e42e4de6907509e7708fe1f6",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Z Purton test"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Y Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "Dev_TestBlock",
                    "enable": {
                        "value": ""
                    },
                    "betType": {
                        "value": ""
                    },
                    "dataType": {
                        "value": ""
                    },
                    "headBanner": {
                        "value": ""
                    },
                    "headBannerEnable": {
                        "value": ""
                    },
                    "lastUpdCfg": {
                        "value": ""
                    },
                    "lastUpdText": {
                        "value": ""
                    },
                    "lastUpdEnable": {
                        "value": ""
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Link",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": []
                    }, {
                        "key": "Profiles",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": ""
                        },
                        "children": []
                    }]
                }, {
                    "key": "JKC_IJC_DS",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "IJC"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{9FB7EC54-450A-4011-91F6-81090A179213}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "System configuration"
                    },
                    "lastUpdText": {
                        "value": ""
                    },
                    "lastUpdEnable": {
                        "value": ""
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": "<span style=\"color: #ff0000;\">To correspond to the races scheduled for the LONGINES International Jockeys' Championship (IJC) on Wednesday December 8, special arrangements will be made to Jockey Challenge for this particular meeting.<br />\n</span><span style=\"color: #333333;\">&gt; Challenge will only cover four nominated IJC races (Races 4, 5, 7 and 8).<br />\n&gt; In Play betting will be available up to the start of Race 7.<br />\n&gt; IJC participating jockeys will be numbered 1 to 12. There will be no 'Others' available for selection.<br />\n&gt; Jockey Challenge Points scored by substitute jockeys after start selling will not count.\n</span>"
                    },
                    "ijcLinkText": {
                        "value": "More"
                    },
                    "ijcLinkUrl": {
                        "value": "https://campaigns.hkjc.com/hkir/en/events-ijc-jockeys"
                    },
                    "ijcEnable": {
                        "value": "1"
                    },
                    "children": [{
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Z Purton"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.jpg?rev=8b1731e17ee14fb8a00361a22932991d",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Z Purton"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Moreira"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Moreira"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "Int_TNC Regular Season",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Entries List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainers' Entries For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainer's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P F Yiu"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P F Yiu"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D A Hayes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D A Hayes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "CS Shum"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "C S Shum"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Fownes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Fownes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CAS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CAS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A S Cruz"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCAS_i.jpg?rev=9b53f81622b54c7aad33990765e6c6a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerCAS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CAS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCAS.jpg?rev=b4b3cfb74b3249489cb42b9aae617937",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A S Cruz"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "W Y So"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "W Y So"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C W Chang"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C W Chang"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Hall"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Hall"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P C Ng"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P C Ng"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K L Man"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K L Man"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "T P Yung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "T P Yung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K W Lui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K W Lui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "F C Lor"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "F C Lor"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y S Tsui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y S Tsui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Whyte "
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Whyte"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A T Millard"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A T Millard"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Ting"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Ting"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Size"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Size"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C H Yip"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C H Yip"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Gibson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Gibson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Richards"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Richards"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "SA_MasterBlockInfo_JKC_RegularSeason",
                    "enable": {
                        "value": ""
                    },
                    "betType": {
                        "value": ""
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "Jockeys' Ranking 2022/23<img alt=\"\" mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": ""
                    },
                    "lastUpdCfg": {
                        "value": "System configuration"
                    },
                    "lastUpdText": {
                        "value": "(排名依2021年9月2日之騎師榜序)"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": "/-/media/System/Simulator-Backgrounds/Blackberry.png?rev=-1",
                        "width": 558,
                        "height": 952,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": "To correspond to the races scheduled for the LONGINES International Jockeys' Championship (IJC) on Wednesday December 8, special arrangements will be made to Jockey Challenge for this particular meeting. &hellip;.<a href=\"https://campaigns.hkjc.com/hkir/ch/events-ijc-jockeys\">更多</a>"
                    },
                    "ijcLinkText": {
                        "value": "More"
                    },
                    "ijcLinkUrl": {
                        "value": "https://campaigns.hkjc.com/hkir/en/events-ijc-jockeys"
                    },
                    "ijcEnable": {
                        "value": "1"
                    },
                    "children": [{
                        "key": "SA_MasterBlockLinkGroup_JKC",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "MasterBlockLink",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Statistics"
                            },
                            "itemLink": {
                                "value": "//racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": "/-/media/Sites/JCBW/MenuIcons/icon-Horse.png?rev=2e02199910e64634ad0bd4b8b7b64b61",
                                "width": 24,
                                "height": 24
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "100"
                            },
                            "pW": {
                                "value": "100"
                            },
                            "pX": {
                                "value": "100"
                            },
                            "pY": {
                                "value": "100"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "MasterBlockLink_TestCHI",
                            "enable": {
                                "value": ""
                            },
                            "name": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "SA_ProfilesGroup_JKC",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": ""
                        },
                        "children": [{
                            "key": "ZP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "JockeyTrainerProfile",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "MOJ"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/jockeyPZ_i.jpg?rev=f92c97eebe6a40b4a283a62b4ca9df2a",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/PZ.jpg?rev=15360bd631504eb88be39d600c008b65",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M  O   J"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K Teetan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.jpg?rev=b0040024abab4cf0bf86a6ee102fffdc",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyTEK_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.jpg?rev=765c522ac141414eb378d8f53299c9c1",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K Teetan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Z Purton"
                            },
                            "pIcon": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/jockeyPZ_1/remark.PNG?rev=b6475d8343a14100b8024433039f38fd",
                                "width": 28,
                                "height": 33,
                                "alt": "remark"
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.jpg?rev=8b1731e17ee14fb8a00361a22932991d",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Z Purton"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Y Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=410e8cf43a7e41c6b4155eb36b523d1f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=64a9b1e4389f412eab62fda2fbc8f8b2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Y Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "S De Sousa"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "S De Sousa"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ferraris"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=7fb2cd65354042f09569d7601803596a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=861e397da68a41ebb62f34eb1a75ad9c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ferraris"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M F Poon"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.jpg?rev=79061669c53f4f30a0dbffdff98a1d66",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyPMF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=d31fe139c7074a99bb03b1866cafc4f2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M F Poon"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y L Chung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y L Chung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H Bentley"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H Bentley"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C L Chau"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.jpg?rev=def751e36dce4fc78268c4b6df737cc6",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCJE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.jpg?rev=0b2866675ff54a409e57cf1737b99e9b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C L Chau"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Hewitson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Hewitson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K C Leung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.jpg?rev=4ee25d9c04aa49618e58e5a216fb39a3",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLDE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.jpg?rev=f9d39f0d100c48f9914ecbbf1a83226c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K C Leung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "V Borges"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.jpg?rev=0683c09559264058a26d618e4578049b",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.jpg?rev=2a54b9c92d9542459e0050f1ab6ddfac",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "V Borges"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Hamelin"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=8d449f6292aa4d2c94ce0064bd6b3eb5",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=7e349d8765a94623a2a042accace06cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Hamelin"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M Chadwick"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.jpg?rev=4e8e7884a5a64d88a83762da001c764b",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCML_i_1"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.jpg?rev=72073421cf1143cc9a7ae847c181affd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M Chadwick"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H N Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=8fb6c0e7fa194ae2a7120da49cec66e8",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WJH&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.jpg?rev=afdb18ddbb004b95b4cfe56afcd39020",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H N Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Badel"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Badel"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Currie"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Moreira"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Moreira"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H W Lai"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.jpg?rev=951f3abb8259425cb61286a7ff0ba8ea",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.jpg?rev=2125f70c94d2433cb8ee85f068288680",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H W Lai"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H T Mo"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.jpg?rev=0b2f7a49f9a04ff3ac40497989748982",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyMHT_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.jpg?rev=9a846823c38748f4ad8aa71b440d80cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H T Mo"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.jpg?rev=6a5db94687414b2c848eab58005b1c76",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyWCV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.jpg?rev=c387e018cb9c467a864e9014856e3e7b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Chan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=6fc925d237294e51b42ce84d9a4fe827",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=c992fc065d8442ccb24f7a18d7f98268",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Chan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M L Yeung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.jpg?rev=861b988f3a7043aa8baf6fa5e5ede203",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyYML_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.jpg?rev=b7dee0c129e04c34b95ff24909ac0d50",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M L Yeung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Maia"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=f8ed246939ca4d8eb55f815a93a4b1a9",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=697a86113d6948d28f1c135047e237bd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Maia"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "SA_MasterSeasonalJKCTNCInfo",
                    "enable": {
                        "value": ""
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Season end"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{E716AF3A-C6A3-46DC-AE13-D2BD8ABCA5E0}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "According to Jockey's Ranking on 16 Jul 2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": "/-/media/remark_e.gif?rev=712a2aaa1ce440eea8d75a07c47fa58e",
                        "width": 173,
                        "height": 51,
                        "alt": "remark_e"
                    },
                    "rmkBannerEnable": {
                        "value": "1"
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "JockeyTrainerProfileItem",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Z Purton"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/PZ.jpg?rev=15360bd631504eb88be39d600c008b65",
                                "width": 88,
                                "height": 100,
                                "alt": "PZ"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/PZ.jpg?rev=15360bd631504eb88be39d600c008b65",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Z Purton"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "JockeyTrainerProfileItem1",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }, {
                        "key": "TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "TopLinkItem",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "TopLinkItem1",
                            "enable": {
                                "value": ""
                            },
                            "name": {
                                "value": "JKC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA01",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": ""
                    },
                    "lastUpdCfg": {
                        "value": "System configuration"
                    },
                    "lastUpdText": {
                        "value": ""
                    },
                    "lastUpdEnable": {
                        "value": ""
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Scheduled Rides List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockeys' Rides For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockey's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TEK"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K Teetan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.jpg?rev=765c522ac141414eb378d8f53299c9c1",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K Teetan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "PZ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Z Purton"
                            },
                            "pIcon": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/jockeyPZ_1/remark.PNG?rev=b6475d8343a14100b8024433039f38fd",
                                "width": 28,
                                "height": 33,
                                "alt": "remark"
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.jpg?rev=8b1731e17ee14fb8a00361a22932991d",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Z Purton"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HCY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Y Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=410e8cf43a7e41c6b4155eb36b523d1f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=64a9b1e4389f412eab62fda2fbc8f8b2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Y Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "DSS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "S De Sousa"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "S De Sousa"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FEL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ferraris"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=7fb2cd65354042f09569d7601803596a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=861e397da68a41ebb62f34eb1a75ad9c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ferraris"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "PMF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M F Poon"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.jpg?rev=79061669c53f4f30a0dbffdff98a1d66",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyPMF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=d31fe139c7074a99bb03b1866cafc4f2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M F Poon"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y L Chung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y L Chung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BHW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H Bentley"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H Bentley"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CJE"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C L Chau"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.jpg?rev=def751e36dce4fc78268c4b6df737cc6",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCJE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.jpg?rev=0b2866675ff54a409e57cf1737b99e9b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C L Chau"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HEL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Hewitson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Hewitson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LDE"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K C Leung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.jpg?rev=4ee25d9c04aa49618e58e5a216fb39a3",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLDE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.jpg?rev=f9d39f0d100c48f9914ecbbf1a83226c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K C Leung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BV"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "V Borges"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.jpg?rev=0683c09559264058a26d618e4578049b",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.jpg?rev=2a54b9c92d9542459e0050f1ab6ddfac",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "V Borges"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Hamelin"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=8d449f6292aa4d2c94ce0064bd6b3eb5",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=7e349d8765a94623a2a042accace06cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Hamelin"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CML"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M Chadwick"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.jpg?rev=4e8e7884a5a64d88a83762da001c764b",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCML_i_1"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.jpg?rev=72073421cf1143cc9a7ae847c181affd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M Chadwick"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WJH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H N Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=8fb6c0e7fa194ae2a7120da49cec66e8",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WJH&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.jpg?rev=afdb18ddbb004b95b4cfe56afcd39020",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H N Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Badel"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Badel"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CLR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Currie"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MOJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Moreira"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Moreira"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LHW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H W Lai"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.jpg?rev=951f3abb8259425cb61286a7ff0ba8ea",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.jpg?rev=2125f70c94d2433cb8ee85f068288680",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H W Lai"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MHT"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H T Mo"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.jpg?rev=0b2f7a49f9a04ff3ac40497989748982",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyMHT_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.jpg?rev=9a846823c38748f4ad8aa71b440d80cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H T Mo"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WCV"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.jpg?rev=6a5db94687414b2c848eab58005b1c76",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyWCV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.jpg?rev=c387e018cb9c467a864e9014856e3e7b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CHA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Chan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=6fc925d237294e51b42ce84d9a4fe827",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=c992fc065d8442ccb24f7a18d7f98268",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Chan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YML"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M L Yeung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.jpg?rev=861b988f3a7043aa8baf6fa5e5ede203",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyYML_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.jpg?rev=b7dee0c129e04c34b95ff24909ac0d50",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M L Yeung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MMR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Maia"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=f8ed246939ca4d8eb55f815a93a4b1a9",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=697a86113d6948d28f1c135047e237bd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Maia"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA02",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Scheduled Rides List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockeys' Rides For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockey's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K Teetan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.jpg?rev=b0040024abab4cf0bf86a6ee102fffdc",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyTEK_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.jpg?rev=765c522ac141414eb378d8f53299c9c1",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K Teetan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Z Purton"
                            },
                            "pIcon": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/jockeyPZ_1/remark.PNG?rev=b6475d8343a14100b8024433039f38fd",
                                "width": 28,
                                "height": 33,
                                "alt": "remark"
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.jpg?rev=8b1731e17ee14fb8a00361a22932991d",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Z Purton"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Y Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=410e8cf43a7e41c6b4155eb36b523d1f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=64a9b1e4389f412eab62fda2fbc8f8b2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Y Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "S De Sousa"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "S De Sousa"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ferraris"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=7fb2cd65354042f09569d7601803596a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=861e397da68a41ebb62f34eb1a75ad9c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ferraris"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M F Poon"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.jpg?rev=79061669c53f4f30a0dbffdff98a1d66",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyPMF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=d31fe139c7074a99bb03b1866cafc4f2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M F Poon"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y L Chung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y L Chung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H Bentley"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H Bentley"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C L Chau"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.jpg?rev=def751e36dce4fc78268c4b6df737cc6",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCJE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.jpg?rev=0b2866675ff54a409e57cf1737b99e9b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C L Chau"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Hewitson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Hewitson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K C Leung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.jpg?rev=4ee25d9c04aa49618e58e5a216fb39a3",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLDE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.jpg?rev=f9d39f0d100c48f9914ecbbf1a83226c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K C Leung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "V Borges"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.jpg?rev=0683c09559264058a26d618e4578049b",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.jpg?rev=2a54b9c92d9542459e0050f1ab6ddfac",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "V Borges"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Hamelin"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=8d449f6292aa4d2c94ce0064bd6b3eb5",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=7e349d8765a94623a2a042accace06cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Hamelin"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M Chadwick"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.jpg?rev=4e8e7884a5a64d88a83762da001c764b",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCML_i_1"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.jpg?rev=72073421cf1143cc9a7ae847c181affd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M Chadwick"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H N Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=8fb6c0e7fa194ae2a7120da49cec66e8",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WJH&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.jpg?rev=afdb18ddbb004b95b4cfe56afcd39020",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H N Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Badel"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Badel"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Currie"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Moreira"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Moreira"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H W Lai"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.jpg?rev=951f3abb8259425cb61286a7ff0ba8ea",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.jpg?rev=2125f70c94d2433cb8ee85f068288680",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H W Lai"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H T Mo"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.jpg?rev=0b2f7a49f9a04ff3ac40497989748982",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyMHT_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.jpg?rev=9a846823c38748f4ad8aa71b440d80cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H T Mo"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.jpg?rev=6a5db94687414b2c848eab58005b1c76",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyWCV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.jpg?rev=c387e018cb9c467a864e9014856e3e7b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Chan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=6fc925d237294e51b42ce84d9a4fe827",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=c992fc065d8442ccb24f7a18d7f98268",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Chan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M L Yeung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.jpg?rev=861b988f3a7043aa8baf6fa5e5ede203",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyYML_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.jpg?rev=b7dee0c129e04c34b95ff24909ac0d50",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M L Yeung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Maia"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=f8ed246939ca4d8eb55f815a93a4b1a9",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=697a86113d6948d28f1c135047e237bd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Maia"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA03",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Scheduled Rides List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockeys' Rides For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockey's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K Teetan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.jpg?rev=b0040024abab4cf0bf86a6ee102fffdc",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyTEK_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.jpg?rev=765c522ac141414eb378d8f53299c9c1",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K Teetan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Y Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=410e8cf43a7e41c6b4155eb36b523d1f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=64a9b1e4389f412eab62fda2fbc8f8b2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Y Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "S De Sousa"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "S De Sousa"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ferraris"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=7fb2cd65354042f09569d7601803596a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=861e397da68a41ebb62f34eb1a75ad9c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ferraris"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M F Poon"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.jpg?rev=79061669c53f4f30a0dbffdff98a1d66",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyPMF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=d31fe139c7074a99bb03b1866cafc4f2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M F Poon"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y L Chung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y L Chung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H Bentley"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H Bentley"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C L Chau"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.jpg?rev=def751e36dce4fc78268c4b6df737cc6",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCJE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.jpg?rev=0b2866675ff54a409e57cf1737b99e9b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C L Chau"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Hewitson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Hewitson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K C Leung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.jpg?rev=4ee25d9c04aa49618e58e5a216fb39a3",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLDE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.jpg?rev=f9d39f0d100c48f9914ecbbf1a83226c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K C Leung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "V Borges"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.jpg?rev=0683c09559264058a26d618e4578049b",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.jpg?rev=2a54b9c92d9542459e0050f1ab6ddfac",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "V Borges"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Hamelin"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=8d449f6292aa4d2c94ce0064bd6b3eb5",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=7e349d8765a94623a2a042accace06cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Hamelin"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M Chadwick"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.jpg?rev=4e8e7884a5a64d88a83762da001c764b",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCML_i_1"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.jpg?rev=72073421cf1143cc9a7ae847c181affd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M Chadwick"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H N Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=8fb6c0e7fa194ae2a7120da49cec66e8",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WJH&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.jpg?rev=afdb18ddbb004b95b4cfe56afcd39020",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H N Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Badel"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Badel"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Currie"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Moreira"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Moreira"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H W Lai"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.jpg?rev=951f3abb8259425cb61286a7ff0ba8ea",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.jpg?rev=2125f70c94d2433cb8ee85f068288680",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H W Lai"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H T Mo"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.jpg?rev=0b2f7a49f9a04ff3ac40497989748982",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyMHT_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.jpg?rev=9a846823c38748f4ad8aa71b440d80cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H T Mo"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.jpg?rev=6a5db94687414b2c848eab58005b1c76",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyWCV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.jpg?rev=c387e018cb9c467a864e9014856e3e7b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Chan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=6fc925d237294e51b42ce84d9a4fe827",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=c992fc065d8442ccb24f7a18d7f98268",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Chan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M L Yeung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.jpg?rev=861b988f3a7043aa8baf6fa5e5ede203",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyYML_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.jpg?rev=b7dee0c129e04c34b95ff24909ac0d50",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M L Yeung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Maia"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=f8ed246939ca4d8eb55f815a93a4b1a9",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=697a86113d6948d28f1c135047e237bd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Maia"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA04",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Scheduled Rides List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockeys' Rides For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockey's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K Teetan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.jpg?rev=b0040024abab4cf0bf86a6ee102fffdc",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyTEK_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.jpg?rev=765c522ac141414eb378d8f53299c9c1",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K Teetan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Y Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=410e8cf43a7e41c6b4155eb36b523d1f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=64a9b1e4389f412eab62fda2fbc8f8b2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Y Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "S De Sousa"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "S De Sousa"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ferraris"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=7fb2cd65354042f09569d7601803596a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=861e397da68a41ebb62f34eb1a75ad9c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ferraris"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M F Poon"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.jpg?rev=79061669c53f4f30a0dbffdff98a1d66",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyPMF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=d31fe139c7074a99bb03b1866cafc4f2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M F Poon"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y L Chung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y L Chung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H Bentley"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H Bentley"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C L Chau"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.jpg?rev=def751e36dce4fc78268c4b6df737cc6",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCJE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.jpg?rev=0b2866675ff54a409e57cf1737b99e9b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C L Chau"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Hewitson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Hewitson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K C Leung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.jpg?rev=4ee25d9c04aa49618e58e5a216fb39a3",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLDE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.jpg?rev=f9d39f0d100c48f9914ecbbf1a83226c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K C Leung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "V Borges"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.jpg?rev=0683c09559264058a26d618e4578049b",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.jpg?rev=2a54b9c92d9542459e0050f1ab6ddfac",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "V Borges"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Hamelin"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=8d449f6292aa4d2c94ce0064bd6b3eb5",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=7e349d8765a94623a2a042accace06cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Hamelin"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M Chadwick"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.jpg?rev=4e8e7884a5a64d88a83762da001c764b",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCML_i_1"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.jpg?rev=72073421cf1143cc9a7ae847c181affd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M Chadwick"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H N Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=8fb6c0e7fa194ae2a7120da49cec66e8",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WJH&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.jpg?rev=afdb18ddbb004b95b4cfe56afcd39020",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H N Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Badel"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Badel"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Currie"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Moreira"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Moreira"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H W Lai"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.jpg?rev=951f3abb8259425cb61286a7ff0ba8ea",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.jpg?rev=2125f70c94d2433cb8ee85f068288680",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H W Lai"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H T Mo"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.jpg?rev=0b2f7a49f9a04ff3ac40497989748982",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyMHT_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.jpg?rev=9a846823c38748f4ad8aa71b440d80cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H T Mo"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.jpg?rev=6a5db94687414b2c848eab58005b1c76",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyWCV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.jpg?rev=c387e018cb9c467a864e9014856e3e7b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Chan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=6fc925d237294e51b42ce84d9a4fe827",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=c992fc065d8442ccb24f7a18d7f98268",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Chan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M L Yeung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.jpg?rev=861b988f3a7043aa8baf6fa5e5ede203",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyYML_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.jpg?rev=b7dee0c129e04c34b95ff24909ac0d50",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M L Yeung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Maia"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=f8ed246939ca4d8eb55f815a93a4b1a9",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=697a86113d6948d28f1c135047e237bd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Maia"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA05",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Scheduled Rides List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockeys' Rides For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockey's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K Teetan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.jpg?rev=b0040024abab4cf0bf86a6ee102fffdc",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyTEK_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.jpg?rev=765c522ac141414eb378d8f53299c9c1",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K Teetan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Z Purton"
                            },
                            "pIcon": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/jockeyPZ_1/remark.PNG?rev=b6475d8343a14100b8024433039f38fd",
                                "width": 28,
                                "height": 33,
                                "alt": "remark"
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.jpg?rev=8b1731e17ee14fb8a00361a22932991d",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Z Purton"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Y Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=410e8cf43a7e41c6b4155eb36b523d1f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=64a9b1e4389f412eab62fda2fbc8f8b2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Y Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "S De Sousa"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "S De Sousa"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ferraris"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=7fb2cd65354042f09569d7601803596a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=861e397da68a41ebb62f34eb1a75ad9c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ferraris"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M F Poon"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.jpg?rev=79061669c53f4f30a0dbffdff98a1d66",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyPMF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=d31fe139c7074a99bb03b1866cafc4f2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M F Poon"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y L Chung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y L Chung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H Bentley"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H Bentley"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C L Chau"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.jpg?rev=def751e36dce4fc78268c4b6df737cc6",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCJE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.jpg?rev=0b2866675ff54a409e57cf1737b99e9b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C L Chau"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Hewitson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Hewitson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K C Leung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.jpg?rev=4ee25d9c04aa49618e58e5a216fb39a3",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLDE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.jpg?rev=f9d39f0d100c48f9914ecbbf1a83226c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K C Leung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "V Borges"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.jpg?rev=0683c09559264058a26d618e4578049b",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.jpg?rev=2a54b9c92d9542459e0050f1ab6ddfac",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "V Borges"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Hamelin"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=8d449f6292aa4d2c94ce0064bd6b3eb5",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=7e349d8765a94623a2a042accace06cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Hamelin"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M Chadwick"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.jpg?rev=4e8e7884a5a64d88a83762da001c764b",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCML_i_1"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.jpg?rev=72073421cf1143cc9a7ae847c181affd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M Chadwick"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H N Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=8fb6c0e7fa194ae2a7120da49cec66e8",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WJH&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.jpg?rev=afdb18ddbb004b95b4cfe56afcd39020",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H N Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Badel"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Badel"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Currie"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Moreira"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Moreira"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H W Lai"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.jpg?rev=951f3abb8259425cb61286a7ff0ba8ea",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.jpg?rev=2125f70c94d2433cb8ee85f068288680",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H W Lai"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H T Mo"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.jpg?rev=0b2f7a49f9a04ff3ac40497989748982",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyMHT_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.jpg?rev=9a846823c38748f4ad8aa71b440d80cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H T Mo"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.jpg?rev=6a5db94687414b2c848eab58005b1c76",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyWCV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.jpg?rev=c387e018cb9c467a864e9014856e3e7b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Chan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=6fc925d237294e51b42ce84d9a4fe827",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=c992fc065d8442ccb24f7a18d7f98268",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Chan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M L Yeung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.jpg?rev=861b988f3a7043aa8baf6fa5e5ede203",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyYML_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.jpg?rev=b7dee0c129e04c34b95ff24909ac0d50",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M L Yeung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Maia"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=f8ed246939ca4d8eb55f815a93a4b1a9",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=697a86113d6948d28f1c135047e237bd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Maia"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA06",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Scheduled Rides List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockeys' Rides For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Jockey's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K Teetan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.jpg?rev=b0040024abab4cf0bf86a6ee102fffdc",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyTEK_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.jpg?rev=765c522ac141414eb378d8f53299c9c1",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K Teetan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Z Purton"
                            },
                            "pIcon": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/jockeyPZ_1/remark.PNG?rev=b6475d8343a14100b8024433039f38fd",
                                "width": 28,
                                "height": 33,
                                "alt": "remark"
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.jpg?rev=8b1731e17ee14fb8a00361a22932991d",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Z Purton"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Y Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=410e8cf43a7e41c6b4155eb36b523d1f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=64a9b1e4389f412eab62fda2fbc8f8b2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Y Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "S De Sousa"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "S De Sousa"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ferraris"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=7fb2cd65354042f09569d7601803596a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=861e397da68a41ebb62f34eb1a75ad9c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ferraris"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M F Poon"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.jpg?rev=79061669c53f4f30a0dbffdff98a1d66",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyPMF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=d31fe139c7074a99bb03b1866cafc4f2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M F Poon"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y L Chung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y L Chung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H Bentley"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H Bentley"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C L Chau"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.jpg?rev=def751e36dce4fc78268c4b6df737cc6",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCJE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.jpg?rev=0b2866675ff54a409e57cf1737b99e9b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C L Chau"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Hewitson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Hewitson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K C Leung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.jpg?rev=4ee25d9c04aa49618e58e5a216fb39a3",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLDE_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.jpg?rev=f9d39f0d100c48f9914ecbbf1a83226c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K C Leung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "V Borges"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.jpg?rev=0683c09559264058a26d618e4578049b",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.jpg?rev=2a54b9c92d9542459e0050f1ab6ddfac",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "V Borges"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Hamelin"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=8d449f6292aa4d2c94ce0064bd6b3eb5",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=7e349d8765a94623a2a042accace06cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Hamelin"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M Chadwick"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.jpg?rev=4e8e7884a5a64d88a83762da001c764b",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCML_i_1"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.jpg?rev=72073421cf1143cc9a7ae847c181affd",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M Chadwick"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H N Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=8fb6c0e7fa194ae2a7120da49cec66e8",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WJH&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.jpg?rev=afdb18ddbb004b95b4cfe56afcd39020",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H N Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A Badel"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A Badel"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Currie"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Moreira"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Moreira"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H W Lai"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.jpg?rev=951f3abb8259425cb61286a7ff0ba8ea",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyLHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.jpg?rev=2125f70c94d2433cb8ee85f068288680",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H W Lai"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "H T Mo"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.jpg?rev=0b2f7a49f9a04ff3ac40497989748982",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyMHT_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.jpg?rev=9a846823c38748f4ad8aa71b440d80cf",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "H T Mo"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Wong"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.jpg?rev=6a5db94687414b2c848eab58005b1c76",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyWCV_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.jpg?rev=c387e018cb9c467a864e9014856e3e7b",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Wong"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Chan"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=6fc925d237294e51b42ce84d9a4fe827",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=c992fc065d8442ccb24f7a18d7f98268",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Chan"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "M L Yeung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.jpg?rev=861b988f3a7043aa8baf6fa5e5ede203",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyYML_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.jpg?rev=b7dee0c129e04c34b95ff24909ac0d50",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "M L Yeung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA01",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Entries List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainers' Entries For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainer's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D A Hayes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D A Hayes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P F Yiu"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P F Yiu"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "CS Shum"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "C S Shum"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Fownes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Fownes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CAS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CAS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A S Cruz"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCAS_i.jpg?rev=9b53f81622b54c7aad33990765e6c6a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerCAS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CAS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCAS.jpg?rev=b4b3cfb74b3249489cb42b9aae617937",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A S Cruz"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "W Y So"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "W Y So"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C W Chang"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C W Chang"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Hall"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Hall"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P C Ng"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P C Ng"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K L Man"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K L Man"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "T P Yung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "T P Yung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K W Lui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K W Lui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "F C Lor"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "F C Lor"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y S Tsui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y S Tsui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Whyte "
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Whyte"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A T Millard"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A T Millard"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Ting"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Ting"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Size"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Size"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C H Yip"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C H Yip"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Gibson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Gibson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Richards"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Richards"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA02",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Entries List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainers' Entries For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainer's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D A Hayes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D A Hayes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P F Yiu"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P F Yiu"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "CS Shum"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "C S Shum"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Fownes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Fownes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CAS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CAS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A S Cruz"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCAS_i.jpg?rev=9b53f81622b54c7aad33990765e6c6a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerCAS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CAS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCAS.jpg?rev=b4b3cfb74b3249489cb42b9aae617937",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A S Cruz"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "W Y So"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "W Y So"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C W Chang"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C W Chang"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Hall"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Hall"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P C Ng"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P C Ng"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K L Man"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K L Man"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "T P Yung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "T P Yung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K W Lui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K W Lui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "F C Lor"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "F C Lor"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y S Tsui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y S Tsui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Whyte "
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Whyte"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A T Millard"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A T Millard"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Ting"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Ting"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Size"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Size"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C H Yip"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C H Yip"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Gibson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Gibson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Richards"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Richards"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA03",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Entries List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainers' Entries For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainer's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D A Hayes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D A Hayes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P F Yiu"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P F Yiu"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "CS Shum"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "C S Shum"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Fownes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Fownes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "W Y So"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "W Y So"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C W Chang"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C W Chang"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Hall"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Hall"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P C Ng"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P C Ng"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K L Man"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K L Man"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "T P Yung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "T P Yung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K W Lui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K W Lui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "F C Lor"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "F C Lor"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y S Tsui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y S Tsui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Whyte "
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Whyte"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A T Millard"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A T Millard"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Ting"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Ting"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Size"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Size"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C H Yip"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C H Yip"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Gibson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Gibson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Richards"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Richards"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA04",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Entries List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainers' Entries For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainer's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D A Hayes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D A Hayes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P F Yiu"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P F Yiu"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "CS Shum"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "C S Shum"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Fownes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Fownes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "W Y So"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "W Y So"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C W Chang"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C W Chang"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Hall"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Hall"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P C Ng"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P C Ng"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K L Man"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K L Man"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "T P Yung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "T P Yung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K W Lui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K W Lui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "F C Lor"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "F C Lor"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y S Tsui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y S Tsui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Whyte "
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Whyte"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A T Millard"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A T Millard"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Ting"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Ting"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Size"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Size"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C H Yip"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C H Yip"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Gibson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Gibson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Richards"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Richards"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA05",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Entries List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainers' Entries For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainer's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D A Hayes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D A Hayes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P F Yiu"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P F Yiu"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "CS Shum"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "C S Shum"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Fownes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Fownes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CAS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CAS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A S Cruz"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCAS_i.jpg?rev=9b53f81622b54c7aad33990765e6c6a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerCAS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CAS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCAS.jpg?rev=b4b3cfb74b3249489cb42b9aae617937",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A S Cruz"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "W Y So"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "W Y So"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C W Chang"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C W Chang"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Hall"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Hall"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P C Ng"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P C Ng"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K L Man"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K L Man"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "T P Yung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "T P Yung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K W Lui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K W Lui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "F C Lor"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "F C Lor"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y S Tsui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y S Tsui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Whyte "
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Whyte"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A T Millard"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A T Millard"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Ting"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Ting"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Size"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Size"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C H Yip"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C H Yip"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Gibson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Gibson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Richards"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Richards"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA06",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "Current Season Up to Race Meeting of 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Statistics"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Entries List"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Odds Chart"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainers' Entries For The Day"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "TNC Standings"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "How to Play"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Selling Hours"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/en/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "Trainer's Info"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D A Hayes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D A Hayes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P F Yiu"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P F Yiu"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "CS Shum"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "C S Shum"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C Fownes"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C Fownes"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CAS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CAS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A S Cruz"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCAS_i.jpg?rev=9b53f81622b54c7aad33990765e6c6a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerCAS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CAS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCAS.jpg?rev=b4b3cfb74b3249489cb42b9aae617937",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A S Cruz"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "W Y So"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "W Y So"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C W Chang"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C W Chang"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Hall"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Hall"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "P C Ng"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "P C Ng"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K L Man"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K L Man"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "T P Yung"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "T P Yung"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K W Lui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K W Lui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "F C Lor"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "F C Lor"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "Y S Tsui"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "Y S Tsui"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "D J Whyte "
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "D J Whyte"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "L Ho"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "L Ho"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "A T Millard"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "A T Millard"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "K H Ting"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "K H Ting"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "J Size"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "J Size"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "C H Yip"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "C H Yip"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "R Gibson"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "R Gibson"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }]
            }
        }
    },
    ch: {
        "data": {
            "item": {
                "children": [{
                    "key": "Int_JKC Regular Season",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "騎師榜2022/23<img alt=\"\" mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "* 今季資料 (截至上次賽事日): 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王統計"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW(SA)"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "JKC_Statistics(SA)"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王坐騎表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "JKC_Odds_Charts"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日騎師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "JKC_Standings"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師資料"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Jockey_Ranking"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TEK"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "田泰安"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.ashx?rev=2e25d9bf4afa42e4a00487bc1ae3eab4",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "System configuration"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.ashx?rev=b3bc5c17762d4c2cbfe2492463c9e7c2",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "田泰安"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "PZ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘頓"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.ashx?rev=ef8ae40f204c4ccc868dd82c5ee68f3a",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "潘頓"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HCY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何澤堯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=4cb1095f68ea4425804d1c22e3e92480",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=c64853b1cf4f41b49d2636cbf3a793a2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何澤堯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "DSS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇兆輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇兆輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FEL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "霍宏聲"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=3e8a00bc2d9649bf89d186dcc07d059a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=3dd81988ce2d47da8b717271604d76aa",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "霍宏聲"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "PMF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘明輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.ashx?rev=d5735b1dc7214f288d8662feb0650a25",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=1f5848ed97ab4023b2c7a7b53ee3b2d2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "潘明輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鍾易禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鍾易禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BHW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "班得禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "班得禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CJE"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "周俊樂"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.ashx?rev=e8968b6e30e6483a91afd6a584138172",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.ashx?rev=f96ff3d1bd3d4f0d849f2f3fc080034d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "周俊樂"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HEL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "希威森"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "希威森"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LDE"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "梁家俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.ashx?rev=dca078b3d5744779b87357cf624e04e1",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.ashx?rev=fde89e94789c4bee94e3fd7a37167534",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "梁家俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BV"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "波健士"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.ashx?rev=1d74b08393be4358bc2f8d3f9a15c063",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.ashx?rev=c9fcd68a5d9d46bb933f93a4239b209d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "波健士"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀銘年"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=0e3f360a04844a97a740d88979129bdf",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=a90a44a2abc144e0aed5ba9764fd1633",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀銘年"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CML"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡明紹"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.ashx?rev=a7345b055b894b29bec0fd17abb4898c",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.ashx?rev=94d778dee7864db68b6474cecadcf960",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "蔡明紹"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WJH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃皓楠"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=fbe47d009c27491ea964dc5cbf0681f2",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.ashx?rev=4a8492b8e94f4b7d9a763704dad35cba",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃皓楠"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巴度"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "巴度"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CLR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "嘉里"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MOJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "莫雷拉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "莫雷拉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LHW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎海榮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.ashx?rev=15dcf3fa92d447daa276b5f2cc419cca",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.ashx?rev=7be1e337890441078a1b2e9f9accfcd1",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黎海榮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MHT"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巫顯東"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.ashx?rev=96378e0b0ece432195f8aa7c42b4d7e9",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.ashx?rev=b1a9698e14994349829789eac18d3ec7",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "毛顯東"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WCV"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.ashx?rev=10a359b50b3146a2a98e5bd89a30dcbd",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.ashx?rev=ada2ff50a5414a38ad936c3c589a9f8d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CHA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "陳嘉熙"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=00229f99b0274677927c26b0742fd86a",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=e5f4610e938d40689a8add918af91f30",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "陳嘉熙"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YML"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "楊明綸"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.ashx?rev=524dc518703047189da38ffbc98be3a8",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.ashx?rev=a4a9b870d1954211b872f7ca0c8c849b",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "楊明綸"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MMR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "馬雅"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=8b372a8f029648cf819ab67233a9a10f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=998f0d284bc34853b0b2173644782644",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "馬雅"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Regular_DS",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "System configuration"
                    },
                    "lastUpdText": {
                        "value": ""
                    },
                    "lastUpdEnable": {
                        "value": ""
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "123",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "123"
                            },
                            "itemLink": {
                                "value": "123"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "456",
                            "enable": {
                                "value": ""
                            },
                            "name": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "testing"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘頓"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.ashx?rev=ef8ae40f204c4ccc868dd82c5ee68f3a",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "潘頓"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "Dev_TestBlock",
                    "enable": {
                        "value": ""
                    },
                    "betType": {
                        "value": ""
                    },
                    "dataType": {
                        "value": ""
                    },
                    "headBanner": {
                        "value": ""
                    },
                    "headBannerEnable": {
                        "value": ""
                    },
                    "lastUpdCfg": {
                        "value": ""
                    },
                    "lastUpdText": {
                        "value": ""
                    },
                    "lastUpdEnable": {
                        "value": ""
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Link",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": []
                    }, {
                        "key": "Profiles",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": ""
                        },
                        "children": []
                    }]
                }, {
                    "key": "JKC_IJC_DS",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "IJC"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{9FB7EC54-450A-4011-91F6-81090A179213}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": ""
                    },
                    "lastUpdText": {
                        "value": ""
                    },
                    "lastUpdEnable": {
                        "value": ""
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": "<span style=\"font-size: 11px;color: #ff0000;\">中文 To correspond to the races scheduled for the LONGINES International Jockeys' Championship (IJC) on Wednesday December 8, special arrangements will be made to Jockey Challenge for this particular meeting.<br />\n</span><span style=\"font-size: 11px;color: #333333;\">&gt; Challenge will only cover four nominated IJC races (Races 4, 5, 7 and 8).<br />\n&gt; In Play betting will be available up to the start of Race 7.<br />\n&gt; IJC participating jockeys will be numbered 1 to 12. There will be no 'Others' available for selection.<br />\n&gt; Jockey Challenge Points scored by substitute jockeys after start selling will not count.\n</span>"
                    },
                    "ijcLinkText": {
                        "value": "更多"
                    },
                    "ijcLinkUrl": {
                        "value": "https://campaigns.hkjc.com/hkir/ch/events-ijc-jockeys"
                    },
                    "ijcEnable": {
                        "value": "1"
                    },
                    "children": [{
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘頓"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.ashx?rev=ef8ae40f204c4ccc868dd82c5ee68f3a",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "潘頓"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "莫雷拉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "莫雷拉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "Int_TNC Regular Season",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "今季資料 (截至上次賽事日): 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王統計"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王出馬表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日練馬師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師資料"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "姚本輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "姚本輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "大衛希斯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "大衛希斯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "沈集成"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "沈集成"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "方嘉柏"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "方嘉柏"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CAS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CAS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "告東尼"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCAS_i.jpg?rev=9b53f81622b54c7aad33990765e6c6a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerCAS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CAS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCAS.jpg?rev=b4b3cfb74b3249489cb42b9aae617937",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "告東尼"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇偉賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇偉賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鄭俊偉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鄭俊偉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HAD&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "伍鵬志"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "伍鵬志"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "文家良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "文家良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "容天鵬"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "容天鵬"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "呂健威"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "呂健威"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "羅富全"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "羅富全"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "徐雨石"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "徐雨石"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "韋達"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "韋達"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "苗禮德"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "苗禮德"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "丁冠豪"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "丁冠豪"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡約翰"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蔡約翰"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "葉楚航"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "葉楚航"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": " 高伯新"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": " 高伯新"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎昭昇"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=RW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "黎昭昇"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "SA_MasterBlockInfo_JKC_RegularSeason",
                    "enable": {
                        "value": ""
                    },
                    "betType": {
                        "value": ""
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{6A4CFA85-9B43-4406-81C5-004BD489E7BA}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": ""
                    },
                    "lastUpdText": {
                        "value": ""
                    },
                    "lastUpdEnable": {
                        "value": ""
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "SA_MasterBlockLinkGroup_JKC",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "MasterBlockLink",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "JKC Statistics"
                            },
                            "itemLink": {
                                "value": "//racing.hkjc.com/racing/information/english/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": "/-/media/Sites/JCBW/MenuIcons/icon-Horse.png?rev=dc1b19071f4649a7b7bd9fe2e2bbde24",
                                "width": 24,
                                "height": 24
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "100"
                            },
                            "pW": {
                                "value": "100"
                            },
                            "pX": {
                                "value": "100"
                            },
                            "pY": {
                                "value": "100"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "MasterBlockLink_TestCHI",
                            "enable": {
                                "value": ""
                            },
                            "name": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "SA_ProfilesGroup_JKC",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": ""
                        },
                        "children": [{
                            "key": "ZP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "JockeyTrainerProfile",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "MOJ"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/jockeyPZ_i.jpg?rev=76d4604aa275462fb9fa528adad79fd7",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/PZ.ashx?rev=d4288557f2a449f39d8c17ea960ebc9e",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "田泰安"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.ashx?rev=2e25d9bf4afa42e4a00487bc1ae3eab4",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "System configuration"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.ashx?rev=b3bc5c17762d4c2cbfe2492463c9e7c2",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "田泰安"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘頓"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.ashx?rev=ef8ae40f204c4ccc868dd82c5ee68f3a",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "潘頓"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何澤堯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=4cb1095f68ea4425804d1c22e3e92480",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=c64853b1cf4f41b49d2636cbf3a793a2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何澤堯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇兆輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇兆輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "霍宏聲"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=3e8a00bc2d9649bf89d186dcc07d059a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=3dd81988ce2d47da8b717271604d76aa",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "霍宏聲"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘明輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.ashx?rev=d5735b1dc7214f288d8662feb0650a25",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=1f5848ed97ab4023b2c7a7b53ee3b2d2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "潘明輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鍾易禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鍾易禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "班得禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "班得禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "周俊樂"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.ashx?rev=e8968b6e30e6483a91afd6a584138172",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.ashx?rev=f96ff3d1bd3d4f0d849f2f3fc080034d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "周俊樂"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "希威森"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "希威森"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "梁家俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.ashx?rev=dca078b3d5744779b87357cf624e04e1",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.ashx?rev=fde89e94789c4bee94e3fd7a37167534",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "梁家俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "波健士"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.ashx?rev=1d74b08393be4358bc2f8d3f9a15c063",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.ashx?rev=c9fcd68a5d9d46bb933f93a4239b209d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "波健士"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀銘年"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=0e3f360a04844a97a740d88979129bdf",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=a90a44a2abc144e0aed5ba9764fd1633",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀銘年"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡明紹"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.ashx?rev=a7345b055b894b29bec0fd17abb4898c",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.ashx?rev=94d778dee7864db68b6474cecadcf960",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "蔡明紹"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃皓楠"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=fbe47d009c27491ea964dc5cbf0681f2",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.ashx?rev=4a8492b8e94f4b7d9a763704dad35cba",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃皓楠"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巴度"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "巴度"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "嘉里"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "莫雷拉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "莫雷拉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎海榮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.ashx?rev=15dcf3fa92d447daa276b5f2cc419cca",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.ashx?rev=7be1e337890441078a1b2e9f9accfcd1",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黎海榮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巫顯東"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.ashx?rev=96378e0b0ece432195f8aa7c42b4d7e9",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.ashx?rev=b1a9698e14994349829789eac18d3ec7",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "毛顯東"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.ashx?rev=10a359b50b3146a2a98e5bd89a30dcbd",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.ashx?rev=ada2ff50a5414a38ad936c3c589a9f8d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "陳嘉熙"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=00229f99b0274677927c26b0742fd86a",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=e5f4610e938d40689a8add918af91f30",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "陳嘉熙"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "楊明綸"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.ashx?rev=524dc518703047189da38ffbc98be3a8",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.ashx?rev=a4a9b870d1954211b872f7ca0c8c849b",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "楊明綸"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "馬雅"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=8b372a8f029648cf819ab67233a9a10f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=998f0d284bc34853b0b2173644782644",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "馬雅"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "SA_MasterSeasonalJKCTNCInfo",
                    "enable": {
                        "value": ""
                    },
                    "betType": {
                        "value": ""
                    },
                    "dataType": {
                        "value": ""
                    },
                    "headBanner": {
                        "value": ""
                    },
                    "headBannerEnable": {
                        "value": ""
                    },
                    "lastUpdCfg": {
                        "value": ""
                    },
                    "lastUpdText": {
                        "value": ""
                    },
                    "lastUpdEnable": {
                        "value": ""
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": ""
                        },
                        "children": [{
                            "key": "JockeyTrainerProfileItem",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/PZ.ashx?rev=d4288557f2a449f39d8c17ea960ebc9e",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "JockeyTrainerProfileItem1",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }, {
                        "key": "TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "TopLinkItem",
                            "enable": {
                                "value": ""
                            },
                            "name": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "TopLinkItem1",
                            "enable": {
                                "value": ""
                            },
                            "name": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA01",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "Jockeys' Ranking 2022/23(sa testing)<img alt=\"\" mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "* 今季資料 (截至上次賽事日SA): 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王統計SA01"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王坐騎表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日騎師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師資料"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TEK"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "田泰安"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current&b_cid=01"
                            },
                            "pRedirect": {
                                "value": "System configuration"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.ashx?rev=b3bc5c17762d4c2cbfe2492463c9e7c2",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "田泰安"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "PZ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘頓"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.ashx?rev=ef8ae40f204c4ccc868dd82c5ee68f3a",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "潘頓"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HCY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何澤堯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=4cb1095f68ea4425804d1c22e3e92480",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=c64853b1cf4f41b49d2636cbf3a793a2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何澤堯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "DSS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇兆輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇兆輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FEL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "霍宏聲"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=3e8a00bc2d9649bf89d186dcc07d059a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=3dd81988ce2d47da8b717271604d76aa",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "霍宏聲"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "PMF"
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": "潘明輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.ashx?rev=d5735b1dc7214f288d8662feb0650a25",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=1f5848ed97ab4023b2c7a7b53ee3b2d2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "潘明輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鍾易禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鍾易禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BHW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "班得禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "班得禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CJE"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "周俊樂"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.ashx?rev=e8968b6e30e6483a91afd6a584138172",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.ashx?rev=f96ff3d1bd3d4f0d849f2f3fc080034d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "周俊樂"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HEL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "希威森"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "希威森"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LDE"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "梁家俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.ashx?rev=dca078b3d5744779b87357cf624e04e1",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.ashx?rev=fde89e94789c4bee94e3fd7a37167534",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "梁家俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BV"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "波健士"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.ashx?rev=1d74b08393be4358bc2f8d3f9a15c063",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.ashx?rev=c9fcd68a5d9d46bb933f93a4239b209d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "波健士"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀銘年"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=0e3f360a04844a97a740d88979129bdf",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=a90a44a2abc144e0aed5ba9764fd1633",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀銘年"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CML"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡明紹"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.ashx?rev=a7345b055b894b29bec0fd17abb4898c",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.ashx?rev=94d778dee7864db68b6474cecadcf960",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "蔡明紹"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WJH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃皓楠"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=fbe47d009c27491ea964dc5cbf0681f2",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.ashx?rev=4a8492b8e94f4b7d9a763704dad35cba",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃皓楠"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "BA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巴度"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "巴度"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CLR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "嘉里"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MOJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "莫雷拉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "莫雷拉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LHW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎海榮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.ashx?rev=15dcf3fa92d447daa276b5f2cc419cca",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.ashx?rev=7be1e337890441078a1b2e9f9accfcd1",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黎海榮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MHT"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巫顯東"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.ashx?rev=96378e0b0ece432195f8aa7c42b4d7e9",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.ashx?rev=b1a9698e14994349829789eac18d3ec7",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "毛顯東"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WCV"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.ashx?rev=10a359b50b3146a2a98e5bd89a30dcbd",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.ashx?rev=ada2ff50a5414a38ad936c3c589a9f8d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CHA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "陳嘉熙"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=00229f99b0274677927c26b0742fd86a",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=e5f4610e938d40689a8add918af91f30",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "陳嘉熙"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YML"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "楊明綸"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.ashx?rev=524dc518703047189da38ffbc98be3a8",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.ashx?rev=a4a9b870d1954211b872f7ca0c8c849b",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "楊明綸"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MMR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "馬雅"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=8b372a8f029648cf819ab67233a9a10f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=998f0d284bc34853b0b2173644782644",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "馬雅"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA02",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": ""
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "* 今季資料 (截至上次賽事日SA): 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王統計SA02"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王坐騎表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日騎師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師資料"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": "田泰安"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.ashx?rev=2e25d9bf4afa42e4a00487bc1ae3eab4",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "System configuration"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.ashx?rev=b3bc5c17762d4c2cbfe2492463c9e7c2",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "田泰安"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘頓"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.ashx?rev=ef8ae40f204c4ccc868dd82c5ee68f3a",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "潘頓"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何澤堯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=4cb1095f68ea4425804d1c22e3e92480",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=c64853b1cf4f41b49d2636cbf3a793a2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何澤堯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇兆輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇兆輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "霍宏聲"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=3e8a00bc2d9649bf89d186dcc07d059a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=3dd81988ce2d47da8b717271604d76aa",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "霍宏聲"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘明輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.ashx?rev=d5735b1dc7214f288d8662feb0650a25",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=1f5848ed97ab4023b2c7a7b53ee3b2d2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "潘明輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鍾易禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鍾易禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "班得禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "班得禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "周俊樂"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.ashx?rev=e8968b6e30e6483a91afd6a584138172",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.ashx?rev=f96ff3d1bd3d4f0d849f2f3fc080034d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "周俊樂"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "希威森"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "希威森"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "梁家俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.ashx?rev=dca078b3d5744779b87357cf624e04e1",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.ashx?rev=fde89e94789c4bee94e3fd7a37167534",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "梁家俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "波健士"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.ashx?rev=1d74b08393be4358bc2f8d3f9a15c063",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.ashx?rev=c9fcd68a5d9d46bb933f93a4239b209d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "波健士"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀銘年"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=0e3f360a04844a97a740d88979129bdf",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=a90a44a2abc144e0aed5ba9764fd1633",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀銘年"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡明紹"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.ashx?rev=a7345b055b894b29bec0fd17abb4898c",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.ashx?rev=94d778dee7864db68b6474cecadcf960",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "蔡明紹"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃皓楠"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=fbe47d009c27491ea964dc5cbf0681f2",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.ashx?rev=4a8492b8e94f4b7d9a763704dad35cba",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃皓楠"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巴度"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "巴度"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "嘉里"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "莫雷拉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "莫雷拉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎海榮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.ashx?rev=15dcf3fa92d447daa276b5f2cc419cca",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.ashx?rev=7be1e337890441078a1b2e9f9accfcd1",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黎海榮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巫顯東"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.ashx?rev=96378e0b0ece432195f8aa7c42b4d7e9",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.ashx?rev=b1a9698e14994349829789eac18d3ec7",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "毛顯東"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.ashx?rev=10a359b50b3146a2a98e5bd89a30dcbd",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.ashx?rev=ada2ff50a5414a38ad936c3c589a9f8d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "陳嘉熙"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=00229f99b0274677927c26b0742fd86a",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=e5f4610e938d40689a8add918af91f30",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "陳嘉熙"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "楊明綸"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.ashx?rev=524dc518703047189da38ffbc98be3a8",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.ashx?rev=a4a9b870d1954211b872f7ca0c8c849b",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "楊明綸"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "馬雅"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=8b372a8f029648cf819ab67233a9a10f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=998f0d284bc34853b0b2173644782644",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "馬雅"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA03",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": ""
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "* 今季資料 (截至上次賽事日SA): 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王統計SA03"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王坐騎表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日騎師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師資料"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "田泰安"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.ashx?rev=2e25d9bf4afa42e4a00487bc1ae3eab4",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "System configuration"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.ashx?rev=b3bc5c17762d4c2cbfe2492463c9e7c2",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "田泰安"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何澤堯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=4cb1095f68ea4425804d1c22e3e92480",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=c64853b1cf4f41b49d2636cbf3a793a2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何澤堯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇兆輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇兆輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "霍宏聲"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=3e8a00bc2d9649bf89d186dcc07d059a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=3dd81988ce2d47da8b717271604d76aa",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "霍宏聲"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘明輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.ashx?rev=d5735b1dc7214f288d8662feb0650a25",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=1f5848ed97ab4023b2c7a7b53ee3b2d2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "潘明輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鍾易禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鍾易禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "班得禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "班得禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "周俊樂"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.ashx?rev=e8968b6e30e6483a91afd6a584138172",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.ashx?rev=f96ff3d1bd3d4f0d849f2f3fc080034d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "周俊樂"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "希威森"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "希威森"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "梁家俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.ashx?rev=dca078b3d5744779b87357cf624e04e1",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.ashx?rev=fde89e94789c4bee94e3fd7a37167534",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "梁家俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "波健士"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.ashx?rev=1d74b08393be4358bc2f8d3f9a15c063",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.ashx?rev=c9fcd68a5d9d46bb933f93a4239b209d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "波健士"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀銘年"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=0e3f360a04844a97a740d88979129bdf",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=a90a44a2abc144e0aed5ba9764fd1633",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀銘年"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡明紹"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.ashx?rev=a7345b055b894b29bec0fd17abb4898c",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.ashx?rev=94d778dee7864db68b6474cecadcf960",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "蔡明紹"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃皓楠"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=fbe47d009c27491ea964dc5cbf0681f2",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.ashx?rev=4a8492b8e94f4b7d9a763704dad35cba",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃皓楠"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巴度"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "巴度"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "嘉里"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "莫雷拉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "莫雷拉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎海榮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.ashx?rev=15dcf3fa92d447daa276b5f2cc419cca",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.ashx?rev=7be1e337890441078a1b2e9f9accfcd1",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黎海榮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巫顯東"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.ashx?rev=96378e0b0ece432195f8aa7c42b4d7e9",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.ashx?rev=b1a9698e14994349829789eac18d3ec7",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "毛顯東"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.ashx?rev=10a359b50b3146a2a98e5bd89a30dcbd",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.ashx?rev=ada2ff50a5414a38ad936c3c589a9f8d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "陳嘉熙"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=00229f99b0274677927c26b0742fd86a",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=e5f4610e938d40689a8add918af91f30",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "陳嘉熙"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "楊明綸"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.ashx?rev=524dc518703047189da38ffbc98be3a8",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.ashx?rev=a4a9b870d1954211b872f7ca0c8c849b",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "楊明綸"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "馬雅"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=8b372a8f029648cf819ab67233a9a10f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=998f0d284bc34853b0b2173644782644",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "馬雅"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA04",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": ""
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "* 今季資料 (截至上次賽事日SA): 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王統計SA04"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王坐騎表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日騎師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師資料"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "田泰安"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.ashx?rev=2e25d9bf4afa42e4a00487bc1ae3eab4",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "System configuration"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.ashx?rev=b3bc5c17762d4c2cbfe2492463c9e7c2",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "田泰安"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何澤堯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=4cb1095f68ea4425804d1c22e3e92480",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=c64853b1cf4f41b49d2636cbf3a793a2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何澤堯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇兆輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇兆輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "霍宏聲"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=3e8a00bc2d9649bf89d186dcc07d059a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=3dd81988ce2d47da8b717271604d76aa",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "霍宏聲"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘明輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.ashx?rev=d5735b1dc7214f288d8662feb0650a25",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=1f5848ed97ab4023b2c7a7b53ee3b2d2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "潘明輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鍾易禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鍾易禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "班得禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "班得禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "周俊樂"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.ashx?rev=e8968b6e30e6483a91afd6a584138172",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.ashx?rev=f96ff3d1bd3d4f0d849f2f3fc080034d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "周俊樂"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "希威森"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "希威森"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "梁家俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.ashx?rev=dca078b3d5744779b87357cf624e04e1",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.ashx?rev=fde89e94789c4bee94e3fd7a37167534",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "梁家俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "波健士"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.ashx?rev=1d74b08393be4358bc2f8d3f9a15c063",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.ashx?rev=c9fcd68a5d9d46bb933f93a4239b209d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "波健士"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀銘年"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=0e3f360a04844a97a740d88979129bdf",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=a90a44a2abc144e0aed5ba9764fd1633",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀銘年"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡明紹"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.ashx?rev=a7345b055b894b29bec0fd17abb4898c",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.ashx?rev=94d778dee7864db68b6474cecadcf960",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "蔡明紹"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃皓楠"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=fbe47d009c27491ea964dc5cbf0681f2",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.ashx?rev=4a8492b8e94f4b7d9a763704dad35cba",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃皓楠"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巴度"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "巴度"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "嘉里"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "莫雷拉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "莫雷拉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎海榮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.ashx?rev=15dcf3fa92d447daa276b5f2cc419cca",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.ashx?rev=7be1e337890441078a1b2e9f9accfcd1",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黎海榮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巫顯東"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.ashx?rev=96378e0b0ece432195f8aa7c42b4d7e9",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.ashx?rev=b1a9698e14994349829789eac18d3ec7",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "毛顯東"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.ashx?rev=10a359b50b3146a2a98e5bd89a30dcbd",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.ashx?rev=ada2ff50a5414a38ad936c3c589a9f8d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "陳嘉熙"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=00229f99b0274677927c26b0742fd86a",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=e5f4610e938d40689a8add918af91f30",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "陳嘉熙"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "楊明綸"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.ashx?rev=524dc518703047189da38ffbc98be3a8",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.ashx?rev=a4a9b870d1954211b872f7ca0c8c849b",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "楊明綸"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "馬雅"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=8b372a8f029648cf819ab67233a9a10f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=998f0d284bc34853b0b2173644782644",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "馬雅"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA05",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": ""
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "* 今季資料 (截至上次賽事日SA): 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王統計SA05"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王坐騎表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日騎師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師資料"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "田泰安"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.ashx?rev=2e25d9bf4afa42e4a00487bc1ae3eab4",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "System configuration"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.ashx?rev=b3bc5c17762d4c2cbfe2492463c9e7c2",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "田泰安"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘頓"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.ashx?rev=ef8ae40f204c4ccc868dd82c5ee68f3a",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "潘頓"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何澤堯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=4cb1095f68ea4425804d1c22e3e92480",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=c64853b1cf4f41b49d2636cbf3a793a2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何澤堯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇兆輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇兆輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "霍宏聲"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=3e8a00bc2d9649bf89d186dcc07d059a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=3dd81988ce2d47da8b717271604d76aa",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "霍宏聲"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘明輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.ashx?rev=d5735b1dc7214f288d8662feb0650a25",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=1f5848ed97ab4023b2c7a7b53ee3b2d2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "潘明輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鍾易禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鍾易禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "班得禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "班得禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "周俊樂"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.ashx?rev=e8968b6e30e6483a91afd6a584138172",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.ashx?rev=f96ff3d1bd3d4f0d849f2f3fc080034d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "周俊樂"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "希威森"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "希威森"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "梁家俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.ashx?rev=dca078b3d5744779b87357cf624e04e1",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.ashx?rev=fde89e94789c4bee94e3fd7a37167534",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "梁家俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "波健士"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.ashx?rev=1d74b08393be4358bc2f8d3f9a15c063",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.ashx?rev=c9fcd68a5d9d46bb933f93a4239b209d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "波健士"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀銘年"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=0e3f360a04844a97a740d88979129bdf",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=a90a44a2abc144e0aed5ba9764fd1633",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀銘年"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡明紹"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.ashx?rev=a7345b055b894b29bec0fd17abb4898c",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.ashx?rev=94d778dee7864db68b6474cecadcf960",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "蔡明紹"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃皓楠"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=fbe47d009c27491ea964dc5cbf0681f2",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.ashx?rev=4a8492b8e94f4b7d9a763704dad35cba",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃皓楠"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巴度"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "巴度"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "嘉里"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "莫雷拉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "莫雷拉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎海榮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.ashx?rev=15dcf3fa92d447daa276b5f2cc419cca",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.ashx?rev=7be1e337890441078a1b2e9f9accfcd1",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黎海榮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巫顯東"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.ashx?rev=96378e0b0ece432195f8aa7c42b4d7e9",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.ashx?rev=b1a9698e14994349829789eac18d3ec7",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "毛顯東"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.ashx?rev=10a359b50b3146a2a98e5bd89a30dcbd",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.ashx?rev=ada2ff50a5414a38ad936c3c589a9f8d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "陳嘉熙"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=00229f99b0274677927c26b0742fd86a",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=e5f4610e938d40689a8add918af91f30",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "陳嘉熙"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "楊明綸"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.ashx?rev=524dc518703047189da38ffbc98be3a8",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.ashx?rev=a4a9b870d1954211b872f7ca0c8c849b",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "楊明綸"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MMR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": "馬雅"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMMR_i.jpg?rev=8b372a8f029648cf819ab67233a9a10f",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyMMR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MMR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMMR.jpg?rev=998f0d284bc34853b0b2173644782644",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "馬雅"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "JKC_Scenario_SA06",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "JKC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{65180306-5CA1-479F-B809-22BE6C5A9689}\" />"
                    },
                    "headBannerEnable": {
                        "value": ""
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "* 今季資料 (截至上次賽事日SA): 11/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_JKC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王統計SA06"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/jkc/JkcStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Scheduled Rides List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王坐騎表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Racing/JKCScheduledRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/jkc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Rides For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日騎師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/JockeysRides.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_JKC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/JKC/JKCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/jockey-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Jockeys Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "騎師資料"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_TEK",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "田泰安"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyTEK_i.ashx?rev=2e25d9bf4afa42e4a00487bc1ae3eab4",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=TEK&Season=Current"
                            },
                            "pRedirect": {
                                "value": "System configuration"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyTEK.ashx?rev=b3bc5c17762d4c2cbfe2492463c9e7c2",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "田泰安"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PZ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘頓"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPZ_i.jpg?rev=c4299060c93f49d883024d17c9e79a98",
                                "width": 88,
                                "height": 71,
                                "alt": "jockeyPZ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PZ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPZ.ashx?rev=ef8ae40f204c4ccc868dd82c5ee68f3a",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "潘頓"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "SA_testingDefaultImage",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": ""
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": ""
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": ""
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": ""
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何澤堯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHCY_i.jpg?rev=4cb1095f68ea4425804d1c22e3e92480",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHCY.jpg?rev=c64853b1cf4f41b49d2636cbf3a793a2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何澤堯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_DSS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇兆輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyDSS_i.jpg?rev=ef5a508b060d468c8a937c0c71366167",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyDSS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=DSS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyDSS.jpg?rev=7c2e2e0628d64a22865d4e21119e6f31",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇兆輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "霍宏聲"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyFEL_i.jpg?rev=3e8a00bc2d9649bf89d186dcc07d059a",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyFEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=FEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyFEL.jpg?rev=3dd81988ce2d47da8b717271604d76aa",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "霍宏聲"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_PMF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "潘明輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyPMF_i.ashx?rev=d5735b1dc7214f288d8662feb0650a25",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=PMF&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyPMF.jpg?rev=1f5848ed97ab4023b2c7a7b53ee3b2d2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "潘明輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鍾易禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCCY_i.jpg?rev=2799d1981020460c85da9990ef8fd185",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCCY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CCY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCCY.jpg?rev=b3a10a105f464570819caa88a5de9890",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鍾易禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "班得禮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBHW_i.jpg?rev=58a58a51a72148a1a5e4067de4978a1b",
                                "width": 87,
                                "height": 71,
                                "alt": "jockeyBHW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBHW.jpg?rev=b5864a116fa248fb8ca9f86f3b8430a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "班得禮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CJE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "周俊樂"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCJE_i.ashx?rev=e8968b6e30e6483a91afd6a584138172",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CJE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCJE.ashx?rev=f96ff3d1bd3d4f0d849f2f3fc080034d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "周俊樂"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HEL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "希威森"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHEL_i.jpg?rev=e454df6dab844b47b6e91bb76bf3639c",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyHEL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HEL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHEL.jpg?rev=e33c45ef6e524da8b3eb43769e821e05",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "希威森"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LDE",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "梁家俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLDE_i.ashx?rev=dca078b3d5744779b87357cf624e04e1",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LDE&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLDE.ashx?rev=fde89e94789c4bee94e3fd7a37167534",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "梁家俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "波健士"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBV_i.ashx?rev=1d74b08393be4358bc2f8d3f9a15c063",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBV.ashx?rev=c9fcd68a5d9d46bb933f93a4239b209d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "波健士"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀銘年"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyHAA_i.jpg?rev=0e3f360a04844a97a740d88979129bdf",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyHAA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=HAA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyHAA.jpg?rev=a90a44a2abc144e0aed5ba9764fd1633",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀銘年"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡明紹"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCML_i_1.ashx?rev=a7345b055b894b29bec0fd17abb4898c",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCML_1.ashx?rev=94d778dee7864db68b6474cecadcf960",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "蔡明紹"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WJH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃皓楠"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWJH_i.jpg?rev=fbe47d009c27491ea964dc5cbf0681f2",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyWJH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CML&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWJH.ashx?rev=4a8492b8e94f4b7d9a763704dad35cba",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃皓楠"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_BA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巴度"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyBA_i.jpg?rev=879cfb021aaa48a68632f2549534adfd",
                                "width": 87,
                                "height": 70,
                                "alt": "jockeyBA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=BA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyBA.jpg?rev=d6ba24f880b54407aa3d713d1a8f8303",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "巴度"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CLR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "嘉里"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCLR_i.jpg?rev=06cb75d853de47d58791df9c1161b103",
                                "width": 88,
                                "height": 70,
                                "alt": "jockeyCLR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CLR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCLR.jpg?rev=3a64926803354fc98925dc903d1bea70",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "嘉里"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MOJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "莫雷拉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i.jpg?rev=a12d9f8e42814d008e0fd6a5ac341d1c",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyMOJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MOJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/MOJ/jockeyMOJ_i_original/jockeyMOJ_original/jockeyMOJ.jpg?rev=48b1c71c29b34c0580261fd9894500df",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "莫雷拉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LHW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎海榮"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyLHW_i.ashx?rev=15dcf3fa92d447daa276b5f2cc419cca",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=LHW&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyLHW.ashx?rev=7be1e337890441078a1b2e9f9accfcd1",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黎海榮"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MHT",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "巫顯東"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyMHT_i.ashx?rev=96378e0b0ece432195f8aa7c42b4d7e9",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=MHT&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyMHT.ashx?rev=b1a9698e14994349829789eac18d3ec7",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "毛顯東"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WCV",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黃俊"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyWCV_i.ashx?rev=10a359b50b3146a2a98e5bd89a30dcbd",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=WCV&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyWCV.ashx?rev=ada2ff50a5414a38ad936c3c589a9f8d",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "黃俊"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CHA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "陳嘉熙"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyCHA_i.jpg?rev=00229f99b0274677927c26b0742fd86a",
                                "width": 59,
                                "height": 45,
                                "alt": "jockeyCHA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=CHA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyCHA.jpg?rev=e5f4610e938d40689a8add918af91f30",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "陳嘉熙"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YML",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": ""
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "楊明綸"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/jockeyYML_i.ashx?rev=524dc518703047189da38ffbc98be3a8",
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Jockey/JockeyPastRec.aspx?JockeyCode=YML&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/jockeyYML.ashx?rev=a4a9b870d1954211b872f7ca0c8c849b",
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "楊明綸"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA01",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "2022/23 練馬師榜 （SA testing)<img alt=\"\" mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "今季資料 (截至上次賽事日SA): 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王統計"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王出馬表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日練馬師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師資料01"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "大衛希斯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "大衛希斯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "姚本輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "姚本輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "沈集成"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "沈集成"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "方嘉柏"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "方嘉柏"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CAS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CAS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "告東尼"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCAS_i.jpg?rev=9b53f81622b54c7aad33990765e6c6a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerCAS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CAS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCAS.jpg?rev=b4b3cfb74b3249489cb42b9aae617937",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "告東尼"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇偉賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇偉賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鄭俊偉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鄭俊偉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HAD&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "伍鵬志"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "伍鵬志"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "文家良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "文家良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "容天鵬"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "容天鵬"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "呂健威"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "呂健威"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "羅富全"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "羅富全"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "徐雨石"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "徐雨石"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "韋達"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "韋達"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "苗禮德"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "苗禮德"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "丁冠豪"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "丁冠豪"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡約翰"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蔡約翰"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "葉楚航"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "葉楚航"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": " 高伯新"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": " 高伯新"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎昭昇"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=RW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "黎昭昇"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA02",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "今季資料 (截至上次賽事日SA): 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王統計"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王出馬表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日練馬師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師資料01"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "大衛希斯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "大衛希斯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "姚本輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "姚本輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": "沈集成"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "沈集成"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "方嘉柏"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "方嘉柏"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CAS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CAS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "告東尼"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCAS_i.jpg?rev=9b53f81622b54c7aad33990765e6c6a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerCAS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CAS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCAS.jpg?rev=b4b3cfb74b3249489cb42b9aae617937",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "告東尼"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇偉賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇偉賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鄭俊偉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鄭俊偉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HAD&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "伍鵬志"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "伍鵬志"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "文家良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "文家良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "容天鵬"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "容天鵬"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "呂健威"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "呂健威"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "羅富全"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "羅富全"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "徐雨石"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "徐雨石"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "韋達"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "韋達"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "苗禮德"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "苗禮德"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "丁冠豪"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "丁冠豪"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡約翰"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蔡約翰"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "葉楚航"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "葉楚航"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": " 高伯新"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": " 高伯新"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎昭昇"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=RW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "黎昭昇"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA03",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "今季資料 (截至上次賽事日SA): 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王統計"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王出馬表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日練馬師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師資料03"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "大衛希斯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "大衛希斯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "姚本輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "姚本輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": "沈集成"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "沈集成"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "方嘉柏"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "方嘉柏"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇偉賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇偉賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鄭俊偉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鄭俊偉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HAD&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "伍鵬志"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "伍鵬志"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "文家良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "文家良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "容天鵬"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "容天鵬"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "呂健威"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "呂健威"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "羅富全"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "羅富全"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "徐雨石"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "徐雨石"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "韋達"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "韋達"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "苗禮德"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "苗禮德"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "丁冠豪"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "丁冠豪"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡約翰"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蔡約翰"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "葉楚航"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "葉楚航"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": " 高伯新"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": " 高伯新"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎昭昇"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=RW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "黎昭昇"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA04",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "今季資料 (截至上次賽事日SA): 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王統計"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王出馬表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日練馬師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師資料03"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "User input"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "大衛希斯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "大衛希斯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "姚本輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "姚本輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": "沈集成"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "沈集成"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "方嘉柏"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "方嘉柏"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇偉賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇偉賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鄭俊偉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鄭俊偉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HAD&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "伍鵬志"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "伍鵬志"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "文家良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "文家良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "容天鵬"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "容天鵬"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "呂健威"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "呂健威"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "羅富全"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "羅富全"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "徐雨石"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "徐雨石"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "韋達"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "韋達"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "苗禮德"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "苗禮德"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "丁冠豪"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "丁冠豪"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡約翰"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蔡約翰"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "葉楚航"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "葉楚航"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": " 高伯新"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": " 高伯新"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "黎昭昇"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=RW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "黎昭昇"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA05",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "今季資料 (截至上次賽事日SA): 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王統計"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王出馬表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日練馬師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師資料01"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "大衛希斯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "大衛希斯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "姚本輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "姚本輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "沈集成"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "沈集成"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "方嘉柏"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "方嘉柏"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CAS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CAS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "告東尼"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCAS_i.jpg?rev=9b53f81622b54c7aad33990765e6c6a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerCAS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CAS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCAS.jpg?rev=b4b3cfb74b3249489cb42b9aae617937",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "告東尼"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇偉賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇偉賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鄭俊偉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鄭俊偉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HAD&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "伍鵬志"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "伍鵬志"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "文家良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "文家良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "容天鵬"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "容天鵬"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "呂健威"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "呂健威"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "羅富全"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "羅富全"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "徐雨石"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "徐雨石"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "韋達"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "韋達"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "苗禮德"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "苗禮德"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "丁冠豪"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "丁冠豪"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡約翰"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蔡約翰"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "葉楚航"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "葉楚航"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": " 高伯新"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": " 高伯新"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_RW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "RW"
                            },
                            "pEnable": {
                                "value": ""
                            },
                            "pName": {
                                "value": "黎昭昇"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerRW_i.jpg?rev=7b8db2b68a894b18aaca3a1cf8eb39e8",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerRW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=RW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerRW.jpg?rev=5d23c9cabc104fb49ca7f8c501f2f9a4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "黎昭昇"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }, {
                    "key": "TNC_Scenario_SA06",
                    "enable": {
                        "value": "1"
                    },
                    "betType": {
                        "value": "TNC"
                    },
                    "dataType": {
                        "value": "Regular Season"
                    },
                    "headBanner": {
                        "value": "<image mediaid=\"{CBBD203F-59C1-4865-81FE-54797ADFF4BF}\" />"
                    },
                    "headBannerEnable": {
                        "value": "1"
                    },
                    "lastUpdCfg": {
                        "value": "User input"
                    },
                    "lastUpdText": {
                        "value": "今季資料 (截至上次賽事日SA): 18/09/2022"
                    },
                    "lastUpdEnable": {
                        "value": "1"
                    },
                    "rmkBanner": {
                        "src": null,
                        "width": null,
                        "height": null,
                        "alt": ""
                    },
                    "rmkBannerEnable": {
                        "value": ""
                    },
                    "ijcInfoText": {
                        "value": ""
                    },
                    "ijcLinkText": {
                        "value": ""
                    },
                    "ijcLinkUrl": {
                        "value": ""
                    },
                    "ijcEnable": {
                        "value": ""
                    },
                    "children": [{
                        "key": "Int_TopLinkGroup",
                        "template": {
                            "name": "TopLinkGroup"
                        },
                        "seq": null,
                        "children": [{
                            "key": "Int_TNC Statistics",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王統計"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/tnc/TncStat.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Entries List",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王出馬表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/TNCEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Odds Chart",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王賠率走勢圖"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/tnc-odds-chart.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Entries For The Day",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "全日練馬師分場表"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/racing/TrainersEntries.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_TNC Standings",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師王積分"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/TNC/TNCResult.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_How to Play",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "玩法"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Selling Hours",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "受注時間"
                            },
                            "itemLink": {
                                "value": "https://campaigns.hkjc.com/trainer-challenge/ch/selling-hours"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Trainers Info",
                            "enable": {
                                "value": "1"
                            },
                            "name": {
                                "value": "練馬師資料01"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerRanking.aspx"
                            },
                            "icon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "pCode": null,
                            "pEnable": null,
                            "pName": null,
                            "pIcon": null,
                            "pImage": null,
                            "pImageLink": null,
                            "pRedirect": null,
                            "pMOEnable": null,
                            "pMOImage": null,
                            "pMOName": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "key": "Int_ProfilesGroup",
                        "template": {
                            "name": "ProfilesGroup"
                        },
                        "seq": {
                            "value": "System configuration"
                        },
                        "children": [{
                            "key": "Int_HDA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HDA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "大衛希斯"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHDA_i.jpg?rev=a03f5e4f1def45fba6decca6dc34fb14",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHDA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.qcew.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HDA&Season=Current"
                            },
                            "pRedirect": {
                                "value": "User input"
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHDA.jpg?rev=6d4104cb099c4d32a0b9f7d801dc420f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "大衛希斯"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YPF",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YPF"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "姚本輝"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYPF_i.jpg?rev=4921edc7177c436fac08246f13a9e424",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYPF_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YPF&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYPF.jpg?rev=acd101df9caa477ba1967336dd36c9c2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "姚本輝"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SCS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SCS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "沈集成"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/Sites/JCBW/ChallengeBlockIcons/JCKprofile_default-image.svg?rev=186c73352c5f40e68a47957bde4a758e",
                                "width": null,
                                "height": null,
                                "alt": "JCKprofile_default image"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SCS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "pMOName": {
                                "value": "沈集成"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_FC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "FC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "方嘉柏"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerFC_i.jpg?rev=a8a471aad03c48af99eb276051ea5f63",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=FC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerFC.jpg?rev=ee3cc6257dd34628830113c6fd79c5ae",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "方嘉柏"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CAS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CAS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "告東尼"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCAS_i.jpg?rev=9b53f81622b54c7aad33990765e6c6a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerCAS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CAS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCAS.jpg?rev=b4b3cfb74b3249489cb42b9aae617937",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "告東尼"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SWY",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SWY"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蘇偉賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSWY_i.jpg?rev=813696e975664a01875802c8f3faeaeb",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerSWY_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SWY&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSWY.jpg?rev=9a6683a282664c00ab845177c3266a62",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蘇偉賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_CCW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "CCW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "鄭俊偉"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerCCW_i.jpg?rev=4023bfc1b5254950b4a115552cb7dd1d",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerCCW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=CCW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerCCW.jpg?rev=811aa1bc40a3482587009d02c2c54fb3",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "鄭俊偉"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HAD",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HAD"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "賀賢"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHAD_i.jpg?rev=a5b5dbcc8cbd4f4bbea2c3e0de4d5c64",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerHAD_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HAD&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHAD.jpg?rev=8a956b4f7cf04b16a53b5c611492b1a0",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "賀賢"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_NPC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "NPC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "伍鵬志"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerNPC_i.jpg?rev=aac5e00d57da48a88b40fb073040b19d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerNPC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=NPC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerNPC.jpg?rev=2595157b79aa4f218475c49b49931aa2",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "伍鵬志"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MKL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MKL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "文家良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMKL_i.jpg?rev=c8fc93a70ab84ae19da5c148bfa2016e",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMKL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MKL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMKL.jpg?rev=e1fbb4619ca64fd8841a727bc47cee67",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "文家良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YTP",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YTP"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "容天鵬"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYTP_i.jpg?rev=079aec10b8824c259255357ab6cc4790",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYTP_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YTP&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYTP.jpg?rev=fc60eb3a3738413287b2f480b6bb5838",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "容天鵬"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LKW",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LKW"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "呂健威"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLKW_i.jpg?rev=dbdb9a866a2b46a583ca8a24ef81e5c9",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerLKW_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LKW&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLKW.jpg?rev=c0e134833655465380fb8b54767dc7b9",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "呂健威"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_LFC",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "LFC"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "羅富全"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerLFC_i.jpg?rev=db542bd6c25849dc8c2d01474972275d",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerLFC_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=LFC&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerLFC.jpg?rev=23aadf5b666f46199872bd8fb388d14f",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "羅富全"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TYS",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TYS"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "徐雨石"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTYS_i.jpg?rev=831c7295604b45e29cc4bcb619860919",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTYS_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TYS&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTYS.jpg?rev=a8a77f5dbe404995bc77dfb8e0fc3db4",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "徐雨石"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_WDJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "WDJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "韋達"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerWDJ_i.jpg?rev=b0d8afa9ba81453c9e672c4587abca03",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerWDJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=WDJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerWDJ.jpg?rev=b63ccc6c9e604577aac2469c22d9842c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "韋達"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_HL",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "HL"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "何良"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerHL_i.jpg?rev=2feff886e76f4c9d8e8f9f5d1eef119c",
                                "width": 88,
                                "height": 70,
                                "alt": "trainerHL_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=HL&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerHL.jpg?rev=5636673ddf27405fbeaae697995793a8",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "何良"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_MA",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "MA"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "苗禮德"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerMA_i.jpg?rev=4df40e8075da4846a6096010cbe789a6",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerMA_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=MA&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerMA.jpg?rev=07731a20b041414b853af83535562e2c",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "苗禮德"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_TKH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "TKH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "丁冠豪"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerTKH_i.jpg?rev=824b833acb81427bb81e607da1961a3b",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerTKH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=TKH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerTKH.jpg?rev=c740ea4ea092415fb3f6d18f731ae574",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "丁冠豪"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_SJJ",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "SJJ"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "蔡約翰"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerSJJ_i.jpg?rev=100ee0a0357c42b6a7fb4ee3f0599c29",
                                "width": 88,
                                "height": 71,
                                "alt": "trainerSJJ_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=SJJ&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerSJJ.jpg?rev=ebde7552204e41feb86c5626acbe4213",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "蔡約翰"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_YCH",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "YCH"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": "葉楚航"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerYCH_i.jpg?rev=2bd99659558b4fe29d698b954de84ea9",
                                "width": 59,
                                "height": 45,
                                "alt": "trainerYCH_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=YCH&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerYCH.jpg?rev=d5b32e4fe7f149e9b9128955f61870f5",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": "葉楚航"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }, {
                            "key": "Int_GR",
                            "enable": null,
                            "name": null,
                            "itemLink": null,
                            "icon": null,
                            "redirectMethod": null,
                            "pH": null,
                            "pW": null,
                            "pX": null,
                            "pY": null,
                            "pCode": {
                                "value": "GR"
                            },
                            "pEnable": {
                                "value": "1"
                            },
                            "pName": {
                                "value": " 高伯新"
                            },
                            "pIcon": {
                                "src": null,
                                "width": null,
                                "height": null,
                                "alt": ""
                            },
                            "pImage": {
                                "src": "/-/media/trainerGR_i.jpg?rev=7c2341aa8a274520a6e28d2a2da7e71f",
                                "width": 87,
                                "height": 70,
                                "alt": "trainerGR_i"
                            },
                            "pImageLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Trainers/TrainerPastRec.aspx?TrainerCode=GR&Season=Current"
                            },
                            "pRedirect": {
                                "value": ""
                            },
                            "pMOEnable": {
                                "value": "1"
                            },
                            "pMOImage": {
                                "src": "/-/media/trainerGR.jpg?rev=95b4a73cbbfd4bdbb7a9e54b79061afc",
                                "width": 88,
                                "height": 100
                            },
                            "pMOName": {
                                "value": " 高伯新"
                            },
                            "GlassboxCustomEventName": null,
                            "GlassboxCustomEventValue": null,
                            "GlassboxTaggingEnabled": null
                        }]
                    }]
                }]
            }
        }
    }
}