import React from 'react';
import { RotatingLines } from 'react-loader-spinner';
import './Loading.scss';

const Loading = ({ className = '', style = {} }, config = {}) => {
    return (
        <div className={`loading-container ${className}`} style={style}>
            <RotatingLines
                strokeColor="#444"
                strokeWidth="4"
                animationDuration="1"
                width="35"
                visible={true}
                {...config}
            />
        </div>
    );
};

export default Loading;
