let ftcLangIndex = 1;
export function ftcLangFn(val) {
    ftcLangIndex = val;
    if (formulainfo.length === 0) {
        initFormula()
    }
    return
}
export const formulaHeader = [["第一關", "第二關", "第三關", "第四關", "第五關", "第六關", "第七關", "第八關"], ["1st Leg", "2nd Leg", "3rd Leg", "4th Leg", "5th Leg", "6th Leg", "7th Leg", "8th Leg"]];
export const chanceHeader = [["過一關", "過兩關", "過三關", "過四關", "過五關", "過六關", "過七關", "過八關"], ["1x1", "2x1", "3x1", "4x1", "5x1", "6x1", "7x1", "8x1"]];
export const formulaText = [
    ["2串1", "2串3", "3串1", "3串3", "3串4", "3串6", "3串7", "4串1", "4串4", "4串5",
        "4串6", "4串10", "4串11", "4串14", "4串15", "5串1", "5串5", "5串6", "5串10", "5串15",
        "5串16", "5串20", "5串25", "5串26", "5串30", "5串31", "6串1", "6串6", "6串7", "6串15",
        "6串20", "6串21", "6串22", "6串35", "6串41", "6串42", "6串50", "6串56", "6串57", "6串62",
        "6串63", "7串1", "7串7", "7串8", "7串21", "7串35", "7串120", "7串127", "8串1", "8串8",
        "8串9", "8串28", "8串56", "8串70", "8串247", "8串255"],
    ["2x1", "2x3", "3x1", "3x3", "3x4", "3x6", "3x7", "4x1", "4x4", "4x5",
        "4x6", "4x10", "4x11", "4x14", "4x15", "5x1", "5x5", "5x6", "5x10", "5x15",
        "5x16", "5x20", "5x25", "5x26", "5x30", "5x31", "6x1", "6x6", "6x7", "6x15",
        "6x20", "6x21", "6x22", "6x35", "6x41", "6x42", "6x50", "6x56", "6x57", "6x62",
        "6x63", "7x1", "7x7", "7x8", "7x21", "7x35", "7x120", "7x127", "8x1", "8x8",
        "8x9", "8x28", "8x56", "8x70", "8x247", "8x255"]];
export const ftBetTypeText = [["HAD (主客和)", "FHAD (半場主客和)", "HHAD (讓球主客和)", "HDC (讓球)", "HILO (入球大細)", "FHL (半場入球大細)", "CHLO (開出角球大細)", "FCHLO (半場開出角球大細)", "CRS (波膽)", "FCS (半場波膽)", "FTS (第一隊入球)", "TTG (總入球)", "OOE (入球單雙)", "FGS (首名入球)", "HFT (半全場)"], ["HAD (Home/Away/Draw)", "FHAD (First Half HAD)", "HHAD (Handicap HAD)", "HDC (Handicap)", "HILO (HiLo)", "FHL (First Half HiLo)", "CHLO (Corner Taken HiLo)", "FCHLO (First Half Corner Taken HiLo)", "CRS (Correct Score)", "FCS (First Half Correct Score)", "FTS (First Team to Score)", "TTG (Total Goals)", "OOE (Odds Even)", "FGS (First Scorer)", "HFT (HaFu)"]];
export const unitbetrange = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 1000, 2000];

export let legchance = new Array(0, 0, 0, 0, 0, 0, 0);
export let allupchance = new Array(0, 0, 0, 0, 0, 0, 0);

function formulaitem(icode, ichoice, imaxleg, ileg1, ileg2, ileg3, ileg4, ileg5, ileg6, ileg7, ileg8) {
    this.code = icode;
    this.choice = ichoice;
    this.maxleg = imaxleg;
    this.leg1 = ileg1;
    this.leg2 = ileg2;
    this.leg3 = ileg3;
    this.leg4 = ileg4;
    this.leg5 = ileg5;
    this.leg6 = ileg6;
    this.leg7 = ileg7;
    this.leg8 = ileg8;
}//  End formulaitem()

export let formulainfo = new Array();
export let formulamap = new Array();

function initFormula() {
    let langIdx = ftcLangIndex;

    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][0], 2, 2, 0, 1, 0, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][1], 2, 2, 2, 1, 0, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][2], 3, 3, 0, 0, 1, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][3], 3, 2, 0, 3, 0, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][4], 3, 3, 0, 3, 1, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][5], 3, 2, 3, 3, 0, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][6], 3, 3, 3, 3, 1, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][7], 4, 4, 0, 0, 0, 1, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][8], 4, 3, 0, 0, 4, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][9], 4, 4, 0, 0, 4, 1, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][10], 4, 2, 0, 6, 0, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][11], 4, 2, 4, 6, 0, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][12], 4, 4, 0, 6, 4, 1, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][13], 4, 3, 4, 6, 4, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][14], 4, 4, 4, 6, 4, 1, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][15], 5, 5, 0, 0, 0, 0, 1, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][16], 5, 4, 0, 0, 0, 5, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][17], 5, 5, 0, 0, 0, 5, 1, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][18], 5, 2, 0, 10, 0, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][19], 5, 2, 5, 10, 0, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][20], 5, 5, 0, 0, 10, 5, 1, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][21], 5, 3, 0, 10, 10, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][22], 5, 3, 5, 10, 10, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][23], 5, 5, 0, 10, 10, 5, 1, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][24], 5, 4, 5, 10, 10, 5, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][25], 5, 5, 5, 10, 10, 5, 1, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][26], 6, 6, 0, 0, 0, 0, 0, 1, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][27], 6, 5, 0, 0, 0, 0, 6, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][28], 6, 6, 0, 0, 0, 0, 6, 1, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][29], 6, 2, 0, 15, 0, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][30], 6, 3, 0, 0, 20, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][31], 6, 2, 6, 15, 0, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][32], 6, 6, 0, 0, 0, 15, 6, 1, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][33], 6, 3, 0, 15, 20, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][34], 6, 3, 6, 15, 20, 0, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][35], 6, 6, 0, 0, 20, 15, 6, 1, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][36], 6, 4, 0, 15, 20, 15, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][37], 6, 4, 6, 15, 20, 15, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][38], 6, 6, 0, 15, 20, 15, 6, 1, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][39], 6, 5, 6, 15, 20, 15, 6, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][40], 6, 6, 6, 15, 20, 15, 6, 1, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][41], 7, 7, 0, 0, 0, 0, 0, 0, 1, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][42], 7, 7, 0, 0, 0, 0, 0, 7, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][43], 7, 7, 0, 0, 0, 0, 0, 7, 1, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][44], 7, 7, 0, 0, 0, 0, 21, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][45], 7, 7, 0, 0, 0, 35, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][46], 7, 7, 0, 21, 35, 35, 21, 7, 1, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][47], 7, 7, 7, 21, 35, 35, 21, 7, 1, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][48], 8, 8, 0, 0, 0, 0, 0, 0, 0, 1);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][49], 8, 8, 0, 0, 0, 0, 0, 0, 8, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][50], 8, 8, 0, 0, 0, 0, 0, 0, 8, 1);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][51], 8, 8, 0, 0, 0, 0, 0, 28, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][52], 8, 8, 0, 0, 0, 0, 56, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][53], 8, 8, 0, 0, 0, 70, 0, 0, 0, 0);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][54], 8, 8, 0, 28, 56, 70, 56, 28, 8, 1);
    formulainfo[formulainfo.length] = new formulaitem(formulaText[langIdx][55], 8, 8, 8, 28, 56, 70, 56, 28, 8, 1);
    for (let i = 0; i < formulainfo.length; i++) {
        formulamap[formulainfo[i].code] = formulainfo[i];
        let sum = formulainfo[i].leg1 + formulainfo[i].leg2 + formulainfo[i].leg3 + formulainfo[i].leg4 + formulainfo[i].leg5 + formulainfo[i].leg6 + formulainfo[i].leg7 + formulainfo[i].leg8;
        let text = !ftcLangIndex ? (formulainfo[i].choice + "串" + sum) : (formulainfo[i].choice + "x" + sum);
        // if (formulainfo[i].code != text) {
        //     console.log("formulainfo " + formulainfo[i].code + " mismatch!");
        // }
    }

}// End initFormula()

export let selectedFormula = '';

export function setFtCalcBetVaue(value, type) {
    if (type === 'selectedFormula') { selectedFormula = value }
    if (type === 'legchance') { legchance = value }
    if (type === 'allupchanceFn') { computeAllupChance() }
}

export function computeAllupChance() {
    let subtotal = 0;
    let count = 0;


    // 1 Leg
    subtotal = 0;
    count = 0;
    if (formulamap[selectedFormula].leg1 > 0) {
        for (let i = 1; i <= formulamap[selectedFormula].choice; i++) {
            subtotal += legchance[i];
            count++;
        }// End for
    }// End if
    if (count != formulamap[selectedFormula].leg1) alert("Counted " + count + " chance, but " + formulamap[selectedFormula].leg1 + " expected.");
    allupchance[1] = subtotal;

    // 2 Legs
    subtotal = 0;
    count = 0;
    if (formulamap[selectedFormula].leg2 > 0) {
        for (let i = 1; i <= formulamap[selectedFormula].choice - 1; i++) {
            for (let j = i + 1; j <= formulamap[selectedFormula].choice; j++) {
                subtotal += legchance[i] * legchance[j];
                count++;
            }// End for j
        }// End for i
    }// End if
    if (count != formulamap[selectedFormula].leg2) alert("Counted " + count + " chance, but " + formulamap[selectedFormula].leg2 + " expected.");
    allupchance[2] = subtotal;

    // 3 Legs
    subtotal = 0;
    count = 0;
    if (formulamap[selectedFormula].leg3 > 0) {
        for (let i = 1; i <= formulamap[selectedFormula].choice - 2; i++) {
            for (let j = i + 1; j <= formulamap[selectedFormula].choice - 1; j++) {
                for (let k = j + 1; k <= formulamap[selectedFormula].choice; k++) {
                    //subtotal += parseInt(document.getElementById("leg" + i + "chance").innerText, 10) * parseInt(document.getElementById("leg" + j + "chance").innerText, 10) * parseInt(document.getElementById("leg" + k + "chance").innerText, 10);
                    subtotal += legchance[i] * legchance[j] * legchance[k];
                    count++;
                }// End for k
            }// End for j
        }// End for i
    }// End if
    if (count != formulamap[selectedFormula].leg3) alert("Counted " + count + " chance, but " + formulamap[selectedFormula].leg3 + " expected.");
    allupchance[3] = subtotal;

    // 4 Legs
    subtotal = 0;
    count = 0;
    if (formulamap[selectedFormula].leg4 > 0) {
        for (let i = 1; i <= formulamap[selectedFormula].choice - 3; i++) {
            for (let j = i + 1; j <= formulamap[selectedFormula].choice - 2; j++) {
                for (let k = j + 1; k <= formulamap[selectedFormula].choice - 1; k++) {
                    for (let l = k + 1; l <= formulamap[selectedFormula].choice; l++) {
                        //subtotal += parseInt(document.getElementById("leg" + i + "chance").innerText, 10) * parseInt(document.getElementById("leg" + j + "chance").innerText, 10) * parseInt(document.getElementById("leg" + k + "chance").innerText, 10) * parseInt(document.getElementById("leg" + l + "chance").innerText, 10);
                        subtotal += legchance[i] * legchance[j] * legchance[k] * legchance[l];
                        count++;
                    }// End for l
                }// End for k
            }// End for j
        }// End for i
    }// End if
    if (count != formulamap[selectedFormula].leg4) alert("Counted " + count + " chance, but " + formulamap[selectedFormula].leg4 + " expected.");
    allupchance[4] = subtotal;

    // 5 Legs
    subtotal = 0;
    count = 0;
    if (formulamap[selectedFormula].leg5 > 0) {
        for (let i = 1; i <= formulamap[selectedFormula].choice - 4; i++) {
            for (let j = i + 1; j <= formulamap[selectedFormula].choice - 3; j++) {
                for (let k = j + 1; k <= formulamap[selectedFormula].choice - 2; k++) {
                    for (let l = k + 1; l <= formulamap[selectedFormula].choice - 1; l++) {
                        for (let m = l + 1; m <= formulamap[selectedFormula].choice; m++) {
                            //subtotal += parseInt(document.getElementById("leg" + i + "chance").innerText, 10) * parseInt(document.getElementById("leg" + j + "chance").innerText, 10) * parseInt(document.getElementById("leg" + k + "chance").innerText, 10) * parseInt(document.getElementById("leg" + l + "chance").innerText, 10) * parseInt(document.getElementById("leg" + m + "chance").innerText, 10);
                            subtotal += legchance[i] * legchance[j] * legchance[k] * legchance[l] * legchance[m];
                            count++;
                        }// End for m
                    }// End for l
                }// End for k
            }// End for j
        }// End for i
    }// End if
    if (count != formulamap[selectedFormula].leg5) alert("Counted " + count + " chance, but " + formulamap[selectedFormula].leg5 + " expected.");
    allupchance[5] = subtotal;

    // 6 Legs
    subtotal = 0;
    count = 0;
    if (formulamap[selectedFormula].leg6 > 0) {
        for (let i = 1; i <= formulamap[selectedFormula].choice - 5; i++) {
            for (let j = i + 1; j <= formulamap[selectedFormula].choice - 4; j++) {
                for (let k = j + 1; k <= formulamap[selectedFormula].choice - 3; k++) {
                    for (let l = k + 1; l <= formulamap[selectedFormula].choice - 2; l++) {
                        for (let m = l + 1; m <= formulamap[selectedFormula].choice - 1; m++) {
                            for (let n = m + 1; n <= formulamap[selectedFormula].choice; n++) {
                                //subtotal += parseInt(document.getElementById("leg" + i + "chance").innerText, 10) * parseInt(document.getElementById("leg" + j + "chance").innerText, 10) * parseInt(document.getElementById("leg" + k + "chance").innerText, 10) * parseInt(document.getElementById("leg" + l + "chance").innerText, 10) * parseInt(document.getElementById("leg" + m + "chance").innerText, 10) * parseInt(document.getElementById("leg" + n + "chance").innerText, 10);
                                subtotal += legchance[i] * legchance[j] * legchance[k] * legchance[l] * legchance[m] * legchance[n];
                                count++;
                            }// End for n
                        }// End for m
                    }// End for l
                }// End for k
            }// End for j
        }// End for i
    }// End if
    if (count != formulamap[selectedFormula].leg6) alert("Counted " + count + " chance, but " + formulamap[selectedFormula].leg6 + " expected.");
    allupchance[6] = subtotal;

    // 7 Legs
    subtotal = 0;
    count = 0;
    if (formulamap[selectedFormula].leg7 > 0) {
        for (let i = 1; i <= formulamap[selectedFormula].choice - 5; i++) {
            for (let j = i + 1; j <= formulamap[selectedFormula].choice - 4; j++) {
                for (let k = j + 1; k <= formulamap[selectedFormula].choice - 3; k++) {
                    for (let l = k + 1; l <= formulamap[selectedFormula].choice - 2; l++) {
                        for (let m = l + 1; m <= formulamap[selectedFormula].choice - 1; m++) {
                            for (let n = m + 1; n <= formulamap[selectedFormula].choice; n++) {
                                for (let o = n + 1; o <= formulamap[selectedFormula].choice; o++) {
                                    //subtotal += parseInt(document.getElementById("leg" + i + "chance").innerText, 10) * parseInt(document.getElementById("leg" + j + "chance").innerText, 10) * parseInt(document.getElementById("leg" + k + "chance").innerText, 10) * parseInt(document.getElementById("leg" + l + "chance").innerText, 10) * parseInt(document.getElementById("leg" + m + "chance").innerText, 10) * parseInt(document.getElementById("leg" + n + "chance").innerText * parseInt(document.getElementById("leg" + o + "chance").innerText, 10);
                                    subtotal += legchance[i] * legchance[j] * legchance[k] * legchance[l] * legchance[m] * legchance[n] * legchance[o];
                                    count++;
                                }//End for o
                            }// End for n
                        }// End for m
                    }// End for l
                }// End for k
            }// End for j
        }// End for i
    }// End if
    if (count != formulamap[selectedFormula].leg7) alert("Counted " + count + " chance, but " + formulamap[selectedFormula].leg7 + " expected.");
    allupchance[7] = subtotal;


    // 8 Legs
    subtotal = 0;
    count = 0;
    if (formulamap[selectedFormula].leg8 > 0) {
        for (let i = 1; i <= formulamap[selectedFormula].choice - 5; i++) {
            for (let j = i + 1; j <= formulamap[selectedFormula].choice - 4; j++) {
                for (let k = j + 1; k <= formulamap[selectedFormula].choice - 3; k++) {
                    for (let l = k + 1; l <= formulamap[selectedFormula].choice - 2; l++) {
                        for (let m = l + 1; m <= formulamap[selectedFormula].choice - 1; m++) {
                            for (let n = m + 1; n <= formulamap[selectedFormula].choice; n++) {
                                for (let o = n + 1; o <= formulamap[selectedFormula].choice; o++) {
                                    for (let p = o + 1; p <= formulamap[selectedFormula].choice; p++) {
                                        //subtotal += parseInt(document.getElementById("leg" + i + "chance").innerText, 10) * parseInt(document.getElementById("leg" + j + "chance").innerText, 10) * parseInt(document.getElementById("leg" + k + "chance").innerText, 10) * parseInt(document.getElementById("leg" + l + "chance").innerText, 10) * parseInt(document.getElementById("leg" + m + "chance").innerText, 10) * parseInt(document.getElementById("leg" + n + "chance").innerText * parseInt(document.getElementById("leg" + o + "chance").innerText * parseInt(document.getElementById("leg" + p + "chance").innerText, 10);
                                        subtotal += legchance[i] * legchance[j] * legchance[k] * legchance[l] * legchance[m] * legchance[n] * legchance[o] * legchance[p];
                                        count++;
                                    }//End for p
                                }//End for o
                            }// End for n
                        }// End for m
                    }// End for l
                }// End for k
            }// End for j
        }// End for i
    }// End if
    if (count != formulamap[selectedFormula].leg8) alert("Counted " + count + " chance, but " + formulamap[selectedFormula].leg8 + " expected.");
    allupchance[8] = subtotal;

}//End computeAllupChance()