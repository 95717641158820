import './DragOnPC.scss';
import React, { useEffect, useRef } from 'react';
export default function DragOnPC({ scroll_speed = 1, children }) {
    const slider = useRef();
    const disablePointer = useRef();
    //disable-pointer
    useEffect(() => {
        if (!slider.current) return;

        let isDown = false;
        let startX;
        let scrollLeft;

        const mousedown = (e) => {
            e.preventDefault();
            isDown = true;
            startX = e.pageX - slider.current.offsetLeft;
            scrollLeft = slider.current.scrollLeft;

            // prevent default child behavior
            document.body.addEventListener('click', function (event) {
                if (slider.current?.contains(event.target)) {
                    event.preventDefault();
                }
            });
        };

        const mouseleave = (e) => {
            isDown = false;
            setTimeout(() => {
                disablePointer.current?.classList.remove('disable-pointer');
            });
        };

        const mousemove = (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.current.offsetLeft;
            const walk = (x - startX) * scroll_speed; //scroll-fast
            slider.current.scrollLeft = scrollLeft - walk;
            if(walk) disablePointer.current?.classList.add('disable-pointer');
        };

        slider.current?.addEventListener('mousedown', mousedown);
        slider.current?.addEventListener('mouseleave', mouseleave);
        slider.current?.addEventListener('mouseup', mouseleave);
        slider.current?.addEventListener('mousemove', mousemove);
        return () => {
            slider.current?.removeEventListener('mousedown', mousedown);
            slider.current?.removeEventListener('mouseleave', mouseleave);
            slider.current?.removeEventListener('mouseup', mouseleave);
            slider.current?.removeEventListener('mousemove', mousemove);
        };
    }, [slider.current]);

    return (
        <div ref={slider} className="DragOnPC-DragWrap">
            <div ref={disablePointer} className="flex" /* className="disable-pointer" */>
                {children}
            </div>
        </div>
    );
}
