import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect,useState } from 'react';
import { getRacingGearConfig } from '../../Common/ConfigHelper'

const GearLegend = ({className,gearData}) => {
  const { t, i18n } = useTranslation()
  const [gear, setGear] = useState([])
  const [gearConfig,setGearConfig] = useState([])
  useEffect(()=>{
    setGearConfig(getRacingGearConfig().RACING_GEAR)
    setGear(getGear(gearData))
  },[gearData])

  const getGear = (data) => {
    if(!data) return []
    let gear = []
    if(data.includes('1')){
      gear.push('"1"')
      data = data.replace(/1/g,'')
    }
    if(data.includes('2')){
      gear.push('"2"')
      data = data.replace(/2/g,'')
    }
    if(data.includes('-')){
      gear.push('"-"')
      data = data.replace(/-/g,'')
    }
    if(data.includes('/')){
      return gear.concat(data.split('/'))
    }else{
      return gear.concat(data.split(' '))
    }   
    
  }

  return<>
   <div className={`${className}`} >
    <h5>{`${t('LB_RC_RACECARD_GEAR_TITLE')}(${gearData})`}</h5>
    <div className='gear-info-list'>
    {gearConfig.map((item, index) => {
          return <div className={`gear-info-item${gear.includes(item.CODE) ? ' active-gear' : ''}${index%2 === 0 ? ' gear-info-left' : '' }`} key={item.CODE}>
            <div className='gearinfo-item-name'>{item.CODE}</div>
            <div className='separator'></div>
            <div className='gearinfo-item-value'>{i18n.language === 'en' ? item.NAME_E : item.NAME_C} </div>
          </div>
    })}
    {gearConfig.length % 2 === 1 && <div className='gear-info-item'></div>}
    </div>
  </div>
  </>
}

export default GearLegend