import { featureMatchCompare } from './common'
export const parseUpdMatchAdm = (message, topicInfo, contentRef) => {
    if(contentRef.current.matchData) {
        let match = contentRef.current.matchData.matches.find(x => x.id === topicInfo.matchid);
        if(match){
            if(match.tvChannels) {
                match.tvChannels = [];
                if ( message.admOpt?.mediaChl?.tVChl?.prog ) {
                    for(let i=0 ; i< message.admOpt?.mediaChl?.tVChl?.prog.length; i++){
                        match.tvChannels.push(updateTV(message.admOpt.mediaChl.tVChl.prog[i]));
                    }
                }
            }

            if ( message?.admOpt?.rmk?.[0]?.typ ) {
                match.adminOperation.remark = { typ: message.admOpt.rmk[0].typ }
            }
        
            match.featureStartTime = message?.admOpt?.featureMatch?.startTm;
            match.featureMatchSequence  = message?.admOpt?.featureMatch?.seq
            if(contentRef.current.page == "OFM"){
                let todayDate = new Date();
                let dformat = [
                    todayDate.getFullYear(),
                    todayDate.getMonth()+1,
                    todayDate.getDate()].join('-');
                if(match.featureStartTime >= dformat || match.featureStartTime == null){
                    let index = contentRef.current.matchData.matches.indexOf(match);
                    // console.debug("index",index);
                    if (index !== -1) {
                        contentRef.current.matchData.matches.splice(index, 1);
                        // console.debug("All match",contentRef.current.matchData.matches);
                    }
                    let listData = contentRef.current.listData.matches.find(x => x.tournament.id===topicInfo.tournamentid && x.id === topicInfo.matchid);
                    let listDataIndex = contentRef.current.listData.matches.indexOf(listData);
                    // console.debug("listDataIndex",listDataIndex);
                    if (listDataIndex !== -1) {
                        contentRef.current.listData.matches.splice(listDataIndex, 1);
                        // console.debug("All listData",contentRef.current.listData.matches);
                    }
                }
            
                // console.debug("todayDate",dformat);   
                contentRef.current.matchData.matches.sort(featureMatchCompare)
            }
        }
    }
}

export const updateTV =(message)=>{
    let name_ch_arr = message.chl.nm.filter(x=> x.ln == "zh-hk")[0];
    let name_en_arr = message.chl.nm.filter(x=> x.ln == "en-us")[0];
    if(name_ch_arr == null){
        name_ch_arr = "";
    }
    else{
        name_ch_arr = name_ch_arr.val;
    }
    if(name_en_arr == null){
        name_en_arr = "";
    }
    else{
        name_en_arr = name_en_arr.val;
    }
    return {
        code : message.chl.code,
        id: message.chl.id,
        name_ch: name_ch_arr,
        name_en: name_en_arr
    }
}