import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Remark } from '../Common/MatchBase';
import NoPoolMsg from '../Common/NoPoolMsg';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import GPWTable from '../Common/GPWTable';
import SPCTable from '../Common/SPCTable';
import GPFTable from '../Common/GPFTable';
import TQLTable from '../Common/TQLTable';
import GeneralTournTable from '../Common/GeneralTournTable';
import RefreshTime from '../../Common/ui-components/RefreshTime'
import { sortSeq } from '../Common/Common';

const Tournament = () => {

    const { t } = useTranslation();
    const context = useContext(FootballContext);
    const tournaments = context.content.tournData?.tournaments || [];
    const AllTournamentOrder = context.content.config.AllTournamentOrder;

    return <Content tournaments={tournaments} AllTournamentOrder={AllTournamentOrder} context={context}/>

}
export default Tournament;
const Content = ({tournaments, context, t, AllTournamentOrder}) => {
    return (
        <>
        {context.content.pageConfig.showRefreshTime && <div className='refreshContainer'><RefreshTime product={'football'}/></div>}
            <section className='Tournament'>
                <AllTournamentTable tournaments={tournaments} AllTournamentOrder={AllTournamentOrder} />
            </section>
            <div className='mobileMarginLR'>
                <Remark />
            </div>
        </>
    )

}


const AllTournamentTable = ({tournaments, AllTournamentOrder}) => {

    if (tournaments == null || tournaments.length == 0) {

        return <NoPoolMsg />
    }
    tournaments.sort(sortSeq);
    return tournaments.map((singleTourn) => {
        return AllTournamentOrder.map((oddsType) => {
            let foPools = singleTourn.foPools.filter(x => x.oddsType===oddsType);
            if (foPools.length>0) {
                switch (oddsType) {
                    case "CHP":
                        return <GeneralTournTable isMatch={false} betType="CHP" tourn={singleTourn} />
                    case "GPW":
                        return <GPWTable singleTourn={singleTourn} />
                    case "GPF":
                        return <GPFTable singleTourn={singleTourn} />
                    case "TPS":
                        return <GeneralTournTable isMatch={false} betType="TPS" tourn={singleTourn} />
                    case "TSP":
                        return <SPCTable betType="TSP" isMatch={false} tourn={singleTourn} showCoupon={true}/>;
                    case "TQL":
                        return <TQLTable betType="TQL" isMatch={false} tourn={singleTourn} />
                }
            } else {
                return null;
            }
        })

    })
}