import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { RacingContext } from "../../Home/RacingPage";
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const AllUpFormulaDropdown = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const formulas = context.content.alupData.length > 1 && context.content.alupData.length <= 6 ? context.content.config['ALUP_FORMULA'][context.content.alupData.length] : [];

    const handleClickFormulaMenu = (event) => {
        let value = event.target.getAttribute("value");
        context.contentRef.current.alupFormula = value;
        context.racingFuncs.setContent({...context.contentRef.current});
        context.racingFuncs.resetInvCal();
    }

    

    return (
        <div className="allupformula">
            {t('LB_RC_ALLUP_FORMULA')} :
            <FormulaDropdown title={t('LB_RC_FORMULA')} show={true} items={formulas} onClickFunc={(e) => { handleClickFormulaMenu(e) }} context={context} t={t}/>
        </div>
    )
}
export default AllUpFormulaDropdown;

function FormulaDropdown({ items, show, onClickFunc, context,t }) {

    if (!show) {
        return null;
    }

    return (<>
        <DropdownButton
            as={ButtonGroup}
            key={`DropdownButton`}
            id={`dropdown-allup-formulas`}
            variant="Formulas"
            title={<><div id="rcAlupFormula" className='rcAlupFormula'>{context.content.alupFormula}</div><ArrowIcon direction='down' size={24}/></>}
            align="end"
            className="dropdown-allup-formulas"
            disabled={items.length <= 0}
        >
            <Dropdown.Item key={`dropdown-allup-formulas-header`}><div>{t('LB_BS_ALLUP_FORMULA')}</div></Dropdown.Item>
            <Dropdown.Item key={`dropdown-allup-formulas-item`}>
                {
                    items.map((_cItem, _cIndex) => {
                        return <a onClick={onClickFunc} value={_cItem} className={context.content.alupFormula === _cItem && 'active'}>{_cItem}</a>
                    })
                }
            </Dropdown.Item>
        </DropdownButton>
    </>)
}