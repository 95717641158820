import React, { useEffect } from 'react';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const Pagination = ({ onChange, pageNum, total, AlwaysShow }) => {

    useEffect(() => {
        if(pageNum > total) {
            onChange(total<1?1:total)
        }
    },[pageNum, total, onChange])

    const onChangePage = (index, action) => {
        if (action) {
            if (action === 'PRE' && pageNum > 1) {
                onChange(pageNum - 1);
            } else if (action === 'NEXT' && pageNum < total) {
                onChange(pageNum + 1);
            }
            return;
        }
        onChange(index);
    };

    const pageList = [];
    for (let i = 1; i <= total; i++) {
        if (total > 5) {
            if (pageNum <= 3 && i > 5) {
                continue;
            } else if (pageNum > 3 && (i < pageNum - 2 || i > pageNum + 2) && pageNum + 2 < total) {
                continue;
            } else if (pageNum + 2 >= total && i < total - 4) {
                continue;
            }
        }
        pageList.push(
            <div key={`pageNum-${i}`} onClick={() => onChangePage(i)} className={`${pageNum === i ? 'active' : ''}`}>
                {i}
            </div>
        );
    }

    return (
        <>
            {total > 1 || AlwaysShow ? (
                <div className="df pagination-box">
                    <div className={`${pageNum === 1 && 'disable'}`} onClick={() => onChangePage(null, 'PRE')}>
                        <ArrowIcon direction="left" />
                    </div>
                    {pageList}
                    <div className={`${pageNum >= total && 'disable'}`} onClick={() => onChangePage(null, 'NEXT')}>
                        <ArrowIcon direction="right" />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Pagination;
