import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const HFT = () => {
    
    return (
        <section className='HFT'>
            <MatchTable betType='HFT' />
            <Remark />
        </section>
    )
}
export default HFT;