import { initSsoAPI, initSsoIbAPI, getAuthorize, getAccessToken, getUserInfo } from './BetSlipAPI';
import { setUserInfoToSession, setTokenToSession, renewTokens } from './LoginHooks';

export const isSsoLogin = async () => {
    if (window.globalConfig.IS_IB) {
        //BAU SSO
        const initData = await initSsoIbAPI();
        const ssoWebId = initData?.DoCheckSSOSignInStatusTRResult?.find((i) => i.Key == 'sso_web_id')?.Value;
        const ssoEnabled = initData?.DoCheckSSOSignInStatusTRResult?.find((i) => i.Key == 'sso_enabled')?.Value;
        const ssoSignInLevel = initData?.DoCheckSSOSignInStatusTRResult?.find(
            (i) => i.Key == 'sso_sign_in_level'
        )?.Value;

        if (ssoWebId?.length > 0 && ssoEnabled == 'true' && ssoSignInLevel > 0) {
            return true;
        } else {
            return false;
        }
    } else {
        //Cloud SSO
        return false;//to do

    }
};

export const initSsoLogin = async () => {
    if (window.globalConfig.IS_IB) {
        //BAU SSO
        const initData = await initSsoIbAPI();
        const ssoWebId = initData?.DoCheckSSOSignInStatusTRResult?.find((i) => i.Key == 'sso_web_id')?.Value;
        const ssoWebIdHashed = initData?.DoCheckSSOSignInStatusTRResult?.find(
            (i) => i.Key == 'sso_web_id_hashed'
        )?.Value;
        const ssoEnabled = initData?.DoCheckSSOSignInStatusTRResult?.find((i) => i.Key == 'sso_enabled')?.Value;
        const ssoGuid = initData?.DoCheckSSOSignInStatusTRResult?.find((i) => i.Key == 'sso_guid')?.Value;
        const ssoSignInLevel = initData?.DoCheckSSOSignInStatusTRResult?.find(
            (i) => i.Key == 'sso_sign_in_level'
        )?.Value;

        if (ssoWebId?.length > 0 && ssoEnabled == 'true' && ssoSignInLevel > 0) {
            sessionStorage.setItem('webID', ssoWebId);
            sessionStorage.setItem('sso_web_id', ssoWebId);
            sessionStorage.setItem('sso_guid', ssoGuid);
            sessionStorage.setItem('sso_web_id_hashed', ssoWebIdHashed);
            sessionStorage.setItem('sso_sign_in_level', ssoSignInLevel);
            return ssoWebId;
        } else {
            setSsoLogout();
            return false;
        }
    } else {
        //Cloud SSO
        const initData = await initSsoAPI();
        if (initData.code === 415) {
            return false;
        }
        if (initData?.tokenId) {
            const authorize = await getAuthorize(initData.tokenId, 'JCBW_LS');
            if (authorize) {
                let code = '';
                if (authorize.url) {
                    const tempUrl = new URL(authorize.url);

                    const queryParams = new URLSearchParams(tempUrl.search);
                    code = queryParams.get('code');
                }
                const accessToken = await getAccessToken(code, 'JCBW_LS');
                if (accessToken?.access_token) {
                    setTokenToSession({
                        accessToken: accessToken.access_token,
                        refreshToken: accessToken.refresh_token,
                        idToken: accessToken.id_token,
                        login_account: ''
                    });

                    //const refreshInverval = betslipMenu?.tokenRefreshInterval || 300;
                    const refreshInverval = 300;
                    const intervalId = setInterval(() => {
                        const token = renewTokens('JCBW_LS');
                        if (token?.access_token && token?.refresh_token) {
                            setTokenToSession({
                                accessToken: token.access_token,
                                refreshToken: token.refresh_token,
                                idToken: token.id_token
                            });
                            // console.log(
                            //     'token renewed (LS)' + Date() + ' ' + sessionStorage.getItem('tokenIntervalId')
                            // );
                        }
                    }, refreshInverval * 1000);
                    sessionStorage.setItem('tokenIntervalId', intervalId);
                    const userInfo = await getUserInfo(accessToken.access_token);
                    setUserInfoToSession(userInfo);
                    return userInfo?.webProfile?.personalInfo?.webAccountLoginName;
                }
            }
        }
    }
    return false;
};

export const setSsoLogout = () => {
    sessionStorage.removeItem('sso_web_id');
    sessionStorage.removeItem('sso_web_id_hashed');
    sessionStorage.removeItem('sso_guid');
    sessionStorage.removeItem('sso_sign_in_level');
    sessionStorage.removeItem('sso_login_status');
    sessionStorage.removeItem('sso_login_error');
};
