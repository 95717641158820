import React, { useEffect, useContext, useState } from 'react';
import { pad } from '../../Common/home-common';
import { useTranslation } from 'react-i18next';
import { getBalanceValue, MyBetSlipContext } from '../BetSlipHooks';
import { useLoginLogout, getSessionLoginAccount } from '../LoginHooks';
import useBroadcastChannel from '../../Common/hooks/useBroadcastChannel';

const DateAccBalHeader = ({ setBalanceLoaded, offsetDate }) => {
    const { balance, setBalance } = useContext(MyBetSlipContext);
    const { t } = useTranslation();
    const boardcastBalance = useBroadcastChannel('refreshBalance');
    // const { loginAccount } = useLoginLogout();
    const loginAccount = getSessionLoginAccount();
    const todayDate = () => {
        //let date = new Date();
        let date = offsetDate;
        return `${pad(date.getDate(), 2)}/${pad(date.getMonth() + 1, 2)}/${date.getFullYear()} ${pad(
            date.getHours(),
            2
        )}:${pad(date.getMinutes(), 2)}`;
    };

    const refreshBalance = async () => {
        setBalance('...');
        const balance = parseFloat(sessionStorage.getItem('balance')); //await getBalanceValue();
        if (!isNaN(balance)) {
            setBalance(balance.toLocaleString(undefined, { minimumFractionDigits: 2 }));
            //updateBetSlipBalance(balance.toLocaleString(undefined, { minimumFractionDigits: 2 }));
        } else {
            setBalance('---');
        }
        setBalanceLoaded(true);
    };

    useEffect(() => {
        refreshBalance();
    }, []);

    const updateBetSlipBalance = (value) => {
        if (!value) return;
        boardcastBalance?.postMessage(value);
    };

    return (
        <div className="acInfo">
            <div className="acInfoCell">
                <span className="labelText">{t('LB_BS_LOGIN_LAST_LOGIN_INFO_TIME')}:</span>
                <span>{todayDate()}</span>
            </div>
            <div className="acInfoCell acInfoCell1 borderLLine">
                <span className="labelText">{t('LB_BETSLIP_BETACCOUNTNO')}:</span>
                <span>{loginAccount}</span>
            </div>
            <div className="acInfoCell acInfoCell2 borderLLine">
                <span className="labelText">{t('LB_BS_LOGIN_BALANCE')}:</span>
                <span className="colorBule">${balance}</span>
            </div>
        </div>
    );
};

export default DateAccBalHeader;
