import { getFootballMenuQuery } from '../DataQuery';
import { Query } from '../../../Common/graphqlFetch'

const useGetFootballMenuData = (setContent) => {
    const loadFootballMenuQuery = (day, idList) => {
        Promise.all([Query({
            query : getFootballMenuQuery(),
            variables: { tday: day, tIdList: idList },
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then(([results]) => {
            let data = results?.data || {};
            setContent({ data: data })
        });
    }

    return {
        loadFootballMenuQuery
    }
}

export default useGetFootballMenuData