import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { getPMPoolResultsQuery } from '../DataQuery';
import { Query, abortFBMatch, apiErrorCallback } from '../../../Common/graphqlFetch'

const useGetPmPools = (contentRef, setContent) => {
    // const apolloClient = useApolloClient();

    const loadPmResults = (paras) => {
        abortFBMatch()
        Promise.all([Query({ 
            query : getPMPoolResultsQuery(),
            variables: paras,
            controller: window.FBMatchController
        })])
        .then(([results]) => {
            setPmResultQueryDataToContent(results);
        }).catch(error => apiErrorCallback(error, ()=>{loadPmResults(paras)}));
    }

    const setPmResultQueryDataToContent = (queryResult) => {
        if(!queryResult || !queryResult.data) return;
        contentRef.current.isLoading = false;
        contentRef.current.isApiErr = false;
        contentRef.current.isSearching = false;
        contentRef.current.matchData = { matches: queryResult.data?.matchResult?.filter(x=> x.fbPmPools?.length>0).sort((x,y)=> {
            if ( x.kickOffTime.substring(0,16) !== y.kickOffTime.substring(0,16) )
                return moment(y.kickOffTime.substring(0,16)) - moment(x.kickOffTime.substring(0,16));
            return parseInt(x.matchNumber) - parseInt(y.matchNumber);
        }) };
        setContent({ ...contentRef.current });
    }

    return {
        loadPmResults
    }
}

export default useGetPmPools