import { consoleLog } from "../../../Common/GlobalFunc";

export const getOddsValue = (odds, cmbSt) => {
    switch (cmbSt) {
        case "Scratched":
            return "SCR";
        case "ReservedOrRefunded":
        case "Refunded":
        case "Reserved":
            return "---";
    }
    return odds;
}

const replaceRacingTopicString = (content, tKey, allRaces) => {
    consoleLog("content",content);
    return content?.config?.pushTopics?.[tKey]
        ?.replace('{dt}', content.date?.replace(/-/g, ''))
        ?.replace('{ve}', content.venue.toLowerCase())
        ?.replace('{no}', allRaces ? '+' : content.raceNo);
}

export const getRacingTopics = (content) => {
        let list = []
        consoleLog("content.page",content.page);
        switch (content.page) {
            case 'WP':
                list.push(replaceRacingTopicString(content, "WIN", true));
                list.push(replaceRacingTopicString(content, "PLA", true));
                list.push(replaceRacingTopicString(content, "ALL_INV", true));
                list.push(replaceRacingTopicString(content,  "ALL_SEL", true));
                list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                break;
            case 'TURNOVER':
                list.push(replaceRacingTopicString(content, "ALL_INV", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                break;
            // case 'HOME':
            //         list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
            //         list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
            //         break;
            case 'PWIN':
                list.push(replaceRacingTopicString(content, "WIN", true));
                list.push(replaceRacingTopicString(content, "PLA", true));
                list.push(replaceRacingTopicString(content, "PWIN0", true));
                list.push(replaceRacingTopicString(content, "PWIN1", true));
                list.push(replaceRacingTopicString(content,  "ALL_SEL", true));
                list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                break;
            case 'JTCOMBO':
                list.push(replaceRacingTopicString(content, "WIN", true));
                list.push(replaceRacingTopicString(content, "PLA", true));
                list.push(replaceRacingTopicString(content,  "ALL_SEL", true));
                list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                break;
            case 'WPQ':
                list.push(replaceRacingTopicString(content, "WIN", true));
                list.push(replaceRacingTopicString(content, "PLA", true));
                list.push(replaceRacingTopicString(content, "QIN", true));
                list.push(replaceRacingTopicString(content, "QPL", true));
                list.push(replaceRacingTopicString(content,  "ALL_SEL", true));
                list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                break;
            case 'IWN':
            case 'FCT':
                list.push(replaceRacingTopicString(content, "WIN", true));
                list.push(replaceRacingTopicString(content, content.page, true));
                list.push(replaceRacingTopicString(content, content.page + "_INV", true));
                list.push(replaceRacingTopicString(content,  "ALL_SEL", true));
                list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                break;
            case 'CWB':
            case 'CWC':
                list.push(replaceRacingTopicString(content, "WIN", true));
                list.push(replaceRacingTopicString(content, content.page, true));
                list.push(replaceRacingTopicString(content,  "ALL_SEL", true));
                list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                break;
            case 'CROSS_ALUP':
            case 'WPALUP':
            case 'WPQALUP':
            case 'FCTALUP':
            case 'TRIALUP':
            case 'CWA':
            case 'CWAALUP':
            case 'CWAALLALUP':
                list.push(replaceRacingTopicString(content, "WIN", true));
                list.push(replaceRacingTopicString(content, "CWA", true));
                list.push(replaceRacingTopicString(content,  "ALL_SEL", true));
                list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                break;
            case 'DBL':
                list.push(replaceRacingTopicString(content, "WIN", true));
                list.push(replaceRacingTopicString(content, "DBL", true));
                list.push(replaceRacingTopicString(content, content.page + "_INV", true));
                list.push(replaceRacingTopicString(content,  "ALL_SEL", true));
                list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                break;
            case 'TBL':
            case 'DT':
            case 'TT':
            case '6UP':
                list.push(replaceRacingTopicString(content, "WIN", true));
                list.push(replaceRacingTopicString(content,  "ALL_SEL", true));
                list.push(replaceRacingTopicString(content, content.page + "_INV", true));
                list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                break;
            case 'TCE':
            case 'TRI':
            case 'QTT':
            case 'FF':
                list.push(replaceRacingTopicString(content, "WIN", true));
                list.push(replaceRacingTopicString(content, content.page + "_INV", true));
                list.push(replaceRacingTopicString(content,  "ALL_SEL", true));
                list.push(replaceRacingTopicString(content,  "ALL_RAC", true));
                list.push(replaceRacingTopicString(content,  "ALL_MEE", true));
                if ( content.displayOpt==="top" ) {
                    list.push(replaceRacingTopicString(content, content.page + "_TOP", true));
                }
                else if ( content.displayOpt==="banker" ) {
                    list.push(replaceRacingTopicString(content, content.page + "_BANK", true));
                }
                else if ( ["TRI", "FF"].includes(content.page) ) {
                    list.push(replaceRacingTopicString(content, content.page, true));
                }
                break;
            case 'JKC':
                list.push(replaceRacingTopicString(content, "JKC_ODD", true));
                list.push(replaceRacingTopicString(content, "JKC_STA", true));
                list.push(replaceRacingTopicString(content, "JKC_DET", true));
                list.push(replaceRacingTopicString(content,  "JKC_SEL", true));
                break;
            case 'TNC':
                list.push(replaceRacingTopicString(content, "TNC_ODD", true));
                list.push(replaceRacingTopicString(content, "TNC_STA", true));
                list.push(replaceRacingTopicString(content, "TNC_DET", true));
                list.push(replaceRacingTopicString(content,  "TNC_SEL", true));
                break;
            }
        return list;
    }
