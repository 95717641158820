import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import moment from 'moment';
import { Cookies } from '../Common/CookieHelper';
import { useLazyQuery } from '@apollo/client';
import { getMsgInboxProducts } from '../Home/Data/DataQuery';
import { getChangesQuery } from '../Racing/Data/DataQuery';
import { translateChangesItem, filterChangeItems } from '../Racing/Common/Common';
import { GetRaceConfig } from '../Common/ConfigHelper';
import { useImportantNoticeQuery } from './Data/DataRefreshHooks';
import { errRedirectPage } from '../Common/utils';
import { isLocalMeeting } from '../Common/home-common';

import '../../css/msginbox.scss';


const MsgInbox = () => {
    const { t, i18n } = useTranslation();
    const { lang, pd } = useParams();
    const localeLang = lang==="ch" ? "zh-hk" : "en-us";
    const [tab, setTab] = useState(-1);
    const [inboxState, setInboxState] = useState({
        product: "GL",
        msgGLNew: [],
        msgGL: [],
        msgPdNew: [],
        msgPd: [],
        newsCnt: 0,
        lastReadDt: {}
    });
    const [loadImportantNotices, importantNoticesLoading, importantNoticesData, remarksLoading, remarksData] = useImportantNoticeQuery();

    const pdCookies = {
        GL: "msgInboxLastReadDtGL",
        HR: "msgInboxLastReadDtHR",
        FB: "msgInboxLastReadDtFB",
        LY: "msgInboxLastReadDtLY",
        FBR: "msgInboxLastReadDtFBR",
        CHG: "msgInboxLastReadDtCHG"
    };

    const saveLastReadDtToCookie = (name, l) => {
        if ( l.length > 0 ) {
            var list = [...l];
            list.sort(function(x, y) {
                return moment(y.dt) - moment(x.dt);
            });
            Cookies.setData(pdCookies[name], list[0].dt);
        }
    }
        
    const getLastReadDtFromCookie = (name) => {	
        var cookieVal = Cookies.getData(pdCookies[name]);
        if (cookieVal==null) {
          cookieVal = '2000-01-01T00:00:00';
        }
        return cookieVal;
    }

    function getVenue(ve) {
        var tmp = ve;
        switch (tmp) {
            case 'ST':
                tmp = t('LB_ST');
                break;
            case 'HV':
                tmp = t('LB_HV');
                break;
        }
        return tmp;
    }

    const getWeekDay = (msg) => {
        if(!msg) return ''
        const weekDay  = new Date(msg.date).getDay()
        return msg.showWeekDay ? `(${t(`LB_WEEKDAY_SHORT_${weekDay}`)}) ` : ''
    }

    function getChangeDateTime(str) {
        return "(" + str.substring(8, 10) + "/" + str.substring(5, 7) + " " + str.substring(11,16) + ")";
    }

    const sortChanges = (x, y) => {
        if(x.date != y.date){
            return x.date - y.date
        }
        if ( x.venue != y.venue ) {
            let venues = GetRaceConfig().validVenue;
            return venues.indexOf(x.venue) - venues.indexOf(y.venue);
        }
        else if ( x.raceNo != y.raceNo ) {
            return parseInt(x.raceNo) - parseInt(y.raceNo);
        }
        else if ( y.dt != x.dt) {
            return moment(y.dt) - moment(x.dt);
        }
        return parseInt(x.runnerNo) - parseInt(y.runnerNo);
    }

    const sortByDate = (x, y) => {
        return moment(y.dt) - moment(x.dt);
     }

    const [ loadQuery, gqlVars ] = useLazyQuery(getChangesQuery(), {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: data=> { 
            let msgs = [];
            data.raceMeetings.forEach(meeting => {
                let venue = meeting.venueCode;
                const filterHistories = meeting.changeHistories.filter(x=> filterChangeItems(x));
                const showWeekDay =isLocalMeeting(venue) && data.raceMeetings.filter(item => item.venueCode== venue).length > 1
                filterHistories.forEach(chg => {
                    let raceNo = chg.raceNo;
                    let runnerNo = chg.runnerNo;
                    let dt = chg.time;
                    let msg = translateChangesItem(t, chg, i18n.language);
                    msgs.push({
                        dt: dt,
                        venue: venue,
                        raceNo: raceNo,
                        runnerNo: runnerNo,
                        msg: msg,
                        date: meeting.date,
                        showWeekDay
                    })
                });
            })
            inboxState.msgPd = msgs;
            inboxState.msgPd.sort(sortChanges);
            inboxState.msgPdNew = inboxState.msgPd.filter(x=> { return moment(x.dt) > moment(inboxState.lastReadDt['CHG']); });
            saveLastReadDtToCookie('CHG', inboxState.msgPd);
            setInboxState({...inboxState});

            resetTabAndnewCnt();
        },
        onError:err=>{
            errRedirectPage(err);
        }
    });

    useEffect(() => {
        i18n.changeLanguage(lang);

        switch (pd) {
            case 'racing':
                inboxState.product = "HR";
                break;
            case 'football':
                inboxState.product = "FB";
                break;
            case 'marksix':
                inboxState.product = "LY";
                break;
        }

        inboxState.lastReadDt = {
            GL: getLastReadDtFromCookie('GL'),
            HR: getLastReadDtFromCookie('HR'),
            FB: getLastReadDtFromCookie('FB'),
            LY: getLastReadDtFromCookie('LY'),
            FBR: getLastReadDtFromCookie('FBR'),
            CHG: getLastReadDtFromCookie('CHG')
        }

        setInboxState({...inboxState});

        loadImportantNotices(getMsgInboxProducts(inboxState.product));
        if ( inboxState.product==="HR" ) {
            loadQuery();
        }

        const autoRefreshInterval = setInterval(() => {
            loadImportantNotices(getMsgInboxProducts(inboxState.product));
            if ( inboxState.product==="HR" ) {
                loadQuery();
            }
        }, 30000);

        return () => clearInterval(autoRefreshInterval);

    }, []);

    // for importantNotice rerender
    useEffect(()=> {
        if ( !importantNoticesLoading  && importantNoticesData ) {
            var msgList = importantNoticesData.filter(item => item?.locations?.includes('MessageBox'));
            inboxState.msgGL = msgList.filter(x=> x.categories.includes("GL"));
            inboxState.msgGLNew = inboxState.msgGL.filter((x) => { return moment(x.dt) > moment(inboxState.lastReadDt['GL']); });
            saveLastReadDtToCookie('GL', msgList.filter(x=> x.categories.includes("GL")));
            switch (inboxState.product) {
                case 'HR':
                    var tmpMsgs = msgList.filter(x=> x.categories.includes("HR") && !x.categories.includes("GL"));
                    inboxState.msgGL = [...inboxState.msgGL, ...tmpMsgs];
                    tmpMsgs = tmpMsgs.filter(x => { return moment(x.dt) > moment(inboxState.lastReadDt['HR']); });
                    inboxState.msgGLNew = [...inboxState.msgGLNew, ...tmpMsgs];
                    saveLastReadDtToCookie('HR', msgList.filter(x=> x.categories.includes("HR")));
                    break;
                case 'FB':
                    var tmpMsgs = msgList.filter(x=> x.categories.includes("FB") && !x.categories.includes("GL"));
                    inboxState.msgGL = [...inboxState.msgGL, ...tmpMsgs];
                    tmpMsgs = tmpMsgs.filter(x => { return moment(x.dt) > moment(inboxState.lastReadDt['FB']); });
                    inboxState.msgGLNew = [...inboxState.msgGLNew, ...tmpMsgs];
                    saveLastReadDtToCookie('FB', msgList.filter(x=> x.categories.includes("FB")));

                    if ( !remarksLoading && remarksData ) {
                        let nowDt = moment().add(remarksData.timeOffset.fb, "seconds");
                        tmpMsgs = remarksData.matches.filter(match => {
                                return moment(match.kickOffTime).add(1, 'days') > nowDt;
                            })
                            .map(match=> {
                                return { contents : match.adminOperation.remark.cont, dt: match.adminOperation.remark.updAt };
                            });
                        
                        tmpMsgs.sort((x,y)=> { return x.dt < y.dt ? 1 : -1; })
                        inboxState.msgPd = [...tmpMsgs];
                        let tmpMsgsNew = tmpMsgs.filter(x => { return moment(x.dt) > moment(inboxState.lastReadDt['FBR']); });
                        inboxState.msgPdNew = [...tmpMsgsNew];
                        saveLastReadDtToCookie('FBR', tmpMsgs);
                    }
                    break;
                case 'LY':
                    inboxState.msgPd = msgList.filter(x=> x.categories.includes("LY") && !x.categories.includes("GL"));
                    tmpMsgs = inboxState.msgPd.filter(x => { return moment(x.dt) > moment(inboxState.lastReadDt['LY']); });
                    inboxState.msgPdNew = [...tmpMsgs];
                    saveLastReadDtToCookie('LY', msgList.filter(x=> x.categories.includes("LY")));
                    break;
            }
            inboxState.msgGLNew.sort(sortByDate);
            inboxState.msgGL.sort(sortByDate);
            inboxState.msgPdNew.sort(inboxState.product=="HR" ? sortChanges : sortByDate);
            inboxState.msgPd.sort(inboxState.product=="HR" ? sortChanges : sortByDate);

            setInboxState({...inboxState});

            resetTabAndnewCnt();
        }
    }, [importantNoticesData, importantNoticesLoading, remarksLoading, remarksData])

    const resetTabAndnewCnt = () => {
        inboxState.newsCnt = inboxState.msgGLNew.length + inboxState.msgPdNew.length;
        setInboxState({...inboxState});
        if ( tab==-1 ) {
            setTab(inboxState.newsCnt > 0 ? 0 : 1);
        }
    }

    const getInboxTitle = () => {
        switch (inboxState.product) {
            case "GL":
                return t('LB_MSGINBOX_GENERALNEWS');
            case "HR":
            case "LY":
            case "FB":
                return t('LB_MSGINBOX_' + inboxState.product) + t('LB_MSGINBOX_MSG');
        }
        return '';
    }
    const InboxSection = ({pd, title, msgList}) => {
        return <div className="msgInboxTable">
            <div className="msgInboxTableHeader">{title}</div>
            <div className="msgInboxTableBody">
                {msgList.length > 0 ?
                    msgList.map((msg, i) => {
                        return <div className="msgInboxTableRow">
                            { pd==='CHG' ? <>
                                <div className="msgInboxChangeRace">{getVenue(msg.venue)} {getWeekDay(msg)}{t('LB_RC_SEL').replace('{0}', msg.raceNo)}</div>
                                <div className="msgInboxTableChangeRow">{msg.msg}</div>
                                <div>{getChangeDateTime(msg.dt)}</div>
                            </>
                            :
                            <>
                                <div className="msgInboxPt">{i+1}. </div>
                                <div className="msgInboxMsg">
                                    <div dangerouslySetInnerHTML={{ __html: msg.contents.filter(x=> x.lang===localeLang)[0].content }}></div>
                                </div>
                            </>}
                        </div>
                    }) 
                    :
                    <div className="msgInboxTableRow">{tab===0 ? t('LB_MSGINBOX_NONEWMSG') : t('LB_MSGINBOX_NOMSG')}</div>
                }
            </div>
        </div>
    }

    return <div className={`${inboxState.product} msgInbox`}>
        <div className="msgInboxTitle">
            <div className="invisiableBlock"></div>
            <div>{getInboxTitle()}</div>
            <div className="msgInboxClose" onClick={()=>{ window.close() }}></div>
        </div>
        <div className="msgInboxHeader">
            <div className={`msgInboxHeaderCell ${tab===0 && "active"}`}>
                <div className="msgInboxHeaderCellInner" onClick={()=>{tab!==0 && setTab(0)}}>
                    {t('LB_MSGINBOX_NEW')}{inboxState.newsCnt>0 && `(${inboxState.newsCnt})`}
                </div>
            </div>
            <div className={`msgInboxHeaderCell ${tab===1 && "active"}`}>
                <div className="msgInboxHeaderCellInner" onClick={()=>{tab!==1 && setTab(1)}}>
                    {t('LB_MSGINBOX_ALL')}
                </div>
            </div>
        </div>
        <div className="msgInboxTables">
            <InboxSection key="indoxGL" pd="GL" title={t(`LB_MSGINBOX_GENERALNEWS`)} msgList={tab===0 ? inboxState.msgGLNew : inboxState.msgGL} />
            {inboxState.product==="HR" && <InboxSection key="indoxHR" pd="CHG" isRacingChanges title={t(`LB_MSGINBOX_CHANGES`)} msgList={tab===0 ? inboxState.msgPdNew : inboxState.msgPd} />}
            {inboxState.product==="FB" && <InboxSection key="indoxFB" pd="FBR" title={t(`LB_MSGINBOX_FB`)} msgList={tab===0 ? inboxState.msgPdNew : inboxState.msgPd} />}
            {inboxState.product==="LY" && <InboxSection key="indoxLY" pd="LY" title={t(`LB_MSGINBOX_LY`)} msgList={tab===0 ? inboxState.msgPdNew : inboxState.msgPd} />}
        </div>
    </div>
}
export default MsgInbox;