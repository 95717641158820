import React,{ useState, useEffect, useContext } from 'react';
import BallPanelTable from '../Common/BallPanelTable'
import { useTranslation } from 'react-i18next';
import AmountDisplayTable, {MobileAmountDisplay} from './AmountDisplayTable'
import {setMyFavCookie, MyFavouritesContext} from './MyFavourites';
import { MarksixContext } from '../../Home/MarksixPage';
import { isStartSell } from './Common';
import { useWindowSize } from '../../Common/CommonHooks';
import { isPmuEoD, eodMessage } from '../../Common/GlobalFunc';

// import { NewWindow } from '../../Common/home-common';

const MultipleSelectedNumTable = () => {
  const {t} = useTranslation()
  const context = useContext(MarksixContext);
  const isSell = isStartSell(context.nextDraw);
  const { isMobileSize } = useWindowSize();
  const initDefaultUnitBet = sessionStorage.getItem('Mk6DefaultUnitBetAmount')?Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount')):10;
  const [unitBet, setUnitBet] = useState(initDefaultUnitBet)
  const [selectedTableData, setSelectedTableData] = useState([{numList:[], isFav:false, chance: 0, unitBet,multidraw:1}])
  const scSize = document.documentElement.clientWidth
  const { updateMyFavourites } = useContext(MyFavouritesContext)
  const [multidraw,setMultidraw]=useState(1);

  const onChangeBall = (ballList,ballNum,activeIndex,random='') => {
    const newTableData = JSON.parse(JSON.stringify(selectedTableData))

    newTableData[0].numList = ballList
    newTableData[0].multidraw=multidraw;
    newTableData[0].random = !!random
    newTableData[0].isFav = false;
    if(ballList.length < 7){
      setUnitBet(initDefaultUnitBet)
      newTableData[0].unitBet=initDefaultUnitBet;
    }
    setSelectedTableData(newTableData)
  }

  const onChangeDraw = (num) => {
    const newTableData = JSON.parse(JSON.stringify(selectedTableData))
    newTableData[0].multidraw = num
    setMultidraw(num)
    setSelectedTableData(newTableData)
  }

  const randomBall = (itemIndex) => {
    if (selectedTableData[itemIndex].numList.length >= 49) {
      return alert(t('LB_M6_MSG_SELECT_NUMBERS_LINE_MAX'))
    }
    const ball = [...selectedTableData[0].numList]
    const isRandom=ball.length==0?true:!!selectedTableData[0].random;
    if(ball.length < 6){
      while(ball.length < 6) {
        const rand = Math.ceil(Math.random()*49)
        if(!ball.includes(rand))ball.push(rand)
      }
    }else{
      const length = ball.length
      while(ball.length < length+1) {
        const rand = Math.ceil(Math.random()*49)
        if(!ball.includes(rand))ball.push(rand)
      }
    }
    const sortBall = ball.sort((a,b)=> a-b)
    onChangeBall(sortBall,null, itemIndex,isRandom)
  }

  useEffect(()=>{
    updateChance()
  },[selectedTableData[0].numList])


  const clearBall = (itemIndex) => {
    if(selectedTableData[itemIndex].numList.length === 0) return
    setSelectedTableData([{numList:[],isFav:false,chance:0, unitBet:10, multidraw:1 }])
  }

  const clearAllNumber = () => {
    setSelectedTableData([{numList:[],isFav:false,chance:0, unitBet:10, multidraw:1 }])
  }

  const handleClickSelectedBall = (num,index) => {
    const newTableData = JSON.parse(JSON.stringify(selectedTableData))
    newTableData[index].numList = newTableData[index].numList.filter(item=> item !== num)
    newTableData[index].isFav = false
    if(newTableData[0].numList.length < 7 ){
      setUnitBet(initDefaultUnitBet)
      newTableData[0].unitBet=initDefaultUnitBet;
    }
    setSelectedTableData(newTableData)
  }

  const handleClickFavIcon = (index) => {
    const newList = [...selectedTableData]
    if(newList[0].numList.length >= 6){

      if(newList[0].isFav){
        newList[0].isFav = !newList[0].isFav
        setSelectedTableData(newList)
      }else{

        if (!isSell) {
          if (isPmuEoD() && eodMessage()?.length > 0) {
            return window.alert(eodMessage());
          } else {
            return window.alert(t('LB_M6_MSG_NOT_DIFINED'));
          }
        }

        if (newList[0]?.bankerList?.length > 0 && (newList[0].bankerList.length + newList[0].numList.length) < 7) {
          return window.alert(t('LB_M6_MSG_INSU_SELECTIONS'))
        }
        const fav = newList.filter(item => !item.isFav)
        for (let i = 0; i < newList.length; i++) {
          if (newList[i].numList.length >= 49) {
            return window.alert(t('LB_M6_MSG_SELECT_NUMBERS_MULT_49'))
          }
        }
        if (fav.length > 0) {
          const favMax = setMyFavCookie(fav, t('LB_M6_MSG_MYFAV_MAX'))
          if (favMax) return
          window.alert(t('LB_M6_MSG_MYFAV'))
          newList[0].isFav = !newList[0].isFav
          setSelectedTableData(newList)
          updateMyFavourites()
        if(isMobileSize) window.location.hash = "#SelectTable";
          else document.querySelector('.StandardPage-scrollbars > div:first-child').scrollTop = 5000
        //   clearAllNumber();
        }
      }
      return
    }
    alert(t('LB_M6_MSG_INSU_SELECTIONS'))

  }

  const handleClickBet = (betNum) => {
    setUnitBet(betNum)
    selectedTableData[0].unitBet=betNum;
    setSelectedTableData(selectedTableData)
  }

  const updateChance = (number) => {
    selectedTableData[0].chance = getChance(number || selectedTableData[0].numList.length)
    setSelectedTableData([...selectedTableData])
  }

  const getChance = (num) => {
    if(num < 6) return 0
    let val = 1
    for(let i = 7; i<=num; i++){
        val = val * (i/(i-6))
    }
    return parseInt(val)
  }
  const selectTableProps = {
    t, scSize,
    handleClickFavIcon,
    handleClickSelectedBall,
    selectedTableData,
    handleClickBet,
    unitBet,
    clearBall,
    randomBall
  }

  return <div className='marksix-multiple-middle'>
    {isMobileSize ?
      <MobileAmountDisplay selectedData={selectedTableData} onChangeDraw={onChangeDraw} activeType={'MULTIPLE'}  unitBet={unitBet} tableType='multiple' clear={clearAllNumber} className='amount-diplay-m' />
    :null}
    <BallPanelTable onChangeBall={onChangeBall} size={50}
    curBallList={selectedTableData[0]} type='multiple'
    selectedData={selectedTableData}
    clear={clearAllNumber} />
    <SelectTable {...selectTableProps}/>
    <AmountDisplayTable unitBet={unitBet} activeType={'MULTIPLE'} selectedData={selectedTableData} onChangeDraw={onChangeDraw} tableType='multiple' clear={clearAllNumber} className='amount-diplay-d'/>
  </div>
}

export default MultipleSelectedNumTable

const SelectTable = (props) => {
  let key = 1

  const table = props.selectedTableData?.map((item,index) => {
    return <TableRow ballData={item} index={index} key={key++} className={index % 2 ? '' : 'sec'} {...props}/>
  })

  return <div className='single-select-table' id='SelectTable'>
    <TableHeader {...props}/>
    {table}
  </div>
}

const TableHeader = ({scSize, t}) => {
  return <div className='table-row table-row-header'>
    <div className='table-cell table-cell-fav'>{scSize > 1023 ? t('LB_M6_SINGLE_TABLE_FAV') : t('LB_M6_SINGLE_TABLE_FAV_M')}</div>
    <div className='table-cell table-cell-number'><div >{t('LB_M6_SINGLE_TABLE_NUMBER')}</div></div>
    <div className='table-cell'></div>
  </div>
}

const TableRow = ({ballData, index, className,t, scSize, handleClickFavIcon, handleClickSelectedBall, selectedTableData, handleClickBet, unitBet, clearBall , randomBall}) => {
  const imgSize = scSize > 1023 ? 'm':'s';
  const {ewinAtM6Obj} = useContext(MarksixContext);
  const { multipleShowUnitBet, initDefaultUnitBet, initPartialUnitBet } = ewinAtM6Obj;
  return <div className={`table-row ${className}`}>
    <div className='table-cell'>
      <div className='table-cell-fav-box pr'>
        {/* {activeItem === index && <div className='table-row-arrow'></div>} */}
        <div title={t('LB_M6_SINGLE_TABLE_FAV')}  className={ballData.isFav? 'marksix-fav-active' :'marksix-fav'} onClick={()=>handleClickFavIcon(index)}></div>
      </div>
    </div>
    <div className='table-cell'>
      <div className='df table-cell-ball-box'>
        {ballData.numList.map((i,ind) => {
          return <div className='ball-img-box' onClick={()=>handleClickSelectedBall(i,index)} key={ind}><img className={`ballsize-${imgSize}`} src={ i && require(`../../../info/marksix/info/images/marksix-${i}.svg`)} alt={i} /></div>
        })}
      </div>
      {multipleShowUnitBet ?
        <div className={`df table-cell-bet-box ${selectedTableData[0].numList.length > 0 ? 'bet-box-show' : 'bet-box-hide'}`}>
          <label  className="radio-container" onClick={ ()=>selectedTableData[0].numList.length >= 7 && handleClickBet(initPartialUnitBet)}>
              {t('LB_M6_MULT_PARBET').replace('{0}', initPartialUnitBet)}
              <input type="radio" className="invCalFlexiBet-betline" onChange={()=>handleClickBet(initPartialUnitBet)} disabled={selectedTableData[0].numList.length < 7}  checked={unitBet === initPartialUnitBet} />
              <span className="radio-checkmark"></span>
          </label>
          <label  className="radio-container" onClick={() => handleClickBet(initDefaultUnitBet)}>
              {t('LB_M6_MULT_UNITBET').replace('{0}', initDefaultUnitBet)}
              <input type="radio" className="invCalFlexiBet-betline" onChange={()=>handleClickBet(initDefaultUnitBet)}   checked={unitBet === initDefaultUnitBet} />
              <span className="radio-checkmark"></span>
          </label>
        </div>
      :null}
    </div>
    <div className='table-cell table-cell-ctrl-box'>
      <div className='df '>
        <div role='button' tabIndex='0' className='marksix-download-icon' title={t('LB_M6_QUICKPICK_BTN_TITLE')} onClick={()=> randomBall(index)}></div>
        <div className='marksix-delete-icon' title={t('LB_M6_DELETE_BTN_TITLE')} onClick={()=> clearBall(index)}></div>
      </div>
    </div>
  </div>
}
