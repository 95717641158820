import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NewWindow } from '../../Common/home-common';
import Instructions from './Instructions'
import { scrollTo } from '../../Common/GlobalFunc';

const CheckResultBallPanelTable = ({ onChangeBall, size = 50, curBallList = [], type, selectedData = [], clear, getInfo, onChangeMode, mySelectData, onChangeCheckTable, betTypeSelect, setBetTypeSelect, cancelFavCheck }) => {
  const [selectedBall, setSelectedBall] = useState([])
  const [selectedBankerBall, setSelectedBankerBall] = useState([])
  const { t, i18n } = useTranslation()
  const [selectedLang, setSelectedLang] = useState(i18n.language);
  const scSize = document.documentElement.clientWidth


  const handleClickBall = (num) => {
    let ballList = []
    if (num === 50) {
      const allBall = Array.from(new Array(50).keys()).slice(1)
      onChangeBall(allBall)
      return
    }
    let numList=curBallList.numList.map(item=>{
      return Number(item);
    })
    if (numList.includes(num)) {
      ballList = numList.filter(i => i !== num)
    } else if (numList.length < size) {
      ballList = numList.concat(num)
    }
    if (numList.length >= size) {
      onChangeBall(numList, num)
      return
    }
    const newBallList = ballList.sort((a, b) => a - b)
    onChangeBall(newBallList)
    // scrollTo(0, document.querySelector('.ball-panel-table-container').offsetTop - 135 + 1)
    cancelFavCheck()
  }

  const handleClickBankerBall = (num) => {
    let curLabelType = betTypeSelect.filter(x => x.checked)[0].type;
    let numList=curBallList.numList.map(item=>{
      return Number(item);
    })

    let bankerList=curBallList.bankerList.map(item=>{
      return Number(item);
    })
    const curSeletedList = [...new Set([...numList, ...bankerList])]
    const labelList = curLabelType === 'banker' ? 'bankerList' : 'numList';
    let ballList = []
    let newBallList={...curBallList};
    if (num === 50) {
      const allBall = Array.from(new Array(50).keys()).slice(1);
      ballList = allBall.filter(x => !bankerList.includes(x));
      curLabelType = 'leg';
      newBallList.numList=ballList.sort((a, b) => a - b);
    } else {
      if (curSeletedList.includes(num)) {
        if(bankerList.includes(num)){
          newBallList.bankerList=newBallList.bankerList.filter(i => i != num);
          if(curLabelType !== 'banker'){
            newBallList.numList=[...newBallList.numList,num].sort((a, b) => a - b)
          }
        }
        if(numList.includes(num)){
          newBallList.numList=newBallList.numList.filter(i => i != num);
          if(curLabelType === 'banker' && newBallList.bankerList.length < 5){
            newBallList.bankerList=[...newBallList.bankerList,num].sort((a, b) => a - b)
          }
        }
      } else {
        if (curLabelType === 'banker' && curBallList[labelList].length >= 5) {
          alert(t('LB_M6_BANKERMAXNUM'));
          return false;
        }
        newBallList[labelList] = [...curBallList[labelList], num].sort((a, b) => a - b)
      }
    }
    onChangeBall(newBallList, null, 0, curLabelType);
    // scrollTo(0, document.querySelector('.ball-panel-table-container').offsetTop - 135 + 1)
    cancelFavCheck()
  }

  const BankerLabel = () => {
    return <div className='banker-selected-area'>
      {betTypeSelect.map(item => {
        return <div className={`banker-selected-text ${item.checked ? 'active' : ''}`} onClick={() => labelOnchange(item)} key={item.name1}>{t(item.name1)}</div>
      })}
    </div>
  }

  const labelOnchange = data => {
    let items = betTypeSelect;
    if (data.type === (items.filter(x => x.checked)[0].type)) {
      return;
    }
    items.map(item => {
      item.checked = !item.checked;
    })

    setBetTypeSelect(prev => { return [...items] });
  }

  const handleClickCheckbox = (type) => {
    mySelectData[type] = !mySelectData[type]
    if (type === 'isMultiCheck') {
      clear()
    }
    onChangeCheckTable(false)
    onChangeMode(mySelectData)
    cancelFavCheck()
  }
  const tableProps = {
    type,
    handleClickBankerBall,
    handleClickBall,
    selectedBall,
    mySelectData,
    handleClickCheckbox,
    scSize,
    curBallList,
    selectedBankerBall
  }

  return <div className='ball-panel-table-container' id="ball-panel-table-container">
    <header className='df marksix-header'>
      <div className='df'>
        {t(`LB_M6_${type.toUpperCase()}`)}
      </div>
    </header>
    <Instructions selectedData={selectedData} clear={clear} tableType={mySelectData.isMultiCheck?'multiple':'banker'} onChangeCheckTable={onChangeCheckTable} pageType='checkdrawresult'/>
    <StatisticsPanel  {...tableProps}/>
    {!mySelectData.isMultiCheck && <BankerLabel />}
    <Table  {...tableProps}/>
  </div>
}

export default CheckResultBallPanelTable

const StatisticsPanel = (props) => {
  const {mySelectData,handleClickCheckbox}=props;
  const { t, i18n } = useTranslation();
  return <div className='df statisticsPanel'>
    <div className='statisticsPanelL' style={{ visibility: 'hidden' }}>
      <div className='panelTitle'>
        {t(`LB_M6_STAT_TITLE`)}
      </div>
      <div className='panelInfo'>
        <div className='df'>
          <div className='marksixCheckbox '>
            <input type='checkbox' checked={mySelectData.sinceNumber} onChange={() => handleClickCheckbox('sinceNumber')} />
            <span className='id' onClick={() => handleClickCheckbox('sinceNumber')}></span>
          </div>
          <span className='panelInfoBlue'>{t(`LB_M6_STAT_SINCE`)}</span>
        </div>
        <div className='df'>
          <div className='marksixCheckbox'>
            <input type='checkbox' checked={mySelectData.timersNumber} onChange={() => handleClickCheckbox('timersNumber')} />
            <span className='id' onClick={() => handleClickCheckbox('timersNumber')}></span>
          </div>
          <span>{t(`LB_M6_STAT_TOTAL`)}</span>
        </div>
      </div>
    </div>
    <div className='df chooseModelBtn'>
      <div className={mySelectData.isMultiCheck ? 'active' : ''} onClick={() => handleClickCheckbox('isMultiCheck')}><div>{t(`LB_M6_STAT_SELECT`)}</div><div>{t(`LB_M6_RANDOM_SINGLE`)}</div></div>
      <div className={!mySelectData.isMultiCheck ? 'active' : ''} onClick={() => handleClickCheckbox('isMultiCheck')}><div>{t(`LB_M6_STAT_SELECT`)}</div><div>{t(`LB_M6_BANKERLABEL`)}</div></div>
    </div>

  </div>
}


const Row = ({ rowIndex, colorList, statData, scSize, type, mySelectData, handleClickBankerBall,handleClickBall,selectedBall,selectedBankerBall,curBallList }) => {
  const { t, i18n } = useTranslation();
  const row = []
  const rowSize = scSize > 1023 ? 10 : 7
  const ballSize = scSize > 1023 ? 'l' : 'm'
  const curClickBallFn = !mySelectData.isMultiCheck ? handleClickBankerBall : handleClickBall;
  for (let i = 1; i <= rowSize; i++) {
    const num = i + rowIndex * rowSize
    const color = colorList[num - 1]
    if (num === 50 && type !== 'single') {
      row.push(<div className='table-cell' key={num} onClick={() => {curClickBallFn(num)}}>
        {curBallList?.numList?.length < 49 ?
          <div className={`yellow-ball cp`}>
            {t('LB_M6_BALLPANEL_ALL')}
          </div>
          :
          <div className='table-cell-ball cp' >
            <img className={`ballsize-${ballSize}`} src={require(`../../../info/marksix/info/images/marksix-F_${i18n.language}.svg`)} alt={num} />
          </div>}
      </div>)
    }
    else if (num >= 50) {
      row.push(<div key={num}></div>)
    }
    else {
      row.push(<div className='table-cell' onClick={() => {window.location.hash = "#ball-panel-table-container";curClickBallFn(num)}} key={num}>
        {!curBallList?.numList?.find(item => item == num) && !curBallList?.bankerList?.find(item => item == num) ?
          <div className={`${color}-ball cp`}>
            {num}
          </div>
          : curBallList?.bankerList?.find(item => item == num) ?
            <div className='table-cell-ball cp'>
              <img className={`ballsize-${ballSize}`} src={require(`../../../info/marksix/info/images/marksix-gold-${num}.svg`)} alt={num} />
            </div>
            :
            <div className='table-cell-ball cp'>
              <img className={`ballsize-${ballSize}`} src={require(`../../../info/marksix/info/images/marksix-${num}.svg`)} alt={num} />
            </div>
        }
      </div>)
    }
  }
  return <div className='table-row'>{row}</div>
}

const Table = (props) => {
  const table = []
  const rowSize = props.scSize > 1023 ? 5 : props.type === 'single' ? 7 : 8
  let key = 1
  const colorList = []
  for (let rowIndex = 0; rowIndex < 5; rowIndex++) {
    for (let i = 1; i <= 10; i++) {
      const red = [1, 2, 7, 8].includes(i - rowIndex)
      const blue = [3, 4, -2, -3, 9, 10].includes(i - rowIndex)
      const color = red ? 'red' : blue ? 'blue' : 'green'
      colorList.push(color)
    }
  }
  for (let i = 0; i < rowSize; i++) {
    table.push(<Row key={key++} rowIndex={i} colorList={colorList} {...props}/>)
  }

  return <div className='ball-panel-table'>
    {table}
  </div>
}