import { useEffect } from 'react';

const useIntersectionObserver = (target, callback) => {
    useEffect(() => {
        const observer = target && new IntersectionObserver(entries => {
            callback?.(entries?.[0]?.intersectionRatio)
        })
        target && observer.observe(target);
        return () => {
            target && observer.unobserve(target)
        }
    }, [target, callback])
}

export default useIntersectionObserver;