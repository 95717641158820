import React, { useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { RacingContext } from '../Home/RacingPage';
import { getSiteCoreImagePath, getMenuParamByName, NewWindowWithProps } from '../Common/home-common';
import { glassboxCustomEvent } from '../Common/utils/glassbox';
import { redirectJCRW } from '../Racing/Common/Common';


export const handleClickMenuItem = (event, menu, handleOnPageChange) => {
    if (menu.itemRedirection?.value === window.sitecoreVars.REDIRECT_JCBW_PAGE && menu.itemPage?.value !== '') {
        handleOnPageChange(menu.itemPage.value);
    } else if (menu.itemLink.value !== '') {
        if (menu.redirectMethod?.value === window.sitecoreVars.REDIRECT_POPUP) {
            let winProps = getMenuParamByName(menu);
            NewWindowWithProps(menu.itemLink.value, menu.key, winProps);
            event.currentTarget.blur();
        } else if (menu.redirectMethod?.value === window.sitecoreVars.REDIRECT_REPLACETAB) {
            redirectJCRW(menu.itemLink.value);
            //NewWindowWithProps(menu.itemLink.value, '_self');
        } else {
            window.open(menu.itemLink.value);
        }
    }

    if (menu.GlassboxTaggingEnabled?.value == '1') {
        const name = menu.GlassboxCustomEventName?.value || "";
        const value = menu.GlassboxCustomEventValue?.value || "";
        if (name != "" && value != "") {
            glassboxCustomEvent(name, value);
        }
    }
};

export const highLightLeftMenuItem = (item, context, product) => {
    let { page, matchId } = useParams();
    if ( !page ) {
        page = 'home';
    }
    page =page.toUpperCase();
    switch (product) {
        case 'racing':
            if (['WPALUP', 'WPQALUP', 'FCTALUP', 'TRIALUP'].includes(page)) {
                page = page.replace('ALUP', '');
            }
            break;
        case 'football':
            if (page === 'INPLAY_ALL') {
                if(`${page}/${matchId}`.toUpperCase() === item.toUpperCase()) return true
                page = 'INPLAY';
                matchId = null ;
            }
            if (page === 'ALLODDS') {
                if(`${page}/${matchId}`.toUpperCase() === item.toUpperCase()) return true
                matchId = null ;
            }
            break;
    }
    return matchId ? `${page}/${matchId}`.toUpperCase() === item.toUpperCase() : page.toUpperCase() === item.toUpperCase();
};

export const isProductInfoItemEnable = (menu) => {
    if (menu.itemEnable.value !== '1') {
        return false;
    }
    return true;
};

export const isMenuWithinDate = (menu, dt) => {
    let emptyDt = '0001-01-01T08:00:00';
    if (menu.fromDate?.value !== emptyDt && moment(menu.fromDate?.value) > dt) {
        return false;
    }
    if (menu.toDate?.value !== emptyDt && moment(menu.toDate?.value) < dt) {
        return false;
    }
    return true;
};

export const isMenuOverDate = (menu, dt) => {
    let emptyDt = '0001-01-01T08:00:00';
    if (menu.startDatetime && menu.startDatetime.value !== emptyDt && moment(menu.startDatetime.value) > dt) {
        return false;
    }
    return true;
};

const getBetType = (typ) => {
    let t = typ.toUpperCase();
    switch (t) {
        case '6UP':
            t = 'SixUP';
            break;
    }
    return t;
};

export const LeftMenuIcon = ({ menu, cashOutNo }) => {
    const { i18n } = useTranslation();
    const racingContext = useContext(RacingContext);

    if (menu.jpFlag?.value === '1') {
        let bType = getBetType(menu.itemPage.value);
        let mtg = racingContext?.content?.meeting;
        if (mtg != null) {
            if (
                mtg.jpEsts.filter(
                    (x) => x.oddsType === bType && (x.jackpot && !isNaN(x.jackpot)) && x.leg.races[0] >= mtg.currentNumberOfRace
                ).length > 0
            ) {
                return (
                    <span className="leftMenuIcon">
                        <img
                            src={getSiteCoreImagePath(menu.itemIcon.src, i18n.language)}
                            width={menu.itemIcon.width}
                            height={menu.itemIcon.height}
                            title={menu.altTxt.value}
                        />
                    </span>
                );
            }
        }
    } else if (menu.sectionIcon?.src) {
        return (
            <span className="leftMenuIcon">
                <img
                    src={getSiteCoreImagePath(menu.sectionIcon.src, i18n.language)}
                    width={menu.sectionIcon.width}
                    height={menu.sectionIcon.height}
                    title={menu.altTxt?.value}
                />
            </span>
        );
    } else if (menu.itemIcon?.src) {
        return (
            <span className="leftMenuIcon">
                <img
                    src={getSiteCoreImagePath(menu.itemIcon.src, i18n.language)}
                    width={menu.itemIcon.width}
                    height={menu.itemIcon.height}
                    title={menu.altTxt?.value}
                />
                {cashOutNo ? cashOutNo : null}
            </span>
        );
    }
    return null;
};

export const MenuIcon = ({ menu, cashOutNo }) => {
    const { i18n } = useTranslation();
    if (menu.itemIcon?.src) {
        return (
            <div className="leftMenuIcon">
                <img
                    src={getSiteCoreImagePath(menu.itemIcon.src, i18n.language, menu.itemIcon.isLocalSrc)}
                    width={menu.itemIcon.width}
                    height={menu.itemIcon.height}
                    title={menu.altTxt?.value}
                />
                {cashOutNo ? cashOutNo : null}
            </div>
        );
    }
    return null;
};

export const IconContent = ({ iconContent }) => {
    const { i18n } = useTranslation();
    if (iconContent?.src) {
        return (
            <div className="leftMenuIcon">
                <img
                    src={getSiteCoreImagePath(iconContent.src, i18n.language)}
                    width={iconContent.width}
                    height={iconContent.height}
                    title={iconContent.alt}
                />
            </div>
        );
    }
    return null;
};

export const BottomMenuIcon = ({ menu }) => {
    const { i18n } = useTranslation();
    if (menu.itemIcon?.src) {
        return (
            <img
                src={getSiteCoreImagePath(menu.itemIcon.src, i18n.language, menu.itemIcon.isLocalSrc)}
                width={menu.itemIcon.width}
                height={24}
                title={menu.altTxt?.value}
            />
        );
    }
    return null;
};

export const hasChpTypePool = (type, chpTypes) => {
    if ( chpTypes!=null ) {
        for ( let i=0; i<chpTypes.length; i++ ) {
            if ( chpTypes[i].foPools?.filter(item=> item.chpType===type).length>0 ) {
                return true;
            }
        }
    }
    return false;
}