import $ from 'jquery';
import moment from 'moment';

const nBetweendelay = 5000;
const nFadespeed = 400;
// let fadeTimeout ;
// var nBetweendelayNews = 10000;
// var nNews=[];
// var nNewsWhatsNew=[];
// var fadeTimeoutWhatsNew;
var fadeTimeout = null;
var isOpenACpage=null;
var acUrl=null;

const validate = (url) => {
	return url
}

export function NewWindow(mypage, myname, w, h, scroll, resizable) {
	mypage = validate(mypage);
	var winl = (window.screen.width - w) / 2;
	var wint = (window.screen.height - h) / 2;
	var winprops = 'height=' + h + ',width=' + w + ',top=' + wint + ',left=' + winl + ',scrollbars=' + scroll + ',resizable=' + resizable
	var win = window.open(mypage, myname, winprops);
	win.self.focus();
	if (parseInt(navigator.appVersion) >= 4) {
		win.window.focus();
	}
	return win;
}

export function NewWindowWithProps(mypage, myname, winprops) {
	var win = window.open(mypage, myname, winprops);
	win.self.focus();
	if (parseInt(navigator.appVersion) >= 4) {
		win.window.focus();
	}
}

export function openBannerNewWindow(url, target, parameter) {
	if(url==""||url==null)
	 return;
	if(url.indexOf("javascript:") !== -1)
	{
		var javaFunc = url.split("javascript:")
		window.eval(javaFunc[1]);
	}
	else if(typeof parameter == "undefined" || parameter=='' || parameter==null) {
		if(target=="New Window")
			window.open(url,"_blank");
		else if (target=="Same Window")
			window.open(url,"_top");
		else
			window.open(url,target);
		}
		else{
			if(target=="New Window")
				window.open(url,"_blank",parameter);
			else if (target=="Same Window")
				window.open(url,"_top",parameter);
			else
				window.open(url,target,parameter);
		}
}

export function MaxOnePopupWindow(isLogout=false,mypage, myname, w, h, scroll, resizable) {
	var winl = (window.screen.width - w) / 2;
	var wint = (window.screen.height - h) / 2;
	var winprops = 'height=' + h + ',width=' + w + ',top=' + wint + ',left=' + winl + ',scrollbars=' + scroll + ',resizable=' + resizable
	if(!acUrl && isLogout){return ;}
	if(acUrl && (acUrl!==mypage || isLogout)){
		acUrl=null;
		isOpenACpage?.close();
		if(isLogout){return ;}
	}
	if (isOpenACpage == null || isOpenACpage?.closed) {
		acUrl=mypage;
		isOpenACpage = window?.open(mypage, myname, winprops);
        isOpenACpage?.self?.focus();
        if (parseInt(navigator.appVersion) >= 4) {
            isOpenACpage?.window?.focus();
        }
	} else {
		isOpenACpage?.focus();
	}
}

export function fadeToggle(i, nNews) {
	if (i === nNews.length)
		i = 0;

	$('#divNewsCont').fadeOut(nFadespeed, function () {
		$('#divNewsCont').html(nNews[i]);
		$('#divNewsCont').fadeIn();
		fadeTimeout = setTimeout(() => fadeToggle(i + 1, nNews), nBetweendelay); //setTimeout('fadeToggle(' + (i+1) + ')', nBetweendelay);
	});
}

export function clearToggle() {
	clearTimeout(fadeTimeout);
}

export function pad(str, max) {
	str = str.toString();
	return str.length < max ? pad('0' + str, max) : str;
}

export function isLocalMeeting(venCode) {
	return ['HV', 'ST', 'CH'].includes(venCode);
}

export function StringXMLToJSON(text) {
	var parser = new DOMParser();
	var xmlDoc = parser.parseFromString(text, "text/xml");

	return xmlToJson(xmlDoc);
}

function xmlToJson(xml) {

	// Create the return object
	var obj = {};

	if (xml.nodeType == 1) { // element
		// do attributes
		if (xml.attributes.length > 0) {
			obj["@attributes"] = {};
			for (var j = 0; j < xml.attributes.length; j++) {
				var attribute = xml.attributes.item(j);
				obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
			}
		}
	} else if (xml.nodeType == 3) { // text
		obj = xml.nodeValue;
	}

	// do children
	if (xml.hasChildNodes()) {
		for (var i = 0; i < xml.childNodes.length; i++) {
			var item = xml.childNodes.item(i);
			var nodeName = item.nodeName;
			if (typeof (obj[nodeName]) == "undefined") {
				obj[nodeName] = xmlToJson(item);
			} else {
				if (typeof (obj[nodeName].push) == "undefined") {
					var old = obj[nodeName];
					obj[nodeName] = [];
					obj[nodeName].push(old);
				}
				obj[nodeName].push(xmlToJson(item));
			}
		}
	}
	return obj;
};

export function currencyFormat(num) {

	if (num === null || num === "") {
		num = 0;
	} else {
		num = parseFloat(num);
	}
	return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function formatNthNum(name) {
	if (name.indexOf('st') >= 0) {
		return <>{name.replace('st', '')}<sup>st</sup></>
	}
	if (name.indexOf('nd') >= 0) {
		return <>{name.replace('nd', '')}<sup>nd</sup></>
	}
	if (name.indexOf('rd') >= 0) {
		return <>{name.replace('rd', '')}<sup>rd</sup></>
	}
	if (name.indexOf('th') >= 0) {
		return <>{name.replace('th', '')}<sup>th</sup></>
	}
	return name;
}

// support yyyy-MM-dd
export function getDayOfWeek(dt, t) {
	return t('LB_' + moment(dt.substring(0, 10)).format('ddd').toUpperCase());
}

export function getDayOfWeek2(dt, t) {
	return t('LB_' + moment(dt.substring(0, 10)).format('ddd').toUpperCase() + '_SHORT');
}

export function getDayOfWeekShort(dt, t) {
	return t('LB_S_' + moment(dt.substring(0, 10)).format('ddd').toUpperCase());
}

export function capitalize(str) {
	if ( str?.length > 0 )
		return str[0].toUpperCase() + str.slice(1).toLowerCase();
	return str;
}

// support hh:MM
export function get12HourTime(tm, t) {
	let hour = parseInt(tm.substring(0, 2));
	let minute = tm.substring(3, 5);
	let tmpTm = '';
	let tmpFm = '';
	if (hour >= 0 && hour < 12) {
		tmpTm = hour + ':' + minute;
		tmpFm = t('LB_AM');
	}
	else if (hour == 12) {
		tmpTm = hour + ':' + minute;
		tmpFm = t('LB_PM');
	}
	else if (hour >= 12 && hour < 18) {
		tmpTm = (hour - 12) + ':' + minute;
		tmpFm = t('LB_PM');
	}
	else if (hour <= 23) {
		tmpTm = (hour - 12) + ':' + minute;
		tmpFm = t('LB_EVENING');
	}

	return t('LB_12HOUR_TIMEFORMAT').replace('{tm}', tmpTm).replace('{fm}', tmpFm);
}

export function PrintNow() {

	window.print();
}

export const getMenuParamByName = (menu) => {
	let defValue = [ 900, 600, 20, 20 ];
	if ( !isNaN(menu.pW.value) )
		defValue[0] = parseInt(menu.pW.value);
	if ( !isNaN(menu.pH.value) )
		defValue[1] = parseInt(menu.pH.value);
	if ( !isNaN(menu.pX.value) )
		defValue[2] = parseInt(menu.pX.value);
	if ( !isNaN(menu.pY.value) )
		defValue[3] = parseInt(menu.pY.value);

	return `width=${defValue[0]},height=${defValue[1]},left=${defValue[2]},top=${defValue[3]},scrollbars=yes,resizable=yes`;
}

export const toLocaleLang = (lang) => {
	return lang==="ch" ? "zh-HK" : "en-US";
}

export const getSiteCoreImagePath = (src, lang, isLocalSrc) => {
	if(isLocalSrc){
		return `${src}`;
	}
	return `${window.globalConfig.SITECORE_IMAGE_URL}${src}`;//${src?.indexOf('?')>=0 ? '&' : '?'}sc_lang=${toLocaleLang(lang)}`;
}

export const isEWinPage = () => {
    return ['', '/en/', '/ch/'].includes(location.pathname)
}

export const handleClickRacingLive = (lang) => {
	let url = `${window.globalConfig.ELOGIN_URL}/api/SSOWeb/GetToken?UIC=${toLocaleLang(lang)}`;
	NewWindow(url, '', 770, 500, 1, 1);
}

export const postMsgToPeNote = (pop) => {
	let pmRetryCnt = 0;
	window.pmMessageInterval = setInterval(() => {
		if ( pmRetryCnt < 100 ) {
			pop.postMessage("Test open", window.globalConfig.PENOTES_URL);
			pmRetryCnt++;
		}
	}, 500);
}