import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import './mobile.scss';
// import '../../../App.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Past30Days from './AccountRecords';
import CurrentSession from './CurrentSession';
import TransactionRecords from './TransactionRecords';
import DateAccBalHeader from './DateAccBalHeader';
import { Export, Print } from './ARExportandPrint';
import { getOffsetTimeQuery } from './Data/DataQuery';
import { useLazyQuery } from '@apollo/client';
import { errRedirectPage } from '../../Common/utils';
import { getProfileByToken } from '../LoginHooks';
import { ApiErrPopupUI } from '../LoginBase';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { setSsoLogout } from '../SsoHook';
import useBroadcastChannel from '../../Common/hooks/useBroadcastChannel';
import Loading from '../../Common/ui-components/Loading';
import CloseButton from '../../Common/ui-components/CloseButton';
import { isEoD } from '../../Common/GlobalFunc';
import { useWindowSize } from '../../Common/CommonHooks.js'
import { GlobalContext } from '../../../contexts/GlobalContext.js';


const AccountRecords = () => {
    const { t, i18n } = useTranslation();
    const {isMobileSize} = useWindowSize()
    const globalContext = useContext(GlobalContext);
    const [tab, setTab] = useState(2);
    const [hasScroll, setHasScroll] = useState(false);
    const [disableScroll, setDisableScroll] = useState(false);
    const [showExportPrint, setShowExportPrint] = useState(false);
    const [downloadData, setDownloadData] = useState({
        searchParams: {},
        statementData: []
    });
    const urlParams = useParams();
    const [balanceLoaded, setBalanceLoaded] = useState(false);
    const [offsetDate, setOffsetDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isCallingApi, setIsCallingApi] = useState(false);
    const ssoLogoutBoardcast = useBroadcastChannel('ssoLogoutNow');
    let isSpeedbet;
    if (window.globalConfig.IS_IB) {
        isSpeedbet = sessionStorage.getItem('speedbet_cust') === 'Y';
    } else {
        const profile = getProfileByToken(sessionStorage.getItem('id_token'));
        isSpeedbet = profile?.speedBet ? true : false;
    }
    const [isApiErr, setIsApiErr] = useState(false);
    const [errorMessageCode, setErrorMessageCode] = useState(''); // IB
    const serverTime = globalContext?.sessionServerTimeStamp || (new Date()).getTime();
    const [loadSimpleMatchListQuery, simpleGqlVars] = useLazyQuery(getOffsetTimeQuery(), {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const offset = data?.timeOffset?.fb || 0;
            const searchEndDt = new Date(serverTime + offset * 1000);
            setOffsetDate(searchEndDt);
            setIsLoading(false);
        },
        onError: (err) => {
            const searchEndDt = serverTime;
            setOffsetDate(searchEndDt);
            setIsLoading(false);
            errRedirectPage(err);
        }
    });

    const getEodMessage = () => {
        let message =
            i18n.language === 'en' ? sessionStorage.getItem('OOSEngContent') : sessionStorage.getItem('OOSChiContent');
        if (message == null || message.length <= 0) {
            message = t('LB_BS_EOD');
        }
        return message;
    };

    useEffect(() => {
        const init = async() =>{
            if(await checkIsEod()){
                window.alert(getEodMessage())
                window.close()
            }
        }
        init()
        loadSimpleMatchListQuery();
        if (urlParams?.curTab === 'transactionRecords') {
            setTab(1);
        }
    }, []);

    useEffect(() => {
        setDisableScroll(false);
    }, [tab]);

    const checkIsEod = async () => {
        return setEodUi();
    };

    const setEodUi = () => {
        if (isEoD()) {
            return true;
        } else {
            return false;
        }
    };

    const isReturnErrorCode = (errorCode, action) => {
        const code = parseInt(errorCode);
        if (!isNaN(code)) {
            if (code == -2) {
                setSsoLogout();
                ssoLogoutBoardcast?.postMessage('ssoLogoutNow');
                window.alert(t('LB_BS_SSO_SIGN_OUT_PASSIVE'));
                window.close();
                return true;
            }

            if (code == 1) {
                if (action == 'recall') {
                    window.alert(sessionStorage.getItem('recall_error'));
                }
                if (action == 'stmt') {
                    window.alert(sessionStorage.getItem('stmt_error'));
                }
                window.close();
                return true;
            }
            if(code == 9){
                window.alert(t('LB_BS_EOD'));
                window.close();
                return true
            }
            if (code >= 100 && code <= 120) {
                window.alert(t('IB_ERROR_' + errorCode));
                window.close();
                return true;
            }
        }
        return false;
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Scrollbars
            className={`ARInbox-scrollbars ${disableScroll ? 'disableScroll' : ''}`}
            style={{ height: '100vh' }}
            autoHeight={false}
            autoHide={disableScroll}
            key={tab}
            onUpdate={(data) => setHasScroll(data.top > 0)}
        >
            <div className={`ARInbox ARInbox-tab-${tab} ${hasScroll > 0 ? 'hasScroll' : ''}`}>
                <div className="commom-header">
                    <div className={`ARInboxTitle ${isMobileSize?'popupTitle':''}`}>
                        <div>{t('LB_BETSLIP_ACCOUNTRECORDS')}</div>
                        <div className="df controlContainer">
                            {showExportPrint ? (
                                <>
                                    {tab === 2 && <Export downloadData={downloadData} />}
                                    <Print currentTab={tab} />
                                </>
                            ) : null}
                        </div>
                        {isMobileSize && <CloseButton isIOSPWA={true} />}
                    </div>
                    <div className="ARInboxHeader">
                        {new Array(3).fill('1').map((item, index) => {
                            return (
                                <div className={`ARInboxHeaderCell ${tab === index && 'active'} `} key={index}>
                                    <div
                                        className={`ARInboxHeaderCellInner ${0 === index && 'firstCell'}`}
                                        onClick={() => {
                                            !isCallingApi && tab !== index && setTab(index);
                                        }}
                                    >
                                        {t(`LB_BETSLIP_ACCOUNTRECORDSTAB${index}`)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <DateAccBalHeader setBalanceLoaded={setBalanceLoaded} offsetDate={offsetDate} />
                </div>

                <div className={`${tab === 0 ? 'ARMain' : 'hide'} `}>
                    <CurrentSession
                        setTab={setTab}
                        setShowExportPrint={setShowExportPrint}
                        currentTab={tab}
                        isShow={tab === 0}
                    />
                </div>
                {tab === 1 && (
                    <div className={`${tab === 1 ? 'ARMain' : 'hide'} `}>
                        <TransactionRecords
                            setShowExportPrint={setShowExportPrint}
                            currentTab={tab}
                            isShow={balanceLoaded}
                            setIsApiErr={setIsApiErr}
                            isReturnErrorCode={isReturnErrorCode}
                            setIsCallingApi={setIsCallingApi}
                        />
                    </div>
                )}
                {tab === 2 && (
                    <div id="acPastDays" className={`${tab === 2 ? 'ARMain' : 'hide'} `}>
                        <Past30Days
                            setShowExportPrint={setShowExportPrint}
                            offsetDate={offsetDate}
                            currentTab={tab}
                            updateDownloadData={setDownloadData}
                            balanceLoaded={balanceLoaded}
                            isSpeedbet={isSpeedbet}
                            setIsApiErr={setIsApiErr}
                            isReturnErrorCode={isReturnErrorCode}
                            setErrorMessageCode={setErrorMessageCode}
                            setDisableScroll={setDisableScroll}
                            setIsCallingApi={setIsCallingApi}
                        />
                    </div>
                )}

                <ApiErrPopupUI isApiErr={isApiErr} errorMessageCode={errorMessageCode} />
            </div>
        </Scrollbars>
    );
};

export default AccountRecords;
