import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../Common/GlobalFunc';
import { getDayOfWeek } from '../../Common/home-common';
import { MarksixContext } from '../../Home/MarksixPage';
import { isSnowballDraw } from './Common';
import Loading from '../../Common/ui-components/Loading';

const LastDrawInfoTable = () => {

  const { t } = useTranslation()
  const context = useContext(MarksixContext);
  const draw = context.lastDraw;
  const isSB = isSnowballDraw(draw);

  const prizeRender = (prize) => {
    const isZeroReg = /^0*(\.)?(0*)?$/;
    return !isZeroReg.test(prize) ? `$${parseInt(prize).toLocaleString()}` : '-';
  }

  const TableL = () => {
    let drawId = `${draw.year.substring(2, 4)}/${String(draw.no).padStart(3, '0')}`;
    let drawDt = ['', ''];
    let dateOfWeek = '';
    if (draw.drawDate != null && draw.drawDate != '') {
      drawDt = formatDateTime(draw.drawDate.substring(0, 10));
      dateOfWeek = '(' + getDayOfWeek(draw.drawDate, t) + ')';
    }
    let inv = '-';
    if (draw?.lotteryPool?.totalInvestment)
      inv = "$" + parseInt(draw.lotteryPool.totalInvestment).toLocaleString();
    return <div className='next-draw-table-r'>
      <div className='next-draw-table-sml-item df dtl'>
        <div>{t('LB_RC_JKC_DETAILS')}</div>
      </div>
      <div className='next-draw-table-item df'><div>{t('LB_M6_LASTDRAW_NUM')}</div><div>{drawId}</div></div>
      <div className='next-draw-table-item next-draw-table-item-grey-bg df'><div>{t('LB_M6_DRAWDATE')}</div><div style={{ wordBreak: "keep-all" }}>{drawDt[0]} <wbr />{dateOfWeek}</div></div>
      <div className='next-draw-table-item df'><div>{t('LB_M6_TOTAL_TURNOVER')}</div><div>{inv}</div></div>
    </div>
  }
 

  const balls = draw?.drawResult?.drawnNo || [];
  const sno = draw?.drawResult?.xDrawnNo;

  const tableRProps = {draw, prizeRender}

  return <div className='next-draw-table-container'>
    <header >{t('LB_M6_LASTDRAW')}</header>
    {context?.gqlVars?.loading ? <>
        <div className='next-draw-table next-draw-noData'>
          <Loading />
        </div>
      </>
      :
      draw?.id ? <>
        <div className='next-draw-table m6-last-draw-table'>
          <TableL />
          <TableR {...tableRProps}/>
        </div>
        <div className="next-draw-table-result next-draw-margin-m">
          <p className="next-draw-table-result-title" >{t('LB_M6_SINGLE_DRAW_RESULTS')} :</p>
          <div className="next-draw-table-result-balls" >
            {balls.map(i => {
              return <div key={i}><img className='ballsize-m' src={require(`../../../info/marksix/info/images/marksix-${i}.svg`)} alt={i} /></div>
            })}
            <p className="next-draw-table-result-plus" key='plus'>+</p>
            {sno ? <img className="ballsize-m" src={require(`../../../info/marksix/info/images/marksix-${sno}.svg`)} alt={sno} key={sno} /> : null}
          </div>
        </div>
      </>
      : 
        <div className='next-draw-table next-draw-noData'>
          <div className='noInfoTip'>
            {t('LB_RC_JTC_NOINFO')}
          </div>
        </div>
      }
  </div>
}

export default LastDrawInfoTable

const TableR = ({draw, prizeRender}) => {
  
  const { t } = useTranslation()
  let prizes = draw.lotteryPool?.lotteryPrizes;
  let prizeRows = [];

  if (prizes == null || prizes.length == 0) {
    prizeRows.push(<div className="prizesEmpty df">
      <div className="next-draw-table-sml-auto-width">
        <div className='next-draw-table-item'>{t('LB_M6_SINGLE_1_M')}</div>
        <div className='next-draw-table-item next-draw-table-item-grey-bg'>{t('LB_M6_SINGLE_2_M')}</div>
        <div className='next-draw-table-item'>{t('LB_M6_SINGLE_3_M')}</div>
      </div>
      <div className="marsix-prize-avail-soon">{t('LB_M6_AVAIL_SOON')}</div>
    </div>);
  }
  else {
    let p1 = prizes.filter(x => x.type == "1")[0];
    let p2 = prizes.filter(x => x.type == "2")[0];
    let p3 = prizes.filter(x => x.type == "3")[0];

    prizeRows = <>
      {p1 ? <div className='next-draw-table-item lastDrawResult  df'>
        <div className="next-draw-table-sml-auto-width  lastDrawLabel">{t('LB_M6_SINGLE_1_M')}</div>
        <div className='result-1st-prize'>{prizeRender(p1.dividend)}</div>
        <div>{parseFloat(p1.winningUnit) == 0 ? '-' : (p1.winningUnit / draw.lotteryPool.unitBet).toLocaleString('en-US', { minimumFractionDigits: 1 })}</div>
      </div> : null}
      {p2 ? <div className='next-draw-table-item lastDrawResult next-draw-table-item-grey-bg df'>
        <div className="next-draw-table-sml-auto-width lastDrawLabel">{t('LB_M6_SINGLE_2_M')}</div>
        <div>{prizeRender(p2.dividend)}</div>
        <div>{parseFloat(p2.winningUnit) == 0 ? '-' : (p2.winningUnit / draw.lotteryPool.unitBet).toLocaleString('en-US', { minimumFractionDigits: 1 })}</div>
      </div> : null}
      {p3 ? <div className='next-draw-table-item lastDrawResult next-draw-table-item-red-type df'>
        <div className="next-draw-table-sml-auto-width lastDrawLabel">{t('LB_M6_SINGLE_3_M')}</div>
        <div>{prizeRender(p3.dividend)}</div>
        <div>{parseFloat(p3.winningUnit) == 0 ? '-' : (p3.winningUnit / draw.lotteryPool.unitBet).toLocaleString('en-US', { minimumFractionDigits: 1 })}</div>
      </div> : null}
    </>
  }
  return <div className='next-draw-table-r next-draw-margin-m'>
    <div className='next-draw-table-sml-item df'>
      <div className="next-draw-table-sml-auto-width">{t('LB_M6_SINGLE_PRIZE')}</div>
      <div>{t('LB_M6_SINGLE_UNIT_PRIZE')}</div>
      <div>{t('LB_M6_SINGLE_WIN_UNIT')}</div>
    </div>
    {prizeRows}
  </div>
}