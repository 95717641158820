import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../Common/ConfigHelper';
import LinkMain from './LinkMain';
import LinkTC from './LinkTC';
import LinkOTP from './LinkOTP';
import LinkResult from './LinkResult';
import LinkBankList from '../components/LinkBankList';

const LinkingBankAccount = ({ props }) => {
    const { nbaData, FPSErrorList, isOpenBankPageStep, curNbaNo, isReturnErrorCode } = props;
    const [selectedItem, setSelectedItem] = useState(null);
    const [curStep, setCurStep] = useState(0);
    const [linkResult, setLinkResult] = useState();
    const [otpConfirmData, setOtpConfirmData] = useState('');
    const [otpCounter, setOTPCounter] = useState(betslipMenu?.OTPResendSeconds);
    const { betslipMenu } = useContext(ConfigContext);

    useEffect(() => {
        if (isOpenBankPageStep) {
            setCurStep(isOpenBankPageStep);
            if (isOpenBankPageStep === 1 && nbaData.link) {
                const { nba1, nba2 } = nbaData.link;
                let paraObj = {};
                if (curNbaNo == 1) {
                    paraObj = {
                        nba: 'nba1',
                        linkStatus: nba1?.FPS?.linkStatus,
                        bankAccountType: 1,
                        isSingleLeg: nba1?.FPS?.isSingleLeg
                    };
                } else if (curNbaNo == 2) {
                    paraObj = {
                        nba: 'nba2',
                        linkStatus: nba2?.FPS?.linkStatus,
                        bankAccountType: 2,
                        isSingleLeg: nba2?.FPS?.isSingleLeg
                    };
                }
                setSelectedItem(paraObj);
            }
        }
    }, [isOpenBankPageStep]);

    useEffect(() => {
        var createAt = otpConfirmData?.DoOTPCodeGenerateTRResult?.find((item) => item.Key == 'createAt')?.Value;
        var expireAt = otpConfirmData?.DoOTPCodeGenerateTRResult?.find((item) => item.Key == 'expireAt')?.Value;

        var count = parseInt(expireAt) - parseInt(createAt);

        if (count && count > 0) {
            if (count > 1000) {
                count = Math.floor(count / 1000);
            }
        } else {
            count = betslipMenu?.OTPResendSeconds;
        }
        setOTPCounter(count);
    }, [otpConfirmData]);

    return (
        <div className="deposit-main">
            {curStep === 0 && (
                <LinkMain
                    props={{
                        ...props,
                        curStep,
                        setCurStep,
                        selectedItem,
                        setSelectedItem,
                        setLinkResult
                    }}
                />
            )}
            {curStep === 1 && (
                <LinkTC
                    props={{
                        ...props,
                        curStep,
                        setCurStep,
                        selectedItem,
                        setSelectedItem,
                        otpConfirmData,
                        setOtpConfirmData,
                        setOTPCounter
                    }}
                />
            )}
            {curStep === 2 && (
                <LinkOTP
                    props={{
                        ...props,
                        curStep,
                        setCurStep,
                        selectedItem,
                        setLinkResult,
                        otpConfirmData,
                        setOtpConfirmData,
                        isReturnErrorCode,
                        otpCounter
                    }}
                />
            )}
            {curStep === 3 && (
                <LinkResult
                    props={{
                        ...props,
                        curStep,
                        setCurStep,
                        setSelectedItem,
                        selectedItem,
                        linkResult,
                        isCheckStatus: false
                    }}
                />
            )}
            {curStep === 4 && (
                <LinkBankList
                    props={{
                        ...props,
                        curStep,
                        setCurStep
                    }}
                />
            )}
            {curStep === 5 && (
                <LinkResult
                    props={{
                        ...props,
                        curStep,
                        setCurStep,
                        selectedItem,
                        linkResult,
                        isCheckStatus: true
                    }}
                />
            )}
        </div>
    );
};

export default LinkingBankAccount;
