import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import EpscoPin from '../components/EpscoPin';
import FPSChargeTips from '../components/FPSChargeTips';
import { textFormatAmountFn, isHasPropertyInObj } from '../../../Common/GlobalFunc';
import { ConfigContext } from '../../../Common/ConfigHelper';
import { postDepositFps, postDepositEps } from '../FundsTransferAPI';
import { postDepositFps_IB, postDepositEps_IB, postSendPPSDORequest_IB, postEnquirePPSStatusTR_IB } from '../FundsTransferIBAPI';
import { getBalanceValue } from '../../BetSlipHooks';
import { getFPSErrorMsg, formatServiceCharge, saveEpsNoticeCookie } from '../utils';
import { setSsoLogout } from '../../SsoHook';
import Popup from 'reactjs-popup';
import useBroadcastChannel from '../../../Common/hooks/useBroadcastChannel';
import { saveBetLinesToCurrentSessionRecords } from '../../../Common/CurrentSessionRecords';
import { GetError } from '../../../Common/bs_str_table';
import { Cookies } from '../../../Common/CookieHelper';
import Loading from '../../../Common/ui-components/Loading';

const DepositPreview = ({ props }) => {
    const {
        curStep,
        setCurStep,
        bankInfo,
        loginAccount,
        setResultShowStatus,
        epsPin,
        setEpsPin,
        transactionMethod,
        depositAmount,
        nbaData,
        loadingStatus,
        setLoadingStatus,
        chargeAmtFps,
        updateBetSlipBalance,
        setResultPageRespData,
        setGetBalance,
        updateFPSInfoFn,
        FPSErrorList,
        chargeAmtEps,
        isReturnErrorCode,
        bankOrPpsAccType, 
        depositConfig, 
        getBalanceFn,
        setPpsPageRespData,
        saveEpsNotice
    } = props;
    const { t, i18n } = useTranslation();
    const { betslipMenu } = useContext(ConfigContext);
    const betslipResultCode = betslipMenu.betslipResultCode;
    const [isChargeTipsStatus, setIsChargeTipsStatus] = useState(false);
    const broadcast_resetlogin = useBroadcastChannel('resetLoginTimeout');
    const curIsPpsType=bankOrPpsAccType==='ppsType';

    const confirmFn = () => {
        if (transactionMethod === 'EPS' && epsPin?.length < 8) {
            if (!epsPin) {
                return alert(t('LB_BS_FT_PIN_ERR_MSG1'));
            }
            return alert(t('LB_BS_FT_PIN_ERR_MSG2'));
        }

        if (sessionStorage.getItem('FPScurrentMonthlyDepCnt') >= nbaData.config?.FPS.monthlyDepMaxCntNoCharge && transactionMethod === 'FPS') {
            setIsChargeTipsStatus(true);
        } else {
            commitDepositApi();
        }
    };

    const commitDepositApi = async () => {
        setLoadingStatus(true);
        const bankAccountType = bankInfo.bankAccountType;
        let params = {
            fpsChannelID: 104,
            bankAccountType,
            bankAccountNo: bankInfo.bankAccountNo,
            bankCode: bankInfo.bankInfo.code,
            firstName: 'string',
            lastName: 'string',
            transAmt: depositAmount * 100
        };
        let curApi;
        if (window.globalConfig.IS_IB) {
            curApi = postDepositFps_IB;
        } else {
            curApi = postDepositFps;
        }

        if (transactionMethod === 'EPS') {
            if (saveEpsNotice) {
                saveEpsNoticeCookie(loginAccount);
            }
            if (window.globalConfig.IS_IB) {
                curApi = postDepositEps_IB;
            } else {
                curApi = postDepositEps;
            }
            params = null;
            params = {
                deviceType: 26,
                pinBlock: epsPin,
                amount: window.globalConfig.IS_IB ? depositAmount : depositAmount * 100,
                nba: bankAccountType
            };
        }
        const data = {
            params,
            timeout: depositConfig[transactionMethod].timeout,
            lang: i18n.language
        };

        const responseData = await curApi(data);
        broadcast_resetlogin?.postMessage(false);
        let status = '';
        let msg = ``;
        let msg_ch = ``;
        let msg_en = ``;
        let amount = 0;
        if (transactionMethod === 'FPS') {
            if (window.globalConfig.IS_IB) {
                if (isReturnErrorCode(sessionStorage.getItem('result'))) {
                    return;
                }
            }

            if (responseData?.transStatus === 'AP') {
                status = 'SUCCESSFUL';
                if (window.globalConfig.IS_IB) {
                    amount = responseData.transAmt ? responseData.transAmt : 0;
                } else {
                    amount = responseData.transAmt ? responseData.transAmt / 100 : 0;
                }
                await getBalanceFn();
                await updateFPSInfoFn(responseData?.currentMonthlyDepCnt || 1);
            } else if (['PX', 'AA', 'MA', 'IP', 'AX', 'UX'].includes(responseData?.transStatus)) {
                status = 'PENDING';
                msg = t('LB_BS_FT_RESULTS_FPS_BANK_TIMEOUT');
                msg_ch = t('LB_BS_FT_RESULTS_FPS_BANK_TIMEOUT', { lng: 'ch' });
                msg_en = t('LB_BS_FT_RESULTS_FPS_BANK_TIMEOUT', { lng: 'en' });
                await getBalanceFn();
            } else if (responseData?.errCode != 'TIMEOUT') {
                status = 'REJECTED';
                if (window.globalConfig.IS_IB) {
                    msg_en = responseData?.errMsg;
                    msg_ch = responseData?.errMsgTC;
                    msg = i18n.language === 'en' ? msg_en : msg_ch;
                } else {
                    msg = getFPSErrorMsg(responseData, FPSErrorList, i18n.language);
                    msg_ch = getFPSErrorMsg(responseData, FPSErrorList, 'ch');
                    msg_en = getFPSErrorMsg(responseData, FPSErrorList, 'en');
                }
                await getBalanceFn();
            }
            if (responseData?.serviceChargeAmt >= 100) {
                responseData.chargeAmt = responseData.serviceChargeAmt / 100;
            }
        } else if (transactionMethod === 'EPS') {
            if (window.globalConfig.IS_IB) {
                if (isReturnErrorCode(sessionStorage.getItem('eft_status'))) {
                    return;
                }
            }

            if (responseData.code === '0' || responseData.code === 0) {
                status = 'SUCCESSFUL';
                amount = responseData.amountCent ? responseData.amountCent / 100 : 0;
                responseData.transactionNumber = responseData.number;
                await getBalanceFn();
            } else if (responseData.code == 2002) {
                // pending
                status = 'PENDING';
                msg = t('LB_BS_FT_RESULTS_EPS_BANK_TIMEOUT');
                msg_en = t('LB_BS_FT_RESULTS_EPS_BANK_TIMEOUT', { lng: 'en' });
                msg_ch = t('LB_BS_FT_RESULTS_EPS_BANK_TIMEOUT', { lng: 'ch' });
                await getBalanceFn();
            } else if (responseData?.errCode != 'TIMEOUT') {
                switch (responseData.errorCode) {
                    case 'EPS BANK TIMEOUT':
                    case 'EFT BANK TIMEOUT WITHDRAWAL':
                    case 'EFT BANK TIME OUT WITHDRAWAL':
                    case 'BANK TIME OUT WITHDRAWAL':
                        status = 'PENDING';
                        msg = t('LB_BS_FT_RESULTS_EPS_BANK_TIMEOUT');
                        msg_en = t('LB_BS_FT_RESULTS_EPS_BANK_TIMEOUT', { lng: 'en' });
                        msg_ch = t('LB_BS_FT_RESULTS_EPS_BANK_TIMEOUT', { lng: 'ch' });
                        break;
                    default:
                        status = 'REJECTED';
                        const langMsg = i18n.language === 'en' ? 'message_en' : 'message_cn';
                        if (window.globalConfig.IS_IB) {
                            msg_en = responseData?.errMsg;
                            msg_ch = responseData?.errMsgTC;
                            msg = i18n.language === 'en' ? msg_en : msg_ch;
                        } else {
                            msg =
                                responseData?.[langMsg] ||
                                betslipResultCode?.[responseData.code]?.[i18n.language] ||
                                betslipResultCode?.[responseData.code]?.['en'];
                            msg_en =
                                responseData?.['message_en'] ||
                                betslipResultCode?.[responseData.code]?.['en'] ||
                                betslipResultCode?.[responseData.code]?.['en'];
                            msg_ch =
                                responseData?.['message_cn'] ||
                                betslipResultCode?.[responseData.code]?.['ch'] ||
                                betslipResultCode?.[responseData.code]?.['en'];
                        }
                        responseData.errorCode = responseData.code;
                        await getBalanceFn();
                }
            }
            if (responseData?.serviceChargeAmt >= 100) {
                responseData.chargeAmt = responseData.serviceChargeAmt / 100;
            }
        }
        if (responseData?.errCode === 'TIMEOUT') {
            status = 'TIMEOUT';
            msg = t('LB_BS_FT_RESULTS_FRONTEND_TIMEOUT');
            msg_en = t('LB_BS_FT_RESULTS_FRONTEND_TIMEOUT', { lng: 'en' });
            msg_ch = t('LB_BS_FT_RESULTS_FRONTEND_TIMEOUT', { lng: 'ch' });
        }

        responseData.msg = msg;
        responseData.msg_en = msg_en;
        responseData.msg_ch = msg_ch;
        responseData.resAmt = amount;
        responseData.status = status;
        setResultPageRespData(responseData);
        setResultShowStatus(status);
        setCurStep(curStep + 1);
        setLoadingStatus(false);
    };

    const backFn = () => {
        setIsChargeTipsStatus(false);
        setCurStep(0);
    };
    const continueFn = () => {
        setIsChargeTipsStatus(false);
        commitDepositApi();
    };
    const ppsPreviewNextFn = async() => {
        setLoadingStatus(true);
        const params = {
            transAmt: depositAmount
        };
        const data = {
            params,
            timeout: 120,
            language: i18n.language
        };
        let eftPath=window.location.href;
        if(eftPath.indexOf('?')>0){
            eftPath=eftPath.split('?')[0]
        }
        Cookies.setData('sp4eEftPath', eftPath, true, 30*60);
        const ppsRespData = await postSendPPSDORequest_IB(data);

        saveBetLinesToCurrentSessionRecords([
            {
                prod: 'FT',
                Result : '', 
                resCode : '999', 
                resultStatus: 'PROCESSING',
                transactionNumber: '',
                referenceNo: ppsRespData.ReferenceNo,
                transaction: 'Deposit',
                curIsPpsType : true,
                msg_en : '',
                msg_ch : '',
                amount: textFormatAmountFn(
                    0,
                    2
                ) ,
                charge: textFormatAmountFn(0, 0),
            }
        ]);

        if (ppsRespData.Result == "0") {
            window.location.href=ppsRespData.PopupURL;
            // window.resizeTo(800, 630);
            window.focus();
        }else{
            let status = 'REJECTED';
            let curSessionRecordsStatus = 'REJECTED';
            if(['611','615'].includes(ppsRespData.Result)){
                curSessionRecordsStatus = 'PROCESSING';
            } else if(['901','603','604','499','610','612','613'].includes(ppsRespData.Result)){
                curSessionRecordsStatus = 'UNKNOWN';
            }
            ppsRespData.status = status;
            ppsRespData.curSessionRecordsStatus = curSessionRecordsStatus;
            ppsRespData.curIsPpsType = true;
            const newObj = await postEnquirePPSStatusTR_IB(ppsRespData.ReferenceNo);
            ppsRespData.transactionNumber=newObj.TransactionNumber;
            ppsRespData.respMsg=newObj.MSG;
            setPpsPageRespData(ppsRespData);
            setResultPageRespData(ppsRespData);
            setResultShowStatus(status);
            setCurStep(curStep + 1);
            setLoadingStatus(false);
        }
    };

    const onChangePin = (str = '') => {
        setEpsPin && setEpsPin(str);
    };

    const langName = i18n.language === 'en' ? 'name_en' : 'name_ch';
    const serviceChargeAmt = transactionMethod === 'EPS' ? chargeAmtEps : chargeAmtFps;
    if (curStep === 1) {
        if(curIsPpsType ){
            if(loadingStatus){
                return <Loading />;
            }
            const message = () => {
                return (
                    <div className="depositPpsPreview" dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_PPS_PREVIEW') }}></div>
                );
            };
            return <FPSChargeTips props={{ backFn, continueFn:ppsPreviewNextFn,  message, hideTitle:true,continueBtnText:t('LB_BS_FT_CONFIRM'), backBtnText: t('LB_BETSLIP_PREVIEWBACK'), isConfirmBtn: true }} />;
        } else if (isChargeTipsStatus) {
            var finalChargeAmountFps = chargeAmtFps;
            if (nbaData?.config?.FPS?.currentMonthlyDepCnt - nbaData?.config?.FPS?.monthlyDepMaxCntNoCharge >= 0) {
                finalChargeAmountFps = nbaData?.config?.FPS?.monthlyDepMaxCntChargeAmt;
            }

            const depositPara = {
                type: 'deposit',
                chargeAmtFps: finalChargeAmountFps
            };
            return <FPSChargeTips props={{ backFn, continueFn,  depositPara }} />;
        } else {
            return (
                <>
                    <div className="deposit-prev">
                        <div className="deposit-prev-title">
                            <div>{t('LB_BS_FT_PREVIEW')}</div>
                            <div>{t('LB_BS_FT_PREVIEWDETAIL')}</div>
                        </div>
                        <div className="FT-form-content">
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_FROM')}:</div>
                                <div className="formItemContent">
                                    <div className="contentTop">{bankInfo?.bankInfo?.[langName]}</div>
                                    <div>{bankInfo?.bankAccountNoMasked}</div>
                                </div>
                            </div>
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_TO')}:</div>
                                <div className="formItemContent">
                                    <div className="contentTop">{t('LB_BS_FT_BETACCOUNT')}</div>
                                    <div>{loginAccount}</div>
                                </div>
                            </div>
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_TRANSACTIONMETHOD')}:</div>
                                <div className="formItemContent">
                                    <div className="contentTop">
                                        {transactionMethod === 'EPS'
                                            ? t('LB_BS_FT_EPS').replace('{0}', t('LB_BS_FT_DEPOSIT'))
                                            : t('LB_BS_FT_FPS').replace('{0}', t('LB_BS_FT_DEPOSIT'))}
                                    </div>
                                    {transactionMethod === 'EPS' && <EpscoPin onChange={onChangePin} />}
                                </div>
                            </div>
                            <div className="formItem">
                                <div className="formItemLabel">{t('LB_BS_FT_DEPOSITAMOUNT')}:</div>
                                <div className="formItemContent">
                                    <div>${textFormatAmountFn(depositAmount, 2)}</div>
                                </div>
                            </div>
                            {serviceChargeAmt > 0 && (
                                <div className="formItem">
                                    <div className="formItemLabel">{t('LB_BS_FT_SERVICECHARGE')}:</div>
                                    <div className="formItemContent">
                                        <div>${textFormatAmountFn(serviceChargeAmt, 2)}</div>
                                    </div>
                                </div>
                            )}
                            {transactionMethod === 'FPS' && (
                                <div className="deposit-nopin">{t('LB_BS_FT_FPSTIPS')}</div>
                            )}
                        </div>
                        <div className="deposit-btnArea prev-btnArea">
                            <div
                                className="deposit-btn backBtn"
                                onClick={() => {
                                    setCurStep(curStep - 1);
                                }}
                            >
                                {t('LB_BETSLIP_PREVIEWBACK')}
                            </div>
                            <div className="deposit-btn confirmBtn" onClick={confirmFn}>
                                {t('LB_BS_FT_CONFIRM')}
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    } else {
        return null;
    }
};

export default DepositPreview;
