import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getBalanceValue } from '../../BetSlipHooks';
import { textFormatAmountFn } from '../../../Common/GlobalFunc';
import { saveBetLinesToCurrentSessionRecords } from '../../../Common/CurrentSessionRecords';
import { formatServiceCharge } from '../utils';

const WithdrawalResult = (props) => {
    const {
        setCurStep,
        postWithdrawalData,
        setResultShowStatus,
        loginAccount,
        withdrawalParams,
        nbaData,
        updateBetSlipBalance,
        resetWithdrawalParams,
        goToLinkBAPage,
        setTab
    } = props;
    const { t, i18n } = useTranslation();
    const { formParams, amount } = withdrawalParams;
    const { nba1, config } = nbaData;
    const bankInfo = nba1?.bankInfo;
    const [balance, setBalance] = useState();
    const lang = i18n.language == 'en' ? 'name_en' : 'name_ch';
    const showBalance =
        postWithdrawalData.status === 'REJECTED' || postWithdrawalData.status === 'PENDING' ||
        (postWithdrawalData.method !== 'AUTOPAY' && ['SUCCESSFUL'].includes(postWithdrawalData.status));

    useEffect(() => {
        const getBalance = async () => {
            const resData = await getBalanceValue(true);

            if (resData?.balanceStatus == 0 && resData?.balance !== '') {
                let newBalance = textFormatAmountFn(resData.balance);
                setBalance(Number(resData.balance).toFixed(2));
                updateBetSlipBalance(newBalance);
            } else {
                setBalance(Number(sessionStorage.getItem('balance')));
            }
        };

        const refreshBetSlipBalanceOnly = async () => {
            if (postWithdrawalData.method == 'AUTOPAY' && ['SUCCESSFUL'].includes(postWithdrawalData.status)) {
                const resData = await getBalanceValue(true);
                if (resData.balanceStatus == 0 && resData?.balance !== '') {
                    let newBalance = textFormatAmountFn(resData.balance);
                    updateBetSlipBalance(newBalance);
                }
            }
        };

        if (showBalance) {
            getBalance();
        } else {
            refreshBetSlipBalanceOnly();
        }

        let status = postWithdrawalData.status;
        if (postWithdrawalData.method === 'AUTOPAY' && status === 'SUCCESSFUL') {
            status = 'AUTOPAYSUCCESSFUL';
        }
        setResultShowStatus(status);
        const errCode = postWithdrawalData.responseData?.code || postWithdrawalData.responseData?.errorCode;
        saveBetLinesToCurrentSessionRecords([
            {
                prod: 'FT',
                resultStatus: postWithdrawalData.status,
                transactionNumber: getRefNo(),
                transaction: 'Withdrawal',
                method: postWithdrawalData.method,
                amount: postWithdrawalData.status == "TIMEOUT" || postWithdrawalData.status == 'PENDING'? '---': getAmount(),
                charge: getChargeAmt(),
                msg_en: postWithdrawalData?.errMsg_en + (postWithdrawalData.method == 'FPS' ? ` (${errCode})` : ''),
                msg_ch: postWithdrawalData?.errMsg_ch + (postWithdrawalData.method == 'FPS' ? ` (${errCode})` : '')
            }
        ]);
    }, []);

    const doneFn = () => {
        setCurStep(0);
        setResultShowStatus('');
        resetWithdrawalParams();
    };

    const getFooterMsg = () => {
        let msg = '';
        if (postWithdrawalData.method === 'AUTOPAY' && postWithdrawalData.status === 'SUCCESSFUL') {
            msg = t('LB_BS_FT_WITHDRAWAL_AUTOPAY_SUCCESSFUL');
        }
        if (postWithdrawalData.status === 'TIMEOUT') {
            msg = t('LB_BS_FT_RESULTS_FRONTEND_TIMEOUT');
        }
        if (postWithdrawalData.status === 'PENDING') {
            msg = t(`LB_BS_FT_WITHDRAWAL_${postWithdrawalData.method}_PENDING`);
        }

        return msg;
    };

    const getRejectedMsg = () => {
        if (postWithdrawalData.status === 'REJECTED') {
            const errCode = postWithdrawalData.responseData?.code || postWithdrawalData.responseData?.errorCode;
            if (postWithdrawalData.method !== 'FPS') {
                return `${postWithdrawalData.errMsg}`;
            } else {
                return `${postWithdrawalData.errMsg} (${errCode})`;
            }
        }
    };

    const getRefNo = () => {
        const refNo = postWithdrawalData?.responseData?.transactionNumber || postWithdrawalData?.responseData?.number;

        return refNo > 0 ? refNo : undefined;
    };

    const getChargeAmt = () => {
        var chargeAmt = 0;
        if (postWithdrawalData.method == 'FPS') {
            if (window.globalConfig.IS_IB) {
                chargeAmt = postWithdrawalData?.responseData?.serviceChargeAmt;
            } else {
                chargeAmt = postWithdrawalData?.responseData?.serviceChargeAmt / 100;
            }
            if (chargeAmt > 0) {
                return textFormatAmountFn(chargeAmt, 2);
            }
            return false;
        }

        if (postWithdrawalData.method == 'EPS') {
            if (window.globalConfig.IS_IB) {
                chargeAmt = postWithdrawalData?.responseData?.epscoChargeAmountCent?.replace('$', '');
                if (chargeAmt > 0) {
                    return textFormatAmountFn(chargeAmt, 2);
                }
                return false;
            } else {
                chargeAmt = postWithdrawalData?.responseData?.epscoChargeAmountCent;
                if (chargeAmt > 0) {
                    return textFormatAmountFn(chargeAmt / 100, 2);
                }
                return false;
            }
        }
    };

    const goToLinkBank = () => {
        goToLinkBAPage(1);
    };

    const getRegisterMsg = () => {
        if (bankInfo.eDDADepositEnable != '1') return;

        let msg;
        if (!nba1.EPS.show && !nba1.FPS.show) {
            msg = (
                <>
                    {t('LB_BS_FT_WITHDRAWAL_REGISTER_MSG2')}
                    <span className="goToLinkBank" onClick={goToLinkBank}>
                        {' '}
                        {t('LB_BS_FT_WITHDRAWAL_REGISTER_LINKTEXT')}{' '}
                    </span>
                    {t('LB_BS_FT_WITHDRAWAL_REGISTER_MSG3')}
                </>
            );
        }
        if (nba1.EPS.show && !nba1.FPS.show) {
            msg = (
                <>
                    {t('LB_BS_FT_WITHDRAWAL_REGISTER_MSG1')}
                    <span className="goToLinkBank" onClick={goToLinkBank}>
                        {' '}
                        {t('LB_BS_FT_WITHDRAWAL_REGISTER_LINKTEXT')}{' '}
                    </span>
                    {t('LB_BS_FT_WITHDRAWAL_REGISTER_MSG3')}
                </>
            );
        }
        return msg;
    };

    const getAmount = () => {
        let resAmount = postWithdrawalData.responseData.amountCent || postWithdrawalData.responseData.transAmt;
        if (resAmount) {
            resAmount = resAmount / 100;
        }
        return textFormatAmountFn(resAmount || amount, 2);
    };

    return (
        <div className="deposit-prev ">
            <div className="deposit-prev-title fontW-b">
                <div>{t('LB_BS_FT_SUMMARY')}</div>
            </div>
            <div className="FT-form-content">
                <div className="formItem">
                    <div className="formItemLabel">{t('LB_BS_FT_FROM')}:</div>
                    <div className="formItemContent">
                        <div>{t('LB_BS_FT_BETACCOUNT')}</div>
                        <div>{loginAccount}</div>
                    </div>
                </div>
                <div className="formItem">
                    <div className="formItemLabel">{t('LB_BS_FT_TO')}:</div>
                    <div className="formItemContent">
                        <div>{bankInfo[lang]}</div>
                        <div>{nba1.bankAccountNoMasked}</div>
                    </div>
                </div>
                <div className="formItem">
                    <div className="formItemLabel">{t('LB_BS_FT_TRANSACTIONMETHOD')}:</div>
                    <div className="formItemContent">
                        <div>{postWithdrawalData.methodName}</div>
                    </div>
                </div>
                <div className="formItem">
                    <div className="formItemLabel">{t('LB_BS_FT_WITHDRAWALAMOUNT')}:</div>
                    <div className="formItemContent">
                        <div>${getAmount()}</div>
                    </div>
                </div>
                {getRejectedMsg() && (
                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_LINK_RJ_REASON')}</div>

                        {window.globalConfig.IS_IB ? (
                            <div
                                className="formItemContent"
                                dangerouslySetInnerHTML={{ __html: getRejectedMsg() }}
                            ></div>
                        ) : (
                            <div className="formItemContent">
                                <div>{getRejectedMsg()}</div>
                            </div>
                        )}
                    </div>
                )}
                {['SUCCESSFUL', 'PENDING'].includes(postWithdrawalData.status) && getChargeAmt() && (
                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_SERVICECHARGE')}:</div>
                        <div className={`formItemContent ${postWithdrawalData.method === 'EPS' ? 'contentBlue' : ''}`}>
                            <div>${getChargeAmt()}</div>
                        </div>
                    </div>
                )}
                {['SUCCESSFUL'].includes(postWithdrawalData.status) && getRefNo() && (
                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_REF_NO')}:</div>
                        <div className="formItemContent">
                            <div>{getRefNo()}</div>
                        </div>
                    </div>
                )}
                {showBalance && balance != null && (
                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_AC_BALANCE')}:</div>
                        <div className="formItemContent">
                            <div>${textFormatAmountFn(balance || 0, 2)}</div>
                        </div>
                    </div>
                )}
                {getFooterMsg() && <div className="deposit-nopin">{getFooterMsg()}</div>}
            </div>
            {getRegisterMsg() && <div className="deposit-registerFPS-msg">{getRegisterMsg()}</div>}
            <div className="deposit-btnArea">
                <div className="deposit-btn doneBtn" onClick={doneFn}>
                    {t('LB_BETSLIP_DONE')}
                </div>
            </div>
        </div>
    );
};

export default WithdrawalResult;
