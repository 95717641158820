import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RrawResultContext } from '../Page/CheckDrawResult';
import CheckBox from '../../Common/ui-components/CheckBox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { MyFavouritesContext } from './MyFavourites';

export const CheckResultMyFav = () => {
    const { t } = useTranslation();
    const [showTable, setShowTable] = useState(true);
    const { myFavourites, updateMyFavourites, setFavInitCheckState, favInitCheckState } = useContext(MyFavouritesContext);
    const { checkRrawNumber, setCheckRrawNumber } = useContext(RrawResultContext);
    const [MyFavData, setMyFavData] = useState([]);
    const scSize = document.documentElement.clientWidth;

    useEffect(() => {
        if (myFavourites) {
            let newData = [...MyFavData];
            myFavourites.forEach((item, index) => {
                if (!newData[index])
                    newData.push({
                        ...item,
                        checked: false,
                        multDraw: 1
                    });
            });

            if (favInitCheckState) {
                newData = newData.map(item => {
                    return { ...item, checked: false };
                })
            }
            setMyFavData([...newData]);
        }
    }, [myFavourites, favInitCheckState]);

    useEffect(() => {
        if (MyFavData.length > 0 && !showTable) {
            setShowTable(true);
        }
    }, [MyFavData]);

    const handleClickCheckbox = (index) => {
        let newData = [...MyFavData];
        newData = newData.map((item, eq) => {
            return {
                ...item,
                checked: index == eq ? !item.checked : false
            };
        })
        setCheckRrawNumber({ ...newData[index] });
        setMyFavData(newData)
    };

    const tableProps = { MyFavData, showTable, scSize, handleClickCheckbox, t };

    return (
        <div className="my-favourites-table-container">
            <div className="my-favourites-header df">
                <div className="">
                    <ArrowIcon
                        className="hueRotate msFavouIcon"
                        onClick={() => setShowTable(!showTable)}
                        direction="down"
                        change={showTable}
                        changeDirection="up"
                        size={20}
                    />
                </div>
                <span>{t('LB_M6_SINGLE_TABLE_MYFAV')}</span>
            </div>
            <div className={`${showTable ? 'my-favourites-show' : 'my-favourites-hide'}`}>
                <Table {...tableProps} />
            </div>
        </div>
    );
};

const Table = (props) => {
    const { MyFavData, showTable } = props;
    const table = [];

    return (
        <div className={`my-favourites-table ${showTable ? 'my-favourites-show' : 'my-favourites-hide'}`}>
            <TableHeader {...props} />
            {MyFavData.map((favItem, index) => {
                return (
                    <FavRow
                        {...props}
                        favData={favItem}
                        key={index}
                        index={index}
                        className={index % 2 ? 'sec' : ''}
                        {...props}
                    />
                );
            })}
        </div>
    );
};

const FavRow = ({ favData, index, className, scSize, handleClickCheckbox, t }) => {
    const imgSize = scSize > 1023 ? 'm' : 's';
    return (
        <div className={`table-row ${className}`}>
            <div className="table-cell table-cell-checkbox">
                <div>
                    <CheckBox
                        product="marksix"
                        type="checkbox"
                        checked={favData.checked}
                        onChange={() => handleClickCheckbox(index)}
                    />
                </div>
            </div>
            <div className="table-cell">
                <div className="df table-cell-ball-box">
                    {favData?.bankerList && (
                        <div className="df">
                            <div className="banker-label">{t('LB_M6_BANKERLABEL')}:</div>
                            <div className="df">
                                {favData?.bankerList?.map((i, ind) => {
                                    return (
                                        <div className="ball-img-box" key={ind}>
                                            <img
                                                className={`ballsize-${imgSize}`}
                                                src={
                                                    i &&
                                                    require(`../../../info/marksix/info/images/marksix-gold-${i}.svg`)
                                                }
                                                alt={i}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="df">
                        {favData?.bankerList && <div className="banker-label">{t('LB_M6_LEGLABEL')}:</div>}
                        <div className="df ball-list-container">
                            {favData.numList.map((i, ind) => {
                                return (
                                    <div className="ball-img-box" key={ind}>
                                        <img
                                            className={`ballsize-${imgSize}`}
                                            src={
                                                i &&
                                                require(`../../../info/marksix/info/images/marksix-${i}.svg`)
                                            }
                                            alt={i}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TableHeader = ({ MyFavData, t }) => {
    return (
        <div className="table-header">
            <div className="table-cell table-checkbox-cell">{MyFavData.length > 0 ? t('LB_M6_CHECK_UNIT') : ''}</div>
            <div className="table-cell"></div>
        </div>
    );
};

