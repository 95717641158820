import { getOddsValue } from "./common";

export const parseWPQBanker = (pmPool, message) => {
    if(message != null){
        if(message.updAt != null && message.cmb !=null){
            pmPool.lastUpdateTime = message.updAt;
            let banker10 = message.cmb.filter(x=>x.odds != null).sort(bankerCompare);
            let tmpOddsNodes = [];
            if(banker10.length<=0){  // ReservedOrRefunded status
                banker10 = message.cmb.filter(x=>x.scrOrd != null).sort(orderCompare).slice(0,20);
            }
            for(let i =1 ;i<pmPool.oddsNodes.length+1;i++){
                let newOddsNodes = {
                    bankerOdds: [],
                    combString: i
                };
                tmpOddsNodes.push(newOddsNodes)
            }
            
            banker10.forEach(comb => {
                let currentNodeVal = tmpOddsNodes.findIndex(x=> x.combString==parseInt(comb.cmbStr.slice(0,2)));
                if(currentNodeVal>=0){
                    tmpOddsNodes[currentNodeVal].bankerOdds.push(comb);
                }
                currentNodeVal = tmpOddsNodes.findIndex(x=> x.combString==parseInt(comb.cmbStr.slice(3,5)));
                if(currentNodeVal>=0){
                    tmpOddsNodes[currentNodeVal].bankerOdds.push(comb);
                }
            });

            pmPool.oddsNodes.forEach(oddNode =>{
                let currentNodeVal = tmpOddsNodes.findIndex(x=> x.combString== oddNode.combString);
                if(currentNodeVal>=0){
                    let topTenBankerOddds = tmpOddsNodes[currentNodeVal].bankerOdds.sort(oddsCompare).slice(0,10).sort(bankerCompare);
                    const newBankerOdds = topTenBankerOddds.map((item) => ({
                        combString: item.cmbStr,
                        oddsValue: item.odds,
                      }));
                    oddNode.bankerOdds = newBankerOdds;
                }
            });
        }
    }
}
function bankerCompare ( a, b ){
    if( a.cmbStr< b.cmbStr ){
        return -1;
    }
    if( a.cmbStr > b.cmbStr ){
        return 1;
    }
    if ( parseFloat(a.odds) < parseFloat(b.odds)){
        return -1;
    }
    if ( parseFloat(a.odds) > parseFloat(b.odds)){
        return 1;
    }
    return 0;
}
function oddsCompare (a,b){
    if ( parseFloat(a.odds) < parseFloat(b.odds)){
        return -1;
    }
    if ( parseFloat(a.odds) > parseFloat(b.odds)){
        return 1;
    }
    return 0;
}
function orderCompare ( a, b ){
    if( parseFloat(a.scrOrd)< parseFloat(b.scrOrd) ){
        return -1;
    }
    if( parseFloat(a.scrOrd) > parseFloat(b.scrOrd) ){
        return 1;
    }
    return 0;
}