import React, { useContext } from 'react';
import { RacingContext } from '../../Home/RacingPage';

import RaceCard from '../Page/RaceCard';
import Results from '../Page/Results';
import OddsWP from '../Page/OddsWP';
import OddsWPQ from '../Page/OddsWPQ';
import OddsFCT from '../Page/OddsFCT';
import OddsIWN from '../Page/OddsIWN';
import OddsTCE from '../Page/OddsTCE';
import OddsTRI from '../Page/OddsTRI';
import OddsQTT from '../Page/OddsQTT';
import OddsFF from '../Page/OddsFF';

import OddsCWA from '../Page/OddsCWA';
import OddsCWB from '../Page/OddsCWB';
import OddsCWC from '../Page/OddsCWC';
import CWAAllAlup from '../Page/CWAAllAlup';

import OddsFOBase from '../Page/OddsFOBase';

import OddsDBL from '../Page/OddsDBL';
import OddsTBL from '../Page/OddsTBL';
import Odds6UP from '../Page/Odds6UP';
import OddsDT from '../Page/OddsDT';
import OddsTT from '../Page/OddsTT';
import CrossAlup from '../Page/CrossAlup';

import PWin from '../Page/PWin';
import JTCombo from '../Page/JTCombo';
import Turnover from '../Page/Turnover';

const RedirectPage = ({ oddsTNCInfo, oddsJKCInfo, handleJTCCheckbox }) => {
    const context = useContext(RacingContext);

    if (context.content.page == null) {
        return <div></div>;
    }

    switch (context.content.page) {
        case "WP":
            return <OddsWP />;
        case "WPQ":
            return <OddsWPQ />;
        case "CWA":
            return <OddsCWA />;
        case "CWB":
            return <OddsCWB />;
        case "CWC":
            return <OddsCWC />;
        case "CWAALLALUP":
            return <CWAAllAlup />;
        case "FCT":
            return <OddsFCT />;
        case "IWN":
            return <OddsIWN />;
        case "TCE":
            return <OddsTCE />;
        case "TRI":
            return <OddsTRI />;
        case "QTT":
            return <OddsQTT />;
        case "FF":
            return <OddsFF />;
        case "DBL":
            return <OddsDBL />;
        case "TBL":
            return <OddsTBL />;
        case "DT":
            return <OddsDT />;
        case "TT":
            return <OddsTT />;
        case "6UP":
            return <Odds6UP />;
        case "PWIN":
            return <PWin />;
        case "JTCOMBO":
            return <JTCombo handleJTCCheckbox={handleJTCCheckbox} />;
        case "TURNOVER":
            return <Turnover />;
        case "TNC":
        case "JKC":
            return <OddsFOBase />;
        case "RESULTS":
        case "HOME_RESULTS":
            return <Results />;
        case "CROSS_ALUP":
        case "WPALUP":
        case "CWAALUP":
        case "WPQALUP":
        case "FCTALUP":
        case "TRIALUP":
            return <CrossAlup />;
        default:
            return <RaceCard />;
    }

}
export default RedirectPage;