import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useProductName from '../Common/hooks/useProductName';
import { GlobalContext } from '../../contexts/GlobalContext';

const Copyright = () => {
    const { t } = useTranslation();
    const productName = useProductName();
    const globalContext = useContext(GlobalContext);
    const serverTime = globalContext?.sessionServerTimeStamp || (new Date()).getTime();
    const getThisYear = new Date(serverTime).getFullYear();
    return (
        <div className={window.globalConfig.IS_EWIN2 ? "ewinCopyRight" : "copyRight"}>
            <a rel="noopener noreferrer" href={t('URL_COPYRIGHT')} target="_blank">{t('LB_COPYRIGHT')}</a>&nbsp;© 2006-{getThisYear} {name} {t('LB_HKJC_' + productName.toUpperCase())}
        </div>
    )
}
export default Copyright;