import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TandCLink, postOtpCodeGenerate } from '../FundsTransferAPI';
import { postOtpCodeGenerate_IB } from '../FundsTransferIBAPI';
import { useBetSlipMsgQuery } from '../../../Home/Data/SitecoreDataHooks';
import { getSessionId } from '../../BetSlipAPI';
import { maskPhoneNumber } from '../utils';

const LinkTC = ({ props }) => {
    const { t, i18n } = useTranslation();
    const {
        nbaData,
        curStep,
        setCurStep,
        selectedItem,
        setSelectedItem,
        resultShowStatus,
        setResultShowStatus,
        otpConfirmData,
        setOtpConfirmData,
        ssoExpired,
        isReturnErrorCode,
        setLoadingStatus
    } = props;
    const [isAcceptTC, setIsAcceptTC] = useState(false);
    const { msgLoading, getLinkBankTnC } = useBetSlipMsgQuery();
    const [textData, setTextData] = useState('');

    useEffect(() => {
        if (!bankAcc || bankAcc.length <= 0) {
            setCurStep(0);
        }
    },[]);

    useEffect(() => {
        if (!msgLoading) {
            let tmpData = getLinkBankTnC();
            if (tmpData === '') {
                setIsAcceptTC(true);
            } else {
                setTextData(tmpData);
            }
        }
    }, [msgLoading]);

    const fullName = () => {
        return window.globalConfig.IS_IB
            ? sessionStorage.getItem('acc_name')
            : sessionStorage.getItem('login_firstNameEn') + ' ' + sessionStorage.getItem('login_lastNameEn');
    };

    const bankName = () => {
        if (i18n.language === 'en') {
            return nbaData.link[selectedItem.nba].bankInfo.name_en;
        } else {
            return nbaData.link[selectedItem.nba].bankInfo.name_ch;
        }
    };

    const bankAcc = nbaData.link[selectedItem.nba].bankAccountNoMasked;
    const mobileNo = window.globalConfig.IS_IB
        ? sessionStorage.getItem('mobile_country')
        ? `+${sessionStorage.getItem('mobile_country')}-${maskPhoneNumber(sessionStorage.getItem('mobile_num'))}`
        : t('LB_BS_FT_LINK_NO_MOBILE')
        : sessionStorage.getItem('login_maskedMobilePhoneNumber')
        ? sessionStorage.getItem('login_maskedMobilePhoneNumber')
        : t('LB_BS_FT_LINK_NO_MOBILE');

    const onChangeChkTandC = (event) => {
        setIsAcceptTC(!isAcceptTC);
    };

    const onClickNext = async () => {
        setLoadingStatus(true);
        if (isAcceptTC) {
            const result = await sendOTP();
            if (
                window.globalConfig.IS_IB == false &&
                result?.authId &&
                result?.callbacks
                    .find((item) => item.type == 'HiddenValueCallback')
                    ?.input[0]?.value?.includes('createAt')
            ) {
                setOtpConfirmData(result);
                setCurStep(2);
            } else if (window.globalConfig.IS_IB == true) {
                const resultCode = result.DoOTPCodeGenerateTRResult.find((e) => e.Key == 'result')?.Value;
                if (resultCode == '0') {
                    setOtpConfirmData(result);
                    setCurStep(2);
                } else if (resultCode == '487') {
                    setResultShowStatus('UNSUCCESSFUL');
                } else {
                    if (isReturnErrorCode(resultCode)) {
                        setLoadingStatus(false);
                        return;
                    }
                }
            } else {
                setResultShowStatus('UNSUCCESSFUL');
            }
        }
        setLoadingStatus(false);
    };

    const sendOTP = async () => {
        const result = window.globalConfig.IS_IB
            ? await postOtpCodeGenerate_IB(i18n.language, nbaData.link.config.timeout)
            : await postOtpCodeGenerate();
        if (sessionStorage.getItem('eft_status') == '-2') {
            ssoExpired();
        }
        return result;
    };

    const onClickDone = () => {
        setSelectedItem({});
        setResultShowStatus('');
        setCurStep(0);
    };

    return (
        <div>
            {resultShowStatus == 'UNSUCCESSFUL' ? (
                <>
                    <div className="FT-form-content">
                        <div
                            className="link-summary"
                            dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_LINK_OTP_MOBIL_FAIL') }}
                        />
                    </div>
                    <div className="deposit-btnArea link-bottom">
                        <div className="deposit-btn doneBtn link-btn-active" onClick={onClickDone}>
                            {t('LB_BETSLIP_DONE')}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="FT-form-content">
                        <div className="link-row">
                            <div className="link-l">{t('LB_BS_FT_LINK_FULLNAME')}:</div>
                            <div className="link-r">{fullName()}</div>
                        </div>
                        <div className="link-row">
                            <div className="link-l">{t('LB_BS_FT_LINK_BANKNAME')}:</div>
                            <div className="link-r">{bankName()}</div>
                        </div>
                        <div className="link-row">
                            <div className="link-l">{t('LB_BS_FT_LINK_BANKACC')}:</div>
                            <div className="link-r">{bankAcc}</div>
                        </div>
                        <div className="link-row">
                            <div className="link-l">{t('LB_BS_FT_LINK_MOBILE')}:</div>
                            <div className="link-r">{mobileNo}</div>
                        </div>
                        {
                            <>
                                <div className="link-row noline">
                                    <div className="link-l">{t('LB_BS_FT_LINK_TC')}:</div>
                                    <div className="link-r"></div>
                                </div>
                                <TandCLink textData={textData} />
                                <div className="link-row noline">
                                    <input
                                        id="chkTandC"
                                        type="checkbox"
                                        checked={isAcceptTC}
                                        onChange={onChangeChkTandC}
                                    ></input>
                                    &nbsp;
                                    <label for="chkTandC">{t('LB_BS_FT_LINK_TC_CHKBOX')}</label>
                                </div>
                            </>
                        }
                    </div>
                    <div className="deposit-btnArea">
                        <div
                            className={isAcceptTC ? 'deposit-btn link-btn-active' : 'deposit-btn link-btn-nonActive'}
                            onClick={() => isAcceptTC && onClickNext()}
                        >
                            {t('LB_BS_FT_NEXT')}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default LinkTC;
