import React, { useState, useEffect, useContext } from 'react';
import BallPanelTable from '../Common/BallPanelTable';
import { useTranslation } from 'react-i18next';
import AmountDisplayTable, { MobileAmountDisplay } from './AmountDisplayTable';
import { isStartSell } from './Common';
import { setMyFavCookie, MyFavouritesContext } from './MyFavourites';
import { MarksixContext } from '../../Home/MarksixPage';
import { useWindowSize } from '../../Common/CommonHooks';
import { isPmuEoD, eodMessage } from '../../Common/GlobalFunc';

const MARKSIX_SINGLE_MAXLINE = 10;

const SingleSelectedNumTable = () => {
    const { t } = useTranslation();
    const context = useContext(MarksixContext);
    const {ewinAtM6Obj} = context;
    const { initDefaultUnitBet } = ewinAtM6Obj;
    const isSell = isStartSell(context.nextDraw);
    const { isMobileSize } = useWindowSize();
    const [selectedTableData, setSelectedTableData] = useState([{ numList: [], isFav: false, multidraw: 1, unitBet: initDefaultUnitBet }]);
    const [activeItem, setActivItem] = useState(0);
    const scSize = document.documentElement.clientWidth;
    const { updateMyFavourites } = useContext(MyFavouritesContext);
    const [multidraw, setMultidraw] = useState(1);

    const onChangeBall = (ballList, ballNum, activeIndex, random = '') => {
        let isMax = true;
        for (let i = 0; i < selectedTableData.length; i++) {
            if (selectedTableData[i].numList.length < 6) {
                isMax = false;
                break;
            }
        }
        if (isMax) return alert(t('LB_M6_MSG_SLOTS_ARE_FILLED').replace('{0}', MARKSIX_SINGLE_MAXLINE));
        let newTableData = JSON.parse(JSON.stringify(selectedTableData));
        const actIndx = activeIndex != null ? activeIndex : activeItem;

        if (newTableData[actIndx].numList.length !== 6 && ballList.length === 6) {
            newTableData[actIndx].random = !!random;
        } else if (newTableData[actIndx].numList.length !== 6) {
            newTableData[actIndx].random = false;
        }
        newTableData[actIndx].numList = ballList;
        if (newTableData[actIndx].numList.length === 6) {
            if (!newTableData[actIndx + 1] && actIndx <= MARKSIX_SINGLE_MAXLINE - 2) {
                newTableData[actIndx + 1] = { numList: [], isFav: false, multidraw, unitBet: initDefaultUnitBet };
                setActivItem(actIndx + 1);
            } else {
                for (let i = 0; i < newTableData.length; i++) {
                    if (newTableData[i].numList.length < 6) {
                        ballNum && newTableData[i].numList.push(ballNum);
                        setActivItem(i);
                        break;
                    }
                }
            }
        }else if(newTableData[actIndx].numList.length == 0 && actIndx != newTableData.length -1){
            let curTableData = JSON.parse(JSON.stringify(newTableData));
            let filterTableData = curTableData.filter((item, index) => index != actIndx);
            if(filterTableData.length == (MARKSIX_SINGLE_MAXLINE -1) && newTableData[newTableData.length -1].numList.length > 0){
                filterTableData.push({ numList: [], isFav: false, multidraw, unitBet: initDefaultUnitBet })
            }
            if(filterTableData.length > 0 ){
                newTableData = filterTableData
            }
        }
        setSelectedTableData(newTableData);
    };

    const onChangeDraw = (num) => {
        const newTableData = JSON.parse(JSON.stringify(selectedTableData));
        setMultidraw(num);
        const newArr = newTableData.map((item) => {
            return { ...item, multidraw: num };
        });
        setSelectedTableData(newArr);
    };

    const randomBall = (itemIndex) => {
        setActivItem(itemIndex);
        if (selectedTableData[itemIndex].numList.length >= 6) {
            return alert(t('LB_M6_MSG_SELECT_NUMBERS_LINE_MAX'));
        }
        const ball = [...selectedTableData[itemIndex].numList];
        const isRandom=ball.length==0;
        while (ball.length < 6) {
            const rand = Math.ceil(Math.random() * 49);
            if (!ball.includes(rand)) ball.push(rand);
        }
        const sortBall = ball.sort((a, b) => a - b);
        onChangeBall(sortBall, null, itemIndex, isRandom);
    };

    const clearBall = (itemIndex) => {
        if (selectedTableData[itemIndex].numList.length === 0) return;
        setActivItem(0);
        const newData = JSON.parse(JSON.stringify(selectedTableData));
        if (newData[newData.length - 1].numList.length === 6 || newData.length - 1 === itemIndex) {
            newData.push({ numList: [], isFav: false, multidraw,unitBet: initDefaultUnitBet });
        }
        newData.splice(itemIndex, 1);
        setActivItem(newData.length - 1);
        setSelectedTableData([...newData]);
    };

    const clearAllNumber = () => {
        setSelectedTableData([{ numList: [], isFav: false, multidraw,unitBet: initDefaultUnitBet }]);
        setActivItem(0);
    };

    const handleClickSelectedBall = (num, index) => {
        setActivItem(index);
        let curTableData = JSON.parse(JSON.stringify(selectedTableData));
        curTableData[index].numList = curTableData[index].numList.filter((item) => item !== num);
        curTableData[index].isFav = false;
        curTableData[index].multidraw = multidraw;
        if(curTableData[index].numList.length == 0 && index != curTableData.length -1){
            let filterTableData = curTableData.filter((item, idx) => index != idx);
            if(filterTableData.length == (MARKSIX_SINGLE_MAXLINE -1) && curTableData[curTableData.length -1].numList.length > 0){
                filterTableData.push({ numList: [], isFav: false, multidraw,unitBet: initDefaultUnitBet })
            }
            if(filterTableData.length > 0 ){
                curTableData = filterTableData
            }
        }
        setSelectedTableData(curTableData);
    };

    const handleClickFavIcon = (index) => {
        const newList = [...selectedTableData];
        if (newList[index].numList.length >= 6) {
            if (newList[index].isFav) {
                newList[index].isFav = !newList[index].isFav;
                setSelectedTableData(newList);
            } else {
                if (!isSell) {
                    if (isPmuEoD() && eodMessage()?.length > 0) {
                        return window.alert(eodMessage());
                    } else {
                        return window.alert(t('LB_M6_MSG_NOT_DIFINED'));
                    }
                }

                if (
                    newList[index]?.bankerList?.length > 0 &&
                    newList[index].bankerList.length + newList[index].numList.length < 7
                ) {
                    return window.alert(t('LB_M6_MSG_INSU_SELECTIONS'));
                }
                const fav = newList.filter((item, idx) => !item.isFav && idx === index);
                for (let i = 0; i < newList.length; i++) {
                    if (newList[i].numList.length >= 49) {
                        return window.alert(t('LB_M6_MSG_SELECT_NUMBERS_MULT_49'));
                    }
                }
                if (fav.length > 0) {
                    const favMax = setMyFavCookie(fav, t('LB_M6_MSG_MYFAV_MAX'));
                    if (favMax) return;
                    window.alert(t('LB_M6_MSG_MYFAV'));
                    newList[index].isFav = true;
                    setSelectedTableData(newList);
                    updateMyFavourites();
                    if(isMobileSize) window.location.hash = "#SelectTable";
                    else document.querySelector('.StandardPage-scrollbars > div:first-child').scrollTop = 5000
                    //   clearBall(index);
                }
            }

            return;
        }
        alert(t('LB_M6_MSG_INSU_SELECTIONS'));
    };

    const selectTableProps = {
        t,
        scSize,
        setActivItem,
        activeItem,
        handleClickSelectedBall,
        randomBall,
        selectedTableData,
        setSelectedTableData,
        clearBall,
        handleClickFavIcon
    };

    return (
        <div className="marksix-single-middle">
            {isMobileSize ? (
                <MobileAmountDisplay
                    selectedData={selectedTableData}
                    onChangeDraw={onChangeDraw}
                    tableType="single"
                    activeType={'SINGLE'}
                    // unitBet={initDefaultUnitBet}
                    clear={clearAllNumber}
                    className="amount-diplay-m"
                />
            ) : null}
            <BallPanelTable
                onChangeBall={onChangeBall}
                size={6}
                curBallList={selectedTableData[activeItem]}
                type="single"
                selectedData={selectedTableData}
                clear={clearAllNumber}
            />
            <SelectTable {...selectTableProps} />
            <AmountDisplayTable
                activeType={'SINGLE'}
                // unitBet={initDefaultUnitBet}
                selectedData={selectedTableData}
                onChangeDraw={onChangeDraw}
                tableType="single"
                clear={clearAllNumber}
                className="amount-diplay-d"
            />
        </div>
    );
};

export default SingleSelectedNumTable;

const SelectTable = (props) => {
    let key = 1;
    const table = props?.selectedTableData.map((item, index) => {
        return <TableRow ballData={item} index={index} key={key++} className={index % 2 ? '' : 'sec'} {...props} />;
    });
    return (
        <div className="single-select-table" id="SelectTable">
            <TableHeader {...props} />
            {table}
        </div>
    );
};

const TableHeader = ({ scSize, t }) => {
    return (
        <div className="table-row table-row-header">
            <div className="table-cell table-cell-fav">
                {scSize > 1023 ? t('LB_M6_SINGLE_TABLE_FAV') : t('LB_M6_SINGLE_TABLE_FAV_M')}
            </div>
            <div className="table-cell table-cell-number">
                <div>{t('LB_M6_SINGLE_TABLE_NUMBER')}</div>
            </div>
            <div className="table-cell"></div>
        </div>
    );
};

const TableRow = ({
    ballData,
    index,
    t,
    scSize,
    setActivItem,
    activeItem,
    handleClickSelectedBall,
    setSelectedTableData,
    selectedTableData,
    randomBall,
    clearBall,
    handleClickFavIcon
}) => {
    const imgSize = scSize > 1023 ? 'm' : 's';
    const {ewinAtM6Obj} = useContext(MarksixContext);
    const { singleShowUnitBet, initDefaultUnitBet, initPartialUnitBet } = ewinAtM6Obj;

    useEffect(() => {
        if(ballData.numList.length===0){
            updateBallUnitBet(initDefaultUnitBet)
        }
    }, [ballData.numList])

    const handleClickBet = (betNum) => {
        updateBallUnitBet(betNum)
    }
    const resetBall = (index) => {
        clearBall(index);
    }
    const updateBallUnitBet = (num) =>{
        selectedTableData[index].unitBet=num;
        setSelectedTableData([...selectedTableData])
    }

    return (
        <div className="table-row" onClick={() => setActivItem(index)}>
            <div className="table-cell">
                <div className="table-cell-fav-box pr">
                    {activeItem === index && <div className="table-row-arrow"></div>}
                    <div
                        title={t('LB_M6_SINGLE_TABLE_FAV')}
                        className={ballData.isFav ? 'marksix-fav-active' : 'marksix-fav'}
                        onClick={() => handleClickFavIcon(index)}
                    ></div>
                </div>
            </div>
            <div className="table-cell">
                <div className="df table-cell-ball-box">
                    {ballData.numList.map((i, ind) => {
                        return (
                            <div className="ball-img-box" onClick={() => handleClickSelectedBall(i, index)} key={ind}>
                                <img
                                    className={`ballsize-${imgSize}`}
                                    src={i && require(`../../../info/marksix/info/images/marksix-${i}.svg`)}
                                    alt={i}
                                />
                            </div>
                        );
                    })}
                </div>
                {singleShowUnitBet ?
                    <div className={`df table-cell-bet-box ${ballData.numList.length > 0 ? 'bet-box-show' : 'bet-box-hide'}`}>
                    <label  className="radio-container" onClick={ ()=> handleClickBet(initPartialUnitBet)}>
                        {t('LB_M6_MULT_PARBET').replace('{0}', initPartialUnitBet)}
                        <input type="radio" className="invCalFlexiBet-betline" onChange={()=>handleClickBet(initPartialUnitBet)}  checked={ballData.unitBet === initPartialUnitBet} />
                        <span className="radio-checkmark"></span>
                    </label>
                    <label  className="radio-container" onClick={() => handleClickBet(initDefaultUnitBet)}>
                        {t('LB_M6_MULT_UNITBET').replace('{0}', initDefaultUnitBet)}
                        <input type="radio" className="invCalFlexiBet-betline" onChange={()=>handleClickBet(initDefaultUnitBet)}   checked={ballData.unitBet === initDefaultUnitBet} />
                        <span className="radio-checkmark"></span>
                    </label>
                    </div>
                :null}
            </div>
            <div className="table-cell table-cell-ctrl-box">
                <div className="df ">
                    <div
                        role='button' tabIndex='0'
                        className="marksix-download-icon"
                        title={t('LB_M6_QUICKPICK_BTN_TITLE')}
                        onClick={() => randomBall(index)}
                    ></div>
                    <div
                        role='button' tabIndex='0'
                        className="marksix-delete-icon"
                        title={t('LB_M6_DELETE_BTN_TITLE')}
                        onClick={() => resetBall(index)}
                    ></div>
                </div>
            </div>
        </div>
    );
};
