export const topMenuData = {
	en: {
        racing: {
            "data": {
                "item": {
                    "children": [{
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Entrylist",
                        "itemName": {
                            "value": "Entrylist"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "https://racing.hkjc.com/racing/information/English/Racing/Entries.aspx"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": "To_JCRW"
                        },
                        "GlassboxCustomEventValue": {
                            "value": "Entrylist"
                        },
                        "GlassboxTaggingEnabled": {
                            "value": "1"
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Race Card",
                        "itemName": {
                            "value": "Race Card"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "//racing.hkjc.com/racing/info/meeting/Racecard/english/Local"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": "To_JCRW"
                        },
                        "GlassboxCustomEventValue": {
                            "value": "Race_Card"
                        },
                        "GlassboxTaggingEnabled": {
                            "value": "1"
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Fixture",
                        "itemName": {
                            "value": "Fixture"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "https://racing.hkjc.com/racing/information/English/Racing/Fixture.aspx"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": "To_JCRW"
                        },
                        "GlassboxCustomEventValue": {
                            "value": "Fixture"
                        },
                        "GlassboxTaggingEnabled": {
                            "value": "1"
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Changes",
                        "itemName": {
                            "value": "Changes"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "//racing.hkjc.com/racing/Info/meeting/Changes/english/Local/"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": "To_JCRW"
                        },
                        "GlassboxCustomEventValue": {
                            "value": "Changes"
                        },
                        "GlassboxTaggingEnabled": {
                            "value": "1"
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Jockey Challenge Info",
                        "itemName": {
                            "value": "Jockey Challenge Info"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_JKC Standings",
                            "itemName": {
                                "value": "JKC Standings"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/JKC/JKCResult.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "JKC_Standings"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Jockey Challenge Odds Chart",
                            "itemName": {
                                "value": "Jockey Challenge Odds Chart"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/jkc-odds-chart.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Jockey_Challenge_Odds_Chart"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Jockey Challenge Statistics",
                            "itemName": {
                                "value": "Jockey Challenge Statistics"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/jkc/JkcStat.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Jockey_Challenge_Statistics"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Jockeys Rides",
                            "itemName": {
                                "value": "Jockeys' Rides"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/JockeysRides.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Jockeys_Rides"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_JKC Introduction",
                            "itemName": {
                                "value": "JKC Introduction"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://special.hkjc.com/racing/info/en/betting/guide_qualifications_fixed.asp"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_RESULTS",
                        "itemName": {
                            "value": "Results & Dividend"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Current Raceday Results and Dividend",
                            "itemName": {
                                "value": "Current Raceday Results & Dividend"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "results"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_All Results and Dividend",
                            "itemName": {
                                "value": "All Results & Dividend"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/English/racing/LocalResults.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Local_Results"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Rebate Calculator",
                            "itemName": {
                                "value": "Rebate Calculator"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "/en/racing/rbc"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "920"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Tools",
                        "itemName": {
                            "value": "Tools"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_AUTO_RACING_NOTES",
                            "itemName": {
                                "value": "Racing Notes"
                            },
                            "itemRedirection": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Raceday Focus",
                            "itemName": {
                                "value": "Raceday Focus"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/rdf/raceday_focus.asp"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Raceday_Focus"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Intro to New Horses",
                            "itemName": {
                                "value": "Intro to New Horses"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/newhorse.asp"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Intro_to_New_Horses"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Last Run Reminder",
                            "itemName": {
                                "value": "Last Run Reminder"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/racing-info/last-run-reminder.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Last_Run_Reminder"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Stats Centre",
                            "itemName": {
                                "value": "Stats Centre"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/english/stats-centre/index.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Stats_Centre"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Tips Index",
                            "itemName": {
                                "value": "Tips Index"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/English/tipsindex/tips_index.asp"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Tips_Index"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_SpeedPRO",
                            "itemName": {
                                "value": "SpeedPRO"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/speedpro/english/formguide/formguide.html?race=1"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "SpeedPRO"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Jockey or Trainer Combo and Debutants Performance",
                            "itemName": {
                                "value": "Jockey/Trainer Combo & Debutants"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://campaign.hkjc.com/en/racing/jtcombo_debutants_statistics.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Horse Movement Records",
                            "itemName": {
                                "value": "Horse Movement Records"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://campaign.hkjc.com/en/racing/conghua-movement-records.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_All Up Calculator",
                            "itemName": {
                                "value": "All Up Calculator"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/english/Racing/AllUpCalculator.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "All_Up_Calulator"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Pools or Bet Types",
                        "itemName": {
                            "value": "Pools/Bet Types"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Pari-mutuel Pools",
                            "itemName": {
                                "value": "Pari-mutuel Pools"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/en-US/betting-info/Racing/commingling-pools/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Fixed Odds Bet Types",
                            "itemName": {
                                "value": "Fixed Odds Bet Types"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/en-US/betting-info/racing/fixed-odds/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Flexi Bet Info",
                            "itemName": {
                                "value": "Flexi Bet Info"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/en-US/betting-info/racing/flexi-bet/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Chance Table",
                            "itemName": {
                                "value": "Chance Table"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/en-US/betting-info/racing/chance-table/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Rebate",
                        "itemName": {
                            "value": "Rebate"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Rebate Sub Menu",
                            "itemName": {
                                "value": "Rebate"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://special.hkjc.com/racing/info/en/betting/guide_rebate.asp"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Rebate Calculator",
                            "itemName": {
                                "value": "Rebate Calculator"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "/en/racing/rbc"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "920"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Entrylist_For_testing",
                        "itemName": {
                            "value": "Entrylist(SA1)"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "https://racing.hkjc.com/racing/information/English/Racing/Entries.aspx"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": "/-/media/Sites/JCBW/MenuIcons/new.gif?rev=b843ace7ec66483c829453dc1ede66dc",
                            "width": 17,
                            "height": 10
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": ""
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "100"
                        },
                        "pW": {
                            "value": "200"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }]
                }
            }
        },
        football: {
            "data": {
                "item": {
                    "children": [{
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Selling Hours",
                        "itemName": {
                            "value": "Selling Hours"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "/en/football/sellinghours"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "New tab in original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": ""
                        },
                        "pX": {
                            "value": ""
                        },
                        "pY": {
                            "value": ""
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "BA1",
                        "itemName": {
                            "value": "BA Menu"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": ""
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "ProductMenuFootball2nd",
                            "itemName": {
                                "value": ""
                            },
                            "itemRedirection": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": ""
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": {
                                "value": "1"
                            },
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": {
                                "value": ""
                            },
                            "frontEndIds": {
                                "value": ""
                            },
                            "sNoOfDays": {
                                "value": ""
                            },
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_RESULTS",
                        "itemName": {
                            "value": "Results/Dividend"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Match Results",
                            "itemName": {
                                "value": "Match Results"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "results"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Double HaFu Score Dividend",
                            "itemName": {
                                "value": "Double HaFu Score Dividend"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "dhcres"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_6 HaFu Dividend",
                            "itemName": {
                                "value": "6 HaFu Dividend"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "hfmres"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_First Scorer Results",
                            "itemName": {
                                "value": "First Scorer Results"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "fgsres"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Champion Results",
                            "itemName": {
                                "value": "Champion Results"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "chpres"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_AUTO_TOURN_RESULTS",
                            "itemName": {
                                "value": ""
                            },
                            "itemRedirection": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": {
                                "value": "1"
                            },
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": {
                                "value": "180"
                            },
                            "frontEndIds": {
                                "value": ""
                            },
                            "sNoOfDays": {
                                "value": ""
                            },
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Payout Time",
                            "itemName": {
                                "value": "Payout Time"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "/en/football/payouttime"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Early Settlement",
                        "itemName": {
                            "value": "Early Settlement"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "cashout"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_All Up or Calculator",
                        "itemName": {
                            "value": "All Up or Calculator"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_All Up Combinations",
                            "itemName": {
                                "value": "All Up Combinations"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "/en/football/bet_six"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "530"
                            },
                            "pW": {
                                "value": "828"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Investment Calculator",
                            "itemName": {
                                "value": "Investment Calculator"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "/en/football/calculator"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "297"
                            },
                            "pW": {
                                "value": "828"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Match Info",
                        "itemName": {
                            "value": "Match Info"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": "1"
                        },
                        "GlassboxCustomEventValue": {
                            "value": "2"
                        },
                        "GlassboxTaggingEnabled": {
                            "value": "1"
                        },
                        "children": [{
                            "key": "Int_Live Centre",
                            "itemName": {
                                "value": "Live Centre"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://football.hkjc.com/football/live-centre/english/live-centre.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "1"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "2"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Head to Head",
                            "itemName": {
                                "value": "Head to Head"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://football.hkjc.com/football/stats-centre/english/stats-centre.aspx?url=https://footylogic.com/en"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_League Statistics",
                            "itemName": {
                                "value": "League Statistics"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://football.hkjc.com/football/stats-centre/english/stats-centre.aspx?url=https://footylogic.com/en"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Tournament Statistics",
                            "itemName": {
                                "value": "Tournament Statistics"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://football.hkjc.com/football/stats-centre/english/stats-centre.aspx?url=https://footylogic.com/en/tournament/home/cup"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_HKJC Football News",
                            "itemName": {
                                "value": "HKJC Football News"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://football.hkjc.com/football/hkjc-notice/english/2022-07/notice-20220725-1644.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Bet Types",
                        "itemName": {
                            "value": "Bet Types"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Bet Types Sub Menu",
                            "itemName": {
                                "value": "Bet Types"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/betting-info/Football/had/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Betting Rules",
                            "itemName": {
                                "value": "Betting Rules"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/en-US/betting-info/football/rules/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }]
                }
            }
        },
        marksix: {
            "data": {
                "item": {
                    "children": [{
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_CHECK_DRAW_RESULT",
                        "itemName": {
                            "value": "Drawn Number Checking"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "checkdrawresult"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": ""
                        },
                        "pX": {
                            "value": ""
                        },
                        "pY": {
                            "value": ""
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_RESULTS",
                        "itemName": {
                            "value": "Results"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "results"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": ""
                        },
                        "pX": {
                            "value": ""
                        },
                        "pY": {
                            "value": ""
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "SA_Eng First",
                        "itemName": {
                            "value": ""
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": ""
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "SATest_20220801",
                        "itemName": {
                            "value": "test"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": ""
                        },
                        "redirectMethod": {
                            "value": "New tab in original browser"
                        },
                        "pH": {
                            "value": "666"
                        },
                        "pW": {
                            "value": "999"
                        },
                        "pX": {
                            "value": "25"
                        },
                        "pY": {
                            "value": "35"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "SA Test_sub menu",
                            "itemName": {
                                "value": "SA Test_Sub menu"
                            },
                            "itemRedirection": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": ""
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_FIXTURES",
                        "itemName": {
                            "value": "Draw Schedule"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "fixtures"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Calculator",
                        "itemName": {
                            "value": "Calculator"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "/en/marksix/calculator"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "377"
                        },
                        "pW": {
                            "value": "500"
                        },
                        "pX": {
                            "value": "200"
                        },
                        "pY": {
                            "value": "200"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_STATISTICS",
                        "itemName": {
                            "value": "Statistics"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Statistics Sub Menu",
                            "itemName": {
                                "value": "Statistics"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "statistics"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": ""
                            },
                            "pW": {
                                "value": ""
                            },
                            "pX": {
                                "value": ""
                            },
                            "pY": {
                                "value": ""
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Lucky OCB",
                            "itemName": {
                                "value": "Lucky OCB"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/lucky-ocb/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_M6GUIDE",
                        "itemName": {
                            "value": "Mark Six Guide"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Mark Six Lottery",
                            "itemName": {
                                "value": "Mark Six Lottery"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/lottery/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Prize Qualification",
                            "itemName": {
                                "value": "Prize Qualification"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/prize-qualification/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Allocation of Prize Fund",
                            "itemName": {
                                "value": "Allocation of Prize Fund"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/prize-fund/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Mark Six Snowball Draw",
                            "itemName": {
                                "value": "Mark Six Snowball Draw"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/snowball-draw/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Types of Entry",
                            "itemName": {
                                "value": "Types of Entry"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/entry_type/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Multiple or Banker Entry Chance Table",
                            "itemName": {
                                "value": "Multiple/Banker Entry Chance Table"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/chance-table/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Multiple Consecutive Draws",
                            "itemName": {
                                "value": "Multiple Consecutive Draws"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/consecitive-draws/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Ticket Filling and Validation",
                            "itemName": {
                                "value": "Ticket Filling and Validation"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/ticket-filling/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Important Notice",
                            "itemName": {
                                "value": "Important Notice "
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/lotteries-notice/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Lotteries Rules",
                            "itemName": {
                                "value": "Lotteries Rules"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/en-US/betting-info/marksix/lotteries_rules/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }]
                }
            }
        }
    },
    ch: {
        racing: {
            "data": {
                "item": {
                    "children": [{
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Entrylist",
                        "itemName": {
                            "value": "報名表"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "https://racing.hkjc.com/racing/information/chinese/Racing/Entries.aspx"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": "To_JCRW"
                        },
                        "GlassboxCustomEventValue": {
                            "value": "Entrylist"
                        },
                        "GlassboxTaggingEnabled": {
                            "value": "1"
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Race Card",
                        "itemName": {
                            "value": "排位表"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "//racing.hkjc.com/racing/info/meeting/Racecard/chinese/Local"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": "To_JCRW"
                        },
                        "GlassboxCustomEventValue": {
                            "value": "Race_Card"
                        },
                        "GlassboxTaggingEnabled": {
                            "value": "1"
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Fixture",
                        "itemName": {
                            "value": "賽期表"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/Fixture.aspx"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": "To_JCRW"
                        },
                        "GlassboxCustomEventValue": {
                            "value": "Fixture"
                        },
                        "GlassboxTaggingEnabled": {
                            "value": "1"
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Changes",
                        "itemName": {
                            "value": "更易事項"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "//racing.hkjc.com/racing/Info/meeting/Changes/chinese/Local/"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": "To_JCRW"
                        },
                        "GlassboxCustomEventValue": {
                            "value": "Changes"
                        },
                        "GlassboxTaggingEnabled": {
                            "value": "1"
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Jockey Challenge Info",
                        "itemName": {
                            "value": "騎師王資料"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_JKC Standings",
                            "itemName": {
                                "value": "騎師王積分"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/JKC/JKCResult.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "JKC_Standings"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Jockey Challenge Odds Chart",
                            "itemName": {
                                "value": "騎師王賠率走勢圖"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/jkc-odds-chart.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Jockey_Challenge_Odds_Chart"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Jockey Challenge Statistics",
                            "itemName": {
                                "value": "騎師王統計"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/jkc/JkcStat.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Jockey_Challenge_Statistics"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Jockeys Rides",
                            "itemName": {
                                "value": "騎師分場表"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/jkc-odds-chart.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Jockeys_Rides"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_JKC Introduction",
                            "itemName": {
                                "value": "騎師王簡介"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://special.hkjc.com/racing/info/ch/betting/guide_qualifications_fixed.asp"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_RESULTS",
                        "itemName": {
                            "value": "賽果及派彩"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "results"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Current Raceday Results and Dividend",
                            "itemName": {
                                "value": "即日賽果及派彩"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "results"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_All Results and Dividend",
                            "itemName": {
                                "value": "所有賽果及派彩"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/Chinese/racing/LocalResults.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Local_Results"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Rebate Calculator",
                            "itemName": {
                                "value": "回扣計算機"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "/ch/racing/rbc"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Tools",
                        "itemName": {
                            "value": "分析工具"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_AUTO_RACING_NOTES",
                            "itemName": {
                                "value": "賽馬筆記"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Raceday Focus",
                            "itemName": {
                                "value": "賽日頭條"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/rdf/raceday_focus_1.asp"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": "/-/media/Sites/JCBW/MenuIcons/new.gif?rev=0fd8875f391746ec9e85c70dc62a7381",
                                "width": 11,
                                "height": 11
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Raceday_Focus"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Intro to New Horses",
                            "itemName": {
                                "value": "初出馬介紹"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/newhorse.asp"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Intro_to_New_Horses"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Last Run Reminder",
                            "itemName": {
                                "value": "上杖備忘"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/racing-info/last-run-reminder.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Last_Run_Reminder"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Stats Centre",
                            "itemName": {
                                "value": "賽日數據"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/stats-centre/index.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Stats_Centre"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Tips Index",
                            "itemName": {
                                "value": "貼士指數"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/chinese/tipsindex/tips_index.asp"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "Tips_Index"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_SpeedPRO",
                            "itemName": {
                                "value": "速勢能量"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/speedpro/chinese/formguide/formguide.html?race=1"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "SpeedPRO"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Jockey or Trainer Combo and Debutants Performance",
                            "itemName": {
                                "value": "騎練配對及初出馬成績"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://campaign.hkjc.com/ch/racing/jtcombo_debutants_statistics.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Horse Movement Records",
                            "itemName": {
                                "value": "馬匹搬遷記錄"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://campaign.hkjc.com/ch/racing/conghua-movement-records.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_All Up Calculator",
                            "itemName": {
                                "value": "過關計數機"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Racing/AllUpCalculator.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "To_JCRW"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "All_Up_Calulator"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Pools or Bet Types",
                        "itemName": {
                            "value": "投注彩池/種類"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Pari-mutuel Pools",
                            "itemName": {
                                "value": "平分彩金彩池"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/zh-HK/betting-info/racing/local-pools/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Fixed Odds Bet Types",
                            "itemName": {
                                "value": "固定賠率投注種類"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/zh-HK/betting-info/racing/fixed-odds/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Flexi Bet Info",
                            "itemName": {
                                "value": "靈活玩簡介"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/zh-HK/betting-info/racing/flexi-bet/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Chance Table",
                            "itemName": {
                                "value": "注數表"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/zh-HK/betting-info/racing/chance-table/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Rebate",
                        "itemName": {
                            "value": "回扣"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Rebate Sub Menu",
                            "itemName": {
                                "value": "回扣"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://special.hkjc.com/racing/info/ch/betting/guide_rebate.asp"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Rebate Calculator",
                            "itemName": {
                                "value": "回扣計算機"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "/ch/racing/rbc"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Entrylist_For_testing",
                        "itemName": {
                            "value": "報名表(SA1)"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "//racing.hkjc.com/racing/info/meeting/Entries/chinese/Local"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": "/-/media/Sites/JCBW/MenuIcons/new.gif?rev=0fd8875f391746ec9e85c70dc62a7381",
                            "width": 11,
                            "height": 11
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "SA_ProductMenuRacing",
                            "itemName": {
                                "value": "Sub_報名表(SA)"
                            },
                            "itemRedirection": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Racing/Entries.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "300"
                            },
                            "pW": {
                                "value": "300"
                            },
                            "pX": {
                                "value": "900"
                            },
                            "pY": {
                                "value": "900"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Copy of SA_ProductMenuRacing",
                            "itemName": {
                                "value": "Sub_報名表(SA)1"
                            },
                            "itemRedirection": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": "https://racing.hkjc.com/racing/information/chinese/Racing/Entries.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": "/-/media/Sites/JCBW/MenuIcons/C_new.gif?rev=745181d6b3184dc09422ce935998d058",
                                "width": 11,
                                "height": 11
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": ""
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "300"
                            },
                            "pW": {
                                "value": "300"
                            },
                            "pX": {
                                "value": "300"
                            },
                            "pY": {
                                "value": "300"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }]
                }
            }
        },
        football: {
            "data": {
                "item": {
                    "children": [{
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Selling Hours",
                        "itemName": {
                            "value": "受注時間"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "/ch/football/sellinghours"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "New tab in original browser"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "BA1",
                        "itemName": {
                            "value": ""
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": ""
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_RESULTS",
                        "itemName": {
                            "value": "賽果/派彩"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Match Results",
                            "itemName": {
                                "value": "球賽結果"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "results"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Double HaFu Score Dividend",
                            "itemName": {
                                "value": "孖寶半全膽派彩"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "dhcres"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_6 HaFu Dividend",
                            "itemName": {
                                "value": "6寶半全場派彩"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "hfmres"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_First Scorer Results",
                            "itemName": {
                                "value": "首名入球結果"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "fgsres"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Champion Results",
                            "itemName": {
                                "value": "冠軍賽果"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "chpres"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_AUTO_TOURN_RESULTS",
                            "itemName": {
                                "value": ""
                            },
                            "itemRedirection": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": {
                                "value": "1"
                            },
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": {
                                "value": "180"
                            },
                            "frontEndIds": {
                                "value": ""
                            },
                            "sNoOfDays": {
                                "value": ""
                            },
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Payout Time",
                            "itemName": {
                                "value": "派彩時間"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "/ch/football/payouttime"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Early Settlement",
                        "itemName": {
                            "value": "派彩快"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "cashout"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_All Up or Calculator",
                        "itemName": {
                            "value": "過關/計數機"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_All Up Combinations",
                            "itemName": {
                                "value": "過關方式及組合"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "/ch/football/bet_six"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "828"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Investment Calculator",
                            "itemName": {
                                "value": "投注計算機"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "/ch/football/calculator"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "552"
                            },
                            "pW": {
                                "value": "880"
                            },
                            "pX": {
                                "value": "50"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Match Info",
                        "itemName": {
                            "value": "足智資料庫"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": "1"
                        },
                        "GlassboxCustomEventValue": {
                            "value": "2"
                        },
                        "GlassboxTaggingEnabled": {
                            "value": "1"
                        },
                        "children": [{
                            "key": "Int_Live Centre",
                            "itemName": {
                                "value": "賽事直播"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://football.hkjc.com/football/live-centre/chinese/live-centre.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": "1"
                            },
                            "GlassboxCustomEventValue": {
                                "value": "2"
                            },
                            "GlassboxTaggingEnabled": {
                                "value": "1"
                            }
                        }, {
                            "key": "Int_Head to Head",
                            "itemName": {
                                "value": "對賽往績"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://football.hkjc.com/football/stats-centre/chinese/stats-centre.aspx?url=https://footylogic.com/zh"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_League Statistics",
                            "itemName": {
                                "value": "數據中心(聯賽)"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://football.hkjc.com/football/stats-centre/chinese/stats-centre.aspx?url=https://footylogic.com/zh/tournament/home/league"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Tournament Statistics",
                            "itemName": {
                                "value": "數據中心(盃賽)"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://football.hkjc.com/football/stats-centre/chinese/stats-centre.aspx?url=https://footylogic.com/zh/tournament/home/cup"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_HKJC Football News",
                            "itemName": {
                                "value": "足智新聞"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://football.hkjc.com/football/hkjc-notice/chinese/2022-07/notice-20220721-1905.aspx"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Bet Types",
                        "itemName": {
                            "value": "投注種類"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Bet Types Sub Menu",
                            "itemName": {
                                "value": "投注種類"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/zh-HK/betting-info/football/had/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Betting Rules",
                            "itemName": {
                                "value": "博彩規例"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/zh-HK/betting-info/football/rules/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }]
                }
            }
        },
        marksix: {
            "data": {
                "item": {
                    "children": [{
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_CHECK_DRAW_RESULT",
                        "itemName": {
                            "value": "核對中獎號碼"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "checkdrawresult"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_RESULTS",
                        "itemName": {
                            "value": "過去攪珠結果"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "results"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "SA_Eng First",
                        "itemName": {
                            "value": ""
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": ""
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "SATest_20220801",
                        "itemName": {
                            "value": ""
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": ""
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "SA Test_sub menu",
                            "itemName": {
                                "value": ""
                            },
                            "itemRedirection": {
                                "value": ""
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": ""
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_FIXTURES",
                        "itemName": {
                            "value": "攪珠日期表"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "fixtures"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Calculator",
                        "itemName": {
                            "value": "六合彩計算機"
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "itemLink": {
                            "value": "/ch/marksix/calculator"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "500"
                        },
                        "pW": {
                            "value": "800"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": []
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_STATISTICS",
                        "itemName": {
                            "value": "攬珠結果統計"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Statistics Sub Menu",
                            "itemName": {
                                "value": "攪珠結果統計"
                            },
                            "itemRedirection": {
                                "value": "JCBW page"
                            },
                            "itemLink": {
                                "value": ""
                            },
                            "itemPage": {
                                "value": "statistics"
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Replace original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Lucky OCB",
                            "itemName": {
                                "value": "幸運投注處"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/lucky-ocb/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "Popup"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }, {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_M6GUIDE",
                        "itemName": {
                            "value": "六合彩指南"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "specialtag": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": {
                            "value": ""
                        },
                        "GlassboxCustomEventValue": {
                            "value": ""
                        },
                        "GlassboxTaggingEnabled": {
                            "value": ""
                        },
                        "children": [{
                            "key": "Int_Mark Six Lottery",
                            "itemName": {
                                "value": "六合彩簡介"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/lottery/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Prize Qualification",
                            "itemName": {
                                "value": "獲獎資格"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/prize-qualification/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Allocation of Prize Fund",
                            "itemName": {
                                "value": "獎金基金的分配"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/prize-fund/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Mark Six Snowball Draw",
                            "itemName": {
                                "value": "六合彩金多寶攪珠"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/snowball-draw/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Types of Entry",
                            "itemName": {
                                "value": "注項種類"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/entry_type/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Multiple or Banker Entry Chance Table",
                            "itemName": {
                                "value": "複式/膽拖注數表"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/chance-table/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Multiple Consecutive Draws",
                            "itemName": {
                                "value": "連續多期攪珠"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/consecitive-draws/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Ticket Filling and Validation",
                            "itemName": {
                                "value": "填寫及核對彩票"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/ticket-filling/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Important Notice",
                            "itemName": {
                                "value": "重要事項"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/lotteries-notice/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }, {
                            "key": "Int_Lotteries Rules",
                            "itemName": {
                                "value": "獎券規例"
                            },
                            "itemRedirection": {
                                "value": "Other link"
                            },
                            "itemLink": {
                                "value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/lotteries_rules/"
                            },
                            "itemPage": {
                                "value": ""
                            },
                            "itemIcon": {
                                "src": null,
                                "width": null,
                                "height": null
                            },
                            "jpFlag": null,
                            "altTxt": null,
                            "itemEnable": {
                                "value": "1"
                            },
                            "redirectMethod": {
                                "value": "New tab in original browser"
                            },
                            "pH": {
                                "value": "600"
                            },
                            "pW": {
                                "value": "900"
                            },
                            "pX": {
                                "value": "20"
                            },
                            "pY": {
                                "value": "20"
                            },
                            "specialtag": null,
                            "frontendID": null,
                            "startDatetime": null,
                            "noOfDays": null,
                            "frontEndIds": null,
                            "sNoOfDays": null,
                            "moHead": null,
                            "moText": null,
                            "moImage": null,
                            "iconM": null,
                            "pCardEnable": null,
                            "GlassboxCustomEventName": {
                                "value": ""
                            },
                            "GlassboxCustomEventValue": {
                                "value": ""
                            },
                            "GlassboxTaggingEnabled": {
                                "value": ""
                            }
                        }]
                    }]
                }
            }
        }
    }
}