export const httpRequest = (url, params, opts, signal) => {
    const method = (opts?.method??'GET').toUpperCase();
    const contentType = method === 'POST' ? 'application/json' : undefined
    const headers = {
      'Content-Type': contentType,
      ...opts?.headers
    }
    const body = params ? JSON.stringify(params) : undefined
    return fetch(url, {
        credentials: 'include',
        ...opts,
        method,
        headers,
        body,
        signal
    }).then(rs => rs.json());
  }
  
  export const timer = (timeout, controller,timeoutErrData) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(timeoutErrData);
            controller.abort();
        }, timeout);
    });
  };
  
  const defaultErrHandler = (err) => { 
    console.error(err);
    return { code: 415 };
  };
  
  export const fetchData = (request, timer) => ({timeout, onError, onSuccess, options, timeoutErrData}) => async (url, params) => {
    try {
      if (timeout) {
        const controller = new AbortController();
        const rs = await Promise.race([timer(timeout, controller,timeoutErrData), request(url, params, options, controller.signal)])
        return onSuccess(rs)
      }
      const rs = await request(url, params, options)
      return onSuccess(rs)
    } catch(e){
      return onError(e)
    }
  }
  
  const Request = (({httpRequest, timer, defaultErrHandler, fetchData}) => {
    return function HttpRequest() {
      const factory = (config) => {
        const obj = {
          timeout(duration) {
            return factory({...config, timeout: duration})
          },
          onError(errorHandler) {
            return factory({...config, onError: errorHandler})
          },
          onSuccess(successHandler) {
            return factory({...config, onSuccess: successHandler})
          },
          options(options) {
            return factory({
              ...config,
              options: {
                ...config.options,
                ...options
              }
            })
          },
          headers(headers) {
            return factory({
              ...config,
              options: {
                ...config.options,
                headers: {
                  ...config.options?.headers,
                  ...headers
                }
              }
            })
          },
          run(url, params) {
            return fetchData(httpRequest, timer)(config)(url, params);
          }
        }
        return obj
      }
      return factory({onError: defaultErrHandler, onSuccess: rs => rs})
    }
    
  })({httpRequest, timer, defaultErrHandler, fetchData});
  
  export default Request

  export const timeoutFetch = fetchData(httpRequest, timer)