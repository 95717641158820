import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const HDC = () => {
    return (
        <section className='HDC'>
            <MatchTable betType='HDC' etBetType='EDC' />
            <Remark betType='HDC'/>
        </section>
    )
}
export default HDC;