import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import InvestmentCalculator from '../Common/InvestmentCalculator';
import CWinBase from '../Common/CWINBase';
import OddsDropLegend from '../Common/OddsDropLegend';
import AddSlipBtn from '../Common/AddSlipBtn';
import { RacingContext } from '../../Home/RacingPage';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';

const OddsCWB = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);

    if (context == null) {
        return <Loading/>
    } else {
        let pageConfig = context.pageConfig;

        return (
            <section className={context.content.page}>
                <InvestmentCalculator enable={true} />
                <RefreshTime product="racing" />
                <CWinBase betType={context.content.page} raceNo={context.content.raceNo} />

                <OddsDropLegend />
                <div className="rc-cwa-all-remarks">
                    <div>{t('LB_RC_CWIN_REMARKS')}</div>
                </div>
                <div className="multi-leg-footer-betSlip">
                    <div><AddSlipBtn /></div>
                </div>
                <InvestmentCalculator enable={!context.windowSize.isMobileSize} />
            </section>
        );
    }
}
export default OddsCWB;