import moment from 'moment';

export const setData = (contentRef, pmPool) => {
    let update = false;
    const idx = contentRef.current.meeting.pmPools.findIndex(x=> x.id===pmPool.id);
    if ( idx > -1 ) {
    //    let oldTime = moment(contentRef.current.meeting.pmPools[idx].lastUpdateTime);
    //    let newTime = moment(pmPool.lastUpdateTime);
    //    if ( oldTime < newTime ) {
            contentRef.current.meeting.pmPools[idx] = pmPool;
    //    }
    }
    else {
        contentRef.current.meeting.pmPools.push(pmPool);
        update = true;
    }
    return update;
}