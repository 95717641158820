import React, { useState, useContext } from 'react';

import { ConfigContext } from './components/Common/ConfigHelper';

const ShowPara = () => {
    const { channelParas } = useContext(ConfigContext)

    const css = `
        .paraTable {
            display: grid;
            grid-template-columns: repeat(10, 10%);
            margin-bottom: 12px;
        }
        .paraSubHeader {
            font-size: 15px;
            margin-top: 12px;
        }
        .paraTable>div {
            border: 1px solid #cccccc;
            word-break: break-all;
        }
    `

    return <>
        <style>{css}</style>
        <div style={{ fontSize: "22px", fontWeight: "bold" }}>Channel Parameters</div>
        <div style={{ fontSize: "18px", fontWeight: "bold", paddingTop: "4px" }}>(endpoint: {window.globalConfig.CHANNEL_PARA_URL})</div>
        {!channelParas && <div>Channel Para loading...</div>}
        {channelParas && channelParas.WebPara && channelParas.WebPara.onlinepara && <>
            <div className="paraSubHeader">Online Para</div>
            <div className="paraTable">
                {Object.keys(channelParas.WebPara.onlinepara).map(idx => {
                    return <>
                        <div>{idx}</div>
                        <div>{channelParas.WebPara.onlinepara[idx]}</div>
                    </>
                })}
            </div>
        </>}
        {channelParas && channelParas.WebPara && channelParas.WebPara.channelpara && <>
            <div className="paraSubHeader">Channel Para</div>
                <div className="paraTable">
                {Object.keys(channelParas.WebPara.channelpara).map(idx => {
                    return <>
                        <div>{idx}</div>
                        <div>{channelParas.WebPara.channelpara[idx]}</div>
                    </>
                })}
            </div>
        </>}
        {channelParas && channelParas.WebPara && channelParas.WebPara.eWinService && <>
            <div style={{ fontSize: "15px" }}>eWin Service</div>
                <div className="paraTable">
                {Object.keys(channelParas.WebPara.eWinService).map(idx => {
                    return <>
                        <div>{idx}</div>
                        <div>
                            {typeof channelParas.WebPara.eWinService[idx]==='object' ?
                                Object.keys(channelParas.WebPara.eWinService[idx]).map(idx2 => {
                                    return <div>{idx2} : {channelParas.WebPara.eWinService[idx][idx2]}</div>
                                })
                                : <div>{channelParas.WebPara.eWinService[idx]}</div>
                            }
                        </div>
                    </>
                })}
            </div>
        </>}
    </>
}
export default ShowPara;