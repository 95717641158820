
export const parseInvOdds = ( content, message, topicInfo) => {
    let pmPool;
    let poolInvs;
    if(content.meeting.pmPools != null){
        pmPool = content.meeting.pmPools.filter(x => x.oddsType===topicInfo.oddsType + "Invest" && x.leg.races[0]===topicInfo.raceNo)[0];
    }
    if(content.meeting.poolInvs != null){
        poolInvs = content.meeting.poolInvs.filter(x => x.oddsType===topicInfo.oddsType && x.leg.races[0]===topicInfo.raceNo)[0];
    }

    if(message != null){
        if(message.invByRuByPos != null  ){
            if(message.invByRuByPos.inv != null){
                if(pmPool != null){
                    for(let i =0;i<message.invByRuByPos.inv.length;i++){
                        let position = message.invByRuByPos.inv[i].pos -1 ;
                        let investment = message.invByRuByPos.inv[i].inv ;
                        let combString = message.invByRuByPos.inv[i].ruNo ;
                        pmPool.oddsNodes.filter(x=> parseInt(x.combString)===combString)[0].bankerOdds[position].oddsValue = investment;
                    }
                    pmPool.lastUpdateTime = message.invByRuByPos.updAt;
                }
            }
        }
        if(poolInvs != null){
            let invObj = null;
            if(message.mgPl!=null && message.mgPl.ttlInv!=null) {
                invObj = message.mgPl.ttlInv;
            }
            else if ( message.ttlInv!=null ) {
                invObj = message.ttlInv;
            }

            if(invObj !=null){
                if( invObj.updAt != null ){
                    poolInvs.lastUpdateTime = invObj.updAt;
                }
                if( invObj.net != null ){
                    poolInvs.investment = invObj.net;
                }
                if( invObj.netInvSt != null ){
                    poolInvs.netInvSt = invObj.netInvSt;
                }
            }
        }
    }
}