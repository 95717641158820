import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringXMLToJSON } from '../../Common/home-common.js';
import BankerKeyPad from './IOddsTable.js';
import { GetSelectedBet } from './RacingBetline';
import { RacingContext } from '../../Home/RacingPage.js';


const OddsEnquiry = ({betType, runnerData, onKeyPadClick}) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const [enable, setEnable] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [displayBetline, setDisplayBetline] = useState('-');
    const [odds, setOdds] = useState('-');//
    const [lastEnquiryDt, setLastEnquiryDt] = useState('-');
    const [pageNum, setPageNum] = useState(1);
    const [bankerNum, setBankerNum] = useState([]);

    useEffect(() => {
        let arr1 = GetSelectedBet(betType, "*", "b1");
        let arr2 = GetSelectedBet(betType, "*", "b2");
        let arr3 = GetSelectedBet(betType, "*", "b3");
        let arr4 = GetSelectedBet(betType, "*", "leg");

        let tmpArr = [];
        tmpArr.push(...arr1);
        tmpArr.push(...arr2);
        tmpArr.push(...arr3);
        tmpArr.push(...arr4);

        setEnable(tmpArr.length==4);
        setBankerNum(tmpArr);
    }, [runnerData])

    const enquiryOdds = () => {

        const dt = context.content.meeting.date;
        const ven = context.content.meeting.venueCode;
        const raceNo = context.content.raceNo;
        const line = bankerNum.join('%2B');
        const url = `${window.globalConfig.INFOAPI_URL}${window.globalConfig.OES_ENDPOINT}/${dt}/${ven}/${raceNo}/QTT/${line}`;
        
        fetch(url, { method: "GET" })
            .then(response => response.text())
            .then(str => {
                let obj = StringXMLToJSON(str, "text/xml");
                setDisplayBetline(bankerNum.join('-'));
                setOdds(obj?.QTTResponseType?.OddsList?.OddsType?.DisplayOdds?.['#text'] || '-');
                setLastEnquiryDt(obj?.QTTResponseType?.OddsList?.OddsType?.OddsGernartionTime?.['#text']?.substring(11,16) || '-');
                return obj;
            });
    }

    function handleKeyPadClick(num, pg) {
        if ( num==-1 ) {
            setPageNum(pg);
        }
        else {
            let isSelected = false;
            if ( onKeyPadClick!=null ) {
                onKeyPadClick(num, bankerNum);
            }
        }
    }

    return (
        <>
            <BankerKeyPad hideBankerLbl={true} data={runnerData} page={pageNum} selectBanker={bankerNum} onKeyPadClick={handleKeyPadClick} />
            <div className="rc-odds-enquiry-m">
                <div className="title">
                    <span>{t('LB_RC_LAST_ENQUIRY')}: {lastEnquiryDt}</span>
                </div>
                <div className="content">
                    <div>
                        <div style={{ textAlign: "left" }}>{t('LB_RC_BETLINE')}</div>
                        <div>{t('LB_ODDS')}</div>
                    </div>
                    <div style={{ marginTop: "4px", color: "#003C84" }}>
                        <div style={{ textAlign: "left", width: "100px" }}>{displayBetline}</div>
                        <div className="odds">{odds}</div>
                    </div>
                    <div>
                        <button onClick={enquiryOdds} disabled={!enable} className={`race-btn mr-12 cta_hr ${!enable && 'dim'}`}>{t('LB_RC_ENQUIRY')}</button>
                    </div>
                </div>
            </div>
            <div className="rc-odds-enquiry">
                <div className="title">
                    <span>{t('LB_RC_ODDS_ENQUIRY_RESULT')}</span>
                    <span>{t('LB_RC_LAST_ENQUIRY')}: {lastEnquiryDt}</span>
                </div>
                <div className="content">
                    <div>
                        <div>{t('LB_RC_BETLINE')}</div>
                        <div>{displayBetline}</div>
                    </div>
                    <div>
                        <div>{t('LB_ODDS')}</div>
                        <div>{odds}</div>
                    </div>
                    <div>
                        <button onClick={enquiryOdds} disabled={!enable} className={`race-btn mr-12 cta_hr ${!enable && 'dim'}`}>{t('LB_RC_ENQUIRY')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OddsEnquiry;