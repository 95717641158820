import { getOddsValue } from "./common";

export const parseBankerOdds = (pmPool, message) => {
    if(message != null) {
        if(message.updAt != null && message.bk !=null){
            pmPool.lastUpdateTime = message.updAt;
            message.bk.forEach(bkNode => {
                let targetComb = pmPool.oddsNodes.filter(x=> parseInt(x.combString)===bkNode.bkNo)[0];
                if ( targetComb!=null ) {
                    let bOdds = [];
					if(bkNode.bkTopN != null){
						 bkNode.bkTopN.forEach(comb => {
                        let bCmb = {
								combString: comb.cmbStr,
								oddsValue: getOddsValue(comb.odds, comb.cmbSt)
							}
							bOdds.push(bCmb);
						});
						targetComb.bankerOdds = bOdds;
					}
                    else{
						if(bkNode.bkScrSt){
							targetComb.oddsValue = "SCR";
                            targetComb.bankerOdds = bOdds;
						}
					}
                }
            });
        }
    }
}