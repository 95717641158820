import { Query } from '../../Common/graphqlFetch'
import { getBSFootballMatchQuery } from '../../Football/Data/DataQuery';

export const footballMatchStatusQuery =async (matchIds) => {
    return Promise.all([Query({ 
        query : getBSFootballMatchQuery(),
        variables: {
            "fbOddsTypes": ["ALL"],
            "matchIds": matchIds
        }
    })])
    .then(([results]) => {
        return results;
    }).catch(err=>{
        return {};
    });
}