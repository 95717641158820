import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form  } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { useWindowSize } from '../../Common/CommonHooks';
import { hlSearchResultText } from "./FBSearchButton";

const CustomScrollbars = props => {
    const { isMobileSize } = useWindowSize();
    return <Scrollbars
        autoHeight
        autoHeightMin={36}
        autoHeightMax={isMobileSize ? 178 : 433}
        className='fbSearchScroll'
        style={{borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px'}}
        // autoHide
        // autoHideTimeout={1000}
        // autoHideDuration={200}
        {...props}
    />
};


export const FBDropDownSearch = ({searchText,onInputSearchText,
    onBlurSearchInput, onFocusSearchInput, searchResults, onClickTeamName, prevSelectVal, searchParams,inputFocus,toggleOnOff, clearSearchResults }) => {
    const { isMobileSize } = useWindowSize();
    const {t, i18n} = useTranslation()
    const inputRef = useRef();
    useEffect(() => {
        const mobileScrollBar = document.querySelector('body')
        if(inputFocus){
            setTimeout(() =>{
                inputRef?.current?.focus();
            }, 50)
            mobileScrollBar.style.overflow = 'hidden';
        }else{
            mobileScrollBar.style.overflow = '';
        }
    }, [inputFocus])

    const allTeamObj={
        id:'0',
        code:'0',
        name_en:'All Teams',
        name_ch:'所有球隊',
        teamName:'All Teams(所有球隊)',
    }

    const ddBlurHandler=(e)=>{
        onBlurSearchInput()

    }

    const searchInputOnFocus = useCallback((e) => {
        // e.target.style.fontSize = ''
        if (isMobileSize) {
            e.preventDefault();
            e.target.setAttribute('data-init', 1);
            e.target.setSelectionRange(10, 10);
            setTimeout(function () {
                e.target.setSelectionRange(10, 10);
            });
        }
        onFocusSearchInput();
    }, [])

    searchResults=[allTeamObj, ...searchResults]

    const filterTeam = searchResults.filter(teamItem => teamItem.teamName.toLowerCase().includes(searchText.toLowerCase().trim())) || [];

    return (
        <div className='FBFilterSelectDrd  matchDropdown-ddbtn fbResultsMatch-ddbtn ddNoRSpace' id='FBFilterSelectDrd'>
            <div className='dropdown-toggle' onClick={toggleOnOff}>
                <div className='select-value'>
                    <span>{t('LB_FB_CHP_TEAM')}</span>
                    <span className="fb-results-searchbox-teamName" title={prevSelectVal.teamName}>{prevSelectVal.teamName}</span>
                </div>
                <ArrowIcon direction='down' size={24}/>
            </div>
            {inputFocus && <div className='dropdown-menu show'>
                <div >{t('LB_FB_CHP_TEAM')}</div>
                <div className='resultSearchText'>
                    <input
                        className="fbSearchInput"
                        type="text"
                        ref={inputRef}
                        placeholder={t('LB_FB_SEARCH_TEAM')}
                        onInput={onInputSearchText}
                        onBlur={ddBlurHandler}
                        onFocus={searchInputOnFocus}
                        value={searchText}
                    />
                    {searchText && <div className='msgInboxClose clearSearchResultBtn' onClick={(e)=> clearSearchResults(e)}></div>}
                </div>
                <>
                    {filterTeam?.length>0 || searchText?.length==0?
                        <CustomScrollbars>
                            <ul className="list-unstyled filterItem">
                                {filterTeam?.map(item =>{
                            const val = hlSearchResultText(searchText, item.label)
                            const teamName = item.teamName;
                            return <div className={`dropdown-item ${searchParams.teamId === item.id?`active`:``}`} onClick={() => onClickTeamName(item)} key={item.label} title={teamName}>
                                <span>
                                    {teamName}
                                </span>
                            </div>
                        })}
                            </ul>
                        </CustomScrollbars>
                    :
                        <div className='dropdown-item noResults'>{t('LB_FB_SEARCH_NOFOUND')}</div>
                    }
                </>
            </div>}
        </div>
    );
}
