export const Cookies = {
    
    setDataArray: (name, value) => { setCookiesArray(name, value) },
    getDataArray: (name) => { return getCookiesArray(name) },
    setData: (name, value, isDomainShare, time) => { setCookies(name, value, isDomainShare, time) }, 
    getData: (name) => { return getCookies(name) }
}

function getCookies(name) {
    
  let cookie = document.cookie.split('; ').find(i => i.includes(name + '='))
  
  if(cookie != null){
    let value = decodeURIComponent(cookie.replace(name + '=',''))

    return value;
  }

  return null;
}



function setCookies(name, value, domain=null, time) {
  const date = new Date()
  let saveTime = 365*2*24*60*60*1000;
  if(time > 0){ saveTime= time*1000; };
  date.setTime(date.getTime()+ (saveTime))
  const expires = "expires=" + date.toUTCString()
  document.cookie = `${name}=${encodeURIComponent(value)}; path=/; ${setDomainShare(domain)} ${expires} `
}

function setDomainShare(isDomainShare) {
  let domainShare = ''
  const hostNameArr = window.location.host.split('.');
  if (hostNameArr.length > 1 && isDomainShare) {
    if(hostNameArr[2].indexOf(':')>-1){
      hostNameArr[2]=hostNameArr[2].split(':')[0]
    }
    domainShare = ` domain=.${hostNameArr[1]}.${hostNameArr[2]};`;
  }
  return domainShare;
}


function getCookiesArray(name) {
    
    let cookie = document.cookie.split('; ').find(i => i.includes(name + '='))
    
    if(cookie != null){
      let value = decodeURIComponent(cookie.replace(name + '=',''))
  
      let output = JSON.parse(value);
  
      return output;
    }
  
    return [];
  }
  
  
  function setCookiesArray(name, value) {
      
    const date = new Date()
    date.setTime(date.getTime()+ (365*2*24*60*60*1000))
    const expires = "expires=" + date.toUTCString()
    document.cookie = `${name}=${encodeURIComponent(JSON.stringify(value))}; path=/; ${expires}`
  
  }
  