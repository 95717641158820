import React from 'react';
import { useTranslation } from 'react-i18next';
import { getNumberSuffix } from './MatchBase';

const NTSLastOddsTable = ({betType, match, isMobile, getOddsVal }) => {
    const { t, i18n } = useTranslation();
    let foPools = match.foPools.filter(x=> x.oddsType===betType);
    foPools.sort((x,y)=> {return x.instNo -y.instNo});

    return foPools.map((foPool, i) => {
        let line = foPool.lines[0];
        let instNo = foPool.instNo;
        let ngoal = `${instNo}${i18n.language=="en" ? getNumberSuffix(instNo) : ''}`;
        let key = t('LB_FB_N_GOAL').replace('{0}', ngoal);
        return <div className={`team ` + betType.toUpperCase()}>
            <div>{key}</div>
            <div>{getOddsVal(line, 'H', isMobile)}</div>
            <div>{getOddsVal(line, 'N', isMobile)}</div>
            <div>{getOddsVal(line, 'A', isMobile)}</div>
        </div>
    })
}
export default NTSLastOddsTable