import {
    logoutIB,
    logoutAsOnlyIB,
    getSessionId_IB,
    checkAccountPasswordAPI_IB,
    checkEKBAAnswerAPI_IB,
    getEkbaBySsoAuthen,
    ticketExtendSession_IB,
    extendSession_IB
} from './BetSlipIBAPI';
import { useEffect, useRef, useState, useContext } from 'react';
import { renewAccessToken, logout, revokeSession, renewSSO } from './BetSlipAPI';
import { ConfigContext, GetBetslipConfig } from '../../components/Common/ConfigHelper';
import { useTranslation } from 'react-i18next';
import { removeCurrentSessionRecords } from '../Common/CurrentSessionRecords';
import { glassboxSetUserInfo } from '../Common/utils/glassbox';
import { callWAReq } from '../Common/utils/wa';
import { MyBetSlipContext } from './BetSlipHooks';
import { getPersonalSettings } from '../Home/PersonalSettings/config';
import { Cookies } from '../Common/CookieHelper';
import { GlobalContext } from '../../contexts/GlobalContext';

const getSessionToken = () => {
    const accessToken = window.sessionStorage.getItem('access_token') || '';
    const refreshToken = window.sessionStorage.getItem('refresh_token') || '';

    return {
        access_token: accessToken,
        refresh_token: refreshToken
    };
};

export const getSessionLoginAccount = () => {
    return window.sessionStorage.getItem('login_account') || '';
};

export const setTokenToSession = (token) => {
    window.sessionStorage.setItem('access_token', token.accessToken);
    window.sessionStorage.setItem('refresh_token', token.refreshToken);
    window.sessionStorage.setItem('id_token', token.idToken);
    token.login_account && window.sessionStorage.setItem('login_account', token.login_account);
};

export const setUserInfoToSession = (userInfo) => {
    window.sessionStorage.setItem('login_firstNameEn', userInfo?.bettingProfile?.personalInfo.firstNameEn);
    window.sessionStorage.setItem('login_firstNameZhHk', userInfo?.bettingProfile?.personalInfo.firstNameZhHk);
    window.sessionStorage.setItem('login_lastNameEn', userInfo?.bettingProfile?.personalInfo.lastNameEn);
    window.sessionStorage.setItem('login_lastNameZhHk', userInfo?.bettingProfile?.personalInfo.lastNameZhHk);
    window.sessionStorage.setItem(
        'login_maskedMobilePhoneNumber',
        userInfo?.bettingProfile?.contactInfo.maskedMobilePhoneNumber
    );
    window.sessionStorage.setItem('login_mobilePhoneNumber', userInfo?.bettingProfile?.contactInfo.mobilePhoneNumber);
    window.sessionStorage.setItem('login_webAccountLoginName', userInfo?.webProfile?.personalInfo?.webAccountLoginName);
};

const removeToken = () => {
    window.sessionStorage.removeItem('access_token');
    window.sessionStorage.removeItem('refresh_token');
    window.sessionStorage.removeItem('id_token');
    window.sessionStorage.removeItem('login_account');
    window.sessionStorage.getItem('PCardTier') && window.sessionStorage.removeItem('PCardTier');
};

const getAgeGroup = (age) => {
    // const age = window.sessionStorage.getItem('login_age')
    if (!age || age === 'undefined') return '00';
    if (age < 18) return '01';
    if (age < 26) return '02';
    if (age > 80) return '14';
    let ageGroup = 2;
    ageGroup += Math.ceil((age - 25) / 5);
    if (ageGroup > 10) return ageGroup + '';
    else {
        return '0' + ageGroup;
    }
};
const getGenderCode = (gender) => {
    if (!gender || gender === 'undefined') return '0';
    if (gender === 'M') return '1';
    if (gender === 'F') return '2';
};
const getMemberCode = (member) => {
    if (!member) return '0';
    if (member === 'FULL') return '2';
    if (member === 'RACE') return '3';
    if (member === 'RCIM') return '4';
    return '0';
};

const getPriorityCardCode = (priorityCardCode) => {
    if (!priorityCardCode && priorityCardCode !== '') return '0';
    if (priorityCardCode) return 'Y';
    return 'N';
};

const loginStatus = {
    isBusy: false
};

export const GetLoginStatus = () => {
    return loginStatus;
};

export function SetLoginBusyStatus(status) {
    loginStatus.isBusy = status;
}

export const callLogoutApi = (accessToken) => {
    revokeSession(accessToken);
    logout(accessToken);
};

export const callLogoutIbApi = (isNew = true) => {
    logoutIB(isNew);
};

export const isLogonHigh = () => {
    return window.sessionStorage.getItem('is_logon') == 1;
};

export const getCIAMJwtToken = () => {
    return sessionStorage.getItem('cJwt');
};

export const getPushToken = () => {
    return sessionStorage.getItem('push_access_token');
};

export const getProfileByToken = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
    const profile = JSON.parse(jsonPayload);
    return profile;
};

export const renewTokens = async (clientId = 'JCBW') => {
    const refreshToken = getSessionToken().refresh_token;
    if (refreshToken == null || refreshToken == '') {
        clearInterval(sessionStorage.getItem('tokenIntervalId'));
        //console.log('stop token renew ' + Date() + ' ' + sessionStorage.getItem('tokenIntervalId'));
    } else {
        const token = await renewAccessToken(refreshToken, clientId);
        if (token?.access_token) {
            await renewSSO(token.access_token);
        }
        return token;
    }
    return null;
};

export const useLoginLogout_IB = (props) => {
    const { t, i18n } = useTranslation();
    const [EKBAAnswerReqData, setEKBAAnswerReqData] = useState(null);
    const [showLoginEKBAAnswer, setShowLoginEKBAAnswer] = useState(false);
    const [loginEKBAQuestion, setLoginEKBAQuestion] = useState('');
    const [loginIncorrectMsg, setLoginIncorrectMsg] = useState('');
    const [loginAccount, setLoginAccount] = useState('');
    const [password, setPassword] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [latitude, setLatitude] = useState(0.0);
    const [longitude, setLongitude] = useState(0.0);
    const [refreshToken, setRefreshToken] = useState('');
    const [accessTokenIdToken, setAccessTokenIdToken] = useState('');
    const [userInfo, setUserInfo] = useState({});
    const [loginTimeoutRemind, setLoginTimeoutRemind] = useState(null);
    const [isShowTandC, setIsShowTandC] = useState(false);
    const [isShowTODialog, setIsShowTODialog] = useState(false);
    const [isShowCSLogoutDialog, setIsShowCSLogoutDialog] = useState(false);
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [showLastLoginInfo, setShowLastLoginInfo] = useState(false);
    const [showLogoutSucMsg, setShowLogoutSucMsg] = useState(false);
    const [showLogoutReminder, setShowLogoutReminder] = useState(false);
    const [showAnswerErrorMsg, setShowAnswerErrorMsg] = useState('');
    const [isShowLogoutBetText, setIsShowLogoutBetText] = useState(false);
    const [willOpenUrl, setWillOpenUrl] = useState('');
    // const timeoutRef = useRef(0);
    const sessionIdleTimeRef = useRef(0);
    const [warningIdleTimeRef2, setWarningIdleTimeRef2] = useState([]);
    const [warningIdleTimeRef, setWarningIdleTimeRef] = useState(null);
    const timerRef = useRef(null);
    // const { betslipMenu } = useContext(ConfigContext);
    // const { isSpeedbet, setIsSpeedbet, setBetBaskets, setBalance, betBaskets } = useContext(MyBetSlipContext);
    const { config, isSpeedbet, setIsSpeedbet, setBetBaskets, setBalance, betBaskets } = props;
    const { betslipMenu } = config;
    const [LoginAPILoading, setLoginAPILoading] = useState(false);
    const [showEwalletDialog, setShowEwalletDialog] = useState(false);
    const globalContext = useContext(GlobalContext);

    function setIdleTimeout(jcrw_redirect = false) {
        let warningIdleTime1;
        let warningIdleTime2;

        if (jcrw_redirect) {
            sessionIdleTimeRef.current = +sessionStorage.getItem('sessionIdleTimeOutTime');
            warningIdleTime1 = +sessionStorage.getItem('warningIdleTime1');
            warningIdleTime2 = JSON.parse(sessionStorage.getItem('warningIdleTime2'));
            console.debug('setIdleTimeout for jcrw redirect');
        } else {
            //use channel para, if invalid use BetslipConfig as default
            let sessionIdleTime =
                !isNaN(+sessionStorage.getItem('SessionIdleTime')) && sessionStorage.getItem('SessionIdleTime')
                    ? +sessionStorage.getItem('SessionIdleTime')
                    : betslipMenu.SessionIdleTime;
            // sessionIdleTime = 6; // for testing

            let warningTimeout =
                !isNaN(+sessionStorage.getItem('WarningTimeout')) && sessionStorage.getItem('WarningTimeout')
                    ? +sessionStorage.getItem('WarningTimeout')
                    : betslipMenu.WarningTimeout;
            // warningTimeout = 4; // for testing
            let targetWarningTimeout = sessionIdleTime - warningTimeout; // get target time to pop warning

            const now = globalContext?.sessionServerTimeStamp || new Date().getTime();
            sessionIdleTimeRef.current = new Date(now + sessionIdleTime * 60 * 1000).getTime();
            warningIdleTime1 = new Date(now + targetWarningTimeout * 60 * 1000).getTime();
            warningIdleTime2 = [];
            for (let i = 1; i < warningTimeout; i++) {
                warningIdleTime2.unshift(new Date(now + (sessionIdleTime - i) * 60 * 1000).getTime());
            }
        }
        setWarningIdleTimeRef(warningIdleTime1);
        setWarningIdleTimeRef2(warningIdleTime2);

        console.log('sessionIdleTimeRef.current', sessionIdleTimeRef.current, new Date(sessionIdleTimeRef.current));
        console.log('warningIdleTime1', warningIdleTime1, new Date(warningIdleTime1));
        console.log('warningIdleTime2', warningIdleTime2);

        // JCRW redirect
        sessionStorage.setItem('sessionIdleTimeOutTime', sessionIdleTimeRef.current);
        sessionStorage.setItem('warningIdleTime1', warningIdleTime1);
        sessionStorage.setItem('warningIdleTime2', JSON.stringify(warningIdleTime2));
    }

    useEffect(() => {
        if (sessionStorage.getItem('is_logon') == '1' && betslipMenu) {
            timerRef.current = setInterval(() => {
                const now = globalContext?.sessionServerTimeStamp || new Date().getTime();

                if (now >= sessionIdleTimeRef.current) {
                    // SessionIdleTime
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                    setLoginTimeoutRemind('3');
                    // if (window.globalConfig.IS_SPEEDBET  || window.globalConfig.IS_EWIN2) {
                    //     setIsShowCSLogoutDialog(true);
                    //     setTimeout(() => {
                    //         setIsShowCSLogoutDialog(false); // Navigate to login
                    //     },1000)
                    // } else {
                    //     setIsShowCSLogoutDialog(true);
                    // }
                    setIsShowCSLogoutDialog(true);

                    setIsShowTODialog(false);
                    logoutAsOnlyIB();
                    return;
                }

                // every minute in between WarningTimeout & SessionIdleTime
                const idx = warningIdleTimeRef2.findLastIndex((el) => now >= el);
                if (idx >= 0) {
                    if (warningIdleTimeRef) {
                        // first time pop-up reminder
                        setLoginTimeoutRemind('1');
                        setIsShowCSLogoutDialog(true);
                        setWarningIdleTimeRef(null);
                    }
                    setWarningIdleTimeRef2(warningIdleTimeRef2.slice(idx + 1));
                    setIsShowTODialog(true);
                    playBgmFunc();
                    return;
                }

                if (
                    // WarningTimeout
                    warningIdleTimeRef &&
                    now >= warningIdleTimeRef &&
                    now < sessionIdleTimeRef.current
                ) {
                    setLoginTimeoutRemind('1');
                    setIsShowCSLogoutDialog(true);
                    setIsShowTODialog(true);
                    playBgmFunc();
                    setWarningIdleTimeRef(null);
                    return;
                }
            }, 1000);
            console.log('set timerRef', warningIdleTimeRef, warningIdleTimeRef2);
            return () => {
                // console.log('reset timeRef, warningIdleTimeRef, warningIdleTimeRef2', warningIdleTimeRef, warningIdleTimeRef2)
                clearInterval(timerRef.current);
                timerRef.current = null;
            };
        }
    }, [warningIdleTimeRef, warningIdleTimeRef2]);

    useEffect(() => {
        const tokenObj = getSessionToken();
        if (tokenObj.access_token && tokenObj.refresh_token) {
            setAccessToken(tokenObj.access_token);
            setRefreshToken(tokenObj.refresh_token);
        }
        setUserInfoIB();
    }, []);

    useEffect(() => {
        // const fetchUserInfo = async () => {
        //     const userInfo = await getUserInfo(accessToken);
        //     userInfo && setUserInfo(userInfo);
        //     setUserInfoToSession(userInfo);
        //     const userInfoAdditional = await getUserInfoAdditional(
        //         accessToken,
        //         sessionStorage.getItem('login_webAccountLoginName')
        //     );

        //     processIdToken(accessTokenIdToken, userInfoAdditional);
        //     setUserInfoAdditionalToSession(userInfoAdditional);
        //     callWAReq();
        //     glassboxSetUserInfo();
        // };
        const betslipEle = document.getElementById('root');
        if (sessionStorage.getItem('is_logon') == '1' && betslipMenu) {
            setShowLoginEKBAAnswer(false);
            setShowUserInfo(true);
            // timeoutRef.current = 0;
            if (!userInfo || Object.keys(userInfo).length <= 0) {
                //       fetchUserInfo();
            }

            // setIdleTimeout();

            // betslipEle.addEventListener('click', resetLoginTimeout);
        } else {
            setShowLastLoginInfo(false);
            setShowUserInfo(false);
            setShowLogoutSucMsg(false);
            clearInterval(timerRef.current);
            timerRef.current = null;
            // betslipEle.removeEventListener('click', resetLoginTimeout);
        }
        return () => {
            clearInterval(timerRef.current);
            timerRef.current = null;
            // betslipEle.removeEventListener('click', resetLoginTimeout);
        };
    }, [accessToken, betslipMenu, accessTokenIdToken, userInfo]);

    // useEffect(() => {
    //     if (loginTimeoutRemind) {
    //         if (['1', '2'].includes(loginTimeoutRemind)) {
    //             setIsShowTODialog(true);
    //         } else {
    //             setIsShowTODialog(false);
    //         }
    //         setIsShowCSLogoutDialog((bool) => true);
    //         if (loginTimeoutRemind == '3') {
    //             logoutAsOnlyIB();
    //         }
    //     }
    // }, [loginTimeoutRemind]);

    const playBgmFunc = () => {
        const audio = document.getElementById('music1');
        audio?.load();
        audio?.play();
        //console.log('playBgmFunc at', new Date().toLocaleTimeString());
    };

    const initLogin = async () => {
        loginStatus.isBusy = true;
        await logoutIB();
        const initData = await getSessionId_IB(true);
        if (initData?.code === 415) {
            setLoginIncorrectMsg(415);
            loginStatus.isBusy = false;
            return;
        }
        if (initData?.sessionId) {
            return initData;
        }
    };

    const checkAccountPassword = async (account, password) => {
        loginStatus.isBusy = true;
        setShowLoginEKBAAnswer(false);
        setLoginIncorrectMsg('');
        setShowAnswerErrorMsg('');
        // has empty
        if (!account || !password) {
            setLoginIncorrectMsg('100001');
            loginStatus.isBusy = false;
            return;
        }

        const loginReqData = await initLogin();
        if (!loginReqData) return;

        const data = await checkAccountPasswordAPI_IB(i18n.language, account, password);

        if (data) {
            // Incorrect Login Name or Betting Account No.(100004)
            const dataObj = {};
            for (const item of data['DoAuthentAccPwdHashResult']) {
                dataObj[item.Key] = item.Value;
            }
            //eWallet account
            if (dataObj.eWalletOnly == 'Y') {
                setShowEwalletDialog(true);
                return;
            } else {
                setShowEwalletDialog(false);
            }
            // error mapping
            if (!(dataObj.login_error === '' && dataObj.login_status === '0')) {
                switch (dataObj.login_status) {
                    case '401':
                        setLoginIncorrectMsg(100401);
                        break;
                    case '402':
                        setLoginIncorrectMsg(100402);
                        break;
                    case '403':
                        setLoginIncorrectMsg(100403);
                        break;
                    case '420':
                        setLoginIncorrectMsg(100420);
                        break;
                    case '421': // wrong ac / pw
                        setLoginIncorrectMsg(100421);
                        break;
                    case '423': // account locked
                        setLoginIncorrectMsg(100002);
                        break;
                    case '425':
                        setLoginIncorrectMsg(100425);
                        break;
                    case '451':
                        setLoginIncorrectMsg(100451);
                        break;
                    case '452':
                        setLoginIncorrectMsg(100452);
                        break;
                    case '454':
                        setLoginIncorrectMsg(100454);
                        break;
                    case '455':
                        setLoginIncorrectMsg(100455);
                        break;
                    case '456':
                        setLoginIncorrectMsg(100456);
                        break;
                    case '479':
                        setLoginIncorrectMsg(100479);
                        break;
                    case '483':
                        setLoginIncorrectMsg(100483);
                        break;
                    case '499':
                        setLoginIncorrectMsg(100499);
                        break;
                    default:
                        setLoginIncorrectMsg(415);
                        break;
                }
                loginStatus.isBusy = false;
                setPassword('');
                return;
            }

            // if (data.code == 401) {
            //     const errData = JSON.parse(data.message);
            //     setLoginIncorrectMsg(errData.errorCode);
            //     loginStatus.isBusy = false;
            //     return;
            // }
            // Timeout or Other error
            if ([415, 416].includes(data.code)) {
                setLoginIncorrectMsg(data.code);
                loginStatus.isBusy = false;
                return;
            }
            // store to sessionStorage
            for (const item of data['DoAuthentAccPwdHashResult']) {
                sessionStorage.setItem(item['Key'], item['Value']);
            }

            // console.log('checkAccountPassword completed')
            showEkbaQuestion(false);
        } else {
            setLoginIncorrectMsg(415);
            loginStatus.isBusy = false;
        }
    };
    function success(pos) {
        const crd = pos.coords;
        setLatitude(crd.latitude);
        setLongitude(crd.longitude);
        // console.log('Your current position is:');
        // console.log(`Latitude : ${crd.latitude}`);
        // console.log(`Longitude: ${crd.longitude}`);
    }

    function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    const showEkbaQuestion = async (retrieveEkbaQuestion = true) => {
        if (retrieveEkbaQuestion) {
            await getSessionId_IB(true);
            const data = await getEkbaBySsoAuthen(i18n.language);
            const loginStatus = data.DoAuthentAccSSOTRResult.find((i) => i.Key == 'login_status')?.Value;
            if (loginStatus == '0') {
                for (const item of data['DoAuthentAccSSOTRResult']) {
                    sessionStorage.setItem(item['Key'], item['Value']);
                }
            } else {
                return false;
            }
        }

        const options = {
            timeout: 1000
        };

        navigator.geolocation.getCurrentPosition(success, error, options);
        const question = sessionStorage.getItem('ekbaQ');
        if (question) {
            setShowLoginEKBAAnswer(true);
            setLoginEKBAQuestion(question);
        } else {
            setLoginIncorrectMsg('OTHER');
        }
    };

    const checkEKBAAnswer = async (answer, setAPILoading) => {
        loginStatus.isBusy = true;
        // if (!EKBAAnswerReqData) {
        //     loginStatus.isBusy = false;
        //     return;
        // }
        setShowAnswerErrorMsg('');

        let network = 'X';

        if (navigator.connection != undefined) {
            if (navigator.connection.type) {
                if (navigator.connection.type == 'cellular') {
                    network = 'M';
                }
                if (navigator.connection.type == 'wifi' || navigator.connection.type == 'wimax') {
                    network = 'W';
                }
            }
        }

        let data = await checkEKBAAnswerAPI_IB(answer, i18n.language, latitude, longitude, network);

        if (data) {
            // parse data object
            const dataObj = {};
            for (const item of data['DoAuthentEKBA_V2TRResult']) {
                //DoAuthentEKBA_V2TRResult
                dataObj[item['Key']] = item['Value'];
                sessionStorage.setItem(item['Key'], item['Value']);
            }

            if (sessionStorage.getItem('sso_web_id_hashed')) {
                sessionStorage.setItem('hWebID', sessionStorage.getItem('sso_web_id_hashed'));
            }

            if (data.code && data.code == 401) {
                // api error
                setLoginIncorrectMsg('100002');
                setShowLoginEKBAAnswer(false);
                setShowAnswerErrorMsg('');
                loginStatus.isBusy = false;
                setAPILoading(false);
                return;
            }
            if (dataObj.login_status === '427' && dataObj.retryCount) {
                // wrong ekba answer
                setLoginEKBAQuestion(dataObj.ekbaQ);
                const retryCount = +dataObj.retryCount;
                setShowAnswerErrorMsg({
                    retry: true,
                    retryCount: retryCount
                });
                setAPILoading(false);
                return;
            } else if (dataObj.login_status == '164') {
                //not speedbet customer
                setShowAnswerErrorMsg({
                    login_status: dataObj.login_status,
                    login_error: i18n.language == 'ch' ? dataObj.login_error_ch : dataObj.login_error,
                    disableEkbaConfirm: true
                });

                setAPILoading(false);
                return;
            } else if (dataObj.login_status == '103') {
                //another session login
                setShowAnswerErrorMsg({
                    login_status: dataObj.login_status,
                    login_error: i18n.language == 'ch' ? dataObj.login_error_ch : dataObj.login_error,
                    disableEkbaConfirm: true
                });
                setAPILoading(false);
                return;
            } else if (dataObj.login_status != '9' && dataObj.login_status != '0') {
                // other errors from ekba
                setShowAnswerErrorMsg({
                    login_status: dataObj.login_status,
                    login_error: i18n.language == 'ch' ? dataObj.login_error_ch : dataObj.login_error
                });

                setAPILoading(false);
                return;
            }

            // set fake tokens
            setAccessToken('abc123');
            setRefreshToken('abc123');
            setTokenToSession({
                accessToken: 'abc123',
                refreshToken: 'abc123',
                idToken: 'abc123',
                login_account: loginAccount
            });

            if (accessToken?.id_token) {
                setAccessTokenIdToken('abc123');
            }
            setIsSpeedbetCust(dataObj.speedbet_cust);

            //set balance from ekba answer reply
            const balance = parseFloat(sessionStorage.getItem('balance'));

            if (!isNaN(balance)) {
                setBalance(balance.toLocaleString(undefined, { minimumFractionDigits: 2 }));
            } else {
                setBalance('---');
            }

            if (getPersonalSettings('accountInfo', 'accountBalance') == 1) {
                Cookies.setData('isMaskAmount', false);
            } else {
                Cookies.setData('isMaskAmount', true);
            }

            // set customer segment
            window.sessionStorage.setItem('is_logon', 1);
            setCustomerSegment();

            setUserInfoIB();

            callWAReq();
            glassboxSetUserInfo();

            setIsShowTandC(true);
            removeCurrentSessionRecords();
            setShowLoginEKBAAnswer(false);

            loginStatus.isBusy = false;
        } else {
            setLoginIncorrectMsg('OTHER');
        }
        setAPILoading(false);
        setIdleTimeout();
    };

    const setIsSpeedbetCust = (speedbet_cust) => {
        console.debug('betBaskets.length, speedbet_cust', betBaskets.length, speedbet_cust);
        if (speedbet_cust === 'Y' && betBaskets.length != window.globalConfig.SPEEDBET_BASKET.length) {
            setIsSpeedbet(true);
            let newBaskets = Array(Number(window.globalConfig.SPEEDBET_BASKET.length)).fill([]);
            newBaskets[0] = betBaskets[0];
            setBetBaskets(newBaskets);
        }
    };

    const resetLoginTimeout = async (callAPI = true) => {
        // console.log('resetLoginTimeout timeoutRef.current', timeoutRef.current);
        if (callAPI) {
            const rsp = await ticketExtendSession_IB();
            const rsp2 = await extendSession_IB();
            if (rsp && rsp2) {
                // timeoutRef.current = 0;
                setIdleTimeout();
                setLoginTimeoutRemind('');
            } else {
                // timeoutRef.current = 0;
                // setLoginTimeoutRemind('');
                // set api error?
            }
        } else {
            // timeoutRef.current = 0;
            setIdleTimeout();
            setLoginTimeoutRemind('');
            setIsShowTODialog(false);
        }
        //console.log('resetLoginTimeout callAPI', callAPI);
    };

    const closeLoginIncorrectMsg = () => {
        setLoginIncorrectMsg(null);
    };

    const logoutStateFn = () => {
        setLoginTimeoutRemind('');
        setAccessToken('');
        setRefreshToken('');
        removeToken();
        setUserInfo({});
        removeCurrentSessionRecords();
    };

    const isLogon =
        window.sessionStorage.getItem('access_token') != null && window.sessionStorage.getItem('access_token') != '';

    const isPCardUser =
        window.sessionStorage.getItem('PCardTier') != null &&
        window.sessionStorage.getItem('PCardTier') != '' &&
        window.sessionStorage.getItem('PCardTier') != 'NonPcard';

    const setCustomerSegment = () => {
        const validateFunc = (item, defaultVal) => {
            return /^[YN\d]+$/.test(item) ? item : defaultVal;
        };

        const priority = validateFunc(sessionStorage.getItem('priority_card'), '0');
        let cbp = validateFunc(sessionStorage.getItem('cbp_seg'), '00000');
        if (cbp.length < 5) {
            cbp = new Array(6 - cbp.length).join('0') + cbp;
        }
        const racing = validateFunc(sessionStorage.getItem('racing_part'), 'N');
        const football = validateFunc(sessionStorage.getItem('football_part'), 'N');
        const marksix = validateFunc(sessionStorage.getItem('m6_part'), 'N');
        const member = validateFunc(sessionStorage.getItem('member_type'), '0');
        const ageGroup = validateFunc(sessionStorage.getItem('age_group'), '00');
        const gender = validateFunc(sessionStorage.getItem('gender'), '0');
        const bettingAC = validateFunc(sessionStorage.getItem('betting_ac_ind'), 'N');
        const speedbet = validateFunc(sessionStorage.getItem('speedbet_cust'), '0');
        const footballLive = validateFunc(sessionStorage.getItem('football_live_ind'), 'N');
        const customerSegment =
            priority + cbp + racing + football + marksix + member + ageGroup + gender + bettingAC + speedbet;

        sessionStorage.setItem('customerSegment', customerSegment);
        sessionStorage.setItem('customerHash', sessionStorage.getItem('hWebID'));
        sessionStorage.setItem('webTV', footballLive == 'Y');

        const expires = new Date(new Date().getTime() + 60 * 60 * 1000).toUTCString();
        document.cookie = `custProInBet=%3A%3A%3A%3A%3A%3A${customerSegment}%3A;expires=${expires};domain=${window.domainName};path=/`;
    };

    const setUserInfoIB = () => {
        const userInfo = {
            bettingProfile: {
                personalInfo: {
                    bettingAccountNumber: sessionStorage.getItem('account'),
                    firstNameEn: sessionStorage.getItem('firstname'),
                    firstNameZhHk: sessionStorage.getItem('firstname'),
                    lastNameEn: sessionStorage.getItem('lastname'),
                    lastNameZhHk: sessionStorage.getItem('lastname')
                }
            }
        };
        setUserInfo(userInfo);
    };

    const loginHigh = () => {
        console.debug('loginHigh for jcrw redirect...');
        // set fake tokens
        setAccessToken('abc123');
        setRefreshToken('abc123');
        setTokenToSession({
            accessToken: 'abc123',
            refreshToken: 'abc123',
            idToken: 'abc123',
            login_account: sessionStorage.getItem('login_account')
        });
        if (accessToken?.id_token) {
            setAccessTokenIdToken('abc123');
        }
        setIsSpeedbetCust(sessionStorage.getItem('speedbet_cust'));

        //set balance from ekba answer reply
        const balance = parseFloat(sessionStorage.getItem('balance'));

        if (!isNaN(balance)) {
            setBalance(balance.toLocaleString(undefined, { minimumFractionDigits: 2 }));
        } else {
            setBalance('---');
        }
        console.debug('balance', balance);

        if (getPersonalSettings('accountInfo', 'accountBalance') == 1) {
            Cookies.setData('isMaskAmount', false);
        } else {
            Cookies.setData('isMaskAmount', true);
        }

        setUserInfoIB();

        callWAReq(); // need call??
        glassboxSetUserInfo(); // need call??

        setIsShowTandC(false);
        // removeCurrentSessionRecords();
        setShowLoginEKBAAnswer(false);

        loginStatus.isBusy = false;

        // set idle timer
        setIdleTimeout(true);
    };

    return {
        checkAccountPassword,
        showLoginEKBAAnswer,
        setShowLoginEKBAAnswer,
        loginEKBAQuestion,
        loginIncorrectMsg,
        checkEKBAAnswer,
        loginAccount,
        setLoginAccount,
        password,
        setPassword,
        resetLoginTimeout,
        accessToken,
        logoutStateFn,
        setLoginTimeoutRemind,
        loginTimeoutRemind,
        isShowTODialog,
        setIsShowTODialog,
        isShowCSLogoutDialog,
        setIsShowCSLogoutDialog,
        isShowTandC,
        setIsShowTandC,
        userInfo,
        showUserInfo,
        setShowUserInfo,
        showLastLoginInfo,
        setShowLastLoginInfo,
        showAnswerErrorMsg,
        showLogoutSucMsg,
        setShowLogoutSucMsg,
        closeLoginIncorrectMsg,
        isShowLogoutBetText,
        setIsShowLogoutBetText,
        willOpenUrl,
        setWillOpenUrl,
        showLogoutReminder,
        setShowLogoutReminder,
        isLogon,
        isPCardUser,
        showEkbaQuestion,
        LoginAPILoading,
        setLoginAPILoading,
        showEwalletDialog,
        setShowEwalletDialog,
        sessionIdleTimeRef,
        warningIdleTimeRef,
        warningIdleTimeRef2,
        loginHigh
    };
};

export const clearLoginSessionStorage = () => {
    sessionStorage.removeItem('is_logon');
    sessionStorage.removeItem('full_domain');
    sessionStorage.removeItem('session_id');
    sessionStorage.removeItem('gu_id');
    sessionStorage.removeItem('bw_id');
    sessionStorage.removeItem('sso_enabled');
    sessionStorage.removeItem('sso_remove_token_on_timeout');
    sessionStorage.removeItem('account');
    sessionStorage.removeItem('webID');
    sessionStorage.removeItem('acc_name');
    sessionStorage.removeItem('sex');
    sessionStorage.removeItem('football_live_ind');
    sessionStorage.removeItem('ekbaLang');
    sessionStorage.removeItem('ekbaID');
    sessionStorage.removeItem('ekbaQ');
    sessionStorage.removeItem('eWalletOnly');
    sessionStorage.removeItem('firstname');
    sessionStorage.removeItem('lastname');
    sessionStorage.removeItem('login_status');
    sessionStorage.removeItem('login_error');
    sessionStorage.removeItem('seq_no');
    sessionStorage.removeItem('balance');
    sessionStorage.removeItem('acc_type');
    sessionStorage.removeItem('autoAccept');
    sessionStorage.removeItem('refresh_rate');
    sessionStorage.removeItem('as_id');
    sessionStorage.removeItem('last_login_date');
    sessionStorage.removeItem('last_login_time');
    sessionStorage.removeItem('eft_flag');
    sessionStorage.removeItem('eft_number');
    sessionStorage.removeItem('is_new_acc');
    sessionStorage.removeItem('speedbet_after');
    sessionStorage.removeItem('channelPara');
    sessionStorage.removeItem('primarynba_status');
    sessionStorage.removeItem('primarynba_bankcode');
    sessionStorage.removeItem('primarynba_bankacno');
    sessionStorage.removeItem('primarynba_activation');
    sessionStorage.removeItem('primarynba_bankname');
    sessionStorage.removeItem('primarynba_bankname_en');
    sessionStorage.removeItem('primarynba_bankname_ch');
    sessionStorage.removeItem('secondarynba_status');
    sessionStorage.removeItem('secondarynba_bankcode');
    sessionStorage.removeItem('secondarynba_bankacno');
    sessionStorage.removeItem('secondarynba_activation');
    sessionStorage.removeItem('secondarynba_bankname');
    sessionStorage.removeItem('secondarynba_bankname_en');
    sessionStorage.removeItem('secondarynba_bankname_ch');
    sessionStorage.removeItem('EFTWhiteListed');
    sessionStorage.removeItem('EFTWithdrawalFeeEnable');
    sessionStorage.removeItem('EFTDepositFeeEnable');
    sessionStorage.removeItem('EFTServiceFee');
    sessionStorage.removeItem('priority_card');
    sessionStorage.removeItem('PCardTier');
    sessionStorage.removeItem('cbp_seg');
    sessionStorage.removeItem('racing_part');
    sessionStorage.removeItem('football_part');
    sessionStorage.removeItem('m6_part');
    sessionStorage.removeItem('member_type');
    sessionStorage.removeItem('age_group');
    sessionStorage.removeItem('gender');
    sessionStorage.removeItem('betting_ac_ind');
    sessionStorage.removeItem('speedbet_cust');
    sessionStorage.removeItem('have_webcast_access');
    sessionStorage.removeItem('hasValidAutopayDepositAcc');
    sessionStorage.removeItem('dda_shortBankCode');
    sessionStorage.removeItem('betLimitControlEnable');
    sessionStorage.removeItem('dda_transferLimit');
    sessionStorage.removeItem('mobile_country');
    sessionStorage.removeItem('mobile_num');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('id_token');
    sessionStorage.removeItem('login_account');
    //sessionStorage.removeItem('customerSegment');
    //sessionStorage.removeItem('customerHash');
    //sessionStorage.removeItem('hWebID');
    sessionStorage.removeItem('webTV');
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('cJwt');
    sessionStorage.removeItem('openBets');
    sessionStorage.removeItem('openBetVer');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('id_token');
};
