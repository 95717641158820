import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AddSlipBtn from '../Common/AddSlipBtn';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import { ClearBetSels, SetBetSelect, IsBetSelected, GetRaceCnt } from '../Common/RacingBetline';
import { isPoolDefined } from '../Common/Common';
import OddsDropLegend from '../Common/OddsDropLegend';
import CWinBase from '../Common/CWINBase';
import { RacingContext } from '../../Home/RacingPage';
import CheckBox from '../../Common/ui-components/CheckBox';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';
import { isStartSellpmPool } from '../Common/Common';

const CWAAllHeader = () => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    let lang = i18n.language;
    let lblA1 = '';
    let lblA2 = '';
    let lblA3 = '';
    let pool = mtg.pmPools.filter((item) => item.oddsType == 'CWA')[0];
    if (pool != null) {
        let a1 = pool.cWinSelections.filter((data) => data.composite == 'A1')[0];
        lblA1 = a1 != null ? a1['name_' + lang] : '';
        let a2 = pool.cWinSelections.filter((data) => data.composite == 'A2')[0];
        lblA2 = a2 != null ? a2['name_' + lang] : '';
        let a3 = pool.cWinSelections.filter((data) => data.composite == 'A3')[0];
        lblA3 = a3 != null ? a3['name_' + lang] : '';
    }

    return (
        <>
            <div className="rc-cwa-all-table-header">{t('LB_RC_ODDS_TABLE_RACENO')}</div>
            <div className="rc-cwa-all-table-header" style={{ lineHeight: 'unset' }}>
                A1
                <br />
                {lblA1}
            </div>
            <div className="rc-cwa-all-table-header" style={{ lineHeight: 'unset' }}>
                A2
                <br />
                {lblA2}
            </div>
            <div className="rc-cwa-all-table-header" style={{ lineHeight: 'unset' }}>
                A3
                <br />
                {lblA3}
            </div>
            <div className="rc-cwa-all-table-header"></div>
        </>
    );
};

const CWAAllOdds = ({ bg, raceNo, pool, sel }) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);

    const handleClickOdds = (_type, _race, _col, _no, e) => {
        let isChecked = e.target.checked;
        SetBetSelect(_type, _race, _col, _no, isChecked);
        if (GetRaceCnt() > 6) {
            SetBetSelect(_type, _race, _col, _no, false);
            alert(t('LB_RC_CWIN_EXCEED_MAX_LEG'));
        }
        context.racingFuncs.resetAllUpFormula();
        context.racingFuncs.resetInvCal();
    };

    let enable = false;
    let cOdds = '---';
    let oddsClass = '';
    if (pool != null) {
        enable = isStartSellpmPool(pool);//pool.status == 'START_SELL';
        let tempCWAodds = pool.oddsNodes.filter((data) => data.combString == sel)[0];

        if (pool.status.indexOf('Refunded') >= 0) {
        } else if (tempCWAodds == null) {
            cOdds = '';
        } else if (tempCWAodds.oddsValue == 'SCR') {
            cOdds = t('LB_RC_SCR');
            enable = false;
        } else {
            cOdds = tempCWAodds.oddsValue;
            if (tempCWAodds.hotFavourite) {
                oddsClass = 'favourite';
            } else {
                const isPresales = context?.content?.isPresales;
                oddsClass = isPresales
                    ? ''
                    : tempCWAodds.oddsDropValue >= 50
                    ? 'od-50'
                    : tempCWAodds.oddsDropValue >= 20
                    ? 'od-20'
                    : '';
            }
        }
    }
    return (
        <div className={`rc-checkbox rc-odds ${bg}`} id={`hr_CWAALLALUP_odds_${raceNo}_${sel}`}>
            <CheckBox
                disabled={!enable}
                id={`cwinleg_CWA_${raceNo}_${sel}`}
                onChange={(e) => handleClickOdds('CWA', raceNo, 'leg', sel, e)}
                defaultChecked={IsBetSelected('CWA', raceNo, 'leg', sel)}
            />
            <div className={`${oddsClass}`} id={`odds_CWAALLALUP_${raceNo}_${sel}`} clickable="false">
                <a>{cOdds}</a>
            </div>
        </div>
    );
};

const CWAAllTable = () => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;

    const [expandCwinTable, setExpandCwinTable] = useState([]);

    const toggleCwinTable = (i) => {
        expandCwinTable[i] = !expandCwinTable[i];
        setExpandCwinTable([...expandCwinTable]);
    };

    let tmpTable = [];
    let maxRace = Math.max(...mtg.races.map((x) => x.no));
    let bgCssCnt = 0;
    for (let i = 1; i <= maxRace; i++) {
        if (mtg.races.filter((x) => x.no === i).length == 0) {
            continue;
        }
        let pool = mtg.pmPools.filter((item) => item.leg.races[0] == i && item.oddsType == 'CWA')[0];
        let bgCss = bgCssCnt++ % 2 == 0 ? 'oddbg' : 'evenbg';
        let isDefined = isPoolDefined('CWA', i, mtg.poolInvs);

        tmpTable.push(
            <>
                <div className={bgCss} id={`hr_CWAALLALUP_raceno_${i}`}>
                    {i}
                </div>
                <CWAAllOdds bg={bgCss} raceNo={i} pool={pool} sel="A1" />
                <CWAAllOdds bg={bgCss} raceNo={i} pool={pool} sel="A2" />
                <CWAAllOdds bg={bgCss} raceNo={i} pool={pool} sel="A3" />
                {isDefined ? (
                    <div
                        className={`rc-cwa-all-expand ${bgCss}`}
                        onClick={(e) => {
                            toggleCwinTable(i);
                        }}
                    >
                        <div
                            style={{ display: 'inline-block', marginTop: 6 }}
                            className={`switch-btn-icon ${expandCwinTable[i] ? 'close-btn-icon' : 'open-btn-icon'}`}
                        ></div>
                        {/* <button className="rc-cwa-all-table-button" id={`hr_CWAALLALUP_button_${i}`}>
                                <span className={expandCwinTable[i] ? "minus" : "plus"}></span><span></span>
                            </button> */}
                    </div>
                ) : (
                    <div className={bgCss}></div>
                )}
                <div className={`rc-cwa-all-single-table ${expandCwinTable[i] ? '' : 'hide'}`}>
                    {isDefined && <CWinBase betType="CWA" raceNo={i} hideCheckbox={true} disableQuickBet={true} />}
                </div>
            </>
        );
    }

    return (
        <div className="rc-cwa-all-table" id={`cwin_table_CWAALLALUP`}>
            <CWAAllHeader />
            {tmpTable}
        </div>
    );
};

const CWAAllAlup = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const [scnUpdate, setScreenUpdate] = useState({});

    const clearClickSelection = () => {
        if (GetRaceCnt() > 0 && window.confirm(t('LB_RC_CWIN_CONFIRM_RESET'))) {
            ClearBetSels();
            context.racingFuncs.resetAllUpFormula();
            setScreenUpdate({ ...scnUpdate });
        }
    };

    if (mtg == null) {
        return <Loading />;
    } else {
        let pageConfig = context.pageConfig;

        return (
            <section className={context.content.page}>
                <InvestmentCalculator enable={true} />
                <RefreshTime product="racing" />
                <div className="rc-cwa-all-head">
                    <div>{t('LB_RACE_CWA')}</div>
                    <div className="reset-btn">
                        <button className="race-btn mr-12 cta_hr" onClick={clearClickSelection}>
                            {t('LB_RC_RESET')}
                        </button>
                    </div>
                </div>
                <CWAAllTable />
                <OddsDropLegend />
                <div className="rc-cwa-all-remarks">
                    <div>{t('LB_RC_CWIN_REMARKS')}</div>
                    <div className="rc-cwa-all-m">
                        <button className="race-btn mr-12 cta_hr" onClick={clearClickSelection}>
                            {t('LB_RC_RESET')}
                        </button>
                    </div>
                </div>
                <div className="multi-leg-footer-betSlip">
                    <div>
                        <AddSlipBtn />
                    </div>
                </div>
                <InvestmentCalculator enable={!context.windowSize.isMobileSize} />
            </section>
        );
    }
};
export default CWAAllAlup;
