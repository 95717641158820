import { createTournData, createTournament, updateTourn } from './createTourn';
import { isTournamentPage } from '../../Common/Common';
import { trimTournamentByTourn } from '../../Common/Common';

export const parseNewTourn = (message, topicInfo, contentRef) => {
    let tourn = isTournamentPage(contentRef.current.page) ? createTournData(message) : createTournament(message);
    let idx = contentRef.current.rawTournList.findIndex(x => x.id===message.id);
    if(idx < 0) {
        contentRef.current.rawTournList.push(tourn);
    }
    else {
        contentRef.current.rawTournList[idx] = tourn;
    }

    // if( isTournamentPage(contentRef.current.page) ) {
    //     let tourn = contentRef.current.tournData?.tournaments.find(x => x.id == message.id);
    //     if(tourn){
    //         updateTourn(tourn, message);
    //         if(["ALL", "TOURN"].includes(contentRef.current.selection.type)) {
    //             contentRef.current.selection.tabs = trimTournamentByTourn(contentRef.current.tournData.tournaments);
    //         }
    //     }
    // }
}