import {parseFootballMessage} from './parseFootballMessage'
import {parseRacingMessage} from './parseRacingMessage'

export const parseMessage = ( topic, message, betBasketsRef) =>{
    // WIP
    if(topic.includes('racing')){
        parseRacingMessage(topic, message, betBasketsRef)
    }else{
        parseFootballMessage(topic, message, betBasketsRef)
    }
}