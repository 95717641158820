import React, { useEffect, useState, useContext } from 'react';
import { alupFormulaItem, nPr, nCr } from '../../Common/GlobalFunc';
import { GetRaceConfig } from '../../Common/ConfigHelper';

const betSels = {
    sels: {},
    subT: "S",
    rand: false
};

export const useBetSels = () => {
    console.log(betSels)
    return { betSels };
}

export function ClearBetSels() {
    betSels.sels = {};
    betSels.subT = "S";
    betSels.rand = false;
}

export function ClearBetRaces(race) {
    let selKeys = Object.keys(betSels.sels);
    for(let i = 0; i < selKeys.length; i++){
        let tmp = selKeys[i].split('_')[1];
        if(tmp === race.toString()){
            delete betSels.sels[selKeys[i]];
        }
    }
}
export function ClearBetByBetTypeAndRaces(bType, race) {
    let selKeys = Object.keys(betSels.sels);
    for(let i = 0; i < selKeys.length; i++){
        let [b, r] = selKeys[i].split('_');
        let curBetTypes = getBetTypes(bType)
        if(curBetTypes.includes(bType) && r === race.toString()){
            delete betSels.sels[selKeys[i]];
        }
    }
}

const getBetTypes = (betType) => {
    if(['QIN','QPL'].includes(betType)){
        return [betType, 'QQP']
    }
    else if(['WIN','PLA'].includes(betType)){
        return [betType, 'W-P']
    }
    return [betType]
}

export function ClearBetByBetTypeAndRacesAndRunner( race , runner) {
    let selKeys = Object.keys(betSels.sels);
    for(let i = 0; i < selKeys.length; i++){
        let [b, r, leg, runNo] = selKeys[i].split('_');
        if(r === race.toString() && runNo == runner ){
            delete betSels.sels[selKeys[i]];
        }
    }
}

export function GetBetSels() {
    return betSels.sels;
}

export function GetBetSelsSubType() {
    return betSels.subT;
}

export function GetBetSelsRandom() {
    return betSels.rand;
}

export function UnsetBetSelsRandom() {
    betSels.rand = false;
}

export function GetBetsByCriteria(betType, raceNo, col) {
    let obj = [];
    for (let idx in betSels.sels) {
        if (betSels.sels[idx]) {
            let arr = idx.split('_');
            let b = arr[0];
            let r = parseInt(arr[1]);
            let c = arr[2];
            if ( (betType=='' || b==betType) && (raceNo<=0 || r==raceNo) && c==col ) {
                obj.push(idx);
            }
        }
    }
    return obj;
}

export function hasSelectedField(betType, raceNo, col) {
    let obj = GetBetsByCriteria(betType, raceNo, col);
    if ( obj.length==1 && obj[0].split('_')[3]=="F" )
        return true;
    return false;
}

export function SetBetSels(sels) {
    betSels.sels = sels;
    betSels.rand = false;
}

export function GetRaceCnt() {
    let obj = [];
    for (let idx in betSels.sels) {
        if (betSels.sels[idx]) {
            let arr = idx.split('_');
            obj.push(arr[1]);
        }
    }
    return [...new Set(obj)].length;
}

export function GetJTCSelectdRace() {
    let obj = [];
    for (let idx in betSels.sels) {
        if (betSels.sels[idx]) {
            let arr = idx.split('_');
            let bType = arr[0];
            let raceNo = arr[1];
            let tmpObj = obj.filter(x=> x.raceNo===raceNo);
            if ( tmpObj.length>0 ) {
                if ( !tmpObj[0].bTypes.includes(bType) ) {
                    tmpObj[0].bTypes.push(bType);
                }
            }
            else {
               obj.push({ raceNo: raceNo, bTypes: [ bType ] });
            }
        }
    }
    return obj;
}

export function GetFirstBetType() {
    for (let idx in betSels.sels) {
        if (betSels.sels[idx]) {
            let arr = idx.split('_');
            let bType = arr[0];
            return bType
        }
    }
    return undefined
}

export function GetSelectedBet(betType, raceNo, col) {
    let obj = [];
    for (let idx in betSels.sels) {
        if (betSels.sels[idx]) {
            let arr = idx.split('_');
            let bType = arr[0];
            let rNo = parseInt(arr[1]);
            let c = arr[2];
            let sel = arr[3];
            if (betType != "*" && betType != bType)
                continue;
            if (raceNo != "*" && raceNo != rNo)
                continue;
            if (col != c)
                continue;

            obj.push(sel == 'F' ? sel : parseInt(sel));
        }
    }
    return obj;
}

export function IsBetSelected(bType, raceNo, col, sel) {
    return betSels.sels[bType + '_' + raceNo + '_' + col + '_' + sel];
}

export function SetBetSelect(bType, raceNo, col, sel, val) {
    if (sel == 'F' && val) {
        for (let idx in betSels.sels) {
            if (idx.indexOf(bType + "_" + raceNo + "_" + col + '_') >= 0)
                delete betSels.sels[idx];
        }
    }
    betSels.rand = false
    betSels.sels[bType + '_' + raceNo + '_' + col + '_' + sel] = val;
}

export function deleteBetSelectByFresh(bType, raceNo) {
    for (let idx in betSels.sels) {
        if (betSels.sels[idx]) {
            let arr = idx.split('_');
            let bTp = arr[0];
            let rNo = parseInt(arr[1]);
            if(bType == bTp && raceNo == rNo) {
                delete betSels.sels[idx];
            }
        }
    }
}

export function SetRandomBetSelect(bType, raceNo, col, sel, val) {
    if (sel == 'F' && val) {
        for (let idx in betSels.sels) {
            if (idx.indexOf(bType + "_" + raceNo + "_" + col + '_') >= 0)
                delete betSels.sels[idx];
        }
    }
    betSels.sels[bType + '_' + raceNo + '_' + col + '_' + sel] = val;
}

export function SetBetSelectSingle(bType, raceNo, col, sel, val) {
    if (val) {
        delete betSels.sels[bType + "_" + raceNo + "_b1_" + sel];
        delete betSels.sels[bType + "_" + raceNo + "_b2_" + sel];
        delete betSels.sels[bType + "_" + raceNo + "_b3_" + sel];
        delete betSels.sels[bType + "_" + raceNo + "_leg_" + sel];
        for (let idx in betSels.sels) {
            if (idx.indexOf(bType + "_" + raceNo + "_" + col + '_') >= 0)
                delete betSels.sels[idx];
        }
    }
    betSels.rand = false
    betSels.sels[bType + '_' + raceNo + '_' + col + '_' + sel] = val;
}

export function SetBetSelectBanker(bType, raceNo, col, sel, val) {
    if (val) {
        delete betSels.sels[bType + "_" + raceNo + "_b1_" + sel];
        delete betSels.sels[bType + "_" + raceNo + "_b2_" + sel];
        delete betSels.sels[bType + "_" + raceNo + "_b3_" + sel];
        delete betSels.sels[bType + "_" + raceNo + "_leg_" + sel];
        for (let idx in betSels.sels) {
            if (col != 'leg' && idx.indexOf(bType + "_" + raceNo + "_" + col + '_') >= 0)
                delete betSels.sels[idx];
        }
    }
    betSels.rand = false
    betSels.sels[bType + '_' + raceNo + '_' + col + '_' + sel] = val;
}

export function SetSubType(subType) {
    betSels.subT = subType;
}

export function ReplaceBetSelect(bType, newBType) {
    for (let idx in betSels.sels) {
        if (idx.indexOf(bType) >= 0) {
            let newIdx = idx.replace(bType, newBType);
            let val = betSels.sels[idx];
            delete betSels.sels[idx];
            betSels.sels[newIdx] = val;
        }
    }
}

export const calBet = (contentRef) => {
    let rcL = GetBetSels();
    if (rcL == null) {
        return 0
    }

    let allSels = GetCalBetObj();
    let selections = 0;
    if ( contentRef.current.page.indexOf("ALUP")>=0 || (contentRef.current.page.indexOf("JTCOMBO")>=0 && !!contentRef.current.alupFormula)  ) {
        selections = CalculateAlupBet(contentRef.current.meeting, contentRef.current.alupData, contentRef.current.alupFormula, allSels);
    }
    else {
        selections = CalculateBet(contentRef.current.meeting, allSels);
    }
    return selections;
}

export function GetCalBetObj() {
    let allSels = [];
    for (let idx in betSels.sels) {
        if (betSels.sels[idx]) {
            let arr = idx.split('_');
            let bType = arr[0];
            let rNo = parseInt(arr[1]);
            let col = arr[2];
            let sel = arr[3];
            let cnt = 1;
            if (sel == 'F') {
                cnt = 'F';
            }
            if (allSels[rNo] == null) {
                allSels[rNo] = {
                    b1Cnt: 0,
                    b2Cnt: 0,
                    b3Cnt: 0,
                    legCnt: {}
                };
            }
            switch (col) {
                case 'leg':
                    if (allSels[rNo].legCnt[bType] == null)
                        allSels[rNo].legCnt[bType] = cnt;
                    else
                        allSels[rNo].legCnt[bType] += cnt;
                    break;
                case 'b1':
                    allSels[rNo].b1Cnt += cnt;
                    break;
                case 'b2':
                    allSels[rNo].b2Cnt += cnt;
                    break;
                case 'b3':
                    allSels[rNo].b3Cnt += cnt;
                    break;
            }
        }
    }
    return allSels;
}

export function getFieldCount(mtg, raceNo, allSels) {
    let run = mtg.races.filter((data) => data.no == raceNo)[0].runners
    if (run != null) {
        let cnt = run.filter((data) => !data.status.toUpperCase().match(/^(SCRATCHED|SCRATCHEDRESERVED|STANDBY)$/)).length;
        if (betSels.subT != "MB")
            cnt -= (allSels[raceNo].b1Cnt + allSels[raceNo].b2Cnt + allSels[raceNo].b3Cnt);
        return cnt;
    }
    return 0;
}

export function CalculateBet(mtg, allSels) {
    let selections = 0;
    let maxRace = Math.max(...mtg.races.map(x=>x.no));
    for (let i = 1; i <= maxRace; i++) {
        if (allSels[i] == null)
            continue;
        for (let idx in allSels[i].legCnt) {
            if (allSels[i].legCnt.hasOwnProperty(idx)) {
                let cnt = allSels[i].legCnt[idx];
                if (cnt == 'F') {
                    cnt = getFieldCount(mtg, i, allSels);
                }
                selections = getCnt(selections, mtg, idx, i, betSels.subT, allSels[i].b1Cnt, allSels[i].b2Cnt, allSels[i].b3Cnt, cnt);
            }
        }
    }
    return selections;
}

function getCnt(selections, mtg, betType, raceNo, subType, b1, b2, b3, cnt) {
    switch (betType) {
        case 'WIN':
        case 'PLA':
        case 'CWA':
        case 'CWB':
        case 'CWC':
            selections += cnt;
            break;
        case 'W-P':
            selections += cnt * 2;
            break;
        case 'IWN':
            selections += 1;
            break;
        case 'QIN':
        case 'QPL':
            selections += CalcQBet(b1, cnt);
            break;
        case 'QQP':
            selections += CalcQBet(b1, cnt) * 2;
            break;
        case 'TRI':
            selections += CalcTBet(b1, cnt);
            break;
        case 'F-F':
        case 'FF':
            selections += CalcFBet(b1, cnt);
            break;
        case 'FCT':
            switch (subType) {
                case "S":
                    selections += 1;
                    break;
                case "M":
                    selections += nPr(cnt, 2);
                    break;
                case "B":
                    selections = nPr(cnt, 1);
                    break;
                case "BM":
                    selections = nCr(cnt, 1) * 2;
                    break;
                case "MB":
                    selections = CalcMBBet(mtg, raceNo, 2);
                    break;
            }
            break;
        case 'TCE':
            switch (subType) {
                case "S":
                    selections += 1;
                    break;
                case "M":
                    selections += nPr(cnt, 3);
                    break;
                case "B":
                    selections = nPr(cnt, 3 - b1 - b2);
                    break;
                case "BM":
                    selections = nCr(cnt, 3 - b1 - b2) * 6;
                    break;
                case "MB":
                    selections = CalcMBBet(mtg, raceNo, 3);
                    break;
            }
            break;
        case 'QTT':
            switch (subType) {
                case "S":
                    selections += 1;
                    break;
                case "M":
                    selections += nPr(cnt, 4);
                    break;
                case "B":
                    selections = nPr(cnt, 4 - b1 - b2 - b3);
                    break;
                case "BM":
                    selections = nCr(cnt, 4 - b1 - b2 - b3) * 24;
                    break;
                case "MB":
                    selections = CalcMBBet(mtg, raceNo, 4);
                    break;
            }
            break;
        case "DBL":
        case "TBL":
        case "SixUP":
        case "6UP":
            selections = selections == 0 ? 1 : selections;
            selections *= cnt;
            break;
        case "DT":
        case "D-T":
        case "TT":
        case "T-T":
            selections = selections == 0 ? 1 : selections;
            selections *= CalcTBet(b1, cnt);
            break;
    }
    return selections;
}

function CalcQBet(bCnt, sCnt) {
    if (bCnt == 0) {
        return nCr(sCnt, 2);
    } else {
        return sCnt;
    }
}

function CalcTBet(bCnt, sCnt) {
    if (bCnt == 0) {
        return nCr(sCnt, 3);
    } else if (bCnt == 1) {
        return nCr(sCnt, 2);
    } else {
        return sCnt;
    }
}

function CalcFBet(bCnt, sCnt) {
    if (bCnt == 0) {
        return nCr(sCnt, 4);
    } else if (bCnt == 1) {
        return nCr(sCnt, 3);
    } else if (bCnt == 2) {
        return nCr(sCnt, 2);
    } else {
        return sCnt;
    }
}

function CalcMBBet(mtg, raceNo, size) {
    let bets = { "b1": [], "b2": [], "b3": [], "leg": [] };
    for (let idx in betSels.sels) {
        if (betSels.sels[idx]) {
            let arr = idx.split('_');
            let rNo = parseInt(arr[1]);
            let col = arr[2];
            let sel = arr[3];
            if (raceNo!=rNo) {
                continue;
            }
            if (sel == "F") {
                let run = mtg.races.filter((data) => data.no == rNo)[0].runners;
                if (run != null) {
                    for (let j in run) {
                        if (!run[j].status.toUpperCase().match(/^(SCRATCHED|SCRATCHEDRESERVED|STANDBY)$/)) {
                            bets[col].push(run[j].no);
                        }
                    }
                }
            }
            else {
                bets[col].push(sel);
            }
        }
    }
    let cnt = 0;
    for (let i in bets.leg) {
        if ( bets.leg.hasOwnProperty(i)) {
            for (let j in bets.b1) {
                if ( bets.b1.hasOwnProperty(j)) {
                    if (bets.leg[i] == bets.b1[j]) {
                        continue;
                    }
                    if (size > 2) {
                        for (let k in bets.b2) {
                            if ( bets.b2.hasOwnProperty(k)) {
                                if (bets.leg[i] == bets.b2[k] || bets.b1[j] == bets.b2[k]) {
                                    continue;
                                }
                                if (size > 3) {
                                    for (let m in bets.b3) {
                                        if ( bets.b3.hasOwnProperty(m)) {
                                            if (bets.leg[i] == bets.b3[m] || bets.b1[j] == bets.b3[m] || bets.b2[k] == bets.b3[m]) {
                                                continue;
                                            }
                                            cnt++;
                                        }
                                    }
                                }
                                else {
                                    cnt++;
                                }
                            }
                        }
                    }
                    else {
                        cnt++;
                    }
                }
            }
        }
    }
    return cnt;
}

export function RandomBanker(mtg, rNo, ranSize) {
    var r = [];
    var rCnt = 0;
    var i = 0;
    let run = mtg.races.filter((data) => data.no == rNo)[0].runners;
    if (run != null) {
        rCnt = run.filter((data) => !data.status.toUpperCase().match(/^(SCRATCHED|SCRATCHEDRESERVED|STANDBY)$/)).length;
    }
    while (i < ranSize) {
        var n = Math.ceil(Math.random() * rCnt) - 1;
        var runner = run[n].no;
        if (run[n].status.toUpperCase().match(/^(SCRATCHED|SCRATCHEDRESERVED|STANDBY)$/)) {
            continue;
        }
        if (r.indexOf(runner) < 0) {
            r.push(runner);
            i++;
        }
    }
    betSels.rand = true;
    return r;
}

export function RandomLeg(mtg, rNo, ranSize, excludeNos) {
    var r = [];
    var rCnt = 0;
    var i = 0;
    let run = mtg.races.filter((data) => data.no == rNo)[0].runners;
    if (run != null) {
        rCnt = run.filter((data) => !data.status.toUpperCase().match(/^(SCRATCHED|SCRATCHEDRESERVED|STANDBY)$/)).length;
    }
    while (i < ranSize && i < rCnt) {
        var n = Math.ceil(Math.random() * run.length) - 1;
        var runner = run[n].no;
        if (run[n].status.toUpperCase().match(/^(SCRATCHED|SCRATCHEDRESERVED|STANDBY)$/)) {
            continue;
        }
        if (r.indexOf(runner) < 0 && excludeNos.indexOf(runner) < 0) {
            r.push(runner);
            i++;
        }
    }
    betSels.rand = true;
    return r;
}

export function CalculateAlupBet(mtg, alupData, formula, allSels) {
    let totalCnt = 0;
    let legList = [];
    let raceList = [];
    let bType;
    let cnt;
    let maxRace = Math.max(...mtg.races.map(x=>x.no));
    for (let i = 1; i <= maxRace; i++) {
        if (allSels[i] == null)
            continue;
        legList.push(allSels[i]);
        raceList.push(i);
    }
    if ( legList.length===0 ) {
        return 0;
    }
    if ( legList.length===1 ) {
        bType = Object.keys(legList[0].legCnt)[0];
        cnt = legList[0].legCnt[bType];
        cnt = cnt=='F' ? getFieldCount(mtg, raceList[0], allSels) : cnt;
        totalCnt += getCnt(totalCnt, mtg, bType, raceList[0], alupData?.[0]?.subType, legList[0].b1Cnt, legList[0].b2Cnt, legList[0].b3Cnt, cnt);
    }
    else {
        let fItems = alupFormulaItem[formula].split('#');
        for ( let m=0; m<fItems.length; m++ ) {
            let singleCnt = 1;
            for ( let n=0; n<fItems[m].length; n++ ) {
                let legIdx = parseInt(fItems[m][n])-1;
                let poolObj = legList[legIdx];
                bType = Object.keys(poolObj?.legCnt)[0];
                cnt = poolObj?.legCnt?.[bType];
                cnt = cnt=='F' ? getFieldCount(mtg, raceList[legIdx], allSels) : cnt;

                singleCnt *= getCnt(0, mtg, bType, raceList[legIdx], alupData?.[legIdx]?.subType, poolObj.b1Cnt, poolObj.b2Cnt, poolObj.b3Cnt, cnt);
            }
            totalCnt += singleCnt;
        }
    }
	return totalCnt;
}

export const validateBet = (contentRef, races, t, hideAlert) => {
    const mtg = contentRef.current.meeting;

    if (contentRef.current.page == "JTCOMBO") {
        for (var i = 0; i < mtg.races.length; i++){
            if (GetBetsByCriteria("WIN", i, "leg").length
                + GetBetsByCriteria("PLA", i, "leg").length
                + GetBetsByCriteria("W-P", i, "leg").length > 0) {
                return true;
            }
        }
        !hideAlert && alert(t("ERR_INSUFF_M"));
        return false;
    }

    for (var i=0; i<races.length; i++) {
        let bType = contentRef.current.page;
        let subType = betSels.subT;
        if ( contentRef.current.config.ALUP_PAGE.includes(contentRef.current.page) ) {
            bType = contentRef.current.alupData[i].betType;
            subType = contentRef.current.alupData[i].subType;
        }
        switch (bType) {
            case "WIN":
            case "PLA":
            case "W-P":
                if ( GetBetsByCriteria(bType, races[i], "leg").length == 0 ) {
                    !hideAlert && alert(t('ERR_SELECTIONS_RACE').replace("{0}", races[i]));
                    return false;
                }
                break;
            case "WP":
                if ( GetBetsByCriteria("WIN", races[i], "leg").length
                    + GetBetsByCriteria("PLA", races[i], "leg").length
                    + GetBetsByCriteria("W-P", races[i], "leg").length == 0 ) {
                    !hideAlert && alert(t('ERR_SELECTIONS_RACE').replace("{0}", races[i]));
                    return false;
                }
                break;
            case "QIN":
            case "QPL":
            case "QQP":
            case "WPQ":
                var b1Cnt = GetBetsByCriteria("", races[i], "b1").length;
                var selCnt = hasSelectedField("", races[i], "leg") ? 99 : GetBetsByCriteria("", races[i], "leg").length;
                if ( b1Cnt > 1) {
                    !hideAlert && alert(t('ERR_TOO_MANY_BANKERS').replace("{0}", races[i]));
                    return false;
                }
                if ( selCnt < 2 ) {
                    !hideAlert && alert(t('ERR_SELECTIONS_RACE').replace("{0}", races[i]));
                    return false;
                }
                break;
            case "CWA":
            case "CWB":
            case "CWC":
                if ( GetBetsByCriteria("", races[i], "leg").length == 0 ) {
                    !hideAlert && alert(t('ERR_SELECTIONS_CWIN').replace("{0}", races[i]));
                    return false;
                }
                break;
            case "IWN":
                if ( GetBetsByCriteria("", races[i], "b1").length==0 || GetBetsByCriteria("", races[i], "leg").length == 0 ) {
                    !hideAlert && alert(t('ERR_SELECTIONS_RACE').replace("{0}", races[i]));
                    return false;
                }
                break;
            case "FCT":
                var b1Cnt = hasSelectedField("", races[i], "b1") ? 99 : GetBetsByCriteria("", races[i], "b1").length;
                var legCnt = hasSelectedField("", races[i], "leg") ? 99 : GetBetsByCriteria("", races[i], "leg").length;
                switch (subType) {
                    case "S":
                        if ( b1Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_S'));
                            return false;
                        }
                        if ( legCnt == 0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_2_S'));
                            return false;
                        }
                        break;
                    case "M":
                        if ( legCnt < 2 ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                    case "B":
                    case "BM":
                        if ( b1Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_B'));
                            return false;
                        }
                        if ( legCnt < 2 ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                    case "MB":
                        if ( b1Cnt < 1 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_MB'));
                            return false;
                        }
                        if ( legCnt < 1 ) {
                            !hideAlert && alert(t('ERR_INSUFF_2_MB'));
                            return false;
                        }
                        if ( b1Cnt+legCnt<=2 || CalcMBBet(mtg, races[i], 2) <= 0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                }
                break;
            case "TCE":
                var b1Cnt = hasSelectedField("", races[i], "b1") ? 99 : GetBetsByCriteria("", races[i], "b1").length;
                var b2Cnt = hasSelectedField("", races[i], "b2") ? 99 : GetBetsByCriteria("", races[i], "b2").length;
                var legCnt = hasSelectedField("", races[i], "leg") ? 99 : GetBetsByCriteria("", races[i], "leg").length;
                switch (subType) {
                    case "S":
                        if ( b1Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_S'));
                            return false;
                        }
                        if ( b2Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_2_S'));
                            return false;
                        }
                        if ( legCnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_3_S'));
                            return false;
                        }
                        break;
                    case "M":
                        if ( legCnt < 3 ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                    case "B":
                        if ( b1Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_B'));
                            return false;
                        }
                        if ( legCnt < 2 ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                    case "BM":
                        if ( b1Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_B'));
                            return false;
                        }
                        if ( b2Cnt==0 && legCnt < 3 || b2Cnt==1 && legCnt < 2 ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                    case "MB":
                        if ( b1Cnt < 1 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_MB'));
                            return false;
                        }
                        if ( b2Cnt < 1 ) {
                            !hideAlert && alert(t('ERR_INSUFF_2_MB'));
                            return false;
                        }
                        if ( legCnt < 1 ) {
                            !hideAlert && alert(t('ERR_INSUFF_3_MB'));
                            return false;
                        }
                        if ( b1Cnt+b2Cnt+legCnt<=3 || CalcMBBet(mtg, races[i], 3) <= 0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                }
                break;
            case "QTT":
                var b1Cnt = hasSelectedField("", races[i], "b1") ? 99 : GetBetsByCriteria("", races[i], "b1").length;
                var b2Cnt = hasSelectedField("", races[i], "b2") ? 99 : GetBetsByCriteria("", races[i], "b2").length;
                var b3Cnt = hasSelectedField("", races[i], "b3") ? 99 : GetBetsByCriteria("", races[i], "b3").length;
                var legCnt = hasSelectedField("", races[i], "leg") ? 99 : GetBetsByCriteria("", races[i], "leg").length;
                switch (subType) {
                    case "S":
                        if ( b1Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_S'));
                            return false;
                        }
                        if ( b2Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_2_S'));
                            return false;
                        }
                        if ( b3Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_3_S'));
                            return false;
                        }
                        if ( legCnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_4_S'));
                            return false;
                        }
                        break;
                    case "M":
                        if ( legCnt < 4 ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                    case "B":
                        if ( b1Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_B'));
                            return false;
                        }
                        if ( b1Cnt==1 && b2Cnt==0 && b3Cnt==1) {
                            !hideAlert && alert(t('ERR_INSUFF_2_B'));
                            return false;
                        }
                        var bTotal = b1Cnt + b2Cnt + b3Cnt;
                        if ( (bTotal == 1 && legCnt <= 2) || (bTotal > 1 && legCnt <= 1) ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                    case "BM":
                        if ( b1Cnt==0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_B'));
                            return false;
                        }
                        if ( b1Cnt==1 && b2Cnt==0 && b3Cnt==1) {
                            !hideAlert && alert(t('ERR_INSUFF_2_B'));
                            return false;
                        }
                        var bTotal = b1Cnt + b2Cnt + b3Cnt;
                        if ( (bTotal == 1 && legCnt <= 3) || (bTotal == 2 && legCnt <= 2) || (bTotal > 2 && legCnt <= 1) ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                    case "MB":
                        if ( b1Cnt < 1 ) {
                            !hideAlert && alert(t('ERR_INSUFF_1_MB'));
                            return false;
                        }
                        if ( b2Cnt < 1 ) {
                            !hideAlert && alert(t('ERR_INSUFF_2_MB'));
                            return false;
                        }
                        if ( b3Cnt < 1 ) {
                            !hideAlert && alert(t('ERR_INSUFF_3_MB'));
                            return false;
                        }
                        if ( legCnt < 1 ) {
                            !hideAlert && alert(t('ERR_INSUFF_4_MB'));
                            return false;
                        }
                        if ( b1Cnt+b2Cnt+b3Cnt+legCnt<=4 || CalcMBBet(mtg, races[i], 4) <= 0 ) {
                            !hideAlert && alert(t('ERR_INSUFF_M'));
                            return false;
                        }
                        break;
                }
                break;
            case "TRI":
            case "DT":
            case "TT":
                var b1Cnt = GetBetsByCriteria("", races[i], "b1").length;
                var selCnt = hasSelectedField("", races[i], "leg") ? 99 : GetBetsByCriteria("", races[i], "leg").length;
                if ( b1Cnt > 2 ) {
                    !hideAlert && alert(t('ERR_TOO_MANY_BANKERS').replace("{0}", races[i]));
                    return false;
                }
                if ( b1Cnt<2 && selCnt<3 || b1Cnt==2 && selCnt<2 ) {
                    !hideAlert && alert(t('ERR_SELECTIONS_RACE').replace("{0}", races[i]));
                    return false;
                }
                break;
            case "FF":
                var b1Cnt = GetBetsByCriteria("", races[i], "b1").length;
                var selCnt = hasSelectedField("", races[i], "leg") ? 99 : GetBetsByCriteria("", races[i], "leg").length;
                if ( b1Cnt > 3 ) {
                    !hideAlert && alert(t('ERR_TOO_MANY_BANKERS').replace("{0}", races[i]));
                    return false;
                }
                if ( b1Cnt<2 && selCnt<4 || b1Cnt==2 && selCnt<3 || b1Cnt==3 && selCnt<2 ) {
                    !hideAlert && alert(t('ERR_SELECTIONS_RACE').replace("{0}", races[i]));
                    return false;
                }
                break;
            case "DBL":
            case "TBL":
            case "SixUP":
            case "6UP":
                if ( GetBetsByCriteria("", races[i], "leg").length == 0 ) {
                    !hideAlert && alert(t('ERR_SELECTIONS_RACE').replace("{0}", races[i]));
                    return false;
                }
                break;
        }
    }
    return true;
}

export function genBetLine(betObj) {

}