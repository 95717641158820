import { getOddsValue } from "./common";

export const parseWPQTop = (pmPool, message) => {
    if(message != null){
        if(message.updAt != null && message.cmb !=null){
            pmPool.lastUpdateTime = message.updAt;
            let top20 = message.cmb.filter(x=>x.odds != null).sort(topCompare).slice(0,20);
            pmPool.oddsNodes = [];
            if(top20.length<=0){  // ReservedOrRefunded status
                top20 = message.cmb.filter(x=>x.scrOrd != null).sort(orderCompare).slice(0,20);
            }
            top20.forEach(comb => {
                let values = {
                    bankerOdds: [],
                    combString: comb.cmbStr,
                    hasFavourite: comb.hf===true,
                    oddsDropValue: parseInt(comb.oDrp) || 0,
                    oddsValue: getOddsValue(comb.odds, comb.cmbSt)
                }
                pmPool.oddsNodes.push(values);
            }); 
        }
    }
}

function topCompare ( a, b ){
    let aWillPay= a.wP?a.wP:99999;
    let bWillPay= b.wP?b.wP:99999;
    if( parseFloat(aWillPay)< parseFloat(bWillPay) ){
        return -1;
    }
    if( parseFloat(aWillPay) > parseFloat(bWillPay) ){
        return 1;
    }
    if( a.cmbStr< b.cmbStr ){
        return -1;
    }
    if( a.cmbStr > b.cmbStr ){
        return 1;
    }
   
    return 0;
}

function orderCompare ( a, b ){
    if( parseFloat(a.scrOrd)< parseFloat(b.scrOrd) ){
        return -1;
    }
    if( parseFloat(a.scrOrd) > parseFloat(b.scrOrd) ){
        return 1;
    }
    return 0;
}