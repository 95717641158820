import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useQuery, useLazyQuery } from '@apollo/client';

import { getRacingFoRaceByRaceQuery } from '../Data/DataQuery';
import { sortFOResult } from './FO_Result';
import { FoName } from './FO_List';
import { lblBr } from '../../Common/GlobalFunc';
import CheckBox from '../../Common/ui-components/CheckBox';
import Loading from '../../Common/ui-components/Loading';


function FO_RaceByRace() {
  const { t, i18n } = useTranslation();
  const { type, mtgdate, vencode, lang, instno } = useParams();
  const betType = type?.toUpperCase();
  const [showPoint, setShowPoint] = useState(false);
  const [apiErr, setApiErr] = useState(false);


  useEffect(() => {
    document.title = t('TITLE');
    loadQuery({ variables: getQueryVariables() });
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  function getQueryVariables() {
    let oTyp = [];
    if (type) {
      if (type == 'jkc')
        oTyp.push("JKC");
      if (type == 'tnc')
        oTyp.push("TNC");
    }

    return { "date": mtgdate, "venueCode": vencode, "foOddsTypes": oTyp };
  }

  const [loadQuery, { loading, data }] = useLazyQuery(getRacingFoRaceByRaceQuery(), {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: res => {
      setApiErr(false);
    },
    onError: err => {
      setApiErr(true);
    }
  });

  function VerticalWord({ val }) {
    return <span className={i18n.language === 'ch' && 'verticalWord'}>{val}</span>
  }

  const RaceByRaceTable = ({ foPool }) => {
    return <div className="racebyrace-table">
      <RaceByRacePtHeader />
      <RaceByRaceHeader />
      <ResSelRow foPool={foPool} />
    </div>
  }

  const RaceByRacePtHeader = () => {
    if (showPoint) {
      return <>
        <div></div>
        <div></div>
        <div className="count1 header">{t('LB_RC_' + betType + '_RACEBYRACE_COUNT')}</div>
        <div></div>
        <div className="count2 header">{t('LB_RC_' + betType + '_RACEBYRACE_COUNT2')}</div>
        <div></div>
        <div></div>
        <div></div>
      </>
    } else {
      return null;
    }
  }
  const RaceByRaceHeader = () => {
    return <>
      <div className="header">
        <span className="d">{t('LB_RC_' + betType + '_RACEBYRACE_SEL')}</span>
        <span className="m">{lblBr(t('LB_RC_' + betType + '_RACEBYRACE_SEL_M'))}</span>
      </div>
      <div className="name header">
        <span className="d">{t('LB_RC_' + betType + '_RACEBYRACE_NAME')}</span>
        <span className="m">{t('LB_RC_' + betType + '_RACEBYRACE_NAME_M')}</span>
      </div>
      <div className="header">
        <span className="d">{t('LB_RC_' + betType + '_RACEBYRACE_1st')}</span>
        <span className="m"><VerticalWord val={t('LB_RC_' + betType + '_RACEBYRACE_1st_M')} /></span>
      </div>
      <div className="header">
        <span className="d">{t('LB_RC_' + betType + '_RACEBYRACE_2nd')}</span>
        <span className="m"><VerticalWord val={t('LB_RC_' + betType + '_RACEBYRACE_2nd_M')} /></span>
      </div>
      <div className="header">
        <span className="d">{t('LB_RC_' + betType + '_RACEBYRACE_3rd')}</span>
        <span className="m"><VerticalWord val={t('LB_RC_' + betType + '_RACEBYRACE_3rd_M')} /></span>
      </div>
      <div></div>
      <div className="header">
        <span className="d">{t('LB_RC_' + betType + '_RACEBYRACE_4th')}</span>
        <span className="m"><VerticalWord val={t('LB_RC_' + betType + '_RACEBYRACE_4th_M')} /></span>
      </div>
      <div className="header">
        <span className="d">{t('LB_RC_' + betType + '_RACEBYRACE_ACC')}</span>
        <span className="m">{lblBr(t('LB_RC_' + betType + '_RACEBYRACE_ACC_M'))}</span>
      </div>
      <div className="header">
        <span className="d">{t('LB_RC_' + betType + '_RACEBYRACE_ODDS')}</span>
        <span className="m">{lblBr(t('LB_RC_' + betType + '_RACEBYRACE_ODDS_M'))}</span>
      </div>
      <div className="header">
        <span className="d">{t('LB_RC_' + betType + '_RACEBYRACE_REMARKS')}</span>
        <span className="m">{t('LB_RC_' + betType + '_RACEBYRACE_REMARKS_M')}</span>
      </div>
    </>
  };

  const ResSelRow = ({ foPool }) => {
    let rows = [];
    const foRes = foPool.selections.map(sel => {
      sel.curRes = sel.results[sel.results.length-1];
      return sel;
    });
    const foOthRes = foPool.otherSelections.map(sel => {
      sel.curRes = sel.results[sel.results.length-1];
      return sel;
    });

    if (foRes.length > 0 && foRes[0].curRes) {
      foRes.sort(sortFOResult);
      if (foOthRes.length > 0 && foOthRes[0].curRes) {
        foOthRes.sort(sortFOResult);
      }
      return foRes.map((sel, idx) => {
        let cssName = idx % 2 === 0 ? 'odd' : 'even';
        let cssHeight = showPoint ? 'rowHeight' : '';
        cssName += showPoint ? ' minHeight' : '';
        return <>
          <div className={`${cssName} ${cssHeight}`}>{sel.number}</div>
          <div className={`${cssName} ${cssHeight} name`}>
            <FoName t={t} betType={betType} foPool={foPool} num={sel.number} code={sel.code} name={sel["name_" + i18n.language]} showOtherTable={true} oSels={foOthRes} />
          </div>
          <div className={`${cssName} horse1`}>
            {sel.curRes.count1st}{sel.curRes.dhRmk1st ? '*' : ''}
            {showPoint ? <div className="foPlacePoint">({sel.curRes.point1st})</div> : null}
          </div>
          <div className={`${cssName} horse2`}>
            {sel.curRes.count2nd}{sel.curRes.dhRmk2nd ? '*' : ''}
            {showPoint ? <div className="foPlacePoint">({sel.curRes.point2nd})</div> : null}
          </div>
          <div className={`${cssName} horse3`}>
            {sel.curRes.count3rd}{sel.curRes.dhRmk3rd ? '*' : ''}
            {showPoint ? <div className="foPlacePoint">({sel.curRes.point3rd})</div> : null}
          </div>
          <div className={`${cssName}`}>&nbsp;</div>
          <div className={`${cssName} ${cssHeight} horse4`}>
            {(sel.curRes.count4th == 0 && sel.curRes.denominator4th > 1) ? null : sel.curRes.count4th}{sel.curRes.denominator4th > 1 ? <span><sup>{sel.curRes.numerator4th}</sup>&frasl;<sub>{sel.curRes.denominator4th}</sub></span> : null}
          </div>
          <div className={`${cssName} ${cssHeight} acc`}>
            {sel.curRes.points >= 0 ? sel.curRes.points : "---"}
          </div>
          <div className={`${cssName} ${cssHeight} odds`}>
            {formatFOOdds(t, sel)}
          </div>
          <div className={`${cssName} ${cssHeight} remarks`}>
            {sel.combStatus == "WIN" ? t('LB_RC_FO_WIN') : '-'}
          </div>
        </>
      });
    } else {
      return null;
    }
  }

  function formatFOOdds(t, sel) {
    var o = sel.currentOdds;
    switch (sel.combStatus) {
      case "REFUND":
      case "REFUNDBEFORESUSPEND":
        o = t('LB_RC_FO_RFD');
        break;
      case "ELIMINATED":
      case "LOSE":
        o = t('LB_RC_FO_LOSE');
        break;
      case "UNAVAILABLE":
        o = "---";
        break;
      default:
        break;
    }
    return o;
  }

  const RaceByRaceRemark = ({ foPool }) => {
    return betType == "JKC" ? <div className="title">{t('LB_RC_' + betType + '_RACEBYRACE_REMARK_MSG').replace('{0}', foPool.instNo)}</div> : null;
  };

  const DeadheatRemark = ({ foPool }) => {
    let showDHRemarks = false;
    foPool.selections.forEach(sel => {
      if (sel.results.filter(x => x.dhRmk1st || x.dhRmk2nd || x.dhRmk3rd).length > 0)
        showDHRemarks = true;
    });
    return showDHRemarks ? <div className="title">* {t('LB_RC_RESULT_DH_LONG')}</div> : null
  };

  const refreshFn = () => {
    if(!document.querySelector('.refreshIcon')?.classList.contains('rotate')) {
        document.querySelector('.refreshIcon')?.classList?.add('rotate')
        setTimeout(() => {
            document.querySelector('.refreshIcon')?.classList?.remove('rotate')
        }, 1200)
    }
    loadQuery({ variables: getQueryVariables() });
  }

  const RaceByRaceTitle = ({ foPool }) => {
    return <div className="">
      <div className="titlebar">
        <div>
          <div className="title">{t('LB_RC_' + betType + '_RACEBYRACE_TITLE').replace('{0}', foPool.instNo || instno)}</div>
        </div>
        <div className="refreshsec">
          <div className="refreshtime">{t('LB_RC_REFRESH_AT')}{foPool.lastUpdateTime?.substring(11, 16) || '16:34'}</div>
          <div className="flex-center refresh-odds">
            <div className="refreshIcon" onClick={refreshFn} />
            <label>{t('LB_RC_REFRESH')}</label>
          </div>
        </div>
      </div>
    </div>
  }

  if (apiErr) {
    return <section className="RACEBYRACE">
      <div className="racebyrace_table">
        <RaceByRaceTitle foPool={{}} />
      </div>
      <div className='noInfoTip'>{t('LB_RC_JTC_NOINFO')}</div>
    </section>
    // }else if (error ) {
    //   return <div>Error: {error}</div>;
  } else if (loading || data == null || data.raceMeetings == null || data.raceMeetings[0] == null) {
    return <Loading />;
  } else {
    let foPool = data.raceMeetings[0].foPools.filter(x => x.instNo == instno)[0];
    if (foPool == null)
      return <div></div>
    else {
      return (
        <section className="RACEBYRACE">
          <div className="racebyrace_table">
            <RaceByRaceTitle foPool={foPool} />
          </div>
          <div>
            <div className="racebyrace_control">
              <div className="checkbox-containter">
                <CheckBox checked={showPoint} onChange={(e) => setShowPoint(!showPoint)} />
                <div className="showpoint">{t('LB_RC_' + betType + '_RACEBYRACE_SHOWPOINT')}</div>
              </div>
              <div className="refreshsec">
                <div className="refreshtime">{t('LB_RC_REFRESH_AT')} {foPool.lastUpdateTime.substring(11, 16)}</div>
              </div>
            </div>
          </div>
          <div className="racebyrace_table">
            <RaceByRaceTable foPool={foPool} />
          </div>
          <div className="racebyrace-rmk">
            <RaceByRaceRemark foPool={foPool} />
            <DeadheatRemark foPool={foPool} />
          </div>
        </section>
      );
    }
  }
}

export default FO_RaceByRace;