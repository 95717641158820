import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DepositForm from './DepositForm';
import DepositPreview from './DepositPreview';
import DepositResult from './DepositResult';
import { ConfigContext } from '../../../Common/ConfigHelper';
import { Cookies } from '../../../Common/CookieHelper';
import { ExceptionalCaseComp } from '../components/FPSChargeTips/otherCaseTips';
import { getBalanceValue } from '../../BetSlipHooks';
import { textFormatAmountFn } from '../../../Common/GlobalFunc';
import {
    postEnquirePPSStatusTR_IB
} from '../FundsTransferIBAPI';

const OnlineDeposit = ({ props }) => {
    const { nbaData, FPSErrorList, setResultShowStatus, setLoadingStatus,
         getExpCaseStatus, 
         goToLinkBAPage,
         isSingleLeg,
         bankAccountType,
         bankList,
         fpsMessageQuery,
         isMobileSize,
         updateBetSlipBalance,
         bankOrPpsAccType, 
         setBankOrPpsAccType,
         ppsBtnState 
        } = props;
    const [curStep, setCurStep] = useState(0);
    const [bankInfo, setBankInfo] = useState({});
    const [depositAmount, setDepositAmount] = useState('');
    const [transactionMethod, setTransactionMethod] = useState('');
    const [showOtherMeans, setShowOtherMeans] = useState(true);
    const [ishadOtherMeans, setIshadOtherMeans] = useState(true);
    const [epsPin, setEpsPin] = useState('');
    const { betslipMenu } = useContext(ConfigContext);
    const [chargeAmtFps, setChargeAmtFps] = useState(0);
    const [chargeAmtEps, setChargeAmtEps] = useState(0);
    const [resultPageRespData, setResultPageRespData] = useState({});
    const [ppsRespData, setPpsPageRespData] = useState({});
    const [getBalance, setGetBalance] = useState('');
    const [bankFir, setBankFir] = useState({});
    const [bankSec, setBankSec] = useState({});
    const [depositConfig, setDepositConfig] = useState({});

    const bankFirShow = nbaData.config.otherMeansEnabled
        ? bankFir?.EPS?.show || bankFir?.FPS?.show
        : bankFir?.EPS?.show || (bankFir?.FPS?.show && !bankFir?.FPS?.disabled);
    const bankSecShow = nbaData.config.otherMeansEnabled
        ? bankSec?.EPS?.show || bankSec?.FPS?.show
        : bankSec?.EPS?.show || (bankSec?.FPS?.show && !bankSec?.FPS?.disabled);

    useEffect(() => {
        if (nbaData) {
            const { nba1, nba2 } = nbaData;
            let obj = nba1;
            if (nba2 && !nba1?.FPS?.show && !nba1?.EPS?.show) {
                obj = nba2;
            }
            if (nba2 && (nba1?.FPS?.disabled && !nba1?.EPS?.show) && ((nba2?.FPS?.show && !nba2?.FPS?.disabled) || nba2?.EPS?.show)) {
                obj = nba2;
            }
            updateDataShow(obj);
        }
    }, [nbaData]);

    const isNeedChargeFn = (selectedNba) => {
        const configFps = nbaData?.config?.FPS || {};
        let chargeAmt = configFps?.serviceChargeAmount;
        let chargeAmtEps = 0;
        if (selectedNba == 1 && nbaData?.nba1?.EPS?.hasDepositCharge) {
            chargeAmtEps = nbaData?.nba1?.EPS?.charge || 0;
        } else if (selectedNba == 2 && nbaData?.nba2?.EPS?.hasDepositCharge) {
            chargeAmtEps = nbaData?.nba2?.EPS?.charge || 0;
        }
        if (window.globalConfig.IS_IB) {
            setChargeAmtEps(chargeAmtEps);
        } else {
            setChargeAmtEps(chargeAmtEps / 100);
        }
        setChargeAmtFps(chargeAmt);
    };

    useEffect(() => {
        if (betslipMenu) {
            setIshadOtherMeans(betslipMenu?.depositOtherMeansEnabled);
        }
    }, [betslipMenu]);

    useEffect(() => {
        if(Cookies.getData('sp4ePpsResult')){
            getPPSResponeDetail();
        }
        
    }, [Cookies.getData('sp4ePpsResult')])

    useEffect(() => {
        if (nbaData) {
            const deposit = nbaData;
            setBankFir(deposit?.nba1);
            setBankSec(deposit?.nba2);
            setDepositConfig(deposit?.config);
        }
    }, [nbaData]);

    useEffect(() => {
        if(!bankOrPpsAccType){
            initType();
        }
    }, [bankFir,ppsBtnState]);

    const initType = () => {
        if(bankFirShow || bankSecShow){
            setBankOrPpsAccType('bankType')
        } else if(ppsBtnState && !isMobileSize){
            setBankOrPpsAccType('ppsType')
        } else {
            setBankOrPpsAccType('')
        }
    }

    const updateDataShow = (obj) => {
        setBankInfo(obj);
        let showMethod = '';
        const fpsMethod = obj?.['FPS'] || {};
        const epsMethod = obj?.['EPS'] || {};
        let showOtherMean = false;
        if (fpsMethod.show) {
            showMethod = 'FPS';
            if (fpsMethod.disabled && epsMethod.show) {
                showMethod = 'EPS';
            }
        } else if (epsMethod.show) {
            showMethod = 'EPS';
            showOtherMean = true;
        }
        setShowOtherMeans(showOtherMean);
        setTransactionMethod(showMethod);
        isNeedChargeFn(obj?.bankAccountType);
    };

    const getPPSResponeDetail = async () => {
        setLoadingStatus(true);
        const ppsRespData=JSON.parse(Cookies.getData('sp4ePpsResult'))
        setCurStep(2);
        let status = 'REJECTED';
        let curSessionRecordsStatus = 'REJECTED';
        if (ppsRespData.Result === "0"){
            status = 'SUCCESSFUL';
            curSessionRecordsStatus = 'SUCCESSFUL';
            getBalanceFn();
        } else if(['611','615'].includes(ppsRespData.Result)){
            curSessionRecordsStatus = 'PROCESSING';
        } else if(['901','603','604','499','610','612','613'].includes(ppsRespData.Result)){
            curSessionRecordsStatus = 'UNKNOWN';
        }
        ppsRespData.status = status;
        ppsRespData.curSessionRecordsStatus = curSessionRecordsStatus;
        ppsRespData.curIsPpsType = true;
        const newObj = await postEnquirePPSStatusTR_IB(ppsRespData.ReferenceNo);
        ppsRespData.transactionNumber=newObj.TransactionNumber;
        ppsRespData.respMsg=newObj.MSG;
        setPpsPageRespData(ppsRespData);
        setResultPageRespData(ppsRespData);
        setResultShowStatus(status);
        setBankOrPpsAccType('ppsType');
        Cookies.setData('sp4eEftPath','',true,1)
        Cookies.setData('sp4ePpsResult','',true,1)
        setLoadingStatus(false);
    }

    const getBalanceFn = async () => {
        const newValue = await getBalanceValue(true);
        if (newValue.balanceStatus == 0 && newValue?.balance !== '') {
            const balance = newValue.balance;
            setGetBalance(balance);
            updateBetSlipBalance(textFormatAmountFn(balance));
        } else {
            setGetBalance(Number(sessionStorage.getItem('balance')));
        }
    };

    const newProps = {
        ...props,
        curStep,
        setCurStep,
        bankInfo,
        depositAmount,
        setDepositAmount,
        transactionMethod,
        setTransactionMethod,
        updateDataShow,
        showOtherMeans,
        setShowOtherMeans,
        epsPin,
        setEpsPin,
        ishadOtherMeans,
        resultPageRespData,
        setResultPageRespData,
        getBalance,
        setGetBalance,
        chargeAmtEps,
        setChargeAmtFps,
        chargeAmtFps,
        ppsRespData, 
        setPpsPageRespData,
        bankFir, 
        setBankFir,
        bankSec, 
        setBankSec,
        bankFirShow,
        bankSecShow,
        depositConfig,
        getBalanceFn,
        initType,
        bankOrPpsAccType, 
        setBankOrPpsAccType
    };
    return (
        <div className="deposit-main">
            {getExpCaseStatus && curStep === 0 && !isMobileSize ? (
                <ExceptionalCaseComp
                    props={{
                        getExpCaseStatus,
                        goToLinkBAPage,
                        isSingleLeg,
                        bankAccountType,
                        bankList,
                        isShowBtnArea:false,
                        isHadPPS:true,
                        fpsMessageQuery
                    }}
                />
            ):null}
            {curStep === 0 && <DepositForm props={newProps} />}
            {curStep === 1 && <DepositPreview props={newProps} />}
            {curStep === 2 && <DepositResult props={newProps} />}
        </div>
    );
};

export default OnlineDeposit;
