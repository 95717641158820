import React, { useEffect, useState, useRef, useContext, useMemo, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../Common/CommonHooks';
import OddsEnquiry from './OddsEnquiry';
import JackpotRemarks from './JackpotRemarks';
import Pagination from './Pagination';
import MergedPoolRemarks from '../Common/MergedPoolRemarks';
import { currencyFormat, isLocalMeeting } from '../../Common/home-common';
import Popup from 'reactjs-popup';
import $ from 'jquery';
import { RacingContext } from '../../Home/RacingPage';
import { clickHorseLink } from './RCOddsTableBase';
import { useQuickBet } from './QuickBetHook'
import { useBreakpoint } from '../../Common/CommonHooks';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import {isStartSellpmPool } from './Common';
import { clipboardTableTop20, clipboardTableBankerTop10, clipboardInvestment, clipboardTableALL } from './ClipboardCommon';

const defalutBreakpoints = { Desktop: 1920, size1440: 1440, size1279: 1279, mobile: 768, size500: 500 };
export const IOddsTable = ({ betType, msg, isDisableEnquiry, onEnquiryKeyPadClick }) => {
    const [collaspse, setCollaspse] = useState(false);
    const [lineLeft, setLeft] = useState(0)
    const lineRef = useRef();
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const currentRaceNo = useRef(context.content.raceNo);

    const allBetTypeOptions = {
        QTT: [
            { top20: { key: "top20", name: "TOP20", subType: "top", desktop: true, mobile: true } },
            { top10: { key: "top10", name: "BANKER_TOP10", subType: "banker", desktop: true, mobile: true } },
            { enquiry: { key: "enquiry", name: "ENQUIRY", subType: "enq", desktop: false, mobile: !isDisableEnquiry } }
        ],
        TCE: [
            { top20: { key: "top20", name: "TOP20", subType: "top", desktop: true, mobile: true } },
            { top10: { key: "top10", name: "BANKER_TOP10", subType: "banker", desktop: true, mobile: true } }
        ],
        TRI: [
            { top20: { key: "top20", name: "TOP20", subType: "top", desktop: true, mobile: true } },
            { top10: { key: "top10", name: "BANKER_TOP10", subType: "banker", desktop: true, mobile: true } }
        ],
        "FF": [
            { top20: { key: "top20", name: "TOP20", subType: "top", desktop: true, mobile: true } },
            { top10: { key: "top10", name: "BANKER_TOP10", subType: "banker", desktop: true, mobile: true } }
        ]
    }

    function isMergedPool(_type) {
        let pool = mtg.poolInvs.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType==_type)[0];
        return pool != null && pool.mergedPoolId != null;
    }

    if (!isMergedPool("TCE")) {
        allBetTypeOptions["TCE"].push({ investment: { key: "investment", name: "INVESTMENT", subType: "inv", desktop: true, mobile: true } });
    }

    if (!isMergedPool("QTT")) {
        allBetTypeOptions["QTT"].push({ investment: { key: "investment", name: "INVESTMENT", subType: "inv", desktop: true, mobile: true } });
    }

    let races = mtg.races.filter((data) => data.no == context.content.raceNo)[0];
    let rLen = races.wageringFieldSize;
    if (rLen <= 16) {
        allBetTypeOptions["TRI"].push({ all: { key: "all", name: "ALL", subType: "", desktop: true, mobile: true } });
        allBetTypeOptions["FF"].push({ all: { key: "all", name: "ALL", subType: "", desktop: true, mobile: true } });
    }

    let options = allBetTypeOptions[betType];
    // let options = useMemo(allBetTypeOptions[betType],[betType]);

    useEffect(() => {
        if ( context.content.displayOpt==='' ){
            $('.current-odds').show();
        } else {
            $('.current-odds').hide();
        }
    }, [context.content.page])

    useEffect(() => {
        let mobileIndex=0;
        let arrLength=0;
        options.map((item,index)=>{
            let name = Object.keys(item)[0];
            let curItem = item[name];
            if(curItem.subType===context.content.displayOpt){
                mobileIndex=index;
            }
            if (!(name === 'enquiry' && !getOption('enquiry')?.mobile)) {
                arrLength++;
            }
        })
        setLeft(mobileIndex/arrLength*100)
    }, [context.content.displayOpt])

    const handleClickCollapse = e => {
        setCollaspse(!collaspse);
    }

    const getTop20Odds = () => {
        let pool = mtg.pmPools.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType==betType + "Top")[0];
        if (pool == null) {
            return [];
        }
        return pool;
    }

    const getBankerTop10Data = () => {
        let pool = mtg.pmPools.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType==betType + "Banker")[0];
        if (pool == null) {
            return [];
        }
        return pool;
    }

    const getAllOdds = () => {
        let preSuffix = context.content.isPresales ? 'Pre' : '';
        let pool = mtg.pmPools.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType==betType+preSuffix)[0];
        if (pool == null) {
            return [];
        }
        pool.oddsNodes.sort((x,y) => x.combString < y.combString ? -1 : 1);
        return pool;
    }

    const getInvestmentData = () => {
        let pool = mtg.pmPools.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType==betType + "Invest")[0];
        if (pool == null) {
            return [];
        }
        return pool.oddsNodes;
    }

    const onChangeTableType = (e, type) => {
        context.contentRef.current.displayOpt = type;
        if ( type==='' )
            $('.current-odds').show();
        else
            $('.current-odds').hide();
        if ( ["top", "banker"].includes(type)) {
            context.contentRef.current.isPresales = false;
        }
        context.setContent({...context.contentRef.current })
        context.racingFuncs.reloadData({...context.contentRef.current });
    }

    const onRDClickHandler = (val) => {
        let data = options.filter((item) => item[val] != null)[0];
        if ( data[val].subType==='' )
            $('.current-odds').show();
        else
            $('.current-odds').hide();
        context.contentRef.current.displayOpt = data[val].subType;
        if ( ["top", "banker"].includes(data[val].subType)) {
            context.contentRef.current.isPresales = false;
        }
        context.setContent({...context.contentRef.current })
        context.racingFuncs.reloadData({...context.contentRef.current });
    }

    const getOption = (val) => {

        let option = options.filter(x => x[val] != null)[0];

        if (option == null)
            return [];

        return option[val];
    }
    const ru = mtg.races.filter(item => item.no === context.content.raceNo)[0].runners;
    const runnerData = ru.filter(item => !!item.no);
    let count = 4;
    const { isMobileSize, width } = useWindowSize();
    const breakpoint = useBreakpoint(defalutBreakpoints);
    if(breakpoint === 'size1279') count = 2;
    if(breakpoint === 'size1440') count = 3;
    if(isMobileSize && breakpoint === 'size500') {
        count = 3;
    }
    let tabsSize = 0
    const tabs = options.map(item => {
        let name = Object.keys(item)[0];
        let curItem = item[name];
        if (name === 'enquiry' && !getOption('enquiry')?.mobile) {
            return <></>;
        }
        tabsSize++
        return <div onClick={(e) => onChangeTableType(e, curItem.subType)} key={name}>
            <div>{t(`LB_RC_TABLE_${curItem.name}`)}</div>
        </div>
    })

    context.racingFuncs.copyClipboardLeftOddsTable = () => { 
        return clipboardTable({betType, context, t, i18n, getTop20Odds, getBankerTop10Data, getAllOdds, getInvestmentData, runnerData});
    }

    return (
        <>
            <div className="QTTOddsTable">
                <div className="QTTOddsTable-m">
                    <a data-bs-toggle="collapse" href="#qTTOddsTableCollapse" role="button" aria-expanded="true" aria-controls="qTTOddsTableCollapse" onClick={() => handleClickCollapse()}>
                        <label className="font-color-black font-size-15">
                            {t('LB_ODDS')}
                        </label>
                        <div className={collaspse ? 'collaspse' : 'expend'}>
                            <ArrowIcon direction='up' change={collaspse} changeDirection='down' />
                        </div>
                    </a>
                </div>
                <div className="collapse with-100 qTTOddsTableCollapse show" id="qTTOddsTableCollapse">
                    <div className="hide-m">
                        <div className="header radio-button-set">
                            {options.map((item,index) => {
                                let name = Object.keys(item)[0];
                                let curItem = item[name];
                                return curItem.desktop ? <div key={name} onClick={()=> { onRDClickHandler(name)}} className={`radio-button-set-item radio-button-set-method-${curItem.subType} ${context.content.displayOpt==curItem.subType?'radio-button-set-item-checked':''}`}>
                                    <label className="radio-container">
                                        <input type="radio" id={`rdBanker${curItem.name}`} name="rdQTTOddsTableCollapse" checked={context.content.displayOpt==curItem.subType} /* onChange={() => onRDClickHandler(name)} */ />
                                        <label htmlFor={`rdBanker${curItem.name}`}>{t('LB_RC_TABLE_' + curItem.name)}</label>
                                        <span className="radio-checkmark"></span>
                                    </label>
                                </div> : null
                            })}
                        </div>
                        {getOption('top20')?.desktop && context.content.displayOpt=='top' && <TableTop20 count={count} betType={betType} data={getTop20Odds()} />}
                        {getOption('top10')?.desktop && context.content.displayOpt=='banker' && <TableTop10 count={count} betType={betType} data={getBankerTop10Data()} runnerData={runnerData} />}
                        {getOption('investment')?.desktop && context.content.displayOpt=='inv' && <Investment betType={betType} data={getInvestmentData()} runnerData={runnerData} />}
                        {getOption('all')?.desktop && context.content.displayOpt=='' && <TableTopALL numOfColumn={count} betType={betType} data={getAllOdds()} runnerData={runnerData} />}

                        {!isDisableEnquiry ? <OddsEnquiry betType={betType} runnerData={runnerData} /> : null}

                    </div>
                    <div className={`${options.length==4? 'qtt4Tab': ''} m-slide`}>
                        {tabs}
                        <div className='table-line' ref={lineRef} style={{ 'left': lineLeft+ "%", 'width': 100 / tabsSize + "%" }}></div>
                    </div>
                    <div className="m-content">
                        {getOption('top20')?.mobile && context.content.displayOpt=='top' && <TableTop20Mobile betType={betType} data={getTop20Odds() } />}
                        {getOption('top10')?.mobile && context.content.displayOpt=='banker' && <TableTop10Mobile betType={betType} data={getBankerTop10Data()} runnerData={runnerData} />}
                        {getOption('enquiry')?.mobile && context.content.displayOpt=='enq' && <OddsEnquiry betType={betType} runnerData={runnerData} onKeyPadClick={onEnquiryKeyPadClick} />}
                        {getOption('investment')?.mobile && context.content.displayOpt=='inv' && <Investment betType={betType} data={getInvestmentData()} runnerData={runnerData} />}
                        {getOption('all')?.mobile && context.content.displayOpt=='' && <TableTopALL numOfColumn={count} betType={betType} data={getAllOdds()} runnerData={runnerData} />}
                    </div>
                    <JackpotRemarks betType={betType} />
                    <MergedPoolRemarks betType={betType} />
                    {msg}
                </div>
            </div>
        </>
    )
}

export const Investment = ({ betType, data, runnerData }) => {
    const { t } = useTranslation();

    const Row = ({ rowData, index, runner }) => {
        const row = []

        let inv1 = '';
        let inv2 = '';
        let inv3 = '';
        let inv4 = '';
        if (rowData != null) {
            inv1 = '$'+parseInt(rowData.bankerOdds.filter(x=>x.combString=="1")[0]?.oddsValue).toLocaleString();
            inv2 = '$'+parseInt(rowData.bankerOdds.filter(x=>x.combString=="2")[0]?.oddsValue).toLocaleString();
            inv3 = '$'+parseInt(rowData.bankerOdds.filter(x=>x.combString=="3")[0]?.oddsValue).toLocaleString();
            inv4 = betType=='QTT' ? '$'+parseInt(rowData.bankerOdds.filter(x=>x.combString=="4")[0]?.oddsValue).toLocaleString() : '';
        }
        row.push(<div className='quartet-odds-table-cell' key={`${index}_1`}>{index + 1}</div>);
        row.push(<div className='quartet-odds-table-cell' key={`${index}_2`}>{runner.status.toUpperCase().indexOf("SCRATCHED")>=0 ? t('LB_RC_SCR') : inv1}</div>);
        row.push(<div className='quartet-odds-table-cell' key={`${index}_3`}>{runner.status.toUpperCase().indexOf("SCRATCHED")>=0 ? t('LB_RC_SCR') : inv2}</div>);
        row.push(<div className='quartet-odds-table-cell' key={`${index}_4`}>{runner.status.toUpperCase().indexOf("SCRATCHED")>=0 ? t('LB_RC_SCR') : inv3}</div>);
        row.push(betType=='QTT' ? <div className='quartet-odds-table-cell' key={`${index}_5`}>{runner.status.toUpperCase().indexOf("SCRATCHED")>=0 ? t('LB_RC_SCR') : inv4}</div>: null);

        return <div className='quartet-odds-table-row'>{row}</div>
    }

    const Table = () => {
        const table = []

        table.push(<div className='quartet-odds-table-row' key={`00`}>
            <div className='quartet-odds-table-cell'>{t('LB_RC_ODDS_TABLE_No')}</div>
            <div className='quartet-odds-table-cell'>{t('LB_RC_ODDS_TABLE_1POS')}&nbsp;{t('LB_RC_ODDS_TABLE_POSSUF')}</div>
            <div className='quartet-odds-table-cell'>{t('LB_RC_ODDS_TABLE_2POS')}&nbsp;{t('LB_RC_ODDS_TABLE_POSSUF')}</div>
            <div className='quartet-odds-table-cell'>{t('LB_RC_ODDS_TABLE_3POS')}&nbsp;{t('LB_RC_ODDS_TABLE_POSSUF')}</div>
            {betType=='QTT' ? <div className='quartet-odds-table-cell'>{t('LB_RC_ODDS_TABLE_4POS')}&nbsp;{t('LB_RC_ODDS_TABLE_POSSUF')}</div> : null}
        </div>
        );

        for (let i = 0; i < runnerData.length; i++) {
            let tmpData = data.filter(item => parseInt(item.combString) == i + 1)[0];
            let runner = runnerData.filter(item => parseInt(item.no)== i+1)[0];
            table.push(<Row rowData={tmpData} index={i} runner={runner} key={i}/>)
        }

        return <div className='odds-table-investment'>{table}</div>
    }

    return (<div className='odds-table-investment-box'>
        <header>{t(`LB_${betType}`)} {t(`LB_RC_TABLE_INVESTMENT`)}</header>
        {data && <Table />}
        <InvestmentGraph betType={betType} data={data} runnerData={runnerData} />
    </div>)
}

export const TableTop20Mobile = ({ betType, data}) => {
    const { t } = useTranslation()
    const { handleClickCombOdds } = useQuickBet()
    const { isMobileSize } = useWindowSize();
    const isRefund = data.status?.toUpperCase().indexOf("REFUND")>=0;
    //const  isStartSell = data.status === 'START_SELL'
    const isStartSell = isStartSellpmPool(data);
    const handleClickOdds = (oddsObj) => {
        if(isStartSell && !isMobileSize){
            handleClickCombOdds( oddsObj,betType)
        }
    }
    const Row = ({ data, index }) => {
        const row = []

        row.push(<div className='quartet-odds-table-cell' key={'001'}></div>);
        for (let i = 0; i < 2; i++) {
            let newComb = [];
            let odds = '';
            let tmpOdds = data?.[i * 10 + index];
            if (tmpOdds != null) {
                let arr = tmpOdds.combString.split(',');
                arr.forEach((item, index) => {
                    newComb.push(parseInt(item));
                });
                odds = isRefund ? "---" : tmpOdds.oddsValue;
            }
            row.push(<div className='quartet-odds-table-cell' id={`${betType}_top20_${newComb.join('-')}`} key={i}>
                <div>
                    <div className='cell-label'>{newComb.join('-')}</div>
                    <div className={`cell-value ${isStartSell ? '' : 'disabled'}`}><span className='cp' onClick={()=> handleClickOdds(tmpOdds)}>{odds}</span></div>
                </div>
            </div>)
        }

        return <div className='quartet-odds-table-row'>{row}</div>
    }

    const Table = () => {
        const table = []

        table.push(
            <div className='quartet-odds-table-row' key={'001'}>
                <div className='quartet-odds-table-cell'></div>
                <div className='quartet-odds-table-cell'>
                    <div className="header">
                        <div className="combinations">{t('LB_COMBINATIONS')}</div>
                        <div className="odds">{t('LB_ODDS')}</div>
                    </div>
                </div>
                <div className='quartet-odds-table-cell'>
                    <div className="header">
                        <div className="combinations">{t('LB_COMBINATIONS')}</div>
                        <div className="odds">{t('LB_ODDS')}</div>
                    </div>
                </div>
            </div>)


        for (let i = 0; i < 10; i++) {
            table.push(<Row data={data?.oddsNodes || []} index={i} key={i}/>)
        }

        return <div className='quartet-odds-table-top20'>{table}</div>
    }

    return (<div className='quartet-odds-table-top20-box'>
        <header>{t(`LB_${betType}`)} {t(`LB_RC_TABLE_TOP20`)}</header>
        {data && <Table />}
    </div>)
}

export const TableTop20 = ({ betType, data, count = 4}) => {

    const lineCount = Math.ceil(20 / count);
    const { t } = useTranslation()
    const { handleClickCombOdds } = useQuickBet()
    //const  isStartSell = data.status === 'START_SELL'
    const isStartSell = isStartSellpmPool(data);
    const { isMobileSize } = useWindowSize();
    const isRefund = data.status?.toUpperCase().indexOf("REFUND")>=0;

    const handleClickOdds = (oddsObj) => {
        if(isStartSell && !isMobileSize){
            handleClickCombOdds( oddsObj,betType)
        }
    }

    const Row = ({ data, index }) => {
        const row = []
        for (let i = 0; i < count; i++) {
            let newComb = [];
            let odds = '';
            let tmpOdds = data[i * lineCount + index];
            if (tmpOdds != null) {
                let arr = tmpOdds.combString.split(',');
                arr.forEach((item, index) => {
                    newComb.push(parseInt(item));
                });
                odds = isRefund ? "---" : tmpOdds.oddsValue;
            }
            row.push(<div className={`quartet-odds-table-cell ${'width' + count}`} id={`${betType}_top20_${newComb.join('-')}`} key={i}>
                <div>
                    <div className='cell-label'>{newComb.join('-')}</div>
                    {newComb.length > 0 && <div className={`cell-value ${isStartSell ? '' : 'disabled'}`}><span className='cp table-odds' onClick={()=> handleClickOdds(tmpOdds)}>{odds}</span></div>}
                </div>
            </div>)
        }

        return <div className='quartet-odds-table-row'>{row}</div>
    }

    const Table = () => {
        const table = []
        for (let i = 0; i < lineCount; i++) {
            table.push(<Row data={data?.oddsNodes || []} index={i} key={i}/>)
        }

        return <div className='quartet-odds-table-top20'>{table}</div>
    }

    return (<div className='quartet-odds-table-top20-box'>
        <header>{t(`LB_${betType}`)} {t(`LB_RC_TABLE_TOP20`)}</header>
        {data && <Table />}
    </div>)
}

export const BankerKeyPad = ({ data, page, selectBanker, onKeyPadClick, hideBankerLbl }) => {
    const { t } = useTranslation();

    let size = data.filter(item => item.no != null).length;
    let maxPage = Math.ceil(size / 14);
    let keyArr = [
        [(page - 1) * 14 + 1, (page - 1) * 14 + 8],
        [(page - 1) * 14 + 2, (page - 1) * 14 + 9],
        [(page - 1) * 14 + 3, (page - 1) * 14 + 10],
        [(page - 1) * 14 + 4, (page - 1) * 14 + 11],
        [(page - 1) * 14 + 5, (page - 1) * 14 + 12],
        [(page - 1) * 14 + 6, (page - 1) * 14 + 13],
        [(page - 1) * 14 + 7, (page - 1) * 14 + 14]
    ];

    const KeyPadItem = ({arr, idx}) => {
        return arr.map(item => {
            let isSCR = data.filter(ritem => ritem.status.toUpperCase().indexOf("SCRATCHED")>=0 && ritem.no == item[idx]).length>0;
            let isRes = data.filter(ritem => ['SCRATCHEDRESERVED', 'RESERVED', 'STANDBY'].includes(ritem.status.toUpperCase()) && ritem.no == item[idx]).length>0;
            let SCRValue = "";
            let SCRstyle ="";
            if(isSCR) {
                SCRValue += "("+t('LB_RC_SCR')+")";
                SCRstyle +=" banker-keypad-btn-scr";
            }
            else if (isRes) {
                SCRValue += "("+t('LB_RC_RESERVE')+")";
                SCRstyle +=" banker-keypad-btn-scr";
            }
        return <div key={item[idx]}>{size >= item[idx] ? <div onClick={(e) => { if(!isSCR){onKeyPadClick(item[idx])} }} className={`banker-keypad-btn ${selectBanker.indexOf(item[idx]) >= 0 ? "active" : ""}`+SCRstyle}>{item[idx]}<p>{SCRValue}</p></div> : null}</div>;
        })
    }

    return <div className="banker-keypad">
        <div className="banker-keypad-text">{hideBankerLbl ? "" : t('LB_RC_TABLE_SELECT_BANKER')}</div>
        <div className="banker-keypad-table">
            <div className="banker-keypad-left">{size > 14 ?
                <div className={`banker-keypad-arrow-btn left ${page > 1 ? "active" : ""}`} onClick={(e) => { page > 1 && onKeyPadClick(-1, page - 1) }}>
                    <li className={`arrow arrow-left ${page > 1 ? "border-blue" : ""}`}></li>
                </div> : null}</div>
            <KeyPadItem arr={keyArr} idx={0} />
            <div className="banker-keypad-right">{size > 14 ?
                <div className={`banker-keypad-arrow-btn right ${page < maxPage ? "active" : ""}`} onClick={(e) => { page < maxPage && onKeyPadClick(-1, page + 1) }}>
                    <li className={`arrow arrow-right ${page < maxPage ? "border-blue" : ""}`}></li>
                </div> : null}</div>
            <KeyPadItem arr={keyArr} idx={1} />
        </div>
    </div>;
}
export default BankerKeyPad;

export const TableTop10Mobile = ({ betType, data, runnerData}) => {
    const { t } = useTranslation();
    const [pageNum, setPageNum] = useState(1);
    let defaultBanker = runnerData.filter(item => item.status.toUpperCase().indexOf("SCRATCHED")<0)[0]?.no;
    const [bankerNum, setBankerNum] = useState(defaultBanker? parseInt(defaultBanker) : 1);
    const { handleClickCombOdds } = useQuickBet()
    //const isStartSell = data.status === 'START_SELL'
    const isStartSell = isStartSellpmPool(data);
    const { isMobileSize } = useWindowSize();
    const isRefund = data.status?.toUpperCase().indexOf("REFUND")>=0;


    const handleClickOdds = (oddsObj) => {
        if(isStartSell && !isMobileSize){
            handleClickCombOdds( oddsObj,betType)
        }
    }

    function handleKeyPadClick(num, pg) {
        if (num == -1) {
            setPageNum(pg);
            const startIndex=(pg - 1) * 14;
            const newBanker = runnerData.find((item,index) => index >= startIndex && item.status.toUpperCase().indexOf("SCRATCHED")<0)?.no;
            setBankerNum(parseInt(newBanker));
        }
        else {
            setBankerNum(num);
        }
    }


    const Row = ({ index }) => {
        if (data == null)
            return null;

        const row = []
        for (let i = 0; i < 2; i++) {
            let newComb = [];
            let odds = '';
            let tmpData = data?.oddsNodes?.filter(data => parseInt(data.combString) == bankerNum)[0];
            let tmpOdds
            if (tmpData != null) {
                tmpOdds = tmpData.bankerOdds[i * 5 + index];
                if (tmpOdds != null) {
                    let arr = tmpOdds.combString.split(',');
                    arr.forEach((item, index) => {
                        newComb.push(parseInt(item));
                    });
                    odds = isRefund ? "---" : tmpOdds.oddsValue;
                }
            }
            row.push(<div className={` quartet-odds-table-cell`} id={`${betType}_bankerTop10_${bankerNum}_${newComb.join('-')}`} key={`${betType}_bankerTop10_${bankerNum}_${newComb.join('-')}${i}`}>
                <div>
                    <div className='cell-label'>{newComb.join('-')}</div>
                    <div className={`cell-value ${isStartSell ? '' : 'disabled'}`}><span className='cp' onClick={()=> handleClickOdds(tmpOdds)}>{odds}</span></div>
                </div>
            </div>)
        }
        return <div className={` quartet-odds-table-row`}>
            {row}
        </div>
    }

    const Table = () => {
        const table = [];

        for (let i = 0; i < 5; i++) {
            table.push(<Row index={i} key={i}/>)
        }

        return <div className='wpq-odds-table-top10'>
            <div className={` quartet-odds-table-row`}></div>
            {table}
        </div>
    }

    return (<div className='quartet-odds-table-top10-box'>
        <header>{t(`LB_${betType}`)} {t(`LB_RC_WPQ_TABLE_BANKER`)}</header>
        <BankerKeyPad data={runnerData} page={pageNum} selectBanker={[bankerNum]} onKeyPadClick={handleKeyPadClick} />
        <Table />
    </div>)
}

export const TableTop10 = ({ betType, data, runnerData, count = 4}) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const [pageNum, setPageNum] = useState(1);
    const [currentRaceData, setCurrentRaceData] = useState([]);
    const { handleClickCombOdds } = useQuickBet()
    //const isStartSell = data.status === 'START_SELL'
    const isStartSell = isStartSellpmPool(data);
    const { isMobileSize } = useWindowSize();
    const isRefund = data.status?.toUpperCase().indexOf("REFUND")>=0;

    useEffect(() => {
        const data = runnerData.filter(item => item.no >= (pageNum - 1) * count + 1 && item.no <= pageNum * count);
        setCurrentRaceData(data);
    }, [pageNum, runnerData, count])


    const handleClickOdds = (oddsObj) => {
        if(isStartSell && !isMobileSize){
            handleClickCombOdds( oddsObj,betType)
        }
    }

    const HorseName = useCallback(({item, context}) => {
        const { t, i18n } = useTranslation();
        const scrStr = item?.status.toUpperCase().indexOf('SCRATCHED')>=0 ? `(${t('LB_RC_SCR')})` : '';
        const resStr = ['SCRATCHEDRESERVED', 'RESERVED', 'STANDBY'].includes(item?.status.toUpperCase()) ? `(${t('LB_RC_RESERVE')})` : '';
        const code = item?.horse?.code;
        const name = item?.["name_"+i18n.language] || '';
        if (isLocalMeeting(context.content.venue)) {
            return <>
                {item?.no} <span className='cp' onClick={() => {
                    clickHorseLink(t, `${code}`);
                    glassboxCustomEvent('To_JCRW', 'Horse_Info_' + context.content.page);
                }}>{name}</span> {scrStr}{resStr}
            </>;
        } else {
            return <>{item?.no} {name} {scrStr}{resStr}</>;
        }
    },[])

    const TableHeader = useCallback(({ data = [], count, context }) => {
        const { t, i18n } = useTranslation();

        const header = []
        for (let i = 0; i < count; i++) {
            const item = data[i]
            const scrStr = item?.status.toUpperCase().indexOf('SCRATCHED')>=0 ? `(${t('LB_RC_SCR')})` : '';
            const resStr = ['RESERVED', 'STANDBY'].includes(item?.status.toUpperCase()) ? `(${t('LB_RC_RESERVE')})` : '';
            header.push(<div className={`quartet-odds-table-cell ${'width' + count}`} key={`horse_${i}_${item?.no}`}> <HorseName item={item} context={context}/></div>)
        }
        return <div className='quartet-odds-table-top10-title  quartet-odds-table-row'>{header}</div>
    },[])

    const Row = useCallback(({ index, data, count, currentRaceData, isRefund, betType, isStartSell, handleClickOdds }) => {
        if (data == null)
            return null;

        const row = []
        for (let i = 0; i < count; i++) {
            let newComb = [];
            let odds = '';
            let tmpOdds = null;
            if (currentRaceData[i] != null) {
                let tmpData = data?.oddsNodes?.filter(item => parseInt(item.combString) == currentRaceData[i].no)[0];
                if (tmpData != null) {
                    tmpOdds = tmpData.bankerOdds[index];
                    if (tmpOdds != null) {
                        let arr = tmpOdds.combString.split(',');
                        arr.forEach((item) => {
                            newComb.push(parseInt(item));
                        });
                        odds = isRefund ? "---" : tmpOdds.oddsValue;
                    }
                }
            }
            row.push(<div className={`quartet-odds-table-cell ${'width' + count}`} id={`${betType}_bankerTop10_${1}_${index}`} key={`${betType}_bankerTop10_${i}_${index}`}>
                <div>
                    <div className='cell-label'>{newComb.join('-')}</div>
                    <div className={`cell-value ${isStartSell ? '' : 'disabled'}`}><span className='cp table-odds' onClick={()=> handleClickOdds(tmpOdds)}>{odds}</span></div>
                </div>
            </div>)
        }
        return <div className={` quartet-odds-table-row`}>
            {row}
        </div>
    },[])

    const Table = useCallback((props) => {

        const {currentRaceData, count, context } = props
        const table = [];

        for (let i = 0; i < 10; i++) {

            table.push(<Row index={i} key={i} {...props}/>)
        }

        return <div className='wpq-odds-table-top10'>
            <TableHeader context={context} count={count} data={currentRaceData}/>
            {table}
        </div>
    },[])

    const tableProps = { data, count, currentRaceData, isRefund, betType, isStartSell, handleClickOdds, context}

    return (<div className='quartet-odds-table-top10-box'>
        <header>{t(`LB_${betType}`)} {t(`LB_RC_WPQ_TABLE_BANKER`)}</header>
        <Table {...tableProps}/>
        <Pagination total={Math.ceil(runnerData.length / count)} onChange={setPageNum} pageNum={pageNum} />
    </div>)
}


export const TableTopALL = ({ betType, data,  numOfColumn = 4, runnerData, isVoid }) => {
    const { t } = useTranslation()
    const { isMobileSize } = useWindowSize();
    const [pageNum, setPageNum] = useState(1)
    const [currentOddsData, setCurrentOddsData] = useState([])
    const { handleClickCombOdds } = useQuickBet()
    // const numOfColumn = 4;
    const numOfRow = 12;
    const numOfItem = numOfRow * numOfColumn;
    //const isStartSell = data.status?.toUpperCase() === 'START_SELL'
    const isStartSell = isStartSellpmPool(data);
    const isRefund = data.status?.toUpperCase().indexOf("REFUND")>=0;

    useEffect(() => {
        const curdata = data?.oddsNodes?.filter((item, index) => index >= (pageNum - 1) * numOfItem && index <= (pageNum * numOfItem - 1));
        setCurrentOddsData(curdata)
    }, [pageNum, data, numOfItem])

    const handleClickOdds = (oddsObj) => {
        if(isStartSell && !isMobileSize){
            handleClickCombOdds( oddsObj,betType)
        }
    }

    const TableHeader = ({ data = [] }) => {

        return <div className=' quartet-odds-table-top10-title  quartet-odds-table-row'></div>
    }

    const Row = ({ index }) => {

        if (currentOddsData == null)
            return null;

        const row = []
        for (let i = 0; i < numOfColumn; i++) {
            let tmpData = currentOddsData[(i * numOfRow) + index];
            let newComb = [];
            let odds = '';
            let isScratch = false;
            let isReserve = false;
            if (tmpData != null) {
                let arr = tmpData.combString.split(',');
                arr.forEach((item, index) => {
                    let rNo = parseInt(item);
                    let runnerStat = runnerData.filter(x=>parseInt(x.no)===rNo)[0]?.status.toUpperCase();
                    if ( runnerStat?.indexOf("SCRATCH") >= 0 ) {
                        isScratch = true;
                    }
                    else if ( ['RESERVED', 'STANDBY'].includes(runnerStat) ) {
                        isReserve = true;
                    }
                    newComb.push(rNo);
                });
                odds = (isRefund || isReserve) ? "---" : (isScratch ? t('LB_RC_SCR') : tmpData.oddsValue);
            }
            let disabled = !isStartSell || isScratch
            row.push(<div className={`quartet-odds-table-cell ${'width' + numOfColumn}`} id={`${betType}_all_${newComb.join('-')}`}>
                <div>
                    <div className='cell-label'>{newComb.join('-')}</div>
                    <div className={`cell-value ${ disabled ?  'disabled' : ''}`}>{!isMobileSize ? <span className={`${ disabled ? '' : 'cp'} table-odds`} onClick={()=> !disabled && handleClickOdds(tmpData)} >{odds}</span> : <span onClick={()=> !disabled && handleClickOdds(tmpData)}>{odds}</span>}</div>
                </div>
            </div>)
        }
        return <div className={` quartet-odds-table-row`}>
            {row}
        </div>
    }

    const Table = () => {
        const table = []

        for (let i = 0; i < numOfRow; i++) {
            table.push(<Row index={i} key={i}/>)
        }


        return <div className='wpq-odds-table-top10'>
            <TableHeader />
            {table}
        </div>
    }

    return (<div className='quartet-odds-table-top10-box'>
        <header>{t(`LB_${betType}`)} {t(`LB_RC_WPQ_TABLE_`)}</header>
        <Table />
        <Pagination total={Math.ceil(data?.oddsNodes?.length / numOfItem)} onChange={setPageNum} pageNum={pageNum} />
    </div>)
}

function InvestmentGraphRow({ betType, no, status, hrName, pos1, pos2, pos3, pos4, pos1Total, pos2Total, pos3Total, pos4Total, isTop1, isTop2, isTop3, isTop4 }) {
    const { t, i18n } = useTranslation()
    let multiplier = betType=='QTT' ? 75 : 100;
    let pos1Width = ((pos1 * multiplier) / pos1Total);
    let pos2Width = ((pos2 * multiplier) / pos2Total);
    let pos3Width = ((pos3 * multiplier) / pos3Total);
    let pos4Width = betType=='QTT' ? ((pos4 * multiplier) / pos4Total) : 0;

    let pos1AmountWidth = pos1Width > 60 ? 25 : (pos1Width + 2);
    let pos2AmountWidth = pos2Width > 60 ? 25 : (pos2Width + 2);
    let pos3AmountWidth = pos3Width > 60 ? 25 : (pos3Width + 2);
    let pos4AmountWidth = pos4Width > 60 ? 25 : (pos4Width + 2);

    return <div className={`investmentGraph-odds-table-row${betType=='QTT' ? '-qtt': ''}`}>
        <div className="investmentGraph-odds-table-cell">{no}</div>
        <div className="investmentGraph-odds-table-cell">{hrName}</div>
        <div className="investmentGraph-odds-table-cell money" ><div className={`char-bar ${isTop1 && pos1>0 ? 'top' : ''}`} style={{ width: pos1Width + "%" }}></div><div style={{ marginLeft: pos1AmountWidth + "%" }}>{status.toUpperCase().indexOf('SCRATCHED')>=0 ? t('LB_RC_SCR') : currencyFormat(pos1)}</div></div>
        <div className="investmentGraph-odds-table-cell money"><div className={`char-bar ${isTop2 && pos2>0 ? 'top' : ''}`} style={{ width: pos2Width + "%" }}></div><div style={{ marginLeft: pos2AmountWidth + "%" }}>{status.toUpperCase().indexOf('SCRATCHED')>=0 ? t('LB_RC_SCR') : currencyFormat(pos2)}</div></div>
        <div className="investmentGraph-odds-table-cell money"><div className={`char-bar ${isTop3 && pos3>0 ? 'top' : ''}`} style={{ width: pos3Width + "%" }}></div><div style={{ marginLeft: pos3AmountWidth + "%" }}>{status.toUpperCase().indexOf('SCRATCHED')>=0 ? t('LB_RC_SCR') : currencyFormat(pos3)}</div></div>
        {betType=='QTT' ? <div className="investmentGraph-odds-table-cell money"><div className={`char-bar ${isTop4 && pos4>0 ? 'top' : ''}`} style={{ width: pos4Width + "%" }}></div><div style={{ marginLeft: pos4AmountWidth + "%" }}>{status.toUpperCase().indexOf('SCRATCHED')>=0 ? t('LB_RC_SCR') : currencyFormat(pos4)}</div></div> : null}
    </div>
}

export const InvestmentGraph = ({ betType, data, runnerData }) => {
    const { t, i18n } = useTranslation()

    let rows = [];

    rows.push(<div className={`investmentGraph-odds-table-row${betType=='QTT' ? '-qtt': ''}`}>
        <div className="investmentGraph-odds-table-cell">{t('LB_RC_ODDS_TABLE_No')}</div>
        <div className="investmentGraph-odds-table-cell">{t('LB_RC_ODDS_TABLE_HRNAME')}</div>
        <div className="investmentGraph-odds-table-cell">{t('LB_RC_ODDS_TABLE_1POS')}&nbsp;{t('LB_RC_ODDS_TABLE_POSSUF')}</div>
        <div className="investmentGraph-odds-table-cell">{t('LB_RC_ODDS_TABLE_2POS')}&nbsp;{t('LB_RC_ODDS_TABLE_POSSUF')}</div>
        <div className="investmentGraph-odds-table-cell">{t('LB_RC_ODDS_TABLE_3POS')}&nbsp;{t('LB_RC_ODDS_TABLE_POSSUF')}</div>
        {betType=='QTT' ? <div className="investmentGraph-odds-table-cell">{t('LB_RC_ODDS_TABLE_4POS')}&nbsp;{t('LB_RC_ODDS_TABLE_POSSUF')}</div> : null}
    </div>);
    let pos1Total = 0;
    let pos2Total = 0;
    let pos3Total = 0;
    let pos4Total = 0;
    let top1Index = 0;
    let top2Index = 0;
    let top3Index = 0;
    let top4Index = 0;
    let top1Amount = 0;
    let top2Amount = 0;
    let top3Amount = 0;
    let top4Amount = 0;
    let amt1 = 0;
    let amt2 = 0;
    let amt3 = 0;
    let amt4 = 0;
    for (let i = 0; i < runnerData.length; i++) {
        let rowData = data.filter(x => parseInt(x.combString) == runnerData[i].no)[0];

        if (rowData == null) continue;
        let amt1 = parseInt(rowData.bankerOdds[0].oddsValue);
        let amt2 = parseInt(rowData.bankerOdds[1].oddsValue);
        let amt3 = parseInt(rowData.bankerOdds[2].oddsValue);
        let amt4 = betType=='QTT' ? parseInt(rowData.bankerOdds[3].oddsValue) : 0;
        pos1Total += amt1;
        pos2Total += amt2;
        pos3Total += amt3;
        pos4Total += amt4;

        let isTop1 = amt1 > top1Amount;
        let isTop2 = amt2 > top2Amount;
        let isTop3 = amt3 > top3Amount;
        let isTop4 = amt4 > top4Amount;
        top1Amount = Math.max(amt1, top1Amount);
        top2Amount = Math.max(amt2, top2Amount);
        top3Amount = Math.max(amt3, top3Amount);
        top4Amount = Math.max(amt4, top4Amount);
        top1Index = isTop1 ? amt1 : top1Index;
        top2Index = isTop2 ? amt2 : top2Index;
        top3Index = isTop3 ? amt3 : top3Index;
        top4Index = isTop4 ? amt4 : top4Index;
    }
    for (let i = 0; i < runnerData.length; i++) {

        let hrName = runnerData[i]['name_'+i18n.language];
        let status = runnerData[i].status;
        let no = runnerData[i].no;
        let rowData = data.filter(x => parseInt(x.combString) == runnerData[i].no)[0];
        if (rowData == null) continue;
        let pos1 = parseInt(rowData.bankerOdds[0].oddsValue);
        let pos2 = parseInt(rowData.bankerOdds[1].oddsValue);
        let pos3 = parseInt(rowData.bankerOdds[2].oddsValue);
        let pos4 = betType=='QTT' ? parseInt(rowData.bankerOdds[3].oddsValue) : 0;
        let isTop1 = pos1 == top1Index;
        let isTop2 = pos2 == top2Index;
        let isTop3 = pos3 == top3Index;
        let isTop4 = pos4 == top4Index;
        rows.push(<InvestmentGraphRow betType={betType} no={no} status={status} hrName={hrName} pos1={pos1} pos2={pos2} pos3={pos3} pos4={pos4}
            pos1Total={pos1Total} pos2Total={pos2Total} pos3Total={pos3Total} pos4Total={pos4Total}
                isTop1={isTop1} isTop2={isTop2} isTop3={isTop3} isTop4={isTop4} key={i} />)
    }


    return (
        <Popup
            trigger={<div className="investmentGraph-label">{t('LB_RACE_INVESTMENT_GRAPH')}</div>}
            className="investment-graph-popup"
            modal
            nested
        >
            {close => (
                <div className="investmentGraph-modal">
                    <div className="header">
                        {t('LB_RACE_INVESTMENT_GRAPH')}
                    </div>
                    <div className="content">
                        <div className="odds-table-investmentGraph-box">{rows}</div>
                    </div>
                </div>
            )}
        </Popup>
    );
}

const clipboardTableTop = (props) => {
    const {betType, t, getTop20Odds} = props;
    const data = getTop20Odds();
    const isRefund = data?.status?.toUpperCase().indexOf("REFUND")>=0;
    return clipboardTableTop20(data?.oddsNodes, `${t(`LB_${betType}`)} ${t(`LB_RC_TABLE_TOP20`)}`, isRefund);
}

const clipboardTableBanker = (props) => {
    const {betType, t, i18n, getBankerTop10Data, runnerData} = props;
    const data = getBankerTop10Data();
    const isRefund = data?.status?.toUpperCase().indexOf("REFUND")>=0;
    return clipboardTableBankerTop10(data?.oddsNodes, runnerData, `${t(`LB_${betType}`)} ${t(`LB_RC_WPQ_TABLE_BANKER`)}`, isRefund, t, i18n);
}

const clipboardTableInv = (props) => {
    const {betType, t, getInvestmentData, runnerData} = props;
    const data = getInvestmentData();
    return clipboardInvestment(betType, data, runnerData, `${t(`LB_${betType}`)} ${t(`LB_RC_TABLE_INVESTMENT`)}`, t);
}

const clipboardTableAll = (props) => {
    const {betType, t, getAllOdds, runnerData} = props;
    const data = getAllOdds();
    return clipboardTableALL(data, runnerData, `${t(`LB_${betType}`)} ${t(`LB_RC_WPQ_TABLE_`)}`, t);
}

const clipboardTable = (props) => {
    const {betType, context, t, i18n, getTop20Odds, getBankerTop10Data, getAllOdds, getInvestmentData, runnerData} = props;

    switch (context.content.displayOpt) {
        case "top":
            return clipboardTableTop(props);
        case "banker":
            return clipboardTableBanker(props);
        case "inv":
            return clipboardTableInv(props);
        case "":
            return clipboardTableAll(props);
    }
    return "";
}