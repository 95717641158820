import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useBetSlip } from './BetSlipHooks'
import { FootballContext } from '../Home/FootballPage'
import { getAllUpCalEntries } from '../Football/Common/AddBet';
import { ConfigContext} from '../Common/ConfigHelper'
import { isTournamentPage } from '../Football/Common/Common';
import { matchCompare } from '../Football/Data/useFootballPush/common';
import { getPersonalSettings } from '../Home/PersonalSettings/config'
import { updateOriginLocation } from '../Common/ui-components/AddToSlipAnimation';
import { WATrackerTrackClickEvent } from '../Common/utils/wa';
import { glassboxCustomEvent } from '../Common/utils/glassbox';
import { GlobalContext } from '../../contexts/GlobalContext';
import { isCRSOtherSels } from '../Football/Common/CRSTable'
import { Query} from '../Common/graphqlFetch'
import { getAllUpMatchQuery, getQueryVariables } from '../Football/Data/DataQuery';

const FBAddSlipBtn = () => {
    const { t } = useTranslation();
    const { addToBetSlip, betBaskets, currentBasket } = useBetSlip()
    const [canShowErrMsg, setCanShowErrMsg] = useState(true)
    const FBContext = useContext(FootballContext)
    const clearOddsSelection = FBContext?.clearOddsSelection
    const Config = useContext(ConfigContext)
    const footballConfig = Config?.football

    const handleClickAddSlipBtn = () => {
        const content = FBContext?.content
        // const AllPoolsMinBet = footballConfig.AllPoolsMinBet
        const AllPoolsMinBet = getPersonalSettings('football')
        const matchList = content?.matchData?.matches
        const tournData = content?.tournData?.tournaments
        if(!content && (!matchList || !tournData)) return
        const checkedLinesObJ = {}
        const betLineList = []
        let canShowErrMsg = true
        let checkedLinesList = content?.oddsSelection?.filter((item) => {
            return item.checked
        })

        // TOURNAMENT
        if (isTournamentPage(content.betType)) {
            checkedLinesList = checkedLinesList.map((item) => {
                const tournInfo = tournData?.find(tournItem => tournItem.id === item.matchId)
                const [betType, lineId, combId, poolId, comb] = item.id.split('_')
                const currentPool = tournInfo?.foPools?.find(pool => pool.id === poolId)
                const currentLine = currentPool?.lines?.find(line => lineId === line.lineId)
                const currentComb = currentLine?.combinations?.find(comb => comb.combId === combId)
                let currentCombNameObj = currentComb?.selections?.[0]

                if ('GPF' === betType) {
                    const combFirstTeam = comb[1];
                    const combSecondTeam = comb[4];
                    const combId1 = currentComb.selections.find(team => team.str === combFirstTeam);
                    const combId2 = currentComb.selections.find(team => team.str === combSecondTeam);
                    const name_en = `${combId1.str} ${combId1.name_en} - ${combId2.str} ${combId2.name_en}`
                    const name_ch = `${combId1.str} ${combId1.name_ch} - ${combId2.str} ${combId2.name_ch}`
                    currentCombNameObj = {
                        name_en,
                        name_ch
                    }
                }else if('GPW' === betType) {
                    const comb = currentComb.selections[0]
                    const name_en = `${comb.str} ${comb.name_en}`
                    const name_ch = `${comb.str} ${comb.name_ch}`
                    currentCombNameObj = {
                        name_en,
                        name_ch
                    }
                }
                return {
                    ...item,
                    betType,
                    comb,
                    poolId,
                    combId,
                    lineId,
                    currentComb,
                    currentLine,
                    currentPool,
                    currentCombNameObj
                }
            })

            // MATCH_SINGLE
        } else {
            checkedLinesList = checkedLinesList.map((item) => {
                const matchInfo = matchList?.find(matchItem => matchItem.id === item.matchId)
                const [betType, lineId, combId, poolId, comb] = item.id.split('_')

                let condition = matchInfo?.foPools.find(pool => pool.id === poolId).lines.find((line) => lineId === line.lineId).condition
                let currentPool = matchInfo?.foPools?.find(pool => pool.id === poolId)
                let currentCombNameObj = currentPool?.lines?.find((line) => lineId === line.lineId)?.combinations?.find(i => combId === i.combId)?.selections?.[0]

                condition = updateCondition( condition,betType,comb)

                return {
                    ...item,
                    betType,
                    instNo: currentPool?.instNo,
                    comb,
                    poolId,
                    combId,
                    lineId,
                    condition,
                    currentCombNameObj,
                    currentPool
                }
            })

        }


        if (isTournamentPage(content.betType)) {
            checkedLinesList.forEach(item => {
                if (checkedLinesObJ[item.id]) {
                    checkedLinesObJ[item.id].combs.push(item)
                } else {

                    checkedLinesObJ[item.id] = {
                        combs: [item],
                        matchId: item.matchId,
                        betType: item.betType,
                        specialLine: item.betType === 'TSP' ? item.currentPool : null
                    }
                }
            })
        } else {
            checkedLinesList.forEach(item => {
                checkedLinesObJ[item.id] = {
                    combs: [item],
                    matchId: item.matchId,
                    betType: item.betType,
                    instNo: item.instNo,
                    specialLine: item.betType === 'MSP' ? item.currentPool :null
                }

            })

        }
        Object.keys(checkedLinesObJ).forEach(key => {
            let betLineObj = { ...checkedLinesObJ[key] }
            let id = ''
            betLineObj.combs.sort().forEach((item) => {
                if(isTournamentPage(content.betType) && item.currentPool.match){
                    betLineObj.matchInfo = {
                        ...item.currentPool.match,
                        isInteractiveServiceAvailable: true,
                        inplayDelay: false
                    }
                    betLineObj.inplayDelay = false
                }
                id += item.id
            })
            if (betBaskets[currentBasket].find(item => item.id === id)) {
                if(canShowErrMsg){
                    window.alert(t('LB_BS_ADDTOSLIP_FB_ERR_MSG1'))
                }
                canShowErrMsg = false

                return
            }

            betLineObj.prod = "FB"
            betLineObj.betType = checkedLinesObJ[key].betType
            betLineObj.instNo = checkedLinesObJ[key].instNo
            betLineObj.id = id

            // TOURNAMENT
            if (isTournamentPage(content.betType)) {
                const tournInfo = tournData?.find(tournItem => tournItem.id === checkedLinesObJ[key].matchId)
                betLineObj = {
                    ...betLineObj,
                    frontEndId: tournInfo?.frontEndId,
                    tournamentNameCH: tournInfo?.name_ch,
                    tournamentNameEN: tournInfo?.name_en,
                    tournamentCode: tournInfo?.code,
                    tournInfo
                }
                // Match single
            } else {
                let matchInfo = matchList?.find(item => item.id === checkedLinesObJ[key].matchId)
                const homeTeam = matchInfo?.homeTeam
                const awayTeam = matchInfo?.awayTeam
                let matchTeamEN = `${homeTeam?.name_en} vs ${awayTeam?.name_en}`
                let matchTeamCH = `${homeTeam?.name_ch} 對 ${awayTeam?.name_ch}`
                let frontEndId =  matchInfo?.frontEndId
                betLineObj = {
                    ...betLineObj,
                    frontEndId,
                    inplayDelay: matchInfo?.inplayDelay,
                    tournamentNameCH: matchInfo?.tournament?.name_ch,
                    tournamentNameEN: matchInfo?.tournament?.name_en,
                    tournamentCode: matchInfo?.tournament?.code,
                    matchTeamEN,
                    matchTeamCH,
                    matchInfo
                }
                if(['CHP','TQL'].includes(checkedLinesObJ[key].betType)){
                    betLineObj.matchTeamEN =  null;
                    betLineObj.matchTeamCH = null;
                    betLineObj.frontEndId =  matchInfo?.tournament?.frontEndId;
                    betLineObj.tournInfo =  matchInfo?.tournament;
                }
            }
            betLineObj.noOfBets = betLineObj.combs.length;
            betLineObj.unitBet = parseInt(AllPoolsMinBet[checkedLinesObJ[key].betType] || 10, 10)
            betLineList.push(betLineObj)
        })
        betLineList.sort((a, b) => singleMatchSortFunc(a, b, content.config.AllOddsOrder));
        addToBetSlip(betLineList) && clearOddsSelection && clearOddsSelection()
        setCanShowErrMsg(true)
        if (betLineList.length > 0) {
            glassboxCustomEvent("Add_to_Slip")
        }
    }

    return (
        <div className="AddToSlip" onClick={handleClickAddSlipBtn}>
            {t('LB_ADDTOSLIP')}
        </div>
    )
}

export default FBAddSlipBtn;


export const FBMixAllUpAddtoSlipBtn = ({MixAllUpParams, disabled}) =>{
    const { t } = useTranslation();
    const { addToBetSlip, betLines } = useBetSlip()
    const FBContext = useContext(FootballContext)
    const clearOddsSelection = FBContext?.clearOddsSelection
    const { betUnit, noOfComb, selectedFormula } = MixAllUpParams;
    // const Config = useContext(ConfigContext)
    // const footballConfig = Config?.football
    const globalContext = useContext(GlobalContext);

    const handleClickAddSlipBtn = () => {
        globalContext.setIsWaitMobileFootballAllupCal(true)
        const content = FBContext?.content
        const matchList = content?.matchData?.matches
        const tournData = content?.tournData?.tournaments
        // const AllPoolsMinBet = footballConfig.AllPoolsMinBet
        const AllPoolsMinBet = getPersonalSettings('football')
        let unitBet = betUnit || AllPoolsMinBet.ALUP;
        if(!content && (!matchList || !tournData)) return
        const checkedLinesObJ = {}
        const betList = []
        let allUpId = 'ALUP'+ selectedFormula;
        let checkedLinesList = content?.oddsSelection?.filter((item) => {
            return item.checked
        }).sort((a, b) => a.matchId - b.matchId)
        if(checkedLinesList?.length < 2) return;

        const matchIDArr = []
        const checkedArr = content?.oddsSelection.filter(i => {
            if(i.checked) {
                if(matchIDArr.indexOf(i.matchId) === -1) {
                    matchIDArr.push(i.matchId)
                    return true
                }
            }
            return false
        })

        if(checkedArr.length > 6 && checkedArr.some(i => content.config.MixAllup6LegPools.indexOf(i.oddsType) !== -1)) {
            const MixAllup6LegPoolsItem = checkedArr.find(i => content.config.MixAllup6LegPools.indexOf(i.oddsType) !== -1)
            alert(t('LB_FB_CAL_MAX_6_LEG_ALERT_SAME').replace('{0}', t('LB_FB_TITLE_' + MixAllup6LegPoolsItem.oddsType)))
            return false
        }


        checkedLinesList = checkedLinesList.map((item) => {
            const matchInfo = matchList?.find(matchItem => matchItem.id === item.matchId)
            const [betType, lineId, combId, poolId, comb] = item.id.split('_')
            let condition = matchInfo?.foPools.find(pool => pool.id === poolId).lines.find((line) => lineId === line.lineId).condition
            let currentPool = matchInfo?.foPools?.find(pool => pool.id === poolId)
            let currentCombNameObj = currentPool?.lines?.find((line) => lineId === line.lineId)?.combinations?.find(i => combId === i.combId)?.selections?.[0]
            if ((['HHA']).includes(betType)) {
                if (comb === 'A') condition = condition > 0 ? `-${Math.abs(condition)}` : `+${Math.abs(condition)}`
                else {
                    condition = condition > 0 ? `+${Math.abs(condition)}` : `-${Math.abs(condition)}`
                }
            }else if(window.fbConfig.HandicapPools.includes(betType)){
               if(comb === 'A'){
                   condition = condition.split('/').map(condItem => {
                      if(Number(condItem) == 0){ return `0`; }
                      return condItem > 0 ? `-${Math.abs(condItem)}` : `+${Math.abs(condItem)}`
                   }).join('/')
               }else{
                condition = condition.split('/').map(condItem => {
                    if(Number(condItem) == 0){ return `0`; }
                    return condItem > 0 ?`+${Math.abs(condItem)}` : `-${Math.abs(condItem)}`
                 }).join('/')
               }
            }else if(['FHL'].includes(betType)){
                condition = condition.split('/').map(condItem => Number(condItem)).join('/')
            }
            return {
                ...item,
                betType,
                comb,
                poolId,
                combId,
                lineId,
                condition,
                currentCombNameObj,
                currentPool
            }
        })

        checkedLinesList.forEach(item => {
            if(checkedLinesObJ[item.matchId]){
                checkedLinesObJ[item.matchId].combs.push(item)
            }else{

                checkedLinesObJ[item.matchId] = {
                    combs: [item],
                    matchId: item.matchId,
                    betType: item.betType,
                    specialLine: item.betType === 'MSP' ? item.currentPool :null
                }
            }

        })

        Object.keys(checkedLinesObJ).sort((a,b) => a - b).forEach(key => {
            let id = ''
            let betObj = { ...checkedLinesObJ[key] }
            betObj.combs.sort((a,b) => {
                if(a.lineId > b.lineId) {
                    return 1
                }else if(a.lineId < b.lineId ) {
                    return -1
                }
                return a.combId - b.combId
            }).forEach((item) => {
                id += item.id
            })

            betObj.prod = "FB"
            betObj.betType = checkedLinesObJ[key].betType
            betObj.id = id
            let matchInfo = matchList?.find(item => item.id === checkedLinesObJ[key].matchId)

            const homeTeam = matchInfo?.homeTeam
            const awayTeam = matchInfo?.awayTeam
            let matchTeamEN = `${homeTeam?.name_en} vs ${awayTeam?.name_en}`
            let matchTeamCH = `${homeTeam?.name_ch} 對 ${awayTeam?.name_ch}`
            let frontEndId =  matchInfo?.frontEndId
            betObj = {
                ...betObj,
                frontEndId,
                tournamentNameCH: matchInfo?.tournament?.name_ch,
                tournamentNameEN: matchInfo?.tournament?.name_en,
                tournamentCode: matchInfo?.tournament?.code,
                matchTeamEN,
                matchTeamCH,
                matchInfo
            }
            allUpId += id
            betList.push(betObj)
        })
        if (betLines.find(item => item.id === allUpId)) {
            window.alert(t('LB_BS_ADDTOSLIP_FB_ERR_MSG1'))
            return
        }
        sortAllUpMatchs(betList)
        const AllUpData = {
            prod: 'FB',
            betType: 'ALUP',
            id: allUpId,
            unitBet,
            noOfComb,
            betList,
            selectedFormula
        }

        let successAdd = addToBetSlip([AllUpData])
        clearOddsSelection && successAdd && clearOddsSelection()
        WATrackerTrackClickEvent("betslip-fb-addbet-01");
        glassboxCustomEvent("All_Up_Calculator_Add_to_Slip", "Football_All_Up");
        updateOriginLocation(`.allup-cal-panel-set .AddToSlip`)
    }

    return (
        <div className={`AddToSlip ${disabled ? 'disabled' : ''}`} onClick={() => { !disabled && handleClickAddSlipBtn(); }}>
            {t('LB_ADDTOSLIP')}
        </div>
    )
}



export const AddFBAllupCalcSlipBtn = (props) => {
    const { t } = useTranslation();
    const { addToBetSlip, betLines } = useBetSlip()
    const Config = useContext(ConfigContext)
    const footballConfig = Config?.football
    const globalContext = useContext(GlobalContext);
    const { isPushing} = globalContext.mqttClient;
    const enableOddsPushRef = useRef(globalContext.globalState.commonCtrl.enableOddsPushButton)
    enableOddsPushRef.current = globalContext.globalState.commonCtrl.enableOddsPushButton && window.globalConfig.FB_ODDS_PUSH

    const FBContext = useContext(FootballContext)
    const content = FBContext?.content
    const matchList = content?.matchData?.matches

    const updateAllUpData = (paras, allupData, reloadCount) => {
        return Promise.all([Query({ 
            query : getAllUpMatchQuery(),
            variables: getQueryVariables(paras)
        })])
        .then(([results]) => {
            if(!reloadCount && results.errors){
                updateAllUpData(paras, allupData, 1)
                return
            }
            const matches = results?.data?.matches || []
            allupData.forEach(item =>{
                let match = matches.find( m => m.id === item.id)
                let pool = match?.foPools?.find(p => p.id === item.poolId)
                let line = pool?.lines?.find(l => l.lineId ===  item.lineId)
                let comb = line?.combinations?.find(c => c.combId === item.combId)
                let lineSell =  line?.status?.toUpperCase() =='AVAILABLE';
                let poolSell =  pool?.status?.toUpperCase() == 'SELLINGSTARTED';
                let combSell = comb?.status?.toUpperCase() == 'AVAILABLE'
                item.isSelling = lineSell && poolSell && combSell && match?.isInteractiveServiceAvailable
            })
        });
    }

    const handleClickAddSlipBtn = async () => {
        let allupData = getAllUpCalEntries();
        const AllPoolsMinBet = footballConfig.AllPoolsMinBet
        // const AllPoolsMinBet = getPersonalSettings('football')
        let unitBetErr = false
        if(allupData.length <= 0 ){
            return
        }
        let allUpIds = allupData.map(item => item.id)
        const paras = {
            page: 'ALLODDS',
            matchIds: allUpIds
        }
        if(!enableOddsPushRef.current || !isPushing ){
            await updateAllUpData(paras, allupData )
        }
        const { betUnit, noOfComb, selectedFormula } = props.allupAddSlipObj;
        const betLineList = []
        let textMsg = ''
        let errLength = 0;
        for (let i = 0; i < allupData.length; i++) {
            const element = allupData[i];
            if (!element.isSelling) {
                textMsg += `${element.frontEndId} ${t('LB_FB_BET_ALLUP_PARTERRMSG')} \n`;
                errLength = errLength + 1;
            }
            if (errLength === allupData.length) {
                return window.alert(t('LB_FB_BET_ALLUP_ALLERRMSG'))
            }
        }
        if (textMsg) {
            return window.alert(textMsg)
        }

        let betLineObj = {}
        const betList = []
        if (allupData.length === 1) {
            const combData =  allupData[0]
            let combStr = isCRSOtherSels(combData.combinations?.[0]?.str) ? combData.combinations?.[0]?.str : combData.str
            const id = `${combData.betType}_${combData.lineId}_${combData.combId}_${combData.poolId}_${combStr}`

            const matchInfo = matchList?.find(matchItem => matchItem.id === combData.id)
            const currentPool = matchInfo?.foPools?.find(pool => pool.id === combData.poolId)

            const matchTeamEN = `${combData.homeTeam_en} vs ${combData.awayTeam_en}`
            const matchTeamCH = `${combData.homeTeam_ch} 對 ${combData.awayTeam_ch}`
            let {condition,betType,str}=combData
            condition = updateCondition( condition,betType,str)
            const combs = []
            if(betUnit * noOfComb < AllPoolsMinBet[betType]){
                return
            }
            combs.push({
                id,
                betType: betType,
                comb: combData.str,
                combId: combData.combId,
                poolId: combData.poolId,
                lineId: combData.lineId,
                oddsVal : combData.oddsVal,
                condition : condition,
                currentPool,
                matchId: combData.id,
                currentCombNameObj: {
                    str: combData.str,
                    isSelling: combData.isSelling,
                    name_en: combData.name_en,
                    name_ch: combData.name_ch
                },
                currentPool: combData.foPool
            })
            let betObj = {
                id,
                betType: combData.betType,
                instNo: combData.instNo,
                combs,
                frontEndId: combData.frontEndId,
                inplayDelay: combData.inplayDelay,
                matchId: combData.id,
                unitBet: betUnit,
                tournamentCode: combData.tournCode,
                tournamentNameCH: combData.tournName_ch,
                tournamentNameEN: combData.tournName_en,
                noOfBets: 1,
                prod: 'FB',
                matchTeamCH,
                matchTeamEN,
                matchInfo: matchInfo || combData.matchInfo
            }
            if(betLines.find(i => i.id === id)) {
                window.alert(t('LB_BS_ADDTOSLIP_FB_ERR_MSG1'))
                return
            }

            addToBetSlip([betObj])
            WATrackerTrackClickEvent("betslip-fb-addbet-01");
            glassboxCustomEvent("All_Up_Calculator_Add_to_Slip", "Football_All_Up");
            return;
        }

        let allUpId = 'ALUP'+ selectedFormula
        let inplayDelay = false;
        allupData.forEach(combData =>{
            const combs = []
            let combStr = isCRSOtherSels(combData.combinations?.[0]?.str) ? combData.combinations?.[0]?.str : combData.str
            let id = `${combData.betType}_${combData.lineId}_${combData.combId}_${combData.poolId}_${combStr}`

            const matchTeamEN = `${combData.homeTeam_en} vs ${combData.awayTeam_en}`
            const matchTeamCH = `${combData.homeTeam_ch} 對 ${combData.awayTeam_ch}`
            let {condition,betType,str}=combData
            condition = updateCondition( condition,betType,str)
            inplayDelay = inplayDelay || combData.inplayDelay;

            if(betUnit * noOfComb < AllPoolsMinBet[betType]){
                unitBetErr = true
                return
            }
            combs.push({
                id,
                betType: betType,
                comb: combData.str,
                combId: combData.combId,
                poolId: combData.poolId,
                lineId: combData.lineId,
                oddsVal : combData.oddsVal,
                matchId: combData.id,
                condition : condition,
                currentCombNameObj: {
                    str: combData.str,
                    isSelling: combData.isSelling,
                    name_en: combData.name_en,
                    name_ch: combData.name_ch
                },
                currentPool: combData.foPool
            })
            let betObj = {
                id,
                betType: combData.betType,
                instNo: combData.instNo,
                combs,
                frontEndId: combData.frontEndId,
                matchId: combData.id,
                tournamentCode: combData.tournCode,
                tournamentNameCH: combData.tournName_ch,
                tournamentNameEN: combData.tournName_en,
                prod: 'FB',
                matchTeamCH,
                matchTeamEN,
                matchInfo: combData.matchInfo
            }
            allUpId = allUpId + id
            betList.push(betObj)
        })
        if(betLines.find(i => i.id === allUpId)) {
            window.alert(t('LB_BS_ADDTOSLIP_FB_ERR_MSG1'))

            return
        }
        if(unitBetErr){
            return
        }


        betLineObj = {
            prod: 'FB',
            betType: 'ALUP',
            inplayDelay: inplayDelay,
            unitBet: betUnit,
            id: allUpId,
            noOfComb,
            betList,
            selectedFormula
        }
        updateOriginLocation(`.allup-cal-content .AddToSlip`)
        betLineList.push(betLineObj)
        addToBetSlip(betLineList)
        WATrackerTrackClickEvent("betslip-fb-addbet-01");
        glassboxCustomEvent("All_Up_Calculator_Add_to_Slip", "Football_All_Up");
    }

    return (
        <div className={`AddToSlip ${props.disabled ? "disabled" : ''}`} onClick={()=> !props.disabled && handleClickAddSlipBtn()}>
            {t('LB_ADDTOSLIP')}
        </div>
    )
}

const updateCondition = (condition, betType, str) => {
    if ((['HHA']).includes(betType)) {
        if (str === 'A') return condition > 0 ? `-${Math.abs(condition)}` : `+${Math.abs(condition)}`
        else {
            return condition > 0 ? `+${Math.abs(condition)}` : `-${Math.abs(condition)}`
        }
    }else if(window.fbConfig.HandicapPools.includes(betType)){
       if(str === 'A'){
           return condition.split('/').map(condItem => {
              if(Number(condItem) == 0){ return `0`; }
              return condItem > 0 ? `-${Math.abs(condItem)}` : `+${Math.abs(condItem)}`
           }).join('/')
       }else{
        return condition.split('/').map(condItem => {
            if(Number(condItem) == 0){ return `0`; }
            return condItem > 0 ?`+${Math.abs(condItem)}` : `-${Math.abs(condItem)}`
         }).join('/')
       }
    }else if(['HIL', 'FHL', 'CHL', 'EHL', 'ECH', 'FCH'].includes(betType)){
        return condition.split('/').map(condItem => Number(condItem)).join('/')
    }
}


const sortAllUpMatchs = (data) => {
    data.sort(( a, b )=> {
        if( a.matchInfo.matchDate < b.matchInfo.matchDate ){
            return -1;
        }
        if( a.matchInfo.matchDate > b.matchInfo.matchDate ){
            return 1;
        }
        if ( a.matchInfo.sequence < b.matchInfo.sequence ){
            return -1;
        }
        if ( a.matchInfo.sequence > b.matchInfo.sequence ){
            return 1;
        }
        return 0;
    })
}

const singleMatchSortFunc = (prevObj, nextObj, AllOddsOrder) => {
    let firstWeight = 0, secondWeight = 0;
    if(prevObj?.matchInfo?.kickOffTime){
        if (new Date(prevObj.matchInfo.kickOffTime) < new Date(nextObj.matchInfo.kickOffTime)) {
            firstWeight += 512, secondWeight += 1024;
        } else if (new Date(prevObj.matchInfo.kickOffTime) > new Date(nextObj.matchInfo.kickOffTime)) {
            firstWeight += 1024, secondWeight += 512;
        }
    }
    if (Number(prevObj.matchId) < Number(nextObj.matchId)) {
        firstWeight += 128, secondWeight += 256;
    } else if (Number(prevObj.matchId) > Number(nextObj.matchId)) {
        firstWeight += 256, secondWeight += 128;
    }
    const [prevBetType, prevLineId, prevCombId, prevPoolId, prevComb] = prevObj.id.split('_')
    const [nextBetType, nextLineId, nextCombId, nextPoolId, nextComb] = nextObj.id.split('_')
    const indexPre = AllOddsOrder.indexOf(prevBetType)
    const indexNext = AllOddsOrder.indexOf(nextBetType)
    if (indexNext > indexPre) {
        firstWeight += 32, secondWeight += 64;
    } else if (indexNext < indexPre) {
        firstWeight += 64, secondWeight += 32;
    }
    if (Number(prevLineId) < Number(nextLineId)) {
        firstWeight += 8, secondWeight += 16;
    } else if (Number(prevLineId) > Number(nextLineId)) {
        firstWeight += 16, secondWeight += 8;
    }
    if (Number(prevCombId) < Number(nextCombId)) {
        firstWeight += 2, secondWeight += 4;
    } else if (Number(prevCombId) > Number(nextCombId)) {
        firstWeight += 4, secondWeight += 2;
    }
    return firstWeight - secondWeight;
}