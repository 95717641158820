import React, { useContext,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StatisticsSelectedNumTable from '../Common/StatisticsSelectedNumTable';
import MyFavourites from '../Common/MyFavourites';
import { MarksixContext } from '../../Home/MarksixPage';


const Statistics = (props) => {
    const context = useContext(MarksixContext);
    const {t, i18n} = useTranslation()

    return (
        <section className={context.pageName}>
            <header className='result-header-m check-draw-header'>{t('LB_M6_STATISTICS')}</header>
            <StatisticsSelectedNumTable />
            <MyFavourites />
        </section>
    );
}
export default Statistics;
