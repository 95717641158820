import React, { useState, useEffect, useContext } from 'react';
import CheckResultBallPanelTable from '../Common/CheckResultBallPanelTable'
import { useTranslation } from 'react-i18next';
import AmountDisplayTable from './AmountDisplayTable'
import { setMyFavCookie, MyFavouritesContext } from './MyFavourites';
import { MarksixContext } from '../../Home/MarksixPage';
import { RrawResultContext } from '../Page/CheckDrawResult';
import { isStartSell } from './Common';

const CheckResultSelectedNumTable = ({ onChangeCheckTable, unitBet, setUnitBet, selectedTableData, setSelectedTableData, betTypeSelect, setBetTypeSelect, mySelectData, setMySelectData, cancelFavCheck }) => {
  const { t } = useTranslation()
  const context = useContext(MarksixContext)
  const isSell = isStartSell(context.nextDraw);
  const initDefaultUnitBet = sessionStorage.getItem('Mk6DefaultUnitBetAmount')?Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount')):10;
  const [disUnitBet5, setDisUnitBet5] = useState(true)
  const scSize = document.documentElement.clientWidth
  const { updateMyFavourites } = useContext(MyFavouritesContext)
  const { checkRrawNumber, setCheckRrawNumber } = useContext(RrawResultContext)

  const getChance = (arr) => {
    const { numList, bankerList } = arr;
    let size = numList?.length;
    let count = 6 - bankerList?.length;
    let betNum = 0;
    if ((size + bankerList.length) < 6 || size < count) {
    } else {
      let val = 1
      let n = 1
      const length = count
      for (let i = 0; i < length; i++) {
        val *= size
        n *= count
        size--
        count--
      }
      betNum = val / n
    }
    return betNum
  }

  const onChangeBall = (ballList, ballNum, activeIndex, curLabelType) => {
    const newTableData = JSON.parse(JSON.stringify(selectedTableData))
    if(curLabelType){
      newTableData[0] = ballList
    }else{
      newTableData[0].numList = ballList
    }
    newTableData[0].chance = getChance(newTableData[0]);
    setSelectedTableData(newTableData)
  }

  const onChangeMode = (data) => {
    setMySelectData({ ...data })
  }

  const clearAllNumber = () => {
    setSelectedTableData([{ numList: [], bankerList: [], isFav: false, unitBet:initDefaultUnitBet }])

  }

  const handleClickSelectedBall = (num, index, curLabelType) => {
    const labelType = curLabelType === 'banker' ? 'bankerList' : 'numList';
    const newTableData = JSON.parse(JSON.stringify(selectedTableData))
    newTableData[index][labelType] = newTableData[index][labelType].filter(item => item !== num)
    newTableData[index].isFav = false
    setSelectedTableData(newTableData)
  }

  const handleClickBet = (betNum) => {
    setUnitBet(betNum)
    selectedTableData[0].unitBet=betNum;
    setSelectedTableData(selectedTableData)
  }
  useEffect(() => {
    if (selectedTableData[0].numList.length < 7 && selectedTableData[0].bankerList.length === 0) {
      setDisUnitBet5(true)
      setUnitBet(initDefaultUnitBet)
      selectedTableData[0].unitBet=initDefaultUnitBet;
      setSelectedTableData(selectedTableData)
    } else {
      setDisUnitBet5(false)
    }
    updateChance(selectedTableData[0])
  }, [selectedTableData[0].numList, selectedTableData[0].bankerList])

  const updateChance = (parObj) => {
    selectedTableData[0].chance = getChance(parObj || selectedTableData[0])
    setSelectedTableData([...selectedTableData])
  }

  const selectTableProps = {
    scSize,
    t,
    handleClickSelectedBall,
    selectedTableData,
    disUnitBet5,
    handleClickBet,
    unitBet,
    mySelectData,
  }

  return <div className='marksix-banker-middle marksix-checkresult-middle'>
    <CheckResultBallPanelTable onChangeBall={onChangeBall} size={50}
      curBallList={selectedTableData[0]} type='checkdrawresult'
      selectedData={selectedTableData} onChangeMode={onChangeMode} mySelectData={mySelectData} betTypeSelect={betTypeSelect} setBetTypeSelect={setBetTypeSelect} cancelFavCheck={cancelFavCheck}
      clear={clearAllNumber} onChangeCheckTable={onChangeCheckTable} />
    <SelectTable {...selectTableProps}/>
    <AmountDisplayTable activeType={'BANKER'} selectedData={selectedTableData} tableType='checkdrawresult' clear={clearAllNumber} onChangeCheckTable={onChangeCheckTable}
      unitBet={unitBet} className='amount-diplay-d' />
  </div>
}

export default CheckResultSelectedNumTable

const SelectTable = (props) => {
  let key = 1
  const table = props.selectedTableData.map((item, index) => {
    return <TableRow ballData={item} index={index} key={key++} className={index % 2 ? '' : 'sec'} {...props}/>
  })
  return <div className='single-select-table' id='SelectTable'>
    {table}
  </div>
}


const TableRow = ({ballData, index, scSize, handleClickFavIcon, t, handleClickSelectedBall, disUnitBet5, handleClickBet, unitBet, randomBall, clearBall, mySelectData}) => {
  const imgSize = scSize > 1023 ? 'm' : 's'
  const initDefaultUnitBet = sessionStorage.getItem('Mk6DefaultUnitBetAmount')?Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount')):10;
  const initPartialUnitBet = sessionStorage.getItem('Mk6PartialUnitBetAmount')?Number(sessionStorage.getItem('Mk6PartialUnitBetAmount')):5;
  return <div className='table-row' >
    <div className='table-cell'>
      {ballData?.numList?.length > 0 || ballData?.bankerList?.length > 0 ?
        <>
          <div className='table-cell-banker-ball-box' style={{ display: !mySelectData.isMultiCheck ? 'flex' : 'none' }}>
            <div className='table-cell-banker-text'>{t('LB_M6_BANKERLABEL')}:</div>
            <div className={`df table-cell-ball-box ${mySelectData.isMultiCheck && 'padLR8'}`}>
              {ballData?.bankerList?.map((i, ind) => {
                return <div className='ball-img-box' onClick={() => handleClickSelectedBall(i, index, 'banker')} key={ind}><img className={`ballsize-${imgSize}`} src={i && require(`../../../info/marksix/info/images/marksix-gold-${i}.svg`)} alt={i} /></div>
              })}
            </div>
          </div>
          <div className='table-cell-banker-ball-box'>
            <div className='table-cell-banker-text' style={{ display: !mySelectData.isMultiCheck ? 'block' : 'none' }}>{t('LB_M6_LEGLABEL')}:</div>
            <div className={`df table-cell-ball-box ${mySelectData.isMultiCheck && 'padLR8'}`}>
              {ballData.numList.map((i, ind) => {
                return <div className='ball-img-box' onClick={() => handleClickSelectedBall(i, index, 'leg')} key={ind}><img className={`ballsize-${imgSize}`} src={i && require(`../../../info/marksix/info/images/marksix-${i}.svg`)} alt={i} /></div>
              })}
            </div>
          </div>
          <div className='df table-cell-bet-box'>
            <label className="radio-container" onClick={() => !disUnitBet5 && handleClickBet(initPartialUnitBet)}>
              {t('LB_M6_MULT_PARBET').replace('{0}', initPartialUnitBet)}
              <input type="radio" className="invCalFlexiBet-betline" onChange={() => handleClickBet(initPartialUnitBet)} disabled={disUnitBet5} checked={unitBet === initPartialUnitBet} />
              <span className="radio-checkmark"></span>
            </label>
            <label className="radio-container" onClick={() => handleClickBet(initDefaultUnitBet)}>
              {t('LB_M6_MULT_UNITBET').replace('{0}', initDefaultUnitBet)}
              <input type="radio" className="invCalFlexiBet-betline" onChange={() => handleClickBet(initDefaultUnitBet)} checked={unitBet === initDefaultUnitBet} />
              <span className="radio-checkmark"></span>
            </label>
          </div>
        </>
        : null}
    </div>
  </div>
}