import { validateBet } from './RacingBetline'

export const rcAddToSlipValidation = (selections, context, t, isAllUpPage, globalContext ) => {
    let  betTypeList = []
    const currentRaceNo = getMultiRaceNo(context) || [context.content.raceNo]
    const selectCombList = Object.keys(selections).filter(key => {
        const [betType ] = key.split('_')
        betTypeList.push(betType)
        if(selections[key]) return key
    }).map(item => {
        const [betType, raceNo, selectType, combStr ] = item.split('_')

        return {
            id: item,
            betType,
            raceNo,
            selectType,
            combStr
        }
    })
    if (context.content.page==="JTCOMBO") {
        if ( globalContext.globalRef.current.allupBoxChecked ) {
            //console.log("selectCombList", selectCombList);
            let raceCnt = [...new Set(selectCombList.map(x=> x.raceNo))].length;
            if ( raceCnt<2 || raceCnt > 6 ) {
                alert(t('LB_RC_JTC_INVALID_RACES'));
                return false;
            }
            let cnts = selectCombList.reduce((init, next) => {
                let key = next.raceNo;
                if ( !init[key] )
                    init[key] = [];
                init[key] = [ ...init[key], next.betType ] ;
                return init;
            }, {});
            for ( let idx in cnts ) {
                if ( [...new Set(cnts[idx])].length > 1 ) {
                    alert(t('LB_RC_JTC_ONE_POOL_ONLY').replace('{0}', idx));
                    return false;
                }
            }
        }
    }
    if(context.content.page === 'CWAALLALUP'){
        const selectObj = {}
        selectCombList.forEach(item => {
            if(selectObj[item.raceNo]){
                selectObj[item.raceNo].push(item)
            }else{
                selectObj[item.raceNo] = [item]
            }
        })
        const selectedRaceNo = Object.keys(selectObj).length
        if(selectedRaceNo < 1){
            alert(t('ERR_CWAALLALUP_MIN_LEG'))
            return false
        }else if(selectedRaceNo > 6 ){
            alert(t('ERR_CWAALLALUP_MAX_LEG'))
            return false
        }
        return true
    }
    if(isAllUpPage){
        return allUpvalidateBet(context,selectCombList,t)
    }
    return validateBet(context.contentRef,currentRaceNo,t)
}

export const allUpvalidateBet = (context,selectCombList,t) => {
    let flag = true
    const allUpData = context.content.alupData
    const selectObj = {}
    selectCombList.forEach(item => {
        if(selectObj[item.raceNo]){
            if(selectObj[item.raceNo][item.selectType]){
                selectObj[item.raceNo][item.selectType].push(item)
            }else{
                selectObj[item.raceNo][item.selectType] = [item]
            }
        }else{
            selectObj[item.raceNo] = {
                raceNo: item.raceNo
            }
            selectObj[item.raceNo][item.selectType] = [item]
        }
    })
    for(let i=0; i < allUpData.length; i++){
        if(!flag) return false
        const raceNo = allUpData[i].raceNo
        const subType =  allUpData[i].subType
        switch(allUpData[i].betType){
            case 'WIN':
            case 'PLA':
            case 'W-P':
            case 'CWA':
                if(!selectObj[raceNo] || selectObj[raceNo]?.leg?.length < 1){
                    alert(t('LB_RC_ADDSLIP_ERR_HORSE_LESS').replace('{0}',raceNo))
                    flag = false
                }
                break
            case "QIN":
            case "QPL":
            case "QQP":
                if(selectObj?.[raceNo]?.b1?.length > 1){
                    alert(t('LB_RC_ADDSLIP_ERR_BANKER_MANY').replace('{0}',raceNo))
                    flag = false
                }else if( !selectObj?.[raceNo]?.leg || (selectObj[raceNo]?.leg?.[0].combStr !== 'F' && selectObj[raceNo]?.leg?.length < 2 )) {

                    alert(t('LB_RC_ADDSLIP_ERR_HORSE_LESS').replace('{0}',raceNo))
                    flag = false
                }
                break
            case "FCT":
                flag = FCTValidation(selectObj[raceNo],t, subType, raceNo)
                break
            case "TRI": 
                flag = TRIValidation(selectObj[raceNo],t, raceNo)
                break
            default:

            }
    }
    return flag
}



const FCTValidation = (selectObj, t, subType, raceNo) => {
    
    const Fcount = (selectObj?.b1?.[0]?.combStr === 'F' || selectObj?.leg?.[0].combStr === 'F') ? 10 : 0
    const legLength = selectObj?.leg?.length || 0
    switch(subType){
        case 'S':
            if(!selectObj?.b1){
                alert(t('LB_RC_ADDSLIP_ERR_HORSE_LESS').replace('{0}', raceNo))
                return false
            }else if(!selectObj?.leg){
                alert(t('LB_RC_ADDSLIP_ERR_HORSE_LESS').replace('{0}', raceNo))
                return false
            }
            break
        case 'M':
            if(!selectObj?.leg ||  ((legLength + Fcount) < 2) ) {
                alert(t('LB_RC_ADDSLIP_ERR_HORSE_LESS').replace('{0}', raceNo))
                return false
            }
            break
        case 'B':
        case "BM":
            if(!selectObj?.b1 || ((legLength + Fcount) < 2)){
                alert(t('LB_RC_ADDSLIP_ERR_HORSE_LESS').replace('{0}', raceNo))
                return false
            }else if(selectObj?.b1?.length > 1){
                alert(t('LB_RC_ADDSLIP_ERR_BANKER_MANY').replace('{0}', raceNo))
                return false
            }
            break
        case "MB":
            const totalLength= (selectObj?.b1?.length || 0) + legLength +Fcount
            if(!selectObj?.b1 || !selectObj?.leg ||  totalLength < 3){
                alert(t('LB_RC_ADDSLIP_ERR_HORSE_LESS').replace('{0}', raceNo))
                return false
            }
            break
        default:
            return true
    }

    return true
}
const TRIValidation = (selectObj, t,  raceNo) => {
    const bCount = selectObj?.b1?.length || 0;
    const lCount = selectObj?.leg?.[0].combStr === 'F' ? 14 : (selectObj?.leg?.length || 0);

    if(bCount > 2){
        alert(t('LB_RC_ADDSLIP_ERR_BANKER_MANY').replace('{0}', raceNo))
        return false
    }
    else if( (bCount==2 && lCount<=1) || (bCount<=1 && lCount<=2) ){
        alert(t('LB_RC_ADDSLIP_ERR_HORSE_LESS').replace('{0}', raceNo))
        return false
    }
    return true
}


const getMultiRaceNo = (context) => {
    const betType = context.content.page
    const currentMeeting = context.content.meeting;
    const poolId = `${currentMeeting.id}${betType === '6UP' ? 'SIXUP' : betType}${context.content.raceNo}`
    const currentRaces = currentMeeting.pmPools.find(item => poolId === item.id.toUpperCase())?.leg?.races
    return currentRaces
}
