import React from 'react';
import './SpeedbetLogo.scss';
import speedBetLogoPNG from '../../../info/Include/images/speedbetLogo.png';

const SpeedBetLogo = () => {
    return (
        <div className="SpeedbetLogo">
            <img className='speedBetLogoPNG' src={speedBetLogoPNG} />
            <div className="hkjcpriority-m">
                <div className="hkjcpriority-logo-m">香港賽馬會「優越」客戶</div>
            </div>
        </div>
    );
};
export default SpeedBetLogo;
