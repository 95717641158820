import { createFoPool } from "./createFoPool";
import { parseFoOdds } from "./parseFoOdds";
import { parseFoDtls } from "./parseFoDtls";
import { parseFoSell } from "./parseFoSell";
import { isDisplayFoPool } from "../../FO/FO_List";

export const parseFoPool = (content, message, topicInfo,t) => {
    if(!content.meeting?.foPools){
        content.meeting.foPools = []
    }
    let foPool = content.meeting?.foPools?.filter(x => x.oddsType===topicInfo.oddsType && x.instNo === topicInfo.insNo)[0];
    if ( !foPool ) {
        foPool =createFoPool(topicInfo);
        content.meeting.foPools.push(foPool);
        content.meeting.foPools.sort(foPoolCompare);
    }
    switch (topicInfo.displayType) {
        case "odds":
            parseFoOdds(foPool, message, content);
            break;
        case "detail":
            parseFoDtls( foPool, message, content,t);
            break;
        case "sell":
            parseFoSell(foPool, message, content);
            break;
        case "sta":
            foPool.poolId = message.id;
            foPool.status = message.st.toUpperCase();
            if ( isDisplayFoPool(foPool) ) {
                content.meeting[topicInfo.oddsType.toLowerCase() + "InstNo"] = [{"instNo": topicInfo.insNo}];
            }
            break;
    }
}
function foPoolCompare(a,b){
    if(parseInt(a.instNo) > parseInt(b.instNo))
        return -1
    if(parseInt(a.instNo) < parseInt(b.instNo))
        return 1
    return  0
}

//  以下為上面代碼的測試代碼
// export const parseFoPool1 = (content, message, topicInfo,t) => {
//     let foPool
//     let foPoolIndex = content.meeting.foPools.findIndex(x => x.oddsType===topicInfo.oddsType && x.instNo === topicInfo.insNo)
//     if ( foPoolIndex === -1 ) {
//         foPool =createFoPool(topicInfo);
//         content.meeting.foPools.push(foPool);
//         content.meeting.foPools.sort(foPoolCompare);
//     } else {
//         foPool = {...content.meeting.foPools[foPoolIndex]}
//         content.meeting.foPools[foPoolIndex] = foPool // 更新對象引用
//     }
//     switch (topicInfo.displayType) {
//         case "odds":
//             parseFoOdds(foPool, message);
//             break;
//         case "detail":
//             parseFoDtls( foPool, message, content,t);
//             break;
//         case "sell":
//             parseFoSell(foPool, message);
//             break;
//         case "sta":
//             foPool.poolId = message.id;
//             foPool.status = message.st.toUpperCase();
//             break;
//     }
// }