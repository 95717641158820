import React, { useEffect } from 'react';
import { iOSMobilePWAMode } from '../../GlobalFunc'

 const CloseButton = ({
    isIOSPWA = false,
 }) => {
   
    const onHandleClick = () =>{
        window.close()
    }
    let conditionBool = true;
    if(isIOSPWA){
        conditionBool = iOSMobilePWAMode();
    }
    return (
        <>
            {conditionBool?
                <div  className={`closeButton`} onClick={onHandleClick}>
                </div>
            :
                null
            }
        </>
    );
};

export default CloseButton