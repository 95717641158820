
export function getBetTypeList() {
    return [
        { type: "WIN", show: false, maxLeg: 6, subType: [], showSubMenu: false },
        { type: "PLA", show: false, maxLeg: 6, subType: [], showSubMenu: false },
        { type: "W-P", show: false, maxLeg: 6, subType: [], showSubMenu: false },
        { type: "QIN", show: false, maxLeg: 6, subType: [], showSubMenu: false },
        { type: "QPL", show: false, maxLeg: 6, subType: [], showSubMenu: false },
        { type: "QQP", show: false, maxLeg: 6, subType: [], showSubMenu: false },
        { type: "FCT", show: false, maxLeg: 3, subType: ['S', 'M', 'B', 'BM', 'MB'], showSubMenu: true },
        { type: "TRI", show: false, maxLeg: 3, subType: [], showSubMenu: false },
        { type: "CWA", show: false, maxLeg: 6, subType: [], showSubMenu: false }
    ];
}

export function getAlupBetTypeList(page) {
    let list = [...getBetTypeList()];
    switch (page) {
        case "CROSS_ALUP":
            list.map((item)=> {
                if (['WIN', 'PLA', 'W-P', 'QIN', 'QPL', 'QQP', 'FCT', 'TRI', 'CWA'].includes(item.type))
                    item.show = true;
            });
            break;
        case "CWAALUP":
            list.map((item)=> {
                if (['CWA'].includes(item.type))
                    item.show = true;
             });
             break;
        case "WPALUP":
            list.map((item)=> {
                if (['WIN', 'PLA', 'W-P'].includes(item.type))
                    item.show = true;
             });
             break;
        case "WPQALUP":
            list.map((item)=> {
                if (['QIN', 'QPL', 'QQP'].includes(item.type))
                    item.show = true;
             });
             break;
        case "FCTALUP":
            list.map((item)=> {
                if (['FCT'].includes(item.type))
                    item.show = true;
             });
             break;
        case "TRIALUP":
            list.map((item)=> {
                if (['TRI'].includes(item.type))
                    item.show = true;
             });
             break;
        default:
            list = [];
            break;
    }
    return list;
}