import { createPool } from './createPool';
import { addOddsDiff, checkChpType, showTournPoolByPage } from './common';
import { updatePoolInfo } from './parseUpdMatchPool'
import {setTournamentPageTabs, trimTournamentByTourn} from '../../Common/Common';

export const parseUpdTournPool = (message, topicInfo, contentRef, otpion) => {
    let tourn = contentRef.current.tournData?.tournaments.filter(x => x.id == topicInfo.tournamentid)[0];
    if(tourn){
        updatePoolInfo(tourn, message)
        let idx = tourn.foPools.findIndex(x => x.id == topicInfo.poolid);
        if ( ["SellingStarted", "SellingStopped", "Hold"].includes(message.st) ) {
            let newPool = createPool(contentRef, message);
            if ( idx >= 0 ) {
                let pool = tourn.foPools[idx];
                addOddsDiff(contentRef, tourn, pool, newPool, otpion);
                tourn.foPools[idx] = newPool;
            }
            else{
                tourn.foPools.push(newPool);
            }
        }
        else {
            tourn.foPools = tourn.foPools.filter(x=> x.id != topicInfo.poolid);
            if(tourn.foPools.length === 0){
                contentRef.current.tournData.tournaments = contentRef.current.tournData?.tournaments.filter(x =>  x.id != tourn.id)
            }
        }
    }else{
        const isTournament = contentRef.current.page === 'TOURNAMENT'
        const rawTourn = contentRef.current.rawTournList.find(i => i.id === topicInfo.tournamentid && (isTournament ? i.nameProfileId === contentRef.current.tournNameProfileId : true))
        if(rawTourn){
            if ( ["SellingStarted", "SellingStopped", "Hold"].includes(message.st) ) {
                let pool = createPool(contentRef, message);
                rawTourn.foPools = [pool];
                rawTourn.poolInfo = {
                    normalPools: [],
                    inplayPools: [],
                    sellingPools: []
                }
                updatePoolInfo(rawTourn, message)

                if(showTournPoolByPage(message.bTyp, message.dtl.chpTyp, contentRef)){
                    contentRef.current.tournData?.tournaments.push(rawTourn)
                }
            }
        }
    }

    contentRef.current.selection.tabs = trimTournamentByTourn(contentRef.current.tournData?.tournaments);
    setTournamentPageTabs(contentRef.current.tournData?.tournaments, contentRef.current)
}
