import { createPool } from './createPool';
import { isInDisplayCriteria, matchCompare, checkINPLAYandOFMPool } from './common';
import { updatePoolInfo } from './parseUpdMatchPool';
import { getDisplayMatches } from '../useGetMatchList/common';

export const parseNewMatchPool = (message, topicInfo, contentRef) => {
    let topicMatchID = topicInfo.matchid;
    if(message.dtl?.tagMatchId){
        topicMatchID = message.dtl.tagMatchId;
    }

    let listDataMatch = contentRef.current.rawMatchList.find(x => x.id===topicMatchID);
    if(listDataMatch) {  
        updatePoolInfo(listDataMatch, message);
    }
    
    let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
    contentRef.current.listData = { matches : matchList };
    
    let match = contentRef.current.matchData?.matches.find(x => x.id===topicMatchID);
    if(match) {  
        updatePoolInfo(match, message);
    }

    if(["SellingStarted", "SellingStopped", "Hold"].includes(message.st) ) {
        if ( isInDisplayCriteria(listDataMatch, message.bTyp, message.dtl?.betOffer?.inplay, contentRef) ) {
            if ( !match && listDataMatch ) {
                match = {...listDataMatch};
                match.foPools = [];
                contentRef.current.matchData.matches.push(match);
                contentRef.current.matchData.matches.sort(matchCompare);
            }

            if ( match ) {
                if(!match?.foPools){
                    match.foPools = []
                }
                let idx = match.foPools.findIndex(x=> x.id == message.id) ;
                if(idx>=0){
                    match.foPools[idx] = createPool(contentRef, message);
                }
                else if(checkINPLAYandOFMPool(message, contentRef)){
                    match.foPools.push(createPool(contentRef, message));
                }
            }
        }
    }
}