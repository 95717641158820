import React,{ useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MarksixContext } from '../../Home/MarksixPage';
import { isSnowballDraw } from './Common';
import MobileMenu from '../../Menu/MobileMenu';
import Sticky from 'react-stickynode';
import { useWindowSize } from '../..//Common/CommonHooks';

const M6PageInfo = ({onPageChange}) => {
  const { t } = useTranslation()
  const context = useContext(MarksixContext);
  const draw = context.nextDraw;
  const isSB = isSnowballDraw(draw)
  let dNo = String(draw?.no).padStart(3, '0');
  const nextDrawNo = draw!=null && draw?.id ? `${draw.year.substring(2,4)}/${isSB ? (context.isMergeDraw ? `${dNo} ${draw?.snowballCode}` :draw?.snowballCode) : dNo}` : '-';
  const {isMobileSize} = useWindowSize();
  return <Sticky innerZ={999} enabled={isMobileSize}>
    <div className={`marksix-header-info ${isSB ? "marksix-header-info-snowball": ''}`}>
        <div className='marksix-info'>{t(isSB ? 'LB_M6_SNOWBALLDRAW_NUM' : 'LB_M6_NEXTDRAW_NUM')} {nextDrawNo}</div>
        <div className='marksix-info-menu'>
            <MobileMenu product="marksix" onPageChange={(url, page)=> {onPageChange(url, page)}} />
        </div>
    </div>
  </Sticky>
}

export default M6PageInfo