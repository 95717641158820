import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../../../css/football/odds/allupCombination.scss';
import { GetFootballConfig } from '../../Common/ConfigHelper';
import { useWindowSize } from '../../Common/CommonHooks';
import CloseButton from '../../Common/ui-components/CloseButton';
import { NewWindow } from '../../Common/home-common';
import { Scrollbars } from 'react-custom-scrollbars-2';

const CustomScrollbars = props => (
    <Scrollbars
        className='AllupCombination-Scrollbars'
        autoHeight={false}
        autoHideTimeout={2000}
        autoHideDuration={200}
        style={{ height: 'calc(100vh - 108px)', maxWidth:'828px' }}
        {...props}
    />
);

const AllupCombination = () => {
    const { t, i18n } = useTranslation();
    let config = GetFootballConfig();
    let AllupCombination = config?.AllupCombination;
    let noOfMatch = -1;
    let backgroundColor = '';
    const windowSize = useWindowSize();
    const [selectedLeg, setSelectedLeg] = useState(2);

    const goToFTCal = () => {
        const url = `/${i18n.language}/football/calculator`;
        const w = windowSize.isMobileSize?null:828;
        const h = windowSize.isMobileSize?null:351;
        NewWindow(url, 'Int_Investment Calculator',w,h);
    }

    const Header = () => {
        return <div className='header'>
            <div className='left'>{t('LB_FB_TITLE_ALL_UP_COMBINATIONS')}</div>
            <div className='right' onClick={goToFTCal}>
                {t('LB_FB_INVESTMENT_CALCULATOR')}
                <div className='icon-allupCal' />
            </div>
        </div>
    }

    const HeaderMobile = () => {
        return <div className='header'>
            <div className='popupTitle'>
                {t('LB_FB_TITLE_ALL_UP_COMBINATIONS')}
                <CloseButton isIOSPWA={true} />
            </div>
            <div>
                <div className='no-of-match'>{t('LB_FB_NO_OF_MATCH')}</div>
                <div className='right' onClick={goToFTCal}>
                    {t('LB_FB_INVESTMENT_CALCULATOR')}
                    <div className='investmentCaculatorBtn'>
                        <div className='icon-allupCal' />
                    </div>
                </div>
            </div>
        </div>
    }

    const SubHeader = () => {
        return <div className='subHeader'>
            <div>{t('LB_FB_ALL_UP_FORMULA')}</div>
            <div>{t('LB_FB_HOW_TO_WIN')}</div>
            <div>{t('LB_FB_NO_OF_MATCH')}</div>
            <div>{t('LB_FB_PER_UNIT_INVESTMENT')}</div>
            <div>
                <div>{t('LB_FB_COMBINATIONS')}</div>
                <div>
                    <div>{t('LB_FB_LEGS_1')} {t('LB_FB_LEG')}</div>
                    <div>{t('LB_FB_LEGS_2')} {t('LB_FB_LEGS')}</div>
                    <div>{t('LB_FB_LEGS_3')} {t('LB_FB_LEGS')}</div>
                    <div>{t('LB_FB_LEGS_4')} {t('LB_FB_LEGS')}</div>
                    <div>{t('LB_FB_LEGS_5')} {t('LB_FB_LEGS')}</div>
                    <div>{t('LB_FB_LEGS_6')} {t('LB_FB_LEGS')}</div>
                    <div>{t('LB_FB_LEGS_7')} {t('LB_FB_LEGS')}</div>
                    <div>{t('LB_FB_LEGS_8')} {t('LB_FB_LEGS')}</div>
                </div>
            </div>
        </div>
    }


    const SubHeaderMobile = () => {
        let legsList = [2, 3, 4, 5, 6, 7, 8];
        return <>
            <div>
                {
                    legsList.map((_leg) => {
                        return <div className={`${_leg === selectedLeg ? 'active' : ''} btn`} onClick={() => setSelectedLeg(_leg)}>
                            {_leg}
                        </div>
                    })
                }

            </div>
        </>
    }

    const Content = () => {
        return <>
            <div className='content'>
                {
                    AllupCombination != null && AllupCombination.map((_combination, _index) => {

                        let spanRow = 0;
                        let allUpFormula = _combination[0].split("#")[0] + " x " + _combination[0].split("#")[1];
                        let isSpanRow = false;
                        if (noOfMatch != _combination[1]) {
                            noOfMatch = _combination[1];
                            spanRow = AllupCombination.filter(x => x[1] == noOfMatch).length;
                            isSpanRow = true;
                            backgroundColor = backgroundColor !== 'odd' ? 'odd' : 'even';
                        }
                        return <>
                            <div className={`${backgroundColor}`}>{allUpFormula}</div>
                            {isSpanRow && <div className={`${backgroundColor} spanRow`} style={{ gridRow: "span " + spanRow }}>
                                {t('LB_FB_ALL_LEGS_CORRECT_' + noOfMatch)}
                            </div>}
                            <div className={`${backgroundColor}`}>{_combination[2]}</div>
                            <div className={`${backgroundColor}`}>{parseInt(_combination[0].split("#")[1]) * 10}</div>
                            <div className={`${backgroundColor} leg`}>
                                {
                                    _combination[3].split("#").map((_leg) => {
                                        return <div>{_leg}</div>
                                    })
                                }

                            </div>
                        </>
                    })
                }
            </div>
        </>
    }

    const ContentMobile = () => {

        let curCombination = AllupCombination?.filter(x => x[0].split("#")[0] === selectedLeg.toString()).sort(
            function(a, b){
                return parseInt(a[0].split('#')[1]) - parseInt(b[0].split('#')[1])
            }
        )
        if(curCombination == null){
            return null;
        }

        let noOfLeg = 0;
        curCombination[curCombination.length-1][3].split("#").map((item, idx) => {
            if(item.length == 0 && noOfLeg == 0){
                noOfLeg = idx;
            }
        })

        noOfLeg = noOfLeg == 0 ? curCombination.length : noOfLeg;

        let legList = [];
        let gridColumnCss = "";
        for(let i = 0; i < noOfLeg; i++){
            legList.push(<div>{t('LB_FB_LEGS_'+(i+1))} {t('LB_FB_LEG')}</div>)
            gridColumnCss += (100/noOfLeg) + '% ';
        }

        return <>
            <div className='subHeader'>

                <div>
                    <div>{t('LB_FB_ALL_UP_FORMULA')}</div>
                    <div>{t('LB_FB_PER_UNIT_INVESTMENT_MOBILE')}</div>
                </div>
                <div>
                    <div>{t('LB_FB_COMBINATIONS')}</div>
                    <div style={{gridTemplateColumns: gridColumnCss}}>
                        {legList}
                    </div>
                </div>
            </div>
            <div className='content'>
                <div>
                    {
                        curCombination?.map((_com) => {
                            return <>
                                <div>
                                    {_com[0].split("#")[0] + " x " + _com[0].split("#")[1]}
                                </div>
                                <div>
                                    {parseInt(_com[0].split("#")[1]) * 10}
                                </div>
                            </>
                        })
                    }
                </div>
                <div>
                    {
                        curCombination?.map((_com) => {

                            return <div style={{gridTemplateColumns: gridColumnCss}}>
                                {
                                    _com[3].split("#")?.map((_leg) => {
                                        // if(_leg.length > 0){
                                        //     return <div>{_leg}</div>
                                        // }else{
                                        //     return null;
                                        // }

                                        return <div>{_leg}</div>
                                    })
                                }
                            </div>
                        })
                    }
                </div>
            </div>
        </>
    }

    return (
        <section className={`AllupCombination ${windowSize.isMobileSize ? 'isMobile' : ''}`}>
            {!windowSize.isMobileSize ? <Header /> : <HeaderMobile />}

            {!windowSize.isMobileSize ? <SubHeader /> : <div className='legSelction'><SubHeaderMobile /></div>}

            {!windowSize.isMobileSize ?
            <CustomScrollbars>
                <Content />
            </CustomScrollbars>
            :
            <ContentMobile />
            }


        </section>
    )
}
export default AllupCombination;