import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useBetSlip } from '../../BetSlip/BetSlipHooks';
import { RacingContext } from '../../Home/RacingPage';
import { ConfigContext } from '../../Common/ConfigHelper';
import { IsFlexibetEnabled } from '../../Common/ChannelParaFunc';
import { getDefaultRacingUnitBet } from './Common';

export const useQuickBet = () => {
    const { addToBetSlip, betLines } = useBetSlip();
    const context = useContext(RacingContext);
    const config = useContext(ConfigContext);
    const mtg = context.content.meeting;

    const { t } = useTranslation();

    const handleClickCombOdds = (comb, betType, raceNo) => {
        raceNo = raceNo || context.content.raceNo;
        let combStrList = comb?.combString?.split(',');
        if ('IWN' === betType) {
            combStrList = comb.combString.split('#');
        }
        if ('DBL' === betType) {
            combStrList = comb.combString.split('/');
        }

        const banker1 = [];
        const banker2 = [];
        const banker3 = [];
        const bankers = [banker1, banker2, banker3];
        const combs = [];
        let currentPool;
        if (['WIN', 'PLA'].includes(betType)) {
            const runner = getRunnerDetails(raceNo, comb.no);
            combs.push(runner);
        } else if (['DBL'].includes(betType)) {
            const betList = [];
            const dblPool = mtg.pmPools.filter(
                (x) => x.leg.races[0] === context.content.raceNo && x.oddsType === betType
            )[0];
            combStrList.forEach((i, index) => {
                const raceNo = dblPool.leg.races[index];
                const runner = getRunnerDetails(raceNo, i);
                const combs = [runner];
                const betItem = {
                    combs,
                    raceNo,
                    betType
                };
                betList.push(betItem);
            });
            const unitBet = getDefaultRacingUnitBet(betType);
            const betObj = {
                betType,
                betList,
                dayid: mtg?.dateOfWeek,
                id: Date.now().toString(),
                noOfBets: 1,
                prod: 'HR',
                poolid: betType,
                raceno: raceNo || context.content.raceNo,
                venueid: context.content.venue,
                meetingId: mtg?.id,
                subType: config.racing.hasSubType.includes(betType) ? context.content?.subMethod?.type : undefined,
                unitBet: unitBet,
                betTotal: unitBet
            };
            addToBetSlip([betObj]);
            return;
        } else if (['CWA', 'CWB', 'CWC'].includes(betType)) {
            const pool = mtg.pmPools?.filter((item) => item.leg.races[0] == raceNo && item.oddsType == betType)[0];
            combStrList.forEach((i, index) => {
                const currentComb = pool?.cWinSelections?.find((item) => item.composite === i);
                const combItem = [];

                currentComb.starters.forEach((runnerNo) => {
                    combItem.push(getRunnerDetails(raceNo, runnerNo));
                });
                const betItem = {
                    combs: combItem,
                    composite: currentComb.composite,
                    name_ch: currentComb.name_ch,
                    name_en: currentComb.name_en
                };
                const betObj = {
                    betType,
                    betList: [betItem],
                    dayid: mtg?.dateOfWeek,
                    id: Date.now().toString(),
                    noOfBets: 1,
                    prod: 'HR',
                    poolid: betType,
                    raceno: raceNo || context.content.raceNo,
                    venueid: context.content.venue,
                    meetingId: mtg?.id,
                    subType: config.racing.hasSubType.includes(betType) ? context.content?.subMethod?.type : undefined
                };

                addToBetSlip([betObj]);
            });
            return;
        } else if (['JKC', 'TNC'].includes(betType)) {
            if (betLines.find((item) => item.id === comb.quickBetId)) {
                alert(t('LB_BS_ADDTOSLIP_HR_ERR_MSG1'));
                return;
            }
            const [betType, meetingId, poolId, no] = comb.quickBetId.split('#');
            currentPool = mtg.foPools.find((item) => item.poolId == poolId);
            comb.poolId = poolId;
            combs.push(comb);
        } else {
            combStrList.forEach((leg) => {
                combs.push(getRunnerDetails(raceNo, Number(leg)));
            });
        }
        const unitBet = getDefaultRacingUnitBet(betType);
        const betObj = {
            betType,
            bankers,
            combs,
            dayid: mtg?.dateOfWeek,
            id: comb.quickBetId || Date.now().toString(),
            noOfBets: 1,
            prod: 'HR',
            poolid: betType,
            raceno: raceNo || context.content.raceNo,
            venueid: context.content.venue,
            subType: config.racing.hasSubType.includes(betType) ? 'S' : undefined,
            currentPool,
            useFlexiUi: IsFlexibetEnabled(betType) ? true : false,
            meetingId: mtg?.id,
            isFlexi: false,
            unitBet: unitBet,
            betTotal: unitBet
        };
        addToBetSlip([betObj]);
    };

    const getRunnerDetails = (raceNo, runnerNo) => {
        const meeting = context.content.meeting;
        let runner = {};
        if (runnerNo === 'F') {
            runner = {
                no: 'F',
                name_en: t('LB_RC_POOL_FULL_EN'),
                name_ch: t('LB_RC_POOL_FULL_CH')
            };
        } else {
            runner = meeting.races
                .find((race) => race.no == raceNo)
                ?.runners.find((runner) => runner.no == Number(runnerNo));
        }
        return runner;
    };

    return {
        handleClickCombOdds
    };
};
