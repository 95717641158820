export const clipboardRaceDesc = () => {
    let row = [];
    if ( document.getElementById("meetingDescNo") ) {
        row.push(`<td>${document.getElementById("meetingDescNo").innerHTML}</td>`);
    }
    if ( document.getElementById("meetingDescDtls") ) {
        row.push(`<td colspan="10">${document.getElementById("meetingDescDtls").innerHTML}</td>`);
    }
    return row.join('');
}


export const clipboardIndicatorStyle = (context, hf, od) => {
    if ( hf ) {
        return "background: #f03939; color: #ffffff";
    }
    else if ( !['DBL'].includes(context.content.page) && !context.content.isPresales && od >= 50) {
        return "background: #993300; color: #ffffff";
    }
    else if ( !['DBL'].includes(context.content.page) && !context.content.isPresales && od >= 20 ) {
        return "background: #2aa217; color: #ffffff";
    }
    return "";
}

export const clipboardInvInfo = () => {
    let row = [];
    if ( document.getElementById("investmentLabel") ) {
        row.push(`<td colspan="2">${document.getElementById("investmentLabel").innerHTML}</td>`);
        row.push(`<td colspan="2">${document.getElementById("investmentValue").innerHTML}</td>`);
    }
    if ( document.getElementById("jackpotLabel") ) {
        row.push(`<td colspan="2">${document.getElementById("jackpotLabel").innerHTML}</td>`);
        row.push(`<td colspan="2">${document.getElementById("jackpotValue").innerHTML}</td>`);
    }
    if ( document.getElementById("estDividendLabel") ) {
        row.push(`<td colspan="2">${document.getElementById("estDividendLabel").innerHTML}</td>`);
        row.push(`<td colspan="2">${document.getElementById("estDividendValue").innerHTML}</td>`);
    }
    return row.join("");
}

export const clipboardTableTop20 = (data, title, isRefund) => {
    const table = [];
    table.push(`<tr><td colspan="8" style="background-color:#122c68; color:#FFFFFF">${title}</td></tr>`);
    for (let index = 0; index < 5; index++) {
        const row = [];
        for (let i = 0; i < 4; i++) {
            const currentIndex = index + i * 5;
            const currentlabel = data[currentIndex]?.combString
                ?.split(',')
                ?.map((item) => Number(item))
                .join('-');
            let odds = '';
            if (data[currentIndex] != null) odds = isRefund ? '---' : data[currentIndex].oddsValue;
            row.push(`<td>="${currentlabel}"</td><td>${odds}</td>`);
        }
        table.push(`<tr>${row.join("")}</tr>`)
    }
    return `<table>${table.join("")}</table>`
}

export const clipboardTableBankerTop10 = (data, runnerData, title, isRefund, t, i18n) => {
    const table = [];
    table.push(`<tr><td colspan="8" style="background-color:#122c68; color:#FFFFFF">${title}</td></tr>`);
    for ( let pageNum = 1; pageNum<=Math.ceil(runnerData.length / 4); pageNum++) {
        let header = [];
        const currentRaceData = runnerData.filter((item) => item.no >= (pageNum - 1) * 4 + 1 && item.no <= pageNum * 4);
        for (let i = 0; i < 4; i++) {
            const item = currentRaceData[i];
            const name = `${item?.no || ''} ${item?.['name_' + i18n.language] || ''}`;
            const scrStr = item?.status.toUpperCase().indexOf('SCRATCHED') >= 0 ? `(${t('LB_RC_SCR')})` : '';
            const resStr = ['RESERVED', 'STANDBY'].includes(item?.status.toUpperCase()) ? `(${t('LB_RC_RESERVE')})` : '';
            header.push(`<td colspan="2">${name}${scrStr}${resStr}</td>`);
        }
        table.push(`<tr>${header.join("")}</tr>`)

        for (let index = 0; index < 10; index++) {
            const row = [];
            for (let i = 0; i < 4; i++) {
                let no = '';
                let odds = '';
                const oddsData = data.filter((item) => item.combString == currentRaceData[i]?.no)[0];
                if (oddsData != null && oddsData.bankerOdds[index] != null) {
                    no = oddsData.bankerOdds[index].combString
                            .split(',')
                            ?.map((item) => Number(item))
                            ?.join('-') || [];
                    odds = isRefund ? '---' : oddsData.bankerOdds[index].oddsValue;
                }

                const currentIndex = index + i * 5;
                row.push(`<td>="${no}"</td><td>${odds}</td>`);
            }
            table.push(`<tr>${row.join("")}</tr>`);
        }
    }
    return `<table>${table.join("")}</table>`
}

export const clipboardInvestment = (betType, data, runnerData, title, t) => {
    const table = [];
    table.push(`<tr><td colspan="4" style="background-color:#122c68; color:#FFFFFF">${title}</td></tr>`);
    table.push(`<tr><td>${t('LB_RC_ODDS_TABLE_No')}</td>
        <td>${t('LB_RC_ODDS_TABLE_1POS')} ${t('LB_RC_ODDS_TABLE_POSSUF')}</td>
        <td>${t('LB_RC_ODDS_TABLE_2POS')} ${t('LB_RC_ODDS_TABLE_POSSUF')}</td>
        <td>${t('LB_RC_ODDS_TABLE_3POS')} ${t('LB_RC_ODDS_TABLE_POSSUF')}</td>
        ${betType=='QTT' ? `<td>${t('LB_RC_ODDS_TABLE_4POS')} ${t('LB_RC_ODDS_TABLE_POSSUF')}</td>` : ""}
    </tr>`);

    for (let i = 0; i < runnerData.length; i++) {
        let rowData = data.filter(item => parseInt(item.combString) == i + 1)[0];
        let runner = runnerData.filter(item => parseInt(item.no)== i+1)[0];
        let isScr = runner.status.toUpperCase().indexOf("SCRATCHED")>=0;

        let inv1 = '';
        let inv2 = '';
        let inv3 = '';
        let inv4 = '';
        if (rowData != null) {
            inv1 = '$'+parseInt(rowData.bankerOdds.filter(x=>x.combString=="1")[0]?.oddsValue).toLocaleString();
            inv2 = '$'+parseInt(rowData.bankerOdds.filter(x=>x.combString=="2")[0]?.oddsValue).toLocaleString();
            inv3 = '$'+parseInt(rowData.bankerOdds.filter(x=>x.combString=="3")[0]?.oddsValue).toLocaleString();
            inv4 = betType=='QTT' ? '$'+parseInt(rowData.bankerOdds.filter(x=>x.combString=="4")[0]?.oddsValue).toLocaleString() : '';
        }
        table.push(`<tr><td>${parseInt(rowData.combString)}</td>
            <td>${isScr ? t('LB_RC_SCR') : inv1}</td>
            <td>${isScr ? t('LB_RC_SCR') : inv2}</td>
            <td>${isScr ? t('LB_RC_SCR') : inv3}</td>
            ${betType=='QTT' ? `<td>${isScr ? t('LB_RC_SCR') : inv4}</td>` : ""}
        </tr>`);
    }
    return `<table>${table.join("")}</table>`;
}

export const clipboardTableALL = (data, runnerData, title, t) => {
    const numOfRow = 12;
    const numOfItem = 48;
    const isRefund = data.status?.toUpperCase().indexOf("REFUND")>=0;
    
    const total = Math.ceil(data?.oddsNodes?.length / numOfItem);
    const table = [];
    table.push(`<tr><td colspan="8" style="background-color:#122c68; color:#FFFFFF">${title}</td></tr>`);

    for (let pageNum=1; pageNum<=total; pageNum++) {
        const oddsData = data?.oddsNodes?.filter((item, index) => index >= (pageNum - 1) * numOfItem && index <= (pageNum * numOfItem - 1));
        table.push(`<tr><td colspan="8">P.${pageNum}</td></tr>`);
        for (let i = 0; i < numOfRow; i++) {
            let row = [];
            for (let j = 0; j < 4; j++) {
                let tmpData = oddsData[(j * numOfRow) + i];
                let comb = '';
                let odds = '';
                if (tmpData != null) {
                    let isScratch = false;
                    let isReserve = false;
                    comb = tmpData.combString.split(',').map((item, index) => {
                        let rNo = parseInt(item);
                        let runnerStat = runnerData.filter(x=>parseInt(x.no)===rNo)[0]?.status.toUpperCase();
                        if ( runnerStat?.indexOf("SCRATCH") >= 0 ) {
                            isScratch = true;
                        }
                        else if ( ['RESERVED', 'STANDBY'].includes(runnerStat) ) {
                            isReserve = true;
                        }
                        return rNo;
                    }).join('-');
                    odds = (isRefund || isReserve) ? "---" : (isScratch ? t('LB_RC_SCR') : (tmpData.oddsValue || ''));
                }
                row.push(`<td>="${comb}"</td><td>${odds}</td>`);
            }
            table.push(`<tr>${row.join("")}</tr>`);
        }
    }

    return `<table>${table.join("")}</table>`;
}