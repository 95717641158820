import ewin_race_card from '../../../info/Include/images/ewin_race_card.png';
import ewin_racing_odds from '../../../info/Include/images/ewin_racing_odds.svg';
import ewin_racing_results from '../../../info/Include/images/ewin_racing-results.svg';
import ewin_racing_info from '../../../info/Include/images/ewin_racing_info.svg';
import ewin_football_results from '../../../info/Include/images/ewin_football_results.svg';
import ewin_football_matches from '../../../info/Include/images/ewin_football_matches.svg';
import ewin_football_info from '../../../info/Include/images/ewin_football_info.svg';
import ewin_marksix_schedule from '../../../info/Include/images/ewin_marksix_schedule.svg';
import ewin_marksix_calendar from '../../../info/Include/images/ewin_marksix_calendar.svg';
import ewin_marksix_info from '../../../info/Include/images/ewin_marksix_info.svg';

export const ewinUsefulLinks = {
    ch: {
        "data": {
            "item": {
                "children": [
                    {
                        "key": "Int_Football Results or Dividends",
                        "product": {
                            "value": "Football"
                        },
                        "name": {
                            "value": "賽果/派彩"
                        },
                        "icon": {
                            "src": ewin_football_results,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": "/ch/football/results"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Race Card",
                        "product": {
                            "value": "Racing"
                        },
                        "name": {
                            "value": "排位表"
                        },
                        "icon": {
                            "src": ewin_race_card,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": "https://racing.hkjc.com/racing/information/Chinese/racing/RaceCard.aspx"
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Odds",
                        "product": {
                            "value": "Racing"
                        },
                        "name": {
                            "value": "賠率"
                        },
                        "icon": {
                            "src": ewin_racing_odds,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": ""
                        },
                        "pX": {
                            "value": ""
                        },
                        "pY": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "/ch/racing/wp"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Racing Results or Dividends",
                        "product": {
                            "value": "Racing"
                        },
                        "name": {
                            "value": "賽果/派彩"
                        },
                        "icon": {
                            "src": ewin_racing_results,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": "https://racing.hkjc.com/racing/information/Chinese/Racing/LocalResults.aspx"
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Racing Guide",
                        "product": {
                            "value": "Racing"
                        },
                        "name": {
                            "value": "賽馬指南"
                        },
                        "icon": {
                            "src": ewin_racing_info,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": "https://special.hkjc.com/e-win/zh-HK/betting-info/racing/beginners-guide/guide/"
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_In-play Betting",
                        "product": {
                            "value": "Football"
                        },
                        "name": {
                            "value": "即場投注"
                        },
                        "icon": {
                            "src": ewin_football_matches,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": "/ch/football/inplay"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Football Guide",
                        "product": {
                            "value": "Football"
                        },
                        "name": {
                            "value": "足智彩指南"
                        },
                        "icon": {
                            "src": ewin_football_info,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": "https://special.hkjc.com/e-win/zh-HK/betting-info/football/had/"
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Draw Schedule",
                        "product": {
                            "value": "Mark Six"
                        },
                        "name": {
                            "value": "攪珠日期表"
                        },
                        "icon": {
                            "src": ewin_marksix_schedule,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": "/ch/marksix/fixtures"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Result",
                        "product": {
                            "value": "Mark Six"
                        },
                        "name": {
                            "value": "攪珠結果"
                        },
                        "icon": {
                            "src": ewin_marksix_calendar,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": "/ch/marksix/results"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Mark Six Guide",
                        "product": {
                            "value": "Mark Six"
                        },
                        "name": {
                            "value": "六合彩指南"
                        },
                        "icon": {
                            "src": ewin_marksix_info,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": "https://special.hkjc.com/e-win/zh-HK/betting-info/marksix/lottery/"
                        },
                        "enable": {
                            "value": "1"
                        }
                    }
                ]
            }
        }
    },
    en: {
        "data": {
            "item": {
                "children": [
                    {
                        "key": "Int_Football Results or Dividends",
                        "product": {
                            "value": "Football"
                        },
                        "name": {
                            "value": "Results / Dividends"
                        },
                        "icon": {
                            "src": ewin_football_results,
                            "isLocalSrc":true,
                            "width": null,
                            "height": null
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": ""
                        },
                        "pX": {
                            "value": ""
                        },
                        "pY": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "/en/football/results"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Race Card",
                        "product": {
                            "value": "Racing"
                        },
                        "name": {
                            "value": "Race Card"
                        },
                        "icon": {
                            "src": ewin_race_card,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": "https://racing.hkjc.com/racing/information/English/racing/RaceCard.aspx"
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Odds",
                        "product": {
                            "value": "Racing"
                        },
                        "name": {
                            "value": "Odds"
                        },
                        "icon": {
                            "src": ewin_racing_odds,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": ""
                        },
                        "pX": {
                            "value": ""
                        },
                        "pY": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "/en/racing/wp"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Racing Results or Dividends",
                        "product": {
                            "value": "Racing"
                        },
                        "name": {
                            "value": "Results / Dividends"
                        },
                        "icon": {
                            "src": ewin_racing_results,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": "https://racing.hkjc.com/racing/information/English/Racing/LocalResults.aspx"
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Racing Guide",
                        "product": {
                            "value": "Racing"
                        },
                        "name": {
                            "value": "Guide"
                        },
                        "icon": {
                            "src": ewin_racing_info,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": "https://special.hkjc.com/e-win/en-US/betting-info/racing/beginners-guide/guide/"
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_In-play Betting",
                        "product": {
                            "value": "Football"
                        },
                        "name": {
                            "value": "In-play betting"
                        },
                        "icon": {
                            "src": ewin_football_matches,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": ""
                        },
                        "pX": {
                            "value": ""
                        },
                        "pY": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "/en/football/inplay"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Football Guide",
                        "product": {
                            "value": "Football"
                        },
                        "name": {
                            "value": "Guide"
                        },
                        "icon": {
                            "src": ewin_football_info,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": "https://special.hkjc.com/e-win/en-US/betting-info/football/had/"
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Draw Schedule",
                        "product": {
                            "value": "Mark Six"
                        },
                        "name": {
                            "value": "Draw Schedule"
                        },
                        "icon": {
                            "src": ewin_marksix_schedule,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": ""
                        },
                        "pX": {
                            "value": ""
                        },
                        "pY": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "/en/marksix/fixtures"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Result",
                        "product": {
                            "value": "Mark Six"
                        },
                        "name": {
                            "value": "Result"
                        },
                        "icon": {
                            "src": ewin_marksix_calendar,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "redirectMethod": {
                            "value": "Replace original browser"
                        },
                        "pH": {
                            "value": ""
                        },
                        "pW": {
                            "value": ""
                        },
                        "pX": {
                            "value": ""
                        },
                        "pY": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "/en/marksix/results"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "enable": {
                            "value": "1"
                        }
                    },
                    {
                        "key": "Int_Mark Six Guide",
                        "product": {
                            "value": "Mark Six"
                        },
                        "name": {
                            "value": "Guide"
                        },
                        "icon": {
                            "src": ewin_marksix_info,
                            "isLocalSrc":true,
                            "width": 40,
                            "height": 40
                        },
                        "itemRedirection": {
                            "value": "Other link"
                        },
                        "redirectMethod": {
                            "value": "Popup"
                        },
                        "pH": {
                            "value": "600"
                        },
                        "pW": {
                            "value": "900"
                        },
                        "pX": {
                            "value": "20"
                        },
                        "pY": {
                            "value": "20"
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": "https://special.hkjc.com/e-win/en-US/betting-info/marksix/lottery/"
                        },
                        "enable": {
                            "value": "1"
                        }
                    }
                ]
            }
        }
    }
}