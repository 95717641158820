import React from 'react';
import { useTranslation } from 'react-i18next';

const OddsDropLegend = (props) => {
    const { t } = useTranslation();
    return (
        <div className="odds-drop-legend">
            <div className="header">{t('LB_RC_GUIDE_ODDS_CHANGE')}</div>
            <div className="content">
                <div className="">
                    <div className="box full-column-no-margin-m">
                        <div className="favourite" />
                        <span>{t('LB_RC_FAVOURITE')}</span>
                    </div>
                    <div className="box">
                        <div className="drop20" />
                        <span>{t('LB_RC_ODDS_DROP_20')}</span>
                    </div>
                    <div className="box">
                        <div className="drop50" />
                        <span>{t('LB_RC_ODDS_DROP_50')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OddsDropLegend;