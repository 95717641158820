import React from 'react';
import { useTranslation } from 'react-i18next';
import { pad } from '../../Common/home-common';

const FGSTable = ({foPool, isMobile, getOddsVal }) => {

    const { t, i18n } = useTranslation();

    let oddsLines = [];

    const playerSize = 20;
    const line = foPool.lines[0];

    for (let i = 1; i <= playerSize; i++) {
        let homeComb = '1' + pad(i,2);
        let awayComb = '2' + pad(i,2);
        let homeOdds = line.combinations.filter(x => x.str === homeComb)[0];
        let awayOdds = line.combinations.filter(x => x.str === awayComb)[0];

        if (homeOdds == null && awayOdds == null) {
            continue;
        }

        let hNo = null;
        let hName = null;
        let aNo = null;
        let aName = null;

        if ( homeOdds != null ) {
            hNo = homeOdds.selections?.[0]?.str;
            hName = homeOdds.selections?.[0]?.['name_' + i18n.language];
        }
        if ( awayOdds != null ) {
            aNo = awayOdds.selections?.[0]?.str;
            aName = awayOdds.selections?.[0]?.['name_' + i18n.language];
        }

        oddsLines.push(<div className={`match-row${isMobile ? '-m' : ''}`}>
            <div className='odds'>
                <div>{hNo} {hName}</div>
                <span></span>
                {homeOdds && getOddsVal(line, homeComb, isMobile)}
            </div>
            <div className='odds'>
                <div>{aNo} {aName}</div>
                <span></span>
                {awayOdds && getOddsVal(line, awayComb, isMobile)}
            </div>
        </div>)
    }
    let noScorerComb = '000';
    let noScorerOdds = line.combinations.filter(x => x.str === noScorerComb)[0];
    let nNo = noScorerOdds?.selections?.[0]?.str;
    if ( nNo=="000")
        nNo = "00";
    let nName = noScorerOdds?.selections?.[0]?.['name_' + i18n.language];
    if( !nName || nName== "" ) {
        nName = t('LB_FB_FGS_NOFIRSTSCORER');
    }

    oddsLines.push(<div className={`match-row${isMobile ? '-m' : ''} zero`}>
        <div className='odds'>
            <div>{nNo} {nName}</div>
            <span></span>
            {getOddsVal(line, noScorerComb, isMobile)}
        </div>
    </div>)

    return <div className='match-row-container match-table FGS'>{oddsLines}</div>;
}
export default FGSTable