import { alupFormulaItem, nPr, nCr } from '../../Common/GlobalFunc';

export function isPoolExist(betType, pools) {
    if (pools == null)
        return false;

    switch (betType) {
        case "ALUP":
            return pools.filter((data) => ['WIN', 'PLA', 'QIN', 'QPL', 'FCT', 'TRI', 'CWA'].includes(data.oddsType)).length>0;
        case "W-P":
            return pools.filter((data) => 'WIN'==data.oddsType).length>0 
            && pools.filter((data) => 'PLA'==data.oddsType).length>0;
        case "QQP":
            return pools.filter((data) => 'QIN'==data.oddsType).length>0 
            && pools.filter((data) => 'QPL'==data.oddsType).length>0;
        default:
            return pools.filter((data) => betType.indexOf(data.oddsType)>=0).length>0;
    }
}

export function getPool(pools, betType, raceNo) {
    return pools.filter(x=> x.leg.races[0] === raceNo && betType===x.oddsType)[0];
}

export function getFirstAlupPool(pools, rbcBetTypes, raceNo) {
    let poolKeys = Object.keys(rbcBetTypes);
    for ( let i=1; i<poolKeys.length; i++ ) {
        if ( rbcBetTypes[poolKeys[i]].alup && pools.filter((data) => data.leg.races[0] === raceNo && poolKeys[i]===data.oddsType).length>0 ) {
            return poolKeys[i];
        }
    }
    return "";
}

export function getAlupPools(pools, rbcBetTypes, raceNo) {
    let alupPools = [];
    let poolKeys = Object.keys(rbcBetTypes);
    for ( let i=1; i<poolKeys.length; i++ ) {
        if ( rbcBetTypes[poolKeys[i]].alup ) {
            switch (poolKeys[i]) {
                case "W-P":
                    if ( pools.filter((data) => data.leg.races[0] === raceNo && 'WIN'==data.oddsType).length>0 
                    && pools.filter((data) => data.leg.races[0] === raceNo && 'PLA'==data.oddsType).length>0 ) {
                        alupPools.push(poolKeys[i]);
                    }
                    break;
                case "QQP":
                    if ( pools.filter((data) => data.leg.races[0] === raceNo && 'QIN'==data.oddsType).length>0 
                    && pools.filter((data) => data.leg.races[0] === raceNo && 'QPL'==data.oddsType).length>0 ) {
                        alupPools.push(poolKeys[i]);
                    }
                    break;
                default:
                    if ( pools.filter((data) => data.leg.races[0] === raceNo && poolKeys[i]===data.oddsType).length>0 ) {
                        alupPools.push(poolKeys[i]);
                    }
                    break;
            }
        }
    }
    return alupPools;
}

export function getMaxAlupLeg(rbcBetTypes, betTypesByRace) {
    let maxLeg = 99;
    Object.keys(betTypesByRace).forEach(key => {
        maxLeg = Math.min(maxLeg, rbcBetTypes[betTypesByRace[key]].maxAlupLeg);
    });
    return maxLeg;
}

export function getAvailableRaces(betType, meeting) {
    let raceArr = [];
    let pools = meeting.pmPools;
    const races=meeting.races;
    let maxRaceNo=1;
    for ( let index = 0; index < races.length; index++ ) {
        maxRaceNo=Math.max(maxRaceNo,races[index].no);
    }
    for ( let i=1; i<=maxRaceNo; i++ ) {
        switch (betType) {
            case "ALUP":
                if ( pools.filter((data) => data.leg.races[0] === i && ['WIN', 'PLA', 'QIN', 'QPL', 'FCT', 'TRI', 'CWA'].includes(data.oddsType)).length>0 ) {
                    raceArr.push(i);
                }
                break;
            case "W-P":
                if ( pools.filter((data) => data.leg.races[0] === i && 'WIN'===data.oddsType).length>0 
                    && pools.filter((data) => data.leg.races[0] === i && 'PLA'===data.oddsType).length>0 ) {
                    raceArr.push(i);
                }
                break;
            case "QQP":
                if ( pools.filter((data) => data.leg.races[0] === i && 'QIN'===data.oddsType).length>0 
                    && pools.filter((data) => data.leg.races[0] === i && 'QPL'===data.oddsType).length>0 ) {
                    raceArr.push(i);
                }
                break;
            default:
                if ( pools.filter((data) => data.leg.races[0] === i && betType===data.oddsType).length>0 ) {
                    raceArr.push(i);
                }
                break;
        }
    }
    return raceArr;
}

export function getAvailableRunnerNos(meeting, raceNo) {
    let runnerNos = [];
    let race = meeting.races.filter(x=> x.no===raceNo)[0];
    let runners = race.runners.filter(x=> x.no!=null && x.no!="");
    runners.forEach(x=> {
        runnerNos.push(x.no);
    });
    runnerNos.sort((x,y)=> { return parseInt(x)-parseInt(y)});
    return runnerNos;
}

export function getScratchRunnerNos(meeting, raceNo) {
    let runnerNos = [];
    let race = meeting.races.filter(x=> x.no===raceNo)[0];
    return race.runners.filter(x=> x.no!=null && ["Scratched","SCRATCHED","ScratchedReserved"].includes(x.status)   ).map(r => r.no);
}

export const getSelections = (rbcState, betType, raceNo, type) => {
    let selPrefix = `${betType}_${raceNo}_${type}`;
    if ( rbcState.selections[`${selPrefix}_F`] )
        return getAvailableRunnerNos(rbcState.meeting, raceNo);
    return Object.keys(rbcState.selections)
        .filter(x=> x.indexOf(selPrefix)>=0)
        .map(x => x.split('_')[3])
        .sort((x,y)=> {return isNaN(x) ? (x < y ? -1 : 1) : parseInt(x)-parseInt(y)});
}

export const validateBet = (rbcState, t) => {
    let valid = true;
    let totalCnt = 0;
    let cnts = [];

    let raceList = rbcState.races;
    if (rbcState.raceConfig.rbcBetType[rbcState.betType].multiLeg && rbcState.races.length>0) {
        raceList = getPool(rbcState.meeting.pmPools, rbcState.betType, rbcState.races[0])?.leg.races;
    }
    raceList.forEach(raceNo => {
        if ( valid ) {
            let cnt = 0;
            let firstRaceNo = rbcState.raceConfig.rbcBetType[rbcState.betType].multiLeg ? raceList[0] : raceNo;
            let betType = rbcState.betTypesByRace[firstRaceNo];
            let subType = rbcState.subTypesByRace[firstRaceNo];
            [valid, cnt] = validateBetByRace(t, rbcState, raceNo, betType, subType);
            cnts.push(cnt);
        }
    });
    if ( rbcState.formula!='' ) {
        let combFlag = alupFormulaItem[rbcState.formula].split('#');
        for (let i = 0; i < combFlag.length; i++) {
            let c = 1;
            for ( let n=0; n< combFlag[i].length; n++ ) {
                c *= cnts[parseInt(combFlag[i][n])-1];
            }
            totalCnt += c;
        }
    }
    else {
        totalCnt = cnts.reduce( (a, b) => a * b );
    }
    return [valid, totalCnt];
}

export function validateBetByRace(t, rbcState, raceNo, betType, subType) {
    let cnt = 0;
    let b1 = getSelections(rbcState, betType, raceNo, 'b1');
    let b2 = getSelections(rbcState, betType, raceNo, 'b2');
    let b3 = getSelections(rbcState, betType, raceNo, 'b3');
    let leg = getSelections(rbcState, betType, raceNo, 'leg');
    switch ( betType ) {
        case "WIN":
        case "PLA":
        case "W-P":
        case "CWA":
        case "CWB":
        case "CWC":
        case "DBL":
        case "TBL":
        case "SixUP":
            if ( leg.length<1 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = leg.length;
            break;
        case "QIN":
        case "QPL":
        case "QQP":
            if ( b1.length>1 ) {
                alert(t('ERR_TOO_MANY_BANKERS').replace('{0}', raceNo));
                return [false, 0];
            }
            leg = leg.filter(x=> b1.indexOf(x)<0);
            if ( leg.length<2 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt  = nCr(leg.length, 2 - b1.length);
            break;
        case "TRI":
        case "DT":
        case "TT":
            if ( b1.length>2 ) {
                alert(t('ERR_TOO_MANY_BANKERS').replace('{0}', raceNo));
                return [false, 0];
            }
            leg = leg.filter(x=> b1.indexOf(x)<0);
            if ( (b1.length===2 && leg.length<2)
                || (b1.length===1 && leg.length<3)
                || (b1.length===0 && leg.length<3) ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt  = nCr(leg.length, 3 - b1.length);
            break;
        case "FF":
            if ( b1.length>3 ) {
                alert(t('ERR_TOO_MANY_BANKERS').replace('{0}', raceNo));
                return [false, 0];
            }
            leg = leg.filter(x=> b1.indexOf(x)<0);
            if ( (b1.length===3 && leg.length<2)
                || (b1.length===2 && leg.length<3)
                || (b1.length===1 && leg.length<4)
                || (b1.length===0 && leg.length<4) ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt  = nCr(leg.length, 4 - b1.length);
            break;
        case "IWN":
            if ( b1.length<1 || leg.length<1 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = 1;
            break;
        case "FCT":
            return validateBetFCTByRace(t, rbcState, raceNo, betType, subType, b1, leg);
        case "TCE":
            return validateBetTCEByRace(t, rbcState, raceNo, betType, subType, b1, b2, leg);
        case "QTT":
            return validateBetQTTByRace(t, rbcState, raceNo, betType, subType, b1, b2, b3, leg);
    }
    return [true, cnt];
}

export function validateBetFCTByRace(t, rbcState, raceNo, betType, subType, b1, leg) {
    let cnt = 0;
    switch( subType ) {
        case "S":
            if ( b1.length<1 || leg.length<1 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = 1;
            break;
        case "M":
            if(b1.length>0 && leg.length<1){
                leg=b1;
            }
            if ( leg.length<2 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = nPr(leg.length, 2);
            break;
        case "B":
        case "BM":
            leg = leg.filter(x=> b1.indexOf(x)<0);
            if ( b1.length===0 || (leg.length < 2) ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = nPr(leg.length, 1);
            if ( subType==="BM" )
                cnt *= 2;
            break;
        case "MB":
            if ( (b1.length < 1 || leg.length < 1) || (b1.length+leg.length<=2) ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = calcMBBet(rbcState, betType, raceNo, 2);
            if ( cnt <= 0 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            break;
    }
    return [true, cnt];
}

export function validateBetTCEByRace(t, rbcState, raceNo, betType, subType, b1, b2, leg) {
    let cnt = 0;
    switch( subType ) {
        case "S":
            if ( b1.length<1 || b2.length<1 || leg.length<1 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = 1;
            break;
        case "M":
            if(b1.length>0 && leg.length<1){
                leg=b1;
            }
            if ( leg.length<3 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = nPr(leg.length, 3);
            break;
        case "B":
            leg = leg.filter(x=> b1.indexOf(x)<0);
            leg = leg.filter(x=> b2.indexOf(x)<0);
            var bTotal = b1.length + b2.length;
            if ( b1.length===0 || (leg.length < 2) ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = nPr(leg.length, 3 - bTotal);
            break;
        case "BM":
            leg = leg.filter(x=> b1.indexOf(x)<0);
            leg = leg.filter(x=> b2.indexOf(x)<0);
            var bTotal = b1.length + b2.length;
            if ( b1.length===0 
                || (b2.length==0 && leg.length < 3)
                || (b2.length==1 && leg.length < 2) ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = nCr(leg.length, 3 - bTotal) * 6;
            break;
        case "MB":
            if ( (b1.length < 1 || b2.length < 1 || leg.length < 1) || (b1.length+b2.length+leg.length<=3) ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = calcMBBet(rbcState, betType, raceNo, 3);
            if ( cnt <= 0 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            break;
    }
    return [true, cnt];
}

export function validateBetQTTByRace(t, rbcState, raceNo, betType, subType, b1, b2, b3, leg) {
    let cnt = 0;
    switch( subType ) {
        case "S":
            if ( b1.length<1 || b2.length<1 || b3.length<1 || leg.length<1 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = 1;
            break;
        case "M":
            if(b1.length>0 && leg.length<1){
                leg=b1;
            }
            if ( leg.length<4 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
			cnt = nPr(leg.length, 4);
            break;
        case "B":
            leg = leg.filter(x=> b1.indexOf(x)<0);
            leg = leg.filter(x=> b2.indexOf(x)<0);
            leg = leg.filter(x=> b3.indexOf(x)<0);
            var bTotal = b1.length + b2.length + b3.length;
            if ( b1.length===0 
                || (b1.length===1 && b2.length===0 && b3.length===1)
                || (bTotal === 1 && leg.length <= 2)
                || (bTotal > 1 && leg.length <= 1) ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = nPr(leg.length, 4 - bTotal);
            break;
        case "BM":
            leg = leg.filter(x=> b1.indexOf(x)<0);
            leg = leg.filter(x=> b2.indexOf(x)<0);
            leg = leg.filter(x=> b3.indexOf(x)<0);
            var bTotal = b1.length + b2.length + b3.length;
            if ( b1.length===0 
                || (b1.length===1 && b2.length===0 && b3.length===1)
                || (bTotal == 1 && leg.length <= 3)
                || (bTotal == 2 && leg.length <= 2) 
                || (bTotal > 2 && leg.length <= 1) ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = nCr(leg.length, 4 - bTotal) * 24;
            break;
        case "MB":
            if ( (b1.length < 1 || b2.length < 1 || b3.length < 1 || leg.length < 1) || (b1.length+b2.length+b3.length+leg.length<=4) ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            cnt = calcMBBet(rbcState, betType, raceNo, 4);
            if ( cnt <= 0 ) {
                alert(t('ERR_SELECTIONS_RACE').replace('{0}', raceNo));
                return [false, 0];
            }
            break;
    }
    return [true, cnt];
}

export function calcMBBet(rbcState, betType, raceNo, size) {
    let b1 = getSelections(rbcState, betType, raceNo, "b1");
    let b2 = getSelections(rbcState, betType, raceNo, "b2");
    let b3 = getSelections(rbcState, betType, raceNo, "b3");
    let leg = getSelections(rbcState, betType, raceNo, "leg");
    let cnt = 0;
    for (let i in leg) {
        if (leg.hasOwnProperty(i)) {
            for (let j in b1) {
                if (b1.hasOwnProperty(j)) {
                    if (leg[i] == b1[j]) {
                        continue;
                    }
                    if (size > 2) {
                        for (let k in b2) {
                            if (b2.hasOwnProperty(k)) {
                                if (leg[i] == b2[k] || b1[j] == b2[k]) {
                                    continue;
                                }
                                if (size > 3) {
                                    for (let m in b3) {
                                        if (b3.hasOwnProperty(m)) {
                                            if (leg[i] == b3[m] || b1[j] == b3[m] || b2[k] == b3[m]) {
                                                continue;
                                            }
                                            cnt++;
                                        }
                                    }
                                }
                                else {
                                    cnt++;
                                }
                            }
                        }
                    }
                    else {
                        cnt++;
                    }
                }
            }
        }
    }
    return cnt;
}

export const getCWDesc = (t, rbcState, betType, raceNo) => {
    let selPrefix = `${betType}_${raceNo}_leg`;
    return Object.keys(rbcState.selections)
        .filter(x=> x.indexOf(selPrefix)>=0)
        .sort((x,y)=> {return x < y ? -1 : 1})
        .map(x => {
            let composite = x.split('_')[3];
            let pool = getPool(rbcState.meeting.pmPools, betType, raceNo);
            let starters = pool.cWinSelections.filter(y=> y.composite===composite)[0].starters.join('+');
            return `${t('LB_RC_CWIN_COMPOSITE')} ${composite} (${starters})`;
        });
}

export const getCWDescByBetline = (t, rbcState, betline) => {
    betline=betline.split("/");
    let newBetLine=betline.map(singleBet=>{
        if(singleBet.startsWith("CW")){
            let [ betType, items ] = singleBet.split(" ");
            let [ raceNo, composite ] = items.split("*");
            let pool = getPool(rbcState.meeting.pmPools, betType, parseInt(raceNo));
            let starters = pool.cWinSelections.filter(y=> y.composite===composite)[0].starters.join('+');
            return `${betType} ${raceNo}*${t('LB_RC_CWIN_COMPOSITE')} ${composite} (${starters})`;
        }else{
            return singleBet;
        }
    })
    return newBetLine.join('/');
}

export const buildBetline = (t, rbcState, firstRaceNo, raceNo, showHead, showDesc) => {
    let line = '';
    let betType = rbcState.betTypesByRace[firstRaceNo];
    let subType = rbcState.subTypesByRace[firstRaceNo];
    let bk = getSelections(rbcState, betType, raceNo, 'b1');
    let bk2 = getSelections(rbcState, betType, raceNo, 'b2');
    let bk3 = getSelections(rbcState, betType, raceNo, 'b3');
    let leg = getSelections(rbcState, betType, raceNo, 'leg');
    let bType = toBetlineBetType(betType);
    if ( subType!='' ) {
        bType = `${bType} ${subType}`;
    }
    let head = showHead ? `${bType} ${raceNo}*` : "";
    switch ( bType ) {
        case 'IWN':
            line = `${head}${bk[0]}#${leg[0]}`;
            break;
        case 'FCT S':
            line = `${head}${bk[0]}+${leg[0]}`;
            break;
        case 'TCE S':
            line = `${head}${bk[0]}+${bk2[0]}+${leg[0]}`;
            break;
        case 'QTT S':
            line = `${head}${bk[0]}+${bk2[0]}+${bk3[0]}+${leg[0]}`;
            break;
        case 'CWA':
        case 'CWB':
        case 'CWC':
            line = `${head}${showDesc? getCWDesc(t, rbcState, betType, raceNo).join('+') : leg.join('+')}`;
            break;
        default:
            if ( subType!=="MB" ) {
                leg = leg.filter(x=> bk.indexOf(x)<0);
                leg = leg.filter(x=> bk2.indexOf(x)<0);
                leg = leg.filter(x=> bk3.indexOf(x)<0);
            }
            const curSign=subType!=="MB"?'+':'>'
            line = `${head}${bk.length>0 ? `${bk.join('+')}` : ''}${bk2.length>0 ? `${curSign}${bk2.join('+')}` : ''}${bk3.length>0 ? `${curSign}${bk3.join('+')}` : ''}${bk.length>0 && leg.length>0 ?'>':''}${leg.join('+')}`;
            break;
    }
    return line;
}

const toBetlineBetType = (betType) => {
    switch ( betType ) {
        case "FF":
            return "F-F";
        case "DT":
            return "D-T";
        case "TT":
            return "T-T";
        case "SixUP":
            return "6UP";
    }
    return betType;
}

export const formatSimpleBetType = (betType) => {
    switch ( betType ) {
        case "F-F":
            return "FF";
        case "D-T":
            return "DT";
        case "T-T":
            return "TT";
        case "6UP":
            return "SixUP";
    }
    return betType;
}