import { timeoutFetch } from '../../Common/timeoutRequest';

const FetchApi = ({ timeout, options, params, url }) => {
    const onError = (err) => {
        console.error(err);
    };
    const onSuccess = (response) => {
        return response;
    };
    const timeoutErrData = {
        errCode: 'TIMEOUT'
    };
    return timeoutFetch({ timeout: timeout * 1000, onError, onSuccess, options, timeoutErrData })(url, params);
};

// re-call API after 2s if error returned
const retryFetch = async (errorFieldName, fetchFunc) => {
    const RETRY_COUNT = 1;
    // Returns a Promise that resolves after "ms" Milliseconds
    const timer = ms => new Promise(res => setTimeout(res, ms))

    const checkFetchSuccessFunc = (data) => {
        if (window.bsConfig.retry_error.filter((item) => data?.[errorFieldName]?.toUpperCase()?.indexOf(item) >= 0).length > 0) {
            return false;
        }
        return true;
    }

    let output = {};
    for (let i = 0; i <= RETRY_COUNT; i++) {
        output = await fetchFunc();
        console.debug('checkFetchSuccessFunc', checkFetchSuccessFunc(output), output)
        // if (checkFetchSuccessFunc(output) && i != 0) {
        // if (i == 0) output.stmt_error = 'system busy ehgiuhg'
        if (checkFetchSuccessFunc(output)) {
            return output;
        } else {
            await timer(2000);
        }
    }
    return output;
}

export const getFPSBankList_IB = async (language) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Para.svc/GetBankList`;
    const bodyValue = {
        isNew: true
    }
    return await fetch(url, {
        method: 'GET',
        credentials: 'include',
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const getFPSErrorList_IB = async (language) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Para.svc/GetFpsError`;
    const bodyValue = {
        isNew: true
    }
    return await fetch(url, {
        method: 'GET',
        credentials: 'include',
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const getAccountInformation_IB = async (lang) => {
    let nba1ActivatedChannels = sessionStorage.getItem('primarynba_activation');
    let nba1ActivatedChannelsArr = [];
    if (nba1ActivatedChannels == 1 || nba1ActivatedChannels == 3) {
        nba1ActivatedChannelsArr.push('IS');
        nba1ActivatedChannelsArr.push('TB');
    }
    let nba2ActivatedChannels = sessionStorage.getItem('secondarynba_activation');
    let nba2ActivatedChannelsArr = [];
    if (nba2ActivatedChannels == 1 || nba2ActivatedChannels == 3) {
        nba2ActivatedChannelsArr.push('IS');
        nba2ActivatedChannelsArr.push('TB');
    }
    let accountTypeIndicationArr = [];
    if (sessionStorage.getItem('EFTWhiteListed') != 0) {
        accountTypeIndicationArr.push('EFT White-listed Account');
    }
    if (sessionStorage.getItem('EFTWithdrawalFeeEnable') == 1) {
        accountTypeIndicationArr.push('EFT Withdrawal Fee Enablement Indicator');
    }
    if (sessionStorage.getItem('EFTDepositFeeEnable') == 1) {
        accountTypeIndicationArr.push('EFT Deposit Fee Enablement Indicator');
    }
    return {
        accountNo: sessionStorage.getItem('account'),
        deviceType: 26,
        sessionId: sessionStorage.getItem('session_id'),
        timestampId: 28800,
        token: '',
        result: 0,
        resultMsg: '',
        nba: {
            nba1Status: sessionStorage.getItem('primarynba_status') == 1 ? 'valid' : 'invalid',
            nba1BankCode: sessionStorage.getItem('primarynba_bankcode'),
            nba1BankAccountNumber: sessionStorage.getItem('primarynba_bankacno'),
            nba1ActivatedChannels: nba1ActivatedChannelsArr,
            nba2Status: sessionStorage.getItem('secondarynba_status') == 1 ? 'valid' : 'invalid',
            nba2BankCode: sessionStorage.getItem('secondarynba_bankcode'),
            nba2BankAccountNumber: sessionStorage.getItem('secondarynba_bankacno'),
            nba2ActivatedChannels: nba2ActivatedChannelsArr
        },
        information: {
            accountType: sessionStorage.getItem('acc_type'),
            language: lang,
            fbAccountType: 7, //sessionStorage.getItem('FBAccountRecordsSharing'),
            accountAccessMessage: sessionStorage.getItem('acc_name'),
            accountTypeIndication: accountTypeIndicationArr,
            EFTServiceFee: sessionStorage.getItem('EFTServiceFee').replace('$', '')
        }
    };
};

const getSetSequenceNumber = () => {
    const seqNo = -(-window.opener.sessionStorage.getItem('seq_no')) + 1;
    window.opener.sessionStorage.setItem('seq_no', seqNo);
    sessionStorage.setItem('seq_no', seqNo);
    return seqNo;
};

export const getFPSInfomation_IB = async (language) => {
    return await retryFetch('errorMessage', () => getFPSInfomation_IB_wrapped(language));
}

const getFPSInfomation_IB_wrapped = async (language) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/FPS.svc/DoFPSInfoTR`;
    const payload = {
        lang: language == 'ch' ? 1 : 0,
        acc: sessionStorage.getItem('account'),
        sid: sessionStorage.getItem('session_id'),
        apimId: sessionStorage.getItem('gu_id'),
        seqNo: getSetSequenceNumber(),
        bettingAccount: sessionStorage.getItem('account'),
        bankCode1: sessionStorage.getItem('primarynba_bankcode'),
        bankAccountNo1: sessionStorage.getItem('primarynba_bankacno'),
        bankCode2: sessionStorage.getItem('secondarynba_bankcode'),
        bankAccountNo2: sessionStorage.getItem('secondarynba_bankacno'),
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        isNew: true
    };

    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            // console.log('data', data);
            // store to session storage
            for (const item of data['DoFPSInfoTRResult']) {
                sessionStorage.setItem(item['Key'], item['Value']);
            }
            if (
                (sessionStorage.getItem('secondarynba_bankacno') == null ||
                    sessionStorage.getItem('secondarynba_bankacno') == '') &&
                (sessionStorage.getItem('secondarynba_bankcode') == null ||
                    sessionStorage.getItem('secondarynba_bankcode') == '')
            ) {
                return {
                    bankAccount: [
                        {
                            bankAccountType: 1,
                            bankAccountNo: sessionStorage.getItem('primarynba_bankacno'),
                            bankCode: sessionStorage.getItem('primarynba_bankcode'),
                            eDDAStatus: sessionStorage.getItem('primarynba_FPSeDDAStatus'),
                            errorType: sessionStorage.getItem('primarynba_FPSerrorType'),
                            errorCode: sessionStorage.getItem('primarynba_FPSerrorCode'),
                            errorSubCode: '', //sessionStorage.getItem(""),
                            dailyDepMaxAmt: -(-sessionStorage.getItem('primarynba_FPSdailyDepMaxAmt')),
                            notifyEDDAStatus: sessionStorage.getItem('primarynba_FPSnotifyEDDAStatus'),
                            FPSPStatus: -(-sessionStorage.getItem('primarynba_FPSPStatus')),
                            EDDASingleLegStatus: -(-sessionStorage.getItem('primarynba_EDDASingleLegStatus')),
                            notifyEDDASingleLegStatus: sessionStorage.getItem('primarynba_notifyEDDASingleLegStatus'),
                            notifyEDDASingleLegErrorType: sessionStorage.getItem(
                                'primarynba_notifyEDDASingleLegErrorType'
                            ),
                            notifyEDDASingleLegErrorCode: sessionStorage.getItem(
                                'primarynba_notifyEDDASingleLegErrorCode'
                            ),
                            notifyEDDASingleLegErrorSubCode: sessionStorage.getItem(
                                'primarynba_notifyEDDASingleLegErrorSubCode'
                            )
                        }
                    ],
                    currentMonthlyDepCnt: -(-sessionStorage.getItem('FPScurrentMonthlyDepCnt'))
                };
            } else {
                return {
                    bankAccount: [
                        {
                            bankAccountType: 1,
                            bankAccountNo: sessionStorage.getItem('primarynba_bankacno'),
                            bankCode: sessionStorage.getItem('primarynba_bankcode'),
                            eDDAStatus: sessionStorage.getItem('primarynba_FPSeDDAStatus'),
                            errorType: sessionStorage.getItem('primarynba_FPSerrorType'),
                            errorCode: sessionStorage.getItem('primarynba_FPSerrorCode'),
                            errorSubCode: '', //sessionStorage.getItem(""),
                            dailyDepMaxAmt: -(-sessionStorage.getItem('primarynba_FPSdailyDepMaxAmt')),
                            notifyEDDAStatus: sessionStorage.getItem('primarynba_FPSnotifyEDDAStatus'),
                            FPSPStatus: -(-sessionStorage.getItem('primarynba_FPSPStatus')),
                            EDDASingleLegStatus: -(-sessionStorage.getItem('primarynba_EDDASingleLegStatus')),
                            notifyEDDASingleLegStatus: sessionStorage.getItem('primarynba_notifyEDDASingleLegStatus'),
                            notifyEDDASingleLegErrorType: sessionStorage.getItem(
                                'primarynba_notifyEDDASingleLegErrorType'
                            ),
                            notifyEDDASingleLegErrorCode: sessionStorage.getItem(
                                'primarynba_notifyEDDASingleLegErrorCode'
                            ),
                            notifyEDDASingleLegErrorSubCode: sessionStorage.getItem(
                                'primarynba_notifyEDDASingleLegErrorSubCode'
                            )
                        },
                        {
                            bankAccountType: 2,
                            bankAccountNo: sessionStorage.getItem('secondarynba_bankacno'),
                            bankCode: sessionStorage.getItem('secondarynba_bankcode'),
                            eDDAStatus: sessionStorage.getItem('secondarynba_FPSeDDAStatus'),
                            errorType: sessionStorage.getItem('secondarynba_FPSerrorType'),
                            errorCode: sessionStorage.getItem('secondarynba_FPSerrorCode'),
                            errorSubCode: '', //sessionStorage.getItem(""),
                            dailyDepMaxAmt: -(-sessionStorage.getItem('secondarynba_FPSdailyDepMaxAmt')),
                            notifyEDDAStatus: sessionStorage.getItem('secondarynba_FPSnotifyEDDAStatus'),
                            FPSPStatus: -(-sessionStorage.getItem('secondarynba_FPSPStatus')),
                            EDDASingleLegStatus: -(-sessionStorage.getItem('secondarynba_EDDASingleLegStatus')),
                            notifyEDDASingleLegStatus: sessionStorage.getItem('secondarynba_notifyEDDASingleLegStatus'),
                            notifyEDDASingleLegErrorType: sessionStorage.getItem(
                                'secondarynba_notifyEDDASingleLegErrorType'
                            ),
                            notifyEDDASingleLegErrorCode: sessionStorage.getItem(
                                'secondarynba_notifyEDDASingleLegErrorCode'
                            ),
                            notifyEDDASingleLegErrorSubCode: sessionStorage.getItem(
                                'secondarynba_notifyEDDASingleLegErrorSubCode'
                            )
                        }
                    ],
                    currentMonthlyDepCnt: -(-sessionStorage.getItem('FPScurrentMonthlyDepCnt'))
                };
            }
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const postDepositFps_IB = async (data) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/FPS.svc/DoFPSDepositTR`;
    const payload = {
        lang: data.lang,
        bettingAccount: sessionStorage.getItem('account'),
        sessionId: sessionStorage.getItem('session_id'),
        apimId: sessionStorage.getItem('gu_id'),
        seqNo: getSetSequenceNumber(),
        seqNo2: getSetSequenceNumber(),
        nbaIndicator: data.params.bankAccountType,
        bankAccountNo: data.params.bankAccountNo,
        bankCode: data.params.bankCode,
        firstName: sessionStorage.getItem('firstname'),
        lastName: sessionStorage.getItem('lastname'),
        transactionAmount: data.params.transAmt / 100,
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        knownWebID: sessionStorage.getItem('sso_web_id'),
        isNew: true
    };

    const timeout = data.timeout;
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((res) => {
            if (res.errCode) {
                return res;
            }
            const dataObj = {};
            for (const item of res['DoFPSDepositTRResult']) {
                dataObj[item['Key']] = item['Value'];
                sessionStorage.setItem(item['Key'], item['Value']);
            }
            // console.log('dataObj', dataObj);
            return {
                transId: '123',
                transStatus: dataObj.transStatus,
                transAmt: dataObj.transAmt,
                serviceChargeAmt: dataObj.serviceChargeAmt,
                errorType: dataObj.errorType,
                errorCode: dataObj.errorCode,
                errorSubCode: dataObj.errorSubCode,
                currentMonthlyDepCnt: dataObj.FPScurrentMonthlyDepCnt,
                transactionNumber: dataObj.transactionNumber,
                errMsg: dataObj.errorMsg,
                errMsgTC: dataObj.errorMsgTC
            };
        })
        .catch((err) => {
            console.error(err);
            return { errCode: 'TIMEOUT', code: 415 };
        });
};

export const postDepositEps_IB = async (data) => {
    const { lang, timeout } = data;
    const FPSPStatus =
        data.params.nba == 1
            ? sessionStorage.getItem('primarynba_FPSPStatus')
            : sessionStorage.getItem('secondarynba_FPSPStatus');
    const epscoChargeIndicator =
        FPSPStatus == 1 &&
        sessionStorage.getItem('EFTWhiteListed') == 0 &&
        sessionStorage.getItem('EFTDepositFeeEnable') == 1
            ? 1
            : 0;
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/EFT.svc/DoEFTOperationTR`;
    const payload = {
        lang: lang == 'ch' ? 1 : 0,
        acc: sessionStorage.getItem('account'),
        webId: sessionStorage.getItem('webID'),
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        seqNo: getSetSequenceNumber(),
        seqNo2: getSetSequenceNumber(),
        amount: data.params.amount,
        PIN: data.params.pinBlock,
        eftType: 5, // 5 deposit 6 withdraw
        showBal: true,
        withdrawType: '',
        nbaIndicator: data.params.nba,
        password: '',
        epscoChargeIndicator: epscoChargeIndicator, // 1 or 0?
        epscoChargeAmount: sessionStorage.getItem('EFTServiceFee'),
        isNew: true
    };

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((res) => {
            if (res.errCode) {
                return res;
            }
            const dataObj = {};
            // console.log('res', res);
            for (const item of res['DoEFTOperationTRResult']) {
                dataObj[item['Key']] = item['Value'];
                sessionStorage.setItem(item['Key'], item['Value']);
            }
            return {
                code: dataObj.eft_status,
                message: '',
                timestampId: '',
                errorSubCode: '',
                accept: sessionStorage.getItem('account'),
                amount: dataObj.transAmt,
                amountCent: dataObj.transAmt * 100,
                number: dataObj.transNo ? dataObj.transNo : '',
                message_cn: '',
                message_en: '',
                epscoChargeAmountCent: dataObj.serviceFee,
                errorCode: dataObj.eft_errcode,
                errMsg: dataObj.eft_error_en,
                errMsgTC: dataObj.eft_error_ch
            };
        })
        .catch((err) => {
            console.error(err);
            return { errCode: 'TIMEOUT', code: 415 };
        });
};

export const postWithdrawalFPS_IB = async (language, param, timeout) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/FPS.svc/DoFPSWithdrawalTR`;
    const payload = {
        lang: language,
        bettingAccount: sessionStorage.getItem('account'),
        sessionId: sessionStorage.getItem('session_id'),
        apimId: sessionStorage.getItem('gu_id'),
        seqNo: getSetSequenceNumber(),
        seqNo2: getSetSequenceNumber(),
        bankAccountType: 1, //nbaIndictator
        bankAccountNo: param.bankAccountNo,
        bankCode: param.bankCode,
        firstName: sessionStorage.getItem('firstname'),
        lastName: sessionStorage.getItem('lastname'),
        transactionAmount: param.transAmt / 100,
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        knownWebID: sessionStorage.getItem('sso_web_id'),
        isNew: true
    };

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((res) => {
            if (res.errCode) {
                return res;
            }
            const dataObj = {};
            // console.log('res', res);
            for (const item of res['DoFPSWithdrawalTRResult']) {
                dataObj[item['Key']] = item['Value'];
                sessionStorage.setItem(item['Key'], item['Value']);
            }
            return {
                accountNo: 8000834,
                deviceType: 26,
                sessionId: sessionStorage.getItem('session_id'),
                timestampId: 8689,
                result: 0,
                resultMsg: '',
                balance: 95480.0,
                errMsg: dataObj.errorMsg,
                errMsgTC: dataObj.errorMsgTC,
                transStatus: dataObj.transStatus,
                errorCode: dataObj.errorCode,
                transactionNumber: dataObj.transactionNumber,
                serviceChargeAmt: dataObj.serviceChargeAmt
            };
        })
        .catch((err) => {
            console.error(err);
            return { errCode: 'TIMEOUT', code: 415 };
        });
};

export const postWithdrawalEPS_IB = async (data) => {
    // console.log('data', data);
    const { lang, params, timeout } = data;
    const FPSPStatus = sessionStorage.getItem('primarynba_FPSPStatus')
    const epscoChargeIndicator =
        FPSPStatus == 1 && params.type == 0 && params.charge > 0 && sessionStorage.getItem('EFTWhiteListed') == 0 && sessionStorage.getItem('EFTWithdrawalFeeEnable') == 1 ? 1 : 0;
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/EFT.svc/DoEFTOperationTR`;
    const payload = {
        lang: lang == 'ch' ? 1 : 0,
        acc: sessionStorage.getItem('account'),
        webId: sessionStorage.getItem('webID'),
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        seqNo: getSetSequenceNumber(),
        seqNo2: getSetSequenceNumber(),
        amount: params.amount,
        PIN: params.pinBlock,
        eftType: 6, // 5 deposit 6 withdraws
        showBal: true,
        withdrawType: params.type, // 0 normal 1 autopay
        nbaIndicator: 1,
        password: '',
        epscoChargeIndicator: epscoChargeIndicator, // 1 or 0?
        epscoChargeAmount: sessionStorage.getItem('EFTServiceFee'),
        isNew: true
    };

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((res) => {
            if (res.errCode) {
                return res;
            }
            const dataObj = {};
            // console.log('res', res);
            for (const item of res['DoEFTOperationTRResult']) {
                dataObj[item['Key']] = item['Value'];
                sessionStorage.setItem(item['Key'], item['Value']);
            }
            return {
                code: dataObj.eft_status,
                message: '',
                timestampId: '',
                errorSubCode: dataObj.errorSubCode,
                accept: sessionStorage.getItem('account'),
                amount: dataObj.transAmt,
                amountCent: dataObj.transAmt * 100,
                number: '',
                message_cn: '',
                message_en: '',
                epscoChargeAmountCent: dataObj.serviceFee,
                errorCode: dataObj.eft_errcode, //code is same as enghlish error message
                errMsg: dataObj.eft_error_en,
                errMsgTC: dataObj.eft_error_ch,
                transactionNumber: dataObj.transNo
            };
        })
        .catch((err) => {
            console.error(err);
            return { errCode: 'TIMEOUT', code: 415 };
        });
};

export const postOtpCodeGenerate_IB = async (language, timeout) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/FPS.svc/DoOTPCodeGenerateTR`;
    const payload = {
        bettingAccount: sessionStorage.getItem('account'),
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        guid: sessionStorage.getItem('gu_id'),
        sessionId: sessionStorage.getItem('session_id'),
        seqNo: getSetSequenceNumber(),
        isNew: true
    };
    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const postOtpCodeConfirm_IB = async (otp) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/FPS.svc/DoOTPCodeConfirmTR`;
    const payload = {
        bettingAccount: sessionStorage.getItem('account'),
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        guid: sessionStorage.getItem('gu_id'),
        sessionId: sessionStorage.getItem('session_id'),
        seqNo: getSetSequenceNumber(),
        otpCode: otp,
        isNew: true
    };
    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            const dataObj = {};
            // console.log('res', data);
            for (const item of data['DoOTPCodeConfirmTRResult']) {
                dataObj[item['Key']] = item['Value'];
                //sessionStorage.setItem(item['Key'], item['Value']);
            }
            return dataObj;
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const postEddaSetup_IB = async (data) => {
    const { language, params, timeout, isSingleLeg } = data;

    let url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/FPS.svc/DoFPSSetupTR`;
    let payload = {
        lang: language == 'ch' ? 0 : 1,
        bettingAccount: sessionStorage.getItem('account'),
        sessionId: sessionStorage.getItem('session_id'),
        apimId: sessionStorage.getItem('gu_id'),
        seqNo: getSetSequenceNumber(),
        bankAccountType: params.bankAccountType,
        bankAccountNo: params.bankAccountNo,
        bankCode: params.bankCode,
        firstName: sessionStorage.getItem('firstname'),
        lastName: sessionStorage.getItem('lastname'),
        mobileNo: params.mobileNo
            ? params.mobileNo
            : `+${sessionStorage.getItem('mobile_country')}-${sessionStorage.getItem('mobile_num')}`,
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        knownWebID: sessionStorage.getItem('sso_web_id'),
        isSingleLeg: isSingleLeg,
        isNew: true
    };
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((res) => {
            if (res.errCode) {
                return res;
            }
            const dataObj = {};
            for (const item of res['DoFPSSetupResult']) {
                dataObj[item['Key']] = item['Value'];
                sessionStorage.setItem(item['Key'], item['Value']);
            }

            // console.log('dataObj', dataObj);
            return {
                eDDATransId: dataObj.eDDATransId,
                eDDAStatus: dataObj.eDDAStatus,
                errorType: dataObj.errorType,
                errorCode: dataObj.errorCode,
                errorMsg: dataObj.errorMsg,
                errorMsgTC: dataObj.errorMsgTC,
                errorSubCode: '',
                dailyDepMaxAmt: dataObj.dailyDepMaxAmt,
                bettingAccount: {
                    bankAccount: [
                        {
                            bankAccountType: dataObj.bankAccType,
                            bankAccountNo: dataObj.bankAccNo,
                            bankCode: dataObj.bankCode,
                            eDDAStatus: dataObj.eDDAStatus,
                            errorType: dataObj.errorType,
                            errorCode: dataObj.errorCode,
                            errorSubCode: '',
                            dailyDepMaxAmt: dataObj.dailyDepMaxAmt,
                            notifyEDDAStatus: dataObj.notifyEDDAStatus,
                            FPSPStatus: dataObj.FPSPStatus,
                            EDDASingleLegStatus: dataObj.EDDASingleLegStatus,
                            notifyEDDASingleLegStatus: dataObj.notifyEDDASingleLegStatus,
                            notifyEDDASingleLegErrorType: dataObj.notifyEDDASingleLegErrorType,
                            notifyEDDASingleLegErrorCode: dataObj.notifyEDDASingleLegErrorCode,
                            notifyEDDASingleLegErrorSubCode: dataObj.notifyEDDASingleLegErrorSubCode
                        }
                    ],
                    currentMonthlyDepCnt: 0
                }
            };
        })
        .catch((err) => {
            console.error(err);
            return { errCode: 'TIMEOUT', code: 415 };
        });
};

export const postUpdateFpsInfo_IB = async (data) => {
    const { language, params, timeout } = data;
    let url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/FPS.svc/DoFPSInfoUpdateTR`;
    let payload = {
        lang: language,
        acc: sessionStorage.getItem('account'),
        sessionId: sessionStorage.getItem('session_id'),
        apimId: sessionStorage.getItem('gu_id'),
        seqNo: getSetSequenceNumber(),
        bankCode: params.bankCode,
        bankAccountNo: params.bankAccountNo,
        eDDAStatus: params.notifyEDDAStatus == 'AP' || params.notifyEDDAStatus == 'RJ' ? '' : 'NO',
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        bankAccountType: params.bankAccountType,
        isNew: true
    };

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((res) => {
            if (res.errCode) {
                return res;
            }
            const dataObj = {};
            // console.log('res', res);
            for (const item of res['DoFPSInfoUpdateTRResult']) {
                dataObj[item['Key']] = item['Value'];
                sessionStorage.setItem(item['Key'], item['Value']);
            }
            return;
        })
        .catch((err) => {
            console.error(err);
            return { errCode: 'TIMEOUT', code: 415 };
        });
};

export const postUpdateFpsSingleLegInfo_IB = async (data) => {
    const { language, params, timeout } = data;
    let url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/FPS.svc/DoFPSSingleLegInfoUpdateTR`;
    let payload = {
        lang: language,
        acc: sessionStorage.getItem('account'),
        sessionId: sessionStorage.getItem('session_id'),
        apimId: sessionStorage.getItem('gu_id'),
        seqNo: getSetSequenceNumber(),
        bankCode: params.bankCode,
        bankAccountNo: params.bankAccountNo,
        action: params.notifyEDDASingleLegStatus,
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        bankAccountType: params.bankAccountType,
        isNew: true
    };

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((res) => {
            if (res.errCode) {
                return res;
            }
            const dataObj = {};
            // console.log('res', res);
            for (const item of res['DoFPSSingleLegInfoUpdateTRResult']) {
                dataObj[item['Key']] = item['Value'];
                sessionStorage.setItem(item['Key'], item['Value']);
            }
            return;
        })
        .catch((err) => {
            console.error(err);
            return { errCode: 'TIMEOUT', code: 415 };
        });
};

export const getPPSEnable_IB = async () => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/PPS.svc/GetPPSEnabled`;
    const payload = {     
        isNew: true
    };
    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            return !!data?.GetPPSEnabledResult;
        })
        .catch((err) => {
            console.error(err);
            return false;
        });
};



export const postSendPPSDORequest_IB = async (data) => {
    const { language, params, timeout } = data;
    let url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/PPS.svc/requestPPSDOTR`;
    let payload = {
        lang: language == 'en'? 0:1,
        account: sessionStorage.getItem('account'),
        sessionID: sessionStorage.getItem('session_id'),
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        guid: sessionStorage.getItem('gu_id'),
        seqNo: getSetSequenceNumber(),
        amount: params.transAmt,
        password: "",
        isNew: true
    };

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((res) => {
            if (res.errCode) {
                return res;
            }
            const dataObj = {};
            // console.log('res', res);
            for (const item of res['requestPPSDOTRResult']) {
                dataObj[item['Key']] = item['Value'];
            }
            return dataObj;
        })
        .catch((err) => {
            console.error(err);
            return { errCode: 'TIMEOUT', code: 415 };
        });
};

export const postEnquirePPSStatusTR_IB = async (refNo) => {
    let url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/PPS.svc/enquirePPSStatusTR`;
    let payload = {
        refNos: refNo
    };

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    const timeout = 120;
    return await FetchApi({ options, timeout, params: payload, url })
        .then((res) => {
            if (res.errCode) {
                return res;
            }
            const dataObj = {};
            for (const item of res['enquirePPSStatusTRResult']) {
                dataObj[item['Key']] = item['Value'];
            }
            return dataObj;
        })
        .catch((err) => {
            console.error(err);
            return { errCode: 'TIMEOUT', code: 415 };
        });
};