import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getSessionId, getSiteInfo } from '../BetSlipAPI';
import { TandCText } from '../LoginMenu';
import { timeoutFetch } from '../../Common/timeoutRequest';
import { useBetSlipMsgQuery } from '../../Home/Data/SitecoreDataHooks';
import { getSessionId_IB } from '../BetSlipIBAPI';

const { v4: uuidv4 } = require('uuid');
// ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'

const FetchApi = ({ timeout, options, params, url }) => {
    const onError = (err) => {
        console.error(err);
    };
    const onSuccess = (response) => {
        return response;
    };
    const timeoutErrData = {
        errCode: 'TIMEOUT'
    };
    return timeoutFetch({ timeout: timeout * 1000, onError, onSuccess, options, timeoutErrData })(url, params);
};

export const getAccountInformation = async () => {
    const accessToken = sessionStorage.getItem('access_token');
    const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken, window.globalConfig.IS_DEBUG);
    //const url = `${window.globalConfig.EFT_URL}/wagering/account/v4/info?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${sessionData?.sessionId}&timestampId=${sessionData?.timestampId}`;
    const url = `${window.globalConfig.EFT_URL}/sp22/sessionmanagement/v101/account/info?deviceType=17&sessionId=${
        sessionData?.sessionId
    }&timestampId=${sessionData?.timestampId}&siteInfo=${getSiteInfo()}`;
    const body = JSON.stringify(['profile', 'nba']);

    if (!sessionData?.sessionId) return;
    return await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            mockAC: sessionStorage.getItem('login_account') // for mock data only
        },
        body
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

export const getFPSInfomation = async (nbaData = {}, ip = '10.122.133.155') => {
    const guid = uuidv4();
    const accessToken = sessionStorage.getItem('access_token');
    const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken, window.globalConfig.IS_DEBUG);
    if (!sessionData?.sessionId) return;

    const nba1 = nbaData?.nba1BankCode
        ? `&bankCode1=${nbaData.nba1BankCode}&bankAccountNo1=${nbaData.nba1BankAccountNumber}`
        : '';
    const nba2 = nbaData?.nba2BankCode
        ? `&bankCode2=${nbaData.nba2BankCode}&bankAccountNo2=${nbaData.nba2BankAccountNumber}`
        : '';
    //const url = `${window.globalConfig.EFT_URL}/moneyservice/fps/v2/bettingaccounts?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${sessionData.sessionId}&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}&fpsChannelID=104${nba1}${nba2}`;
    const url = `${
        window.globalConfig.EFT_URL
    }/sp22/sessionmanagement/v100/fps/bettingaccounts?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${
        sessionData.sessionId
    }&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}&fpsChannelID=104${nba1}${nba2}`;

    return await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

export const getFPSBankList = async (ip = '10.122.133.155') => {
    const accessToken = sessionStorage.getItem('access_token');
    const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken, window.globalConfig.IS_DEBUG);
    if (!sessionData?.sessionId) return;

    const uuid = uuidv4();
    //const url = `${window.globalConfig.EFT_URL}/moneyservice/fps/v2/reference/banks?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${sessionData.sessionId}&timestampId=${sessionData.timestampId}&traceId=${uuid}&clientIp=${ip}`;
    const url = `${
        window.globalConfig.EFT_URL
    }/sp22/sessionmanagement/v100/fps/reference/banks?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${
        sessionData.sessionId
    }&timestampId=${sessionData.timestampId}&traceId=${uuid}&clientIp=${ip}`;

    return await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

export const getFPSErrorList = async (ip = '10.122.133.155') => {
    const uuid = uuidv4();
    const accessToken = sessionStorage.getItem('access_token') || '123';
    const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken, window.globalConfig.IS_DEBUG);
    if (!sessionData?.sessionId) return;

    //const url = `${window.globalConfig.EFT_URL}/moneyservice/fps/v2/reference/errors?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${sessionData.sessionId}&timestampId=${sessionData.timestampId}&traceId=${uuid}&clientIp=${ip}`;
    const url = `${
        window.globalConfig.EFT_URL
    }/sp22/sessionmanagement/v100/fps/reference/errors?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${
        sessionData.sessionId
    }&timestampId=${sessionData.timestampId}&traceId=${uuid}&clientIp=${ip}`;

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.json())
        .catch((err) => {
            console.error(err);
        });
};

export const postDepositFps = async (data) => {
    const { ip = '10.122.133.155', params, timeout } = data;
    const guid = uuidv4();
    const accessToken = sessionStorage.getItem('access_token');
    const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken, window.globalConfig.IS_DEBUG);
    if (!sessionData?.sessionId) return;
    //const url = `${window.globalConfig.EFT_URL}/moneyservice/fps/v2/deposit?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${sessionData.sessionId}&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}`;
    const url = `${
        window.globalConfig.EFT_URL
    }/sp22/sessionmanagement/v100/fps/deposit?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${
        sessionData.sessionId
    }&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}`;

    const options = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    return FetchApi({ options, timeout, params, url });
};

export const postDepositEps = async (data) => {
    const { ip = '10.122.133.155', params, timeout } = data;
    const accessToken = sessionStorage.getItem('access_token');
    const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken, window.globalConfig.IS_DEBUG);

    if (!sessionData?.sessionId) return;
    const url = `${window.globalConfig.EFT_URL}/sp22/eftservice/api/v1/deposit`;
    const body = {
        ...params,
        sessionId: sessionData?.sessionId,
        timestampId: sessionData.timestampId,
        //deviceType: 26,
        deviceType: 11,
        type: 0,
        siteInfo: getSiteInfo()
    };
    const options = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };
    return FetchApi({ options, timeout, params: body, url });
};

export const postWithdrawalFPS = async (data) => {
    const { ip = '10.122.133.155', params, timeout } = data;
    const guid = uuidv4();
    const accessToken = sessionStorage.getItem('access_token');
    const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken, window.globalConfig.IS_DEBUG);
    if (!sessionData?.sessionId) return;
    //const url = `${window.globalConfig.EFT_URL}/moneyservice/fps/v2/withdrawal?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${sessionData.sessionId}&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}`;
    const url = `${
        window.globalConfig.EFT_URL
    }/sp22/sessionmanagement/v100/fps/withdrawal?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${
        sessionData.sessionId
    }&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}`;

    // const body = JSON.stringify(params)
    const options = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    return FetchApi({ options, timeout, params, url });
};

export const postWithdrawalEPS = async (data) => {
    const { ip = '10.122.133.155', params, timeout } = data;
    const accessToken = sessionStorage.getItem('access_token');
    const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken, window.globalConfig.IS_DEBUG);

    if (!sessionData?.sessionId) return;
    const url = `${window.globalConfig.EFT_URL}/sp22/eftservice/api/v1/withdrawal`;
    const body = {
        ...params,
        sessionId: sessionData?.sessionId,
        timestampId: sessionData.timestampId,
        //deviceType: 26,
        deviceType: 11,
        siteInfo: getSiteInfo()
    };
    const options = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };
    return FetchApi({ options, timeout, params: body, url });
};

export const TandCLink = ({ textData }) => {
    return (
        <div className="link-TandC">
            <div className="loginTandCText">
                <TandCText textData={textData} />
            </div>
        </div>
    );
};

export const postOtpCodeGenerate = async () => {
    const accessToken = sessionStorage.getItem('access_token');
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/authtree/authenticate?authIndexType=service&authIndexValue=hkjcOtpExtraHigh&ForceAuth=true`;

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Access-Token': `${accessToken}`,
            'Accept-API-Version': 'resource=2.0, protocol=1.0',
            'BILLING-CODE': 'CMT',
            'PURPOSE-CODE': 'CS'
        }
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
};

export const postOtpCodeConfirm = async (params) => {
    const accessToken = sessionStorage.getItem('access_token');

    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/authtree/authenticate?authIndexType=service&authIndexValue=hkjcOtpExtraHigh`;
    const body = JSON.stringify(params);

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Access-Token': `${accessToken}`,
            'Accept-API-Version': 'resource=2.0, protocol=1.0',
            'BILLING-CODE': 'CMT',
            'PURPOSE-CODE': 'CS',
            'Content-Type': 'application/json'
        },
        body
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
};

export const postEddaSetup = async (data) => {
    const { ip = '10.122.133.155', params, timeout, isSingleLeg } = data;
    const guid = uuidv4();
    const accessToken = sessionStorage.getItem('access_token');
    const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken, window.globalConfig.IS_DEBUG);

    if (!sessionData?.sessionId) return;
    //let url = `${window.globalConfig.EFT_URL}/moneyservice/fps/v2/edda?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${sessionData.sessionId}&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}`;
    let url = `${window.globalConfig.EFT_URL}/sp22/sessionmanagement/v100/fps/edda?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${sessionData.sessionId}&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}`;
    if (isSingleLeg) {
        url = `${
            window.globalConfig.EFT_URL
        }/moneyservice/fps/v2/singleLegEdda?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${
            sessionData.sessionId
        }&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}`;
    }

    const options = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };
    return FetchApi({ options, timeout, params, url });
};

export const postUpdateFpsInfo = async (params) => {
    const ip = '10.122.133.155';
    const guid = uuidv4();
    const accessToken = sessionStorage.getItem('access_token');
    const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken, window.globalConfig.IS_DEBUG);
    if (!sessionData?.sessionId) return;
    let newParams = {
        bankAccountType: params.bankAccountType,
        bankAccountNo: params.bankAccountNo,
        bankCode: params.bankCode,
        notifyEDDAStatus: params.notifyEDDAStatus,
        fpsChannelId: 26,
        eDDAStatus: 'NO'
    };
    let url = `${
        window.globalConfig.EFT_URL
    }/moneyservice/fps/v2/bettingaccounts?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${
        sessionData.sessionId
    }&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}`;
    if (params.action) {
        url = `${
            window.globalConfig.EFT_URL
        }/moneyservice/fps/v2/bettingaccounts/notifyEddaSingleLegStatus?deviceType=26&siteInfo=${getSiteInfo()}&sessionId=${
            sessionData.sessionId
        }&timestampId=${sessionData.timestampId}&traceId=${guid}&clientIp=${ip}`;
        newParams = {
            bankAccountType: params.bankAccountType,
            bankAccountNo: params.bankAccountNo,
            bankCode: params.bankCode,
            action: 'SL',
            fpsChannelId: 26
        };
    }
    const body = JSON.stringify(newParams);

    return await fetch(url, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        body
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
};
