import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MarksixContext } from '../../Home/MarksixPage';
import { useBetSlip, AddM6SlipFunc } from '../../BetSlip/BetSlipHooks'
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { isStartSell } from './Common';
import { isPmuEoD, eodMessage } from '../../Common/GlobalFunc';
import { useWindowSize } from '../../Common/CommonHooks';

const ResultsLuckyNumber = ({ isNotDefined }) => {
    const { t } = useTranslation()
    const [luckyNumber, setLuckyNumber] = useState('')
    const context = useContext(MarksixContext);
    const { addToBetSlip, betLines } = useBetSlip()
    const { isMobileSize } = useWindowSize()

    const handleClickAddToSlip = () => {
        const isSell = isStartSell(context.nextDraw);
        if (!isSell) {
            if (isPmuEoD() && eodMessage()?.length > 0) {
                window.alert(eodMessage())
            } else {
                window.alert(t('LB_M6_MSG_NOT_DIFINED'))
            }
            return;
        }
        const realNumber = Number(luckyNumber);
        if (realNumber < 1 || realNumber > 49) {
            window.alert(t('LB_M6_RESULTS_LUCKY_NUMBER_ERRMSG'))
            return
        }
        const ballList = [realNumber];
        while (ballList.length < 6) {
            const rand = Math.ceil(Math.random() * 49)
            if (!ballList.includes(rand)) ballList.push(rand)
        }
        const sortBall = ballList.sort((a, b) => a - b)
        setLuckyNumber('')
        const selectedData = [{ numList: sortBall, isFav: false, chance: 1, multidraw: 1, random: true }]
        const betList = AddM6SlipFunc(selectedData, context)
        addToBetSlip(betList)
        glassboxCustomEvent("Add_to_Slip");
    }

    const onInputLuckyNumber = (e) => {
        let value = e.target.value
        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
            if (e.nativeEvent.data != null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute('data-init', 0);
        }
        if (Number(value).toString() !== 'NaN') {
            setLuckyNumber(value)
        }
        if(Number(value) > 10) {
            e.target.setAttribute('maxLength', 2);
        }
    }

    const onFocus = (e) => {
        if (isMobileSize) {
            e.preventDefault();
            e.target.setAttribute('data-init', 1);
            e.target.setSelectionRange(10, 10);
            setTimeout(function () {
                e.target.setSelectionRange(10, 10);
            });
            e.target.removeAttribute('maxLength');
        }
    };

    return <div className='result-lucky-number'>
        <div>{t('LB_M6_RESULTS_LUCKY_NUMBER_TIPS')}</div>
        <div>
            <input type='text' maxLength={2} value={luckyNumber} onFocus={onFocus} onInput={onInputLuckyNumber}></input>
            <div className='AddToSlip' title={t('LB_ADDTOSLIP')} onClick={handleClickAddToSlip}>{t('LB_ADDTOSLIP')}</div>
        </div>
    </div>
}

export default ResultsLuckyNumber