import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

const LinkBankList = ({ props, setCaseStatus }) => {
    const {
        setCurStep,
        bankList,
        getExpCaseStatus
    } = props;
    const { t, i18n } = useTranslation();

    const onClickBankListDone = () => {
        if (setCurStep) {
            setCurStep(0);
        } else if(setCaseStatus) {
            setCaseStatus(getExpCaseStatus);
        }
    };

    return (
        <div>
            <div className="link-summary">{t('LB_BS_FT_LINK_BANK_INFO')}</div>
            <div className="link-summary">{t('LB_BS_FT_LINK_BANK_LIST')}</div>
            <div className="FT-form-content link-bank">
                <div className="link-row noline">
                    <div className="link-bank-row-l">{t('LB_BS_FT_LINK_BANK_CODE')}</div>
                    <div className="link-bank-row-r">{t('LB_BS_FT_LINK_BANK_NAME')}</div>
                </div>

                <div className="link-bank-list">
                    {bankList.banks.map((item, idx) => {
                        return (
                            item.eDDADepositEnable == '1' && (
                                <div key={item.code} className="link-bank-row">
                                    <div className="link-bank-row-l">{item.code}</div>
                                    <div className="link-bank-row-r">
                                        {i18n.language == 'en' ? item.name : item.nameTC}
                                    </div>
                                </div>
                            )
                        );
                    })}
                </div>
            </div>
            <div className="deposit-btnArea">
                <div className="deposit-btn doneBtn link-btn-active" onClick={() => onClickBankListDone()}>
                    {t('LB_BETSLIP_DONE')}
                </div>
            </div>
        </div>
    );
};

export default LinkBankList;
