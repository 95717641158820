import React, { useCallback, useContext } from 'react';
import { FootballContext } from '../../Home/FootballPage';
import GeneralTournTable from '../Common/GeneralTournTable';
import { Remark } from '../Common/MatchBase';
import { sortSeq } from '../Common/Common';
import RefreshTime from '../../Common/ui-components/RefreshTime'

const CHP = () => {
    const context = useContext(FootballContext);
    
    const ChpTable = useCallback(({context}) => {
        
        const tournaments = context.content.tournData?.tournaments || [];
        const selection = context.content.selection;
        tournaments.sort(sortSeq);
        return tournaments.map((item) => {
            let show = selection.value.includes(item.id);
            return show && <GeneralTournTable betType="CHP" isMatch={false} tourn={item} isSingleBetPage={true} />
        })
    }, []);
    

    return (
        <>
            {context.content.pageConfig.showRefreshTime && <div className='refreshContainer'><RefreshTime product={'football'}/></div>}
            <ChpTable context={context}/>
            <div className='mobileMarginLR'>
                <Remark />
            </div>
        </>
    )
}
export default CHP;

