import React, { useContext, useState } from 'react';
import { onClickCheckBox } from './AddBet';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { getOddsArrowCss } from './MatchBase';
import { formatOdds, isEliminated, isRefund, isSellingStarted, getFootballHelpUrl } from './Common';
import CheckBox from '../../Common/ui-components/CheckBox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const SGATable = ({ match, isResultPage, expand, handleSetLineState }) => {
    const { t, i18n } = useTranslation();
    let foPools = match.foPools.filter((x) => x.oddsType === 'SGA');
    foPools?.sort((x, y) => {
        return x.instNo - y.instNo;
    });
    foPools = foPools?.filter((x) => x.lines?.[0]?.combinations?.[0] != null);

    return (
        <section className="SGA">
            <div className="sgaTable">
                {foPools?.map((foPool, idx) => {
                    return (
                        (isResultPage || expand || idx < 4) && (
                            <SGAItemTable key={idx} match={match} isResultPage={isResultPage} foPool={foPool} />
                        )
                    );
                })}
            </div>
            {!isResultPage && foPools.length > 4 && (
                <div
                    className="sgaMoreButton"
                    onClick={() => {
                        handleSetLineState(match.matchDate, 'SGA');
                    }}
                >
                    <div>{expand ? t('LB_FB_SHOW_LESS') : t('LB_FB_MORE')}</div>
                    <ArrowIcon color="green" className="" direction={expand ? 'up' : 'down'} size={24} />
                </div>
            )}
        </section>
    );
};

const SGAItemTable = ({ match, isResultPage, foPool }) => {
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const [checkboxState, setCheckboxState] = useState([]);

    let isSelling = isSellingStarted(match, foPool, foPool.lines[0]);
    let comb = foPool.lines[0].combinations[0];
    let sel = comb.selections[0];
    let id = `SGA_${foPool.lines[0].lineId}_${comb.combId}_${foPool.id}_${foPool.instNo}`;
    let curState = context.content.oddsSelection.filter((x) => x.id == id)[0];
    let isDisabled = isResultPage || isEliminated(comb) || isRefund(comb) || !isSelling || comb.status != 'AVAILABLE';
    if (curState == null) {
        curState = {
            id: id,
            checked: false,
            matchId: match.id,
            oddsVal: comb.currentOdds
        };
    }
    return (
        <div className={`sgaCellGrid`}>
            <div className="sgaAns">{sel['name_' + i18n.language]}</div>
            <div className='oddsCheckboxGrid'>
                <div className={`sgaSelChkbox`}>
                    {!isResultPage && (
                        <CheckBox
                            product="football"
                            id={`fb_${id}`}
                            classNameBox={`${id} ${isDisabled && 'dim'}`}
                            onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)}
                            defaultChecked={curState.checked}
                            disabled={isDisabled}
                        />
                    )}
                </div>
                <div className={`sgaSelOdds oddsColor ${getOddsArrowCss(curState)}`}>
                    {formatOdds(t, comb.currentOdds, isResultPage ? '' : comb.status)}
                    <div className={`oddsArrow ${getOddsArrowCss(curState)}`}></div>
                </div>
            </div>
        </div>
    );
};

export default SGATable;
