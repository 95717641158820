import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import './index.scss';
import { handleClickMenuItem, IconContent } from '../../../Menu/MenuCommon'; 
import { useProductInfoBlockQuery } from '../../../Home/Data/SitecoreDataHooks';

const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 15000,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const News = () => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [ newsLoading, newsData, getRandomNews ] = useProductInfoBlockQuery('ewin');
    const navigate = useNavigate();

    let newsArr = getRandomNews();

    return !newsLoading && newsArr.length > 0 ?
        <div className="news-slider">
            <div className="news ">{t('LB_NEWS')}</div>
            <div className="slider">
                <div className="slider-content">
                    <Slider {...settings}>
                        {newsArr.map((news, index) => {
                            let predefinedColor = news.item.predefinedColor?.targetItem?.optionValue?.value || "";
                            let hlStyle = predefinedColor!="" ? { color: predefinedColor } : {};
                            return <div className="slider-item" key={index}>
                                <div className="title">
                                    <span className={`type ${news.key}`}>{news.prod}</span>
                                    <span className="date">{news.dt}</span>
                                </div>
                                <div className={`content`} style={hlStyle} onClick={(e) => handleClickMenuItem(e, news.item, navigate)}>
                                    {news.item.message.value}
                                    <span style={{ paddingLeft: "4px" }}>
                                        <IconContent iconContent={news.item.iconContent} />
                                    </span>
                                </div>
                            </div>
                        })}
                    </Slider>
                </div>
            </div>
        </div> : null;
};

export default News;
