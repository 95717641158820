import React, { useEffect, useContext } from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import MIXALLUP_DETAIL from './MIXALLUP_DETAIL';

const MIXALLUP = () => {


    const context = useContext(FootballContext);
    const { t } = useTranslation();



    return !context.content.isAllUpDtls ?
        <section className='MIXALLUP'>
            <MatchTable betType='MIXALLUP' disableHeader={true} />
            <Remark />
        </section>
        :
        <section className='MIXALLUP_DETAIL'>
            <MIXALLUP_DETAIL />
        </section>
}
export default MIXALLUP;