import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NewWindowWithProps, openBannerNewWindow } from '../../home-common';

import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import './index.scss';
import { glassboxCustomEvent } from '../../utils/glassbox';
import { useWindowSize } from '../../CommonHooks';

const getGlassboxEventName = (item) => {
    if (item.campaignID.includes('BWHO') || item.url.includes('BWHO') || item.bannerName.includes('BWHO')) {
        return 'Banner_on_Home';
    }
    if (item.campaignID.includes('BWRH') || item.url.includes('BWRH') || item.bannerName.includes('BWRH')) {
        return 'Banner_on_Racing';
    }
    if (item.campaignID.includes('BWFH') || item.url.includes('BWFH') || item.bannerName.includes('BWFH')) {
        return 'Banner_on_Football';
    }
    if (item.campaignID.includes('BW6H') || item.url.includes('BW6H') || item.bannerName.includes('BW6H')) {
        return 'Banner_on_Marksix';
    }
};

const getGlassboxEventValue = (campaignID, lang) => {
    return campaignID + '_' + lang;
    /*
    if (campaignID.includes('BWHO')) {
        return 'BWHOTB' + parseInt(bannerName.replace('home', '')) + lang;
    }
    if (campaignID.includes('BWRH')) {
        return 'BWRHTB' + parseInt(bannerName.replace('racing', '')) + lang;
    }
    if (campaignID.includes('BWFH')) {
        return 'BWFHTB' + parseInt(bannerName.replace('football', '')) + lang;
    }
    if (campaignID.includes('BW6H')) {
        return 'BW6HTB' + parseInt(bannerName.replace('marksix', '')) + lang;
    }
    */
};

export const Swipe = (props) => {
    let { bannerArr, autoPlayTime } = props;
    const autoTime = (autoPlayTime || 15) * 1000;

    const { i18n } = useTranslation();
    let lang = i18n.language === 'ch' ? 'C' : 'E';

    const { isMobileSize } = useWindowSize();

    // console.log('bannerArr', bannerArr)

    const validate = (url) => {
        return url;
    };

    return (
        <div className="swiper-container">
            <Swiper
                key={isMobileSize}
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={isMobileSize ? 6 : 12}
                autoplay={{
                    delay: autoTime,
                    disableOnInteraction: false
                }}
                style={{
                    '--swiper-navigation-color': '#000',
                    '--swiper-navigation-size': '13px'
                }}
                pagination={{
                    el: '.bannerPagination',
                    clickable: true,
                    renderBullet: (index, className) => {
                        return `<span class="${className}"></span>`;
                    }
                }}
                navigation={isMobileSize ? false : true}
                loop={bannerArr.length > 0}
                modules={[Pagination, Navigation, Autoplay]}
                className="swiper-custom"
            >
                {bannerArr.map((item, index) => {
                    return (
                        <SwiperSlide>
                            <div
                                onClick={() => {
                                    openBannerNewWindow(item.url, item.target, item.targetWindowParameters);
                                    glassboxCustomEvent(
                                        getGlassboxEventName(item),
                                        getGlassboxEventValue(item.campaignID, lang)
                                    );
                                }}
                                className={`swiper-slide-content`}
                                key={index}
                            >
                                <img src={`${validate(window.globalConfig.COMMON_URL)}${item.imagePathLarge}`} alt="" />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="bannerPagination"></div>
        </div>
    );
};
