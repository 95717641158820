export const parseTopic = (topic, contentRef) => {
    let touNew = false;
    let matNew = false;
    let pooNew = false;
    let touUpd = false;
    let poolUpd = false;
    let chpPooUpd = false;
    let chpPooNew = false;
    let matchUpd = false;
    let matchAdminUpd = false;
    let matchWagUpd = false;
    let tInfo = topic.split('/');
    let tournamentid ;
    let matchid ;
    let bettype ;
    let poolid ;
    if ( tInfo.length < 9 ) {
        return {}
    }
    if(tInfo.length == 9){
        touNew = true;
    }
    if(tInfo.length == 10){
        if(tInfo[8] == "tourn"){
            tournamentid =  tInfo[9];
            touUpd = true ;
        }
        else if(tInfo[8]  =="match"){
            tournamentid =  tInfo[9];
            matNew = true ;
        }
        else{
            
        }
    }
    if(tInfo.length == 11){
        if(tInfo[8] == "genrl"){
            tournamentid =  tInfo[9];
            matchid = tInfo[10]; 
            matchUpd = true;
        }
        else if(tInfo[8] == "wgr_res"){
            tournamentid =  tInfo[9];
            matchid = tInfo[10]; 
            matchWagUpd = true;

        }
        else if(tInfo[8] == "adm_opt"){
            tournamentid =  tInfo[9];
            matchid = tInfo[10]; 
            matchAdminUpd = true;
        }
    }
    if(tInfo.length == 12){
        if(window.fbConfig?.AllTournamentOrder?.includes?.(tInfo[11].toUpperCase())){
            tournamentid =  tInfo[10];
            chpPooNew = true;
        }
    }
    if(tInfo.length == 13){
        if(window.fbConfig?.AllTournamentOrder?.includes?.(tInfo[11].toUpperCase())){
            tournamentid =  tInfo[10];
            poolid =  tInfo[12];
            chpPooUpd = true;
        }
        else{
            tournamentid =  tInfo[10];
            matchid = tInfo[11];
            pooNew = true;
        }
    }
    if(tInfo.length == 14){
        tournamentid =  tInfo[10];
        matchid = tInfo[11];
        bettype = tInfo[12];
        poolid =  tInfo[13];
        poolUpd = true;

    }
    return {
        tournamentid,
        matchid,
        bettype,
        poolid,
        touNew,
        matNew,
        pooNew,
        touUpd,
        matchUpd,
        matchAdminUpd,
        matchWagUpd,
        poolUpd,
        chpPooUpd,
        chpPooNew
    }

}