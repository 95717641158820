import { useTranslation } from 'react-i18next';
import { formatDateObj, dateAdd } from '../../Common/GlobalFunc';
import { isCRSOtherSels } from './CRSTable';
import moment from 'moment';

export function toDateFormat(str, showYear, showTime) {
    if(str && str.length <= 10) str += 'T00:00:00.000+08:00'
    let d = str ? moment(str) : moment();
    let format = `DD/MM${showYear ? "/YYYY" : ""}${showTime ? " HH:mm" : ""}`;
    return d.utcOffset('+08:00').format(format);
}

export function toTimeFormat(str) {
    return moment(str).utcOffset('+08:00').format("HH:mm");
}

export function formatOdds(t, odds, combSt) {
    switch (combSt) {
        case 'IRRATIONAL':
            return '---';
        case 'REFUND':
        case 'REFUNDBEFORESUSPEND':
            return t('LB_FB_RFD');
        case 'ELIMINATED':
        case 'LOSE':
            return t('LB_FB_LSE');
    }
    return odds;
}

export const isRefund = (comb) => {
    return  ["REFUND", "REFUNDBEFORESUSPEND"]?.includes(comb.status);
}

export const isEliminated = (comb) => {
    return  ["LOSE", "ELIMINATED"]?.includes(comb.status);
}

export function isInplayPage(page) {
    return window.fbConfig["InplayPages"]?.includes(page);
}

export function isTournamentPage(page) {
    return window.fbConfig["TOURNAMENT_PAGE"]?.includes(page);
}

export function isMatchResultPage(page) {
    return window.fbConfig["FB_MATCH_RESULT_PAGE"]?.includes(page);
}

export function isPmPoolResultPage(page) {
    return window.fbConfig["FB_PM_RESULT_PAGE"]?.includes(page);
}

export function isTournResultPage(page) {
    return window.fbConfig["FB_TOURN_RESULT_PAGE"]?.includes(page);
}

export function isPoolPayoutOrRefund(pool) {
    return ["PAYOUTSTARTED", "REFUND", "REFUNDBEFORESUSPEND"]?.includes(pool.status);
}

export function isSellingStarted(match, foPool, line) {
    let lineSell = foPool==null || (line==null ? foPool?.lines?.filter(x=> x.status=='AVAILABLE').length > 0 : line.status=='AVAILABLE');
    let poolSell = foPool==null ? match?.poolInfo.sellingPools.length > 0 : foPool?.status=='SELLINGSTARTED';
    return match.isInteractiveServiceAvailable && lineSell && poolSell;
}

export function isPoolDefined(content) {
    if ( "CASHOUT"===content.page ) {
        return true;
    }
    else if (isTournamentPage(content.page) || isTournResultPage(content.page)) {
        if (content.tournData == null || content.tournData.tournaments == null) {
            return false;
        }
        return content.tournData.tournaments.length > 0;
    }
    else if (isPmPoolResultPage(content.page)) {
        return content.matchData != null && content.matchData.matches != null;
    }
    else if (isMatchResultPage(content.page) || "FGSRES"===content.page) {
        if (content.matchData == null || content.matchData.matches == null) {
            return false;
        }
        return content.matchData.matches.length > 0;
    }
    else if (content.config?.MixAllUpPages.includes(content.page)) {
        if (content.listData == null || content.listData.matches == null) {
            return false;
        }
        return content.listData.matches.length > 0;
    }
    else {
        if (content.listData == null || content.listData.matches == null) {
            return false;
        } else if (content.matchData == null || content.matchData.matches == null) {
            return false;
        }
        return content.listData.matches.length > 0
    }
}

export function isFeaturedMatch(match, offset) {
    if ( match.featureStartTime!=null ) {
      let serverTime = window.ssTime || new Date().getTime()
      let today = new Date(serverTime);
      today.setTime(today.getTime() + (offset*1000));
      let dt = new Date(match.featureStartTime);
      return today >= dt;
    }
    return false;
}

export function hasAnyFeaturedMatch(matches, offset) {
    if ( matches==null )
        return false;
    for ( let idx in matches ) {
        if ( isFeaturedMatch(matches[idx], offset) )
            return true;
    }
    return false;
}

export function getExtraTimeBetType(betType) {
    switch (betType) {
        case 'HAD': return 'EHA';
        case 'HDC': return 'EDC';
        case 'HIL': return 'EHL';
        case 'CHL': return 'ECH';
        case 'CRS': return 'ECS';
        case 'TTG': return 'ETG';
        case 'NTS': return 'NTS';
    }
    return null;
}

export function hasConfirmedResult(match) {
    let hasRes = false;
    let jsonObj = match?.results?.filter(item => item.resultConfirmType>1 && item.payoutConfirmed && item.homeResult>-1 && item.awayResult>-1);
    let filterScore = jsonObj?.filter(item => item.resultType==1 && [3, 5, 9, 100].includes(item.stageId));

    // let hasCornerRefund = false
    // if(match?.foPools?.length > 0 ) {
    //     hasCornerRefund = match.foPools.some(pool => {
    //         return ['Refund', 'RefundedBeforeSuspend'].includes(pool.status)
    //     })
    // }
    if ( filterScore?.length > 0 ) {
      hasRes = true;
    }
    // return hasRes || hasCornerRefund;
    return hasRes;
}

export function trimDate(matches, offset, config) {
    let currentDate = new Date();
    offset = offset || 0
    currentDate.setTime(currentDate.getTime() + offset * 1000);
    let firstDate = new Date(matches[0].matchDate.substring(0, 10));
    let dtLast = currentDate > firstDate ? new Date(firstDate) : currentDate;
    dtLast.setDate(dtLast.getDate() + config.OtherDateLimit - 1);
    let trimD = [...new Set(matches.map(match => {
        return match.matchDate.substring(0, 10);
    }))];
    let setFirstOtherDate = true;
    let firstOtherDate = '';
    return trimD.map(dt => {
        let date = new Date(dt);
        let isOther = false;
        if ( date >= dtLast ) {
            isOther = true;
            if ( setFirstOtherDate ) {
                firstOtherDate = dt;
                setFirstOtherDate = false;
            }
        }
        return { date: dt, isOther: isOther, firstOtherDate: firstOtherDate }
    });
}

export function trimTournament(matches) {
    let tourns = [];
    matches.forEach(match => {
        let tourn = tourns.filter(t=> t.nameProfileId==match.tournament.nameProfileId)[0];
        if ( tourn==null) {
            if(match.tournament.id){
                tourns.push({ nameProfileId: match.tournament.nameProfileId,
                    name_en: match.tournament.name_en,
                    name_ch: match.tournament.name_ch,
                    code: match.tournament.code,
                    sequence: match.tournament.sequence,
                    tournIds: [ match.tournament.id ] });
            }
        }
        else {
            tourn.tournIds.push(match.tournament.id);
            tourn.tournIds = [...new Set(tourn.tournIds)];
        }
    });
    tourns.sort(sortSeq);
    return tourns;
}

export function trimTournamentByTourn(tournaments) {
    let tourns = [];
    tournaments.forEach(tournament => {
        let tourn = tourns.filter(t=> t.nameProfileId==tournament.nameProfileId)[0];
        if ( tourn==null ) {
            tourns.push({ nameProfileId: tournament.nameProfileId,
                name_en: tournament.name_en,
                name_ch: tournament.name_ch,
                code: tournament.code,
                sequence: tournament.sequence,
                tournIds: [ tournament.id ] });
        }
        else {
            tourn.tournIds.push(tournament.id);
            tourn.tournIds = [...new Set(tourn.tournIds)];
        }
    });
    tourns.sort(sortSeq);
    return tourns;
}

export function getQueryDateRange(matches, offset, content) {
    content.selection.tabs = trimDate(matches, offset, content.config);
    let dateFrom;
    let dateTo;
    let tabDate = content.selection.tabs.find(tab=>tab.date===content.selection.value[0]);
    if(tabDate && tabDate.isOther && tabDate.date !== tabDate.firstOtherDate){
        tabDate =  content.selection.tabs.find(tab=>tab.date === tabDate.firstOtherDate)
    }

    if ( tabDate!=null ) {
        // dateFrom = content.selection.value[0];
        dateFrom = tabDate.date;
        if ( tabDate.isOther ) {
            dateTo = content.selection.tabs[content.selection.tabs.length-1].date;
        }
        else {
            if ( content.config.SingleMatchPages.includes(content.page) )
                dateTo = dateFrom;
            else
                dateTo = dateAdd(dateFrom, 1);
        }
    }
    else {
        dateFrom = matches[0].matchDate.substring(0, 10);
        if ( content.config.SingleMatchPages.includes(content.page) )
            dateTo = dateFrom;
        else
            dateTo = dateAdd(dateFrom, 1);
    }
    content.selection.value = [ dateFrom ];

    return [dateFrom, dateTo];
}

export function getQueryTorunIds(matches, content, npId, selectAll) {
    content.selection.tabs = trimTournament(matches);
    let newTournIds = [];
    if ( npId ) {
        newTournIds = [...new Set(matches.filter(x=> x.tournament.nameProfileId===npId).map(x=> x.tournament.id))];
    }else if(selectAll || content.selection.tournAll){
        newTournIds = content.selection.tabs.map(item => item.tournIds)?.flat()
    }
    else {
        let tournIds = content.selection.tabs.filter(item => content.selection.value.includes(item.nameProfileId) || item.tournIds.find(tournId =>  content.selection.value.includes(tournId))) || []
        tournIds = tournIds.reduce((arr, next) =>{
            return [...arr, ...next.tournIds]
        },[])
        tournIds.forEach(item=> {
            if (matches.filter(x=> x.tournament.id===item).length>0)
            newTournIds.push(item);
        })
    }
    let outTournIds = [];
    if ( newTournIds.length > 0 ) {
        outTournIds = newTournIds;
    }
    else {
        outTournIds = content.selection.tabs?.[0]?.tournIds;
    }
    content.selection.value = [...outTournIds];

    return outTournIds;
}

export function getQueryMatchIdForDateTab(matches, offset, content, matchId) {
    content.selection.tabs = trimDate(matches, offset, content.config);
    let outMatchId = null;
    let selectedDate = content.selection.value[0];
    let filteredMatch = matches.find(x=> x.id===matchId);
    if ( filteredMatch!=null ) {
        outMatchId = matchId;
        let dtTab = content.selection.tabs.filter(d => d.date===filteredMatch.matchDate.substring(0,10))[0];
        if ( selectedDate==null || selectedDate!=filteredMatch.matchDate.substring(0,10) || dtTab.isOther) {
            if ( dtTab.isOther ) {
                content.selection.value = [ dtTab.firstOtherDate ];
            }
            else {
                content.selection.value = [ dtTab.date ];
            }
        }
    }
    else {
        if ( selectedDate==null ) {
            outMatchId = matches[0].id;
            content.selection.value = [ matches[0].matchDate.substring(0,10) ];
        }
        else {
            let selectedMatches = matches.filter(x=>x.matchDate.substring(0,10)===selectedDate);
            if(selectedMatches.length == 0){
                outMatchId = matches[0].id;
                content.selection.value = [ matches[0].matchDate.substring(0,10) ];
            }else{
                outMatchId = selectedMatches[0].id;
                content.selection.value = [ selectedMatches[0].matchDate.substring(0,10) ];
            }
        }
    }
    return [ outMatchId ];
}

export function getQueryMatchIdForTournTab(matches, content, matchId, npId, selectAll) {
    // content.selection.tabs = trimTournament(matches);
    getQueryTorunIds(matches,content,npId, selectAll )
    let outMatchId = null;
    let selectedTourns = content.selection.value;
    let filteredMatch = matches.filter(x=> x.id===matchId)[0];
    if ( filteredMatch!=null ) {
        outMatchId = matchId;
    }
    else {
        let selectedMatches = matches.filter(x=> selectedTourns.includes(x.tournament.id));
        if ( selectedMatches.length>0 ) {
            outMatchId = selectedMatches[0].id;
        }
        else {
            let selectedMatches = matches.filter(x=> content.selection.tabs[0].tournIds.includes(x.tournament.id));
            outMatchId = selectedMatches[0].id;
            content.selection.value = [ ...content.selection.tabs[0].tournIds ];
        }
    }
    return [ outMatchId ];
}

export function setTournamentPageTabs(tournaments, content, selectedFrist) {
    if(content.page == 'TOURNAMENT') return
    let isTournFilter = content.selection.type === 'TOURN'
    let isAll = content.selection.type === 'ALL'
    if ( tournaments!=null && tournaments.length > 0 ) {
        content.selection.tabs = trimTournamentByTourn(tournaments);
        let newTorunIds = [];
        content.selection.value.forEach(item=> {
            if (tournaments.filter(x=> x.id===item).length>0)
                newTorunIds.push(item);
        })
        let outTournIds = [];
        if(selectedFrist || (isTournFilter && newTorunIds.length == 0) ){

            outTournIds = content.selection.tabs[0].tournIds;
        }else if ( newTorunIds.length > 0 &&  !isAll) {
            outTournIds = newTorunIds;
        }
        else {
            outTournIds = content.selection.tabs?.reduce((arr,tourn)=>{
                return [...arr,...tourn.tournIds]
            },[]);
        }
        content.selection.value = [...outTournIds];
    }
}

export function getCellId(foPool, line, comb) {
    let id = "";
    switch (foPool.oddsType) {
        case "TSP":
        case "MSP":
            id = foPool.oddsType + '_' + line.lineId + "_" + comb.combId + "_" + foPool.id + "_" + comb.selections[0].str;
            break;
        case "SGA":
            id = foPool.oddsType + '_' + line.lineId + "_" + comb.combId + "_" + foPool.id + "_" + foPool.instNo;
            break;
        default:
            id = foPool.oddsType + '_' + line.lineId + "_" + comb.combId + "_" + foPool.id + "_" + comb.str;
            if(isCRSOtherSels(comb.selections?.[0]?.str) ){
                id = foPool.oddsType + '_' + line.lineId + "_" + comb.combId + "_" + foPool.id + "_" + comb.selections?.[0]?.str
            }
            break;
    }
    return id;
}

export const getFootballHelpUrl = (type, lang) => {
    let pLang = lang==="en" ? "en-US" : "zh-HK";
    return window.globalConfig.SPECIAL_URL + window.specialPages['FB_' + type]?.replace('{0}', pLang);
}

export const hasMatchInfoUpdate = (content) => {
    const { listData, matchData } = content;
    const { matches } = listData;
    const curMatch = matchData.matches[0];
    let isShowInfoUpdateMsg = false;
    const hasInMatches = matches.find(item => item.id === curMatch.id)
    const hasFoPool = curMatch?.foPools?.length > 0
    if (!hasFoPool || !hasInMatches) {
        isShowInfoUpdateMsg = true;
    }
    return isShowInfoUpdateMsg;
}

export const MatchUpdateMsg = () => {
    const { t } = useTranslation();
    return <div className='updateMatchMsg'>{t('LB_FB_INFO_UPDATE_SELECT_OTHER_MATCH')}</div>
}

export const sortSeq = (x, y) => {
    return x.sequence < y.sequence? -1 : 1
}

export const sortSeqIgnoreCase = (x, y) => {
    return x.sequence.toLowerCase() < y.sequence.toLowerCase() ? -1 : 1
}

export const isEarlySettlementEanbled = (globalContext) => {
    return globalContext.globalState.commonCtrl.earlySettlementEnabled || window.globalConfig.IS_PREVIEW;
}