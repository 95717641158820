import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isLocalMeeting } from '../../Common/home-common';
import { lblBr } from '../../Common/GlobalFunc';
import { RacingContext } from '../../Home/RacingPage';
import { getPwinTimes } from './RCOddsTableBase';

const RCOddsHeader = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;

    const isLocal = () => {
        return isLocalMeeting(context.content.venue);
    }

    const [pTime0, pTime1, winTime] = getPwinTimes(context);

    return (
        <div className="rc-odds-table-header">
            {props.columns.raceNo ? <div className="no">{t('LB_RC_ODDS_TABLE_RACENO')}</div> : null}
            {props.columns.tp ? <div className="tp">{t('LB_RC_ODDS_TABLE_TP')}</div> : null}
            {props.columns.no ? <div className="no">{t('LB_RC_ODDS_TABLE_NO')}</div> : null}
            {props.columns.colour ? (!props.columns.showStandby ? <div className="colour">{t('LB_RC_ODDS_TABLE_COLOUR')}</div> : <div></div>) : null}
            {props.columns.horseName ? <div className="horseName">{t('LB_RC_ODDS_TABLE_HRNAME')}</div> : null}
            {props.columns.rcNote && isLocal() ? <div className="RCNOTE">{lblBr(t('LB_RC_ODDS_TABLE_RCNOTE_BR'))}</div> : null}
            {props.columns.draw ?  (!props.columns.showStandby ? <div className="draw">{t('LB_RC_ODDS_TABLE_DRAW')}</div> : <div></div>) : null}
            {props.columns.wt ? <div className="wt">{t('LB_RC_ODDS_TABLE_WT')}</div> : null}
            {props.columns.jocky ?  (!props.columns.showStandby ? <div className="jocky">{t('LB_RC_ODDS_TABLE_JOCKEY')}</div> : <div></div>) : null}
            {props.columns.trainer ? <div className="trainer">{t('LB_RC_ODDS_TABLE_TRAINER')}</div> : null}
            {props.columns.bodyWT ? <div className="bodyWT">{t('LB_RC_ODDS_TABLE_BODYWT')}</div> : null}
            {props.columns.intRtg ? <div className="rtg">{lblBr(t('LB_RC_ODDS_TABLE_INT_RTG'))}</div> : null}
            {props.columns.rtg ? <div className="rtg">{isLocal() ? t('LB_RC_ODDS_TABLE_RTG') : lblBr(t('LB_RC_ODDS_TABLE_INT_RTG'))}</div> : null}
            {props.columns.gear ? <div className="gear">{t('LB_RC_ODDS_TABLE_GEAR')}</div> : null}
            {props.columns.last6 ? <div className="last6">{isLocal() ? t('LB_RC_ODDS_TABLE_L6R') : t('LB_RC_ODDS_TABLE_L5R')}</div> : null}
            {props.columns.pwin0 ? <td className="pwinCol" colSpan={3}>
                <div className="pwinheader">{t('LB_RACE_PWIN')}</div>
                <div className='pwinColContainer'>
                    <div>{pTime0}</div>
                    {props.columns.pwin1 ? <div >{pTime1}</div> : <div></div>}
                    {props.columns.pwin0 ? <div className="win">{winTime}</div>:<div></div>}
                </div>
                </td> :null }
            {props.columns.win && !props.columns.pwin0 ? <div className="pwinCol win">{t('LB_RC_ODDS_TABLE_WIN')}</div> : null}
            {props.columns.place ? <div className="place">{t('LB_RC_ODDS_TABLE_Place')}</div> : null}
            {props.columns.wp ? <div className="wp">{t('LB_RC_ODDS_TABLE_WINPlace')}</div> : null}
        </div>
    )
}
export default RCOddsHeader;

export const clipboardHeader = (columns, context, t) => {
    const isLocal = () => {
        return isLocalMeeting(context.content.venue);
    }

    const [pTime0, pTime1, winTime] = getPwinTimes(context);

    return `<tr>
            ${columns.raceNo ? `<td>${t('LB_RC_ODDS_TABLE_RACENO')}</td>` : ""}
            ${columns.tp ? `<td>${t('LB_RC_ODDS_TABLE_TP')}</td>` : ""}
            ${columns.no ? `<td>${t('LB_RC_ODDS_TABLE_NO')}</td>` : ""}
            ${columns.colour ? `<td>${!columns.showStandby ? t('LB_RC_ODDS_TABLE_COLOUR') : ""}</td>` : ""}
            ${columns.horseName ? `<td>${t('LB_RC_ODDS_TABLE_HRNAME')}</td>` : ""}
            ${columns.draw ?  `<td>${!columns.showStandby ? t('LB_RC_ODDS_TABLE_DRAW') : ""}</td>` : ""}
            ${columns.wt ? `<td>${t('LB_RC_ODDS_TABLE_WT')}</td>` : ""}
            ${columns.jocky ?  `<td>${!columns.showStandby ? t('LB_RC_ODDS_TABLE_JOCKEY') : ""}</td>` : ""}
            ${columns.trainer ? `<td>${t('LB_RC_ODDS_TABLE_TRAINER')}</td>` : ""}
            ${columns.bodyWT ? `<td>${t('LB_RC_ODDS_TABLE_BODYWT')}</td>` : ""}
            ${columns.intRtg ? `<td>${lblBr(t('LB_RC_ODDS_TABLE_INT_RTG'))}</td>` : ""}
            ${columns.rtg ? `<td>${t('LB_RC_ODDS_TABLE_RTG')}</td>` : ""}
            ${columns.gear ? `<td>${t('LB_RC_ODDS_TABLE_GEAR')}</td>` : ""}
            ${columns.last6 ? `<td>${isLocal() ? t('LB_RC_ODDS_TABLE_L6R') : t('LB_RC_ODDS_TABLE_L5R')}</td>` : ""}
            ${columns.pwin0 ? `<td>${pTime0}</td>` : ""}
            ${columns.pwin1 ? `<td>${pTime1}</td>` : ""}
            ${columns.win && columns.pwin0 ? `<td>${winTime}</td>` : ""}
            ${columns.win && !columns.pwin0 ? `<td>${t('LB_RC_ODDS_TABLE_WIN')}</td>` : ""}
            ${columns.place ? `<td>${t('LB_RC_ODDS_TABLE_Place')}</td>` : ""}
        </tr>`;
}