import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const FGS = () => {
    return <section className='FGS'>
        <MatchTable betType='FGS'/>
        <Remark />
    </section>
}
export default FGS;