import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSiteCoreImagePath } from '../Common/home-common';
import { handleClickMenuItem } from '../Menu/MenuCommon';
import { scrollTo } from '../Common/GlobalFunc';

const ResponsiblyImage = ({rgpData}) => {
    const { i18n } = useTranslation();

    const navigate = useNavigate()

    const goToLink = (url) => {
        scrollTo(0, 0);
        navigate(url);
    }

    return <div className="footer-banner-rgp-imag">
        <div className="footer-play-responsibly-imag" onClick={(event)=> { handleClickMenuItem(event, rgpData, goToLink) }}>
            <div>{rgpData.icon?.src!=="" && <img src={getSiteCoreImagePath(rgpData.icon.src, i18n.language)} width={rgpData.icon.width} height={rgpData.icon.height} title={rgpData.header.value} />}</div>
        </div>
    </div>
}
export default ResponsiblyImage;