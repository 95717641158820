import React, { useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate  } from 'react-router-dom';
import { EmptyTable } from '../../Common/EwinHomeCommon';
import { getDayOfWeek2, capitalize } from '../../Common/home-common';
import { formatDateTime, scrollTo } from '../../Common/GlobalFunc';
import MobileTabSet from '../../Common/MobileTabSet';
import { getBallImage } from './Common';
import { LandingContext } from '../../Home/LangingPage';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const EwinHomeMarksixBlock = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const context = useContext(LandingContext);
    const { data, loading } = context.marksixData;
    const ballSize = 's';

    const [tabs, setTabs] = useState([
        { type: "R", name1: 'LB_M6_RESULTSDETAIL', disabled: false, checked: true },
        { type: "N", name1: 'LB_M6_NEXTDRAW', disabled: false, checked: false },
        { type: "S", name1: 'LB_M6_STATISTICS', disabled: false, checked: false }
    ]);

    const currentType = () =>{
        return tabs.filter(d => d.checked)[0].type;
    }

    const changeTab = (value) => {
        // let value = event.currentTarget.getAttribute("value");
        let oldType = currentType();
        if (oldType == value) {
            return;
        }
        for (let idx in tabs) {
            if (tabs.hasOwnProperty(idx)) {
                tabs[idx].checked = false;
                if (tabs[idx].type === value) {
                    tabs[idx].checked = true;
                }
            }
        }
        setTabs([...tabs]);
    }

    const DrawResult = useCallback(({data,t,ballSize}) => {
        let lastDraw = data.lotteryDraws[0];
        if ( lastDraw==null )
            return <div className="blockBody M6NoInfo">{t('LB_M6_INFO_BLOCK_NOINFO')}</div>
        let drawId = `${lastDraw.year.substring(2,4)}/${String(lastDraw.no).padStart(3, '0')}`;
        let [ drawDt ] = formatDateTime(lastDraw.drawDate.substring(0, 10));
        let firstPrize = lastDraw.lotteryPool.lotteryPrizes.filter(x=> x.type===1)[0];
        const prize = parseInt(firstPrize?.dividend) > 0 ? `$${parseInt(firstPrize.dividend).toLocaleString()}` : '-';
        const winUnit = firstPrize?.winningUnit > 0 ? (firstPrize.winningUnit / lastDraw.lotteryPool.unitBet).toLocaleString('en-US', { minimumFractionDigits: 1 }) : '-';
        return <div className="blockBody">
            <div className="blockDrawResultHead bottom-border">
                <div>{t('LB_M6_LASTRESULTS')}</div>
                <div className='df-date'>
                    <div className="right-border">{drawId}</div>
                    <div>{drawDt}</div>
                </div>
            </div>
            <div className="blockDrawResultBody">
                {lastDraw.drawResult?.drawnNo.map(x=> {
                    return <div className="blockDrawBall">{getBallImage(x, ballSize)}</div>
                })}
                <div className="blockDrawPlus">+</div>
                <div className="blockDrawBall">{getBallImage(lastDraw.drawResult?.xDrawnNo, ballSize)}</div>
            </div>
            <div className="blockDrawResultBottom">
                <div>{t('LB_M6_1ST_PRIZE_UNIT')} {prize}({winUnit})</div>
            </div>
        </div>
    },[])

    const NextDraw = useCallback(({data, t}) => {
        let lastDraw = data.lotteryDraws.length > 0 ? data.lotteryDraws[0] : {};
        let nextDraw = data.lotteryDraws.length > 1 ? data.lotteryDraws[1] : {};
        if ( !lastDraw || Object.keys(lastDraw).length===0 ){
            return <div className="blockBody M6NoInfo">{t('LB_M6_INFO_BLOCK_NOINFO')}</div>
        }
        let drawId = '';
        let drawDt = ['(-)', ''];
        let inv = ' ';
        let estdiv =  '-';
        let jpt = '-';
        if ( nextDraw && Object.keys(nextDraw).length>0 ){
            drawId = `${nextDraw.year.substring(2,4)}/${String(nextDraw.no).padStart(3, '0')}`;
            drawDt  = formatDateTime(nextDraw.drawDate.substring(0, 10));
            let pmPool = nextDraw.lotteryPool;
            inv = pmPool?.totalInvestment > 0 ? "$" + parseInt(pmPool.totalInvestment).toLocaleString() : "-";
            estdiv = pmPool?.derivedFirstPrizeDiv > 0 ? "$" + parseInt(pmPool.derivedFirstPrizeDiv).toLocaleString() : "-";
            jpt = pmPool?.jackpot > 0 ? "$" + parseInt(pmPool.jackpot).toLocaleString() : "-";
        }
        //let drawWD = capitalize(getDayOfWeek2(nextDraw.drawDate, t));
        return <div className="blockBody">
            <div className="blockNextDrawBody">
                <div>{t('LB_M6_LASTDRAW_NUM')}:</div>
                <div>{drawId}</div>
                <div>{t('LB_M6_DRAWDATE')}:</div>
                <div>{drawDt[0]}</div>
                <div>{t('LB_M6_TURNOVER')}:</div>
                <div>{inv}</div>
                <diiv>{t('LB_M6_JACKPOT_S')}:</diiv>
                <div className="blockM6Jackpot">{jpt}</div>
                <diiv>{t('LB_M6_EST_DIV_S')}:</diiv>
                <div className="blockM6Est">{estdiv}</div>
            </div>
        </div>
    },[])

    const StatTable = useCallback(({data, t}) => {
        let stat = data.lotteryStats[0];
        if ( stat==null )
            return <div className="blockBody M6NoInfo">{t('LB_M6_INFO_BLOCK_NOINFO')}</div>

        stat.drawnNumbers.sort((x,y) => {
            if ( x.totalNumber === y.totalNumber )
                return x.drawnNo - y.drawnNo;
            return y.totalNumber - x.totalNumber;
        });

        let mostDrawns = [...stat.drawnNumbers.slice(0, 6)];
        mostDrawns.sort((x,y) => { return x.drawnNo - y.drawnNo; });

        stat.drawnNumbers.sort((x,y) => {
            if ( x.totalNumber === y.totalNumber )
                return x.drawnNo - y.drawnNo;
            return x.totalNumber - y.totalNumber;
        });

        let leastDrawns = [...stat.drawnNumbers.slice(0, 6)];
        leastDrawns.sort((x,y) => { return x.drawnNo - y.drawnNo; });

        stat.drawnNumbers.sort((x,y) => {
            if ( x.lastDrawnIn === y.lastDrawnIn )
                return x.drawnNo - y.drawnNo;
            return y.lastDrawnIn - x.lastDrawnIn;
        });

        let mostDrawnsSince = [...stat.drawnNumbers.slice(0, 6)];
        mostDrawnsSince.sort((x,y) => { return x.drawnNo - y.drawnNo; });

        return <div className="blockBody blockBodyContent">
            <div className="blockStatBody">
                <div className='blockItemBody'>
                    <div>{t('LB_M6_STAT_LEAST_DRAWN')}</div>
                    <div>
                        {leastDrawns.map(x=> {
                            return <div className="blockStatBall">{getBallImage(x.drawnNo, 's')}</div>
                        })}
                    </div>
                </div>
                <div className='blockItemBody'>
                    <div>{t('LB_M6_STAT_MOST_DRAWN')}</div>
                    <div>
                        {mostDrawns.map(x=> {
                            return <div className="blockStatBall">{getBallImage(x.drawnNo, 's')}</div>
                        })}
                    </div>
                </div>
                <div className='blockItemBody'>
                    <div>{t('LB_M6_STAT_MOST_DRAWN_SINCE')}</div>
                    <div>
                        {mostDrawnsSince.map(x=> {
                            return <div className="blockStatBall">{getBallImage(x.drawnNo, 's')}</div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    },[])


    return (
        <section className="LYBlock">
            <div  role='button' tabIndex='0' className="blockHeader" onClick={()=>{ scrollTo(0, 0); navigate(`/${i18n.language}/marksix`) }}>
                <div>{t('LB_BMENU_MARKSIX')}</div>
                <ArrowIcon className='hueRotate' direction='right' size={24}/>
            </div>

            <MobileTabSet className="m6tab" items={tabs} handleRadioClick={changeTab} />

            {loading && <EmptyTable/> }
            {!loading && currentType()==="R" && <DrawResult ballSize={ballSize} data={data} t={t}/>}
            {!loading && currentType()==="N" && <NextDraw data={data} t={t}/>}
            {!loading && currentType()==="S" && <StatTable data={data} t={t}/>}

        </section>
    );
}
export default EwinHomeMarksixBlock;


