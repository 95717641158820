import React,{useState,useEffect } from 'react'
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import { toDateFormat } from './Common';
import { TournImage, FlagTournIcon } from './MatchBase'
import { useWindowSize } from '../../Common/CommonHooks';

const HaFuResTable = ({matchData={}}) => {
    const {t} = useTranslation()
    const [isOpen, setIsOpen] = useState(true)
    const handleClickExpand = () => {
        setIsOpen(!isOpen)
    }
    const pmPool = matchData.fbPmPools?.[0] || {};

    const combs = pmPool.winningCombination?.split('/') || []


    const matchDt = toDateFormat(matchData.kickOffTime?.substring(0,10) || '', true, false);
    const title = `${matchDt} (${matchData.matchDayOfWeek} ${matchData.matchNumber})`

    return < div className='MRTable-container HafuResContainer'>
        <header onClick={handleClickExpand} className='HaFuResultTableHeader'>
            <div className='header-left'>
               <div className={`expand ${isOpen ? 'open': 'close'}`}></div>
                <span>{title}</span>
            </div>
            <div className='header-right'>
                {t('LB_FB_STOP_SELL_DATE')}:  {matchDt}
            </div>
        </header>
        {isOpen && <div className='hafu-results-table'>
            <DividendInfo pmPool={pmPool}/>
            <div className='fb-results-mip-table'>
            <TableHeader />
            {pmPool.leg?.map((item, index) =>
                <TableRow legData={item} comb={combs[index]} className={index %2 ? 'sec' : ''} key={item.match.id}/>
            )}
            </div>
        </div>}
    </ div>
}

const formatVal = (val, defaultVal) => {
    if ( val===null || val==="" || isNaN(val) )
        return defaultVal || "-";
    return "$" + parseInt(val).toLocaleString();
}

const DividendInfo = ({pmPool}) => {
    const { isMobileSize } = useWindowSize();
    const { t } = useTranslation();
    const isRefund = pmPool.status.includes('REFUND')
    const winComb = isRefund || pmPool.winningCombination===null || pmPool.winningCombination==="-" ? '-' : pmPool.winningCombination;
    const jackpotgen = isRefund ? '-' : `${formatVal(pmPool.jackpotGen)}`
    const investment = isRefund ? '-' : `${formatVal(pmPool.investment)}`
    const noWinner =  parseInt(pmPool.dividends) <= 0 
    const winningdividend = isRefund ? '-' : noWinner ? t('LB_FB_DHCRES_NOWINNER') : `${formatVal(pmPool.dividends)}`
    const winninginvestment = isRefund? '-' : `${formatVal(pmPool.winningInvestment)}`
    const noConWinner =  parseInt(pmPool.consolationDividends) <= 0 
    const conDiv = pmPool.consolationDividends && pmPool.consolationDividends!=="" ? (noConWinner ? t('LB_FB_DHCRES_NOWINNER') : `${formatVal(pmPool.consolationDividends)}`) : null;
    const conInv = pmPool.consolationInvestment && pmPool.consolationInvestment!=="" ? `${formatVal(pmPool.consolationInvestment)}` : null;
    const colon = isMobileSize ? "" : ":";
    return (
        <div  className='hafu-results-dividendInfo'>
                <div>
                    <div>{t('LB_FB_DHCRES_WINCOMB')}{colon}</div>
                    <span>{winComb}</span>
                </div>
                <div>
                    <div>{t('LB_FB_DHCRES_JACKPOT_GENERATED')}{colon}</div>
                    <span>{jackpotgen}</span>
                </div>
                <div>
                    <div>{t('LB_FB_DHCRES_INV')}{colon}</div>
                    <span>{investment}</span>
                </div>
                <div>
                    <div>{t('LB_FB_DHCRES_UNIT_DIVIDEND')}{colon}</div>
                    {conDiv===null ? <span>{winningdividend}</span>
                    : <>
                        <span>{t('LB_FB_DHCRES_WINNING')} {winningdividend}<br/>
                        {t('LB_FB_DHCRES_CONSOLATION')} {conDiv}</span>
                    </>}
                </div>
                <div>
                    <div>{t('LB_FB_DHCRES_WINNING_INV')}{colon}</div>
                    {conDiv===null ? <span>{winninginvestment}</span>
                    : <>
                        <span>{winninginvestment}<br/>{conInv}</span>
                    </>}
                </div>
            </div>
    )
}

const TableHeader = () =>{
    const {t} = useTranslation();

    return <div className='table-row table-header'>
        <div className='table-cell'>
            {t('LB_FB_DHCRES_LEG')}
        </div>
        <div className='table-cell'>
            {t('LB_FB_DHCRES_EVENTID')}
        </div>
        <div className='table-cell'>
            <FlagTournIcon />
        </div>
        <div className='table-cell table-cell-team'>
            {t('LB_FB_DHCRES_TEAM')}
        </div>
        <div className='table-cell'>
            {t('LB_FB_DHCRES_DATE')}
        </div>
        <div className='table-cell table-cell-results'>
            <div>{t('LB_FB_DHCRES_MATCH_RESULTS')}</div>
            <div>
                <div>{t('LB_FB_DHCRES_HALF_TIME')}</div>
                <div>{t('LB_FB_DHCRES_FULL_TIME')}</div>
            </div>
        </div>
    </div>
}

const TableRow = ({legData, className, comb}) => {
    const {t, i18n} = useTranslation()
    const homeT = legData.match.homeTeam?.["name_" + i18n.language];
    const awayT = legData.match.awayTeam?.["name_" + i18n.language];
    const tournName = legData.match.tournament?.["name_" + i18n.language];
    const tournCode = legData.match.tournament?.code;
    const date = moment(legData.match.kickOffTime?.substring(0,10)).format("DD/MM/YYYY");
    const firstHalf = legData.match.results?.find(item => item.stageId === 3 && item.resultType===1 && item.resultConfirmType>1);
    const secondHalf = legData.match.results?.find(item => item.stageId === 5 && item.resultType===1 && item.resultConfirmType>1);
    const combList = comb?.split('#') || []
    const FHOtherVal = ['HO', 'AO', 'DO'].includes(combList[0]) ? `(${t(`LB_FB_${combList[0]}`)})` : ''
    const SHOtherVal = ['HO', 'AO', 'DO'].includes(combList[1]) ? `(${t(`LB_FB_${combList[1]}`)})` : ''
    const FHScore = firstHalf ? <div>{firstHalf.homeResult} : {firstHalf.awayResult}<br/>{FHOtherVal}</div> : <div>-</div>
    const SHScore = secondHalf ? <div>{secondHalf.homeResult} : {secondHalf.awayResult}<br/>{SHOtherVal}</div> : <div>-</div>

    const isVoid = legData.match.status.indexOf("VOID")>=0 && !firstHalf

    return <div className={`DHCRES-TableRow table-row ${className ? className : ''}`}>
         <div className='table-cell DHCRES-leg'>
            {t(`LB_FB_DHCRES_LEG${legData.no}`)}
        </div>
        <div className='table-cell DHCRES-day'>
            {t(`LB_${legData.match.matchDayOfWeek}_SHORT`)} {legData.match.matchNumber}
        </div>
        <div className='table-cell DHCRES-flag'>
            <TournImage code={tournCode} name={tournName} />
        </div>
        <div className='table-cell table-cell-team DHCRES-team'>
            <div>{homeT}</div>
            <div>{awayT}</div>
        </div>
        <div className='table-cell DHCRES-date'>
            {date}
        </div>
        <div className='table-cell table-cell-results DHCRES-score'>
            <div className={`results-score ${isVoid ? 'isMatchVoid' : ''}`}>
            {isVoid ? <>
                <div className='DHCRES-void void-color'>{t('LB_FB_DHCRES_VOID')}</div>
                </>: 
                <>
                    {FHScore}
                    {legData.match.status.indexOf("VOID")>=0 ? <div className='void-color'>{t('LB_FB_DHCRES_VOID')}</div> : SHScore}
                </>}
            </div>
        </div>
    </div>
}

const HaFuResTableMobile = ({matchData={}}) => {
    const {t} = useTranslation()
    const pmPool = matchData.fbPmPools?.[0] || {};

    const combs = pmPool.winningCombination?.split('/') || []
    const table = pmPool.leg?.map((item, index) => <TableRowMobile legData={item} comb={combs[index]} className={index %2 ? 'sec' : ''} key={item.match.id}/>)

    const matchDt = toDateFormat(matchData.kickOffTime?.substring(0,10) || '', true, false);
    const title = `${matchDt} (${matchData.matchDayOfWeek} ${matchData.matchNumber})`

    
    return <div className='HafuResContainerMobile'>
        <header  className='HaFuResultTableHeader'>
            <div className='header-left'>
                <span>{title}</span>
            </div>
        </header>
        <div className="HaFuResultTableStopSellDate">
            <div>{t('LB_FB_STOP_SELL_DATE')}</div>
            <div>{matchDt}</div>
        </div>
        <div className='hafu-results-table-m'>
            <DividendInfo pmPool={pmPool}/>
            <div >
            <TableHeaderMobile />
            {table}
            </div>
        </div>
    </div>
}

const TableHeaderMobile = () => {
    const { t } = useTranslation();
    return <div className='hafu-results-table-m-header'>
        <div></div>
        <div>{t('LB_FB_DHCRES_HALF_TIME_M')}</div>
        <div>{t('LB_FB_DHCRES_FULL_TIME_M')}</div>
    </div>
}

const TableRowMobile = ({legData, className, comb}) => {
    const {t, i18n} = useTranslation()
    const homeT = legData.match.homeTeam?.["name_" + i18n.language];
    const awayT = legData.match.awayTeam?.["name_" + i18n.language];
    const date = moment(legData.match.kickOffTime?.substring(0,10)).format("DD/MM/YYYY");
    const firstHalf = legData.match.results?.find(item => item.stageId === 3 && item.resultType===1 && item.resultConfirmType>1);
    const secondHalf = legData.match.results?.find(item => item.stageId === 5 && item.resultType===1 && item.resultConfirmType>1);
    const combList = comb?.split('#') || []
    const FHOtherVal = ['HO', 'AO', 'DO'].includes(combList[0]) ? `(${t(`LB_FB_${combList[0]}`)})` : ''
    const SHOtherVal = ['HO', 'AO', 'DO'].includes(combList[1]) ? `(${t(`LB_FB_${combList[1]}`)})` : ''
    const FHScore = firstHalf ? <div>{firstHalf.homeResult} : {firstHalf.awayResult}<br/>{FHOtherVal}</div> : <div>-</div>
    const SHScore = secondHalf ? <div>{secondHalf.homeResult} : {secondHalf.awayResult}<br/>{SHOtherVal}</div> : <div>-</div>

    return <div className={`HafuResTableRowMobile ${className ? className : ''}`}>
        <div className='DHCRES-info-m'>
            <div><span>{t(`LB_${legData.match.matchDayOfWeek}_SHORT`)} {legData.match.matchNumber}</span> <span className='DHCRES-leg-m'>{t(`LB_FB_DHCRES_LEG${legData.no}`)}</span></div>
            <div className='DHCRES-team-m'>
                <div>{homeT}</div>
                <div>{awayT}</div>
            </div>
            <div className='DHCRES-date-m match-date'>{date}<div className="match-date-triangle"></div></div>
        </div>
        {legData.match.status.indexOf("VOID")>=0 && firstHalf===null ? <>
            <div className='DHCRES-void-m'>{t('LB_FB_DHCRES_VOID')}</div>
        </>: 
        <>
            {FHScore}
            {legData.match.status.indexOf("VOID")>=0 ? <div className='void-color'>{t('LB_FB_DHCRES_VOID')}</div> : SHScore}
        </>}
    </div>
}

const ResultsTable = ({matches}) => {
    return <div>
        <div className='mobile-hide'>
            {
                matches.map(item =>{
                    return <HaFuResTable matchData={item}  />
                })
            }
        </div>
        <div className='mobile-block'>
            {
                matches.map(item =>{
                    return <HaFuResTableMobile matchData={item}  />
                })
            }
        </div>
    </div>
}



export default ResultsTable