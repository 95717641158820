import React, { useContext, useState, useRef, useEffect } from 'react';
import { onClickCheckBox } from './AddBet';
import { Coupon } from '../Common/TournamentTable';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { toDateFormat, formatOdds, isEliminated, isRefund, isSellingStarted } from './Common';
import { getOddsArrowCss } from './MatchBase';
import CheckBox from '../../Common/ui-components/CheckBox';

const GPFTable = ({odds, singleTourn}) => {

    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const [expand, setExpand] = useState(true);
    const [checkboxState, setCheckboxState] = useState([]);
    const foPools = singleTourn.foPools.filter(x=> x.oddsType==="GPF");
    foPools.sort((x,y) => { return x.instNo - y.instNo });
    const scrollStates = useRef([...foPools.map(x=> 0)]);

    useEffect(() => {
        foPools.forEach((foPool, idx) => {
            const dom = document.querySelector('.gpfInst'+idx)
            if(!dom) return
            dom.scrollLeft = scrollStates.current[idx];
        });
    }, [scrollStates, foPools]);

    const toggle = () => {
        setExpand(!expand)
    }

    const regroupGPF = (combs) => {
        let sels = [];
        let strMapping = {};
        combs.forEach(comb => {
            // let selKey = `${comb.selections[0].str}_${comb.selections[1].str}`;
            // strMapping[selKey] = comb.combId;
            // strMapping[comb.str] = comb.combId;
            comb.selections.forEach(sel => {
                if ( sels.filter(x=>x.str===sel.str).length==0 )
                sels.push(sel)
            });
        });
        sels.sort((x, y) => { return parseInt(x.str) - parseInt(y.str) });
        return [sels, strMapping];
    }

    const tableProps = {singleTourn, context, setCheckboxState, regroupGPF, scrollStates}

    return <section className="GPF">
        <Coupon title={`${t('LB_FB_TITLE_GPF')} ${singleTourn.frontEndId}`} handleOnClick={toggle} isChecked={expand} showHelpBtn={true} showAllupTxt={true} betType="GPF" frontEndId={singleTourn.frontEndId} />
        { expand ? <>
            {foPools.map((foPool, idx) => {
                return <GPFSingleTable foPool={foPool} idx={idx} {...tableProps}/>
            })}
        </> : null }
    </section>

}
export default GPFTable;

const GPFSingleTable = ({foPool, idx, singleTourn, context, setCheckboxState, regroupGPF, scrollStates}) => {
    const {t, i18n} = useTranslation()
    let combs = foPool.lines[0].combinations;
    const [ sels, strMapping ] = regroupGPF(combs)
    let cssTemplate = 'gpfSelections' + sels.length;
    return <div className="gpfSingleTable">
        <div className="gpfHeader">
            <div className="gpfTitle">{`${foPool["name_" + i18n.language]} / ${t('LB_FB_GPW_GROUPNO')}: ${foPool.instNo}`}</div>
            <div className="gpfEsst">
                <div className="gpwEsstLbl">{t('LB_FB_CHP_TABLE_DATE')}</div>
                <div>{toDateFormat(foPool.expectedSuspendDateTime, true, true)}</div>
            </div>
        </div>
        <div className={`gpfInst${idx} ${cssTemplate}`} onScroll={(e) => { scrollStates.current[idx] = e.target.scrollLeft }}>
            <div className="gpfFirstCell gpfStickyCol">
                <div className="gpfWinner">{t('LB_FB_GPF_WINNER')}</div>
                <div className="gpfRunnerUp">{t('LB_FB_GPF_RUNNERUP')}</div>
            </div>
            {sels.map(sel => {
                return <div className="gpfSelHeader">{`${sel.str}. ${sel["name_"+i18n.language]}`}</div>
            })}
            {sels.map((sel, index) => {
                let cssStr = index++%2==0 ? 'even': '';
                return <>
                    <div className={`gpfSelRowHeader gpfStickyCol gpfRow${cssStr}`}>{`${sel.str}. ${sel["name_"+i18n.language]}`}</div>
                    {sels.map(sel2 => {
                        let comb = combs.filter(x=> x.str===`${sel2.str.padStart(2,'0')}:${sel.str.padStart(2,'0')}`)[0];
                        let odds = '';
                        let isDisabled = true;
                        let id = '';
                        if ( comb!=null ) {
                            odds = comb.currentOdds;
                            isDisabled = isRefund(comb) || isEliminated(comb) || !isSellingStarted(singleTourn, foPool, foPool.lines[0]) || comb.status!="AVAILABLE";
                            id = 'GPF_' + foPool.lines[0].lineId + "_" + comb.combId  + "_"  + foPool.id + "_" + comb.str;
                        }
                        let curState = context.content.oddsSelection.filter(x => x.id == id)[0];
                        curState = {...curState,
                            id: id,
                            checked: curState?.checked || false,
                            oddsVal: odds,
                            matchId: singleTourn.id
                        }
                        const oddsColor = getOddsArrowCss(curState);
                        return sel.str!=sel2.str ? <div className={`gpfCellGrid ${cssStr}`}>
                            <div className={`gpfSelChkbox`}>
                                <CheckBox  product='football' id={`fb_${id}`} onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)} defaultChecked={curState.checked} disabled={isDisabled} />
                                <span onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)} className={`${id} ${isDisabled && 'dim'}`}></span>
                            </div>
                            <div className={`gpfSelOdds oddsColor ${oddsColor}`}>
                                {oddsColor && <div className={`oddsArrow ${oddsColor}`}></div>}
                                {formatOdds(t, odds, comb?.status)}
                            </div>
                        </div> : <div className={`gpfCell ${cssStr}`}>-</div>
                    })}
                </>
            })}
        </div>
    </div>
}