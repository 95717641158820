import { createTournData, createTournament, updateTourn } from './createTourn';
import { isTournamentPage } from '../../Common/Common';
import { trimTournament, trimTournamentByTourn } from '../../Common/Common';
import { getDisplayMatches } from '../useGetMatchList/common';

export const parseUpdTourn = (message, topicInfo, contentRef) => {
    let tourn = isTournamentPage(contentRef.current.page) ? createTournData(message) : createTournament(message);
    let idx = contentRef.current.rawTournList.findIndex(x => x.id===message.id);
    if(idx < 0) {
        contentRef.current.rawTournList.push(tourn);
    }
    else {
        contentRef.current.rawTournList[idx] = tourn;
    }

    if( isTournamentPage(contentRef.current.page) ) {
        let tourn = contentRef.current.tournData?.tournaments.filter(x => x.id == message.id)[0];
        if(tourn){
            updateTourn(tourn, message);
            if(["ALL", "TOURN"].includes(contentRef.current.selection.type)) {
                contentRef.current.selection.tabs = trimTournamentByTourn(contentRef.current.tournData.tournaments);
            }
        }
    }
    else{
        let matches = contentRef.current.matchData?.matches?.filter(x => x.tournament.id===topicInfo.tournamentid) || []
        matches.forEach(match => {
            updateTourn(match.tournament, message);
        });

        matches = contentRef.current.rawMatchList.filter(x => x.tournament.id===topicInfo.tournamentid) 
        matches.forEach(match => {
            updateTourn(match.tournament, message);
        });
        let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
        contentRef.current.listData = { matches : matchList };

        if(["ALL", "TOURN"].includes(contentRef.current.selection.type)) {
            contentRef.current.selection.tabs = trimTournament(contentRef.current.listData.matches);
        }
    }
}