import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RacingContext } from '../../Home/RacingPage';

const Investment = ({ betType, showJpt, showEstDiv }) => {

    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const poolInv =  mtg.poolInvs.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType==betType)[0];
    const jpEst =  mtg.jpEsts.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType==betType)[0];


    function isMergedPool() {
        return poolInv!=null && poolInv.mergedPoolId!=null;
    }

    const investment = () => {
        let tempLabel = t('LB_RC_INVESTMENT');
        let amount = "-";
        if ( poolInv?.status.indexOf("REFUND")<0 ) {
            if (poolInv?.investment != null && poolInv?.investment > 0 ) {
                amount = parseInt(poolInv.investment).toLocaleString();
            }
        }

        if ( isMergedPool() ) {
            switch (betType) {
                case "FF":
                case "QTT":
                    tempLabel = t('LB_RC_QTTFF_MERGE') + t('LB_RC_INVESTMENT');
                    break;
                case "FCT":
                case "TCE":
                case "TRI":
                    tempLabel = t('LB_RC_INVESTMENT_MERGE_WITH_REMARKS');
                    break;
                default:
                    break;
            }
        }
        return <div><div className="label" id={`investmentLabel`} style={{ whiteSpace: "nowrap" }}>{tempLabel} :</div> <div className="amount-blue"  id={`investmentValue`} >${amount}</div></div>
    }

    const hasJpt = () => {
        return jpEst!=null && jpEst.jackpot != null;
    }

    const jackpot = () => {
        let tempLabel = t('LB_RC_JACKPOT');
        let amount = "-";
        if ( poolInv?.status.indexOf("REFUND")<0 ) {
            if (jpEst?.jackpot != null) {
                amount = '$' + parseInt(jpEst.jackpot).toLocaleString();
            }
        }

        if ( isMergedPool() ) {
            switch (betType) {
                case "FF":
                case "QTT":
                    tempLabel = t('LB_RC_QTTFF_MERGE') + t('LB_RC_JACKPOT');
                    break;
                case "TCE":
                case "TRI":
                    tempLabel = t('LB_RC_TCETRI_MERGE') + t('LB_RC_JACKPOT');
                    break;
                default:
                    break;
            }
        }
        
        switch (betType) {
            case "DT":
                if ( poolInv?.leg.number>0 )
                    tempLabel = t('LB_RC_POOL_NO_' + poolInv.leg.number) + t('LB_' + betType) + ' '  + t('LB_RC_JACKPOT');
                break;
            case "TT":
                if ( poolInv?.leg.number>0 && mtg.poolInvs.filter((data) => data.oddsType==betType).length > 1 )
                    tempLabel = t('LB_RC_POOL_NO_' + poolInv.leg.number) + t('LB_' + betType) + ' '  + t('LB_RC_JACKPOT');
                break;
            case "SixUP":
                tempLabel = t('LB_6UP_WIN_BONUS') + ' '  + t('LB_RC_JACKPOT');
                if ( poolInv?.leg.number>0 && mtg.poolInvs.filter((data) => data.oddsType==betType).length > 1)
                    tempLabel = t('LB_RC_POOL_NO_' + poolInv.leg.number) + t('LB_6UP_WIN_BONUS') + ' '  + t('LB_RC_JACKPOT');
                break;
        }


        return <div><div className="label" id={`jackpotLabel`} style={{ whiteSpace: "nowrap" }}>{tempLabel} :</div> <div id={`jackpotValue`} className="amount-blue">{amount}</div></div>
    }

    const estDiv = () => {
        let tempLabel = t('LB_RC_ESTDIV');
        let amount = "-";
        if ( poolInv?.status.indexOf("REFUND")<0 ) {
            if (jpEst?.estimatedDividend != null) {
                amount = '$' + parseInt(jpEst.estimatedDividend).toLocaleString();
            }
        }
        return <div><div className="label" id={`estDividendLabel`} style={{ whiteSpace: "nowrap" }}>{tempLabel} :</div> <div id={`estDividendValue`} className="amount-blue">{amount}</div></div>
    }

    let gridStyle = "";
    if ( showEstDiv && showJpt ) {
        gridStyle = "meeting-info-ivestment-3x";
    }
    else if ( showJpt || hasJpt() ) {
        gridStyle = "meeting-info-ivestment-2x";
    }
    return (<div className={`meeting-info-investment-top`}>
        <div className={`meeting-info-investment ${gridStyle}`}>
            {investment()}
            {showJpt || hasJpt() ? jackpot() : null}
            {showEstDiv ? estDiv() : null}
        </div>
    </div>
    )
}

export default Investment