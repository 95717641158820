import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { ResultHeader, ResultFooter } from '../Common/ResultsBase';
import { Coupon } from '../Common/TournamentTable';

const TOURNRES = () => {
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const [ expandTables, setExpandTable ] = useState({
        CHP: true,
        GPF: true,
        GPW: true,
        TQL: true,
        TSP: true,
        TPS: true
    });
    const tournament = context.content.tournData?.tournaments?.[0];
    const AllTournamentOrder = context.content.config.AllTournamentOrder;
    const isMobile = context.state.windowSize.isMobileSize;

    const toggleTable = (betType) => {
        expandTables[betType] = !expandTables[betType];
        setExpandTable({...expandTables});
    }

    const isAllPoolRefund = (foPools) => {
        let hasAll = true;
        foPools.forEach(foPool => {
            if ( foPool.status.indexOf("REFUND")<0 )
                hasAll = false;
        });
        return hasAll;
    }

    const hasAnyRefundSels = (foPools) => {
        let hasAny = false;
        foPools.forEach(foPool => {
            if ( foPool.lines[0].combinations.filter(x=> x.status.indexOf("REFUND")>=0).length > 0 )
                hasAny = true;
        });
        return hasAny;
    }

    const GeneralTorunResultTable = ({ foPool, betType }) => {
        let refundAll = foPool.status.indexOf("REFUND")>=0;
        let winCombs = foPool.lines[0].combinations.filter(x=> x.status==="WIN").sort((a,b) => a.winOrd - b.winOrd);
        let rfdCombs = foPool.lines[0].combinations.filter(x=> x.status.indexOf("REFUND")>=0);
        let hasRefundSels = !refundAll && rfdCombs.length>0;

        return <section className={betType}>
            <Coupon title={t('LB_FB_TITLE_'+betType)} handleOnClick={toggleTable} isChecked={expandTables[betType]} betType={betType} />
            {expandTables[betType] && <div className={`tourn-result-table${hasRefundSels ? '2' : ''}`}>
                <div className="header">{t(`LB_FB_TOURNRES_${betType}_HEADER`)}</div>
                {hasRefundSels && <div className="header">{t('LB_FB_RFD')}</div>}
                <div className="cell grey">{refundAll ?
                    <span className="refund-for-all">{t('LB_FB_REFUND_FOR_ALL')}</span>
                    : winCombs.map(comb => {
                        return <div>{comb.selections[0].str} {comb.selections[0]["name_"+i18n.language]}</div>
                    })}
                </div>
                {hasRefundSels && <div className="cell left-border grey">{rfdCombs.map(comb => {
                    return <div className="refund-wrap">{comb.selections[0].str} {comb.selections[0]["name_"+i18n.language]}</div>
                })}</div>}
            </div>}
        </section>
    }

    const TQLResultTable = ({ foPools }) => {
        foPools.sort((x, y) => { return x.instNo - y.instNo; });
        let cssIdx  = 0;
        let isAllRefund = isAllPoolRefund(foPools);
        let hasRefundSels = hasAnyRefundSels(foPools);
        let show3Col = !isAllRefund && hasRefundSels;
        return <section className="TQL">
            <Coupon title={t('LB_FB_TITLE_TQL')} handleOnClick={toggleTable} isChecked={expandTables["TQL"]} betType="TQL" />
            {expandTables["TQL"] && <div className={`tourn-result-table${show3Col ? '3' : '2'}`}>
                <div className="header">{t('LB_FB_TQL_CODE')}</div>
                <div className="header">{t('LB_FB_TOURNRES_TQL_HEADER')}</div>
                {show3Col && <div className="header">{t('LB_FB_RFD')}</div>}
                {foPools.map((foPool) => {
                    let refundAll = foPool.status.indexOf("REFUND")>=0;
                    let winCombs = foPool.lines[0].combinations.filter(x=> x.status==="WIN").sort((a,b) => a.winOrd - b.winOrd);
                    let rfdCombs = foPool.lines[0].combinations.filter(x=> x.status.indexOf("REFUND")>=0);
                    let cssBg = cssIdx++%2===0 ? 'grey' : '';
                    return <>
                        <div className={`cell ${cssBg}`}>{foPool.instNo}</div>
                        <div className={`cell left-border ${cssBg}`}>{refundAll ?
                            <span className="refund-for-all">{t('LB_FB_REFUND_FOR_ALL')}</span>
                            : winCombs.map((winComb) => {
                                return <div>{winComb?.selections[0].str} {winComb?.selections[0]["name_"+i18n.language]}</div>
                            })
                        }</div>
                        {show3Col && <div className={`cell left-border ${cssBg}`}>{!refundAll && rfdCombs.map(comb => {
                            return <div className="refund-wrap">{comb.selections[0].str} {comb.selections[0]["name_"+i18n.language]}</div>
                        })}</div>}
                    </>
                })}
                </div>
            }
        </section>
    }

    const GPWResultTable = ({ foPools }) => {
        foPools.sort((x, y) => { return x.instNo - y.instNo; });
        let cssIdx  = 0;
        let hasRefundSels = hasAnyRefundSels(foPools);
        return <section className="GPW">
            <Coupon title={t('LB_FB_TITLE_GPW')} handleOnClick={toggleTable} isChecked={expandTables["GPW"]} betType="GPW" />
            {expandTables["GPW"] && <div className={`tourn-result-table-gp${hasRefundSels ? '2' : '1'}`}>
                <div className="header">{t('LB_FB_GPW_GROUPNO')}</div>
                <div className="header">{t('LB_FB_GPW_GROUP')}</div>
                <div className="header tourn-result-left">{t('LB_FB_TOURNRES_GPW_HEADER')}</div>
                {hasRefundSels && <div className="header tourn-result-left">{t('LB_FB_RFD')}</div>}
                {foPools.map((foPool) => {
                    let refundAll = foPool.status.indexOf("REFUND")>=0;
                    let winCombs = foPool.lines[0].combinations.filter(x=> x.status==="WIN").sort((a,b) => a.winOrd - b.winOrd);
                    let rfdCombs = foPool.lines[0].combinations.filter(x=> x.status.indexOf("REFUND")>=0);
                    let cssBg = cssIdx++%2===0 ? 'grey': '';
                    return <>
                        <div className={`cell ${cssBg}`}>{foPool.instNo}</div>
                        <div className={`cell left-border ${cssBg}`}>{foPool["name_"+i18n.language]}</div>
                        <div className={`cell left-border tourn-result-left ${cssBg}`}>{refundAll ?
                            <span className="refund-for-all">{t('LB_FB_REFUND_FOR_ALL')}</span>
                            : winCombs.map((winComb) => {
                                return <div>{winComb?.selections[0].str} {winComb?.selections[0]["name_"+i18n.language]}</div>
                            })
                        }</div>
                        {hasRefundSels && <div className={`cell left-border tourn-result-left ${cssBg}`}>{!refundAll && rfdCombs.map(comb => {
                            return <div className="refund-wrap">{comb.selections[0].str} {comb.selections[0]["name_"+i18n.language]}</div>
                        })}</div>}
                    </>
                })}
                </div>
            }
        </section>
    }

    const GPFResultTable = ({ foPools }) => {
        foPools.sort((x, y) => { return x.instNo - y.instNo; });
        let cssIdx  = 0;
        let hasRefundSels = hasAnyRefundSels(foPools);

        return <section className="GPF">
            <Coupon title={t('LB_FB_TITLE_GPF')} handleOnClick={toggleTable} isChecked={expandTables["GPF"]} betType="GPF" />
            {expandTables["GPF"] && <div className={`tourn-result-table-gp${hasRefundSels ? '3' : '2'}`}>
                <div className="header gpf-result-groupno">{t('LB_FB_GPW_GROUPNO')}</div>
                <div className="header gpf-result-group">{t('LB_FB_GPW_GROUP')}</div>
                <div className="header gpf-result-wincomb">{t('LB_FB_TOURNRES_GPF_HEADER')}</div>
                {hasRefundSels && <div className="header gpf-result-refund tourn-result-left">{t('LB_FB_RFD')}</div>}
                <div className="header gpf-result-winner tourn-result-left">{t('LB_FB_GPF_WINNER')}</div>
                <div className="header gpf-result-runnerup tourn-result-left">{t('LB_FB_GPF_RUNNERUP')}</div>
                {foPools.map((foPool) => {
                    let refundAll = foPool.status.indexOf("REFUND")>=0;
                    let winCombs = foPool.lines[0].combinations.filter(x=> x.status==="WIN").sort((a,b) => a.winOrd - b.winOrd);
                    let rfdCombs = foPool.lines[0].combinations.filter(x=> x.status.indexOf("REFUND")>=0);
                    rfdCombs.sort((x, y) => { return parseInt(x.combId) - parseInt(y.combId) });
                    let cssBg = cssIdx++%2===0 ? 'grey': '';
                    return <>
                        <div className={`cell ${cssBg}`}>{foPool.instNo}</div>
                        <div className={`cell left-border ${cssBg}`}>{foPool["name_"+i18n.language]}</div>
                        <div className={`cell left-border tourn-result-left ${cssBg}`}>{refundAll ?
                            <span className="refund-for-all">{t('LB_FB_REFUND_FOR_ALL')}</span>
                            : winCombs.map((winComb) => {
                                return <div>{winComb?.selections[0].str} {winComb?.selections[0]["name_"+i18n.language]}</div>
                            })
                        }</div>
                        <div className={`cell left-border tourn-result-left ${cssBg}`}>
                            {winCombs.map((winComb) => {
                                return <div>{!refundAll && winComb?.selections[1].str} {winComb?.selections[1]["name_"+i18n.language]}</div>
                            })}
                        </div>
                        {hasRefundSels && <div className={`cell left-border tourn-result-left refund-wrap ${cssBg}`}>{!refundAll && rfdCombs.map(comb => {
                            return <span>{comb.selections[0].str} {comb.selections[0]["name_"+i18n.language]}
                                - {comb.selections[1].str} {comb.selections[1]["name_"+i18n.language]}</span>
                        })}</div>}
                    </>
                })}
                </div>
            }
        </section>
    }

    const GPResultTableMobile = ({ foPools, betType }) => {
        foPools.sort((x, y) => { return x.instNo - y.instNo; });
        let cssIdx  = 0;
        return <section className={betType}>
            <Coupon title={t('LB_FB_TITLE_'+betType)} handleOnClick={toggleTable} isChecked={expandTables[betType]} betType={betType} />
            {expandTables[betType] && <div className={`tourn-result-table-gp-m`}>
                <div className="header">{t('LB_FB_GPW_GROUPNO')}</div>
                <div className="header">{t('LB_FB_GPW_GROUP')}</div>
                <div className="header tourn-result-left">{t('LB_FB_TOURNRES_' + betType + '_HEADER_M')}</div>
                {foPools.map((foPool) => {
                    let refundAll = foPool.status.indexOf("REFUND")>=0;
                    let winCombs = foPool.lines[0].combinations.filter(x=> x.status==="WIN").sort((a,b) => a.winOrd - b.winOrd);
                    let rfdCombs = foPool.lines[0].combinations.filter(x=> x.status.indexOf("REFUND")>=0);
                    let cssBg = cssIdx++%2===0 ? 'grey': '';
                    return <>
                        <div className={`cell ${cssBg}`}>{foPool.instNo}</div>
                        <div className={`cell left-border ${cssBg}`}>{foPool["name_"+i18n.language]}</div>
                        <div className={`cell left-border tourn-result-left ${cssBg}`}>{refundAll ?
                            <span className="refund-for-all">{t('LB_FB_REFUND_FOR_ALL')}</span>
                            : <>
                            {!refundAll && <div className="gp-small-title">{t('LB_FB_TOURNRES_' + betType + '_WINNER_M')}</div>}
                            <div>
                                {winCombs.map((winComb) => {
                                    return <div>{winComb?.selections[0].str} {winComb?.selections[0]["name_"+i18n.language]}</div>
                                })}
                            </div>
                            {betType==="GPF" && !refundAll && <div className="gp-small-title-padding">{t('LB_FB_GPF_RUNNERUP')}</div>}
                            {betType==="GPF" && <div>{winCombs.map((winComb) => {
                                    return <div>{winComb?.selections[1].str} {winComb?.selections[1]["name_"+i18n.language]}</div>
                                })}</div>}
                            </>}
                            {!refundAll && rfdCombs.length>0 && <>
                                <div className="gp-small-title-padding">{t('LB_FB_RFD')}</div>
                                {!refundAll && rfdCombs.map(comb => {
                                    return betType==="GPF" ?
                                    <div className="refund-wrap">{comb.selections[0].str} {comb.selections[0]["name_"+i18n.language]}
                                - {comb.selections[1].str} {comb.selections[1]["name_"+i18n.language]}</div>
                                    : <div className="refund-wrap">{comb.selections[0].str} {comb.selections[0]["name_"+i18n.language]}</div>
                            })}</>}
                        </div>
                    </>
                })}
                </div>
            }
        </section>
    }

    const TSPResultTable = ({ foPools }) => {
        foPools.sort((x, y) => { return x.instNo - y.instNo; });
        let cssIdx  = 0;
        return <section className="TSP">
            <Coupon title={t('LB_FB_TITLE_TSP')} handleOnClick={toggleTable} isChecked={expandTables["TSP"]} betType="TSP" />
            {expandTables["TSP"] && <div className={`tourn-result-table-tsp`}>
                <div className="header">{t('LB_FB_RESULT_SPC_NO')}</div>
                <div className="header tourn-result-left">{t('LB_FB_RESULT_SPC_ITEM')}</div>
                <div className="header tourn-result-left">{t('LB_FB_RESULT_SPC_RESULTS')}</div>
                {foPools.map((foPool) => {
                    let refundAll = foPool.status.indexOf("REFUND")>=0;
                    let winCombs = foPool.lines[0].combinations.filter(x=> x.status==="WIN").sort((a,b) => a.winOrd - b.winOrd);
                    let rfdCombs = foPool.lines[0].combinations.filter(x=> x.status.indexOf("REFUND")>=0);
                    let cssBg = cssIdx++%2===0 ? 'grey': '';
                    return <>
                        <div className={`cell ${cssBg}`}>{foPool.instNo}</div>
                        <div className={`cell left-border tourn-result-left ${cssBg}`}>{foPool["name_"+i18n.language]}</div>
                        <div className={`cell left-border tourn-result-left ${cssBg}`}>{refundAll ?
                            <span className="refund-for-all">{t('LB_FB_REFUND_FOR_ALL')}</span>
                            : <>
                                {winCombs.map(comb => {
                                    return <div>({comb.selections[0].str}) {comb.selections[0]["name_"+i18n.language]}</div>
                                })}
                                {rfdCombs.map(comb => {
                                    return <div>({comb.selections[0].str}) {comb.selections[0]["name_"+i18n.language]} <span className="refund-for-all">- {t('LB_FB_RFD')}</span></div>
                                })}
                            </>}
                        </div>
                    </>
                })}
                </div>
            }
        </section>
    }

    const TSPResultTableMobile = ({ foPools }) => {
        foPools.sort((x, y) => { return x.instNo - y.instNo; });
        let cssIdx  = 0;
        return <section className="TSP">
            <Coupon title={t('LB_FB_TITLE_TSP')} handleOnClick={toggleTable} isChecked={expandTables["TSP"]} betType="TSP" />
            {expandTables["TSP"] && <div className={`tourn-result-table-tsp-m`}>
                {foPools.map((foPool) => {
                    let refundAll = foPool.status.indexOf("REFUND")>=0;
                    let winCombs = foPool.lines[0].combinations.filter(x=> x.status==="WIN").sort((a,b) => a.winOrd - b.winOrd);
                    let rfdCombs = foPool.lines[0].combinations.filter(x=> x.status.indexOf("REFUND")>=0);
                    let cssBg = cssIdx++%2===0 ? 'grey': '';
                    return <>
                        <div className={`cell tourn-result-left`}>{t('LB_FB_RESULT_SPC_ITEM')} {foPool.instNo}. {foPool["name_"+i18n.language]}</div>
                        <div className={`cell tourn-result-left grey`}>{refundAll ?
                            <span className="refund-for-all">{t('LB_FB_REFUND_FOR_ALL')}</span>
                            : <div className="tourn-result-table-tsp-answer">
                                <div className="gp-small-title">{t('LB_FB_TOURNRES_TSP_HEADER_M')}</div>
                                <div>
                                    {winCombs.map(comb => {
                                        return <div>{comb.selections[0].str}. {comb.selections[0]["name_"+i18n.language]}</div>
                                    })}
                                    {rfdCombs.map(comb => {
                                        return <div>{comb.selections[0].str}. {comb.selections[0]["name_"+i18n.language]} <span className="refund-for-all">- {t('LB_FB_RFD')}</span></div>
                                    })}
                                </div>
                            </div>}
                        </div>
                    </>
                })}
                </div>
            }
        </section>
    }

    const AllTournamentTable = () => {
        return AllTournamentOrder.map((oddsType) => {
            let foPools = tournament.foPools.filter(x => x.oddsType===oddsType && (["PAYOUTSTARTED"].includes(x.status) || x.status.includes('REFUND')));
            if (foPools.length>0) {
                switch (oddsType) {
                    case "CHP":
                        return <GeneralTorunResultTable foPool={foPools[0]} betType="CHP" />
                    case "GPW":
                        return isMobile ? <GPResultTableMobile foPools={foPools} betType="GPW" /> : <GPWResultTable foPools={foPools} />;
                    case "GPF":
                        return isMobile ? <GPResultTableMobile foPools={foPools} betType="GPF" /> : <GPFResultTable foPools={foPools} />;
                    case "TPS":
                        return <GeneralTorunResultTable foPool={foPools[0]} betType="TPS" />;
                    case "TSP":
                        return isMobile ? <TSPResultTableMobile foPools={foPools} /> : <TSPResultTable foPools={foPools} />;
                    case "TQL":
                        return <TQLResultTable foPools={foPools} />;
                }
            } else {
                return null;
            }
        })
    }

    return <>
        <ResultHeader />
        {tournament==null || tournament.foPools.length==0 ?
        <div className='nopool'>
            <div className='nopoolmsg'>
                {t('LB_FB_CHPRES_NORESULT')}
            </div>
        </div>
        :
        <>
            <div className='tourn-result-mobile-title'>{tournament.frontEndId} {tournament["name_"+i18n.language]}</div>
            <section className="TOURNRES">
                <AllTournamentTable />
                <div className="chpres-line"></div>
                <ResultFooter />
            </section>
        </>
        }
        </>
}
export default TOURNRES;