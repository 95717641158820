import { updateTV } from "./parseUpdMatchAdm";
import { pushLiveEvtFun } from "./common";

export const createListData =(newMatch)=>{
    let listData = {
        awayTeam : newMatch.awayTeam,
        featureMatchSequence : newMatch.featureMatchSequence,
        featureStartTime : newMatch.featureStartTime,
        frontEndId : newMatch.frontEndId,
        homeTeam : newMatch.homeTeam,
        id : newMatch.id,
        kickOffTime : newMatch.kickOffTime,
        matchDate : newMatch.matchDate,
        poolInfo : newMatch.poolInfo,
        tournament : newMatch.tournament,
        venue : newMatch.venue
    }
    return listData;
}

export const createSelectionData =(tTour,seq)=>{
    // console.debug("tTour",tTour);
    let toursIDArr = [];
    toursIDArr.push(tTour.id);
    let tourId ={
        code : tTour.code,
        nameProfileId : tTour.nameProfileId.toString(),
        name_ch : tTour.name_ch,
        name_en : tTour.name_en,
        sequence : seq,
        tournIds : toursIDArr
    }
    return tourId;
}

export const createMatch = (message, topicInfo, contentRef)=>{
    let awayTeam =  {
        id :  message.aTeam.id,
        name_ch : message.aTeam.nm.filter(x=> x.ln == "zh-hk")[0].sNm,
        name_en : message.aTeam.nm.filter(x=> x.ln == "en-us")[0].sNm,
    };
    let homeTeam =  {
        id :  message.hTeam.id,
        name_ch : message.hTeam.nm.filter(x=> x.ln == "zh-hk")[0].sNm,
        name_en : message.hTeam.nm.filter(x=> x.ln == "en-us")[0].sNm,
    };
   
    let tour = contentRef.current.rawTournList.filter(x=> x.id == topicInfo.tournamentid)[0];

    let tvChannelsArr = [];
    if ( message.admOpt?.mediaChl?.tVChl?.prog ) {
        for(let i=0 ; i< message.admOpt.mediaChl.tVChl.prog.length; i++){
            tvChannelsArr.push(updateTV(message.admOpt.mediaChl.tVChl.prog[i]));
        }
    }

    let rmk = null;
    if ( message?.rmk?.[0]?.typ ) {
        rmk = { typ: message?.rmk?.[0]?.typ };
    }

    let entInfo = [];
    let inplayPools = [];
    let normalPools = [];
    let ntsInfo = [];
    let sellingPools = [];
    let definedPools = [];

    let poolInfo ={
        entInfo :entInfo,
        inplayPools :inplayPools,
        normalPools :normalPools,
        ntsInfo :ntsInfo,
        sellingPools :sellingPools,
        definedPools
    }
    let chlInfoSta;
    if(message.chlInfo!=null){
        chlInfoSta =message.chlInfo.filter(x=> x.chl == "InteractiveService")[0].st == "Available";
    }
    let tempMatch =  {
        adminOperation: { remark : rmk },
        awayTeam : awayTeam,
        featureMatchSequence : '',
        featureStartTime : null,
        frontEndId : message.frontEndId,
        homeTeam : homeTeam,
        id : message.id.toString(),
        inplayDelay : message.ref?.inplayDelay,
        isInteractiveServiceAvailable : chlInfoSta,
        kickOffTime : message.kOTm,
        liveEvents : [pushLiveEvtFun(message)],
        matchDate : message.bizDt.substring(0,10) + '+08:00',
        poolInfo : poolInfo,
        sequence: message.seq,
        status : message.st.toUpperCase(),
        tournament : {...tour},
        tvChannels : tvChannelsArr,
        updateAt : message.stUpdAt,
        venue : message.neGd && message.ven ? {
            code : message.ven.code,
            name_ch : message.ven.nm.find(x=>x.ln == "zh-hk").val,
            name_en : message.ven.nm.find(x=>x.ln == "en-us").val

        } : null,
        esIndicatorEnabled : message.isESOffer
    };

    return tempMatch;
}