import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../Common/CommonHooks';
import FootballTopBlock from '../Football/Common/FootballTopBlock';
import MarksixTopBlock from '../Marksix/Common/MarksixTopBlock';
import RacingTopBlock from '../Racing/Common/RacingTopBlock';
import { Swipe } from '../Common/ui-components/NewsBanner/swipe';
import { loadBanner } from '../Common/ui-components/NewsBanner/bannerAdScript';

const Banner = (props) => {
    const [news, setNews] = useState();
    const { i18n } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const bannerProcess = useRef(0);
    const tmpBanner = useRef([]);
    const [bannerArr, setBannerArr] = useState([]);

    // get meeting list when didmount
    useEffect(() => {
        loadNews()
    }, []);

    useEffect(()=> {
        let lang = i18n.language==="ch" ? "C" : "E";
        tmpBanner.current = [];
        bannerProcess.current = 0;
        setBannerArr([]);
        window.bannerAdIds[getProductCode(props.Product)].forEach((item, idx) => {
            loadBanner(item + lang).onload = () => {
                let bannerAdList = window['sdceo_getZoneBanner_' + item + lang]?.(1)?.BannerAdList || [];
                if ( bannerAdList.length > 0 ) {
                    bannerAdList[0].idx = idx;
                }
                tmpBanner.current.push(...bannerAdList);
                bannerProcess.current++;
                if ( bannerProcess.current===window.bannerAdIds[getProductCode(props.Product)].length ) {
                    tmpBanner.current.sort((x,y) => x.idx - y.idx);
                    setBannerArr(tmpBanner.current);
                }
            };
        });
    }, [i18n.language]);

    const getProductCode = (prod) => {
        switch (prod) {
            case "racing":
                return "HR";
            case "football":
                return "FB";
            case "marksix":
                return "LY";
        }
        return "GL";
    }

    const loadNews = () => {
        if (props.Product) {
            let temp = null;
            switch (props.Product) {
                case 'racing':
                    temp = <RacingTopBlock />
                    break;
                case 'football':
                    temp = <FootballTopBlock />
                    break;
                case 'marksix':
                    temp = <MarksixTopBlock />
                    break;
                default:
                    temp = geneWinNews();
            }
            setNews(temp);
        }
    };

    const geneWinNews = () => {
        return <div>ewin banner</div>;
    };

    const Banner = useCallback(({bannerArr})=> {
        return <Swipe bannerArr={bannerArr} />
    }, [])

    let infoWidth=document.querySelector('.leftMenuMain')?.offsetWidth || 222;
    infoWidth = Math.max(198, infoWidth)
    return !window.globalConfig.IS_EWIN2 ?
        <div id="topBannerDiv" className={`topBanner ${props.enable ? 'show' : 'hide'}`}>
            <div id="divTopBanner">
                <div id="info_top_block">{!isMobileSize && news}</div>
                <div className="info_top_promo" style={{width:`calc(100% - ${isMobileSize?0:infoWidth}px)`}}>
                    <Banner key={JSON.stringify(bannerArr)} bannerArr={bannerArr}/>
                </div>
            </div>
        </div>
    : null;
};
export default Banner;
