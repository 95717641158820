import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import ArrowIcon from '../ArrowIcon';
import './index.scss';

const Select = ({ selectOption, onChange, value }) => {
    return (
        <div className="select ddNoRSpace">
            <DropdownButton
                title={
                    <>
                        {parseInt(value, 10) === 1 ? '---' : value}
                        <ArrowIcon direction='down' size={24}/>
                    </>
                }
                align="start"
                value={value}
            >
                {selectOption.map((item, index) => {
                    return (
                        <Dropdown.Item
                            value={item.value}
                            onClick={() => onChange(item.value)}
                            active={item.value === value}
                            key={index}
                        >
                            {item.label}
                        </Dropdown.Item>
                    );
                })}
            </DropdownButton>
        </div>
    );
};

export default Select;
