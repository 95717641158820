const getDistanceOfPageTop = (element) => {
    const scrollOnWindow =
        window.pageYOffset !== undefined
            ? window.pageYOffset
            : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    const rect = element.getBoundingClientRect();
    let top = rect?.top || 0;
    if (scrollOnWindow !== 0) {
        top = rect.top + scrollOnWindow;
    }
    return top;
};

export default getDistanceOfPageTop