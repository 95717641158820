import React from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmLoading = ({ props }) => {
    const { t } = useTranslation();

    return (
        <div className="ft-transaction">
            <div className="mrB24 load-icon" />
            <div>{t('LB_BS_FT_TRANSACTIONLOADING')}</div>
        </div>
    );
};

export default ConfirmLoading;
