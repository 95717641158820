import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import FOList, { isRefund } from '../FO/FO_List';
import FOIconList from '../FO/FO_IconList';
import { RacingContext } from '../../Home/RacingPage';
import { useWindowSize } from '../../Common/CommonHooks';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import '../../../css/racing/fo.scss';
import Loading from '../../Common/ui-components/Loading';

// Trainer's Ranking
// Trainer's List

const OddsFOBase = () => {
    const { t, i18n } = useTranslation();
    const {lang} = useParams();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    let foPool = mtg.foPools.filter((x) => x.oddsType == context.content.page)[0];

    function FoRemarks() {
        if (!foPool || isRefund(foPool)) return null;
        return (
            <div className="foNotes">
                <b>{t('LB_RC_' + context.content.page + '_REMARKS_NOTES')}</b>
                <br />
                {t('LB_RC_' + context.content.page + '_REMARKS_MSG')}
            </div>
        );
    }

    const { isMobileSize } = useWindowSize();
    const columnsCountBreakPoints = isMobileSize ? { 320: 1 } : { 350: 2 };

    useEffect(() => {
        context?.foUserContent.loadFOUserContent();
    }, [i18n.language])

    if (context == null) {
        return <Loading />;
    } else {
        let prefix = 'ICON_';
        return (
            <>
                <section className={context.content.page}>
                    <RefreshTime product="racing" />
                    <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints} style={{ margin: '12px 0px' }}>
                        <Masonry className="masonry-col-fo">
                            <>
                                <FOIconList betType={context.content.page} prefix={prefix} />
                            </>
                            <>
                                <FOList betType={context.content.page} />
                            </>
                        </Masonry>
                    </ResponsiveMasonry>
                    <FoRemarks />
                </section>
            </>
        );
    }
};

export default OddsFOBase;
