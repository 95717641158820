import { GetChannelParas, GetBetslipConfig, GetRaceConfig } from './ConfigHelper';

export const GetOnlinePara = (key) => {
    let onlinepara = GetChannelParas()?.WebPara?.onlinepara;
    if ( onlinepara==null )
        return null;
    return onlinepara[key];
}

export const GetChannelPara = (key) => {
    /*
    let channelpara = GetChannelParas()?.WebPara?.channelpara;
    if ( channelpara==null )
        return null;
    */
    return window.sessionStorage.getItem(key);
}

export const IsBetTypeEnabled = (betType) => {
    let bsConfig = GetBetslipConfig();
    let isET = window.fbConfig['ExtraTimePools'].includes(betType)
    return (isET ? GetChannelPara('ExtraTime') == 1 : true) && GetChannelPara(bsConfig.channelParaBetTypeMapping[betType])==1;
}

export const IsFlexibetEnabled = (betType) => {
    let bsConfig = GetBetslipConfig();
    let rcConfig = GetRaceConfig();

    if ( "WP"===betType ) {
        return GetChannelPara("FlexiBet")==1 
        && GetChannelPara(bsConfig?.channelParaFlexibetMapping["WIN"])==1
        && GetChannelPara(bsConfig?.channelParaFlexibetMapping["PLA"])==1
    }
    if ( "WPQ"===betType ) {
        return GetChannelPara("FlexiBet")==1 
        && GetChannelPara(bsConfig?.channelParaFlexibetMapping["QIN"])==1
        && GetChannelPara(bsConfig?.channelParaFlexibetMapping["QPL"])==1
    }

    if (rcConfig.ALUP_PAGE.includes(betType)) {
        betType = "ALUP";
    }
    return GetChannelPara("FlexiBet")==1 && GetChannelPara(bsConfig?.channelParaFlexibetMapping[betType])==1;
}