import React, { useEffect, useState } from 'react';
import WithdrawalBegins from './WithdrawalBegins';
import WithdrawalPreview from './WithdrawalPreview';
import WithdrawalPreviewValidation from './WithdrawalPreviewValidation';
import WithdrawalResult from './WithdrawalResult';
import './index.scss';

const OnlineWithdrawal = ({
    loginAccount,
    nbaData,
    FPSErrorList,
    setResultShowStatus,
    loadingStatus,
    setLoadingStatus,
    updateBetSlipBalance,
    setTab,
    goToLinkBAPage,
    fpsBannerQuery,
    fpsMessageQuery,
    isReturnErrorCode,
    saveEpsNotice,
    setSaveEpsNotice
}) => {
    const [withdrawalStep, setWithdrawalStep] = useState(0);
    const [withdrawalParams, setWithdrawalParams] = useState({
        formParams: {},
        amount: '',
        otherMeansEnable: true
    });
    const [transactionMethodList, setTransactionMethodList] = useState([]);
    const [postWithdrawalData, setPostWithdrawalData] = useState({});

    const updateWithdrawalParams = (newObj) => {
        const newParams = {};
        if (newObj.formParams) {
            newParams.formParams = newObj.formParams;
        }
        if (newObj.amount) {
            newParams.amount = newObj.amount;
        }
        if (newObj.otherMeansEnable != null) {
            newParams.otherMeansEnable = newObj.otherMeansEnable;
        }
        setWithdrawalParams({ ...withdrawalParams, ...newParams });
    };

    const resetWithdrawalParams = () => {
        setWithdrawalParams({
            ...withdrawalParams,
            amount: '',
            otherMeansEnable: true
        });
    };

    const props = {
        setLoadingStatus,
        loginAccount,
        withdrawalParams,
        updateWithdrawalParams,
        nbaData,
        transactionMethodList,
        setWithdrawalStep,
        setCurStep: setWithdrawalStep,
        setTransactionMethodList,
        FPSErrorList,
        postWithdrawalData,
        setPostWithdrawalData,
        loadingStatus,
        setResultShowStatus,
        updateBetSlipBalance,
        setTab,
        goToLinkBAPage,
        resetWithdrawalParams,
        fpsBannerQuery,
        fpsMessageQuery,
        isReturnErrorCode,
        setSaveEpsNotice,
        saveEpsNotice
    };

    return (
        <div className="onlineWithdrawalContainer">
            {withdrawalStep === 0 && <WithdrawalBegins {...props} />}
            {withdrawalStep === 1 && <WithdrawalPreview {...props} />}
            {withdrawalStep === 2 && <WithdrawalPreviewValidation {...props} />}
            {withdrawalStep === 3 && <WithdrawalResult {...props} />}
        </div>
    );
};

export default OnlineWithdrawal;
