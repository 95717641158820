import React,{ useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { TournImage, FlagTournIcon } from './MatchBase'
import { filterMatchData } from './ResultsBase';
import { getDayOfWeekShort } from '../../Common/home-common';
import { toDateFormat } from './Common';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const TableHeader = ({isInProgress, t}) => {

    return <div className='table-row table-header'>
        {!isInProgress && <div className='table-cell date-cell'>{t('LB_FB_BY_DATE')}</div>}
        <div className='table-cell matchNo-cell'>{t('LB_FB_EVENT_ID')}</div>
        <div className='table-cell flag-cell'>
            <FlagTournIcon />
        </div>
        <div className='table-cell had-name'>{t('LB_FB_TEAM')} ({t('LB_FB_HOME_VS_AWAY')})</div>
        {!isInProgress && <div className='table-cell half-time-cell'>{t('LB_FB_INPLAY_CLOCK_ALT_HT')}</div> }
        {!isInProgress && <div className='table-cell full-time-cell'>
            {t('LB_FB_INPLAY_CLOCK_ALT_FT')}<br/>
            <span>[ {t('LB_FB_INPLAY_CLOCK_ALT_ET')} ]</span>
        </div> }
        {!isInProgress && <div className='table-cell detail-cell'>{t('LB_FB_RESULT_RESULTS_DETAILS')}</div> }
        {isInProgress && <div className='table-cell'>{t('LB_FB_TITLE_FGS')}</div>}
        <div className='table-cell last-odds-cell'>{t('LB_FB_RESULT_LAST_ODDS')}</div>
    </div>
}

const TableHeaderMobile = ({isInProgress, t, title}) => {

    return <div className={`table-header-mobile table-row ${isInProgress ? 'resultInProgressTable' : ''}`}>
        <div className='table-cell'>
            {isInProgress ? '' :title}
        </div>
        {isInProgress && <div className='table-cell first-scorer'>{t('LB_FB_TITLE_FGS')}</div>}
        {!isInProgress && <div className='table-cell half-time-cell'>{t('LB_FB_INPLAY_CLOCK_ALT_HT_M')}</div>}
        {!isInProgress && <div className='table-cell full-time-cell'>
            {t('LB_FB_INPLAY_CLOCK_ALT_FT_M')}<br/>
            <span>[{t('LB_FB_INPLAY_CLOCK_ALT_ET_M')}]</span>    
        </div>}
        {!isInProgress && <div className='table-cell result-detail'>{t('LB_FB_RESULT_RESULTS_DETAILS')}</div>}
        <div className='table-cell last-odds'>{t('LB_FB_RESULT_LAST_ODDS')}</div>
    </div>
}


const TableRow = ({matchData, className, isInProgress, onClickDetail, onClickLastOdds}) => {
    const {t, i18n} = useTranslation()
    const {
        eventId,
        halfTime,
        fullTime,
        extraTime,
        halfVoid,
        fullVoid,
        extraVoid,
        tournCode,
        tournName,
        matchDate,
        h2h,
        enableDtlsBtn,
        matchId
    } = filterMatchData(matchData, t, i18n);

    return <div className={`table-row ${className}`}>
        {!isInProgress && <div className='table-cell date-cell'>{matchDate}</div>}
        <div className='table-cell matchNo-cell'>{eventId}</div>
        <div className='table-cell flag-cell' ><TournImage code={tournCode} name={tournName} /></div>
        <div className='table-cell had-name'>{h2h}</div>
        {/* {isInProgress && <div className='table-cell'>{matchDate}</div>} */}
        {!isInProgress && <div className={`table-cell half-time-cell ${halfVoid ? 'void-half-time' : 'half-time'}`}>
            <span>{halfVoid ? t('LB_FB_RESULT_VOID_MATCH') : halfTime}</span>
        </div>}
        {!isInProgress && <div className='table-cell full-time-cell'>
            <span className={fullVoid ? 'extra-time-red' : 'full-time'}>
                {fullVoid ? t('LB_FB_RESULT_VOID_MATCH') : (!halfVoid ? fullTime : '')}
            </span><br />
            <span className={extraVoid ? 'extra-time-red' : 'extra-time-orange'}>
                {extraVoid ? `[${t('LB_FB_RESULT_VOID_MATCH')}]` : (extraTime!='-' ? `[ ${extraTime} ]` : '')}
            </span>
        </div>}
        {!isInProgress && <div className='table-cell detail-cell'>
        <div className={`detail-btn ${!enableDtlsBtn ? 'disable-detail-btn' : ''}`} onClick={()=> enableDtlsBtn && onClickDetail(matchId)}></div>  
        </div>}
        {isInProgress && <div className='table-cell first-scorer'>{t('LB_FB_TITLE_RESULTS_MIP')}</div>}

        <div className='table-cell last-odds last-odds-cell'>
            <div onClick={() => onClickLastOdds(matchId, isInProgress)}>
             %
            </div>
        </div>
    </div>
}

const TableRowMobile = ({matchData, className, isInProgress, onClickDetail, onClickLastOdds})=> {
    const {t, i18n} = useTranslation()
    const {
        eventId,
        halfTime,
        fullTime,
        extraTime,
        halfVoid,
        fullVoid,
        extraVoid,
        tournName,
        matchDate,
        hName,
        aName,
        enableDtlsBtn,
        matchId
    } = filterMatchData(matchData, t, i18n);

    return<div className={`table-row ${className}`}>
        <div className='table-cell result-match-info'>
            <div >
                <div>{eventId} {tournName}</div>
                <div className='team-name'>{hName}</div>
                <div className='team-name'>{aName}</div>
            </div>
            <div className='match-date'>{matchDate} <div className='match-date-triangle'></div></div>
        </div>

        {isInProgress && <div className='table-cell'>{t('LB_FB_TITLE_RESULTS_MIP')}</div>}


        {!isInProgress && <div className={`table-cell ${halfVoid ? 'void-half-time' : 'half-time'}`}>
            <span>{halfVoid ? t('LB_FB_RESULT_VOID_MATCH') : halfTime}</span>
        </div>}
        {!isInProgress && <div className='table-cell'>
            <span className={`${fullVoid ? 'extra-time-red' : 'full-time'}`}>
                {fullVoid ? t('LB_FB_RESULT_VOID_MATCH') : (!halfVoid ? fullTime : '')}
            </span>
            {(extraVoid || extraTime!='-') && <span  className={`${extraVoid ? 'extra-time-red' : 'extra-time-orange'} extra-time`}>
                {extraVoid ? `[${t('LB_FB_RESULT_VOID_MATCH')}]` : (extraTime!='-' ? `[ ${extraTime} ]` : '')}
            </span>}
        </div>}
        {!isInProgress && <div className='table-cell'>
            <div className={`detail-btn ${!enableDtlsBtn ? 'disable-detail-btn' : ''}`} onClick={()=>enableDtlsBtn && onClickDetail(matchId)}></div>
        </div>}
        <div className='table-cell last-odds last-odds-cell'>
            <div onClick={() => onClickLastOdds(matchId, isInProgress)}> % </div>
        </div>
    </div>
}

const MRTable = ({date, match, isInProgress, onClickDetail, onClickLastOdds}) => {
    const {t} = useTranslation()
    const [isOpen, setIsOpen] = useState(true)
    const handleClickExpand = () => {
        setIsOpen(!isOpen)
    }


    const title = `${toDateFormat(date, true, false)} (${getDayOfWeekShort(date, t)}) ${t('LB_FB_MATCH')}`
    return < div className='MRTable-container'>
        <header onClick={handleClickExpand}>
            <div className={`arrow-area `}><ArrowIcon  className='hueRotate' direction='down' change={isOpen} changeDirection='up' size={20} /></div>
            <span>{title}</span>
        </header>
        {isOpen && <div className='fb-results-mip-table'>
            {match.map((item, index) => <TableRow matchData={item} onClickDetail={onClickDetail} onClickLastOdds={onClickLastOdds}
        className={index %2 ? 'sec' : ''}
        isInProgress={isInProgress} key={item.frontEndId}/>)}
        </div>}
    </ div>
}

const  MRTableMobile = ({date, match, isInProgress, onClickDetail, onClickLastOdds}) => {
    const {t} = useTranslation()
   
    const title = `${toDateFormat(date, true, false)} (${getDayOfWeekShort(date, t)}) ${t('LB_FB_MATCH')}`
    return  <div className='fb-results-mip-table-m'>
        <TableHeaderMobile isInProgress={isInProgress} t={t} title={title}/>
        {match.map((item, index) => <TableRowMobile matchData={item} onClickDetail={onClickDetail} onClickLastOdds={onClickLastOdds}
    className={index %2 ? 'sec' : ''} isInProgress={isInProgress}
    key={item.frontEndId}/>)}
    </div>
}

const MIPTable = ({matches, isInProgress, t, handleClickLastOddsBtn}) => {

    return <div className='fb-results-mip-table'>
        <TableHeader isInProgress={isInProgress} t={t}/>
        {matches.map((item, index)=> 
        <TableRow matchData={item} className={index %2 ? 'sec' : ''} isInProgress={isInProgress} key={item.frontEndId}
        onClickLastOdds={handleClickLastOddsBtn}/> )
        }
    </div>
}
const MIPTableMobile = ({matches, isInProgress, t, handleClickLastOddsBtn}) => {

    return <div className='fb-results-mip-table-m'>
        <TableHeaderMobile isInProgress={isInProgress} t={t} title={t('LB_FB_TITLE_RESULTS_MIP')}/>
        { 
        matches.map((item, index)=> 
        <TableRowMobile matchData={item} className={index %2 ? 'sec' : ''} isInProgress={isInProgress}
        key={item.frontEndId} onClickLastOdds={handleClickLastOddsBtn}/>)
        }
    </div>
}


const MatchResultsTable = ({matchResultsData, isInProgress, t, handleClickDetailBtn, handleClickLastOddsBtn}) => {


    return <div className='fb-results-match-results'>
        <TableHeader isInProgress={isInProgress} t={t}/>
        {Object.keys(matchResultsData).map(key => {
        return <MRTable date={key} match={matchResultsData[key]} onClickDetail={handleClickDetailBtn} onClickLastOdds={handleClickLastOddsBtn} isInProgress={isInProgress} key={key}/>
    })}
    </div>

}

const MatchResultsTableMobile = ({matchResultsData, isInProgress, t, handleClickDetailBtn, handleClickLastOddsBtn}) => {


    return <div className='fb-results-match-results-m'>
        {Object.keys(matchResultsData).map(key => {
        return <MRTableMobile date={key} match={matchResultsData[key]} onClickDetail={handleClickDetailBtn} onClickLastOdds={handleClickLastOddsBtn} isInProgress={isInProgress} key={key}/>
        })}
    </div>
}

const ResultsTable = ({matches, isInProgress, handleClickDetailBtn, handleClickLastOddsBtn}) => {
    const {t} = useTranslation()
    const [matchResultsData, setMatchResultsData] = useState([])
    
    useEffect(()=>{
        if(!isInProgress){
            let matchList = matches.reduce((init, next) => {
                let key = next.kickOffTime.substring(0,10);
                init[key] = init[key] || [];
                init[key].push(next);
                return init;
            }, {});
            setMatchResultsData(matchList)
        }

    },[matches])

    const MipTableProps = {
        matches, isInProgress, t, handleClickLastOddsBtn
    }
    const tableProps={
        matchResultsData, isInProgress, t, handleClickDetailBtn, handleClickLastOddsBtn
    }
    
    return <div className='fb-results-mip-table-container'>
        {isInProgress ? 
        <>
        <MIPTable {...MipTableProps}/>
        <MIPTableMobile {...MipTableProps}/>
        </>
        :
        <>
        <MatchResultsTable {...tableProps}/>
        <MatchResultsTableMobile {...tableProps}/>
        </>
        }
        
    </div>
}

export default ResultsTable