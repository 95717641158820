import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactRadio from '../../../Common/ui-components/ReactRadio';
import AmountInput from '../../../Common/ui-components/AmountInput';
import { numberDesensitizeFn } from '../../../Common/GlobalFunc';
import { ConfigContext } from '../../../Common/ConfigHelper';
import { textFormatAmountFn } from '../../../Common/GlobalFunc';
import FPSBanner from '../components/FPSBanner';
import ArrowIcon from '../../../Common/ui-components/ArrowIcon';
import { formatServiceCharge } from '../utils';
import EpsNotice from '../EpsNotice';

const WithdrawalBegins = ({
    loginAccount,
    setTransactionMethodList,
    transactionMethodList,
    withdrawalParams,
    goToLinkBAPage,
    updateWithdrawalParams,
    setWithdrawalStep,
    nbaData,
    fpsBannerQuery,
    setSaveEpsNotice,
    fpsMessageQuery
}) => {
    const { t, i18n } = useTranslation();
    const [bankInfo, setBankInfo] = useState({
        name_en: '-',
        name_ch: '-',
        bank_account: 1234567890,
        status: '1'
    });

    const [showOtherMeans, setShowOtherMeans] = useState(false);
    const [hideOtherMeansBtn, setHideOtherMeansBtn] = useState(true);
    const [hideRadio, setHideRadio] = useState(true);
    const { betslipMenu } = useContext(ConfigContext);
    const [curTransactionMethodList, setCurTransactionMethodList] = useState([]);
    const [proceedEft, setProceedEFT] = useState(false);
    const [showReminder, setShowReminder] = useState({ open: false, status: '', params: {} });
    const [epsChargeAmt, setEpsChargeAmt] = useState(0);

    const otherMeansEnable = betslipMenu?.withdrawalOtherMeansEnabled;

    const nba1 = nbaData?.nba1;
    const config = nbaData?.config || {};
    const FPSConfig = config?.FPS || {};
    const EPSConfig = config?.EPS || {};
    const AUTOPAYConfig = config?.AUTOPAY || {};
    const fpsEFTMessage = fpsMessageQuery?.jcbwControl?.fpsBannerControl?.fpsEFTMessage['name_' + i18n.language]?.replace(/\n/g,'<br/>');

    useEffect(() => {
        if (!nbaData?.nba1) return;
        const bankData = nbaData.nba1;
        let trMethodList = hasMethodFn(nbaData);
        let curMethodList = trMethodList.filter((i) => !(i.method === 'AUTOPAY' && i.disabled));

        if (trMethodList.length > 1 && nba1.FPS.show && !nba1.FPS.disabled) {
            setHideOtherMeansBtn(curMethodList.length == 1);
            setHideRadio(curMethodList.length == 1);
        } else {
            setHideOtherMeansBtn(true);
            setShowOtherMeans(true);
            setHideRadio(curMethodList.length == 1);
        }

        setTransactionMethodList(trMethodList);
        let defaultSelect = trMethodList.find((i) => !i.disabled);
        if (!defaultSelect) {
            if (nba1.FPS?.show && nba1?.AUTOPAY.disabled) {
                defaultSelect = trMethodList.find((i) => i.method === 'FPS');
            } else {
                defaultSelect = trMethodList.find((i) => i.method === 'AUTOPAY');
            }
        }
        if (withdrawalParams.formParams.method) {
            defaultSelect = withdrawalParams.formParams;
        }
        updateWithdrawalParams({ formParams: defaultSelect, otherMeansEnable });
        setCurTransactionMethodList([defaultSelect]);
        if (defaultSelect?.method !== trMethodList[0]?.method) {
            setShowOtherMeans(true);
        }

        setBankInfo({
            bank_account: bankData.bankAccountNo,
            ...bankData.bankInfo
        });
    }, []);

    useEffect(() => {
        if (showOtherMeans) {
            setCurTransactionMethodList(transactionMethodList);
        } else {
            const singleData = transactionMethodList.find(
                (item) => item.method === withdrawalParams?.formParams?.method
            );
            setCurTransactionMethodList([singleData]);
        }
    }, [showOtherMeans, transactionMethodList]);

    useEffect(() => {
        if (proceedEft) {
            setWithdrawalStep(1);
        }
    }, [proceedEft]);

    const singleLegMsg = (
        <div className="singleLegMsg">
            *{t('LB_BS_FT_SINGLELEG_FPSMSG1')}
            <span
                className="text-underline"
                onClick={() => {
                    handleClickHereBtn();
                }}
            >
                {t('LB_BS_FT_SINGLELEG_FPSMSG2')}
            </span>
            {t('LB_BS_FT_SINGLELEG_FPSMSG3')}
        </div>
    );

    const hasMethodFn = (nbaData) => {
        const methods = ['FPS', 'EPS', 'AUTOPAY'];
        const curMethods = [];
        const nba1 = nbaData.nba1;

        methods.forEach((method) => {
            if (nba1[method]?.show) {
                let radioRemark = t(`LB_BS_FT_${method}_REMARK1`);
                let inputRemark = t(`LB_BS_FT_${method}_REMARK2`);
                let chargeRemark = t('LB_BS_FT_EPS_REMARK5');
                let minAmount;
                let maxAmount;
                const TBMaxAutoWithdrawal = textFormatAmountFn(AUTOPAYConfig?.TBMaxAutoWithdrawal, 0);
                if (method === 'FPS') {
                    const perWdrChargeAmt = formatServiceCharge(FPSConfig.perWdrChargeAmt);
                    const perWdrMinAmt = textFormatAmountFn(FPSConfig.perWdrMinAmt, 0);
                    const perWdrMaxAmt = textFormatAmountFn(FPSConfig.perWdrMaxAmt, 0);
                    chargeRemark = FPSConfig.perWdrChargeAmt ? `\n${chargeRemark.replace('{0}', perWdrChargeAmt)}` : '';
                    if (nba1[method].disabled) {
                        radioRemark = <span className="method-dim" dangerouslySetInnerHTML={{__html: fpsEFTMessage?.length > 0? fpsEFTMessage: t(`LB_BS_FT_FPS_REMARK3`)}}></span>;
                    } else {
                        radioRemark = (
                            <>
                                {radioRemark.replace('{0}', perWdrMaxAmt).replace('{1}', perWdrChargeAmt)}
                                <span className="contentBlue">{chargeRemark}</span>
                            </>
                        );
                    }
                    if (nba1[method].isSingleLeg) {
                        radioRemark = (
                            <>
                                {radioRemark}
                                {singleLegMsg}
                            </>
                        );
                    }
                    minAmount = FPSConfig.perWdrMinAmt;
                    maxAmount = FPSConfig.perWdrMaxAmt;
                    inputRemark = inputRemark
                        .replace('{0}', perWdrMinAmt)
                        .replace('{1}', perWdrMaxAmt)
                        .replace('{2}', TBMaxAutoWithdrawal);

                    if (!otherMeansEnable) {
                        if (nba1[method].disabled) {
                            inputRemark = fpsEFTMessage?.length > 0? fpsEFTMessage : t(`LB_BS_FT_FPS_REMARK3`);
                        }
                        if (nba1[method].isSingleLeg) {
                            inputRemark = (
                                <>
                                    {inputRemark} {singleLegMsg}
                                </>
                            );
                        }
                    }
                }
                if (method === 'EPS') {
                    const TBMaxWithdrawal = textFormatAmountFn(EPSConfig.TBMaxWithdrawal, 0);
                    const TBMinWithdrawal = textFormatAmountFn(EPSConfig.TBMinWithdrawal, 0);
                    var chargeAmt = 0;
                    if (window.globalConfig.IS_IB) {
                        chargeAmt = nba1.EPS?.hasWithdrawalCharge ? formatServiceCharge(nba1.EPS?.charge) : 0;
                    } else {
                        chargeAmt = nba1.EPS?.hasWithdrawalCharge ? formatServiceCharge(nba1.EPS?.charge / 100) : 0;
                    }
                    setEpsChargeAmt(chargeAmt);

                    chargeRemark = (
                        <span className="contentBlue">
                            {nba1.EPS?.hasWithdrawalCharge && chargeAmt > 0
                                ? `\n${chargeRemark.replace('{0}', chargeAmt)}`
                                : ''}
                        </span>
                    );
                    if (!EPSConfig?.TBMinWithdrawal || EPSConfig?.TBMinWithdrawal <= 0) {
                        radioRemark = (
                            <>
                                {t(`LB_BS_FT_EPS_REMARK3`).replace('{0}', TBMaxWithdrawal)}
                                {chargeRemark}
                            </>
                        );
                    } else {
                        radioRemark = (
                            <>
                                {radioRemark.replace('{0}', TBMinWithdrawal).replace('{1}', TBMaxWithdrawal)}
                                {chargeRemark}
                            </>
                        );
                    }
                    minAmount = EPSConfig.TBMinWithdrawal;
                    maxAmount = EPSConfig.TBMaxWithdrawal;
                    inputRemark = inputRemark
                        .replace('{0}', TBMinWithdrawal)
                        .replace('{1}', TBMaxWithdrawal)
                        .replace('{2}', TBMaxAutoWithdrawal);
                }
                if (method === 'AUTOPAY') {
                    radioRemark = radioRemark.replace('{0}', TBMaxAutoWithdrawal);
                    inputRemark = inputRemark.replace('{0}', TBMaxAutoWithdrawal);
                    minAmount = AUTOPAYConfig?.TBMinAutoWithdrawal;
                    maxAmount = AUTOPAYConfig?.TBMaxAutoWithdrawal;
                }

                curMethods.push({
                    method,
                    disabled: nba1[method].disabled,
                    methodName: t(`LB_BS_FT_${method}`).replace('{0}', t('LB_BS_FT_WITHDRAWAL')),
                    radioRemark,
                    inputRemark,
                    minAmount,
                    maxAmount,
                    bankAccount: nba1.bankAccountNo
                });
            }
        });
        return curMethods;
    };

    const handleClickHereBtn = () => {
        goToLinkBAPage(1);
    };

    const handleClickShowOtherMeans = () => {
        setShowOtherMeans(!showOtherMeans);
    };

    const onChangeTransactionMethod = (value) => {
        if (value.disabled) return;
        updateWithdrawalParams({ formParams: value });
    };

    const onChangeAmount = (value) => {
        updateWithdrawalParams({ amount: value });
    };

    const hasEpscoNoticeCookie = () => {
        var match = document.cookie.match(new RegExp('(^| )' + 'NoEpsNotice_' + loginAccount + '=([true;]+)'));
        if (match) {
            return true;
        } else {
            return false;
        }
    };

    const handleClickNextBtn = () => {
        if (amountDisabled) return;

        if (!withdrawalParams.amount) {
            return alert(t('LB_BS_FT_AMOUNT_ERR_MSG1'));
        }
        let minAmount = parseInt(withdrawalParams?.formParams?.minAmount, 10);
        let maxAmount = withdrawalParams?.formParams?.maxAmount;
        if (!otherMeansEnable) {
            maxAmount = AUTOPAYConfig?.TBMaxAutoWithdrawal;
        }

        if (withdrawalParams.amount < parseInt(minAmount) || withdrawalParams.amount > parseInt(maxAmount)) {
            const amountErrMSg = t('LB_BS_FT_AMOUNT_ERR_MSG3').replace('{0}', minAmount).replace('{1}', maxAmount);
            alert(amountErrMSg);
            return;
        }
        //check EPS service fee notification
        if (
            withdrawalParams.formParams.method === 'EPS' &&
            nba1.EPS?.hasWithdrawalCharge &&
            !hasEpscoNoticeCookie() &&
            betslipMenu?.enableEpsChargeNotification
        ) {
            setShowReminder({ open: true, status: '', params: {} });
            return;
        }
        setWithdrawalStep(1);
    };

    const handleClickClose = () => {
        window.close();
    };

    const getAUTOPAYErrMsg = () => {
        let msg;
        if (nba1.AUTOPAY.disabled) {
            msg = <span className="red">{t('LB_BS_FT_WITHDRAWAL_AUTOPAY_DISABLED2')}</span>;
        }
        return msg;
    };

    const showCurMethod = (item) => {
        if (!item) return;
        if (item.method !== 'AUTOPAY') return true;
        if (transactionMethodList.length > 1) {
            return !item.disabled;
        }
        return true;
    };

    const lang = i18n.language === 'en' ? 'name_en' : 'name_ch';
    const formParams = withdrawalParams.formParams;

    let amountDisabled = nba1?.[formParams.method]?.disabled; //&& withdrawalParams.otherMeansEnable;
    const showCloseBtn = amountDisabled && !nba1.FPS.show && !nba1.EPS.show;

    return (
        <div className="withdrawalBegins-container">
            <div className="FT-form-content">
                <div className="formItem">
                    <div className="formItemLabel">{t('LB_BS_AR_EXPORT_FROM')}:</div>
                    <div className="formItemContent">
                        <div className="contentTop">{t('LB_BS_FT_BETACCOUNTNO')}</div>
                        <div className="contentBottom">{loginAccount}</div>
                    </div>
                </div>
                <div className="formItem">
                    <div className="formItemLabel">{t('LB_BS_AR_EXPORT_TO')}:</div>
                    <div className="formItemContent">
                        <div className="contentTop">{bankInfo[lang]}</div>
                        <div className="contentBottom">{numberDesensitizeFn(bankInfo.bank_account)}</div>
                    </div>
                </div>
                {otherMeansEnable && (
                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_TRANSACTIONMETHOD')}:</div>
                        <div className="formItemContent ">
                            {curTransactionMethodList.map(
                                (item) =>
                                    showCurMethod(item) && (
                                        <div
                                            className={`itemMult ${item.disabled ? 'disabled' : ''}`}
                                            key={item?.methodName}
                                        >
                                            {!hideRadio && (
                                                <ReactRadio
                                                    checked={withdrawalParams?.formParams?.method === item?.method}
                                                    disabled={item.disabled}
                                                    onChange={() => onChangeTransactionMethod(item)}
                                                />
                                            )}
                                            <div className="bankInfo">
                                                <div className="contentTop">{item.methodName}</div>
                                                {item.method === 'AUTOPAY' && getAUTOPAYErrMsg() ? (
                                                    <div className="contentBottom ">{getAUTOPAYErrMsg()}</div>
                                                ) : (
                                                    <div className="contentBottom">{item.radioRemark}</div>
                                                )}
                                            </div>
                                        </div>
                                    )
                            )}
                            {!hideOtherMeansBtn && (
                                <div className="otherMeansBtn" onClick={handleClickShowOtherMeans}>
                                    <span>{showOtherMeans ? t('LB_BS_FT_SHOWLESS') : t('LB_BS_FT_OTHERMEANS')}</span>
                                    <ArrowIcon
                                        className=""
                                        direction="down"
                                        change={showOtherMeans}
                                        changeDirection="up"
                                        size={24}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className="formItem">
                    <div className="formItemLabel">{t('LB_BS_FT_TWITHDRAWALAMOUNT')}:</div>
                    <div className="formItemContent">
                        <div className="contentTop inputContainer">
                            <AmountInput
                                onChange={onChangeAmount}
                                onInput={onChangeAmount}
                                disabled={amountDisabled}
                                min={1}
                                value={withdrawalParams.amount}
                            />
                        </div>
                        {!otherMeansEnable &&
                            (withdrawalParams.formParams.method == 'AUTOPAY' && getAUTOPAYErrMsg() ? (
                                <div className="contentBottom ">{getAUTOPAYErrMsg()}</div>
                            ) : (
                                <div className="contentBottom">{withdrawalParams?.formParams?.inputRemark}</div>
                            ))}
                    </div>
                </div>
            </div>
            <FPSBanner fpsBannerQuery={fpsBannerQuery} />
            <div className="footerBox">
                {showCloseBtn ? (
                    <div className="deposit-btn nextBtn" onClick={handleClickClose}>
                        {t('LB_CLOSE')}
                    </div>
                ) : (
                    <div
                        className={`deposit-btn ${amountDisabled ? 'fpsDim' : 'nextBtn'}`}
                        onClick={handleClickNextBtn}
                    >
                        {t('LB_BS_FT_NEXT')}
                    </div>
                )}
            </div>
            <EpsNotice
                chargeAmtEps={epsChargeAmt}
                setProceedEFT={setProceedEFT}
                loginAccount={loginAccount}
                goToLinkBAPage={goToLinkBAPage}
                bankInfo={bankInfo}
                showReminder={showReminder}
                setShowReminder={setShowReminder}
                setSaveEpsNotice={setSaveEpsNotice}
                linkDisabled={nbaData.config.FPS.linkDisabled}
            ></EpsNotice>
        </div>
    );
};

export default WithdrawalBegins;
