import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '../../Common/CommonHooks';
import { useWindowSize } from '../../Common/CommonHooks';
import { RacingContext } from '../../Home/RacingPage';
import { getSiteCoreImagePath, NewWindow } from '../../Common/home-common';
import useQueryString from '../../Common/hooks/useQueryString';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { useJockeyTrainerRankingQuery } from '../Data/RacingStatHooks';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import {redirectJCRW} from '../Common/Common';

const breakpoints = { Desktop: 1920, cols3: 1420, cols2: 1180 }
const FO_IconList = ({betType}) => {
    const { t, i18n } = useTranslation();
    const queryString = useQueryString();
    const [ jtLoading, jockeyData, trainerData, getLastMeeting ] = useJockeyTrainerRankingQuery();
    const context = useContext(RacingContext);
    let templateName = queryString.get("template");
    let foContent = null;
    if ( window.globalConfig.IS_PREVIEW && templateName ) {
        foContent = context.foUserContent.error ? null : context.foUserContent?.getTemplateByName(betType, templateName);
    }
    if ( !foContent )
        foContent = context.foUserContent.error ? null : context.foUserContent?.getTemplate(betType);
    const foProfiles = context.foUserContent.error ? null : foContent?.children?.filter(x=> x.template.name===window.sitecoreVars.RS_FO_PROFILE_GROUP)[0];
    const [collapse, setCollapse] = useState(true);
    const { isMobileSize } = useWindowSize();
    const breakpoint = useBreakpoint(breakpoints);
    const [cols , setCols ] = useState(4)

    useEffect(() => {
        if(breakpoint === 'cols2') setCols(2);
        else if(breakpoint === 'cols3') setCols(3);
        else if(breakpoint === 'Desktop') setCols(4);
        if(isMobileSize) setCols(4);
    }, [breakpoint, isMobileSize])

    const handleClickCollapse = () => {
        if ( isMobileSize ) {
            setCollapse(!collapse);
        }
    }

    const IconTable = (props) => {
        const {jtLoading, foProfiles } = props
        if ( jtLoading || !foProfiles ) {
            return null;
        }

        return <>
            {foProfiles.seq.value===window.sitecoreVars.USER_INPUT ?
                <IconRowsManualOrder {...props}/>
                :
                <IconRowsSystemOrder {...props}/>
            }
            <IconBottomBanner {...props}/>
            <IconRemarks {...props}/>
        </>;
    }

    const IconRowsManualOrder = (props) => {
        const {foProfiles, cols, betType,jockeyData, trainerData,  handleClickIconItem, foContent  } =props
        let data = betType === "JKC" ? jockeyData: trainerData;
        return <div className={`foProfile${cols}`}>
            {foProfiles.children?.map(item => {
                let cItem = data.filter(x=> x.code===item.pCode.value)[0];
                if ( foContent.dataType.value!=="IJC" && cItem==null ) {
                    return null;
                }
                else if ( item.hkonly?.value=="1" && cItem?.visitingIndex=="Y" ) {
                    return null;
                }
                else if ( item.pEnable.value!=="1" ) {
                    return null;
                }
                return <div className="cell icon" onClick={()=> handleClickIconItem(item)}>
                    <IconItem item={item} {...props}/>
                    <IconItemDesc item={item} {...props}/>
                </div>
            })}
            {foContent.dataType.value!=="IJC" && <IconRowsOutSyncOrder foProfiles={foProfiles} {...props}/>}
        </div>
    }

    const IconRowsSystemOrder = (props) => {
        const {foProfiles, cols, betType,jockeyData, trainerData, i18n, handleClickIconItem  } =props
        let data = betType === "JKC" ? jockeyData: trainerData;
        return <div className={`foProfile${cols}`}>
            {data.map(c => {
                let item = foProfiles.children?.filter(x=> c.code===x.pCode.value)[0];
                if ( foProfiles.hkonly?.value=="1" && c.visitingIndex=="Y" ) {
                    return null;
                }
                else if ( !item ) {
                    return <div className="cell icon">
                        <IconEmptyItem {...props}/>
                        <IconEmptyItemDesc name={c["name_" + i18n.language]} code={c.code} {...props}/>
                    </div>
                }
                else if ( item.pEnable.value==="1" ) {
                    return <div className="cell icon" align="center" onClick={()=> handleClickIconItem(item)}>
                        <IconItem item={item} {...props}/>
                        <IconItemDesc item={item} {...props}/>
                    </div>
                }
                else {
                    return null;
                }
        })}
        </div>
    }

    const IconRowsOutSyncOrder = (props) => {
        const {foProfiles, betType,jockeyData, trainerData, i18n } =props
        let data = betType === "JKC" ? jockeyData: trainerData;
        return data.map(c => {
            let item = foProfiles.children?.filter(x=> c.code===x.pCode.value)[0];
            if ( foProfiles.hkonly?.value=="1" && c.visitingIndex=="Y" ) {
                return null;
            }
            else if ( !item ) {
                return <div className="cell icon">
                    <IconEmptyItem {...props}/>
                    <IconEmptyItemDesc name={c["name_" + i18n.language]} code={c.code} {...props}/>
                </div>
            }
            return null;
        })
    }

    const IconItem = useCallback(({ item, i18n }) => {
        const [requestImgNum,setRequestImgNum]=useState(0);
        useEffect(()=>{
            setRequestImgNum(0)
        },[i18n.language, item])
        return <div className="profile">
            <img className="profile-pic" src={getSiteCoreImagePath(item.pImage.src, i18n.language)} width={item.pImage.width} height={item.pImage.height} title={item.pMOName.value}
                onError={({ currentTarget }) => {
                    if(location.hostname === 'localhost') return
                    if(requestImgNum>0){
                        return false;
                    }else{
                        setRequestImgNum(prev=>prev+1)
                    }
                    currentTarget.onerror = null;
                    currentTarget.src=`${window.globalConfig.SITECORE_IMAGE_URL}${window.sitecorePath.FLAG_PATH_PREFIX}anonymous`;
                }}
            />
            {item.pIcon.src && <img className="profile-icon" src={getSiteCoreImagePath(item.pIcon.src, i18n.language)} width={item.pIcon.width} height={item.pIcon.height} title={item.pIcon.alt}/>}
        </div>
    },[])

    const IconEmptyItem = () => {
        return <div className="profile">
            <img className="profile-pic" src={`${window.globalConfig.SITECORE_IMAGE_URL}${window.sitecorePath.FLAG_PATH_PREFIX}anonymous`} width="60px" height="60px" />
        </div>
    }

    const clickLink = (url) => {
        NewWindow(url, '', 770, 500, 1, 1);
    }

    const clickJockyRecLink = (code) => {
        let url = window.globalConfig.JCRW_URL + t('URL_RC_JOCKY_RECORD') + code;
        glassboxCustomEvent("Jockey_Info");
        redirectJCRW(url);
    }

    const clickTrainerRecLink = (code) => {
        let url = window.globalConfig.JCRW_URL + t('URL_RC_TRAINER_RECORD') + code;
        glassboxCustomEvent("Trainer_Info");
        redirectJCRW(url);
    }

    const handleClickIconItem = (item) =>{
        let link = betType === "JKC" ? clickJockyRecLink : clickTrainerRecLink;
        let para = item.pCode.value;
        if ( item.pRedirect.value===window.sitecoreVars.USER_INPUT) {
            link = clickLink;
            para = item.pImageLink.value;
        }
        link(para)
    }

    const IconItemDesc = ({ item }) => {

        return <div className='name' align="center"><a >{item.pName.value}</a></div>;
    }

    const IconEmptyItemDesc = ({ name, code }) => {
        return <div className='name' align="center">{name}</div>;
    }

    const IconBottomBanner = ({foContent, i18n}) => {
        return foContent.rmkBannerEnable.value==="1" && foContent.rmkBanner.src ? <div>
            <img src={getSiteCoreImagePath(foContent.rmkBanner.src, i18n.language)} width={foContent.rmkBanner.width} height={foContent.rmkBanner.height} title={foContent.rmkBanner.alt}/>
        </div> : null;
    }

    const IconRemarks = ({t,betType, getLastMeeting, foContent}) => {
        let txt = t('LB_RC_' + betType + '_ICON_REMARKS_MSG').replace("{0}", getLastMeeting());  // to be changed in next sprint
        if ( foContent.lastUpdEnable.value==="1" && foContent.lastUpdCfg.value===window.sitecoreVars.USER_INPUT ) {
            txt = foContent.lastUpdText.value;
        }
        return foContent.lastUpdEnable.value==="1" ? <div className="remarks">{txt}</div> : null;
    }

    const IJCContent = ({foContent}) => {
        return <div>
            <div className="foIJCtxt" dangerouslySetInnerHTML={{ __html: foContent.ijcInfoText.value }}></div>
            <div className="foIJCLink"><a href="#" onClick={()=> {NewWindow(foContent.ijcLinkUrl.value)}}>{foContent.ijcLinkText.value}</a></div>
        </div>
    }
    const MainTable = useCallback((props)=> {
        const { foContent, collapse, t, handleClickCollapse} = props
        return foContent ? <div className="iconsection"><div className="icon-list">
            <div className="header" onClick={() => handleClickCollapse()}>
                {foContent.headBannerEnable.value==="1" && <div className="foIconHeader" dangerouslySetInnerHTML={{ __html: foContent.headBanner.value }}>
                </div>}
                <div className="icontitle-m">
                    <a data-bs-toggle="collapse" href={`#collapseIconList`} role="button" aria-expanded="false" aria-controls={`collapseIconList`} >
                        <ArrowIcon className='hueRotate' direction='down' change={!collapse} changeDirection='up' size={20} {...props}/>
                    </a>
                </div>
            </div>
            {foContent.ijcEnable.value==="1" && <IJCContent {...props}/>}
            <div className={`${collapse ? 'collapse' : ''} with-100 collapseIconList`} id="collapseIconList">
                <IconTable {...props}/>
            </div>
        </div></div> : <div className='noinfo'>{t('LB_RC_JTC_NOINFO')}</div>;
    }, [])

    const tableProps = {jtLoading, foContent, collapse, cols, t, handleClickCollapse,
        i18n, betType, getLastMeeting, foProfiles, jockeyData, trainerData,  handleClickIconItem
    }

    return <MainTable {...tableProps}/>
}
export default FO_IconList;