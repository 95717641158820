import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { RacingContext } from '../../Home/RacingPage';
import { handleClickMenuItem } from '../../Menu/MenuCommon';
import { useWindowSize } from '../../Common/CommonHooks';
import { getSiteCoreImagePath } from '../../Common/home-common';
import useQueryString from '../../Common/hooks/useQueryString';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: 3,
    // slidesToScroll: 3,
    initialSlide: 0,
    variableWidth: true
};

const FO_Menu = () => {
    const { i18n } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const context = useContext(RacingContext);
    const queryString = useQueryString();
    const betType = context.content.page;

    const Menu = () => {
        let templateName = queryString.get('template');
        let foContent = null;
        if (window.globalConfig.IS_PREVIEW && templateName) {
            foContent = context.foUserContent?.getTemplateByName(betType, templateName);
        }
        if (!foContent) foContent = context.foUserContent?.getTemplate(betType);
        const foLinks = foContent?.children?.filter((x) => x.template.name === window.sitecoreVars.RS_FO_LINK_GROUP)[0];
        if (!foLinks) {
            return null;
        }
        return foLinks.children?.map((item, index) => {
            if (item.enable.value !== '1') {
                return null;
            }
            const cssStr = isMobileSize ? 'fomenuM marginRight16px' : index > 0 ? 'left-border' : '';
            return (
                <div className={cssStr} key={index}>
                    <a href="#" onClick={(e) => handleClickMenuItem(e, item, null)}>
                        {item.name.value}
                    </a>
                    {item.icon.src && (
                        <span className="leftMenuIcon">
                            <img
                                src={getSiteCoreImagePath(item.icon.src, i18n.language)}
                                width={item.icon.width}
                                height={item.icon.height}
                                title={item.name.value}
                            />
                        </span>
                    )}
                </div>
            );
        });
    };

    return isMobileSize ? (
        <div className="foslider">
            <Slider {...settings}>{Menu()}</Slider>
        </div>
    ) : (
        <div className="fotopmenu">
            <Menu />
        </div>
    );
};

export default FO_Menu;
