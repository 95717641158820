import React,{ useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useMarksixFixturesQuery } from '../../Home/Data/SitecoreDataHooks';
import { useWindowSize } from '../../Common/CommonHooks';
import Loading from '../../Common/ui-components/Loading';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const FixturesCalendar = () => {
    const {t} = useTranslation()
    const [selectedMonthIndex, setSelectedMonthIndex] = useState(-1);
    const { loading, months, legends, getDatesByYearAndMonth } = useMarksixFixturesQuery();

    useEffect(()=>{
        if ( !loading ) {
            let currentYearMonth = moment().format('YYYYMM');
            let idx = months.indexOf(currentYearMonth);
            if ( idx>=0 ) {
                setSelectedMonthIndex(idx);
            }
            else {
                setSelectedMonthIndex(0);
            }
        }
    }, [loading]);

    const handleMonthChange = (idx) => {
        if ( idx>=0 && idx<months.length )
            setSelectedMonthIndex(idx);
    }

    const formatMonthLabel = (item) => {
        let year = item?.substring(0, 4);
        let month = parseInt(item?.substring(4, 6));
        return t('LB_YEAR_MONTH_FORMAT').replace('{0}', year).replace('{1}', t('LB_MONTH_'+month));
    }

    const MonthDropdown = () => {
        if ( selectedMonthIndex<0 )
            return null;

        return <div className="fixtures-calendar-top">
            <div className="fixtures-arrow">
                <ArrowIcon className="cursor-point" onClick={()=> handleMonthChange(selectedMonthIndex-1)} direction='left' size={30}/>

            </div>
            <div className="cursor-pointer">
                <DropdownButton
                title={formatMonthLabel(months[selectedMonthIndex])}
                align="start"
                value={formatMonthLabel(months[selectedMonthIndex])} >
                {months.map((item, index) => {
                    return <Dropdown.Item value={item} onClick={()=>handleMonthChange(index)} active={selectedMonthIndex === index} key={'top' + item}>{formatMonthLabel(item)}</Dropdown.Item>
                })}
                </DropdownButton>
            </div>
            <div className="fixtures-arrow">
                <ArrowIcon className="cursor-point" onClick={()=> handleMonthChange(selectedMonthIndex+1)} direction='right' size={30}/>

            </div>
        </div>
    }

    const CalendarHeader = () => {
        return <>
            <div className="fixtures-calendar-head">{t('LB_SS_SUN')}</div>
            <div className="fixtures-calendar-head">{t('LB_SS_MON')}</div>
            <div className="fixtures-calendar-head">{t('LB_SS_TUE')}</div>
            <div className="fixtures-calendar-head">{t('LB_SS_WED')}</div>
            <div className="fixtures-calendar-head">{t('LB_SS_THU')}</div>
            <div className="fixtures-calendar-head">{t('LB_SS_FRI')}</div>
            <div className="fixtures-calendar-head">{t('LB_SS_SAT')}</div>
            <div className="fixtures-headerline"></div>
        </>
    }

    const CalendarBody = () => {
        let ym = months[selectedMonthIndex];
        let year = parseInt(ym?.substring(0,4));
        let month = parseInt(ym?.substring(4,6));
        let startDate = moment([year, month-1, 1]);
        startDate.subtract(startDate.day(), 'days');
        let output = [];

        for ( let i=0; i<42; i++ ) {
            let tmpYearMonth = startDate.format("YYYYMM");
            if ( i%7===0 && parseInt(tmpYearMonth)>parseInt(ym) ) {
                break;
            }
            let hlCss = 'fixture-nohl';
            if ( startDate.month()+1===month ) {
                let monthObj = getDatesByYearAndMonth(tmpYearMonth);
                if ( monthObj?.snowballs.date.filter(x=>parseInt(x.value.substring(0,2))===startDate.date()).length > 0 ) {
                    hlCss = 'fixture-snowball-hl';
                }
                else if ( monthObj?.dates.date.filter(x=>parseInt(x.value.substring(0,2))===startDate.date()).length > 0 ) {
                    hlCss = 'fixture-drawday-hl';
                }
                else if ( monthObj?.presales.date.filter(x=>parseInt(x.value.substring(0,2))===startDate.date()).length > 0 ) {
                    hlCss = 'fixture-presales-hl';
                }
            }
            output.push(<div className={startDate.month()+1!==month ? "fixture-body-cell-dim" : 'fixture-body-cell'}>
                <div className={hlCss}>{startDate.date()}</div>
            </div>);
            startDate.add(1, 'days');
        }

        output.push(<div className="fixtures-bottomline"></div>);
        return output;
    }

    const CalendarRemarks = () => {
        let ym = months[selectedMonthIndex];
        let month = getDatesByYearAndMonth(ym);
        return month ?
            <div>
                <div className="fixtures-remark-title" >{month.header?.value}</div>
                <div className="fixtures-remark-text" dangerouslySetInnerHTML={{ __html: month.message?.value}}></div>
            </div> : null
    }

    const CalendarLegend = () => {
        return <div className="fixture-legend">
            {legends?.children?.map(legend => {
                switch ( legend.key ) {
                    case "Normal":
                        return <>
                            <div className="fixture-drawday-legend-color"></div>
                            <div className="fixture-legend-txt">{legend.name.value}</div>
                        </>
                    case "SnowballDraw":
                        return <>
                            <div className="fixture-snowball-legend-color"></div>
                            <div className="fixture-legend-txt">{legend.name.value}</div>
                        </>
                    case "Presales":
                        return <>
                            <div className="fixture-presales-legend-color"></div>
                            <div className="fixture-legend-txt">{legend.name.value}</div>
                        </>
                }
            })}
        </div>
    }

    const { isMobileSize } = useWindowSize();
    const columnsCountBreakPoints = isMobileSize ? { 350: 1, 1024: 2 } : { 350: 2 }

    if (selectedMonthIndex==-1 || loading) {
        return <Loading/>
    } else if(months.length == 0){
        return <div className='noInfoTip'>{t('LB_RC_JTC_NOINFO')}</div>
    }
    else {
        return <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
            <Masonry className="masonry-col">
                <div className="fixture-left">
                    <MonthDropdown />
                    <div className="fixtures-calendar">
                        <CalendarHeader />
                        <CalendarBody />
                    </div>
                    <CalendarLegend />
                </div>
                <div className="fixture-right">
                    <CalendarRemarks />
                </div>
            </Masonry>
        </ResponsiveMasonry>
    }
}

export default FixturesCalendar