import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';

export const CRSTopTips = (props) => {
    const { t, i18n } = useTranslation();
    const {crsTipsObj, crsTipsCbFunc, resetCrsTipsObjFunc} = props;
    const {isCrsTipsShow}=crsTipsObj;

    useEffect(() => {
        
        return () => {
            resetCrsTipsObjFunc();
            enableScrollBar()
        }
    }, [])

    const onClickClose = () => {
        crsTipsCbFunc(crsTipsObj)
    };

    const disableScrollBar = () => {
        document.body.style.overflow = "hidden";
    }
    const enableScrollBar = () => {
        document.body.removeAttribute('style');
    }

    return (
        <Popup 
            open={isCrsTipsShow} 
            onOpen={disableScrollBar}
            onClose={enableScrollBar}
            closeOnDocumentClick={false} 
            className="CRSTopTips"
        >
            <div className="crsTips-msg-container">
                <div className="msgContent">
                    {new Array(9).fill('LB_FB_CRSTIPS_MSG').map((item,index)=>{
                        const greenText=[0,1].includes(index)?'greenText ':'';
                        const betterMargin=[1,4,7,8].includes(index)?'betterMargin ':'';
                        return <div className={`${greenText}${betterMargin}`} key={item}>{t(item+(index+1))}</div>
                    })}
                </div>
                <div className="close-btn-box">
                    <div className="close" onClick={onClickClose}>
                        {t('LB_FB_CRSTIPS_GOTIT')}
                    </div>
                </div>
            </div>
        </Popup>
    );
};
