import React,{ useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MarksixContext } from '../../Home/MarksixPage';
import FixturesCalendar from '../Common/FixturesCalendar'

const Fixtures = () => {
    const {t} = useTranslation()
    const context = useContext(MarksixContext);
    useEffect(()=>{
    },[])
    return <section className={context.pageName}>
        <header className='result-header-m'>
            {t('LB_M6_FIXTURES')}
        </header>
        <FixturesCalendar />
    </section>
}

export default Fixtures