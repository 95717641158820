import { getWebIDHashed, getCustomerSegment } from './wa';

export const glassboxSetUserInfo = () => {
    try {
        window._detector?.triggerCustomEvent('hashID', getWebIDHashed());
        window._detector?.triggerCustomEventMap('custSegment', parseCustomerSegment());
    } catch (e) {}
};

export const glassboxClearWebIDHashed = () => {
    try {
        window._detector?.triggerCustomEvent('hashID', '');
        window._detector?.triggerCustomEvent('custSegment', '');
    } catch (e) {}
};

export const glassboxCustomEvent = (name, value = '') => {
    if (name) {
        window._detector?.triggerCustomEvent(name, value);
    }
};

const parseCustomerSegment = () => {
    const custSegment = getCustomerSegment();
    if (custSegment.length >= 15) {
        return {
            PriorityCardHolder: custSegment.substring(0, 1),
            CBPSegment: custSegment.substring(1, 6),
            RacingParticipation: custSegment.substring(6, 7),
            FootballParticipation: custSegment.substring(7, 8),
            M6Participation: custSegment.substring(8, 9),
            Member: custSegment.substring(9, 10),
            AgeGroup: custSegment.substring(10, 12),
            Gender: custSegment.substring(12, 13),
            SpeedBet: custSegment.substring(13, 14),
            WebTV: custSegment.substring(14,15)
        }
    }
    return '';
}