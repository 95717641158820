import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEarlySettlementMsgQuery } from '../../../Home/Data/SitecoreDataHooks';
import './scss/index.scss';

const Header = () => {
    const { t } = useTranslation();
    return <header className="header">{t('LB_HELP_IMPORTANT_NOTICE')}</header>;
};


const FootBallHelp = () => {
    const { msgLoading, msgData } = useEarlySettlementMsgQuery();
    return (
        <div className="help">
            <Header />
            {!msgLoading && msgData?.enable?.value==="1" ? <div className="content" dangerouslySetInnerHTML={{ __html: msgData.msg.value }}></div> : null}
        </div>
    );
};

export default FootBallHelp;
