import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NewWindow, isLocalMeeting } from '../../Common/home-common';
import CheckBox from '../../Common/ui-components/CheckBox';


import downloadBtn from '../../../info/Include/images/race_form_download.png';
import { getRefreshTime } from './Common';
import { GetRaceConfig } from '../../Common/ConfigHelper';
import { RacingContext } from '../../Home/RacingPage';
import { GlobalContext } from '../../../contexts/GlobalContext';
import $ from 'jquery';

import { getMeetingHeaderTitle } from './Common';
import { CurrentOdds } from './CurrentOdds';
import { isDisplayFoPool } from '../FO/FO_List';
import { clipboardRaceDesc, clipboardInvInfo } from './ClipboardCommon';


function RefreshBtn(props) {
    return (
        <div className="inline-flex refresh-odds" onClick={props.onClickRefreshButton}>
            <div id="refreshButton" />
            {/* <label id="refreshButtonTxt">{t('LB_RC_REFRESH' + (context.content.page != 'TURNOVER' ? '_ODDS' : ''))}</label> */}
        </div>
    );
}

const RacingTitle = (props) => {
    const context = useContext(RacingContext);
    const globalContext = useContext(GlobalContext);
    const { t, i18n } = useTranslation();
    const mtg = context.content.meeting;
    let foPool = mtg.foPools?.filter((x) => x?.oddsType == context.content.page && isDisplayFoPool(x))[0];

    useEffect(() => {
        if (!props.poolDefined) {
            $('.allupbtn-container-m').hide();
        } else {
            $('.allupbtn-container-m').show();
        }
    }, [props.poolDefined]);



    const rcTitleRef = useRef(null);
    const resizeRef = useRef(null);
    const rightRef = useRef(null);
    const timer = useRef(null);
    const alupBtnProps = { ...props,mtg, context, globalContext }

    const copyContent = () => {
        // try {
        //     navigator.clipboard.writeText(`<table><tr><td>${t('LB_RACE_' + context.content.page)}</td><td colspan="3">${getMeetingHeaderTitle(context, mtg, t, i18n.language)}</td></tr>
        //     ${clipboardRaceDesc()}
        //     </table>
        //     <table><tr>
        //     ${clipboardInvInfo()}
        //     </tr></table>
        //     <table><tr>
        //     <td>${context.racingFuncs.copyClipboardLeftOddsTable ? context.racingFuncs.copyClipboardLeftOddsTable() : ""}</td>
        //     <td></td>
        //     <td>${context.racingFuncs.copyClipboardRightOddsTable ? context.racingFuncs.copyClipboardRightOddsTable() : ""}</td>
        //     </tr></table>
        //     `);
        //     console.debug('copy content to clipboard');
        // } catch (e) {
        //     console.error('Fail to copy content to clipboard', e)
        // }
    }

    return (
        <div ref={rcTitleRef} className={`rcTitle${context.pageConfig.showPresalesDropdown ? ' rc-page-rcTitle' : ''}`}>
            <div id="mtgInfoDV" ref={resizeRef}>
                <div>
                    <span id="lblPageName" onClick={() => { copyContent(); }}>{t('LB_RACE_' + context.content.page)}</span>
                    {(props.poolDefined || props.foPoolDefined) && (
                        <>
                            {context.pageConfig.showInstNo ? foPool?.instNo : null}
                            {context.pageConfig.showHelpBtn ? <HelpBtn /> : null}
                        </>
                    )}
                </div>
                <div id="meetingInfoDiv">{(props.poolDefined || props.foPoolDefined) && getMeetingHeaderTitle(context, mtg, t, i18n.language)}</div>
            </div>

            {(props.poolDefined || props.foPoolDefined) && (
                <div className='rcTitleControl'>
                    {context.pageConfig.showAlupBtn ? (
                        <div className="allupBtn">
                            <AllupBtn {...alupBtnProps}/>
                        </div>
                    ) : null}
                    <div ref={rightRef} className="right form-check">
                        {context.pageConfig.showRefreshTime ? (
                            <div id="refreshTime">
                                {t('LB_LASTUPDATE')} {getRefreshTime(mtg, context, true)}
                            </div>
                        ) : null}
                        {context.pageConfig.showRefreshBtn ? <RefreshBtn onClickRefreshButton={props.onClickRefreshButton}/> : null}
                        {context.pageConfig.showDonloadBtn && window.globalConfig.IS_EWIN2 ? <DownloadBtn {...alupBtnProps}/> : null}
                    </div>
                    {context.pageConfig.showPresalesDropdown ? <CurrentOdds context={context} /> : null}
                </div>
            )}
        </div>
    );
};
export default RacingTitle;

function HelpBtn() {
    const {t, i18n} = useTranslation();
    const context = useContext(RacingContext);

    let pLang = i18n.language==="en" ? "en-US" : "zh-HK";
    let guidePage = ["JKC", "TNC"].includes(context.contentRef.current.page) ? window.specialPages["RC_HELP_FO"] : window.specialPages['RC_HELP'];
    let url =  window.globalConfig.SPECIAL_URL + guidePage.replace('{0}', pLang);
    return <div className="mtgInfoHelp" title={t('LB_FB_HELP_ALT')} onClick={() => NewWindow(url, '', 800, 600, 1, 1)} />;
}

function AllupBtn(props) {
    const {t, i18n} = useTranslation()
    const { mtg, context, globalContext } = props
    if (mtg == null || !props.poolDefined) return null;

    if (context.content.page === 'CWA') {
        return (
            <div className="allup-btn inline-flex">
                <label id='caw_allup_label'>{t('LB_RC_ALLUP')}: </label>
                <CheckBox
                    id="cwaallupCheckbox"
                    disabled={mtg.totalNumberOfRace <= 1}
                    onClick={(e) => {
                        props.onClickAllUpBtn(e, false, 'CWAALUP');
                    }}
                    noBorder
                />
                <label htmlFor="cwaallupCheckbox" title="" className="form-check-label">
                    {t('LB_RC_INDIVIDUAL_RACE')}
                </label>
                <CheckBox
                    id="cwaalallupCheckbox"
                    disabled={mtg.totalNumberOfRace <= 1}
                    onClick={(e) => {
                        props.onClickAllUpBtn(e, false, 'CWAALLALUP');
                    }}
                    noBorder
                />
                <label
                    htmlFor="cwaalallupCheckbox"
                    title=""
                    className="form-check-label"
                >
                    {t('LB_RC_ALL_RACE')}
                </label>
            </div>
        );
    } else {
        let targetPage = '';
        let checked = false;
        let enable = false;
        switch (context.content.page) {
            case 'WP':
                targetPage = 'WPALUP';
                enable = mtg.totalNumberOfRace > 1;
                break;
            case 'WPALUP':
                targetPage = 'WP';
                checked = true;
                enable = mtg.totalNumberOfRace > 1;
                break;
            case 'WPQ':
                targetPage = 'WPQALUP';
                enable = mtg.totalNumberOfRace > 1;
                break;
            case 'WPQALUP':
                targetPage = 'WPQ';
                checked = true;
                enable = mtg.totalNumberOfRace > 1;
                break;
            case 'FCT':
                targetPage = 'FCTALUP';
                enable = mtg.totalNumberOfRace > 1;
                break;
            case 'FCTALUP':
                targetPage = 'FCT';
                enable = mtg.totalNumberOfRace > 1;
                checked = true;
                break;
            case 'TRI':
                targetPage = 'TRIALUP';
                enable = mtg.totalNumberOfRace > 1;
                break;
            case 'TRIALUP':
                targetPage = 'TRI';
                enable = mtg.totalNumberOfRace > 1;
                checked = true;
                break;
            case 'JTCOMBO':
                targetPage = 'JTCOMBO';
                enable = globalContext.globalState.jtcPara.length > 0;
                break;
        }
        return (
            <div className="allup-btn inline-flex">
                <CheckBox
                    className="allupCheckbox"
                    id="allupCheckbox"
                    disabled={!enable}
                    defaultChecked={checked || globalContext.globalRef.current.allupBoxChecked}
                    control={true}
                    onClick={(e) => {
                        props.onClickAllUpBtn(e, false, targetPage, context);
                    }}
                    noBorder={true}
                    reverse={true}
                />
                <label htmlFor="allupCheckbox" title="" className="form-check-label">
                    {t('LB_RC_ALLUP')}
                </label>
            </div>
        );
    }
}

function DownloadBtn({context}) {
    const {t, i18n} = useTranslation()

    const dt = context.content.date.replace(/-/g, '');
    const venCode = context.content.venue;
    const raceNo = context.content.raceNo;
    const lang = i18n.language === 'en' ? '' : '_chi';
    const oLang = i18n.language === 'en' ? "E" : "C";
    const isLocal = isLocalMeeting(venCode);
    const urlConfig = GetRaceConfig()['racingUrl'];
    const downloadUrl = isLocal
        ? urlConfig.raceCardPDFFile
            .replace('{DOMAIN}', window.globalConfig.JCRW_URL)
            .replace('{0}', dt)
            .replace('{1}', raceNo)
            .replace('{2}', lang)
        : urlConfig.raceCardOverseaPDFFile
            .replace('{DOMAIN}', window.globalConfig.JCRW_URL)
            .replace('{0}', oLang)
            .replace('{1}', dt)
            .replace('{2}', venCode)
            .replace('{3}', raceNo);
    const downloadAllUrl = isLocal
        ? urlConfig.raceCardAllPDFFile
            .replace('{DOMAIN}', window.globalConfig.JCRW_URL)
            .replace('{0}', dt)
            .replace('{1}', lang)
        : urlConfig.raceCardOverseaAllPDFFile
            .replace('{DOMAIN}', window.globalConfig.JCRW_URL)
            .replace('{0}', oLang)
            .replace('{1}', dt)
            .replace('{2}', venCode);

    const handleClickDownLoad = async (isAll) => {
        if (isAll) {
            // const result = await fetch(downloadAllUrl, { method: 'HEAD' });
            // if (result.ok) {
                window.open(downloadAllUrl);
            // } else {
            //     alert(t('LB_RC_RACECARD_DOWNLOAD_404'));
            // }
        } else {
            // const result = await fetch(downloadUrl, { method: 'HEAD' });
            // if (result.ok) {
                window.open(downloadUrl);
            // } else {
            //     alert(t('LB_RC_RACECARD_DOWNLOAD_404'));
            // }
        }
    };

    return (
        <>
            <div className="download-race-btn cp" onClick={() => handleClickDownLoad()} id={'raceTitleDownload_This'}>
                <img className="raceCard-download-icon" src={downloadBtn} alt={t('LB_RC_RACECARD_DOWNLOAD_THIS')} />
                <span>{t('LB_RC_RACECARD_DOWNLOAD_THIS')}</span>
            </div>
            <div className="download-race-btn cp" onClick={() => handleClickDownLoad(true)} id={'raceTitleDownload_All'}>
                <img className="raceCard-download-icon" src={downloadBtn} alt={t('LB_RC_RACECARD_DOWNLOAD_ALL')} />
                <span>{t('LB_RC_RACECARD_DOWNLOAD_ALL')} </span>
            </div>
        </>
    );
}