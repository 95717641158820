import { isTournamentPage } from "../../Common/Common";

export const createPool =(contentRef, message, options={})=>{
    // console.debug("message",message);
    let linesArr = [];
    let avaLineArr = [];
   
    for(let i=0; i<message.lines.line.length ; i++){
        if( contentRef?.current?.isESContent || options.isBetSlip || message.lines.line[i].st == "Available"){
            avaLineArr.push(i); 
        }
    }
    for(let arrline = 0; arrline < avaLineArr.length; arrline++){
        let lineNo = avaLineArr[arrline];
        let combArr = [];
        for(let i=0; i<message.lines?.line?.[lineNo]?.comb?.length;i++){
            let tCombId = parseInt(message.lines.line[lineNo].comb[i].id);
            let tCmb = message.dtl.cmb?.filter(x=>x.id===tCombId)[0];
            if (!tCmb) continue;

            let selArr = [];
            tCmb.selList?.forEach(s => {
                let tSel = message.dtl.sel.filter(x=> x.id===s.id )[0];
                if ( tSel ) {
                    const selId = ['TQL'].includes(message.bTyp) ? message.dtl?.sel?.find(item => item.id === s.id)?.ref?.teamId?.[0] : s.id
                    let sel = {
                        name_ch: null,
                        name_en: null,
                        selId: selId,
                        str: tSel.str,
                        sequence: s.seq
                    }

                    if(tSel.ref?.ply!=null){
                        sel.name_ch = tSel.ref.ply?.[0]?.nm?.filter(x => x.ln =="zh-hk")[0]?.val;
                        sel.name_en = tSel.ref.ply?.[0]?.nm?.filter(x => x.ln =="en-us")[0]?.val;
                    }
                    else if(tSel.ref?.team!=null){
                        sel.name_ch = tSel.ref.team?.[0]?.nm?.filter(x => x.ln =="zh-hk")[0]?.val;
                        sel.name_en = tSel.ref.team?.[0]?.nm?.filter(x => x.ln =="en-us")[0]?.val;
                    }
                    else if(tSel.ref?.nm!=null){
                        sel.name_ch = tSel.ref.nm?.filter(x => x.ln =="zh-hk")[0]?.val;
                        sel.name_en = tSel.ref.nm?.filter(x => x.ln =="en-us")[0]?.val;
                    }
                    selArr.push(sel);
                }
            });
            let str = message.lines.line[lineNo].comb[i].str
            if( ['GPF'].includes(message.bTyp)){
                let selListId1 = tCmb.selList.find(i => i.seq == 1)?.id
                let selListId2 = tCmb.selList.find(i => i.seq == 2)?.id
                const sel1 = message.dtl.sel.find(x=> x.id===selListId1 )
                const sel2 = message.dtl.sel.find(x=> x.id===selListId2 )
                str = `0${sel1?.str}:0${sel2?.str}`
                selArr = selArr.sort((x,y) => x?.sequence > y?.sequence)
            }
            let comb = {
                combId: message.lines.line[lineNo].comb[i].id,
                currentOdds: message.lines.line[lineNo].comb[i].odds.cur.odds,
                selections: selArr,
                status: message.lines.line[lineNo].comb[i]?.st?.toUpperCase(),
                str: str ,
                offerEarlySettlement: message.lines.line[lineNo].comb[i].esStatus
            }
            combArr.push(comb);
        }
        
        let lines = {
            combinations: combArr,
            condition: message.lines.line[lineNo].cond,
            lineId: message.lines.line[lineNo].id,
            main: message.lines.line[lineNo].main,
            status: message.lines.line[lineNo]?.st?.toUpperCase()
        }
        
        linesArr.push(lines);
    }
    let name_ch = null;
    let name_en = null;
    if(message.dtl.desc?.length>0){
        name_ch = message.dtl.desc.filter(x => x.ln =="zh-hk")[0].val;
        name_en = message.dtl.desc.filter(x => x.ln =="en-us")[0].val;
    }
    let matchId = message.dtl.tagMatchId;
    let chpType = message.dtl.chpTyp?.toString();
    // console.debug("chpType",chpType);
    let tempPool =  {
       id : message.id,
       expectedSuspendDateTime : message?.dtl?.expBizSuspendDtT,
       chpType : chpType,
       inplay : message.dtl.betOffer.inplay,
       bTyp : message.bTyp,
       instNo : message.instNo || 0,
       lines: linesArr,
       name_ch : name_ch,
       name_en :  name_en,
       oddsType : message.bTyp,
       status : message.st.toUpperCase(),
       updateAt : message.updAt,
       matchid:  matchId
    };
    if(contentRef && isTournamentPage(contentRef?.current?.page) && ['TQL', 'CHP'].includes(message.bTyp) && message.dtl.tagMatchId){
        const curMatch = contentRef.current.rawMatchList.find(item  => item.id === message.dtl.tagMatchId)
        tempPool.match ={
            id:curMatch?.id,
            status: curMatch?.status,
            homeTeam: {name_en: curMatch?.homeTeam?.name_en}
        }
    }

    return tempPool;
}